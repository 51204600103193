import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useMemo } from "react";
import { GQL_MetaFieldType, useGetDashboardMetaFieldsNbQuery } from "@brikl/codegen";
export var useDashboardMetaFieldsConfigurations = function(metaFieldResourceType) {
    //query
    var ref = useGetDashboardMetaFieldsNbQuery({
        variables: {
            filter: {
                resourceType: metaFieldResourceType
            }
        }
    }), data = ref.data, loading = ref.loading;
    //utility
    var getMetaFieldsConfigurations = function(type) {
        var ref;
        return (ref = metaFields === null || metaFields === void 0 ? void 0 : metaFields.filter(function(field) {
            return field.type === type;
        })) !== null && ref !== void 0 ? ref : [];
    };
    //data
    var metaFields = useMemo(function() {
        return data === null || data === void 0 ? void 0 : data.metaFields;
    }, [
        data
    ]);
    var ref1;
    var metaFieldsConfigurationsNames = useMemo(function() {
        var ref;
        return (ref1 = data === null || data === void 0 ? void 0 : (ref = data.metaFields) === null || ref === void 0 ? void 0 : ref.map(function(field) {
            return field.name;
        })) !== null && ref1 !== void 0 ? ref1 : [];
    }, [
        data
    ]);
    var metaFieldsTypeOptions = getMetaFieldsConfigurations(GQL_MetaFieldType.Option);
    var metaFieldsTypeSingles = getMetaFieldsConfigurations(GQL_MetaFieldType.SingleLineTextField);
    var metaFieldsTypeMultiples = getMetaFieldsConfigurations(GQL_MetaFieldType.MultipleLineTextField);
    var metaFieldsTypeNumbers = getMetaFieldsConfigurations(GQL_MetaFieldType.Int);
    var metaFieldsTypeBooleans = getMetaFieldsConfigurations(GQL_MetaFieldType.Boolean);
    var metaFieldsOptionsConfigurations = metaFieldsTypeOptions === null || metaFieldsTypeOptions === void 0 ? void 0 : metaFieldsTypeOptions.map(function(field) {
        var ref;
        var fieldOptions = (field === null || field === void 0 ? void 0 : (ref = field.configuration) === null || ref === void 0 ? void 0 : ref.__typename) === "MetaFieldOptionConfiguration" ? field === null || field === void 0 ? void 0 : field.configuration.MetaFieldOption : [];
        return _object_spread_props(_object_spread({}, field), {
            configuration: {
                metaFieldOptions: fieldOptions
            }
        });
    });
    return {
        loadingMetaFieldsConfiguration: loading,
        metaFieldsConfigurationsNames: metaFieldsConfigurationsNames,
        metaFieldsTypeOptions: metaFieldsOptionsConfigurations,
        metaFieldsTypeSingles: metaFieldsTypeSingles,
        metaFieldsTypeMultiples: metaFieldsTypeMultiples,
        metaFieldsTypeNumbers: metaFieldsTypeNumbers,
        metaFieldsTypeBooleans: metaFieldsTypeBooleans
    };
};
