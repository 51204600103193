import { darken, lighten } from "color2k";
export var getShade = function(colorValue) {
    var color = colorValue && colorValue !== "#" ? colorValue : "#000000";
    try {
        return {
            50: lighten(color, 0.8),
            100: lighten(color, 0.5),
            200: lighten(color, 0.375),
            300: lighten(color, 0.25),
            400: lighten(color, 0.125),
            500: color,
            600: darken(color, 0.125),
            700: darken(color, 0.25),
            800: darken(color, 0.375),
            900: darken(color, 0.5)
        };
    } catch (error) {
        return {
            50: lighten("#000000", 0.8),
            100: lighten("#000000", 0.5),
            200: lighten("#000000", 0.375),
            300: lighten("#000000", 0.25),
            400: lighten("#000000", 0.125),
            500: "#000000",
            600: darken("#000000", 0.125),
            700: darken("#000000", 0.25),
            800: darken("#000000", 0.375),
            900: darken("#000000", 0.5)
        };
    }
};
