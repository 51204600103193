import React, { useEffect, useState } from 'react'

import { useAuth } from '@brikl/dashboard-utils'
import {
  Box,
  Checkbox,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@brikl/design-core'
import { BriklButton } from '@brikl/design-system'
import { reportError } from '@brikl/shared-utils'

export const FeatureNotice = ({ shopId }) => {
  const { isOpen, onClose } = useDisclosure()
  const authContext = useAuth()

  const enabledNoticeForUser =
    authContext?.user?.email !== 'qa@brikl.io' && shopId !== 'vermarc'
  useEffect(() => {
    try {
      if (
        enabledNoticeForUser &&
        window.localStorage &&
        window.localStorage.getItem(
          'ACKNOWNLEDGE_FEATURE_TEAMSTORE_DEPLOYMENT'
        ) !== 'true'
      ) {
        // onOpen()
      }
    } catch (error) {
      reportError(error, {
        module: 'FeatureNotice',
        action: 'enabledNoticeForUser',
      })
    }
  }, [enabledNoticeForUser])
  const [dontShowAgain, setDontShowAgain] = useState(false)
  const onCloseModal = () => {
    if (dontShowAgain) {
      try {
        window.localStorage.setItem(
          'ACKNOWNLEDGE_FEATURE_TEAMSTORE_DEPLOYMENT',
          'true'
        )
      } catch (error) {
        reportError(error, {
          module: 'FeatureNotice',
          action: 'onCloseModal',
        })
      }
    }
    onClose()
  }
  return (
    <Modal
      size="3xl"
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <Box p="6" borderWidth="1px" borderColor="gray.200" borderRadius="3px">
          <Heading as="h1" size="md">
            Changes to team store deployments
          </Heading>
          <Text my={1}>
            From today onwards, team store changes are now available in
            real-time.
          </Text>
          <Text my={1}>
            All team store pages, such as team store homepages, product and
            collection pages, will show the most recent data at all times.
            Deployment is no longer required.
          </Text>
          <Text my={1}>
            Non-team store collection pages and content pages will still require
            deployment for updates to be publicly visible.
            <Text my={1}>
              Reach out to{' '}
              <a href="mailto:support@brikl.com">support@brikl.com</a> if you
              have any questions.
            </Text>
          </Text>
          <Box my={4}>
            <Checkbox
              isChecked={dontShowAgain}
              onChange={e => {
                setDontShowAgain(e.currentTarget.checked)
              }}
            >
              Do not show again
            </Checkbox>
          </Box>
          <Box my={4}>
            <BriklButton onClick={onCloseModal} variant="outlined" mr="4">
              Close
            </BriklButton>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  )
}
