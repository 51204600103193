export var collectionGraphQLWhitelist = [
    //C
    "createDashboardCollectionNB",
    //R
    "getDashboardCollectionCommonNB",
    "getDashboardCollectionListNB",
    "getDashboardCollectionInformationNB",
    "getDashboardCollectionMediaNB",
    "getDashboardCollectionProductNB",
    "getDashboardParentCollectionNB",
    "getDashboardChildCollectionNB",
    //U
    "updateDashboardCollectionNB",
    "addDashboardChildCollectionNB",
    "removeDashboardChildCollectionNB",
    "addDashboardProductToCollectionNB",
    "removeDashboardProductFromCollectionNB",
    "updateDashboardCollectionProductSortOrderNB",
    "updateDashboardCollectionChildSortOrderNB",
    //D
    "deleteDashboardCollectionNB", 
];
