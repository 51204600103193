export var GRAPHQL_URI_PRODUCTION = "https://api.mybrikl.com/graphql";
export var GRAPHQL_URI_STAGING = "https://staging.api.mybrikl.com/graphql";
export var GRAPHQL_URI_DEV = "https://dev.api.mybrikl.com/graphql";
export var GRAPHQL_URI_LOCAL = "http://localhost:1212/dev/graphql";
export var HASURA_URI_DEV = "dev.events.mybrikl.com/v1/graphql";
export var HASURA_URI_STAGING = "staging.events.mybrikl.com/v1/graphql";
export var HASURA_URI_PRODUCTION = "events.mybrikl.com/v1/graphql";
export var CDN_URI_DEV = "https://dev.cdn.mybrikl.com";
export var CDN_URI_STAGING = "https://staging.cdn.mybrikl.com";
export var CDN_URI_PRODUCTION = "https://cdn.mybrikl.com";
export var HASURA_BACKEND_V2_URI_DEV = "dev.internal-api.brikl.com/v1/graphql";
export var HASURA_BACKEND_V2_URI_STAGING = "staging.internal-api.brikl.com/v1/graphql";
export var HASURA_BACKEND_V2_URI_PRODUCTION = "internal-api.brikl.com/v1/graphql";
