import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { createVocab } from "./createVocab";
export var en_UK = {
    studioColorZone: "Color Zone",
    no_data: "No Data",
    payment_detail: "Payment Detail",
    order_status: "Order Status",
    order_created: "Order created",
    see_more: "see more",
    see_less: "see less",
    store: "Store",
    created: "Created",
    width: "Width",
    height: "Height",
    rotation: "Rotation",
    QualityOptionHighest: "Higest",
    QualityOptionHigh: "High",
    QualityOptionMedium: "Medium",
    QualityOptionLow: "Low",
    bulkOrderSaveBeforeContinue: "The order details will be saved before continuing.",
    bulkOrderDeleteAll: "Delete All",
    resetColor: "Reset color",
    resetAop: "Reset Print",
    resetEditedImage: "Reset",
    studioLayer_not_found: "Layer not found.",
    studioQualitySettingtooltip: "The configuration is used to adjust the quality of the 3D model which will effect the speed of the studio. For example, the high resolution will consume more resources and make your studio slow.",
    studioQualityControlHeader: "Quality ",
    studioStepPattern: "Pattern Overlay",
    studioStepMaterial: "Material",
    studioStepOrderdetail: "Personalization",
    studioStepImage: "Image",
    studioStepColor: "Color",
    studioStepAlloverprint: "Print",
    studioStepTemplate: "Design",
    studioStepText: "Text",
    studioStepPersonalization: "Placeholder",
    bulkOrderProcessCheckout: "Proceed to Checkout",
    bulkOrderConfirm: "Once you have confirmed and proceeded to the checkout, you cannot go back to edit the order details. Please make sure all information is correct.",
    bulkOrderCheckout: "Checkout",
    bulkOrderPleaseNote: "Please note that going back to the studio will delete all the order detail entries. Do you wish to proceed?",
    backToStudio: "Back to Studio",
    bulkOrderAcceptItem: "Currently we accept a maximum of 200 orders pasted into the sheet (1 line = 1 item).",
    bulkOrderPasteInfo: "You must paste the information in the same format / order of the columns present.",
    bulkOrderCopyAndPaste: "Copy and paste the orders into the sheet below.",
    bulkOrderTooltip: "How does it work?",
    bulkPasteOrder: "Paste Order",
    bulkAddOrder: "Bulk Add Orders",
    orderDetailDescription: "You can enter the order detail one-by-one or paste your bulk order into our system.",
    orderDetailSelectMethod: "Select Your Method",
    orderDetailBulkOrder: "Bulk Paste Order",
    orderDetailManualOrder: "Manually Enter Order",
    comments: "Comments",
    questions: "Questions",
    payment_instruction: "Payment Instruction",
    free_shipping: "Free shipping",
    designTemplateTooltip: "\n  Upload the design based on the largest size. Make sure it covers all smaller sizes.\n  If needed, add a bleed line. When you download the print file, it will be based on the largest size.\n  You can superimpose smaller sizes and use a clipping mask to get the desired print by size\n  ",
    sizeDesignTemplateTooltip: "\n  Upload a design for each individual size. When customers change colors, BRIKL will automatically change colors across all the sizes pre-loaded on the product.\n  When you download the print file, you can choose the size you need\n  ",
    studioTextOpacity: "Opacity",
    studioImageOpacity: "Opacity",
    storefront_shipping_methods_unselected_warning: "Please select the shipping methods",
    storefront_select_shipping_methods: "Select the shipping methods",
    amount: "Amount",
    vat: "VAT",
    total_tax: "Total VAT",
    copyright: "Copyright",
    cart: "Cart",
    account: "Account",
    more_product_info: "More product info",
    studio_my_design_title: "My Designs",
    studio_my_design_copylink: "Copy link",
    studio_my_design_copiedLink: "Copied",
    studio_my_design_description: "Select a saved design to load into the studio",
    studio_my_design_createdAt: "Created:",
    studio_my_design_loadmore: "Load More Designs",
    studio_load_design: "Load",
    is_billing_default: "Is billing default",
    set_as_billing_default: "Set as billing default",
    is_shipping_default: "Is shipping default",
    set_as_shipping_default: "Set as shipping default",
    contact_us: "Contact Us",
    studioTextInputPlaceholder: "Enter text here",
    sent_successfully: "Sent successfully",
    warning: "warning",
    free_product_applied_to_cart: "Free Product Applied to cart",
    coupon_removed: "Coupon Removed",
    on_item: "on Item",
    to_total: "to total",
    code: "Code",
    applied: "Applied",
    promocode_not_found: "Promo Code not found",
    select: "Select",
    must_not_be_empty: "must not be empty",
    must_be_selected: "must be selected",
    is_not_valid: "is not valid",
    open_link_below_to_complete_payment: "Open link below to complete payment",
    terms_and_condition: "terms and conditions",
    I_agree_with_the: "I agree with the",
    and_the: "and the",
    privacy_policy: "privacy policy",
    of: "of",
    vermarc_sport: "Vermarc Sport",
    complete_payment: "Complete payment",
    off: "off",
    promo: "Promo",
    missing_payment_method: "Missing payment method",
    unsupported_payment_propvider: "Unsupported payment provider",
    pay: "Pay",
    card: "Card",
    bank_transfer: "Bank transfer",
    //payment provider
    ideal: "IDEAL",
    bancontact: "Bancontact",
    sofort: "Sofort",
    stripe: "Stripe",
    stripe_connect: "Stripe Connect",
    omise: "Omise",
    promptpay: "Prompt Pay",
    cash: "Cash",
    cash_on_delivery: "Cash on delivery",
    external: "EXTERNAL",
    paid: "PAID",
    refuned: "REFUNED",
    shipping_status: "Shipping status",
    production_status: "Production status",
    processing: "PROCESSING",
    refunded: "REFUNDED",
    in_production: "IN PRODUCTION",
    not_applicable: "NOT APPLICABLE",
    pending: "PENDING",
    failed: "FAILED",
    delivered: "DELIVERED",
    cancelled: "CANCELLED",
    canceled: "CANCELED",
    delivery_provider: "Delivery Provider",
    product_name: "Product",
    item: "Item",
    qty: "Qty",
    order_no: "Order No",
    back_to_order: "Back to Orders",
    placed_on: "Placed On",
    payment_status: "Payment status",
    shipping_to: "Shipping to",
    billing_to: "Billing to",
    contact_info: "Contact Info",
    order_creation_failed: "Order creation failed",
    remainChar: "Characters Remaining",
    studioMissingPersonalizationConfig: "Missing Personalization Group Configuration",
    request_for_quote: "Request quote",
    apply: "Apply",
    processing_payments: "Please wait while we process your request...",
    all_done: "All done",
    payment_redirect_note: "If you are not redirected automatically, please complete payment with the payment provider by clicking the following link:",
    size_chart: "Size Chart",
    shopping_cart: "Shopping basket",
    invalid_cart: "Invalid basket",
    invalid_partner_number: "Invalid partner number",
    parner_number_used: "Partner number has been used already",
    product: "Product",
    quantity: "Quantity",
    register: "Register",
    tell_us_who_you_are: "Tell us who you are",
    sport_club: "Sport Club",
    company: "Company",
    private_person: "Private person",
    selected_specific: "If you've selected sport club or company",
    enter_company_name: "Enter company name",
    company_information: "Company information",
    enter_sport_club_name: "Enter sport club name",
    vat_number: "VAT Number",
    example_number: "e.g. 1234567890",
    personal_information: "Personal Information",
    first_name: "First Name",
    first_name_placeholder: "Your name",
    last_name: "Last Name",
    last_name_placeholder: "Your last name",
    repeat_email: "Repeat your email",
    activate_passport: "Activate your Benefits Passport:",
    passport_number: "Loyalty passport number",
    passport_card_number_placeholder: "Enter Card Number",
    sign_up: "Sign Up",
    price: "Price",
    continue_shopping: "Continue Shopping",
    checkout_now: "Checkout Now",
    total: "Total",
    vat_included: "VAT included",
    nothing_in_your_cart: "Your shopping basket is empty",
    validate: "Validate",
    description: "Description",
    vermarc_cart_service_title: "OUR SERVICE YOUR ADVANTAGE",
    vermarc_cart_service_description: "The ultimate test of sports professionals and the utmost care for our products, are the guarantee of quality of Vermarc. If you have any questions, feel free to fall back on our knowledge and experience. We will be pleased to help you. The vermarc guarantees:",
    vermarc_cart_service_item_1: "Quality guarantee",
    vermarc_cart_service_item_2: "Optimal customer service guarantee",
    vermarc_cart_service_item_3: "Premium dealer network in Benelux",
    vermarc_cart_service_item_4: "Not satisfied, exchange guarantee or money back",
    coupon_code: "Coupon code:",
    delivery_prices: "DELIVERY PRICES",
    payment_options: "SECURE PAYMENT",
    bank_account_country: "Bank account country",
    payment_method: "Payment method",
    belgium_and_netherlands: "Belgium, Netherlands and Luxembourg",
    benelux: "Belgium, Netherlands and Luxembourg",
    from: "from",
    within_the_eu: "Within the EU",
    outside_the_eu: "Outside the EU",
    learn_more: "Learn more about our terms and conditions.",
    free: "Free.",
    email: "Email",
    password: "Password",
    confirm_password: "Confirm password",
    password_placeholder: "Min. 8 chars, 1 lowercase, 1 uppercase, 1 special char",
    enter_email: "Enter your email address",
    enter_password: "Enter your password",
    forgot_password: "Forgot password",
    reset_password: "Reset password",
    password_reset_required: "Your account requires a password reset. To reset click the button below.",
    log_in: "LOG IN",
    no_account_yet: "You don't have an account yet. Sign up now!",
    user_does_not_exist: "User does not exist!",
    premium_dealers_title: "PREMIUM DEALERS",
    premium_dealers_subtitle: "Vermarc Sport selects its distribution points carefully.",
    premium_dealers_belgium: "Belgium",
    premium_dealers_netherlands: "Netherlands",
    phone: "Phone",
    my_orders: "My Orders",
    my_designs: "My Designs",
    fabric: "Fabric",
    color: "Color",
    text: "Text",
    logo: "Logo",
    label: "Label",
    login: "Login",
    care_label: "Care label",
    choose_your_fabric: "Choose your fabric",
    interlock: "Interlock",
    mesh: "Mesh",
    woven: "Woven",
    cotton: "Cotton",
    fabric_overview: "Fabric overview",
    group: "Group",
    construction: "Construction",
    content: "Content",
    weight: "Weight",
    measurements: "Measurements",
    measurement_table: "Measurement table",
    help_center: "Help Center",
    need_help: "Need help",
    enter_article_name: "Enter article name",
    my_cart: "My cart",
    style_number: "Style number",
    color_and_print_overview: "Color and print overview",
    element: "Element",
    print: "Print",
    color_in_stock_for_this_fabric: "Color in stock for this fabric",
    out_of_stock: "Out of stock",
    dyed_for_both_sides: "Dyed both sides",
    color_picker: "Color picker",
    sublimated_one_side: "Sublimated one side",
    upload_your_artwork: "Upload your artwork",
    drag_a_file_here: "Drag a file here",
    or: "Or",
    select_from_computer: "Select from your computer",
    scale: "Scale",
    rotate: "Rotate",
    offset_x: "Offset X",
    offset_y: "Offset Y",
    add_your_own_text: "Add your own text",
    enter_your_text: "Enter your text",
    text_overview: "Text overview",
    add: "Add",
    add_your_own_logo: "Add your own logo",
    customize_care_label: "Customize your care label",
    size_indication: "Size indication for illustration only",
    select_your_logo: "Select your logo",
    select_your_care_label: "Select your care label",
    left: "Left",
    right: "Right",
    side: "Side",
    front: "Front",
    three_quarter: "3/4",
    size_indication_note: "Note: Size indication for illustration only",
    back: "Back",
    upcharge: "Upcharge",
    no_upcharge: "No upcharge",
    select_printing_technique_to_see_color: "Select a printing technique to see available colors",
    choose_from_our_colors: "Choose from our colors",
    remove: "Remove",
    position: "Position",
    logo_overview: "Logo overview",
    printing_technique: "Printing technique",
    select_care_label_template: "Select a care label template",
    shipping_price_not_included: "Shipping price not included",
    shipping_cost_calculated_next_step: "Shipping Price will be calculated in the next step",
    save_and_continue: "Save & Continue",
    create_folder: "Create folder",
    add_to_cart: "Add to basket",
    delete: "Delete",
    review: "Review",
    need_sample: "Need sample",
    total_piece: "Total piece",
    collection: "Collection",
    season: "Season",
    discount: "Discount",
    minimum_quantity_50: "Cart list minimum quantity: 50 pieces",
    submitted: "Submitted",
    on_hold: "On Hold",
    production: "Production",
    shipped: "Shipped",
    your_orders: "Your orders",
    edit: "Edit",
    my_account: "My Account",
    phone_number: "Phone number",
    default_currency: "Default currency",
    default_language: "Default language",
    default_country: "Default country",
    vermarc_passport_number: "Vermarc Passport Number",
    vermarc_passport_number_placeholder: "Enter your passport number",
    saved_addresses: "Saved Addresses",
    new_address: "New Address",
    save: "Save",
    set_default_shipping: "Set default shipping",
    set_default_billing: "Set default billing",
    street_or_building: "Street or Building",
    postcode: "Postcode",
    city: "City",
    province: "Province",
    country: "Country",
    country_placeholder: "Select...",
    saved_designs: "Saved Designs",
    teamwear_quality_lines: "Teamwear Quality Lines",
    es_line_description: "The comfort ES.L line offers a unique Vermarc quality and comfort to meet the technical questions of cyclists of all levels and for the most diverse type of riders.",
    sp_line_description: "The premium SP.L line benefits of the immense professional testing and research of Vermarc’s premium collection and gives a unique proposition on performance and comfort.",
    pr_line_description: "The elite PR.R line takes more and more innovative, more ergonomic and lighter garments to meet the highest requirements of the professional riders.",
    category_football: "Football",
    category_cycling: "Cycling",
    category_athletics: "Athletics",
    filter_by: "Filter by",
    order_now: "Order Now",
    design_now: "Design Now",
    size: "Size",
    product_information: "Product Information",
    collections: "Collections",
    close: "Close",
    beCreative_add_to_cart_confirm: "You are one step closer to receiving your personalised products!",
    beCreative_add_to_cart_button: "Add To Basket",
    cancel: "Cancel",
    beCreative_cancel_button: "Cancel",
    beCreative_reset_button: "Reset",
    beCreative_color_fill: "Colour Fill",
    beCreative_color_a_zone: "Colour A Zone",
    beCreative_upload_button: "Upload",
    beCreative_upload_label: "Upload Image",
    beCreative_logo_list: "Image List",
    beCreative_place_logo: "Place Image",
    beCreative_text_input_label: "Text",
    beCreative_text_input_placeholder: "Enter text here",
    beCreative_add_text: "Add text",
    beCreative_apply_change_text: "Apply Change",
    beCreative_select_fonts: "Select fonts",
    beCreative_transform_label: "Transform & Control",
    beCreative_outline_size: "Outline Size",
    beCreative_line_spacing: "Line Spacing",
    beCreative_color: "Colour",
    beCreative_font_color: "Font Colour",
    beCreative_outline_color: "Outline Colour",
    beCreative_instruction_how_to_use: "How to use 3D",
    beCreative_instruction_rotate360: "Rotate 360",
    beCreative_instruction_rotate_mouse: "Left click + drag (Mouse)",
    beCreative_instruction_rotate_touch: "One finger + drag (Touch)",
    beCreative_instruction_zoom: "Zoom",
    beCreative_instruction_zoom_mouse: "Scroll the wheel (Mouse)",
    beCreative_instruction_zoom_touch: "Pinch out (Touch)",
    beCreative_instruction_move: "Move",
    beCreative_instruction_move_mouse: "Right click + drag (Mouse)",
    beCreative_instruction_move_touch: "Two finger + drag (Touch)",
    beCreative_instruction_close_button: "Close",
    beCreative_request_quote_address_club: "Address (team/club/company)",
    beCreative_request_quote_design_application: "Design application for personalised clothing",
    beCreative_request_quote_type_of_products: "Type of products",
    beCreative_request_quote_athletics: "Athletics",
    beCreative_request_quote_leisure: "Leisure",
    beCreative_request_quote_football: "Football (soccer)",
    beCreative_request_quote_triathlon: "Triathlon",
    beCreative_request_quote_cycling: "Cycling",
    beCreative_request_quote_type_of_clothing: "Type of cycling clothing (personalized): Product",
    beCreative_request_quote_jersey_short_sleeve: "Jersey short sleeve",
    beCreative_request_quote_jersey_long_sleeve: "Jersey long sleeve",
    beCreative_request_quote_mid_season_jacket: "Mid season jacket",
    beCreative_request_quote_technical_jacket: "Technical jacket",
    beCreative_request_quote_windstopper: "Windstopper / Kaos",
    beCreative_request_quote_rain_jacket: "Rain jacket",
    beCreative_request_quote_bib_shorts: "Bib shorts",
    beCreative_request_quote_tights: "Tights",
    beCreative_request_quote_quality_line: "Type of cycling clothing (personalised): Quality line",
    beCreative_request_quote_dont_know_yet: "Don't know yet",
    beCreative_request_quote_comments_questions: "Comments/Questions",
    send: "Send",
    sent: "Sent",
    please_fill_all_fields: "Please fill all required fields",
    teamstores: "Team Stores",
    already_in_cart: "Already in basket",
    same_supplier_warning: "You can only add products of the same supplier to the basket",
    go_to_order: "Go to order",
    select_delivery_mode: "SELECT DELIVERY MODE",
    pay_online_delivery_home: "Pay online, delivered to your home",
    pay_online_delivery_home_express: "Pay online, delivered to your home (Express)",
    pay_online_pickup: "Pay online, pick up in store",
    pay_now: "Pay Now",
    pay_retry: "Retry Payment",
    your_card_information: "Your card information",
    processing_payment: "Payment processing...",
    select_address: "Select Address",
    close_form: "Close Form",
    shipping_address: "Shipping address",
    billing_address: "Billing address",
    create_new_address: "Create a new address",
    no_address: "No address available",
    shipping_method: "Shipping method",
    invalid_address: "Invalid address. Please select another one.",
    incl_free: "incl. free",
    cart_not_found: "Card not found",
    no_payment_available_yet: "No payment available yet",
    confirm_payment: "Confirm Payment",
    credit_card: "Credit Card",
    credit_card_no: "Credit Card Number",
    purchase_order: "Purchase order",
    expiry_date: "Expiry Date",
    security_code: "Security Code",
    make_your_choice: "Make your choice",
    you_may_also_like: "You may also like",
    personalization: "Personalisation",
    i_have_read_and_have_approved_the_privacy_policy: "I HAVE READ AND HAVE APPROVED THE PRIVACY POLICY",
    shipping_cost_included: "Shipping cost included",
    shop_the_collection: "View the collection",
    same_as_shipping: "Same as shipping address",
    invoice_summary: "Invoice Summary",
    sub_total: "Sub-Total",
    shipping: "Shipping",
    cookie_settings: "Cookie settings",
    essential_cookies: "Essential Cookies",
    analytical_cookies: "Analytical Cookies",
    marketing_cookies: "Marketing Cookies",
    active: "Active",
    always_active: "Always active",
    cookie_settings_description: "Our website keeps three levels of cookies. You can adjust your preferences at any time. If you want more information about what cookies are and which cookies we collect, please read our cookie policy.",
    essential_cookies_description: "Functional cookies are essential cookies that ensure that de website functions properly and that your preferences (e.g. language, region) are saved.",
    analytical_cookies_description: "Analytical cookies allow us to analyse website use and to improve the visitor's experience.",
    marketing_cookies_description: "The aim of marketing cookies is to personalise your experience and to send you relevant content and offers, on this website and other websites.",
    agree: "Agree",
    cookie_notice: "This website uses cookies to improve your surfing experience. By clicking on 'Agree', you accept the use of these cookies.",
    firstName_name: "First Name",
    lastName_name: "Last Name",
    company_name: "Company name",
    email_name: "Email",
    phone_name: "Phone",
    vatNumber_name: "VAT No",
    address1_name: "Street/Building",
    zip_name: "ZIP/Postcode",
    city_name: "City",
    province_name: "State/Province",
    countryCode_name: "Country",
    firstName_placeholder: "First Name",
    lastName_placeholder: "Last Name",
    company_placeholder: "Company Name",
    email_placeholder: "Email",
    phone_placeholder: "Phone",
    vatNumber_placeholder: "VAT No",
    address1_placeholder: "Street/Building",
    zip_placeholder: "ZIP/Postcode",
    city_placeholder: "City",
    province_placeholder: "State/Province",
    countryCode_placeholder: "Country",
    message: "Message",
    studioOrderDetailStepHeadline: "Personalization",
    studioOrderDetailStepSelectQuantity: "Select the quantity",
    studioAddToCartStepAllDone: "All done",
    studioAddToCartStepOneStepCloser: "You are one step closer to receiving your personalised products!",
    studioMenuExpand: "Expand menu",
    studioMenuCollapse: "Collapse menu",
    studioSelectOption: "Select option",
    studioViewLink: "Link to view design",
    studioMyAccount: "My account",
    studioShare: "Share",
    studioTemplateStepHeadline: "Select a design",
    studioDoneButton: "Done",
    studioLayerControlTitle: "Layer control",
    studioMaterialStepHeadline: "Fabric",
    studioMaterialStepSelectMaterial: "Select your fabric",
    studioColorStepHeadline: "Color",
    studioColorStepSelectArea: "Select an area on the model or from the dropdown to fill:",
    studioColorStepReset: "Reset",
    studioPersonalizationStepPlacement: "Placement",
    studioPersonalizationStepSelectOption: "Select an option",
    studioPersonalizationStepPersonalizationHeadline: "Placeholder",
    studioPersonalizationStepPersonalizationSelectZone: "Select a zone",
    studioPersonalizationStepPersonalizationPrintingTechniques: "Techniques",
    studioPersonalizationStepPersonalizationMaximumItems: "Maximum items:",
    studioPersonalizationStepPlacedImages: "Placed images",
    studioPersonalizationStepPlaceholderPlacedText: "Placed texts",
    studioPersonalizationStepPlaceholderColorOptions: "Color options",
    studioTextHeadline: "Text",
    studioTextEnterText: "Enter text:",
    studioTextEditText: "Edit text:",
    studioTextPlaceText: "Place text",
    studioTextSelectAreaToPlaceText: "Select the area to place the text.",
    studioTextSelectAreaToPlaceImage: "Select the area to place the image.",
    studioTextRemove: "Remove",
    studioTextMirrorY: "Flip Horizontal",
    studioTextMirrorX: "Flip Vertical",
    studioTextDuplicate: "Duplicate",
    studioTextLock: "Lock",
    studioTextUnlock: "Unlock",
    studioTextVisible: "Visible",
    studioTextInvisible: "Invisible",
    studioTextCurrentlyPlaced: "Currently placed",
    studioTextNoTextPlacedYet: "No texts elements placed yet.",
    studioTextNoTextClickIn3D: "Click in the 3D",
    studioTextFontFamily: "Font family",
    studioTextFontSize: "Font size",
    studioTextScale: "Scale",
    studioTextRotate: "Rotate",
    studioTextOutlineSize: "Outline",
    studioTextCharSpacing: "Char spacing",
    studioTextSelectColor: "Select color",
    studioTextSelectOutlineColor: "Select Outline Colour",
    studioTextToggleGridHelper: "Toggle Grid Helper",
    studioImageStepHeadline: "Images",
    studioImageDragYourImagesOrClickToUpload: "Drag your images or click to upload",
    studioImageUploadSupportFiles: "(JPG, SVG, AI, PDF, EPS)",
    studioImageImageLibrary: "Image library",
    studioImageUploadedImages: "Uploaded Images",
    studioImageNoImagesUploaded: "No images uploaded",
    studioImageNoImagesPlaced: "No images placed",
    studioImageScale: "Scale",
    studioImageRemove: "Remove",
    studioImageMirrorY: "Flip Horizontal",
    studioImageMirrorX: "Flip Vertical",
    studioImageDuplicate: "Duplicate",
    studioImageLock: "Lock",
    studioImageUnlock: "Unlock",
    studioImageInvisible: "Invisible",
    studioImageVisible: "Visible",
    studioImageRotate: "Rotate",
    studioImagePlaceImage: "Place Image",
    studioImageImagesPlaced: "Images placed",
    studioImageToggleGridHelper: "Toggle Grid Helper",
    studioToggleGridHelperDescription: "Grid helper lines will be visible when you move the object on modal",
    studioPatternFillHeadline: "Add a print",
    studioPatternFillSelectAnAreaOnTheModelOrFromTheDropdownToFill: "Select an area on the model or from the dropdown to fill:",
    studioPatternFillUploadAPrint: "Upload a print",
    studioPatternFillSelectAPrint: "Select a print",
    studioPatternFillUsedPrint: "Used print",
    studioPatternFillUploadedPrints: "Uploaded prints",
    studioPatternFillNoPrintUploaded: "No print uploaded",
    studioPatternFillMoveHorizontally: "Move horizontally",
    studioPatternFillMoveVertically: "Move vertically",
    studioPatternFillScale: "Scale",
    studioPatternFillRotate: "Rotate",
    studioTitlePrintingTechnique: "Printing Techniques",
    search: "Search",
    unable_to_load_products: "Unable to load products...",
    no_result: "No Result...",
    storefront_address_warning: "Please select an address ",
    storefront_card_empty_warning: "Please enter a credit card number",
    storefront_invalid_card_warning: "Invalid credit card",
    scan_qr_code_to_pay: "Scan the QR code to pay",
    payment_failed: "Payment failed",
    redirecting: "Redirecting...",
    loading: "Loading",
    confirm_email: "Confirm Email",
    view_order_detail: "View Order Details",
    create_new_revision: "Create New Revision",
    done: "Done",
    new_password: "New Password",
    repeat_new_password: "Repeat New Password",
    set_new_password: "Set New Password",
    checkout: "Checkout",
    gst_tax: "GST",
    qst_tax: "QST",
    pst_tax: "PST",
    quote: "Quote",
    quote_comments: "Quote comments",
    confirm: "Confirm",
    signup: "Sign up",
    password_change_successful: "Password changed successfully",
    verify_account: "Verify your account",
    already_have_an_account: "Already have an account?",
    teamstore_not_open: "This teamstore hasn't been opened yet.",
    teamstore_closed: "This teamstore is already closed.",
    cart_empty: "Cart is empty",
    not_available: "Not available",
    product_in_cart_not_available: "Some product(s) in the cart is not available",
    contact_person: "Contact person",
    available_from: "Available from",
    available_until: "Available until",
    variant_not_selected_or_not_available: "Invalid cart",
    fit_width: "Fit width",
    fit_height: "Fit height",
    dragDropMultipleFiles: "Drag 'n' drop or upload your files here",
    dragDropSingleFile: "Drag 'n' drop or upload your file here",
    fileUpload: "Upload",
    fileUploadAll: "Upload all",
    designCoreUploadBoxPreviewHeader: "Edit the image",
    designCoreUploadBoxCustomizationSelectorPreview: "Preview",
    designCoreUploadBoxCustomizationSelectorCrop: "Crop",
    designCoreUploadBoxCustomizationSelectorRecolor: "Recolor",
    designCoreUploadBoxCustomizationSelectorInvert: "Invert",
    designCoreUploadBoxCustomizationSelectorRemoveBackground: "Remove Background",
    file_download_confirmation: "If the file is not downloading. Please click",
    here: "here",
    renderingMode: "Rendering mode",
    realisticRender: "Realistic render",
    renderInHighQuality: "Render in High Quality",
    untitled: "Untitled",
    image: "Image"
};
export var nl_NL = _object_spread_props(_object_spread({}, en_UK), {
    studioImageScale: "Schalen",
    studioImageUploadedImages: "Upload afbeelding",
    dragDropMultipleFiles: "Slepen en plaats, of upload uw bestanden hier",
    dragDropSingleFile: "Sleep 'n' drop of upload je bestand hier",
    width: "Breedte",
    height: "Hoogte",
    rotation: "Rotatie",
    orderDetailBulkOrder: "Bulk bestelling",
    orderDetailSelectMethod: "Selecteer uw methode",
    studioTemplateStepHeadline: "Sjablonen",
    studioTitlePrintingTechnique: "Druk techniek",
    studioImageOpacity: "ondoorzichtigheid",
    studioTextOpacity: "ondoorzichtigheid",
    copyright: "auteursrechten",
    cart: "Wagen",
    account: "Account",
    more_product_info: "Meer productinformatie",
    studio_my_design_title: "My Designs",
    studio_my_design_copylink: "Copy link",
    studio_my_design_copiedLink: "Copied",
    studio_my_design_description: "Select a saved design to load into the studio",
    studio_my_design_createdAt: "Created:",
    studio_my_design_loadmore: "Load More Designs",
    studio_load_design: "Load",
    request_for_quote: "Hulp nodig bij ontwerpen",
    processing_payments: "Please wait while we process your request...",
    size_chart: "Size Chart",
    studioTextInputPlaceholder: "Enter text here",
    to_total: "to total",
    warning: "Warning",
    coupon_removed: "Coupon Removed",
    sent_successfully: "Sent successfully",
    free_product_applied_to_cart: "",
    is_billing_default: "Is factuurinstelling",
    set_as_billing_default: "Stel in als factuur",
    is_shipping_default: "Is verzending",
    set_as_shipping_default: "Set als verzending",
    contact_us: "Neem contact op",
    on_item: "op product",
    code: "Code",
    applied: "Toegepast",
    promocode_not_found: "Promo Code niet gevonden",
    select: "Selecteer",
    must_not_be_empty: "moet ingevuld worden",
    must_be_selected: "moet geselecteerd worden",
    is_not_valid: "is niet correct",
    open_link_below_to_complete_payment: "Open onderstaande link om de betaling te vervolledigen",
    terms_and_condition: "Algemene Voorwaarden",
    I_agree_with_the: "Ik ga akkoord met de",
    and_the: "en de",
    privacy_policy: "privacy policy",
    of: "van",
    vermarc_sport: "Vermarc Sport",
    complete_payment: "Vervolledig de betaling",
    off: "",
    promo: "Promo",
    missing_payment_method: "Betaalmethode ontbreekt",
    unsupported_payment_propvider: "Betaaldienst niet ondersteund",
    pay: "Betaal",
    card: "Kaart",
    bank_transfer: "Overschrijving",
    //payment provider
    ideal: "IDEAL",
    bancontact: "Bancontact",
    sofort: "Sofort",
    stripe: "Stripe",
    stripe_connect: "Stripe Connect",
    omise: "Omise",
    promptpay: "Prompt Pay",
    cash: "Cash",
    cash_on_delivery: "Cash bij levering",
    external: "EXTERN",
    paid: "BETAALD",
    refuned: "TERUG GESTORT",
    shipping_status: "Verzendstatus",
    pending: "BEZIG",
    failed: "MISLUKT",
    delivered: "ONTVANGEN",
    cancelled: "GEANNULEERD",
    delivery_provider: "Aanbieder van bezorging",
    product_name: "Productnaam",
    item: "Product",
    qty: "Hoeveelheid",
    order_no: "Ordernummer",
    back_to_order: "Terug naar orders",
    placed_on: "Besteld op",
    payment_status: "Betaling",
    shipping_to: "Verzendadres",
    billing_to: "Factuuradres",
    contact_info: "Contact Info",
    order_creation_failed: "Plaatsen van order mislukt",
    remainChar: "Overgebleven karakters",
    studioMissingPersonalizationConfig: "Ontbrekende configuratie van personalisatiegroep",
    apply: "Toepassen",
    all_done: "Ben je klaar",
    payment_redirect_note: "Als u niet wordt doorgestuurd, kan u de betaling voltooien door op de volgende link te klikken:",
    shopping_cart: "MIJN WINKELMANDJE",
    invalid_cart: "Ongeldig winkelmandje",
    invalid_partner_number: "Ongeldig vennootnummer",
    parner_number_used: "Vennootnummer is reeds gebruikt",
    product: "Product",
    quantity: "Aantal",
    register: "REGISTREER NU",
    tell_us_who_you_are: "IK BEN EEN",
    sport_club: "Sportclub",
    company: "Bedrijf",
    private_person: "Particulier persoon",
    selected_specific: "Bedrijf / Sportclub / Personeelsnummer",
    enter_company_name: "Bedrijf / Sportclub / Personeelsnummer",
    company_information: "Bedrijfsinformatie",
    enter_sport_club_name: "Bedrijf / Sportclub / Personeelsnummer",
    vat_number: "BTW Nummer",
    example_number: "e.g. 1234567890",
    personal_information: "Persoonlijke informatie",
    first_name: "Voornaam",
    first_name_placeholder: "Voornaam",
    last_name: "Achternaam",
    last_name_placeholder: "Naam",
    repeat_email: "Herhaal je e-mail",
    activate_passport: "VOORDELENKAART ACTIVEREN?",
    passport_number: "KAARTNUMMER:",
    passport_card_number_placeholder: "Kaartnummer",
    sign_up: "Registeren",
    signup: "Registeren",
    price: "Prijs",
    continue_shopping: "Verder winkelen",
    checkout_now: "Bestellen",
    total: "Totaal",
    vat_included: "incl. BTW",
    nothing_in_your_cart: "Uw winkelmandje is leeg",
    validate: "Korting toevoegen",
    description: "Omschrijving",
    vermarc_cart_service_title: "ONZE SERVICE, UW VOORDEEL",
    vermarc_cart_service_description: "De ultieme testen van sport professionals en de uiterste zorg voor onze producten, zijn de garantie voor kwaliteit van Vermarc. Mocht je vragen hebben, doe gerust een beroep op onze kennis en ervaring. We helpen je graag verder. De Vermarc garanties:",
    vermarc_cart_service_item_1: "Kwaliteitsgarantie",
    vermarc_cart_service_item_2: "Optimale klantenservice garantie",
    vermarc_cart_service_item_3: "Premium dealernetwerk in Benelux",
    vermarc_cart_service_item_4: "Niet tevreden, ruilgarantie of geld terug",
    coupon_code: "COUPON CODE TOEVOEGEN:",
    delivery_prices: "VERZENDKOSTEN",
    payment_options: "VEILIG BETALEN",
    bank_account_country: "Bankrekening - Land",
    payment_method: "Betaalmethode",
    belgium_and_netherlands: "Belgi\xeb, Nederland en Luxemburg",
    benelux: "Belgi\xeb, Nederland en Luxemburg",
    from: "vanaf",
    within_the_eu: "Binnen Europa",
    outside_the_eu: "Buiten Europa",
    learn_more: "Meer over onze algemene voorwaarden",
    free: "gratis.",
    email: "Email",
    password: "Wachtwoord",
    confirm_password: "Bevestig Wachtwoord",
    password_placeholder: "Min. 8 tekens, 1 kleine letter, 1 grote letter, 1 speciaal teken",
    enter_email: "Voer je emailadres in",
    enter_password: "Voer je wachtwoord in",
    forgot_password: "Wachtwoord vergeten",
    reset_password: "Wachtwoord opnieuw instellen",
    password_reset_required: "Uw account vereist een wachtwoordreset. Om te resetten klik op de onderstaande knop.",
    log_in: "Aanmelden",
    no_account_yet: "Heb je nog geen account? Registreer nu!",
    user_does_not_exist: "Gebruiker bestaat niet",
    premium_dealers_title: "PREMIUM DEALERS",
    premium_dealers_subtitle: "Vermarc Sport selecteert zijn verkooppunten zorgvuldig.",
    premium_dealers_belgium: "Belgie",
    premium_dealers_netherlands: "Nederland",
    phone: "Telefoon",
    my_orders: "Mijn Bestellingen",
    my_designs: "Mijn Ontwerpen",
    fabric: "Stof",
    color: "Kleur",
    text: "Tekst",
    logo: "Logo",
    label: "Label",
    login: "AANMELDEN",
    care_label: "Wasvoorschrift",
    choose_your_fabric: "Kies je stof",
    interlock: "Interlock",
    mesh: "Mesh",
    woven: "Geweven",
    cotton: "Katoen",
    fabric_overview: "Stoffenoverzicht",
    group: "Groep",
    construction: "Structuur",
    content: "Inhoud",
    weight: "Gewicht",
    measurements: "Maten",
    measurement_table: "Matentabel",
    help_center: "Help Center",
    need_help: "Hulp nodig?",
    enter_article_name: "Voer het artikelnaam in",
    my_cart: "Mijn winkelmandje",
    style_number: "Productnummer",
    color_and_print_overview: "Kleur- en printoverzicht",
    element: "Element",
    print: "Print",
    color_in_stock_for_this_fabric: "Kleur op voorraad voor deze stof",
    out_of_stock: "Out of stock",
    dyed_for_both_sides: "Gekleurd aan beide kanten",
    color_picker: "Kleurenkiezer",
    sublimated_one_side: "Gesublimeerd aan \xe9\xe9n kant",
    upload_your_artwork: "Importeer je tekening",
    drag_a_file_here: "Sleep een bestand",
    or: "Of",
    select_from_computer: "Kies op je computer",
    scale: "Vergroten en verkleinen",
    rotate: "Draaien",
    offset_x: "Verschuif horizontaal",
    offset_y: "Verschuif vertikaal",
    add_your_own_text: "Voeg je tekst toe",
    enter_your_text: "Voer je tekst in",
    text_overview: "Tekstoverzicht",
    add: "Toevoegen",
    add_your_own_logo: "Voeg je logo toe",
    customize_care_label: "Ontwerp het wasvoorschrift",
    size_indication: "Maten zijn enkel ter illustratie",
    select_your_logo: "Kies je logo",
    select_your_care_label: "Kies het wasvoorschrift",
    left: "Links",
    right: "Rechts",
    side: "Zijde",
    front: "Voor",
    three_quarter: "3/4",
    size_indication_note: "Maten zijn enkel ter illustratie",
    back: "Terug",
    upcharge: "Upcharge",
    no_upcharge: "Geen upcharge",
    select_printing_technique_to_see_color: "Kies een printtechniek om de kleuren te zien",
    choose_from_our_colors: "Kies uit onze kleuren",
    remove: "Verwijder",
    position: "Plaats",
    logo_overview: "Logo overzicht",
    printing_technique: "Printtechniek",
    select_care_label_template: "Kies het wasvoorschrift",
    shipping_price_not_included: "Verzendkosten niet inbegrepen",
    shipping_cost_calculated_next_step: "Verzendingskosten worden berekend in de volgende stap",
    save_and_continue: "Bewaar en ga verder",
    create_folder: "Maak een map aan",
    add_to_cart: "Toevoegen aan winkelmand",
    delete: "Verwijder",
    review: "Nakijken",
    need_sample: "Sample nodig",
    total_piece: "Totaal aantal stuks",
    collection: "Collectie",
    season: "Seizoen",
    discount: "Korting",
    minimum_quantity_50: "Mininumhoeveelheid: 50 stuks",
    submitted: "Besteld",
    on_hold: "Nakijken",
    production: "Productie",
    shipped: "Verzonden",
    your_orders: "Mijn bestellingen",
    edit: "Aanpassen",
    my_account: "Mijn Account",
    phone_number: "Telefoon",
    default_currency: "Standaardmunt",
    default_language: "Standaardtaal",
    default_country: "Standaardland",
    vermarc_passport_number: "Vermarc Kaartnummer",
    vermarc_passport_number_placeholder: "Voer je kaartnummer in",
    saved_addresses: "Bewaarde adressen",
    new_address: "Nieuw adres",
    save: "Bewaar",
    set_default_shipping: "Kies je standaard verzendingsadres",
    set_default_billing: "Kies je standaard facturatieadres",
    street_or_building: "Straat",
    postcode: "Postcode",
    city: "Stad",
    close: "Sluiten",
    province: "Provincie",
    country: "Land",
    country_placeholder: "Kies...",
    saved_designs: "Bewaarde ontwerpen",
    teamwear_quality_lines: "Kwaliteitslijnen",
    es_line_description: "De comfort lijn ES.L biedt een unieke Vermarc kwaliteit en comfort en komt tegemoet aan de technische vragen van wielrenners op alle niveaus en voor de meest uiteenlopende type renners.",
    sp_line_description: "De premium SP.L lijn profiteert mee van de immense professionele testen en research van Vermarc's premium collectie en geeft een unieke propositie op prestatie en comfort.",
    pr_line_description: "De elite PR.R lijn brengt steeds meer innoverende, meer ergonomische en lichtere kledij die telkens opnieuw tegemoet komt aan de hoogste vereisten van de professionele renners.",
    category_football: "Voetbal",
    category_cycling: "Fietsen",
    category_athletics: "Atletiek",
    filter_by: "Filteren op",
    order_now: "Nu online kopen",
    design_now: "Nu ontwerpen",
    size: "Maat",
    product_information: "PRODUCTINFORMATIE",
    collections: "Collecties",
    beCreative_add_to_cart_confirm: "Weet u zeker dat u dit artikel aan uw winkelwagen wilt toevoegen?",
    beCreative_add_to_cart_button: "Voeg toe aan winkelmandje",
    cancel: "Annuleer",
    beCreative_cancel_button: "Annuleer",
    beCreative_reset_button: "Reset",
    beCreative_color_fill: "Kleuren",
    beCreative_color_a_zone: "Kleur een zone in",
    beCreative_upload_button: "Opladen",
    beCreative_upload_label: "Importeer je logo",
    beCreative_logo_list: "Lijst van logos",
    beCreative_place_logo: "Plaats je logo",
    beCreative_text_input_label: "Tekst",
    beCreative_text_input_placeholder: "Voer je tekst in",
    beCreative_add_text: "Voeg tekst toe",
    beCreative_apply_change_text: "Verandering toepassen",
    beCreative_select_fonts: "Selecteer lettertype",
    beCreative_transform_label: "Transformeren en beheren",
    beCreative_outline_size: "Contourgrootte",
    beCreative_line_spacing: "Letterafstand",
    beCreative_color: "Kleur",
    beCreative_font_color: "Kleur lettertype",
    beCreative_outline_color: "Kleur contour",
    beCreative_instruction_how_to_use: "Hoe 3D te gebruiken",
    beCreative_instruction_rotate360: "Draai 360",
    beCreative_instruction_rotate_mouse: "Linkerklik en versleep (Muis)",
    beCreative_instruction_rotate_touch: "Klik en versleep met \xe9\xe9n vinger (Touch)",
    beCreative_instruction_zoom: "Zoom",
    beCreative_instruction_zoom_mouse: "Scrollwiel (Muis)",
    beCreative_instruction_zoom_touch: "Beweeg twee vingers uit elkaar (Touch)",
    beCreative_instruction_move: "Verplaats",
    beCreative_instruction_move_mouse: "Rechterklik en versleep (Muis)",
    beCreative_instruction_move_touch: "Klik en versleep met twee vingers (Touch)",
    beCreative_instruction_close_button: "Sluiten",
    beCreative_request_quote_address_club: "Adres (club/team/bedrijf)",
    beCreative_request_quote_design_application: "Ontwerpaanvraag voor gepersonaliseerde kledij",
    beCreative_request_quote_type_of_products: "Type producten",
    beCreative_request_quote_athletics: "Atletiek",
    beCreative_request_quote_leisure: "Vrije tijd",
    beCreative_request_quote_football: "Voetbal",
    beCreative_request_quote_triathlon: "Triatlon",
    beCreative_request_quote_cycling: "Fietsen",
    beCreative_request_quote_type_of_clothing: "Type fietskleding (gepersonaliseerd): Product",
    beCreative_request_quote_jersey_short_sleeve: "Fietstrui korte mouwen",
    beCreative_request_quote_jersey_long_sleeve: "Fietstrui lange mouwen",
    beCreative_request_quote_mid_season_jacket: "Tussenseizoen vest",
    beCreative_request_quote_technical_jacket: "Technical vest",
    beCreative_request_quote_windstopper: "Windstopper / Kaos",
    beCreative_request_quote_rain_jacket: "Regenvest",
    beCreative_request_quote_bib_shorts: "Korte fietsbroek",
    beCreative_request_quote_tights: "Lange fietsbroek",
    beCreative_request_quote_quality_line: "Type fietskleding (gepersonaliseerd): Designlijn",
    beCreative_request_quote_dont_know_yet: "Nog geen keuze gemaakt",
    beCreative_request_quote_comments_questions: "Vragen/Opmerkingen",
    send: "Verzenden",
    sent: "Verzonden",
    please_fill_all_fields: "Gelieve alle velden in te vullen",
    teamstores: "Club Shops",
    already_in_cart: "Reeds in winkelmandje",
    same_supplier_warning: "U kunt alleen producten van dezelfde leverancier aan het winkelwagentje toevoegen",
    select_address: "Kies jouw adres",
    create_new_address: "Maak een nieuw adres aan",
    no_address: "Geen adres beschikbaar",
    close_form: "Close Form",
    shipping_address: "Verzend adres",
    shipping_method: "Verzendmethode",
    billing_address: "Facturatie adres",
    invalid_address: "Ongeldig adres. Selecteer een andere.",
    incl_free: "incl. gratis",
    cart_not_found: "Kaart niet gevonden",
    no_payment_available_yet: "Nog geen betaling beschikbaar",
    confirm_payment: "Bevestig betaling",
    credit_card: "Kredietkaart",
    credit_card_no: "Kredietkaartnummer",
    expiry_date: "Vervaldatum",
    security_code: "Verificatiecode",
    go_to_order: "Bestel nu",
    select_delivery_mode: "SELECTEER LEVERINGSMETHODE",
    pay_online_delivery_home: "Online betalen, thuis geleverd",
    pay_online_delivery_home_express: "Online betalen, thuis geleverd (Express)",
    pay_online_pickup: "Online betalen, ophalen in winkel",
    pay_now: "Nu betalen",
    pay_retry: "Nu betalen",
    your_card_information: "Kaartgegevens",
    processing_payment: "Verwerken van betaling...",
    make_your_choice: "Maak uw keuze",
    you_may_also_like: "Misschien wilt u ook deze producten",
    personalization: "Personalisatie",
    i_have_read_and_have_approved_the_privacy_policy: "IK VERKLAAR DAT IK DE PRIVACY POLICY HEB GELEZEN EN GOEDGEKEURD.",
    shipping_cost_included: "Verzendkosten inbegrepen",
    shop_the_collection: "Bekijk de collectie",
    same_as_shipping: "Hetzelfde als het verzendadres",
    invoice_summary: "Factuuroverzicht",
    sub_total: "Subtotaal",
    shipping: "Verzending",
    cookie_settings: "Cookie instellingen",
    essential_cookies: "Essenti\xeble Cookies",
    analytical_cookies: "Analytische cookies",
    marketing_cookies: "Marketing cookies",
    active: "Actief",
    always_active: "Altijd actief",
    cookie_settings_description: "Onze website heeft drie niveaus van cookies. U kunt uw voorkeuren op elk gewenst moment aanpassen. Als u meer informatie wilt over wat cookies zijn en welke cookies we verzamelen, lees dan ons cookiebeleid.",
    essential_cookies_description: "Functionele cookies zijn essenti\xeble cookies die ervoor zorgen dat de website naar behoren functioneert en dat uw voorkeuren (bijv. Taal, regio) worden opgeslagen.",
    analytical_cookies_description: "Met analytische cookies kunnen we het gebruik van de website analyseren en de ervaring van de bezoeker verbeteren.",
    marketing_cookies_description: "Het doel van marketingcookies is om uw ervaring te personaliseren en u relevante inhoud en aanbiedingen te sturen, op deze website en andere websites.",
    agree: "Akkoord",
    cookie_notice: "Deze website maakt gebruik van cookies om uw surfervaring te verbeteren. Door op Akkoord te klikken, accepteert u het gebruik van deze cookies.",
    firstName_name: "Voornaam",
    lastName_name: "Achternaam",
    company_name: "Bedrijfsnaam",
    email_name: "Email",
    phone_name: "Telefoon",
    vatNumber_name: "BTW Nummer",
    address1_name: "Straat/Huisnummer",
    zip_name: "Postcode",
    city_name: "Stad",
    province_name: "Provincie",
    countryCode_name: "Land",
    firstName_placeholder: "Voornaam",
    lastName_placeholder: "Achternaam",
    company_placeholder: "Bedrijfsnaam",
    email_placeholder: "Email",
    phone_placeholder: "Telefoon",
    vatNumber_placeholder: "BTW Nummer",
    address1_placeholder: "Straat/Huisnummer",
    zip_placeholder: "Postcode",
    city_placeholder: "Stad",
    province_placeholder: "Provincie",
    countryCode_placeholder: "Land",
    message: "Bericht",
    studioOrderDetailStepHeadline: "Personalisatie",
    studioOrderDetailStepSelectQuantity: "Selecteer de hoeveelheid",
    studioAddToCartStepAllDone: "Ben je klaar?",
    studioAddToCartStepOneStepCloser: "Je bent een stap dichter bij het ontvangen van uw gepersonaliseerde producten!",
    studioMenuExpand: "Expand menu",
    studioMenuCollapse: "Collapse menu",
    studioSelectOption: "Select option",
    studioViewLink: "Link to view design",
    studioMyAccount: "My account",
    studioShare: "Share",
    studioDoneButton: "Klaar",
    studioMaterialStepHeadline: "Materialen",
    studioMaterialStepSelectMaterial: "Selecteer je materiaal",
    studioColorStepHeadline: "Inkleuren",
    studioColorStepSelectArea: "Selecteer een gebied op het product of in de drop down om in te kleuren:",
    studioColorStepReset: "Reset",
    studioPersonalizationStepPlacement: "Plaatsen",
    studioPersonalizationStepSelectOption: "Selecteer een optie",
    studioPersonalizationStepPersonalizationHeadline: "Personalisatie",
    studioPersonalizationStepPersonalizationSelectZone: "Selecteer een gebied",
    studioPersonalizationStepPersonalizationPrintingTechniques: "Printing toepassing",
    studioPersonalizationStepPersonalizationMaximumItems: "Maximum aantal producten:",
    studioPersonalizationStepPlacedImages: "Placed images",
    studioTextHeadline: "Tekst",
    studioTextEnterText: "Tekst invoeren",
    studioTextEditText: "Tekst bewerken",
    studioTextPlaceText: "Tekst plaatsen",
    studioTextSelectAreaToPlaceText: "Select the area to place the text.",
    studioTextRemove: "Remove",
    studioTextMirrorY: "Verticaal spiegelen",
    studioTextMirrorX: "Horizontaal spiegelen",
    studioTextDuplicate: "Kopieren",
    studioTextLock: "Vergrendel",
    studioTextUnlock: "Ontgrendel",
    studioTextVisible: "Zichtbaar",
    studioTextInvisible: "Onzichtbaar",
    studioTextCurrentlyPlaced: "In gebruik",
    studioTextNoTextPlacedYet: "Nog geen tekstelementen geplaatst.",
    studioTextNoTextClickIn3D: "Klik in het 3D model",
    studioTextFontSize: "Lettertype grootte",
    studioTextFontFamily: "Lettertype",
    studioTextScale: "Vergroten",
    studioTextRotate: "Draaien",
    studioTextOutlineSize: "Omlijning",
    studioTextCharSpacing: "Ruimte tussen tekens",
    studioTextSelectColor: "Selecteer kleur",
    studioTextSelectOutlineColor: "Selecteer kleur omlijning",
    studioImageStepHeadline: "Afbeeldingen",
    studioImageDragYourImagesOrClickToUpload: "Sleep je afbeeldingen of selecteer om op te laden",
    studioImageUploadSupportFiles: "(JPG, SVG, AI, PDF, EPS)",
    studioImageImageLibrary: "Afbeeldingen",
    studioImageNoImagesUploaded: "Geen afbeeldingen opgelaad",
    studioImageRemove: "Verwijderen",
    studioImageMirrorY: "Spiegel verticaal",
    studioImageMirrorX: "Spiegel horizontaal",
    studioImageDuplicate: "Kopieren",
    studioImageLock: "Vergrendel",
    studioImageUnlock: "Ontgrendel",
    studioImageNoImagesPlaced: "Geen afbeeldingen geplaatst",
    studioImagePlaceImage: "Plaats ‘afbeelding",
    studioImageVisible: "Zichtbaar",
    studioImageInvisible: "Onzichtbaar",
    studioImageRotate: "Draaien",
    studioImageImagesPlaced: "Afbeeldingen geplaatst",
    studioPatternFillHeadline: "Patroonvulling",
    studioPatternFillSelectAnAreaOnTheModelOrFromTheDropdownToFill: "Selecteer een gebied op het product of in de drop down om in te kleuren:",
    studioPatternFillUploadAPrint: "Print opladen",
    studioPatternFillSelectAPrint: "Selecteer een print",
    studioPatternFillUsedPrint: "Used print",
    studioPatternFillUploadedPrints: "Beschikbare prints",
    studioPatternFillNoPrintUploaded: "Geen print opgelaad",
    studioPatternFillMoveHorizontally: "Verschuif horizontaal",
    studioPatternFillMoveVertically: "Verschuif verticaal",
    studioPatternFillScale: "Vergroten",
    studioPatternFillRotate: "Draaien",
    orderDetailDescription: "U kunt de order details 1 voor 1 erin zetten of kopieer uw bulk order in 1 keer in ons systeem",
    orderDetailManualOrder: "Handmatig order invoeren",
    search: "Zoeken",
    unable_to_load_products: "Kan producten niet laden...",
    no_result: "Geen resultaat...",
    storefront_address_warning: "Selecteer een adres ",
    storefront_card_empty_warning: "Voer een creditcardnummer in",
    storefront_invalid_card_warning: "Ongeldige credit card",
    scan_qr_code_to_pay: "Scan the QR code to pay",
    payment_failed: "Payment failed",
    redirecting: "Redirecting...",
    loading: "Loading",
    confirm_email: "Confirm Email",
    view_order_detail: "View Order Details",
    create_new_revision: "Create New Revision",
    done: "Done",
    new_password: "New Password",
    repeat_new_password: "Repeat New Password",
    set_new_password: "Set New Password",
    checkout: "Checkout",
    gst_tax: "GST",
    qst_tax: "QST",
    pst_tax: "PST",
    quote: "Quote",
    quote_comments: "Quote comments",
    confirm: "Confirm",
    password_change_successful: "Password changed successfully",
    verify_account: "Verify your account",
    already_have_an_account: "Heb je al een account?",
    teamstore_not_open: "This teamstore hasn't been opened yet.",
    teamstore_closed: "This teamstore is already closed.",
    cart_empty: "Cart is empty",
    not_available: "Not available",
    product_in_cart_not_available: "Some product(s) in the cart is not available",
    contact_person: "Contact person",
    available_from: "Available from",
    available_until: "Available until",
    variant_not_selected_or_not_available: "Ongeldig winkelmandje",
    fit_width: "Fit width",
    fit_height: "Fit height",
    storefront_select_shipping_methods: "Selecteer verzendmethode",
    total_tax: "Totaal BTW",
    amount: "Prijs",
    vat: "BTW"
});
export var fr_FR = _object_spread_props(_object_spread({}, en_UK), {
    studioStepPersonalization: "Espace r\xe9serv\xe9",
    studioPersonalizationStepPersonalizationHeadline: "Espace r\xe9serv\xe9",
    studioColorStepHeadline: "Choisissez une couleur",
    studioPatternFillHeadline: "Choisissez un motif",
    studioTemplateStepHeadline: "Choisissez un mod\xe8le",
    studioImageOpacity: "opacit\xe9",
    studioTextOpacity: "opacit\xe9",
    vat: "T.V.A.",
    amount: "Montant",
    copyright: "droits d'auteur",
    cart: "Chariot",
    account: "Compte",
    more_product_info: "Plus d'informations sur le produit",
    studio_my_design_title: "My Designs",
    studio_my_design_copylink: "Copy link",
    studio_my_design_copiedLink: "Copied",
    studio_my_design_description: "Select a saved design to load into the studio",
    studio_my_design_createdAt: "Created:",
    studio_my_design_loadmore: "Load More Designs",
    studio_load_design: "Load",
    is_billing_default: "Est la facturation par d\xe9faut",
    set_as_billing_default: "D\xe9finir comme d\xe9faut de facturation",
    is_shipping_default: "Est-ce que l'exp\xe9dition par d\xe9faut",
    set_as_shipping_default: "D\xe9finir par d\xe9faut d'exp\xe9dition",
    contact_us: "Nous contacter",
    studioTextInputPlaceholder: "Inscrivez votre texte",
    done: "Pr\xeat",
    sent_successfully: "bien envoy\xe9",
    warning: "l' avertissement",
    free_product_applied_to_cart: "Produit gratuit appliqu\xe9 au panier",
    coupon_removed: "Coupon supprim\xe9",
    on_item: "sur l'article",
    to_total: "au total",
    code: "Code",
    applied: "appliqu\xe9",
    promocode_not_found: "Le code promotionnel n'a pas \xe9t\xe9 trouv\xe9",
    select: "S\xe9lectionner",
    must_not_be_empty: "champ obligatoire",
    must_be_selected: "veuillez s\xe9lectionner",
    is_not_valid: "n'est pas valide",
    open_link_below_to_complete_payment: "Veuillez ouvrir le lien ci-dessous pour terminer le paiement",
    terms_and_condition: "Termes et Conditions",
    I_agree_with_the: "Je suis d'accord avec les",
    and_the: "et la",
    privacy_policy: "Politique de Confidentialit\xe9",
    of: "de",
    vermarc_sport: "Vermarc Sport",
    complete_payment: "Effectuer le paiement",
    off: "d\xe9sactiv\xe9",
    promo: "Promo",
    missing_payment_method: "Mode de paiement manquant",
    unsupported_payment_propvider: "Fournisseur de paiement non pris en charge",
    pay: "Paiement",
    card: "Carte",
    bank_transfer: "Virement",
    //payment provider
    ideal: "IDEAL",
    bancontact: "Bancontact",
    sofort: "Sofort",
    stripe: "Stripe",
    stripe_connect: "Stripe Connect",
    omise: "Omise",
    promptpay: "Paiement ponctuel",
    cash: "Esp\xe8ces",
    cash_on_delivery: "Paiement \xe0 la livraison",
    external: "Externe",
    paid: "Pay\xe9",
    refuned: "REMBOURS\xc9",
    shipping_status: "Statut de livraison",
    pending: "EN COURS",
    failed: "NON R\xc9USSI",
    delivered: "LIVR\xc9",
    cancelled: "ANNUL\xc9",
    delivery_provider: "Fournisseur de livraison",
    product_name: "Nom du produit",
    item: "Item",
    qty: "Quantit\xe9",
    order_no: "Num\xe9ro de la commande",
    back_to_order: "Retour aux commandes",
    placed_on: "Date de la commande",
    payment_status: "Pay\xe9",
    shipping_to: "Adresse de livraison",
    billing_to: "Adresse de facturation",
    contact_info: "Informations de contact",
    order_creation_failed: "Erreur de cr\xe9ation de la commande",
    remainChar: "Caract\xe8res restants",
    studioMissingPersonalizationConfig: "Configuration de groupe de personnalisation manquante",
    apply: "Appliquer",
    request_for_quote: "Demande de devis",
    processing_payments: "Veuillez patienter pendant que nous acc\xe9dons \xe0 votre requ\xeate ...",
    all_done: "All done?",
    payment_redirect_note: "Si vous n’\xeates pas redirig\xe9, veuillez effectuer le paiement en cliquant sur le lien suivant:",
    size_chart: "Tableau des tailles",
    shopping_cart: "MON PANIER",
    invalid_cart: "Panier invalide",
    invalid_partner_number: "Num\xe9ro associ\xe9s invalide",
    parner_number_used: "Le num\xe9ro associ\xe9s a d\xe9j\xe0 \xe9t\xe9 utilis\xe9",
    product: "Produit",
    quantity: "Quantit\xe9",
    register: "INSCRIVEZ-VOUS MAINTENANT",
    tell_us_who_you_are: "JE SUIS UN",
    sport_club: "Club de sport",
    company: "Soci\xe9t\xe9",
    private_person: "Particulier",
    selected_specific: "Soci\xe9t\xe9 / Club de sport / Num\xe9ro d'Employ\xe9",
    enter_company_name: "Soci\xe9t\xe9 / Club de sport / Num\xe9ro d'Employ\xe9",
    company_information: "Information d’entreprise",
    enter_sport_club_name: "Soci\xe9t\xe9 / Club de sport / Num\xe9ro d'Employ\xe9",
    vat_number: "TVA Num\xe9ro",
    example_number: "e.g. 1234567890",
    personal_information: "Informations personnelles",
    first_name: "Pr\xe9nom",
    first_name_placeholder: "Pr\xe9nom",
    last_name: "Nom",
    last_name_placeholder: "Nom",
    repeat_email: "R\xe9p\xe9tez votre email",
    activate_passport: "ACTIVER CARTE AVANTAGES?",
    passport_number: "NUM\xc9RO DE CARTE",
    passport_card_number_placeholder: "Num\xe9ro de carte",
    signup: "Registrer",
    sign_up: "Registrer",
    price: "Prix",
    continue_shopping: "Continuer vos achats",
    checkout_now: "Finaliser ma commande",
    total: "Total",
    vat_included: "TVA inclus",
    nothing_in_your_cart: "Votre panier d'achat est vide",
    validate: "Ajouter ristourne",
    description: "La description",
    vermarc_cart_service_title: "NOTRE SERVICE VOTRE AVANTAGE",
    vermarc_cart_service_description: "Le test ultime de sportifs professionnels sur le terrain et le plus grand soin pour nos produits, sont la garantie de qualit\xe9 de Vermarc. Si vous avez des questions, n’h\xe9sitez pas \xe0 faire appel \xe0 notre connaissance et exp\xe9rience. Nous serons heureux de vous aider. Les garanties Vermarc:",
    vermarc_cart_service_item_1: "Garantie de qualit\xe9",
    vermarc_cart_service_item_2: "Garantie service client\xe8le optimale",
    vermarc_cart_service_item_3: "R\xe9seau de revendeurs premium au Benelux",
    vermarc_cart_service_item_4: "Pas satisfait, garantie d’\xe9change ou remboursement",
    coupon_code: "AJOUTER CODE COUPON",
    delivery_prices: "FRAIS DE PORT",
    payment_options: "PAIEMENT S\xc9CURIS\xc9",
    bank_account_country: "Compte bancaire - Pays",
    payment_method: "Mode de paiement",
    belgium_and_netherlands: "Belgique, les Pays-Bas et Luxembourg",
    benelux: "Belgique, les Pays-Bas et Luxembourg",
    from: "\xe0 partir de",
    within_the_eu: "Dans l’UE",
    outside_the_eu: "En dehors de l’UE",
    learn_more: "En savoir plus sur nos conditions.",
    free: "gratuite.",
    email: "Email",
    password: "MOT DE PASSE",
    confirm_password: "CONFIRMEZ MOT DE PASSE",
    password_placeholder: "Min. 8 chars, 1 lowercase, 1 uppercase, 1 special char",
    enter_email: "Entrez votre adresse email",
    enter_password: "Entrez votre mot de passe",
    forgot_password: "Mot de passe oubli\xe9",
    reset_password: "R\xe9initialiser le mot de passe",
    password_reset_required: "Votre compte n\xe9cessite une r\xe9initialisation du mot de passe. Pour r\xe9initialiser, cliquez sur le bouton ci-dessous.",
    log_in: "Connecter",
    no_account_yet: "Vous n'avez pas encore de compte? S'inscrire maintenant!",
    user_does_not_exist: "L'utilisateur n'existe pas!",
    premium_dealers_title: "PREMIUM DEALERS",
    premium_dealers_subtitle: "Vermarc Sport choisit ses revendeurs soigneusement.",
    premium_dealers_belgium: "Belgique",
    premium_dealers_netherlands: "Pays-Bas",
    phone: "T\xe9l\xe9phone",
    my_orders: "Mes Commandes",
    my_designs: "Mes Dessins",
    fabric: "Le tissue",
    color: "La coleur",
    text: "Le texte",
    logo: "Le logo",
    label: "L'\xe9tiquette",
    login: "CONNECTER",
    care_label: "L'\xe9tiquette",
    choose_your_fabric: "Choisissez votre tissu",
    interlock: "Interlock",
    mesh: "Mesh",
    woven: "Tiss\xe9",
    cotton: "Coton",
    fabric_overview: "Aper\xe7u de tissu",
    group: "Le groupe",
    construction: "La construction",
    content: "Le contenue",
    weight: "Le poids",
    measurements: "Les mesures",
    measurement_table: "La table de mesure",
    help_center: "Besoin d'aide",
    need_help: "Besoin d'aide?",
    enter_article_name: "Entrez le nom de l'article",
    my_cart: "Mon panier",
    style_number: "Num\xe9ro de style",
    color_and_print_overview: "Aper\xe7u des couleurs et des impressions",
    element: "L'\xe9l\xe9ment",
    print: "L'impression",
    color_in_stock_for_this_fabric: "Couleur en stock pour ce tissu",
    out_of_stock: "Out of stock",
    dyed_for_both_sides: "Teint des deux c\xf4t\xe9s",
    color_picker: "Pipette \xe0 couleurs",
    sublimated_one_side: "Sublim\xe9 un c\xf4t\xe9",
    upload_your_artwork: "Upload your artwork",
    drag_a_file_here: "Drag a file here",
    or: "Ou",
    select_from_computer: "Select from your computer",
    scale: "Scale",
    rotate: "Tourner",
    offset_x: "D\xe9placer horizontalement",
    offset_y: "D\xe9placer verticalement",
    add_your_own_text: "Ajoutez votre propre texte",
    enter_your_text: "Entrez votre texte",
    text_overview: "Aper\xe7u du texte",
    add: "Ajouter",
    add_your_own_logo: "Ajoutez votre propre logo",
    customize_care_label: "Personnalisez votre \xe9tiquette d'entretien",
    size_indication: "Indication de taille pour illustration seulement",
    select_your_logo: "S\xe9lectionnez votre logo",
    select_your_care_label: "S\xe9lectionnez votre \xe9tiquette d'entretien",
    left: "Gauche",
    right: "Droite",
    side: "C\xf4t\xe9",
    front: "De face",
    three_quarter: "3/4",
    size_indication_note: "Remarque: Indication de taille pour illustration seulement",
    back: "Retour",
    upcharge: "Suppl\xe9ment",
    no_upcharge: "Pas de suppl\xe9ment",
    select_printing_technique_to_see_color: "S\xe9lectionnez une technique d'impression pour voir les couleurs disponibles",
    choose_from_our_colors: "Choisissez parmi nos couleurs",
    remove: "Effacer",
    position: "Placement",
    logo_overview: "Aper\xe7u du logo",
    printing_technique: "Technique d'impression",
    select_care_label_template: "S\xe9lectionnez un mod\xe8le d'\xe9tiquette d'entretien",
    shipping_price_not_included: "Prix d'exp\xe9dition non inclus",
    shipping_cost_calculated_next_step: "Calcul des frais de livraison \xe0 l'\xe9tape suivante",
    save_and_continue: "Sauvegarder et continuer",
    create_folder: "Cr\xe9er le dossier",
    add_to_cart: "Ajouter au panier",
    delete: "Effacer",
    review: "R\xe9viser",
    need_sample: "Besoin d'\xe9chantillon",
    total_piece: "Pi\xe8ce totale",
    collection: "Collection",
    season: "Saison",
    discount: "Remise",
    minimum_quantity_50: "Quantit\xe9 minimum: 50 pi\xe8ces",
    submitted: "Soumis",
    on_hold: "En attente",
    production: "Production",
    shipped: "Exp\xe9di\xe9",
    your_orders: "Mes commandes",
    edit: "\xc9diter",
    close: "Fermer",
    my_account: "Mon compte",
    phone_number: "Num\xe9ro de t\xe9l\xe9phone",
    default_currency: "Devise standard",
    default_language: "Langue standard",
    default_country: "Pays standard",
    vermarc_passport_number: "Num\xe9ro de carte",
    vermarc_passport_number_placeholder: "Entrez votre num\xe9ro de carte",
    saved_addresses: "Adresses sauvegard\xe9es",
    new_address: "Nouvelle adresse",
    save: "Sauvegarder",
    set_default_shipping: "Exp\xe9dition standard",
    set_default_billing: "Facturation standard",
    street_or_building: "Rue ou b\xe2timent",
    postcode: "Code postal",
    city: "Ville",
    province: "Province",
    country: "Pays",
    country_placeholder: "S\xe9lectionner...",
    saved_designs: "Dessins sauvegard\xe9s",
    teamwear_quality_lines: "Lignes De Qualit\xe9",
    es_line_description: "La ligne confort ES.L offre la qualit\xe9 et le confort uniques de Vermarc pour r\xe9pondre aux plus grand nombre de cyclistes de tous les niveaux.",
    sp_line_description: "La ligne premium SP.L profite des importants testes et recherches sur la collection \xe9lite de Vermarc et donne une proposition unique sur la performance et le confort.",
    pr_line_description: "La ligne \xe9lite PR.R propose un \xe9quipement plus innovateur, plus ergonomique et plus l\xe9ger pour r\xe9pondre aux plus hautes exigences des coureurs professionels.",
    category_football: "Football",
    category_cycling: "Cyclisme",
    category_athletics: "Athl\xe9tisme",
    filter_by: "Filtrer par",
    order_now: "Acheter en ligne maintenant",
    design_now: "Dessiner",
    size: "Taille",
    product_information: "INFORMATION PRODUIT",
    collections: "Collections",
    beCreative_add_to_cart_confirm: "\xcates-vous s\xfbr de vouloir ajouter cet article \xe0 votre panier?",
    beCreative_add_to_cart_button: "Ajouter au panier",
    cancel: "Annuler",
    beCreative_cancel_button: "Annuler",
    beCreative_reset_button: "R\xe9initialiser",
    beCreative_color_fill: "Couleurs",
    beCreative_color_a_zone: "Couleur une zone",
    beCreative_upload_button: "T\xe9l\xe9charger",
    beCreative_upload_label: "T\xe9l\xe9charger un logo",
    beCreative_logo_list: "Liste de logo",
    beCreative_place_logo: "Placez un logo",
    beCreative_text_input_label: "Texte",
    beCreative_text_input_placeholder: "Entrez le texte ici",
    beCreative_add_text: "Ajouter du texte",
    beCreative_apply_change_text: "Appliquer le changement",
    beCreative_select_fonts: "S\xe9lectionner les polices ",
    beCreative_transform_label: "Transformer et contr\xf4ler",
    beCreative_outline_size: "Taille du contour",
    beCreative_line_spacing: "Interligne",
    beCreative_color: "Couleur",
    beCreative_font_color: "Couleur de la police",
    beCreative_outline_color: "Couleur de contour",
    beCreative_instruction_how_to_use: "Comment utiliser la 3D",
    beCreative_instruction_rotate360: "Tourner \xe0 360",
    beCreative_instruction_rotate_mouse: "Left click + drag (Mouse)",
    beCreative_instruction_rotate_touch: "One finger + drag (Touch)",
    beCreative_instruction_zoom: "Zoom",
    beCreative_instruction_zoom_mouse: "Scroll the wheel (Mouse)",
    beCreative_instruction_zoom_touch: "Pinch out (Touch)",
    beCreative_instruction_move: "Move",
    beCreative_instruction_move_mouse: "Right click + drag (Mouse)",
    beCreative_instruction_move_touch: "Two finger + drag (Touch)",
    beCreative_instruction_close_button: "Fermer",
    beCreative_request_quote_address_club: "Adresse (club/enterprise)",
    beCreative_request_quote_design_application: "Application de conception de v\xeatements personnalis\xe9s",
    beCreative_request_quote_type_of_products: "Type de produits",
    beCreative_request_quote_athletics: "Athl\xe9tisme",
    beCreative_request_quote_leisure: "Loisir",
    beCreative_request_quote_football: "Football",
    beCreative_request_quote_triathlon: "Triathlon",
    beCreative_request_quote_cycling: "Cyclisme",
    beCreative_request_quote_type_of_clothing: "Type de v\xeatement cycliste (personnalis\xe9): Produit",
    beCreative_request_quote_jersey_short_sleeve: "Maillot manches courtes",
    beCreative_request_quote_jersey_long_sleeve: "Maillot manches longues",
    beCreative_request_quote_mid_season_jacket: "Veste mi-saison",
    beCreative_request_quote_technical_jacket: "Veste technical",
    beCreative_request_quote_windstopper: "Coupe-vent / Kaos",
    beCreative_request_quote_rain_jacket: "Imperm\xe9able",
    beCreative_request_quote_bib_shorts: "Cuissard",
    beCreative_request_quote_tights: "Collants",
    beCreative_request_quote_quality_line: "Type de v\xeatement cycliste (personnalis\xe9): Ligne de qualit\xe9",
    beCreative_request_quote_dont_know_yet: "Ne sais pas encore",
    beCreative_request_quote_comments_questions: "Commentaires/Questions",
    send: "Envoyez",
    sent: "Envoy\xe9",
    please_fill_all_fields: "Veuillez remplir tous le formulaire",
    teamstores: "Espace Clubs",
    already_in_cart: "D\xe9j\xe0 dans le panier",
    same_supplier_warning: "Vous pouvez uniquement ajouter des produits du m\xeame fournisseur au panier.",
    select_address: "Choisissez votre adresse",
    create_new_address: "Cr\xe9er une nouvelle adresse",
    no_address: "Aucune adresse disponible",
    close_form: "Fermer le formulaire",
    shipping_address: "Adresse de livraison",
    shipping_method: "Mode de livraison",
    billing_address: "Adresse de facturation",
    invalid_address: "Adresse invalide. Veuillez en choisir un autre.",
    incl_free: "y compris gratuit",
    cart_not_found: "Carte non trouv\xe9e",
    no_payment_available_yet: "Aucun paiement disponible pour le moment",
    confirm_payment: "Confirmer le paiement",
    credit_card: "Carte de cr\xe9dit",
    credit_card_no: "Num\xe9ro de carte de cr\xe9dit",
    expiry_date: "Date d'expiration",
    security_code: "Code de s\xe9curit\xe9",
    go_to_order: "Commandez maintenant",
    select_delivery_mode: "S\xc9LECTIONNER LE MODE DE LIVRAISON",
    pay_online_delivery_home: "Payer en ligne, livraison \xe0 la maison",
    pay_online_delivery_home_express: "Payer en ligne, livraison \xe0 la maison (Express)",
    pay_online_pickup: "Payez en ligne, retirer au magasin",
    pay_now: "Payez maintenant",
    pay_retry: "Payez maintenant",
    your_card_information: "Les informations de votre carte",
    processing_payment: "Paiement en cours...",
    make_your_choice: "Faites votre choix",
    you_may_also_like: "Vous aimerez peut-\xeatre aussi",
    personalization: "Personnalisation",
    i_have_read_and_have_approved_the_privacy_policy: "J’AI LU ET ACCEPTE LA POLITIQUE DE CONFIDENTIALIT\xc9.",
    shipping_cost_included: "Frais de livraison inclus",
    shop_the_collection: "Voir toute la collection",
    same_as_shipping: "M\xeame que l'adresse d'exp\xe9dition",
    invoice_summary: "R\xe9sum\xe9 de la facture",
    sub_total: "Total partiel",
    shipping: "Frais d’envoi",
    cookie_settings: "Param\xe8tres de cookie",
    essential_cookies: "Cookies Essentiels",
    analytical_cookies: "Cookies analytiques",
    marketing_cookies: "Cookies de marketing",
    active: "Activ\xe9",
    always_active: "Toujours activ\xe9",
    cookie_settings_description: "Notre site conserve trois niveaux de cookies. Vous pouvez ajuster vos pr\xe9f\xe9rences \xe0 tout moment. Si vous souhaitez plus d'informations sur les cookies et les cookies que nous collectons, veuillez consulter notre politique en mati\xe8re de cookies.",
    essential_cookies_description: "Les cookies fonctionnels sont des cookies essentiels qui garantissent le bon fonctionnement du site Web et l'enregistrement de vos pr\xe9f\xe9rences (langue, r\xe9gion, etc.).",
    analytical_cookies_description: "Les cookies analytiques nous permettent d'analyser l'utilisation du site et d'am\xe9liorer l'exp\xe9rience du visiteur.",
    marketing_cookies_description: "Les cookies de marketing ont pour objectif de personnaliser votre exp\xe9rience et de vous envoyer du contenu et des offres pertinents sur ce site et d'autres sites.",
    agree: "Agree",
    cookie_notice: 'Ce site utilise des cookies pour am\xe9liorer votre exp\xe9rience de navigation. En cliquant sur "Accepter", vous acceptez l\'utilisation de ces cookies.',
    firstName_name: "Pr\xe9nom",
    lastName_name: "Nom",
    company_name: "Nom de l'entreprise",
    email_name: "Email",
    phone_name: "T\xe9l\xe9phone",
    vatNumber_name: "TVA",
    address1_name: "Rue / B\xe2timent",
    zip_name: "Code postal",
    city_name: "Ville",
    province_name: "Province",
    countryCode_name: "Pays",
    firstName_placeholder: "Pr\xe9nom",
    lastName_placeholder: "Nom",
    company_placeholder: "Nom de l'entreprise",
    email_placeholder: "Email",
    phone_placeholder: "T\xe9l\xe9phone",
    vatNumber_placeholder: "TVA",
    address1_placeholder: "Rue / B\xe2timent",
    zip_placeholder: "Code postal",
    city_placeholder: "Ville",
    province_placeholder: "Province",
    countryCode_placeholder: "Pays",
    message: "Message",
    studioOrderDetailStepHeadline: "Personnalisation",
    studioOrderDetailStepSelectQuantity: "S\xe9lectionnez la quantit\xe9",
    studioAddToCartStepAllDone: "\xcates-vous pr\xeat?",
    studioAddToCartStepOneStepCloser: "vous \xeates presque pr\xeat \xe0 recevoir vos produits personnalis\xe9s!",
    studioMenuExpand: "Expand menu",
    studioMenuCollapse: "Collapse menu",
    studioSelectOption: "Choisir une option",
    studioViewLink: "Link to view design",
    studioMyAccount: "My account",
    studioShare: "Share",
    studioDoneButton: "Pr\xeat",
    studioMaterialStepHeadline: "Mat\xe9riel",
    studioMaterialStepSelectMaterial: "S\xe9lectionnez votre mat\xe9riel",
    studioColorStepSelectArea: "S\xe9lectionnez une zone sur le mod\xe8le ou dans la liste d\xe9roulante \xe0 remplir:",
    studioColorStepReset: "R\xe9initialiser",
    studioPersonalizationStepPlacement: "Placement",
    studioPersonalizationStepSelectOption: "S\xe9lectionnez une option",
    studioPersonalizationStepPersonalizationSelectZone: "S\xe9lectionnez une zone",
    studioPersonalizationStepPersonalizationPrintingTechniques: "Techniques",
    studioPersonalizationStepPersonalizationMaximumItems: "Nombre maximum d'articles:",
    studioPersonalizationStepPlacedImages: "Placed images",
    studioTextHeadline: "Texte",
    studioTextEnterText: "Entrez du texte:",
    studioTextEditText: "Edit text:",
    studioTextPlaceText: "Placer du texte",
    studioTextSelectAreaToPlaceText: "Select the area to place the text.",
    studioTextRemove: "Remove",
    studioTextMirrorY: "Retourner verticalement",
    studioTextMirrorX: "Retourner horizontalement",
    studioTextDuplicate: "Dupliquer",
    studioTextLock: "Fermer \xe0 cl\xe9",
    studioTextUnlock: "Ourvrir",
    studioTextVisible: "Visible",
    studioTextInvisible: "Invisible",
    studioTextCurrentlyPlaced: "Actuellement plac\xe9",
    studioTextNoTextPlacedYet: "Aucun \xe9l\xe9ment de texte n'a encore \xe9t\xe9 plac\xe9.",
    studioTextNoTextClickIn3D: "Cliquez dans la 3D",
    studioTextFontSize: "Taille de police",
    studioTextFontFamily: "Type de police",
    studioTextScale: "Croissance",
    studioTextRotate: "Tourner",
    studioTextOutlineSize: "Contour",
    studioTextCharSpacing: "Espacement des caract\xe8res",
    studioTextSelectColor: "Choisissez la couleur",
    studioTextSelectOutlineColor: "S\xe9lectionnez la couleur du contour",
    studioImageStepHeadline: "Images",
    studioImageDragYourImagesOrClickToUpload: "T\xe9l\xe9chargez vos images",
    studioImageImageLibrary: "Biblioth\xe8que d'images",
    studioImageUploadedImages: "Uploaded Images",
    studioImageNoImagesUploaded: "Aucune image t\xe9l\xe9charg\xe9e",
    studioImageScale: "Croissance",
    studioImageRemove: "Retirer",
    studioImageMirrorY: "Retourner verticalement",
    studioImageMirrorX: "Retourner horizontalement",
    studioImageDuplicate: "Dupliquer",
    studioImageLock: "Fermer \xe0 cl\xe9",
    studioImageUnlock: "Ouvrir",
    studioImageVisible: "Visible",
    studioImageInvisible: "Invisible",
    studioImageRotate: "Tourner",
    studioImagePlaceImage: "Placer l’image",
    studioImageImagesPlaced: "Images plac\xe9es",
    studioImageUploadSupportFiles: "(JPG, SVG, AI, PDF, EPS)",
    studioImageNoImagesPlaced: "Aucune image plac\xe9e",
    studioPatternFillSelectAnAreaOnTheModelOrFromTheDropdownToFill: "S\xe9lectionnez une zone sur le mod\xe8le ou dans la liste d\xe9roulante \xe0 remplir:",
    studioPatternFillUploadAPrint: "T\xe9l\xe9chargez une impression",
    studioPatternFillSelectAPrint: "S\xe9lectionnez une impression",
    studioPatternFillUsedPrint: "Used print",
    studioPatternFillUploadedPrints: "Impressions t\xe9l\xe9charg\xe9es",
    studioPatternFillNoPrintUploaded: "Aucune impression t\xe9l\xe9charg\xe9e",
    studioPatternFillMoveHorizontally: "D\xe9placer horizontalement",
    studioPatternFillMoveVertically: "D\xe9placer verticalement",
    studioPatternFillScale: "Croissance",
    studioPatternFillRotate: "Tourner",
    studioTitlePrintingTechnique: "Marquage",
    search: "Chercher",
    unable_to_load_products: "Impossible de charger les produits ...",
    no_result: "Pas de r\xe9sultat...",
    storefront_address_warning: "Veuillez s\xe9lectionner une adresse ",
    storefront_card_empty_warning: "Veuillez saisir un num\xe9ro de carte de cr\xe9dit",
    storefront_invalid_card_warning: "Carte de cr\xe9dit invalide",
    scan_qr_code_to_pay: "Scan the QR code to pay",
    payment_failed: "Payment failed",
    loading: "Loading",
    confirm_email: "Confirm Email",
    view_order_detail: "View Order Details",
    create_new_revision: "Create New Revision",
    new_password: "New Password",
    repeat_new_password: "Repeat New Password",
    set_new_password: "Set New Password",
    checkout: "Valider la commande",
    gst_tax: "TPS",
    qst_tax: "TVQ",
    pst_tax: "TVP",
    quote: "Soumission",
    quote_comments: "Notes",
    confirm: "Confirm",
    password_change_successful: "Password changed successfully",
    verify_account: "Verify your account",
    redirecting: "Redirection...",
    already_have_an_account: "Vous avez d\xe9j\xe0 un compte?",
    teamstore_not_open: "This teamstore hasn't been opened yet.",
    teamstore_closed: "This teamstore is already closed.",
    cart_empty: "Cart is empty",
    not_available: "Not available",
    product_in_cart_not_available: "Some product(s) in the cart is not available",
    contact_person: "Personne ressource",
    available_from: "Date d'ouverture",
    available_until: "Date de fermeture",
    variant_not_selected_or_not_available: "Panier invalide",
    fit_width: "Fit width",
    fit_height: "Fit height"
});
export var fr_CA = _object_spread_props(_object_spread({}, fr_FR), {
    shipping: "Frais d’envoi",
    apply: "Appliquer",
    checkout_now: "Passer \xe0 la caisse",
    continue_shopping: "Continuer \xe0 magasiner"
});
export var en_US = _object_spread_props(_object_spread({}, en_UK), {
    vat_number: "Tax Number",
    add_to_cart: "Add to cart",
    shopping_cart: "SHOPPING CART",
    invalid_cart: "Invalid cart",
    nothing_in_your_cart: "Your shopping cart is empty",
    beCreative_add_to_cart_confirm: "Are you sure you want to add this item to your cart?",
    beCreative_add_to_cart_button: "Add To Cart",
    beCreative_color_fill: "Color Fill",
    beCreative_color_a_zone: "Color A Zone",
    beCreative_color: "Color",
    beCreative_font_color: "Font Color",
    beCreative_outline_color: "Outline Color",
    beCreative_request_quote_design_application: "Design application for personalized clothing",
    already_in_cart: "Already in cart",
    same_supplier_warning: "You can only add products of the same supplier to the cart",
    personalization: "Personalization",
    loading: "Loading",
    confirm_email: "Confirm Email",
    view_order_detail: "View Order Details",
    create_new_revision: "Create New Revision",
    done: "Done",
    new_password: "New Password",
    repeat_new_password: "Repeat New Password",
    set_new_password: "Set New Password",
    checkout: "Checkout",
    gst_tax: "GST",
    qst_tax: "QST",
    pst_tax: "PST",
    quote: "Quote",
    quote_comments: "Quote comments",
    confirm: "Confirm",
    signup: "Sign up",
    password_change_successful: "Password changed successfully",
    verify_account: "Verify your account",
    teamstore_not_open: "This teamstore hasn't been opened yet.",
    teamstore_closed: "This teamstore is already closed.",
    cart_empty: "Cart is empty",
    not_available: "Not available",
    product_in_cart_not_available: "Some product(s) in the cart is not available",
    vatNumber_name: "Tax ID",
    vatNumber_placeholder: "Tax ID",
    vat_included: "Tax included",
    vat: "Tax",
    total_tax: "Total Tax",
    amount: "Amount"
});
export var en_CA = _object_spread({}, en_US);
export var en_AU = _object_spread_props(_object_spread({}, en_UK), {
    vat: "GST",
    vat_included: "GST included",
    total_tax: "Total GST",
    vatNumber_name: "ABN",
    vatNumber_placeholder: "ABN"
});
export var de_DE = _object_spread_props(_object_spread({}, en_US), {
    vat: "MwSt.",
    total_tax: "MwSt. gesamt",
    amount: "Betrag",
    copyright: "Urheberrechte",
    cart: "Wagen",
    account: "Konto",
    more_product_info: "Weitere Produktinfo",
    studio_my_design_title: "Meine Designs",
    studio_my_design_copylink: "Link kopieren",
    studio_my_design_copiedLink: "Copied",
    studio_my_design_description: "W\xe4hle ein gespeichertes Design aus und lade es in deinen Entwurf.",
    studio_my_design_createdAt: "Erstellt am:",
    studio_my_design_loadmore: "Mehr Designs anzeigen",
    studio_load_design: "Hochladen",
    is_billing_default: "Ist Abrechnungsausfall",
    set_as_billing_default: "Als Abrechnung festlegen",
    is_shipping_default: "Ist Versandverzug",
    set_as_shipping_default: "Als Versand eingestellt",
    contact_us: "kontaktiere uns",
    studioTextInputPlaceholder: "Hier Text eingeben ",
    sent_successfully: "erfolgreich gesendet",
    warning: "warnung",
    free_product_applied_to_cart: "Kostenloses Produkt auf Warenkorb angewendet",
    coupon_removed: "Gutschein entfernt",
    on_item: "auf Artikel",
    to_total: "die Summe",
    code: "Code",
    applied: "Angewandt",
    promocode_not_found: "Promo-Code nicht gefunden",
    select: "W\xe4hlen",
    must_not_be_empty: "Pflichtfeld",
    must_be_selected: "muss ausgew\xe4hlt sein",
    is_not_valid: "das ist nicht richtig",
    open_link_below_to_complete_payment: "\xd6ffnen Sie den Link unten, um die Zahlung abzuschlie\xdfen",
    terms_and_condition: "Bedingungen und Konditionen",
    I_agree_with_the: "Ich bin einverstanden mit die",
    and_the: "und die",
    privacy_policy: "Datenschutz-Bestimmungen",
    of: "von",
    vermarc_sport: "Vermarc Sport",
    complete_payment: "Schlie\xdfen Sie die Zahlung ab",
    off: "aus",
    promo: "Promo",
    missing_payment_method: "Zahlungsmethode fehlt",
    unsupported_payment_propvider: "Zahlungsservice wird nicht unterst\xfctzt",
    pay: "Zahlen",
    card: "Karte",
    bank_transfer: "Bank\xfcberweisung",
    //payment provider
    ideal: "IDEAL",
    bancontact: "Bancontact",
    sofort: "Sofort",
    stripe: "Stripe",
    stripe_connect: "Stripe Connect",
    omise: "Omise",
    promptpay: "Schnelle Bezahlung",
    cash: "Kasse",
    cash_on_delivery: "Barzahlung bei Lieferung",
    external: "EXTERN",
    paid: "BEZAHLT",
    refuned: "R\xdcCKERSTATTET",
    shipping_status: "Versandstatus",
    production_status: "Produktionsstatus",
    pending: "OFFEN",
    failed: "GESCHEITERT",
    in_production: "IN BEARBEITUNG",
    delivered: "ERHALTEN",
    cancelled: "ABGEBROCHEN",
    delivery_provider: "Lieferanbieter",
    product_name: "Artikel/ Produktname ",
    item: "Item",
    qty: "Qty",
    order_no: "Bestellung Nr.",
    back_to_order: "Back to Orders",
    placed_on: "Bestellt am (date)",
    payment_status: "Bezahlung",
    shipping_to: "Shipping to",
    billing_to: "Billing to",
    contact_info: "Contact Info",
    order_creation_failed: "Order creation failed",
    remainChar: "Verbleibende Charaktere",
    studioMissingPersonalizationConfig: "Fehlende Personalisierungsgruppenkonfiguration",
    apply: "Best\xe4tigen",
    request_for_quote: "Angebotsanfrage",
    processing_payments: "Een ogenblik geduld terwijl we uw verzoek verwerken ...",
    all_done: "Fertig?",
    payment_redirect_note: "Falls Sie nicht automatisch weitergeleitet werden, klicken Sie bitte den Link um die Bezahlung abzuschliessen:",
    size_chart: "Gr\xf6\xdfentabelle",
    shopping_cart: "Warenkorb",
    invalid_cart: "Ung\xfcltiger Warenkorb",
    invalid_partner_number: "Ung\xfcltige Partnernummer",
    parner_number_used: "Partnernummer wurde bereits verwendet",
    product: "Produkt",
    quantity: "Anzahl",
    register: "Registrieren",
    tell_us_who_you_are: "Sagen Sie uns, wer Sie sind",
    sport_club: "SPORTVEREIN",
    company: "Unternehmen",
    private_person: "Privatperson",
    selected_specific: "Wenn Sie einen Sportverein oder eine Firma ausgew\xe4hlt haben",
    enter_company_name: "Firmennamen eingeben",
    company_information: "Firmeninformation",
    enter_sport_club_name: "Geben Sie den Namen des Sportvereins ein",
    vat_number: "Umsatzsteuer-Identifikationsnummer",
    example_number: "z.B. 1234567890",
    personal_information: "Pers\xf6nliche Angaben",
    first_name: "Vorname",
    first_name_placeholder: "Dein Name",
    last_name: "Nachname",
    last_name_placeholder: "Ihr Nachname",
    repeat_email: "Wiederholen Sie Ihre E-Mail",
    activate_passport: "Aktivieren Sie Ihren Vorteilspass:",
    passport_number: "Passnummer f\xfcr Loyalit\xe4t",
    passport_card_number_placeholder: "Kartennummer eingeben",
    sign_up: "Anmelden",
    signup: "Anmelden",
    price: "Preis",
    continue_shopping: "Mit dem Einkauf fortfahren",
    checkout_now: "Zur Kasse >",
    total: "Gesamt",
    vat_included: "Inkl. MwSt",
    nothing_in_your_cart: "Ihr Warenkorb ist leer",
    validate: "Best\xe4tigen",
    description: "Beschreibung",
    vermarc_cart_service_title: "Unser Service Ihr Vorteil",
    vermarc_cart_service_description: "Der ultimative Test von Sportprofis und die gr\xf6\xdfte Sorgfalt f\xfcr unsere Produkte sind die Qualit\xe4tsgarantie von Vermarc. Bei Fragen k\xf6nnen Sie gerne auf unser Wissen und unsere Erfahrung zur\xfcckgreifen. Wir helfen Ihnen gerne weiter. Der vermarc garantiert:",
    vermarc_cart_service_item_1: "Qualit\xe4tsgarantie",
    vermarc_cart_service_item_2: "Optimale Kundendienstgarantie",
    vermarc_cart_service_item_3: "Premium-H\xe4ndlernetz in Benelux",
    vermarc_cart_service_item_4: "Nicht zufrieden, Umtauschgarantie oder Geld zur\xfcck",
    coupon_code: "Gutscheincode:",
    delivery_prices: "Lieferpreise",
    payment_options: "Sichere Bezahlung",
    bank_account_country: "Land des Bankkontos",
    payment_method: "Zahlungsmethode",
    belgium_and_netherlands: "Belgien, die Niederlande und Luxemburg",
    benelux: "Belgien, die Niederlande und Luxemburg ab 120 €",
    from: "ab",
    within_the_eu: "Innerhalb der EU",
    outside_the_eu: "Au\xdferhalb der EU",
    learn_more: "Erfahren Sie mehr \xfcber unsere Allgemeinen Gesch\xe4ftsbedingungen.",
    free: "Frei.",
    email: "Email",
    password: "Passwort",
    confirm_password: "Passwort best\xe4tigen",
    password_placeholder: "Mindest. 8 Zeichen, 1 Kleinbuchstabe, 1 Gro\xdfbuchstabe, 1 Sonderzeichen",
    enter_email: "Geben sie ihre E-Mailadresse ein",
    enter_password: "Geben Sie Ihr Passwort ein",
    forgot_password: "Passwort vergessen",
    reset_password: "Passwort zur\xfccksetzen",
    password_reset_required: "Ihr Konto erfordert ein Zur\xfccksetzen des Passworts. Zum Zur\xfccksetzen klicken Sie auf die Schaltfl\xe4che unten.",
    log_in: "EINLOGGEN",
    no_account_yet: "Sie haben noch keinen Account? Jetzt registrieren!",
    user_does_not_exist: "Benutzer existiert nicht!",
    premium_dealers_title: "Premium-H\xe4ndler",
    premium_dealers_subtitle: "Vermarc Sport w\xe4hlt seine Vertriebsstellen sorgf\xe4ltig aus.",
    premium_dealers_belgium: "Belgien",
    premium_dealers_netherlands: "Niederlande",
    phone: "Telefon",
    my_orders: "Meine Bestellungen",
    my_designs: "Meine Designs",
    fabric: "Stoff",
    color: "Farbe",
    text: "Text",
    logo: "Logo",
    label: "Etikette",
    login: "Einloggen",
    care_label: "Pflegeetikett",
    choose_your_fabric: "W\xe4hlen Sie Ihren Stoff",
    interlock: "Verriegelung",
    mesh: "Mesh",
    woven: "Gewebte",
    cotton: "Baumwolle",
    fabric_overview: "Stoff\xfcbersicht",
    group: "Gruppe",
    construction: "Konstruktion",
    content: "Inhalt",
    weight: "Gewicht",
    measurements: "Messungen",
    measurement_table: "Ma\xdftabelle",
    help_center: "Hilfezentrum",
    need_help: "Brauche Hilfe?",
    enter_article_name: "Artikelnamen eingeben",
    my_cart: "Mein Warenkorb",
    style_number: "Style-Nummer",
    color_and_print_overview: "Farb- und Druck\xfcbersicht",
    element: "Element",
    print: "Drucken",
    color_in_stock_for_this_fabric: "Farbe auf Lager f\xfcr diesen Stoff",
    out_of_stock: "Ausverkauft",
    dyed_for_both_sides: "Beidseitig gef\xe4rbt",
    color_picker: "Farbw\xe4hler",
    sublimated_one_side: "Einseitig sublimiert",
    upload_your_artwork: "Laden Sie Ihr Kunstwerk hoch",
    drag_a_file_here: "Ziehen Sie eine Datei hierher",
    or: "Oder",
    select_from_computer: "W\xe4hlen Sie von Ihrem Computer",
    scale: "vergr\xf6\xdfern",
    rotate: "Drehen",
    offset_x: "Horizontal",
    offset_y: "Vertikal",
    add_your_own_text: "F\xfcgen Sie Ihren eigenen Text hinzu",
    enter_your_text: "Geben Sie Ihren Text ein",
    text_overview: "Text\xfcbersicht",
    add: "Hinzuf\xfcgen",
    add_your_own_logo: "F\xfcgen Sie Ihr eigenes Logo hinzu",
    customize_care_label: "Passen Sie Ihr Pflegeetikett an",
    size_indication: "Gr\xf6\xdfenangabe nur zur Illustration",
    select_your_logo: "W\xe4hlen Sie Ihr Logo",
    select_your_care_label: "W\xe4hlen Sie Ihr Pflegeetikett",
    left: "Links",
    right: "Rechts",
    side: "Seite",
    front: "Vorderseite",
    three_quarter: "3/4",
    size_indication_note: "Hinweis: Gr\xf6\xdfenangabe nur zur Illustration",
    back: "Zur\xfcck",
    upcharge: "Aufpreis",
    close: "Schlie\xdfen",
    no_upcharge: "Kein Aufpreis",
    select_printing_technique_to_see_color: "W\xe4hlen Sie eine Drucktechnik aus, um die verf\xfcgbaren Farben anzuzeigen",
    choose_from_our_colors: "W\xe4hlen Sie aus unseren Farben",
    remove: "L\xf6schen",
    position: "Position",
    logo_overview: "Logo-\xdcbersicht",
    printing_technique: "Drucktechnik",
    select_care_label_template: "W\xe4hlen Sie eine Pflegeetikettenvorlage aus",
    shipping_price_not_included: "Versandkosten nicht enthalten",
    shipping_cost_calculated_next_step: "Der Versandpreis wird im n\xe4chsten Schritt berechnet",
    save_and_continue: "Speichern fortsetzen",
    create_folder: "Ordner erstellen",
    add_to_cart: "In den Warenkorb legen",
    delete: "L\xf6schen",
    review: "Rezension",
    need_sample: "Ben\xf6tigen Sie eine Probe",
    total_piece: "Gesamtst\xfcck",
    collection: "Sammlung",
    season: "Jahreszeit",
    discount: "Rabatt",
    minimum_quantity_50: "Warenkorb Liste Mindestmenge: 50 St\xfcck",
    submitted: "Eingereicht",
    on_hold: "In Wartestellung",
    production: "Produktion",
    shipped: "Versand erfolgt",
    your_orders: "Deine Bestellungen",
    edit: "Bearbeiten",
    my_account: "Mein Konto",
    phone_number: "Telefonnummer",
    default_currency: "Standardw\xe4hrung",
    default_language: "Standardsprache",
    default_country: "Standardland",
    vermarc_passport_number: "Vermarc-Passnummer",
    vermarc_passport_number_placeholder: "Geben Sie Ihre Passnummer ein",
    saved_addresses: "Gespeicherte Adressen",
    new_address: "Neue Adresse",
    save: "speichern",
    fileUpload: "Hochladen",
    set_default_shipping: "Standardversand festlegen",
    set_default_billing: "Festlegen der Standardabrechnung",
    street_or_building: "Stra\xdfe oder Geb\xe4ude",
    postcode: "Postleitzahl",
    city: "Stadt",
    province: "Bundesstaat / Provinz",
    country: "Land",
    country_placeholder: "W\xe4hlen...",
    saved_designs: "Gespeicherte Designs",
    teamwear_quality_lines: "Teamwear Qualit\xe4tslinien",
    es_line_description: "Die Comfort ES.L-Linie bietet eine einzigartige Vermarc-Qualit\xe4t und Komfort f\xfcr die technischen Fragen von Radfahrern aller Niveaus und f\xfcr die unterschiedlichsten Fahrertypen.",
    sp_line_description: "Die Premium-Linie SP.L profitiert von den immensen professionellen Tests und Untersuchungen der Premium-Kollektion von Vermarc und bietet ein einzigartiges Angebot an Leistung und Komfort.",
    pr_line_description: "Die Elite PR.R-Linie umfasst immer innovativere, ergonomischere und leichtere Kleidungsst\xfccke, um den h\xf6chsten Anforderungen der professionellen Fahrer gerecht zu werden.",
    category_football: "Fu\xdfball",
    category_cycling: "Radfahren",
    category_athletics: "Die Leichtathletik",
    filter_by: "Filtern nach",
    order_now: "Jetzt bestellen",
    design_now: "Jetzt gestalten",
    size: "Gr\xf6\xdfe",
    product_information: "Produktinformation",
    collections: "Sammlungen",
    beCreative_add_to_cart_confirm: "M\xf6chten Sie diesen Artikel wirklich in Ihren Warenkorb legen?",
    beCreative_add_to_cart_button: "In den Warenkorb legen",
    cancel: "Stornieren",
    beCreative_cancel_button: "Stornieren",
    beCreative_reset_button: "Zur\xfccksetzen",
    beCreative_color_fill: "Farbf\xfcllung",
    beCreative_color_a_zone: "Eine Zone f\xe4rben",
    beCreative_upload_button: "Hochladen",
    beCreative_upload_label: "Logo hochladen",
    beCreative_logo_list: "Logo-Liste",
    beCreative_place_logo: "Logo platzieren",
    beCreative_text_input_label: "Text",
    beCreative_text_input_placeholder: "Hier Text eingeben",
    beCreative_add_text: "Text hinzuf\xfcgen",
    beCreative_apply_change_text: "\xc4nderung anwenden",
    beCreative_select_fonts: "W\xe4hlen Sie Schriftarten aus",
    beCreative_transform_label: "Transformieren & Steuern",
    beCreative_outline_size: "Umrissgr\xf6\xdfe",
    beCreative_line_spacing: "Zeilenabstand",
    beCreative_color: "Farbe",
    beCreative_font_color: "Schriftfarbe",
    beCreative_outline_color: "Umrissfarbe",
    beCreative_instruction_how_to_use: "Wie benutze ich 3D?",
    beCreative_instruction_rotate360: "360 drehen",
    beCreative_instruction_rotate_mouse: "Linksklick + Ziehen (Maus)",
    beCreative_instruction_rotate_touch: "Ein Finger + Ziehen (Touch)",
    beCreative_instruction_zoom: "Zoomen",
    beCreative_instruction_zoom_mouse: "Scrolle mit dem Rad (Maus)",
    beCreative_instruction_zoom_touch: "Herausziehen (Touch)",
    beCreative_instruction_move: "Bewegung",
    beCreative_instruction_move_mouse: "Rechtsklick + Ziehen (Maus)",
    beCreative_instruction_move_touch: "Zwei Finger + Ziehen (Touch)",
    beCreative_instruction_close_button: "Schlie\xdfen",
    beCreative_request_quote_address_club: "Adresse (Mannschaft / Verein / Firma)",
    beCreative_request_quote_design_application: "Design-Anwendung f\xfcr personalisierte Kleidung",
    beCreative_request_quote_type_of_products: "Art der Produkte",
    beCreative_request_quote_athletics: "Die Leichtathletik",
    beCreative_request_quote_leisure: "Freizeit",
    beCreative_request_quote_football: "Fussball",
    beCreative_request_quote_triathlon: "Triathlon",
    beCreative_request_quote_cycling: "Radfahren",
    beCreative_request_quote_type_of_clothing: "Art der Fahrradbekleidung (personalisiert): Produkt",
    beCreative_request_quote_jersey_short_sleeve: "Jersey short sleeve",
    beCreative_request_quote_jersey_long_sleeve: "Jersey long sleeve",
    beCreative_request_quote_mid_season_jacket: "Mid season jacket",
    beCreative_request_quote_technical_jacket: "Technical jacket",
    beCreative_request_quote_windstopper: "Windstopper / Kaos",
    beCreative_request_quote_rain_jacket: "Rain jacket",
    beCreative_request_quote_bib_shorts: "Bib shorts",
    beCreative_request_quote_tights: "Tights",
    beCreative_request_quote_quality_line: "Type of cycling clothing (personalized): Quality line",
    beCreative_request_quote_dont_know_yet: "Ich wei\xdf es noch nicht",
    beCreative_request_quote_comments_questions: "Kommentare / Fragen",
    send: "Senden",
    sent: "Geschickt",
    please_fill_all_fields: "Bitte alle notwendigen Felder ausf\xfcllen",
    teamstores: "Team Stores",
    already_in_cart: "Bereits im Warenkorb",
    same_supplier_warning: "Sie k\xf6nnen nur Produkte desselben Lieferanten in den Warenkorb legen",
    go_to_order: "Zur Bestellung gehen",
    select_delivery_mode: "LIEFERMODUS W\xc4HLEN",
    pay_online_delivery_home: "Online bezahlen, zu Ihnen nach Hause geliefert",
    pay_online_delivery_home_express: "Online bezahlen, zu Ihnen nach Hause geliefert (Express)",
    pay_online_pickup: "Online bezahlen, im Gesch\xe4ft abholen",
    pay_now: "Jetzt bezahlen",
    pay_retry: "Jetzt bezahlen",
    your_card_information: "Ihre Karteninformationen",
    processing_payment: "Zahlungsabwicklung...",
    select_address: "Adresse ausw\xe4hlen",
    create_new_address: "Erstellen Sie eine neue Adresse",
    no_address: "keine Adresse hinterlegt",
    close_form: "Adresse ausblenden",
    shipping_address: "Lieferanschrift",
    shipping_method: "Liefermodus",
    billing_address: "Rechnungsadresse",
    invalid_address: "Ung\xfcltige Adresse. Bitte w\xe4hlen Sie einen anderen aus.",
    incl_free: "inkl. frei",
    cart_not_found: "Karte nicht gefunden",
    no_payment_available_yet: "Noch keine Zahlung m\xf6glich",
    confirm_payment: "Best\xe4tige Zahlung",
    credit_card: "Kreditkarte",
    credit_card_no: "Kreditkartennummer",
    expiry_date: "Ablaufdatum",
    security_code: "CSV",
    make_your_choice: "Treffen Sie Ihre Wahl",
    you_may_also_like: "Kunden kauften auch",
    personalization: "Personalisierung",
    i_have_read_and_have_approved_the_privacy_policy: "ICH HABE DIE DATENSCHUTZRICHTLINIE GELESEN UND GENEHMIGT",
    shipping_cost_included: "Versandkosten inklusive",
    shop_the_collection: "Sehen Sie sich die Sammlung an",
    same_as_shipping: "Gleiche wie Lieferadresse",
    invoice_summary: "Rechnungszusammenfassung",
    sub_total: "Zwischensumme",
    shipping: "Versand",
    cookie_settings: "Cookie-Einstellungen",
    essential_cookies: "Grundlegende Cookies",
    analytical_cookies: "Analytische Cookies",
    marketing_cookies: "Marketing-Cookies",
    active: "Aktiv",
    always_active: "Immer aktiv",
    cookie_settings_description: "Unsere Website enth\xe4lt drei Ebenen von Cookies. Sie k\xf6nnen Ihre Einstellungen jederzeit anpassen. Wenn Sie weitere Informationen dar\xfcber w\xfcnschen, was Cookies sind und welche Cookies wir sammeln, lesen Sie bitte unsere Cookie-Richtlinien.",
    essential_cookies_description: "Funktionscookies sind wichtige Cookies, die sicherstellen, dass die Website ordnungsgem\xe4\xdf funktioniert und Ihre Einstellungen (z. B. Sprache, Region) gespeichert werden.",
    analytical_cookies_description: "Analytical cookies allow us to analyse website use and to improve the visitor's experience.",
    marketing_cookies_description: "Das Ziel von Marketing-Cookies ist es, Ihre Erfahrung zu personalisieren und Ihnen relevante Inhalte und Angebote auf dieser Website und anderen Websites zu senden.",
    agree: "Zustimmen",
    cookie_notice: 'Diese Website verwendet Cookies, um Ihr Surferlebnis zu verbessern. Durch Klicken auf "Zustimmen" akzeptieren Sie die Verwendung dieser Cookies.',
    firstName_name: "Vorname",
    lastName_name: "Nachname",
    company_name: "Name der Firma",
    email_name: "Email",
    phone_name: "Telefon",
    vatNumber_name: "Umsatzsteuernummer",
    address1_name: "Stra\xdfe / Geb\xe4ude",
    zip_name: "PLZ",
    city_name: "Stadt",
    province_name: "Bundesstaat / Provinz",
    countryCode_name: "Land",
    firstName_placeholder: "Vorname",
    lastName_placeholder: "Nachname",
    company_placeholder: "Name der Firma",
    email_placeholder: "Email",
    phone_placeholder: "Telefon",
    vatNumber_placeholder: "Umsatzsteuernummer",
    address1_placeholder: "Stra\xdfe / Geb\xe4ude",
    zip_placeholder: "PLZ",
    city_placeholder: "Stadt",
    province_placeholder: "Bundesstaat / Provinz",
    countryCode_placeholder: "Land",
    message: "Nachricht",
    studioOrderDetailStepHeadline: "Personalisierung",
    studioOrderDetailStepSelectQuantity: "Menge",
    studioAddToCartStepAllDone: "All done?",
    studioAddToCartStepOneStepCloser: "You are one step closer to receiving your personalised products!",
    studioQualityControlHeader: "Qualit\xe4t ",
    studioMenuExpand: "Expand menu",
    studioMenuCollapse: "Collapse menu",
    studioSelectOption: "Select option",
    studioViewLink: "Link to view design",
    studioMyAccount: "My account",
    studioShare: "Share",
    studioTemplateStepHeadline: "Designvorlagen",
    studioDoneButton: "Fertig",
    studioMaterialStepHeadline: "Fabric",
    studioMaterialStepSelectMaterial: "Select your fabric",
    studioColorStepHeadline: "Farbe",
    studioColorStepSelectArea: "Select an area on the model or from the dropdown to fill:",
    studioQualitySettingtooltip: "Hier kannst du die Qualit\xe4t der 3D-Visualisierung einstellen. Bitte beachte, dass eine hohe Aufl\xf6sung unter Umst\xe4nden zu einer Verlangsamung des Programms f\xfchrt.",
    studioColorStepReset: "Reset",
    studioPersonalizationStepPlacement: "Placement",
    studioPersonalizationStepSelectOption: "Select an option",
    studioPersonalizationStepPersonalizationHeadline: "Personalisierung",
    studioPersonalizationStepPersonalizationSelectZone: "Wo soll deine Personalisierung platziert werden?",
    studioPersonalizationStepPersonalizationPrintingTechniques: "Techniques",
    studioPersonalizationStepPersonalizationMaximumItems: "Maximale Anzahl:",
    studioPersonalizationStepPlacedImages: "Platzierte Bilder",
    designCoreUploadBoxPreviewHeader: "Bild bearbeiten",
    designCoreUploadBoxCustomizationSelectorPreview: "Vorschau",
    designCoreUploadBoxCustomizationSelectorCrop: "Zuschneiden",
    designCoreUploadBoxCustomizationSelectorRecolor: "Umf\xe4rben",
    designCoreUploadBoxCustomizationSelectorInvert: "Invertieren",
    bulkOrderDeleteAll: "Alles l\xf6schen",
    designCoreUploadBoxCustomizationSelectorRemoveBackground: "Hintergrund l\xf6schen",
    studioTextHeadline: "Text",
    studioTextEnterText: "Gib hier deinen Wunschtext ein",
    studioTextEditText: "Gib hier deinen Wunschtext ein",
    studioTextPlaceText: "Text platzieren",
    backToStudio: "Speichern und zur\xfcck",
    studioTextSelectAreaToPlaceText: "W\xe4hle den Bereich aus, indem du den Text platzieren m\xf6chtest.",
    studioTextRemove: "Remove",
    studioTextMirrorY: "Flip Horizontal",
    studioTextMirrorX: "Flip Vertical",
    studioTextDuplicate: "Duplicate",
    resetEditedImage: "Zur\xfccksetzen",
    studioTextLock: "Lock",
    studioTextUnlock: "Unlock",
    studioTextVisible: "Visible",
    studioTextInvisible: "Invisible",
    studioTextCurrentlyPlaced: "Bisher platziert.",
    studioTextNoTextPlacedYet: "Bisher hast du noch keine Textelemente plaziert.",
    studioTextNoTextClickIn3D: "Klicke in das 3D Modell",
    studioTextFontSize: "Schriftgr\xf6\xdfe",
    studioTextFontFamily: "Schriftart",
    studioTextScale: "Skalierung",
    studioTextRotate: "Drehung",
    studioTextOutlineSize: "Outline",
    studioTextCharSpacing: "Zeichenabstand",
    studioTextSelectColor: "Farbe ausw\xe4hlen",
    studioTextSelectOutlineColor: "Outlinefarbe ausw\xe4hlen",
    studioTextToggleGridHelper: "Hilfslinien",
    studioToggleGridHelperDescription: "Sobald du das Element bewegst, werden dir Hilfslinien angezeigt um das Element genauer zu platzieren.",
    studioImageStepHeadline: "Bilder",
    studioImageDragYourImagesOrClickToUpload: "Drag your images or click to upload",
    studioImageImageLibrary: "Bildergalerie",
    studioImageUploadedImages: "Hochgeladene Bilder",
    studioImageNoImagesUploaded: "Du hast noch keine Bilder hochgeladen",
    studioImageScale: "Scale",
    studioImageRemove: "Remove",
    studioImageMirrorY: "Flip Horizontal",
    studioImageMirrorX: "Flip Vertical",
    studioImageDuplicate: "Duplicate",
    studioImageLock: "Lock",
    studioImageUnlock: "Unlock",
    studioImageInvisible: "Invisible",
    studioImageRotate: "Rotate",
    studioImageNoImagesPlaced: "No images placed",
    studioImageVisible: "Visible",
    studioImagePlaceImage: "Place Image",
    studioImageUploadSupportFiles: "(JPG, SVG, AI, PDF, EPS)",
    studioImageImagesPlaced: "Platzierte Bilder",
    studioPatternFillHeadline: "Pattern Fill",
    studioPatternFillSelectAnAreaOnTheModelOrFromTheDropdownToFill: "Select an area on the model or from the dropdown to fill:",
    studioPatternFillUploadAPrint: "Upload a print",
    studioPatternFillSelectAPrint: "Select a print",
    studioPatternFillUsedPrint: "Used print",
    studioPatternFillUploadedPrints: "Uploaded prints",
    studioPatternFillNoPrintUploaded: "No print uploaded",
    studioPatternFillMoveHorizontally: "Move horizontally",
    studioPatternFillMoveVertically: "Move vertically",
    studioPatternFillScale: "Scale",
    studioPatternFillRotate: "Rotate",
    studioLayerControlTitle: "Ebenen",
    QualityOptionMedium: "Mittel",
    QualityOptionLow: "Schwach",
    bulkOrderTooltip: "Wie funktioniert's?",
    bulkOrderCopyAndPaste: "Trage deine gew\xfcnschten Varianten in die folgende Tabelle ein.",
    bulkPasteOrder: "Varianten eintragen",
    bulkAddOrder: "Varianten bestellen",
    bulkOrderPasteInfo: " Bitte achte darauf, dass die Informationen dem vorgegebenen Format entsprechen und die Daten der richtigen Spalte zugeordnet sind.",
    bulkOrderCheckout: "Jetzt bestellen",
    studioTitlePrintingTechnique: "Texteinstellungen",
    studioLayer_not_found: "Du hast noch keine Ebenen erstellt",
    studioImageOpacity: "Deckkraft",
    studioTextOpacity: "Deckkraft",
    bulkOrderAcceptItem: "Aktuell k\xf6nnen wir nur 200 Varianten innerhalb eines Produktes bearbeiten. (1 Linie entspricht einer Variante).M\xf6chtest du mehr Varianten eines Produktes bestellen, dann lege bitte ein weiteres Produkt im Warenkorb an und nutze unsere Funktion, dein Design zu speichern und erneut hochzuladen.",
    orderDetailDescription: "Es gibt die M\xf6glichkeit, dein individuelles Design f\xfcr dein Team mit dem Spielername und/ oder der R\xfcckennummer kostenlos zu personalisieren. Die Varianten deiner Spieler kannst du hier angeben.",
    orderDetailSelectMethod: "Wie m\xf6chtest du die Personalisierung eintragen?",
    orderDetailBulkOrder: "Personalisierung in Tabelle eintragen (empfohlen f\xfcr Teams)",
    orderDetailManualOrder: "Personalisierung manuell eintragen (empfohlen f\xfcr geringe St\xfcckzahlen)",
    dragDropSingleFile: "Bitte lade dein Dokument hier hoch oder lege es per Drag 'n' Drop ab",
    bulkOrderSaveBeforeContinue: "Die Varianten werden gespeichert, wenn du zur\xfcck in den Bearbeitungsmodus kehrst.",
    search: "Suche",
    unable_to_load_products: "Produkte k\xf6nnen nicht geladen werden...",
    no_result: "Kein Ergebnis...",
    storefront_address_warning: "Bitte eine Addresse ausw\xe4hlen ",
    storefront_card_empty_warning: "Bitte eine Kreditkarte eingeben",
    storefront_invalid_card_warning: "Falsche Kreditkarte",
    scan_qr_code_to_pay: "Scan the QR code to pay",
    payment_failed: "Payment failed",
    loading: "Loading",
    confirm_email: "Confirm Email",
    view_order_detail: "Details ansehen",
    create_new_revision: "Create New Revision",
    done: "Fertig",
    new_password: "New Password",
    repeat_new_password: "Repeat New Password",
    set_new_password: "Set New Password",
    checkout: "Checkout",
    gst_tax: "GST",
    qst_tax: "QST",
    pst_tax: "PST",
    quote: "Quote",
    quote_comments: "Quote comments",
    confirm: "Confirm",
    password_change_successful: "Password changed successfully",
    verify_account: "Verify your account",
    redirecting: "Weiterleiten...",
    already_have_an_account: "Sie haben bereits ein Konto?",
    teamstore_not_open: "This teamstore hasn't been opened yet.",
    teamstore_closed: "This teamstore is already closed.",
    cart_empty: "Cart is empty",
    not_available: "Not available",
    product_in_cart_not_available: "Some product(s) in the cart is not available",
    contact_person: "Kontakt",
    available_from: "Available from",
    available_until: "Available until",
    variant_not_selected_or_not_available: "Ung\xfcltiger Warenkorb",
    fit_width: "Fit width",
    fit_height: "Fit height",
    untitled: "Unbekannt",
    image: "Bild"
});
export var pt_BR = _object_spread_props(_object_spread({}, en_UK), {
    studioColorStepHeadline: "Selecione uma cor",
    studioPatternFillHeadline: "Adicione uma estampa",
    studioTemplateStepHeadline: "Selecione um modelo",
    studioStepPattern: "Modelagem",
    studioStepMaterial: "Materiais",
    studioStepOrderdetail: "Quant. e Tamanho",
    studioStepImage: "Imagens",
    studioStepColor: "Colorir",
    studioStepAlloverprint: "Estampas",
    studioStepTemplate: "Modelos",
    studioStepText: "Adic. Texto",
    studioStepPersonalization: "Itens de Posi\xe7\xe3o Fixa",
    studioPersonalizationStepPersonalizationHeadline: "Itens de Posi\xe7\xe3o Fixa",
    bulkOrderProcessCheckout: "Finalizar Compra",
    bulkOrderConfirm: "Ao prosseguir para checkout n\xe3o ser\xe1 mais poss\xedvel editar os detalhes. Favor conferir se a informa\xe7\xe3o est\xe1 correta.",
    bulkOrderCheckout: "Final. Compra",
    bulkOrderPleaseNote: "Ao apertar em Voltar ao Est\xfadio vai eliminar as entradas feitas na planilha.",
    backToStudio: "Voltar ao Est\xfadio",
    bulkOrderAcceptItem: "Atualmente aceitamos at\xe9 200 registros (1 linha = 1 registro)",
    bulkOrderPasteInfo: "Voc\xea deve colar os dados na mesma sequ\xeancia das colunas indicadas abaixo.",
    bulkOrderCopyAndPaste: "Copie e Cole os dados na planilha abaixo.",
    bulkOrderTooltip: "Como funciona?",
    bulkPasteOrder: "Planilha",
    bulkAddOrder: "Fun\xe7\xe3o Planilha",
    orderDetailDescription: "Indicar quantidade e tamanho. Para pedidos de times, completar etapa “Nome & N\xfamero” e depois indicar nesta etapa o tamanho e dados de cada membro do time. Voc\xea pode entrar os dados um a um ou usar a fun\xe7\xe3o planilha para copiar e colar os dados. ",
    orderDetailSelectMethod: "Escolher forma de entrada",
    orderDetailBulkOrder: "Fun\xe7\xe3o Planilha",
    orderDetailManualOrder: "Entrar um a um",
    studioImageOpacity: "opacidade",
    studioTextOpacity: "opacidade",
    vat: "CUBA",
    contact_us: "Contate-Nos",
    studioTextInputPlaceholder: "Insira o texto aqui",
    sent_successfully: "Enviado com sucesso",
    warning: "Aviso",
    free_product_applied_to_cart: "Produto gr\xe1tis aplicado ao carrinho",
    coupon_removed: "Cupom removido",
    on_item: "No Produto",
    to_total: "Total",
    code: "C\xf3digo",
    applied: "Aplicado",
    promocode_not_found: "C\xf3digo promocional n\xe3o encontrado",
    select: "Selecione",
    must_not_be_empty: "n\xe3o deve estar vazio",
    must_be_selected: "deve ser selecionado",
    is_not_valid: "n\xe3o \xe9 v\xe1lido",
    open_link_below_to_complete_payment: "Abra o link abaixo para concluir o pagamento",
    terms_and_condition: "Termos e Condi\xe7\xf5es",
    I_agree_with_the: "Eu concordo com o",
    and_the: "e a",
    privacy_policy: "Pol\xedtica de Privacidade",
    of: "de",
    vermarc_sport: "---",
    complete_payment: "Processo de pagamento",
    off: "fora",
    promo: "Promo",
    missing_payment_method: "M\xe9todo de pagamento ausente",
    unsupported_payment_propvider: "Provedor de pagamento n\xe3o suportado",
    pay: "Pagar",
    card: "Cart\xe3o",
    bank_transfer: "Transfer\xeancia banc\xe1ria",
    //payment provider
    ideal: "IDEAL",
    bancontact: "Bancontact",
    sofort: "Sofort",
    stripe: "Stripe",
    stripe_connect: "Stripe Connect",
    omise: "Omise",
    promptpay: "Pagamento imediato",
    cash: "Dinheiro",
    cash_on_delivery: "Pagamento na entrega",
    external: "EXTERNO",
    paid: "PAGA",
    refuned: "DEVOLVEU",
    shipping_status: "Status de envio",
    pending: "PENDENTE",
    failed: "FRACASSADO",
    delivered: "ENTREGUE",
    cancelled: "CANCELADA",
    delivery_provider: "Provedor de entrega",
    product_name: "Nome do Produto",
    item: "Item",
    qty: "Quantidade",
    order_no: "Pedido n\xba",
    back_to_order: "De volta \xe0s encomendas",
    placed_on: "Colocado nesta data",
    payment_status: "Pagamento",
    shipping_to: "Enviando para",
    billing_to: "Faturamento para",
    contact_info: "Informa\xe7\xe3o de contato",
    order_creation_failed: "A cria\xe7\xe3o do pedido falhou",
    remainChar: "Caracteres restantes",
    studioMissingPersonalizationConfig: "Configura\xe7\xe3o de grupo de personaliza\xe7\xe3o ausente",
    request_for_quote: "Solicitar or\xe7amento",
    apply: "Aplicar",
    processing_payments: "Aguarde enquanto processamos sua solicita\xe7\xe3o ...",
    all_done: "Tudo feito",
    payment_redirect_note: "Se voc\xea n\xe3o for redirecionado automaticamente, conclua o pagamento com o provedor de pagamento clicando no seguinte link:",
    size_chart: "Tabela de medidas",
    shopping_cart: "Cesta de compras",
    invalid_cart: "Cesta inv\xe1lida",
    invalid_partner_number: "N\xfamero de parceiro inv\xe1lido",
    parner_number_used: "O n\xfamero do parceiro j\xe1 foi usado",
    product: "Produto",
    quantity: "Quantidade",
    register: "Registro",
    tell_us_who_you_are: "Diga-nos quem \xe9 voc\xea",
    sport_club: "Esporte Clube",
    company: "Empresa",
    private_person: "Pessoa privada",
    selected_specific: "Se voc\xea selecionou clube esportivo ou empresa",
    enter_company_name: "Insira o nome da empresa",
    company_information: "Informa\xe7\xf5es da Empresa",
    enter_sport_club_name: "Digite o nome do clube esportivo",
    vat_number: "N\xfamero de IVA",
    example_number: "ex. 1234567890",
    personal_information: "Informa\xe7\xf5es pessoais",
    first_name: "Primeiro nome",
    first_name_placeholder: "Seu nome",
    last_name: "Sobrenome",
    last_name_placeholder: "Seu \xfaltimo Nome",
    repeat_email: "Repita seu email",
    activate_passport: "Ative seu Passaporte de Benef\xedcios:",
    passport_number: "N\xfamero do passaporte de fidelidade",
    passport_card_number_placeholder: "Digite o n\xfamero do cart\xe3o",
    sign_up: "Inscrever-se",
    price: "Pre\xe7o",
    continue_shopping: "Continue comprando",
    checkout_now: "Fechar pedido",
    total: "",
    vat_included: "IVA incluido",
    nothing_in_your_cart: "Seu carrinho de compras est\xe1 vazio",
    validate: "Validar",
    description: "Descri\xe7\xe3o",
    vermarc_cart_service_title: "NOSSO SERVI\xc7O SUA VANTAGEM",
    vermarc_cart_service_description: "The ultimate test of sports professionals and the utmost care for our products, are the guarantee of quality of Vermarc. If you have any questions, feel free to fall back on our knowledge and experience. We will be pleased to help you. The vermarc guarantees:",
    vermarc_cart_service_item_1: "Quality guarantee",
    vermarc_cart_service_item_2: "Optimal customer service guarantee",
    vermarc_cart_service_item_3: "Premium dealer network in Benelux",
    vermarc_cart_service_item_4: "Not satisfied, exchange guarantee or money back",
    coupon_code: "C\xf3digo do cupom:",
    delivery_prices: "PRE\xc7OS DE ENTREGA",
    payment_options: "PAGAMENTO SEGURO",
    bank_account_country: "Pa\xeds da conta banc\xe1ria",
    payment_method: "Forma de pagamento",
    belgium_and_netherlands: "B\xe9lgica, Holanda e Luxemburgo",
    benelux: "B\xe9lgica, Holanda e Luxemburgo",
    from: "de",
    within_the_eu: "Dentro da UE",
    outside_the_eu: "Fora da UE",
    learn_more: "Saiba mais sobre nossos termos e condi\xe7\xf5es.",
    free: "Gratis.",
    email: "E-mail",
    password: "Senha",
    confirm_password: "Confirme a Senha",
    password_placeholder: "Min. 8 caracteres, 1 min\xfascula, 1 mai\xfascula, 1 caractere especial",
    enter_email: "Insira o seu endere\xe7o de email",
    enter_password: "Coloque sua senha",
    forgot_password: "Senha esquecida",
    reset_password: "Redefinir senha",
    password_reset_required: "Sua conta requer uma redefini\xe7\xe3o de senha. Para reiniciar, clique no bot\xe3o abaixo.",
    log_in: "CONECTE-SE",
    no_account_yet: "Voc\xea ainda n\xe3o tem uma conta. Inscreva-se agora!",
    user_does_not_exist: "Usu\xe1rio n\xe3o existe!",
    premium_dealers_title: "REVENDEDORES PREMIUM",
    premium_dealers_subtitle: "Vermarc Sport selects its distribution points carefully.",
    premium_dealers_belgium: "B\xe9lgica",
    premium_dealers_netherlands: "Holanda",
    phone: "Telefone",
    my_orders: "Minhas Ordens",
    my_designs: "Meus Projetos",
    fabric: "Tecido",
    color: "Cor",
    text: "Texto",
    logo: "Logotipo",
    label: "R\xf3tulo",
    login: "Conecte-se",
    care_label: "Etiqueta de cuidado",
    choose_your_fabric: "Escolha o seu tecido",
    interlock: "Interlock",
    mesh: "Mesh",
    woven: "Tecido",
    cotton: "Algod\xe3o",
    fabric_overview: "Vis\xe3o geral do tecido",
    group: "Grupo",
    construction: "Constru\xe7\xe3o",
    content: "Conte\xfado",
    weight: "Peso",
    measurements: "Medidas",
    measurement_table: "Tabela de medidas",
    help_center: "Centro de ajuda",
    need_help: "Voc\xea precisa de ajuda?",
    enter_article_name: "Insira o nome do artigo",
    my_cart: "Meu carrinho",
    style_number: "N\xfamero do estilo",
    color_and_print_overview: "Vis\xe3o geral de cores e impress\xe3o",
    element: "Elemento",
    print: "Impress\xe3o",
    color_in_stock_for_this_fabric: "Cor em estoque para este tecido",
    out_of_stock: "Fora de estoque",
    dyed_for_both_sides: "Tingido de ambos os lados",
    color_picker: "Seletor de cores",
    sublimated_one_side: "Sublimado um lado",
    upload_your_artwork: "Envie sua arte",
    drag_a_file_here: "Arraste um arquivo aqui",
    or: "Ou",
    select_from_computer: "Selecione no seu computador",
    scale: "Escala",
    rotate: "Girar",
    offset_x: "Offset X",
    offset_y: "Offset Y",
    add_your_own_text: "Adicione seu pr\xf3prio texto",
    enter_your_text: "Digite seu texto",
    text_overview: "Vis\xe3o geral do texto",
    add: "Adicionar",
    add_your_own_logo: "Adicione seu pr\xf3prio logotipo ",
    customize_care_label: "Personalize sua etiqueta de cuidados",
    size_indication: "Indica\xe7\xe3o de tamanho apenas para ilustra\xe7\xe3o",
    select_your_logo: "Selecione o seu logotipo",
    select_your_care_label: "Selecione sua etiqueta de cuidado",
    left: "Esquerda",
    right: "Certa",
    side: "Lado",
    front: "Frente",
    three_quarter: "3/4",
    size_indication_note: "Nota: Indica\xe7\xe3o de tamanho apenas para ilustra\xe7\xe3o",
    back: "Voltar",
    upcharge: "Aumento",
    no_upcharge: "Sem aumento",
    select_printing_technique_to_see_color: "Selecione uma t\xe9cnica de impress\xe3o para ver as cores dispon\xedveis",
    choose_from_our_colors: "Escolha entre nossas cores",
    remove: "Remover",
    position: "Posi\xe7\xe3o",
    logo_overview: "Vis\xe3o geral do logotipo",
    printing_technique: "T\xe9cnica de impress\xe3o",
    select_care_label_template: "Selecione um modelo de etiqueta de cuidado",
    shipping_price_not_included: "Pre\xe7o de envio n\xe3o inclu\xeddo",
    shipping_cost_calculated_next_step: "O pre\xe7o do frete ser\xe1 calculado na pr\xf3xima etapa",
    save_and_continue: "Salvar e continuar",
    create_folder: "Criar pasta",
    add_to_cart: "Adic. ao Carrinho",
    delete: "Excluir",
    review: "An\xe1lise",
    need_sample: "Necessita amostra",
    total_piece: "Pe\xe7a total",
    collection: "Cole\xe7\xe3o",
    season: "Esta\xe7\xe3o",
    discount: "Desconto",
    minimum_quantity_50: "Quantidade m\xednima da lista de carrinhos: 50 pe\xe7as",
    submitted: "Submetida",
    on_hold: "Em espera",
    production: "Produ\xe7\xe3o",
    shipped: "Enviado",
    your_orders: "Seus pedidos",
    edit: "Editar",
    my_account: "Minha conta",
    phone_number: "N\xfamero de telefone",
    default_currency: "Moeda padr\xe3o",
    default_language: "Idioma padr\xe3o",
    default_country: "Pa\xeds padr\xe3o",
    vermarc_passport_number: "Vermarc Passport Number",
    vermarc_passport_number_placeholder: "Enter your passport number",
    saved_addresses: "Endere\xe7os salvos",
    new_address: "Novo endere\xe7o",
    save: "Salve ",
    set_default_shipping: "Definir frete padr\xe3o",
    set_default_billing: "Definir faturamento padr\xe3o",
    street_or_building: "Rua ou edif\xedcio",
    postcode: "C\xf3digo Postal",
    city: "Cidade",
    province: "Prov\xedncia",
    country: "Pa\xeds",
    country_placeholder: "Selecione...",
    saved_designs: "Projetos salvos",
    teamwear_quality_lines: "Linhas de Qualidade Teamwear",
    es_line_description: "The comfort ES.L line offers a unique Vermarc quality and comfort to meet the technical questions of cyclists of all levels and for the most diverse type of riders.",
    sp_line_description: "The premium SP.L line benefits of the immense professional testing and research of Vermarc’s premium collection and gives a unique proposition on performance and comfort.",
    pr_line_description: "The elite PR.R line takes more and more innovative, more ergonomic and lighter garments to meet the highest requirements of the professional riders.",
    category_football: "Futebol",
    category_cycling: "Ciclismo",
    category_athletics: "Atletismo",
    filter_by: "Filtrar por",
    order_now: "Comprar",
    design_now: "Personalize agora",
    size: "Tamanho",
    product_information: "Informa\xe7\xe3o do produto",
    collections: "Cole\xe7\xf5es",
    close: "Fechar",
    beCreative_add_to_cart_confirm: "Voc\xea est\xe1 um passo mais perto de receber seus produtos personalizados!",
    beCreative_add_to_cart_button: "Adicionar ao carrinho",
    cancel: "Cancelar",
    beCreative_cancel_button: "Cancelar",
    beCreative_reset_button: "Redefinir",
    beCreative_color_fill: "Escolha de Cores",
    beCreative_color_a_zone: "Pinte uma \xe1rea",
    beCreative_upload_button: "Envio",
    beCreative_upload_label: "Enviar Imagem",
    beCreative_logo_list: "Lista de Imagens",
    beCreative_place_logo: "Inserir",
    beCreative_text_input_label: "Texto",
    beCreative_text_input_placeholder: "Insira o texto aqui",
    beCreative_add_text: "Adicione texto",
    beCreative_apply_change_text: "Aplicar Mudan\xe7a",
    beCreative_select_fonts: "Selecione as fontes",
    beCreative_transform_label: "Transformar e controlar",
    beCreative_outline_size: "Tamanho do contorno",
    beCreative_line_spacing: "Espa\xe7amento entre linhas",
    beCreative_color: "Cor",
    beCreative_font_color: "Cor da fonte",
    beCreative_outline_color: "Cor de contorno",
    beCreative_instruction_how_to_use: "Como usar 3D",
    beCreative_instruction_rotate360: "Girar 360",
    beCreative_instruction_rotate_mouse: "Clique com o bot\xe3o esquerdo e arraste (mouse)",
    beCreative_instruction_rotate_touch: "Um dedo + arrastar (Pad)",
    beCreative_instruction_zoom: "Amplia\xe7\xe3o",
    beCreative_instruction_zoom_mouse: "Role a roda (Mouse)",
    beCreative_instruction_zoom_touch: "Pin\xe7ar (Pad)",
    beCreative_instruction_move: "Mover",
    beCreative_instruction_move_mouse: "Clique com o bot\xe3o direito e arraste (mouse)",
    beCreative_instruction_move_touch: "Dois dedos + arrastar (Pad)",
    beCreative_instruction_close_button: "Fechar",
    beCreative_request_quote_address_club: "Endere\xe7o (equipe / clube / empresa)",
    beCreative_request_quote_design_application: "Aplicativo de personaliza\xe7\xe3o para roupas personalizadas",
    beCreative_request_quote_type_of_products: "Tipo de produtos",
    beCreative_request_quote_athletics: "Atletismo",
    beCreative_request_quote_leisure: "Lazer",
    beCreative_request_quote_football: "Futebol",
    beCreative_request_quote_triathlon: "Triatlo",
    beCreative_request_quote_cycling: "Ciclismo",
    beCreative_request_quote_type_of_clothing: "Tipo de roupa de ciclismo (personalizada): Produto",
    beCreative_request_quote_jersey_short_sleeve: "Camisa de manga curta",
    beCreative_request_quote_jersey_long_sleeve: "Jersey de mangas compridas",
    beCreative_request_quote_mid_season_jacket: "Jaqueta de meia temporada",
    beCreative_request_quote_technical_jacket: "Jaqueta t\xe9cnica",
    beCreative_request_quote_windstopper: "Windstopper / Kaos",
    beCreative_request_quote_rain_jacket: "Capa de chuva",
    beCreative_request_quote_bib_shorts: "Shorts bib",
    beCreative_request_quote_tights: "Collants",
    beCreative_request_quote_quality_line: "Tipo de roupa de ciclismo (personalizada): Linha de qualidade",
    beCreative_request_quote_dont_know_yet: "N\xe3o sei ainda",
    beCreative_request_quote_comments_questions: "Coment\xe1rios / perguntas",
    send: "Mandar",
    sent: "Enviei",
    please_fill_all_fields: "Por favor, preencha todos os campos obrigat\xf3rios",
    teamstores: "Lojas da equipe",
    already_in_cart: "J\xe1 no carrinho",
    same_supplier_warning: "Voc\xea s\xf3 pode adicionar produtos do mesmo fornecedor ao carrinho",
    go_to_order: "V\xe1 para o pedido",
    select_delivery_mode: "SELECIONE O MODO DE ENTREGA",
    pay_online_delivery_home: "Pague online, entregue em sua casa",
    pay_online_delivery_home_express: "Pague online, entregue em sua casa (expresso)",
    pay_online_pickup: "Pague online, retire na loja",
    pay_now: "Pague agora",
    pay_retry: "Pague agora",
    your_card_information: "Informa\xe7\xf5es do seu cart\xe3o",
    processing_payment: "Processo de pagamento...",
    select_address: "Selecione o endere\xe7o",
    close_form: "Fechar formul\xe1rio",
    shipping_address: "Endere\xe7o para envio",
    billing_address: "Endere\xe7o de Cobran\xe7a",
    create_new_address: "Crie um novo endere\xe7o",
    shipping_method: "M\xe9todo de envio",
    invalid_address: "Endere\xe7o inv\xe1lido. Selecione outro.",
    incl_free: "incl. gratuitamente",
    cart_not_found: "Cart\xe3o n\xe3o encontrado",
    no_payment_available_yet: "Nenhum pagamento dispon\xedvel ainda",
    confirm_payment: "Confirme o pagamento",
    credit_card: "Cart\xe3o de cr\xe9dito",
    credit_card_no: "N\xfamero do cart\xe3o de cr\xe9dito",
    expiry_date: "Data de expira\xe7\xe3o",
    security_code: "C\xf3digo de seguran\xe7a",
    make_your_choice: "Fa\xe7a a sua escolha",
    you_may_also_like: "Voc\xea pode gostar:",
    personalization: "Personaliza\xe7\xe3o",
    i_have_read_and_have_approved_the_privacy_policy: "LEI E APROVO A POL\xcdTICA DE PRIVACIDADE",
    shipping_cost_included: "Custo de envio incluso",
    shop_the_collection: "Veja a cole\xe7\xe3o",
    same_as_shipping: "Igual ao endere\xe7o de entrega",
    invoice_summary: "Resumo da fatura",
    sub_total: "Subtotal",
    shipping: "Envio",
    cookie_settings: "Configura\xe7\xf5es de cookies",
    essential_cookies: "Cookies essenciais",
    analytical_cookies: "Cookies anal\xedticos",
    marketing_cookies: "Cookies de marketing",
    active: "Ativo",
    always_active: "Sempre ativo",
    cookie_settings_description: "Nosso site mant\xe9m tr\xeas n\xedveis de cookies. Voc\xea pode ajustar suas prefer\xeancias a qualquer momento. Se voc\xea quiser mais informa\xe7\xf5es sobre o que s\xe3o cookies e quais cookies coletamos, leia nossa pol\xedtica de cookies. ",
    essential_cookies_description: "Cookies funcionais s\xe3o cookies essenciais que garantem que o site funcione corretamente e que suas prefer\xeancias (por exemplo, idioma, regi\xe3o) sejam salvas. ",
    analytical_cookies_description: "Os cookies anal\xedticos permitem-nos analisar a utiliza\xe7\xe3o do site e melhorar a experi\xeancia do visitante.",
    marketing_cookies_description: "Os cookies de marketing t\xeam como objetivo personalizar a sua experi\xeancia e enviar-lhe conte\xfados e ofertas relevantes, neste e noutros sites.",
    agree: "Concordar",
    cookie_notice: "Este site usa cookies para melhorar sua experi\xeancia de navega\xe7\xe3o. Ao clicar em 'Concordo', aceita a utiliza\xe7\xe3o destes cookies.",
    firstName_name: "Primeiro nome",
    lastName_name: "Sobrenome",
    company_name: "Nome da empresa",
    email_name: "E-mail",
    phone_name: "Telefone",
    vatNumber_name: "N\xfamero de IVA",
    address1_name: "Rua / Pr\xe9dio",
    zip_name: "C\xf3digo postal",
    city_name: "Cidade",
    province_name: "Estado / Prov\xedncia",
    countryCode_name: "Pa\xeds",
    firstName_placeholder: "Primeiro nome",
    lastName_placeholder: "Sobrenome",
    company_placeholder: "Nome da empresa",
    email_placeholder: "E-mail",
    phone_placeholder: "Telefone",
    vatNumber_placeholder: "N\xfamero de IVA",
    address1_placeholder: "Rua / Pr\xe9dio",
    zip_placeholder: "C\xf3digo postal",
    city_placeholder: "Cidade",
    province_placeholder: "Estado / Prov\xedncia",
    countryCode_placeholder: "Pa\xeds",
    message: "Mensagem",
    studioOrderDetailStepHeadline: "Personaliza\xe7\xe3o",
    studioOrderDetailStepSelectQuantity: "Selecione a quantidade",
    studioAddToCartStepAllDone: "Tudo feito",
    studioAddToCartStepOneStepCloser: "Voc\xea est\xe1 um passo mais perto de receber seus produtos personalizados!",
    studioMenuExpand: "Expandir menu",
    studioMenuCollapse: "Recolher menu",
    studioSelectOption: "Escolher",
    studioViewLink: "Link para visualizar o design",
    studioMyAccount: "Minha conta",
    studioShare: "Compartilhar",
    studioDoneButton: "OK",
    studioLayerControlTitle: "Contr. de Camada",
    studioMaterialStepHeadline: "Tecido",
    studioMaterialStepSelectMaterial: "Selecione o seu tecido",
    studioColorStepSelectArea: "Selecione uma \xe1rea no modelo ou no menu suspenso para preencher:",
    studioColorStepReset: "Redefinir",
    studioPersonalizationStepPlacement: "Coloca\xe7\xe3o",
    studioPersonalizationStepSelectOption: "Selecione uma op\xe7\xe3o",
    studioPersonalizationStepPersonalizationSelectZone: "Selecione uma zona",
    studioPersonalizationStepPersonalizationPrintingTechniques: "T\xe9cnicas",
    studioPersonalizationStepPersonalizationMaximumItems: "M\xe1ximo de Itens:",
    studioPersonalizationStepPlacedImages: "Imagens Escolhidas",
    studioPersonalizationStepPlaceholderPlacedText: "Textos Inseridos",
    studioPersonalizationStepPlaceholderColorOptions: "Op\xe7\xf5es de Cores",
    studioTextHeadline: "Texto",
    studioTextEnterText: "Digite o texto:",
    studioTextEditText: "Editar texto:",
    studioTextPlaceText: "Coloque o texto",
    studioTextSelectAreaToPlaceText: "Selecione a \xe1rea para colocar o texto.",
    studioTextSelectAreaToPlaceImage: "Selecione a \xe1rea para colocar a imagem.",
    studioTextRemove: "Remover",
    studioTextMirrorY: "Flip Horizontal",
    studioTextMirrorX: "Flip Vertical",
    studioTextDuplicate: "Duplicar",
    studioTextLock: "Trancar",
    studioTextUnlock: "Desbloquear",
    studioTextVisible: "Vis\xedvel",
    studioTextInvisible: "Invis\xedvel",
    studioTextCurrentlyPlaced: "Atualmente colocado",
    studioTextNoTextPlacedYet: "Nenhum elemento de texto colocado ainda.",
    studioTextNoTextClickIn3D: "Clique no modelo 3D",
    studioTextFontFamily: "Op\xe7\xf5es de Fontes",
    studioTextFontSize: "Tamanho da fonte",
    studioTextScale: "Escala",
    studioTextRotate: "Girar",
    studioTextOutlineSize: "Contorno",
    studioTextCharSpacing: "Espa\xe7amento entre caracteres",
    studioTextSelectColor: "Selecione a cor",
    studioTextSelectOutlineColor: "Selecione a cor do contorno",
    studioTextToggleGridHelper: "Ligar aux\xedlio de grade",
    studioImageStepHeadline: "Imagens",
    studioImageDragYourImagesOrClickToUpload: "Arraste suas imagens ou clique para fazer o upload",
    studioImageUploadSupportFiles: "(JPG, SVG, AI, PDF, EPS)",
    studioImageImageLibrary: "Biblioteca de imagens",
    studioImageUploadedImages: "Imagens carregadas",
    studioImageNoImagesUploaded: "Sem imagens enviadas",
    studioImageNoImagesPlaced: "Sem imagens colocadas",
    studioImageScale: "Tamanho",
    studioImageRemove: "Remover",
    studioImageMirrorY: "Espelhar Y",
    studioImageMirrorX: "Espelhar X",
    studioImageDuplicate: "Duplicar",
    studioImageLock: "Trancar",
    studioImageUnlock: "Desbloquear",
    studioImageInvisible: "Invis\xedvel",
    studioImageVisible: "Vis\xedvel",
    studioImageRotate: "Rota\xe7\xe3o",
    studioImagePlaceImage: "Inserir",
    studioImageImagesPlaced: "Imagens colocadas",
    studioImageToggleGridHelper: "Ligar aux\xedlio de grade",
    studioPatternFillSelectAnAreaOnTheModelOrFromTheDropdownToFill: "Selecione uma \xe1rea no modelo ou no menu suspenso para preencher:",
    studioPatternFillUploadAPrint: "Carregar uma estampa",
    studioPatternFillSelectAPrint: "Selecione uma estampa",
    studioPatternFillUsedPrint: "Estampa usada",
    studioPatternFillUploadedPrints: "Estampas carregadas",
    studioPatternFillNoPrintUploaded: "Nenhuma impress\xe3o enviada",
    studioPatternFillMoveHorizontally: "Mover horizontalmente",
    studioPatternFillMoveVertically: "Mover verticalmente",
    studioPatternFillScale: "Tamanho",
    studioPatternFillRotate: "Girar",
    studioTitlePrintingTechnique: "T\xe9cnicas de Impress\xe3o",
    search: "Procurar",
    unable_to_load_products: "N\xe3o foi poss\xedvel carregar produtos ...",
    no_result: "Sem resultado...",
    storefront_address_warning: "Selecione um endere\xe7o",
    storefront_card_empty_warning: "Por favor insira um n\xfamero de cart\xe3o de cr\xe9dito",
    storefront_invalid_card_warning: "Cart\xe3o de cr\xe9dito inv\xe1lido",
    scan_qr_code_to_pay: "Leia o c\xf3digo QR para pagar",
    payment_failed: "Pagamento falhou",
    redirecting: "Redirecionando...",
    loading: "Carregando",
    confirm_email: "Confirmar e-mail",
    view_order_detail: "Exibir detalhes do pedido",
    create_new_revision: "Criar Nova Revis\xe3o",
    done: "Completo",
    new_password: "Nova Senha",
    repeat_new_password: "Repita a nova senha",
    set_new_password: "Definir nova senha",
    no_address: "Nenhum endere\xe7o ainda",
    checkout: "Confira",
    gst_tax: "GST",
    qst_tax: "QST",
    pst_tax: "PST",
    quote: "Cota\xe7\xe3o",
    quote_comments: "Coment\xe1rios sobre a cita\xe7\xe3o",
    confirm: "confirme",
    signup: "Inscrever-se",
    password_change_successful: "Senha alterada com sucesso",
    verify_account: "Verifique sua conta",
    already_have_an_account: "J\xe1 tem uma conta?",
    teamstore_not_open: "Esta Teamstore ainda n\xe3o foi aberta.",
    teamstore_closed: "Esta teamstore j\xe1 est\xe1 fechada.",
    cart_empty: "Carrinho esta vazio",
    not_available: "Not available",
    product_in_cart_not_available: "Alguns produtos no carrinho n\xe3o est\xe3o dispon\xedveis",
    contact_person: "Pessoa de contato",
    available_until: "Dispon\xedvel at\xe9",
    available_from: "Dispon\xedvel a partir de",
    variant_not_selected_or_not_available: "Carrinho inv\xe1lido",
    dragDropSingleFile: "Arraste e solte seu arquivo aqui",
    fileUpload: "Carregar",
    fileUploadAll: "Carregar tudo",
    designCoreUploadBoxPreviewHeader: "Editar a Imagem",
    designCoreUploadBoxCustomizationSelectorPreview: "Pr\xe9-vizualizar",
    designCoreUploadBoxCustomizationSelectorCrop: "Recortar",
    designCoreUploadBoxCustomizationSelectorRecolor: "Trocar Cores",
    designCoreUploadBoxCustomizationSelectorInvert: "Inverter",
    designCoreUploadBoxCustomizationSelectorRemoveBackground: "Remover Fundo",
    studioToggleGridHelperDescription: "Linhas de aux\xedlio de grade ficam vis\xedveis no modelo 3D para ajudar a posicionar o objeto."
});
export var pt_PT = _object_spread({}, pt_BR);
export var lv_LV = _object_spread_props(_object_spread({}, en_UK), {
    studioImageOpacity: "aprobežotība",
    studioTextOpacity: "aprobežotība",
    vat: "PVM",
    contact_us: "Sazinies ar mums",
    i_have_read_and_have_approved_the_privacy_policy: "Esmu lasījis un apstiprinājis privātuma politiku",
    firstName_name: "Vārds",
    lastName_name: "Uzvārds",
    first_name: "Vārds",
    last_name: "Uzvārds",
    send: "Nosūtīt",
    sent: "Nosūtīts",
    email: "E-pasts",
    please_fill_all_fields: "Lūdzu, aizpildiet visus obligātos laukus",
    message: "Ziņojums",
    personalization: "Personalizācija",
    sent_successfully: "veiksmīgi nosūtīts",
    studioStepAlloverprint: "Tekstūra",
    studioOrderDetailStepHeadline: "Personalizācija",
    studioOrderDetailStepSelectQuantity: "Izvēlies daudzumu",
    studioAddToCartStepAllDone: "Viss pabeigts?",
    studioAddToCartStepOneStepCloser: "Tu esi vienu soli tuvāk sava personalizēta produkta   saņemšanai!",
    studioMenuExpand: "Expand menu",
    studioMenuCollapse: "Collapse menu",
    studioSelectOption: "Select option",
    studioViewLink: "Link to view design",
    studioMyAccount: "My account",
    studioShare: "Share",
    studioTemplateStepHeadline: "Izvēlēties dizainu",
    studioDoneButton: "Gatavs",
    studioMaterialStepHeadline: "Audums",
    studioMaterialStepSelectMaterial: "Izvēlies audumu",
    studioColorStepHeadline: "Pievienot krāsu",
    studioColorStepSelectArea: "Norādi vajadzīgo apgabalu uz 3D modeļa vai izvēlies no saraksta:",
    studioColorStepReset: "Atiestatīt",
    studioStepPersonalization: "Ievades lauks",
    studioStepPattern: "Aizpildīt ar tekstūru",
    studioPersonalizationStepPlacement: "Novietojums",
    studioPersonalizationStepSelectOption: "Izvēlies opciju",
    studioPersonalizationStepPersonalizationHeadline: "Personalizācija",
    studioPersonalizationStepPersonalizationSelectZone: "Izvēlies zonu",
    studioPersonalizationStepPersonalizationPrintingTechniques: "Drukas tehnikas",
    studioPersonalizationStepPersonalizationMaximumItems: "Elementu limits:",
    studioTextHeadline: "Teksts",
    studioTextEnterText: "Levadi tekstu:",
    studioTextEditText: "Edit text:",
    studioTextPlaceText: "Novieto tekstu",
    studioTextSelectAreaToPlaceText: "Izvēlies apgabalu kur novietot tekstu",
    studioTextRemove: "Dzēst",
    studioTextMirrorY: "Horizontālais spoguļskats",
    studioTextMirrorX: "Vertikālais spoguļskats",
    studioTextDuplicate: "Duplicēt",
    studioTextLock: "Aizslēgt",
    studioTextUnlock: "Atslēgt",
    studioTextInvisible: "Neradzams",
    studioTextCurrentlyPlaced: "Pašlaik novietots",
    studioTextNoTextPlacedYet: "Pagaidām nav novietots neviens teksta objekts.",
    studioTextNoTextClickIn3D: "Uzklikšķini uz 3D modeļa",
    studioTextFontFamily: "Fontu saime",
    studioTextScale: "Mainīt izmēru",
    studioTextRotate: "Rotēt",
    studioTextOutlineSize: "Kontūrlīnija",
    studioTextCharSpacing: "Atstarpe starp burtiem",
    studioTextSelectColor: "Izvēlies krāsu",
    studioTextSelectOutlineColor: "Izvēlies kontūras krāsu",
    studioImageStepHeadline: "Bildes",
    studioImageDragYourImagesOrClickToUpload: "Ievelc attēlu šeit vai uzklikšķini šeit lai augšupielādētu.",
    studioImageImageLibrary: "Attēlu galerija",
    studioImageUploadedImages: "Uploaded Images",
    studioImageNoImagesUploaded: "Pašlaik nav augšupielādēta neviena bilde",
    studioImageScale: "Mainīt izmēru",
    studioImageRemove: "Dzēst",
    studioImageMirrorY: "Horizontālais spoguļskats",
    studioImageMirrorX: "Vertikālais spoguļskats",
    studioImageDuplicate: "Duplicēt",
    studioImageLock: "Aizslēgt",
    studioImageUnlock: "Atslēgt",
    studioImageInvisible: "Neradzams",
    studioImageRotate: "Rotēt",
    studioImageImagesPlaced: "Novietotās bildes",
    studioTitlePrintingTechnique: "Printing Techniques",
    studioPatternFillHeadline: "Pievienot tekstūru",
    studioPatternFillSelectAnAreaOnTheModelOrFromTheDropdownToFill: "Norādi vajadzīgo apgabalu uz 3D modeļa vai izvēlies no saraksta",
    studioPatternFillUploadAPrint: "Augšupielādē rakstu",
    studioPatternFillSelectAPrint: "Izvēlies rakstu",
    studioPatternFillUsedPrint: "Used print",
    studioPatternFillUploadedPrints: "Augšupielādētie raksti",
    studioPatternFillNoPrintUploaded: "Neviens raksts nav augšuplādēts",
    studioPatternFillMoveHorizontally: "Bīdīt horizontāli",
    studioPatternFillMoveVertically: "Bīdīt vertikāli",
    studioPatternFillScale: "Mainīt izmēru",
    studioPatternFillRotate: "Rotēt",
    search: "Meklēt",
    unable_to_load_products: "Nevar ielādēt produktus...",
    no_result: "Nav rezultātu...",
    scan_qr_code_to_pay: "Scan the QR code to pay",
    payment_failed: "Payment failed",
    redirecting: "Redirecting..."
});
export var es_ES = _object_spread_props(_object_spread({}, en_UK), {
    studioStepPersonalization: "Marcador de posici\xf3n",
    studioPersonalizationStepPersonalizationHeadline: "Marcador de posici\xf3n",
    studioColorStepHeadline: "Elige un color",
    studioPatternFillHeadline: "Elige un estampado",
    studioTemplateStepHeadline: "Elige un dise\xf1o",
    vat: "IVA",
    copyright: "Derechos de autor",
    cart: "Carro",
    account: "Cuenta",
    more_product_info: "M\xe1s informaci\xf3n del producto",
    is_billing_default: "Est\xe1 facturando por defecto",
    set_as_billing_default: "Establecer como facturaci\xf3n",
    is_shipping_default: "Es el env\xedo predeterminado",
    set_as_shipping_default: "Establecer como env\xedo",
    contact_us: "Contacta con nosotras",
    sent_successfully: "Enviado correctamente",
    warning: "advertencia",
    free_product_applied_to_cart: "Producto gratis agregado al carrito",
    coupon_removed: "Cup\xf3n eliminado",
    on_item: "en el Producto",
    to_total: "en el total",
    code: "C\xf3digo",
    applied: "Aplicado",
    promocode_not_found: "C\xf3digo no encontrado",
    select: "Seleccionar",
    must_not_be_empty: "Campo obligatorio",
    must_be_selected: "Debe estar seleccionado",
    is_not_valid: "no es v\xe1lido",
    open_link_below_to_complete_payment: "Abrir el link para completar el pago",
    terms_and_condition: "T\xe9rminos y condiciones",
    I_agree_with_the: "Estoy de acuerdo con los",
    and_the: "y la",
    privacy_policy: "Pol\xedtica de Privacidad",
    of: "de",
    vermarc_sport: "Vermarc Sport",
    complete_payment: "Completar el pago",
    off: "Apagado",
    promo: "Promoci\xf3n",
    missing_payment_method: "Falta el m\xe9todo de pago",
    unsupported_payment_propvider: "Servicio de pago no admitido",
    pay: "Pago",
    card: "Tarjeta",
    bank_transfer: "Transferencia bancaria",
    //payment provider
    ideal: "IDEAL",
    bancontact: "Bancontact",
    sofort: "Sofort",
    stripe: "Stripe",
    stripe_connect: "Stripe Connect",
    omise: "Omise",
    promptpay: "Pago puntual",
    cash: "Efectivo",
    cash_on_delivery: "Pago en efectivo contra entrega",
    external: "Externo",
    paid: "Pagado",
    refuned: "Reembolsado",
    shipping_status: "Estado del env\xedo",
    pending: "PENDIENTE",
    failed: "FALLADO",
    delivered: "ENTREGADO",
    cancelled: "CANCELADO",
    storefront_address_warning: "Por favor selecciona una direcci\xf3n ",
    storefront_card_empty_warning: "Por favor ingresa los n\xfameros de la tarjeta de cr\xe9dito",
    storefront_invalid_card_warning: "Tarjeta de cr\xe9dito inv\xe1lida",
    delivery_provider: "Proveedor de entrega",
    product_name: "Nombre del producto",
    item: "Art\xedculo",
    qty: "Cantidad",
    order_no: "N\xfamero de orden",
    back_to_order: "Regresar a \xf3rdenes",
    placed_on: "Pedido en",
    payment_status: "Pago",
    shipping_to: "Direcci\xf3n de env\xedo",
    billing_to: "Direcci\xf3n de facturaci\xf3n",
    contact_info: "Informaci\xf3n de contacto",
    order_creation_failed: "No se ha podido completar el pedido",
    request_for_quote: "Quiero cotizar",
    apply: "Aplicar",
    processing_payments: "Por favor espera mientras procesamos tu pago...",
    all_done: "Todo listo?",
    payment_redirect_note: "Si no te redirige autom\xe1ticamente, por favor completa el pago con el proveedor de pagos haciendo clic en el siguiente enlace",
    size_chart: "Gu\xeda de medidas",
    shopping_cart: "Carrito de compras",
    invalid_cart: "Carrito no v\xe1lido",
    invalid_partner_number: "N\xfamero de socio no v\xe1lido",
    parner_number_used: "El n\xfamero de socio ya se ha utilizado",
    product: "Producto",
    quantity: "Cantidad",
    register: "Registrarse",
    tell_us_who_you_are: "H\xe1blanos de t\xed",
    sport_club: "Club Deportivo",
    company: "Compa\xf1\xeda",
    private_person: "Persona privada",
    selected_specific: "Si has seleccionado club deportivo o compa\xf1\xeda",
    enter_company_name: "Ingresa el nombre de la compa\xf1\xeda",
    company_information: "Informaci\xf3n de la compa\xf1\xeda",
    enter_sport_club_name: "Ingresa el nombre del club deportivo",
    vat_number: "N\xfamero para impuestos",
    example_number: "Ejemplo: 1234567890",
    personal_information: "Informaci\xf3n personal",
    first_name: "Nombre",
    first_name_placeholder: "Tu Nombre",
    last_name: "Apellido(s)",
    last_name_placeholder: "Tu(s) apellido(s)",
    repeat_email: "Repite tu correo electr\xf3nico",
    activate_passport: "Activa tu carn\xe9 de beneficios",
    passport_number: "N\xfamero de carn\xe9 de fidelidad",
    passport_card_number_placeholder: "Ingresa el n\xfamero de la tarjeta",
    sign_up: "Reg\xedstrate",
    signup: "Reg\xedstrate",
    price: "Precio",
    continue_shopping: "Continuar comprando",
    checkout_now: "Proceder al pago",
    total: "Total",
    vat_included: "Impuestos incluidos",
    nothing_in_your_cart: "Tu carrito de compras est\xe1 vac\xedo",
    validate: "Validar",
    vermarc_cart_service_title: "OUR SERVICE YOUR ADVANTAGE",
    vermarc_cart_service_description: "The ultimate test of sports professionals and the utmost care for our products, are the guarantee of quality of Vermarc. If you have any questions, feel free to fall back on our knowledge and experience. We will be pleased to help you. The vermarc guarantees:",
    vermarc_cart_service_item_1: "Quality guarantee",
    vermarc_cart_service_item_2: "Optimal customer service guarantee",
    vermarc_cart_service_item_3: "Premium dealer network in Benelux",
    vermarc_cart_service_item_4: "Not satisfied, exchange guarantee or money back",
    coupon_code: "C\xf3digo del cup\xf3n:",
    delivery_prices: "COSTOS DE ENTREGA",
    payment_options: "PAGO SEGURO",
    bank_account_country: "Pa\xeds de origen de la cuenta de banco",
    payment_method: "Forma de pago",
    belgium_and_netherlands: "Belgium, Netherlands and Luxembourg",
    benelux: "Belgium, Netherlands and Luxembourg",
    from: "de",
    within_the_eu: "Within the EU",
    outside_the_eu: "Outside the EU",
    learn_more: "Conoce m\xe1s sobre nuestros t\xe9rminos y condiciones.",
    free: "Gratis.",
    email: "Correo electr\xf3nico",
    password: "Contrase\xf1a",
    confirm_password: "Confirma tu contrase\xf1a",
    password_placeholder: "M\xecnimo 8 caracteres, 1 min\xfascula, 1 may\xfascula, 1 caracter especial",
    enter_email: "Ingresa tu correo electr\xf3nico",
    enter_password: "Ingresa tu contrase\xf1a",
    forgot_password: "\xbfHas olvidado tu contrase\xf1a?",
    reset_password: "Resetea tu contrase\xf1a",
    password_reset_required: "Tu cuenta requiere un cambio de contrase\xf1a, para cambiarla da click en el bot\xf3n de abajo.",
    log_in: "INICIAR SESI\xd3N",
    no_account_yet: "\xbfA\xfan no tienes una cuenta? \xa1Reg\xedstrate ahora!",
    user_does_not_exist: "\xa1Este usuario no existe!",
    premium_dealers_title: "PREMIUM DEALERS",
    premium_dealers_subtitle: "Vermarc Sport selects its distribution points carefully.",
    premium_dealers_belgium: "Belgium",
    premium_dealers_netherlands: "Netherlands",
    phone: "Tel\xe9fono",
    my_orders: "Mis \xf3rdenes",
    my_designs: "Mis dise\xf1os",
    fabric: "Tela",
    color: "Color",
    text: "Texto",
    logo: "Logotipo",
    label: "Etiqueta",
    login: "Iniciar sesi\xf3n",
    care_label: "Etiqueta de cuidados",
    choose_your_fabric: "Escoje una tela",
    interlock: "Tela stretch",
    mesh: "Tela Mesh",
    woven: "Tela tejida",
    cotton: "Algod\xf3n",
    fabric_overview: "Vista general de la tela",
    group: "Grupo",
    construction: "Construcci\xf3n",
    content: "Contenido",
    weight: "Peso",
    measurements: "Medidas",
    measurement_table: "Tabla de medidas",
    help_center: "Centro de Ayuda",
    need_help: "\xbfNecesitas ayuda?",
    enter_article_name: "Ingresa el nombre del art\xedculo",
    my_cart: "Mi carrito de compras",
    style_number: "N\xfamero de estilo",
    color_and_print_overview: "Vista general del color y estampado",
    element: "Elemento",
    print: "Estampado",
    color_in_stock_for_this_fabric: "Color en existencia para esta tela",
    out_of_stock: "Agotado",
    dyed_for_both_sides: "Tejido por ambos lados",
    color_picker: "Selector de color",
    sublimated_one_side: "Sublimado de un lado",
    upload_your_artwork: "Sube tu propio arte",
    drag_a_file_here: "Arrastra un archivo aqu\xed",
    or: "O",
    select_from_computer: "Selecciona desde tu ordenador",
    scale: "Escalar",
    rotate: "Rotar",
    offset_x: "Offset X",
    offset_y: "Offset Y",
    add_your_own_text: "Agrega tu propio texto",
    enter_your_text: "Ingresa tu texto",
    text_overview: "Vista general del texto",
    add: "Agregar",
    add_your_own_logo: "Agrega tu propio logotipo",
    customize_care_label: "Personaliza tu etiqueta de cuidados",
    size_indication: "Indicaci\xf3n de tama\xf1o solo con fines ilustrativos",
    select_your_logo: "Selecciona tu logotipo",
    select_your_care_label: "Selecciona tu etiqueta de cuidados",
    left: "Izquierda",
    right: "Derecha",
    side: "Lado",
    front: "Frente",
    three_quarter: "3/4",
    size_indication_note: "Nota: Indicaci\xf3n de tama\xf1o solo con fines ilustrativos",
    back: "Atr\xe1s",
    upcharge: "Recargo",
    no_upcharge: "Sin recargos",
    select_printing_technique_to_see_color: "Selecciona una t\xe9cnica de impresi\xf3n para ver los colores disponibles",
    choose_from_our_colors: "Escoge de nuestra paleta de colores",
    remove: "Remover",
    position: "Posici\xf3n",
    logo_overview: "Vista general del logotipo",
    printing_technique: "T\xe9cnica de impresi\xf3n",
    select_care_label_template: "Selecciona una platilla de etiqueta de cuidados",
    shipping_price_not_included: "Costo de env\xedo no incluido",
    shipping_cost_calculated_next_step: "El costo de env\xedo ser\xe1 calculado en el siguiente paso",
    save_and_continue: "Guardar y Continuar",
    create_folder: "Crear folder",
    add_to_cart: "A\xf1adir al carrito",
    delete: "Borrar",
    review: "Revisar",
    need_sample: "Necesita una muestra",
    total_piece: "Pieza total",
    collection: "Colecci\xf3n",
    season: "Temporada",
    discount: "Descuento",
    minimum_quantity_50: "Cantidad m\xednima de la lista del carrito: 50 piezas",
    submitted: "Orden ingresada",
    on_hold: "En Espera",
    production: "En Producci\xf3n",
    shipped: "Despachada",
    your_orders: "Tus \xf3rdenes",
    edit: "Editar",
    my_account: "Mi Cuenta",
    phone_number: "N\xfamero telef\xf3nico",
    default_currency: "Moneda predeterminada",
    default_language: "Idioma predeterminado",
    default_country: "Pa\xeds predeterminado",
    vermarc_passport_number: "Vermarc Passport Number",
    vermarc_passport_number_placeholder: "Enter your passport number",
    saved_addresses: "Direcciones guardadas",
    new_address: "Nueva direcci\xf3n",
    save: "Guardar",
    set_default_shipping: "Establecer direcci\xf3n de env\xedo predeterminado",
    set_default_billing: "Establecer direcci\xf3n de facturaci\xf3n predeterminada",
    street_or_building: "Calle o Edificio",
    postcode: "C\xf3digo Postal",
    city: "Ciudad",
    province: "Provincia",
    country: "Pa\xeds",
    country_placeholder: "Seleccionar...",
    saved_designs: "Dise\xf1os guardados",
    teamwear_quality_lines: "Teamwear Quality Lines",
    es_line_description: "The comfort ES.L line offers a unique Vermarc quality and comfort to meet the technical questions of cyclists of all levels and for the most diverse type of riders.",
    sp_line_description: "The premium SP.L line benefits of the immense professional testing and research of Vermarc’s premium collection and gives a unique proposition on performance and comfort.",
    pr_line_description: "The elite PR.R line takes more and more innovative, more ergonomic and lighter garments to meet the highest requirements of the professional riders.",
    category_football: "Futbol soccer",
    category_cycling: "Ciclismo",
    category_athletics: "Atletismo",
    filter_by: "Filtrar por",
    order_now: "Ordenar ahora",
    design_now: "Dise\xf1ar ahora",
    size: "Talla",
    product_information: "Informacion del producto",
    collections: "Colecciones",
    beCreative_add_to_cart_confirm: "\xa1Est\xe1s un paso m\xe1s cerca de recibir tus productos personalizados!",
    beCreative_add_to_cart_button: "A\xf1adir al carrito",
    cancel: "Cancelar",
    beCreative_cancel_button: "Cancelar",
    beCreative_reset_button: "Reiniciar",
    beCreative_color_fill: "Relleno de color",
    beCreative_color_a_zone: "Colorea una zona",
    beCreative_upload_button: "Subir",
    beCreative_upload_label: "Subir imagen",
    beCreative_logo_list: "Lista de im\xe1genes",
    beCreative_place_logo: "Colocar la imagen",
    beCreative_text_input_label: "Texto",
    beCreative_text_input_placeholder: "Ingresa el texto aqu\xed",
    beCreative_add_text: "Agregar texto",
    beCreative_apply_change_text: "Aplicar cambio",
    beCreative_select_fonts: "Seleccionar fuentes",
    beCreative_transform_label: "Transformar y Controlar",
    beCreative_outline_size: "Tama\xf1o del contorno",
    beCreative_line_spacing: "Interlineado",
    beCreative_color: "Color",
    beCreative_font_color: "Color de la fuente",
    beCreative_outline_color: "Color del contorno",
    beCreative_instruction_how_to_use: "\xbfC\xf3mo usar el 3D?",
    beCreative_instruction_rotate360: "Rotar 360",
    beCreative_instruction_rotate_mouse: "Click bot\xf3n izquierdo + Arrastrar (Rat\xf3n)",
    beCreative_instruction_rotate_touch: "Un dedo + arrastrar (Panel t\xe1ctil)",
    beCreative_instruction_zoom: "Acercar",
    beCreative_instruction_zoom_mouse: "Desplazar la rueda (Rat\xf3n)",
    beCreative_instruction_zoom_touch: "Pellizcar hacia afuera (Panel t\xe1ctil)",
    beCreative_instruction_move: "Mover",
    beCreative_instruction_move_mouse: "Click bot\xf3n derecho + Arrastrar (Rat\xf3n)",
    beCreative_instruction_move_touch: "Dos dedos + arrastrar (Panel t\xe1ctil)",
    beCreative_instruction_close_button: "Cerrar",
    beCreative_request_quote_address_club: "Direcci\xf3n (equipo/club/compa\xf1\xeda)",
    beCreative_request_quote_design_application: "Aplicaci\xf3n de dise\xf1o para ropa personalizada",
    beCreative_request_quote_type_of_products: "Tipo de productos",
    beCreative_request_quote_athletics: "Atletismo",
    beCreative_request_quote_leisure: "Pasatiempos",
    beCreative_request_quote_football: "F\xfatbol soccer",
    beCreative_request_quote_triathlon: "Triatlon",
    beCreative_request_quote_cycling: "Ciclismo",
    beCreative_request_quote_type_of_clothing: "Tipo de ropa de ciclismo (personalizada): Producto",
    beCreative_request_quote_jersey_short_sleeve: "Jersey de manga corta",
    beCreative_request_quote_jersey_long_sleeve: "Jersey de manga larga",
    beCreative_request_quote_mid_season_jacket: "Chaqueta de mitad de temporada",
    beCreative_request_quote_technical_jacket: "Chaqueta t\xe9cnica",
    beCreative_request_quote_windstopper: "Rompevientos",
    beCreative_request_quote_rain_jacket: "Chaqueta impermeable",
    beCreative_request_quote_bib_shorts: "Shorts con tirantes",
    beCreative_request_quote_tights: "Medias",
    beCreative_request_quote_quality_line: "Tipo de ropa de ciclismo (personalizada): L\xednea de calidad",
    beCreative_request_quote_dont_know_yet: "No lo s\xe9 todav\xeda",
    beCreative_request_quote_comments_questions: "Comentarios/Preguntas",
    send: "Enviar",
    sent: "Enviado",
    please_fill_all_fields: "Por favor complete todos los campos obligatorios",
    teamstores: "Team Stores",
    already_in_cart: "Ya est\xe1 en el carrito",
    same_supplier_warning: "Solo puede agregar productos del mismo proveedor al carrito",
    go_to_order: "Ir a la orden",
    select_delivery_mode: "SELECCIONAR MODO DE ENTREGA",
    pay_online_delivery_home: "Pagar en l\xednea, entregado en tu domicilio",
    pay_online_delivery_home_express: "Pagar en l\xednea, entregado en tu domicilio (expr\xe9s)",
    pay_online_pickup: "Pagar en l\xednea, recoger en tienda",
    pay_now: "Pagar ahora",
    pay_retry: "Pagar ahora",
    your_card_information: "Informaci\xf3n de tu tarjeta",
    processing_payment: "Procesando pago...",
    select_address: "Seleccionar direcci\xf3n",
    close_form: "Cerrar formato",
    shipping_address: "Direcci\xf3n de env\xedo",
    billing_address: "Direcci\xf3n de facturaci\xf3n",
    create_new_address: "Crear una nueva direcci\xf3n",
    no_address: "No hay direcci\xf3n disponible",
    shipping_method: "M\xe9todo de env\xedo",
    invalid_address: "Direcci\xf3n inv\xe1lida. Por favor seleccione otra.",
    incl_free: "incl. gratis",
    cart_not_found: "Tarjeta no encontrada",
    no_payment_available_yet: "No hay pago disponible todav\xeda",
    confirm_payment: "Confirmar pago",
    credit_card: "Tarjeta de cr\xe9dito",
    credit_card_no: "N\xfamero de tarjeta de cr\xe9dito",
    expiry_date: "Fecha de vencimiento",
    security_code: "C\xf3digo de seguridad",
    make_your_choice: "Haz tu selecci\xf3n",
    you_may_also_like: "Tambi\xe9n te puede gustar esto",
    personalization: "Personalizaci\xf3n",
    i_have_read_and_have_approved_the_privacy_policy: "HE LE\xcdDO Y APROBADO LA POL\xcdTICA DE PRIVACIDAD",
    shipping_cost_included: "Costo de env\xedo incluido",
    shop_the_collection: "Ver la colecci\xf3n",
    same_as_shipping: "Igual que la direccion de env\xedo",
    invoice_summary: "Resumen de la factura",
    sub_total: "Subtotal",
    shipping: "Env\xedo",
    cookie_settings: "Configuraci\xf3n de cookies",
    essential_cookies: "Cookies esenciales",
    analytical_cookies: "Cookies anal\xedticas",
    marketing_cookies: "Cookies de marketing",
    active: "Activas",
    always_active: "Siempre activas",
    cookie_settings_description: "Nuestro sitio web mantiene tres niveles de cookies. Puede ajustar sus preferencias en cualquier momento. Si desea obtener m\xe1s informaci\xf3n sobre qu\xe9 son las cookies y qu\xe9 cookies recopilamos, lea nuestra pol\xedtica de cookies.",
    essential_cookies_description: "Las cookies esenciales son cookies funcionales que aseguran que el sitio web funcione adecuadamente y que tus preferencias sean guardadas (ejemplo;: idioma, regi\xf3n, etc.)",
    analytical_cookies_description: "Las cookies anal\xedticas nos permiten analizar el uso del website y mejorar la experiencia de usuario.",
    marketing_cookies_description: "El objetivo de las cookies de marketing es personalizar tu experiencia y enviarte contenido relevante y ofertas en este sitio web y otros sitios.",
    agree: "Aceptar",
    cookie_notice: "Este sitio web utiliza cookies para mejorar su experiencia de navegaci\xf3n. Al hacer clic en 'Aceptar' consientes el uso de estas cookies.",
    firstName_name: "Nombre",
    lastName_name: "Apellido(s)",
    company_name: "Nombre de la compa\xf1\xeda",
    email_name: "Correo electr\xf3nico",
    phone_name: "Tel\xe9fono",
    vatNumber_name: "N\xfamero para pago de impuestos",
    address1_name: "Calle/Edificio",
    zip_name: "C\xf3digo Postal",
    city_name: "Ciudad",
    province_name: "Estado/Provincia",
    countryCode_name: "Pa\xeds",
    firstName_placeholder: "Nombre",
    lastName_placeholder: "Apellido(s)",
    company_placeholder: "Nombre de la compa\xf1\xeda",
    email_placeholder: "Correo electr\xf3nico",
    phone_placeholder: "Tel\xe9fono",
    vatNumber_placeholder: "N\xfamero para pago de impuestos",
    address1_placeholder: "Calle/Edificio",
    zip_placeholder: "C\xf3digo Postal",
    city_placeholder: "Ciudad",
    province_placeholder: "Estado/Provincia",
    countryCode_placeholder: "Pa\xeds",
    message: "Mensaje",
    studioOrderDetailStepHeadline: "Personalizaci\xf3n",
    studioOrderDetailStepSelectQuantity: "Selecciona una cantidad",
    studioAddToCartStepAllDone: "\xbfHas terminado?",
    studioAddToCartStepOneStepCloser: "\xa1Est\xe1s un paso m\xe1s cerca de recibir tus productos personalizados!",
    studioMenuExpand: "Expandir men\xfa",
    studioMenuCollapse: "Cerrar men\xfa",
    studioViewLink: "Enlace para ver el dise\xf1o",
    studioMyAccount: "Mi cuenta",
    studioShare: "Compartir",
    studioDoneButton: "Hecho",
    studioMaterialStepHeadline: "Tela",
    studioMaterialStepSelectMaterial: "Selecciona tu tela",
    studioColorStepSelectArea: "Seleccione un \xe1rea en el modelo o del men\xfa desplegable para completar:",
    studioColorStepReset: "Reiniciar",
    studioPersonalizationStepPlacement: "Colocaci\xf3n",
    studioPersonalizationStepSelectOption: "Seleccionar una opci\xf3n",
    studioPersonalizationStepPersonalizationSelectZone: "Seleccionar una zona",
    studioPersonalizationStepPersonalizationPrintingTechniques: "T\xe9cnicas",
    studioPersonalizationStepPersonalizationMaximumItems: "Cantidad m\xe1xima de elementos",
    studioTextHeadline: "Texto",
    studioTextEnterText: "Ingresar texto:",
    studioTextEditText: "Editar texto:",
    studioTextPlaceText: "Colocar texto",
    studioTextSelectAreaToPlaceText: "Seleccinonar el \xe1rea para colocar el texto",
    studioTextRemove: "Quitar",
    studioTextMirrorY: "Espejo Y",
    studioTextMirrorX: "Espejo X",
    studioTextDuplicate: "Duplicar",
    studioTextLock: "Bloquear",
    studioTextUnlock: "Desbloquear",
    studioTextVisible: "Visible",
    studioTextInvisible: "Invisible",
    studioTextCurrentlyPlaced: "Actualmente colocado",
    studioTextNoTextPlacedYet: "A\xfan no se han colocado elementos de texto.",
    studioTextNoTextClickIn3D: "Click en el 3D",
    studioTextFontFamily: "Familia tipogr\xe1fica",
    studioTextFontSize: "Tama\xf1o de la fuente",
    studioTextScale: "Escalar",
    studioTextRotate: "Rotar",
    studioTextOutlineSize: "Contorno",
    studioTextCharSpacing: "Espaciado entre caracteres",
    studioTextSelectColor: "Seleccionar color",
    studioTextSelectOutlineColor: "Seleccionar color de contorno",
    studioImageStepHeadline: "Im\xe1genes",
    studioImageDragYourImagesOrClickToUpload: "Arrastra tus im\xe1genes o haz clic para subir",
    studioImageUploadSupportFiles: "(JPG, SVG, AI, PDF, EPS)",
    studioImageImageLibrary: "Biblioteca de im\xe1genes",
    studioImageUploadedImages: "Uploaded Images",
    studioImageNoImagesUploaded: "No se han subido im\xe1genes",
    studioImageNoImagesPlaced: "No hay im\xe1genes colocadas",
    studioImageScale: "Escalar",
    studioImageRemove: "Quitar",
    studioImageMirrorY: "Espejo Y",
    studioImageMirrorX: "Espejo X",
    studioImageDuplicate: "Duplicar",
    studioImageLock: "Bloquear",
    studioImageUnlock: "Desbloquear",
    studioImageInvisible: "Invisible",
    studioImageVisible: "Visible",
    studioImageRotate: "Rotar",
    studioImagePlaceImage: "Colocar imagen",
    studioImageImagesPlaced: "Im\xe1genes colocadas",
    studioPatternFillSelectAnAreaOnTheModelOrFromTheDropdownToFill: "Seleccione un \xe1rea en el modelo o del men\xfa desplegable para completar:",
    studioPatternFillUploadAPrint: "Subir un estampado",
    studioPatternFillSelectAPrint: "Seleccionar un estampado",
    studioPatternFillUsedPrint: "Used print",
    studioPatternFillUploadedPrints: "Estampados cargados",
    studioPatternFillNoPrintUploaded: "No se ha cargado ning\xfan estampado",
    studioPatternFillMoveHorizontally: "Mover horizontalmente",
    studioPatternFillMoveVertically: "Mover verticalmente",
    studioPatternFillScale: "Escalar",
    studioPatternFillRotate: "Rotar",
    studioTitlePrintingTechnique: "T\xe9cnicas de impresi\xf3n",
    search: "Buscar",
    unable_to_load_products: "No se pueden cargar productos...",
    no_result: "No hay resultados...",
    scan_qr_code_to_pay: "Scan the QR code to pay",
    payment_failed: "Payment failed",
    redirecting: "Redirecting...",
    already_have_an_account: "Ya tienes una cuenta?",
    teamstore_not_open: "This teamstore hasn't been opened yet.",
    teamstore_closed: "This teamstore is already closed.",
    cart_empty: "Cart is empty",
    not_available: "Not available",
    product_in_cart_not_available: "Some product(s) in the cart is not available"
});
export var th_TH = _object_spread_props(_object_spread({}, en_UK), {
    studioStepPersonalization: "กรอบใส่สัญลักษณ์",
    studioPersonalizationStepPersonalizationHeadline: "กรอบใส่สัญลักษณ์",
    studioColorStepHeadline: "เพิ่มสี",
    studioPatternFillHeadline: "เพิ่มภาพพิมพ์",
    studioTemplateStepHeadline: "เลือกดีไซน์",
    studioStepPattern: "ซ้อนทับแพทเทิร์น",
    studioOrderDetailStepHeadline: "การปรับเปลี่ยนในแบบของคุณ",
    resetAop: "รีเซ็ตแบบพิมพ์",
    resetColor: "รีเซ็ตสี",
    studioImageOpacity: "ความทึบ",
    studioTextOpacity: "ความทึบ",
    vat: "ภ.พ.",
    product_name: "ชื่อสินค้า",
    item: "สิ่งของ",
    order_no: "หมายเลขสั่งซื้อ",
    back_to_order: "กลับไปที่หน้าสั่งซื้อ",
    placed_on: "สั่งซื้อเมื่อ",
    payment_status: "สถานะการชำระเงิน",
    shipping_to: "ส่งไปที่",
    billing_to: "เรียกเก็บเงินไปที่",
    contact_info: "ข้อมูลติดต่อ",
    order_creation_failed: "ทำรายการสั่งซื้อไม่สำเร็จ",
    copyright: "ลิขสิทธิ์",
    cart: "ตะกร้า",
    account: "บัญชี",
    more_product_info: "ดูข้อมูลสินค้า",
    remainChar: "ตัวอักษรที่ยังเขียนได้",
    studioMissingPersonalizationConfig: "การกำหนดค่ากลุ่มส่วนบุคคลที่ขาดหายไป",
    request_for_quote: "ขอใบเสนอราคา",
    apply: "ใช้",
    processing_payments: "โปรดรอสักครู่ในขณะที่เราดำเนินการตามคำขอของคุณ ...",
    all_done: "เสร็จหมดแล้ว?",
    payment_redirect_note: "หากคุณไม่ได้เปลี่ยนเส้นทางอัตโนมัติโปรดชำระเงินด้วยผู้ให้บริการการชำระเงินโดยคลิกที่ลิงค์ต่อไปนี้:",
    size_chart: "แผนภูมิขนาด",
    shopping_cart: "ตะกร้าช้อปปิ้ง",
    invalid_cart: "ตะกร้าไม่ถูกต้อง",
    invalid_partner_number: "หมายเลขพันธมิตรไม่ถูกต้อง",
    parner_number_used: "มีการใช้หมายเลขพันธมิตรแล้ว",
    product: "ผลิตภัณฑ์",
    quantity: "ปริมาณ",
    register: "ลงทะเบียน",
    tell_us_who_you_are: "บอกเราว่าคุณเป็นใคร",
    sport_club: "สปอร์ตคลับ",
    company: "บริษัท",
    private_person: "คนเอกชน",
    selected_specific: "หากคุณเลือกสปอร์ตคลับหรือ บริษัท",
    enter_company_name: "ป้อนชื่อ บริษัท",
    company_information: "ข้อมูล บริษัท",
    enter_sport_club_name: "ป้อนชื่อสปอร์ตคลับ",
    vat_number: "หมายเลขภาษี",
    example_number: "ese.1234567890",
    personal_information: "ข้อมูลส่วนบุคคล",
    first_name: "ชื่อจริง",
    first_name_placeholder: "ชื่อของคุณ",
    last_name: "นามสกุล",
    last_name_placeholder: "นามสกุลของคุณ",
    repeat_email: "ทำซ้ำอีเมลของคุณ",
    activate_passport: "เปิดใช้งานหนังสือเดินทางผลประโยชน์ของคุณ:",
    passport_number: "หมายเลขหนังสือเดินทางความภักดี",
    passport_card_number_placeholder: "ป้อนหมายเลขบัตร",
    sign_up: "ลงชื่อ",
    price: "ราคา",
    continue_shopping: "ช้อปปิ้งต่อไป",
    checkout_now: "ชำระเงินตอนนี้",
    total: "รวม",
    vat_included: "รวมภาษีมูลค่าเพิ่มแล้ว",
    nothing_in_your_cart: "ตะกร้าสินค้าของคุณว่างเปล่า",
    orderDetailSelectMethod: "กรุณาเลือกวิธีการ",
    validate: "ตรวจสอบ",
    description: "คำอธิบาย",
    vermarc_cart_service_title: "บริการของเราได้เปรียบของคุณ",
    vermarc_cart_service_description: "การทดสอบที่ดีที่สุดของผู้เชี่ยวชาญด้านการกีฬาและการดูแลอย่างเต็มที่สำหรับผลิตภัณฑ์ของเราคือการรับประกันคุณภาพของ Vermarcหากคุณมีคำถามใด ๆ อย่าลังเลที่จะถอยกลับความรู้และประสบการณ์ของเราเรายินดีที่จะช่วยคุณรับประกัน Vermarc:",
    vermarc_cart_service_item_1: "รับประกันคุณภาพ",
    vermarc_cart_service_item_2: "รับประกันการบริการลูกค้าที่ดีที่สุด",
    vermarc_cart_service_item_3: "เครือข่ายตัวแทนจำหน่ายพรีเมียมใน Benelux",
    vermarc_cart_service_item_4: "ไม่พอใจการแลกเปลี่ยนการแลกเปลี่ยนหรือเงินคืน",
    coupon_code: "รหัสคูปอง:",
    delivery_prices: "ราคาส่งมอบ",
    payment_options: "การชำระเงินที่ปลอดภัย",
    orderDetailManualOrder: "เพิ่มออเดอร์ทีละรายการ",
    bank_account_country: "ประเทศบัญชีธนาคาร",
    payment_method: "วิธีการชำระเงิน",
    belgium_and_netherlands: "เบลเยียมเนเธอร์แลนด์และลักเซมเบิร์ก",
    benelux: "เบลเยียมเนเธอร์แลนด์และลักเซมเบิร์ก",
    from: "จาก",
    within_the_eu: "ภายในสหภาพยุโรป",
    outside_the_eu: "นอกสหภาพยุโรป",
    learn_more: "เรียนรู้เพิ่มเติมเกี่ยวกับข้อกำหนดและเงื่อนไขของเรา",
    free: "ฟรี.",
    email: "ส่งอีเมล",
    password: "รหัสผ่าน",
    confirm_password: "ยืนยันรหัสผ่าน",
    password_placeholder: "นาที.8 ตัวอักษร, 1 ตัวพิมพ์เล็ก, 1 ตัวพิมพ์ใหญ่ 1 ถ่านพิเศษ",
    enter_email: "ใส่อีเมลล์ของคุณ",
    enter_password: "ป้อนรหัสผ่านของคุณ",
    forgot_password: "ลืมรหัสผ่าน",
    reset_password: "รีเซ็ตรหัสผ่าน",
    password_reset_required: "บัญชีของคุณต้องรีเซ็ตรหัสผ่านหากต้องการรีเซ็ตคลิกปุ่มด้านล่าง",
    log_in: "เข้าสู่ระบบ",
    no_account_yet: "คุณยังไม่มีบัญชีหรือยังสมัครตอนนี้เลย!",
    user_does_not_exist: "ผู้ใช้ไม่มีอยู่!",
    premium_dealers_title: "ตัวแทนจำหน่ายพรีเมี่ยม",
    premium_dealers_subtitle: "Vermarc Sport เลือกจุดแจกจ่ายอย่างระมัดระวัง",
    premium_dealers_belgium: "เบลเยี่ยม",
    premium_dealers_netherlands: "เนเธอร์แลนด์",
    phone: "โทรศัพท์",
    out_of_stock: "สินค้าหมด",
    size_indication: "ตัวบ่งชี้ขนาดสำหรับภาพประกอบเท่านั้น",
    select_your_care_label: "เลือกฉลากการดูแลของคุณ",
    upcharge: "การดื้อย",
    shipping_cost_calculated_next_step: "ราคาการจัดส่งจะถูกคำนวณในขั้นตอนต่อไป",
    my_account: "บัญชีของฉัน",
    phone_number: "หมายเลขโทรศัพท์",
    default_currency: "สกุลเงินเริ่มต้น",
    default_language: "ภาษาเริ่มต้น",
    default_country: "ประเทศเริ่มต้น",
    vermarc_passport_number: "หมายเลขหนังสือเดินทาง Vermarc",
    vermarc_passport_number_placeholder: "ป้อนหมายเลขหนังสือเดินทางของคุณ",
    saved_addresses: "ที่อยู่ที่บันทึกไว้",
    new_address: "ที่อยู่ใหม่",
    save: "บันทึก",
    set_default_shipping: "ตั้งค่าเริ่มต้นการจัดส่งสินค้า",
    set_default_billing: "ตั้งค่าการเรียกเก็บเงินเริ่มต้น",
    street_or_building: "ถนนหรืออาคาร",
    postcode: "รหัสไปรษณีย์",
    city: "เมือง",
    province: "จังหวัด",
    country: "ประเทศ",
    country_placeholder: "เลือก...",
    saved_designs: "การออกแบบที่บันทึกไว้",
    teamwear_quality_lines: "สายงานที่มีคุณภาพ",
    es_line_description: "Comfort es.l Line เสนอคุณภาพ Vermarc ที่ไม่ซ้ำกันและความสะดวกสบายเพื่อตอบสนองคำถามทางเทคนิคของนักปั่นจักรยานทุกระดับและสำหรับนักปั่นที่มีความหลากหลายมากที่สุด",
    sp_line_description: "พรีเมี่ยม SP.L Line ประโยชน์ของการทดสอบระดับมืออาชีพอันยิ่งใหญ่และการวิจัยของคอลเลกชันระดับพรีเมียมของ Vermarc และให้ข้อเสนอที่เป็นเอกลักษณ์เกี่ยวกับประสิทธิภาพและความสะดวกสบาย",
    pr_line_description: "The Elite PR.R Line ใช้งานนวัตกรรมมากขึ้นเรื่อย ๆ ที่เหมาะกับการทำงานและเบาขึ้นเพื่อตอบสนองความต้องการสูงสุดของนักปั่นมืออาชีพ",
    category_football: "ฟุตบอล",
    category_cycling: "การขี่จักรยาน",
    category_athletics: "กรีฑา",
    filter_by: "กรองโดย",
    order_now: "สั่งซื้อตอนนี้",
    design_now: "ออกแบบตอนนี้",
    size: "ขนาด",
    product_information: "ข้อมูลผลิตภัณฑ์",
    collections: "คอลเลกชัน",
    close: "ปิด",
    beCreative_add_to_cart_confirm: "คุณอยู่ใกล้กับการรับผลิตภัณฑ์ส่วนบุคคลของคุณ!",
    beCreative_add_to_cart_button: "เพิ่มในตะกร้า",
    cancel: "ยกเลิก",
    beCreative_cancel_button: "ยกเลิก",
    beCreative_reset_button: "รีเซ็ต",
    beCreative_color_fill: "เติมสี",
    beCreative_color_a_zone: "สีโซน",
    beCreative_upload_button: "ที่อัพโหลด",
    beCreative_upload_label: "อัพโหลดภาพ",
    beCreative_logo_list: "รายการภาพ",
    beCreative_place_logo: "ภาพสถานที่",
    beCreative_text_input_label: "ข้อความ",
    beCreative_text_input_placeholder: "ป้อนข้อความที่นี่",
    beCreative_add_text: "เพิ่มข้อความ",
    beCreative_apply_change_text: "ใช้การเปลี่ยนแปลง",
    beCreative_select_fonts: "เลือกแบบอักษร",
    beCreative_transform_label: "แปลงและควบคุม",
    beCreative_outline_size: "ขนาดเส้นขอบ",
    beCreative_line_spacing: "ระยะเว้นระยะ",
    beCreative_color: "สี",
    beCreative_font_color: "สีตัวอักษร",
    beCreative_outline_color: "โคนสี",
    beCreative_instruction_how_to_use: "วิธีใช้ 3D",
    beCreative_instruction_rotate360: "หมุน 360",
    beCreative_instruction_rotate_mouse: "คลิกซ้าย + ลาก (เมาส์)",
    beCreative_instruction_rotate_touch: "หนึ่งนิ้ว + ลาก (สัมผัส)",
    beCreative_instruction_zoom: "ซูม",
    beCreative_instruction_zoom_mouse: "เลื่อนล้อ (เมาส์)",
    beCreative_instruction_zoom_touch: "หยิกออก (สัมผัส)",
    beCreative_instruction_move: "ย้าย",
    beCreative_instruction_move_mouse: "คลิกขวา + ลาก (เมาส์)",
    beCreative_instruction_move_touch: "สองนิ้ว + ลาก (สัมผัส)",
    beCreative_instruction_close_button: "ปิด",
    beCreative_request_quote_address_club: "ที่อยู่ (ทีม / สโมสร / บริษัท )",
    beCreative_request_quote_design_application: "การออกแบบแอปพลิเคชันสำหรับเสื้อผ้าส่วนบุคคล",
    beCreative_request_quote_type_of_products: "ประเภทของผลิตภัณฑ์",
    beCreative_request_quote_athletics: "กรีฑา",
    beCreative_request_quote_leisure: "สันทนาการ",
    beCreative_request_quote_football: "ฟุตบอล (ฟุตบอล)",
    beCreative_request_quote_triathlon: "ไตรกีฬา",
    beCreative_request_quote_cycling: "การขี่จักรยาน",
    beCreative_request_quote_type_of_clothing: "ประเภทของเสื้อผ้าขี่จักรยาน (ส่วนบุคคล): ผลิตภัณฑ์",
    beCreative_request_quote_jersey_short_sleeve: "แขนสั้นเจอร์ซีย์",
    beCreative_request_quote_jersey_long_sleeve: "เสื้อแขนยาวเจอร์ซีย์",
    beCreative_request_quote_mid_season_jacket: "แจ็คเก็ตกลางฤดู",
    beCreative_request_quote_technical_jacket: "แจ็คเก็ตทางเทคนิค",
    beCreative_request_quote_windstopper: "Windstopper / Kaos",
    beCreative_request_quote_rain_jacket: "เสื้อกันฝน",
    beCreative_request_quote_bib_shorts: "กางเกงขาสั้นผ้ากันเปื้อน",
    beCreative_request_quote_tights: "ถุงน่อง",
    beCreative_request_quote_quality_line: "ประเภทของเสื้อผ้าขี่จักรยาน (ส่วนบุคคล): สายคุณภาพ",
    beCreative_request_quote_dont_know_yet: "ยังไม่รู้",
    beCreative_request_quote_comments_questions: "ความคิดเห็น / คำถาม",
    send: "ส่ง",
    sent: "ส่ง",
    please_fill_all_fields: "กรุณากรอกฟิลด์ที่จำเป็นทั้งหมด",
    teamstores: "ร้านค้าทีม",
    already_in_cart: "อยู่ในตะกร้าแล้ว",
    same_supplier_warning: "คุณสามารถเพิ่มผลิตภัณฑ์ของซัพพลายเออร์เดียวกันกับตะกร้าเท่านั้น",
    go_to_order: "สั่งซื้อ",
    select_delivery_mode: "เลือกโหมดการจัดส่ง",
    pay_online_delivery_home: "จ่ายออนไลน์ส่งถึงบ้านของคุณ",
    pay_online_delivery_home_express: "จ่ายออนไลน์ส่งถึงบ้านของคุณ (ด่วน)",
    pay_online_pickup: "จ่ายออนไลน์รับในร้าน",
    pay_now: "จ่ายตอนนี้",
    pay_retry: "จ่ายตอนนี้",
    your_card_information: "ข้อมูลบัตรของคุณ",
    processing_payment: "การประมวลผลการชำระเงิน ...",
    select_address: "เลือกที่อยู่",
    close_form: "แบบปิด",
    shipping_address: "ที่อยู่จัดส่ง",
    billing_address: "ที่อยู่เรียกเก็บเงิน",
    create_new_address: "สร้างที่อยู่ใหม่",
    shipping_method: "วิธีการจัดส่งสินค้า",
    invalid_address: "ที่อยู่ที่ไม่ถูกต้อง.โปรดเลือกอันอื่น",
    incl_free: "รวมฟรี",
    cart_not_found: "ไม่พบการ์ด",
    no_payment_available_yet: "ยังไม่มีการชำระเงิน",
    confirm_payment: "ยืนยันการชำระเงิน",
    credit_card: "บัตรเครดิต",
    credit_card_no: "หมายเลขบัตรเครดิต",
    expiry_date: "วันหมดอายุ",
    security_code: "หมายเลข CVV",
    make_your_choice: "โปรดเลือก",
    you_may_also_like: "คุณอาจชอบ",
    personalization: "การทำให้เป็นส่วนตัว",
    i_have_read_and_have_approved_the_privacy_policy: "ฉันได้อ่านและอนุมัตินโยบายความเป็นส่วนตัว",
    shipping_cost_included: "รวมค่าจัดส่ง",
    shop_the_collection: "ดูคอลเลกชัน",
    same_as_shipping: "เช่นเดียวกับที่อยู่จัดส่ง",
    invoice_summary: "สรุปใบแจ้งหนี้",
    sub_total: "รวมย่อย",
    shipping: "การส่งสินค้า",
    cookie_settings: "การตั้งค่าคุกกี้",
    essential_cookies: "คุกกี้ที่จำเป็น",
    analytical_cookies: "คุกกี้วิเคราะห์",
    marketing_cookies: "คุกกี้การตลาด",
    active: "คล่องแคล่ว",
    always_active: "ใช้งานอยู่เสมอ",
    cookie_settings_description: "เว็บไซต์ของเราเก็บคุกกี้สามระดับคุณสามารถปรับการตั้งค่าของคุณได้ตลอดเวลาหากคุณต้องการข้อมูลเพิ่มเติมเกี่ยวกับสิ่งที่คุกกี้และคุกกี้ที่เรารวบรวมโปรดอ่านนโยบายคุกกี้ของเรา",
    essential_cookies_description: "คุกกี้ที่ใช้งานได้เป็นคุกกี้ที่จำเป็นที่ทำให้มั่นใจได้ว่าการทำงานของเว็บไซต์อย่างถูกต้องและการตั้งค่าของคุณ (E.g. , ภูมิภาค) จะถูกบันทึกไว้",
    analytical_cookies_description: "คุกกี้เชิงวิเคราะห์ช่วยให้เราสามารถวิเคราะห์การใช้งานเว็บไซต์และปรับปรุงประสบการณ์ของผู้เข้าชม",
    marketing_cookies_description: "เป้าหมายของคุกกี้การตลาดคือการปรับแต่งประสบการณ์ของคุณและส่งเนื้อหาและข้อเสนอที่เกี่ยวข้องกับเว็บไซต์นี้และเว็บไซต์อื่น ๆ",
    agree: "ตกลง",
    cookie_notice: "เว็บไซต์นี้ใช้คุกกี้เพื่อปรับปรุงประสบการณ์การท่องเว็บของคุณโดยคลิกที่ 'ยอมรับ' คุณยอมรับการใช้คุกกี้เหล่านี้",
    firstName_name: "ชื่อจริง",
    lastName_name: "นามสกุล",
    company_name: "ชื่อ บริษัท",
    email_name: "ส่งอีเมล",
    phone_name: "โทรศัพท์",
    vatNumber_name: "ภาษีมูลค่าเพิ่ม",
    address1_name: "ถนน / อาคาร",
    zip_name: "รหัสไปรษณีย์ / รหัสไปรษณีย์",
    city_name: "เมือง",
    province_name: "รัฐ / จังหวัด",
    countryCode_name: "ประเทศ",
    firstName_placeholder: "ชื่อจริง",
    lastName_placeholder: "นามสกุล",
    company_placeholder: "ชื่อ บริษัท",
    email_placeholder: "ส่งอีเมล",
    phone_placeholder: "โทรศัพท์",
    vatNumber_placeholder: "ภาษีมูลค่าเพิ่ม",
    address1_placeholder: "ถนน / อาคาร",
    zip_placeholder: "รหัสไปรษณีย์ / รหัสไปรษณีย์",
    city_placeholder: "เมือง",
    province_placeholder: "รัฐ / จังหวัด",
    countryCode_placeholder: "ประเทศ",
    message: "ข้อความ",
    studioOrderDetailStepSelectQuantity: "เลือกปริมาณ",
    studioAddToCartStepAllDone: "เสร็จหมดแล้ว?",
    studioAddToCartStepOneStepCloser: "คุณอยู่ใกล้กับการรับผลิตภัณฑ์ส่วนบุคคลของคุณ!",
    studioMenuExpand: "ขยายเมนู",
    studioMenuCollapse: "ยุบเมนู",
    studioSelectOption: "เลือกตัวเลือก",
    studioViewLink: "ลิงค์เพื่อดูการออกแบบ",
    studioMyAccount: "บัญชีของฉัน",
    studioShare: "แบ่งปัน",
    studioDoneButton: "เสร็จสิ้น",
    studioMaterialStepHeadline: "ผ้า",
    studioMaterialStepSelectMaterial: "เลือกผ้าของคุณ",
    studioColorStepSelectArea: "เลือกพื้นที่บนโมเดลหรือจากดรอปดาวน์เพื่อเติม:",
    studioColorStepReset: "รีเซ็ต",
    studioPersonalizationStepPlacement: "ตำแหน่ง",
    studioPersonalizationStepSelectOption: "เลือกตัวเลือก",
    studioPersonalizationStepPersonalizationSelectZone: "เลือกโซน",
    studioPersonalizationStepPersonalizationPrintingTechniques: "เทคนิค",
    studioPersonalizationStepPersonalizationMaximumItems: "รายการสูงสุด:",
    studioTextHeadline: "ข้อความ",
    studioTextEnterText: "ป้อนข้อความ:",
    studioTextEditText: "แก้ไขข้อความ:",
    studioTextPlaceText: "วางข้อความ",
    studioTextSelectAreaToPlaceText: "เลือกพื้นที่เพื่อวางข้อความ",
    studioTextRemove: "ลบ",
    studioTextMirrorY: "กลับด้าน y",
    studioTextMirrorX: "กลับด้าน x",
    studioTextDuplicate: "ทำซ้ำ",
    studioTextLock: "ล็อค",
    studioTextUnlock: "ปลดล็อค",
    studioTextVisible: "มองเห็นได้",
    studioTextInvisible: "มองไม่เห็น",
    studioTextCurrentlyPlaced: "ข้อความที่วางไว้",
    studioTextNoTextPlacedYet: "ยังไม่มีองค์ประกอบข้อความที่วางไว้",
    studioTextNoTextClickIn3D: "คลิกใน 3D",
    studioTextFontFamily: "แบบตัวอักษร",
    studioTextFontSize: "ขนาดตัวอักษร",
    studioTextScale: "สเกล",
    studioTextRotate: "หมุน",
    studioTextOutlineSize: "เส้นขอบ",
    studioTextCharSpacing: "การเว้นวรรค",
    studioTextSelectColor: "เลือกสี",
    studioTextSelectOutlineColor: "เลือกสีเส้นขอบ",
    studioImageStepHeadline: "ภาพ",
    studioImageDragYourImagesOrClickToUpload: "ลากรูปภาพของคุณหรือคลิกเพื่ออัปโหลด",
    studioImageUploadSupportFiles: "(JPG, SVG, AI, PDF, EPS)",
    studioImageImageLibrary: "ห้องสมุดรูปภาพ",
    studioImageUploadedImages: "อัพโหลดรูปภาพ",
    studioImageNoImagesUploaded: "ไม่มีภาพที่อัปโหลด",
    studioImageNoImagesPlaced: "ไม่มีรูปภาพที่วางไว้",
    studioImageScale: "สเกล",
    studioImageRemove: "ลบ",
    studioImageMirrorY: "กลับด้าน y",
    studioImageMirrorX: "กลับด้าน x",
    studioImageDuplicate: "ทำซ้ำ",
    studioImageLock: "ล็อค",
    studioImageUnlock: "ปลดล็อค",
    studioImageInvisible: "มองไม่เห็น",
    studioImageVisible: "มองเห็นได้",
    studioImageRotate: "หมุน",
    studioImagePlaceImage: "วางรูปภาพ",
    studioImageImagesPlaced: "รูปที่วางไว้",
    studioPatternFillSelectAnAreaOnTheModelOrFromTheDropdownToFill: "เลือกพื้นที่บนโมเดลหรือจากดรอปดาวน์เพื่อเติม:",
    studioPatternFillUploadAPrint: "อัปโหลดการพิมพ์",
    studioPatternFillSelectAPrint: "เลือกพิมพ์",
    studioPatternFillUploadedPrints: "อัพโหลดงานพิมพ์",
    studioPatternFillNoPrintUploaded: "ไม่มีการพิมพ์ที่อัปโหลด",
    studioPatternFillMoveHorizontally: "เคลื่อนที่ในแนวนอน",
    studioPatternFillMoveVertically: "ย้ายในแนวตั้ง",
    studioPatternFillScale: "สเกล",
    studioPatternFillRotate: "หมุน",
    studioTitlePrintingTechnique: "เทคนิคการพิมพ์",
    unable_to_load_products: "ไม่สามารถโหลดผลิตภัณฑ์ ...",
    no_result: "ไม่มีผลลัพธ์...",
    storefront_address_warning: "กรุณาเลือกที่อยู่",
    storefront_card_empty_warning: "โปรดป้อนหมายเลขบัตรเครดิต",
    storefront_invalid_card_warning: "บัตรเครดิตไม่ถูกต้อง",
    scan_qr_code_to_pay: "สแกนรหัส QR เพื่อชำระเงิน",
    payment_failed: "การชำระเงินล้มเหลว",
    redirecting: "การเปลี่ยนเส้นทาง ...",
    loading: "การโหลด",
    confirm_email: "ยืนยันอีเมล์",
    view_order_detail: "ดูรายละเอียดการสั่งซื้อ",
    create_new_revision: "สร้างการแก้ไขใหม่",
    done: "เสร็จสิ้น",
    new_password: "รหัสผ่านใหม่",
    repeat_new_password: "ทำซ้ำรหัสผ่านใหม่",
    set_new_password: "ตั้งรหัสผ่านใหม่",
    no_address: "ยังไม่มีที่อยู่",
    checkout: "เช็คเอาท์",
    gst_tax: "GST",
    qst_tax: "qst",
    pst_tax: "pst",
    quote: "อ้าง",
    quote_comments: "อ้างความคิดเห็น",
    confirm: "ยืนยัน",
    signup: "ลงชื่อ",
    password_change_successful: "เปลี่ยนรหัสผ่านสำเร็จแล้ว",
    verify_account: "ตรวจสอบบัญชีของคุณ",
    already_have_an_account: "มีบัญชีแล้วหรือยัง",
    my_orders: "ออเดอร์ของฉัน",
    my_designs: "ดีไซน์ของฉัน",
    fabric: "ผ้า",
    color: "สี",
    text: "ข้อความ",
    logo: "โลโก้",
    label: "ป้าย",
    login: "เข้าสู่ระบบ",
    care_label: "ป้ายไซส์",
    choose_your_fabric: "เลือกผ้าของคุณ",
    interlock: "ผ้าตัว",
    mesh: "ผ้าตาข่าย",
    woven: "ผ้าวูเว่น",
    cotton: "ผ้าคอตต้อน",
    fabric_overview: "สรุปผ้าที่ใช้",
    group: "กลุ่ม",
    construction: "โครงสร้าง",
    content: "ส่วนประกอบ",
    weight: "น้ำหนัก",
    measurements: "ขนาดของผลิตภัณฑ์",
    measurement_table: "ตารางไซส์",
    help_center: "ศูนย์ช่วยเหลือ",
    need_help: "ต้องการความช่วยเหลือ?",
    enter_article_name: "กรุณาระบุชื่อผลิตภัณฑ์",
    my_cart: "ตะกร้าสินค้าของคุณ",
    style_number: "ชื่อผลิตภัณฑ์",
    color_and_print_overview: "สรุปสี และ ลายพิมพ์",
    element: "ชื่ิ้นส่วน",
    print: "ลายพิมพ์",
    color_in_stock_for_this_fabric: "สีที่มีอยู่ในสต๊อก",
    dyed_for_both_sides: "ผ้าย้อม (มีสีสองฝั่ง)",
    color_picker: "เลือกสี (พิมพ์)",
    sublimated_one_side: "ผ้าพิมพ์ (มีสีฝั่งเดียว)",
    upload_your_artwork: "อัพโหลดภาพของคุณ",
    drag_a_file_here: "วางไฟล์ตรงนี้",
    or: "หรือ",
    select_from_computer: "เลือกไฟล์จากคอมพิวเตอร์",
    scale: "ขนาด",
    rotate: "หมุน",
    offset_x: "เลื่อน ซ้าย-ขวา",
    offset_y: "เลื่อน ขึ้น-ลง",
    add_your_own_text: "เพิ่มข้อความของคุณ",
    enter_your_text: "เพิ่มข้อความของคุณ",
    text_overview: "สรุปข้อความ",
    add: "เพิ่ิ่ม",
    add_your_own_logo: "เพิ่มโลโก้ของคุณ",
    customize_care_label: "สร้างป้ายไซส์ของคุณ",
    size_indication_note: "หมายเหตุ: ขนาดที่แสดงเป็นตัวอย่างเท่านั้น",
    select_your_logo: "เลือกโลโก้ของคุณ",
    select_care_label_template: "เลือกป้ายไซส์ของคุณ",
    left: "มุมมองด้านซ้าย",
    right: "มุุมมองด้านขวา",
    side: "มุมมองด้านข้าง",
    front: "มุมมองด้านหน้า",
    three_quarter: "3/4",
    back: "กลับ",
    no_upcharge: "ไม่มีค่าใช้จ่ายเพิ่ม",
    select_printing_technique_to_see_color: "เลือกเทคนิคการพิมพ์เพื่อดูสีที่มี",
    choose_from_our_colors: "เลือกจากสีในคลัง",
    remove: "ลบ",
    position: "ตำแหน่ง",
    logo_overview: "ภาพรวม โลโก้",
    printing_technique: "เทคนิคการพิมพ์",
    shipping_price_not_included: "ไม่รวมค่าขนส่ง (ต่างประเทศ)",
    save_and_continue: "บันทึก และ ดำเนินการต่อ",
    create_folder: "สร้างโฟลเดอร์",
    add_to_cart: "เพิ่มเข้าตะกร้าสินค้า",
    delete: "ลบ",
    review: "ตรวจสอบ",
    orderDetailDescription: "คุณสามารถสั่งออเดอร์ทีละรายการ หรือ วางออเดอร์ใหญ่ลงบนระบบของเรา",
    orderDetailBulkOrder: "วางออเดอร์ใหญ่",
    need_sample: "ต้องการตัวอย่าง",
    total_piece: "จำนวนทั้งหมด",
    collection: "คอลเลคชั่น",
    season: "ซีซั่น",
    discount: "ลดราคา",
    minimum_quantity_50: "จำนวนขั้นต่ำต่อออเดอร์: 50 ชิ้น",
    submitted: "ออเดอร์ที่สั่ง",
    on_hold: "ระหว่างพิจารณา",
    production: "ระหว่างการผลิต",
    shipped: "ส่งออกแล้ว",
    your_orders: "ออเดอร์ของคุณ",
    edit: "แก้ไข",
    search: "ค้นหา",
    contact_us: " ติดต่อเรา",
    sent_successfully: "ส่งสำเร็จ",
    I_agree_with_the: "ฉันเห็นด้วยกับ",
    and_the: "และ",
    applied: "applied",
    bancontact: "แบนคอนแทค",
    bank_transfer: "โอนเงินผ่านธนาคาร",
    cancelled: "ยกเลิก",
    card: "บัตร",
    cash: "เงินสด",
    cash_on_delivery: "เงินสดในการจัดส่ง",
    code: "รหัส",
    complete_payment: "ชำระเงิน",
    coupon_removed: "คูปองถูกลบออก",
    delivered: "ส่ง",
    delivery_provider: "ผู้ให้บริการจัดส่ง",
    external: "ภายนอก",
    failed: "ล้มเหลว",
    free_product_applied_to_cart: "ผลิตภัณฑ์ฟรีถูกใช้กับรถเข็น",
    ideal: "ในอุดมคติ",
    is_not_valid: "ไม่ถูกต้อง",
    missing_payment_method: "วิธีชำระเงินขาดหายไป",
    must_be_selected: "จะต้องถูกเลือก",
    must_not_be_empty: "ต้องไม่ว่างเปล่า",
    of: "ของ",
    off: "ส่วนลด",
    omise: "โอมิเสะ",
    on_item: "ในรายการ",
    open_link_below_to_complete_payment: "เปิดลิงค์ด้านล่างเพื่อชำระเงินให้เสร็จสมบูรณ์",
    paid: "จ่ายเงินแล้ว",
    pay: "จ่ายเงิน",
    pending: "รอดำเนินการ",
    privacy_policy: "นโยบายความเป็นส่วนตัว",
    promo: "โปรโมชั่น",
    promocode_not_found: "ไม่พบรหัสโปรโมชั่น",
    promptpay: "พร้อมเพย์",
    qty: "จำนวน",
    refuned: "คืนเงินแล้ว",
    select: "เลือก",
    shipping_status: "สถานะการจัดส่ง",
    sofort: "โซฟอร์ท",
    stripe: "สไตรป์",
    stripe_connect: "สไตรป์ คอนเนค",
    terms_and_condition: "ข้อกำหนดและเงื่อนไข",
    to_total: "รวม",
    unsupported_payment_propvider: "ไม่พบผู้ให้บริการชำระเงินนี้",
    vermarc_sport: "เวอร์มาร์คสปอร์ต",
    warning: "คำเตือน"
});
export var ja_JP = _object_spread_props(_object_spread({}, en_UK), {
    studioStepPersonalization: "プレースホルダ",
    studioPersonalizationStepPersonalizationHeadline: "プレースホルダ",
    studioPatternFillHeadline: "プリント地を追加",
    studioTemplateStepHeadline: "デザインを選択",
    studioStepPattern: "パターンオーバーレイ",
    studioOrderDetailStepHeadline: "パーソナライゼーション",
    studioColorStepHeadline: "色を追加",
    studioImageOpacity: "不透明な",
    studioTextOpacity: "不透明な",
    copyright: "著作権",
    cart: "カート",
    account: "アカウント",
    more_product_info: "その他の製品情報",
    studio_my_design_title: "私のデザイン",
    studio_my_design_copylink: "リンクをコピーする",
    studio_my_design_copiedLink: "コピーした",
    studio_my_design_description: "Studioにロードするために保存したデザインを選択してください",
    studio_my_design_createdAt: "作成した：",
    studio_my_design_loadmore: "より多くのデザインをロードします",
    studio_load_design: "負荷",
    is_billing_default: "請求のデフォルトです",
    set_as_billing_default: "請求デフォルトとして設定します",
    is_shipping_default: "デフォルトの出荷です",
    set_as_shipping_default: "出荷のデフォルトとして設定します",
    free_product_applied_to_cart: "無料の製品がカートに適用されました",
    on_item: "アイテムについて",
    is_not_valid: "有効ではない",
    and_the: "そしてその",
    missing_payment_method: "支払い方法がありません",
    unsupported_payment_propvider: "サポートされていない支払いプロバイダー",
    pay: "支払う",
    ideal: "IDEAL",
    bancontact: "バンコンアクト",
    sofort: "直ちに",
    stripe: "ストライプ",
    stripe_connect: "ストライプコネクト",
    omise: "省略",
    promptpay: "迅速な支払い",
    external: "external",
    studioMissingPersonalizationConfig: "Personalization Groupの構成がありません",
    request_for_quote: "リクエスト引用",
    vermarc_cart_service_title: "私たちのサービスあなたの利点",
    vermarc_cart_service_description: "スポーツ専門家の究極のテストと私たちの製品のための最大のケアは、Vermarcの品質の保証です。ご質問がある場合は、私たちの知識と経験に戻ってください。私たちはあなたを助けることを嬉しく思います。Vermarcの保証",
    vermarc_cart_service_item_1: "品質保証",
    vermarc_cart_service_item_2: "最適なカスタマーサービスの保証",
    vermarc_cart_service_item_3: "ベネルクのプレミアムディーラーネットワーク",
    vermarc_cart_service_item_4: "満足していない、交換の保証またはお金を返金",
    payment_options: "安全なお支払い",
    from: "から",
    free: "自由。",
    premium_dealers_subtitle: "Vermarc Sportはその配布ポイントを慎重に選択します。",
    premium_dealers_belgium: "ベルギー",
    premium_dealers_netherlands: "オランダ",
    care_label: "ケアラベル",
    interlock: "インターロック",
    group: "グループ",
    construction: "建設",
    content: "コンテンツ",
    enter_article_name: "記事の名前を入力してください",
    element: "素子",
    color_picker: "カラーピッカー",
    scale: "規模",
    offset_x: "オフセットX.",
    offset_y: "オフセットY.",
    customize_care_label: "あなたのケアラベルをカスタマイズします",
    size_indication: "イラストのみのサイズ表示",
    select_your_care_label: "あなたのケアラベルを選択してください",
    three_quarter: "3/4",
    size_indication_note: "注：図のみのサイズ表示",
    vermarc_passport_number: "Vermarc Passport Number",
    vermarc_passport_number_placeholder: "パスポート番号を入力してください",
    set_default_shipping: "デフォルトの配送を設定します",
    set_default_billing: "デフォルトの請求を設定します",
    teamwear_quality_lines: "チームウェア品質ライン",
    es_line_description: "Comfort Es.Lラインは、すべてのレベルのサイクリストの技術的な質問を満たすためのユニークなVermarc品質と快適さを提供しています。",
    sp_line_description: "Premium Sp.Lラインは、Vermarcのプレミアムコレクションの巨大な専門的なテストと研究の利点と、パフォーマンスと快適さに関する独自の命題を与えます。",
    pr_line_description: "Elite Pr.r線は、プロのライダーの最高の要件を満たすために、より革新的で、人間工学的で軽い衣服を踏みます。",
    beCreative_transform_label: "変換＆コントロール",
    beCreative_outline_size: "概要サイズ",
    beCreative_line_spacing: "行間隔",
    beCreative_outline_color: "概要色",
    beCreative_instruction_rotate_mouse: "左クリック+ドラッグ（マウス）",
    beCreative_instruction_rotate_touch: "1指+ドラッグ（タッチ）",
    beCreative_instruction_zoom: "ズーム",
    beCreative_instruction_zoom_mouse: "車輪をスクロールする（マウス）",
    beCreative_instruction_zoom_touch: "ピンチアウト（タッチ）",
    beCreative_instruction_move: "動く",
    beCreative_instruction_move_mouse: "右クリック+ドラッグ（マウス）",
    beCreative_instruction_move_touch: "2指+ドラッグ（タッチ）",
    beCreative_instruction_close_button: "閉じる",
    beCreative_request_quote_design_application: "パーソナライズされた衣料品のための設計アプリケーション",
    beCreative_request_quote_windstopper: "ウィンドストッパー/カオス",
    teamstores: "チームストア",
    select_delivery_mode: "配信モードを選択してください",
    close_form: "閉じる",
    incl_free: "摂動する。自由",
    essential_cookies: "必須クッキー",
    analytical_cookies: "分析クッキー",
    marketing_cookies: "マーケティングクッキー",
    cookie_settings_description: "私達のウェブサイトは3つのレベルのクッキーを保持します。あなたはいつでもあなたの好みを調整することができます。あなたがCookieがどんなクッキーか、どのクッキーかを収集するかについてのより多くの情報が欲しいならば、私達のクッキーポリシーを読んでください。",
    essential_cookies_description: "機能的なクッキーは、DE Webサイトが正しく機能し、あなたの好み（例えば言語、地域）が保存されていることを保証する不可欠なクッキーです。",
    analytical_cookies_description: "分析クッキーは、ウェブサイトの使用を分析し、訪問者の経験を改善することを可能にします。",
    marketing_cookies_description: "クッキーのマーケティングの目的は、あなたの経験をパーソナライズし、あなたにこのウェブサイトやその他のWebサイトについてあなたに関連するコンテンツやオファーを送ることです。",
    agree: "賛成",
    cookie_notice: "このウェブサイトはあなたのサーフィン経験を改善するためにクッキーを使います。「同意」をクリックすると、これらのCookieの使用を受け入れます。",
    email_name: "Eメール",
    email_placeholder: "Eメール",
    studioPersonalizationStepPlacement: "配置",
    studioPersonalizationStepPersonalizationPrintingTechniques: "テクニック",
    studioPersonalizationStepPersonalizationMaximumItems: "最大項目：",
    studioTextNoTextClickIn3D: "3Dをクリックしてください",
    studioTextScale: "規模",
    studioTextOutlineSize: "概要",
    studioTextCharSpacing: "チャーの間隔",
    studioTextSelectOutlineColor: "アウトラインの色を選択してください",
    studioImageUploadSupportFiles: "（JPG、SVG、AI、PDF、EPS）",
    studioImageScale: "規模",
    studioPatternFillScale: "規模",
    studioTitlePrintingTechnique: "印刷技術",
    search: "探す",
    gst_tax: "g",
    qst_tax: "QST.",
    pst_tax: "PST",
    teamstore_not_open: "このチームストールはまだ開かれていません。",
    teamstore_closed: "このチームストールはすでに閉まっています。",
    fit_width: "フィット幅",
    fit_height: "フィットハイト",
    contact_us: "お問合せ",
    studioTextInputPlaceholder: "ご記入欄",
    sent_successfully: "送信完了",
    warning: "警告",
    coupon_removed: "クーポン　削除",
    to_total: "小計",
    code: "コード",
    applied: "適用",
    promocode_not_found: "プロモーションコードが見つかりません",
    select: "選択",
    must_not_be_empty: "記入必須",
    orderDetailManualOrder: "マニュアル注文",
    must_be_selected: "選択必須",
    open_link_below_to_complete_payment: "下記リンクからお支払いに進む",
    terms_and_condition: "ご利用規約",
    I_agree_with_the: "私は同意する",
    privacy_policy: "個人情報の取り扱い",
    of: "に関して",
    vermarc_sport: "フェルマルクスポーツ",
    complete_payment: "お支払い手続き",
    off: "割引",
    promo: "プロモーション",
    card: "カード決済",
    bank_transfer: "銀行振り込み",
    cash: "現金",
    cash_on_delivery: "着払い",
    paid: "お支払い完了",
    refuned: "返金",
    shipping_status: "配送状況",
    pending: "保留中",
    failed: "失敗",
    delivered: "配送完了",
    cancelled: "キャンセル",
    delivery_provider: "配送業者",
    product_name: "商品名",
    item: "商品",
    qty: "数量",
    order_no: "発注番号",
    back_to_order: "注文に戻る",
    placed_on: "注文完了",
    payment_status: "お支払い",
    shipping_to: "配送先",
    billing_to: "ご請求先",
    contact_info: "ご連絡先",
    order_creation_failed: "注文失敗",
    remainChar: "未記入",
    apply: "適用",
    processing_payments: "ご注文処理完了までお待ちください",
    all_done: "完了",
    orderDetailSelectMethod: "ご注文方法を選択してください。",
    payment_redirect_note: "自動的に次ページを移行しない場合は、下記お支払方法から選択してお支払いを完了してください。",
    size_chart: "サイズ寸法",
    shopping_cart: "買い物かご",
    invalid_cart: "買い物カゴが無効です。",
    invalid_partner_number: "パートナー番号が無効です。",
    parner_number_used: "パートナー番号は既に使われております。",
    product: "商品",
    quantity: "数量",
    register: "登録",
    tell_us_who_you_are: "お客様情報をご記入ください",
    sport_club: "団体名",
    company: "法人名",
    private_person: "個人",
    selected_specific: "団体名を入力",
    enter_company_name: "法人名を入力",
    company_information: "法人情報",
    enter_sport_club_name: "団体名",
    vat_number: "VAT 番号",
    example_number: "例 1234567890",
    personal_information: "お客様情報",
    first_name: "名",
    first_name_placeholder: "名",
    last_name: "姓",
    last_name_placeholder: "姓",
    repeat_email: "eメール再入力",
    activate_passport: "有効なパスポート情報を入力してください",
    passport_number: "パスポート番号",
    passport_card_number_placeholder: "カード番号入力",
    sign_up: "サインアップ",
    price: "価格",
    continue_shopping: "買い物を続ける",
    checkout_now: "お会計",
    total: "合計",
    vat_included: "VAT 込み",
    nothing_in_your_cart: "買い物カゴは空です。",
    validate: "有効",
    description: "詳細",
    coupon_code: "クーポンコード:",
    delivery_prices: "配送料",
    bank_account_country: "銀行口座のある国名",
    payment_method: "お支払方法",
    belgium_and_netherlands: "ベルギー, オランダ ルクセンブルク",
    benelux: "ベルギー, オランダ ルクセンブルク",
    within_the_eu: "EU圏内",
    outside_the_eu: "EU圏外",
    learn_more: "規約と条件に関して",
    email: "Eメール",
    password: "パスワード",
    confirm_password: "パスワードの確認",
    password_placeholder: "8文字以上、大文字１、小文字１、記号１を組み合わせてください。",
    enter_email: "Eメール入力",
    enter_password: "パスワード入力",
    forgot_password: "パスワードを忘れた。",
    reset_password: "パスワードをリセット",
    orderDetailBulkOrder: "一括注文",
    password_reset_required: "アカウントのパスワードリセットが必要です。下記リンクボタンをクリックしてリセットしてください。",
    log_in: "ログイン",
    no_account_yet: "アカウントがありません。サインアップしてください。",
    user_does_not_exist: "ユーザーが存在しません。",
    premium_dealers_title: "プレミアムディーラー",
    phone: "電話番号",
    my_orders: "ご注文",
    my_designs: "デザイン",
    fabric: "生地",
    color: "カラー",
    text: "テキスト",
    logo: "ロゴ",
    label: "ラベル",
    login: "ログイン",
    choose_your_fabric: "生地を選んでください",
    mesh: "メッシュ",
    woven: "ウーブン",
    cotton: "綿",
    fabric_overview: "生地詳細",
    weight: "重量",
    measurements: "寸法",
    measurement_table: "寸法表",
    help_center: "ヘルプセンター",
    need_help: "ヘルプ",
    my_cart: "カゴ",
    style_number: "型番",
    color_and_print_overview: "カラーとプリントの詳細",
    print: "プリント",
    color_in_stock_for_this_fabric: "指定生地カラー在庫",
    out_of_stock: "品切れ",
    dyed_for_both_sides: "両面染色",
    sublimated_one_side: "片面昇華",
    upload_your_artwork: "デザインをアップロード",
    drag_a_file_here: "ファイルをドラッグしてください",
    or: "または",
    select_from_computer: "コンピューターより選択",
    rotate: "回転",
    add_your_own_text: "オリジナルテキスト追加",
    enter_your_text: "テキスト入力",
    text_overview: "テキスト概要",
    add: "追加",
    add_your_own_logo: "オリジナルロゴ追加",
    select_your_logo: "ロゴを選択",
    left: "左",
    right: "右",
    side: "側面",
    front: "前面",
    back: "背面",
    upcharge: "追加費用",
    no_upcharge: "追加費用無し",
    select_printing_technique_to_see_color: "印刷方法を選択して、カラー在庫を検索",
    choose_from_our_colors: "色を選択",
    remove: "削除",
    position: "配置",
    orderDetailDescription: "一着ずつマニュアル、もしくは同一デザインでの一括注文も可能です。",
    logo_overview: "ロゴ詳細",
    printing_technique: "プリント方法",
    select_care_label_template: "ラベルテンプレートを選択",
    shipping_price_not_included: "送料は含まれません。",
    shipping_cost_calculated_next_step: "送料は次の工程で計算されます。",
    save_and_continue: "保存して続ける",
    create_folder: "フォルダを作成",
    add_to_cart: "カゴに入れる",
    delete: "削除",
    review: "レビュー",
    need_sample: "サンプル必須",
    total_piece: "総量",
    collection: "コレクション",
    season: "シーズン",
    discount: "ディスカウント",
    minimum_quantity_50: "最低数量: 50 ",
    submitted: "提出済",
    on_hold: "保留",
    production: "生産",
    shipped: "発送済",
    your_orders: "ご注文内容",
    edit: "修正",
    my_account: "マイ　アカウント",
    phone_number: "電話番号",
    default_currency: "デフォルト貨幣",
    default_language: "デフォルト言語",
    default_country: "デフォルト国",
    saved_addresses: "住所",
    new_address: "新住所",
    save: "保存",
    street_or_building: "通りorビル",
    postcode: "郵便番号",
    city: "市",
    province: "県",
    country: "国",
    country_placeholder: "選択...",
    saved_designs: "保存デザイン",
    category_football: "フットボール",
    category_cycling: "サイクリング",
    category_athletics: "アスレチック",
    filter_by: "抽出",
    order_now: "注文する",
    design_now: "デザインする",
    size: "サイズ",
    product_information: "製品情報",
    collections: "コレクション",
    close: "閉じる",
    beCreative_add_to_cart_confirm: "パーソナライズ製品まで、あと１ステップです!",
    beCreative_add_to_cart_button: "カゴに追加",
    cancel: "キャンセル",
    beCreative_cancel_button: "キャンセル",
    beCreative_reset_button: "リセット",
    beCreative_color_fill: "色指定",
    beCreative_color_a_zone: "Aゾーン配色",
    beCreative_upload_button: "アップロード",
    beCreative_upload_label: "イメージをアップロード",
    beCreative_logo_list: "イメージリスト",
    beCreative_place_logo: "イメージを追加",
    beCreative_text_input_label: "テキスト",
    beCreative_text_input_placeholder: "テキスト入力",
    beCreative_add_text: "テキスト追加",
    beCreative_apply_change_text: "テキスト変更",
    beCreative_select_fonts: "フォント選択",
    beCreative_color: "カラー",
    beCreative_font_color: "フォントカラー",
    beCreative_instruction_how_to_use: "3Dの使い方",
    beCreative_instruction_rotate360: "360度回転",
    beCreative_request_quote_address_club: "住所 (チーム/クラブ/法人)",
    beCreative_request_quote_type_of_products: "商品タイプ",
    beCreative_request_quote_athletics: "アスレチック",
    beCreative_request_quote_leisure: "レジャー",
    beCreative_request_quote_football: "フットボール (サッカー)",
    beCreative_request_quote_triathlon: "トライアスロン",
    beCreative_request_quote_cycling: "サイクリング",
    beCreative_request_quote_type_of_clothing: "サイクリング製品タイプ (パーソナライズ): 製品",
    beCreative_request_quote_jersey_short_sleeve: "半袖シャツ",
    beCreative_request_quote_jersey_long_sleeve: "長袖シャツ",
    beCreative_request_quote_mid_season_jacket: "ミッドシーズンジャケット",
    beCreative_request_quote_technical_jacket: "テクニカルジャケット",
    beCreative_request_quote_rain_jacket: "レインジャケット",
    beCreative_request_quote_bib_shorts: "Bビブ　ショーツ",
    beCreative_request_quote_tights: "タイツ",
    beCreative_request_quote_quality_line: "サイクリング製品タイプ (パーソナライズ):  Quality line",
    beCreative_request_quote_dont_know_yet: "検討中",
    beCreative_request_quote_comments_questions: "コメント・質問",
    send: "送信",
    sent: "送信完了",
    please_fill_all_fields: "必須項目をご記入ください",
    already_in_cart: "カゴに既に入ってます。",
    same_supplier_warning: "同サプライヤー製品のみカゴに追加可能",
    go_to_order: "注文に進む",
    pay_online_delivery_home: "オンライン決済, ご自宅配送",
    pay_online_delivery_home_express: "オンライン決済, ご自宅配送 (エクスプレス)",
    pay_online_pickup: "オンライン決済,店頭受け取り",
    pay_now: "お支払い",
    pay_retry: "お支払い",
    your_card_information: "カード情報",
    processing_payment: "お支払処理中...",
    select_address: "住所選択",
    shipping_address: "配送先住所",
    billing_address: "ご請求先",
    create_new_address: "新しい住所",
    shipping_method: "配送方法",
    invalid_address: "住所が無効です。 ほかの住所を入力して下さい。",
    cart_not_found: "カートが見つかりません。",
    no_payment_available_yet: "お支払が済んでません。",
    confirm_payment: "お支払い完了",
    credit_card: "クレジットカード",
    credit_card_no: "クレジットカード番号",
    expiry_date: "有効期限",
    security_code: "セキュリティコード",
    make_your_choice: "選択してください",
    you_may_also_like: "もしかして、こんな製品も",
    personalization: "パーソナライズ",
    i_have_read_and_have_approved_the_privacy_policy: "個人情報規約を確認及び了承しました。",
    shipping_cost_included: "配送料込み",
    shop_the_collection: "コレクションを見る",
    same_as_shipping: "配送先住所と同じ",
    invoice_summary: "請求書概要",
    sub_total: "小計",
    shipping: "配送",
    cookie_settings: "Cookie設定",
    active: "有効",
    always_active: "常に有効",
    firstName_name: "名",
    lastName_name: "姓",
    company_name: "法人名",
    phone_name: "電話番号",
    vatNumber_name: "VAT番号",
    address1_name: "通り・ビル名",
    zip_name: "郵便番号",
    city_name: "市",
    province_name: "県",
    countryCode_name: "国",
    firstName_placeholder: "名",
    lastName_placeholder: "姓",
    company_placeholder: "法人名",
    phone_placeholder: "電話番号",
    vatNumber_placeholder: "VAT番号",
    address1_placeholder: "通り・ビル名",
    zip_placeholder: "郵便番号",
    city_placeholder: "市",
    province_placeholder: "県",
    countryCode_placeholder: "国",
    message: "メッセージ",
    studioOrderDetailStepSelectQuantity: "数量を選択",
    studioAddToCartStepAllDone: "完了",
    studioAddToCartStepOneStepCloser: "パーソナライズ製品まで、あと１ステップです!",
    studioMenuExpand: "もっと見る",
    studioMenuCollapse: "戻る",
    studioSelectOption: "オプションを選択",
    studioViewLink: "デザインを見る",
    studioMyAccount: "マイアカウント",
    studioShare: "シェア",
    studioDoneButton: "完了",
    studioMaterialStepHeadline: "生地",
    studioMaterialStepSelectMaterial: "生地を選択",
    studioColorStepSelectArea: "モデルの部位選択:",
    studioColorStepReset: "リセット",
    studioPersonalizationStepSelectOption: "オプション",
    studioPersonalizationStepPersonalizationSelectZone: "部位選択",
    studioTextHeadline: "テキスト",
    studioTextEnterText: "テキスト入力:",
    studioTextEditText: "テキスト編集:",
    studioTextPlaceText: "テキスト挿入",
    studioTextSelectAreaToPlaceText: "テキスト挿入部位を選択",
    studioTextRemove: "削除",
    studioTextMirrorY: "反転 Y",
    studioTextMirrorX: "反転 X",
    studioTextDuplicate: "複製",
    studioTextLock: "固定",
    studioTextUnlock: "固定解除",
    studioTextVisible: "見せる",
    studioTextInvisible: "隠す",
    studioTextCurrentlyPlaced: "確定",
    studioTextNoTextPlacedYet: "テキスト配置されてません",
    studioTextFontFamily: "フォント形式",
    studioTextFontSize: "フォントサイズ",
    studioTextRotate: "軽天",
    studioTextSelectColor: "色選択",
    studioImageStepHeadline: "イメージ",
    studioImageDragYourImagesOrClickToUpload: "イメージをドラッグかアップロード",
    studioImageImageLibrary: "イメージ　ライブラリー",
    studioImageUploadedImages: "イメージ　アップロード",
    studioImageNoImagesUploaded: "イメージがありません",
    studioImageNoImagesPlaced: "イメージがありません",
    studioImageRemove: "削除",
    studioImageMirrorY: "反転 Y",
    studioImageMirrorX: "反転 X",
    studioImageDuplicate: "複製",
    studioImageLock: "固定",
    studioImageUnlock: "固定解除",
    studioImageInvisible: "隠す",
    studioImageVisible: "見せる",
    studioImageRotate: "回転",
    studioImagePlaceImage: "イメージ挿入",
    studioImageImagesPlaced: "イメージ挿入済",
    studioPatternFillSelectAnAreaOnTheModelOrFromTheDropdownToFill: "モデルの部位選択:",
    studioPatternFillUploadAPrint: "プリントをアップロード",
    studioPatternFillSelectAPrint: "プリントを選択",
    studioPatternFillUsedPrint: "プリントを使用",
    studioPatternFillUploadedPrints: "プリントアップロード完了",
    studioPatternFillNoPrintUploaded: "プリントがありません",
    studioPatternFillMoveHorizontally: "水平移動",
    studioPatternFillMoveVertically: "垂直移動",
    studioPatternFillRotate: "回転",
    unable_to_load_products: "製品をロード出来ません...",
    no_result: "見つかりません...",
    storefront_address_warning: "住所を選択 ",
    storefront_card_empty_warning: "カード番号入力",
    storefront_invalid_card_warning: "カードが無効です。",
    scan_qr_code_to_pay: "QR コードをスキャンしてお支払い",
    payment_failed: "お支払い失敗",
    redirecting: "再試行中...",
    loading: "ロード中",
    confirm_email: "Emailを確定",
    view_order_detail: "注文内容を見る",
    create_new_revision: "修正する",
    done: "完了",
    new_password: "新しいパスワード",
    repeat_new_password: "新しいパスワード再入力",
    set_new_password: "新しいパスワードを作成",
    no_address: "住所がありません",
    checkout: "チェックアウト",
    quote: "見積り",
    quote_comments: "見積注釈",
    confirm: "確定",
    signup: "サインアップ",
    password_change_successful: "パスワード変更完了",
    verify_account: "アカウント確認",
    already_have_an_account: "アカウントをお持ちですか？",
    cart_empty: "カゴが空です",
    not_available: "Not available",
    product_in_cart_not_available: "カゴの中のアイテムが品切れです",
    contact_person: "連絡窓口",
    available_from: "から入荷",
    available_until: "まで販売",
    variant_not_selected_or_not_available: "カゴが無効",
    vat: "付加価値税"
});
export default createVocab({
    en_UK: en_UK,
    en_US: en_US,
    en_CA: en_CA,
    en_AU: en_AU,
    de_DE: de_DE,
    nl_NL: nl_NL,
    fr_FR: fr_FR,
    pt_BR: pt_BR,
    pt_PT: pt_PT,
    fr_CA: fr_CA,
    lv_LV: lv_LV,
    es_ES: es_ES,
    th_TH: th_TH,
    ja_JP: ja_JP
}, en_UK);
