import camelCase from "lodash/camelCase";
import upperFirst from "lodash/upperFirst";
/* eslint-disable @typescript-eslint/no-explicit-any */ /**
 * Get component displayName
 */ export function getDisplayName(Component) {
    return Component.displayName || Component.name || "AnonymousComponent";
}
/**
 * Create a displayName for the composed component in HOC.
 * Note: hocName will be transformed to PascalCase since the return string is a component display name.
 */ export function wrapDisplayName(hocName, component) {
    return "".concat(upperFirst(camelCase(hocName)), "(").concat(getDisplayName(component), ")");
}
