import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { createStandaloneToast } from "@chakra-ui/react";
import { captureException } from "@sentry/browser";
import omit from "lodash/omit";
import { getErrorType } from "./utils/getSentryErrorType";
var ref = createStandaloneToast(), standaloneToast = ref.toast;
export function reportError(error, tags) {
    var errorObj = _instanceof(error, Error) ? error : new Error(error);
    console.error(errorObj, tags);
    if (tags.showToast) {
        standaloneToast({
            title: tags.title || "Some error occurred",
            description: errorObj.message,
            status: "error",
            isClosable: true,
            duration: 4000
        });
    }
    var newTagsValue = _object_spread_props(_object_spread({}, tags), {
        errorType: getErrorType(errorObj)
    });
    if ("production" !== "development") {
        captureException(errorObj, {
            tags: omit(newTagsValue, [
                "showToast"
            ])
        });
    }
}
