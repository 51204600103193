import { grayShade } from "../shades/grayShade";
import { greenShade } from "../shades/greenShade";
import { redShade } from "../shades/redShade";
import { yellowShade } from "../shades/yellowShade";
export var bgColors = {
    primary: grayShade[50],
    item: grayShade[100],
    activeItem: grayShade[200],
    container: "#FFFFFF",
    disabled: grayShade[100],
    delete: redShade[50],
    error: redShade[500],
    warning: yellowShade[500],
    success: greenShade[500],
    transparent: "#FFFFFF00",
    header: grayShade[200],
    errorLight: redShade[50],
    warningLight: yellowShade[50],
    successLight: greenShade[50]
};
