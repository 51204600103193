export var briklHeadlineVaraints = {
    h1: {
        fontSize: "xxl",
        fontWeight: "bold",
        color: "content.primary"
    },
    h2: {
        fontSize: "xl",
        fontWeight: "bold",
        color: "content.primary"
    },
    h3: {
        fontSize: "lg",
        fontWeight: "bold",
        color: "content.primary"
    },
    h4: {
        fontSize: "md",
        fontWeight: "medium",
        color: "content.primary"
    },
    /**
   * @deprecated This is not in our design system
   */ h5: {
        fontSize: "sm",
        fontWeight: "normal",
        color: "content.primary"
    }
};
export var briklTextVariants = {
    body1: {
        fontSize: "md",
        fontWeight: "normal",
        color: "content.primary"
    },
    body2: {
        fontSize: "sm",
        fontWeight: "medium",
        color: "content.primary"
    },
    body3: {
        fontSize: "sm",
        fontWeight: "normal",
        color: "content.primary"
    },
    description: {
        fontSize: "xs",
        fontWeight: "normal",
        color: "content.secondary"
    }
};
