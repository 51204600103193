import produce from "immer";
import omit from "lodash/omit";
import { AUTH_INITIAL_STATE, IActionType } from "@brikl/dashboard-utils";
var reducer = produce(function(draft, action) {
    switch(action.type){
        case IActionType.AUTHORIZED:
            draft.isLoggedIn = true;
            draft.user = action.payload;
            draft.isAuthenticating = false;
            break;
        case IActionType.CHECK_AUTHORIZE:
            draft.isAuthenticating = true;
            break;
        case IActionType.UPDATE_ACCESS_LEVEL:
            draft.accessLevel = omit(action.payload, "__typename");
            break;
        case IActionType.RESET:
            return AUTH_INITIAL_STATE;
        case IActionType.UPDATE_USER_ROLE:
            draft.userRole = action.payload;
    }
}, AUTH_INITIAL_STATE);
export default reducer;
