import { useContext } from "react";
import { BreadcrumbsContext, DEFAULT_BREADCRUMBS_STATE } from "./BreadcrumbsContext";
var fallbackStateTuple = [
    DEFAULT_BREADCRUMBS_STATE,
    function() {
        throw new Error("A fallback state setter is invoked. This happens because of useBreadcrumbsContext is used outside BreadcrumbsProvider. Breadcrumbs will not work properly.");
    }, 
];
export var useBreadcrumbsContext = function() {
    var options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    var suppressUsageError = options.suppressUsageError;
    var stateTuple = useContext(BreadcrumbsContext);
    if (!stateTuple) {
        if (!suppressUsageError) {
            throw new Error("useBreadcrumbsContext is used outside BreadcrumbsProvider. Breadcrumbs will not work properly.");
        }
        return fallbackStateTuple;
    }
    return stateTuple;
};
