import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { useEffect, useState } from "react";
import { useAdminSearchCollectionsLazyQuery, useAdminSearchProductsLazyQuery, useAdminSearchSaleChannelsLazyQuery, useSearchDashboardCollectionsNbLazyQuery, useSearchDashboardProductsNbLazyQuery, useSearchDashboardSalesChannelsNbLazyQuery } from "@brikl/codegen";
import { getSalesChannelTitleWithName } from "@brikl/dashboard-utils";
import { useDashboardFeatureFlags } from "~/apps/dashboard/src/hooks/useDashboardFeatureFlags";
var obProductItemParser = function(products) {
    return products.map(function(product) {
        var ref;
        var title = product === null || product === void 0 ? void 0 : (ref = product.title) === null || ref === void 0 ? void 0 : ref.defaultValue;
        title = product.no !== title ? title + " (".concat(product.no, ")") : title;
        return {
            title: title,
            image: product.thumbnailCdnImageUrl,
            id: product.id
        };
    });
};
var obSalesChannelItemParser = function(salesChannels) {
    return salesChannels.map(function(channel) {
        return {
            id: channel.id,
            image: channel.logo,
            title: channel.title.defaultValue
        };
    });
};
var obCollectionItemParser = function(collections) {
    return collections.map(function(collection) {
        var ref;
        var title = collection === null || collection === void 0 ? void 0 : (ref = collection.title) === null || ref === void 0 ? void 0 : ref.defaultValue;
        title = collection.no !== title ? title + " (".concat(collection.no, ")") : title;
        return {
            title: title,
            image: collection.thumbnailCdnImageUrl,
            id: collection.id
        };
    });
};
var nbItemParser = function(searchResults) {
    var isSalesChannel = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    if (!searchResults) {
        return [];
    }
    return searchResults.edges.map(function(param) {
        var searchResult = param.node;
        var ref, ref1, ref2, ref3;
        var internalName = searchResult === null || searchResult === void 0 ? void 0 : searchResult.name;
        var storeFrontTitle = searchResult === null || searchResult === void 0 ? void 0 : (ref = searchResult.storeFront) === null || ref === void 0 ? void 0 : ref.title;
        var teamStoreTitle = searchResult === null || searchResult === void 0 ? void 0 : (ref1 = searchResult.teamStore) === null || ref1 === void 0 ? void 0 : ref1.title;
        var labelTitle = getSalesChannelTitleWithName(storeFrontTitle, teamStoreTitle, internalName);
        var storeFrontLogo = searchResult === null || searchResult === void 0 ? void 0 : (ref2 = searchResult.storeFront) === null || ref2 === void 0 ? void 0 : ref2.logo;
        var teamStoreLogo = searchResult === null || searchResult === void 0 ? void 0 : (ref3 = searchResult.teamStore) === null || ref3 === void 0 ? void 0 : ref3.logo;
        var salesChannelLogo = storeFrontLogo || teamStoreLogo;
        var searchResultInternalId = (searchResult === null || searchResult === void 0 ? void 0 : searchResult.internalId) ? "(".concat(searchResult.internalId, ")") : "";
        var result = {
            title: isSalesChannel ? labelTitle : "".concat(searchResult.defaultTitle, " ").concat(searchResultInternalId),
            image: searchResult.thumbnailUrl || salesChannelLogo,
            id: searchResult.id
        };
        return result;
    });
};
export default function useAdminQuickSearch() {
    var ref, ref1;
    var NBE_CATALOG = useDashboardFeatureFlags().NBE_CATALOG;
    var ref2 = useState([]), listResultProducts = ref2[0], setListResultProducts = ref2[1];
    var ref3 = useState([]), listResultSalesChannels = ref3[0], setListSalesChannels = ref3[1];
    var ref4 = useState([]), listResultCollections = ref4[0], setListCollections = ref4[1];
    var ref5 = _sliced_to_array(useAdminSearchProductsLazyQuery(), 2), searchProductQuery = ref5[0], productResult = ref5[1];
    var ref6 = _sliced_to_array(useAdminSearchSaleChannelsLazyQuery(), 2), searchSalesChannelQuery = ref6[0], salesChannelResult = ref6[1];
    var ref7 = _sliced_to_array(useAdminSearchCollectionsLazyQuery(), 2), searchCollectionQuery = ref7[0], collectionResult = ref7[1];
    var ref8 = _sliced_to_array(useSearchDashboardProductsNbLazyQuery(), 2), nbSearchProductQuery = ref8[0], nbProductResult = ref8[1];
    var ref9 = _sliced_to_array(useSearchDashboardSalesChannelsNbLazyQuery(), 2), nbSearchSalesChannelQuery = ref9[0], nbSalesChannelResult = ref9[1];
    var ref10 = _sliced_to_array(useSearchDashboardCollectionsNbLazyQuery({
        context: {
            hasuraDependency: true
        }
    }), 2), nbSearchCollectionQuery = ref10[0], nbCollectionResult = ref10[1];
    // life cycle
    useEffect(function() {
        var ref, ref1;
        if (NBE_CATALOG) {
            var ref2;
            var listResults = nbItemParser((ref2 = nbProductResult.data) === null || ref2 === void 0 ? void 0 : ref2.products);
            setListResultProducts(listResults);
            return;
        }
        if (((ref = productResult.data) === null || ref === void 0 ? void 0 : (ref1 = ref.adminSearchProducts) === null || ref1 === void 0 ? void 0 : ref1.length) > 0) {
            var ref3;
            var listProducts = obProductItemParser(((ref3 = productResult.data) === null || ref3 === void 0 ? void 0 : ref3.adminSearchProducts) || []);
            setListResultProducts(listProducts);
        }
    }, [
        productResult.data,
        nbProductResult.data,
        NBE_CATALOG
    ]);
    useEffect(function() {
        var ref, ref1;
        if (NBE_CATALOG) {
            var ref2;
            var listResults = nbItemParser((ref2 = nbSalesChannelResult.data) === null || ref2 === void 0 ? void 0 : ref2.salesChannels, true);
            setListSalesChannels(listResults);
            return;
        }
        if (((ref = salesChannelResult.data) === null || ref === void 0 ? void 0 : (ref1 = ref.adminSearchSalesChannels) === null || ref1 === void 0 ? void 0 : ref1.length) > 0) {
            var listSalesChannels = obSalesChannelItemParser(salesChannelResult.data.adminSearchSalesChannels || []);
            setListSalesChannels(listSalesChannels);
        }
    }, [
        NBE_CATALOG,
        (ref = nbSalesChannelResult.data) === null || ref === void 0 ? void 0 : ref.salesChannels,
        salesChannelResult.data, 
    ]);
    useEffect(function() {
        var ref, ref1;
        if (NBE_CATALOG) {
            var ref2;
            var listResults = nbItemParser((ref2 = nbCollectionResult.data) === null || ref2 === void 0 ? void 0 : ref2.productCollections);
            setListCollections(listResults);
            return;
        }
        if (((ref = collectionResult.data) === null || ref === void 0 ? void 0 : (ref1 = ref.adminSearchCollections) === null || ref1 === void 0 ? void 0 : ref1.length) > 0) {
            var listCollections = obCollectionItemParser(collectionResult.data.adminSearchCollections || []);
            setListCollections(listCollections);
        }
    }, [
        NBE_CATALOG,
        collectionResult.data,
        (ref1 = nbCollectionResult.data) === null || ref1 === void 0 ? void 0 : ref1.productCollections, 
    ]);
    var nbeSearch = function(keyword, param) {
        var isSearchOnlyProduct = param.isSearchOnlyProduct, isSearchOnlyCollection = param.isSearchOnlyCollection, isSearchOnlySalesChannel = param.isSearchOnlySalesChannel;
        if (isSearchOnlyProduct) {
            nbSearchProductQuery({
                variables: {
                    keyword: keyword
                }
            });
        }
        if (isSearchOnlyCollection) {
            nbSearchCollectionQuery({
                variables: {
                    query: keyword
                }
            });
        }
        if (isSearchOnlySalesChannel) {
            nbSearchSalesChannelQuery({
                variables: {
                    query: keyword
                }
            });
        }
    };
    var obeSearch = function(keyword, param) {
        var isSearchOnlyProduct = param.isSearchOnlyProduct, isSearchOnlyCollection = param.isSearchOnlyCollection, isSearchOnlySalesChannel = param.isSearchOnlySalesChannel;
        if (isSearchOnlyProduct) {
            searchProductQuery({
                variables: {
                    name: keyword
                }
            });
        }
        if (isSearchOnlyCollection) {
            searchCollectionQuery({
                variables: {
                    name: keyword
                }
            });
        }
        if (isSearchOnlySalesChannel) {
            searchSalesChannelQuery({
                variables: {
                    name: keyword
                }
            });
        }
    };
    var reset = function() {
        setListResultProducts([]);
        setListCollections([]);
        setListSalesChannels([]);
    };
    var search = function(keyword, searchOptions) {
        if (!keyword) {
            reset();
            if (NBE_CATALOG) {
                return nbeSearch(keyword, searchOptions);
            }
        }
        // Need at least 3 character before we perform search (old logic)
        if (keyword.length <= 2) return;
        if (NBE_CATALOG) {
            return nbeSearch(keyword, searchOptions);
        }
        return obeSearch(keyword, searchOptions);
    };
    var isLoading = productResult.loading || collectionResult.loading || salesChannelResult.loading || nbProductResult.loading || nbCollectionResult.loading || nbSalesChannelResult.loading;
    return {
        search: search,
        reset: reset,
        isLoading: isLoading,
        listResultProducts: listResultProducts,
        listResultCollections: listResultCollections,
        listResultSalesChannels: listResultSalesChannels
    };
};
