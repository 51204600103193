import { ChangeEvent, useEffect, useState } from 'react'

import {
  useDashboardGetTermOfServiceAcceptanceQuery,
  useDashboardUpdateTermOfServiceAcceptanceMutation,
} from '@brikl/codegen'
import { useDisclosure } from '@brikl/design-core'

export const useTermsOfService = () => {
  const { isOpen: isModalOpen, onClose, onOpen } = useDisclosure()
  const [isAccected, setIsAccecpted] = useState<boolean>(false)

  const { data } = useDashboardGetTermOfServiceAcceptanceQuery()
  const [updateTOS, updateTOSState] =
    useDashboardUpdateTermOfServiceAcceptanceMutation()

  const onClickContinue = async () => {
    await updateTOS()
    onClose()
  }

  const checkTOS = () => {
    if (data?.adminUser?.tosAcceptedAt === null) onOpen()
  }

  useEffect(() => {
    checkTOS()
  }, [data?.adminUser?.tosAcceptedAt])

  const onChangeAccept = (e: ChangeEvent<HTMLInputElement>) =>
    setIsAccecpted(e.target.checked)

  return {
    isAccected,
    isModalOpen,
    isLoading: updateTOSState?.loading,
    onClickContinue,
    onChangeAccept,
  }
}
