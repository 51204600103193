import { useContext } from "react";
import { DEFAULT_LANG_CODE, LocalizationContext } from "./LocalizationContext";
var fallbackStateTuple = [
    DEFAULT_LANG_CODE,
    function() {
        throw new Error("A fallback state setter was invoked. This happens because of useLocalizationContext is used outside LocalizationProvider. Localization will not work properly.");
    }, 
];
export var useLocalizationContext = function() {
    var options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    var suppressUsageError = options.suppressUsageError;
    var stateTuple = useContext(LocalizationContext);
    if (!stateTuple) {
        if (!suppressUsageError) {
            throw new Error("useLocalizationContext is used outside LocalizationProvider. Localization will not work properly.");
        }
        return fallbackStateTuple;
    }
    return stateTuple;
};
