import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { ApolloManager } from "./ApolloManager";
/**
 * Runs the GraphQL query with Apollo.
 *
 * @example
 * // Query using the default application.
 * const { data } = await query(GetShopDocument, { shopId: "vermarc" })
 *
 * // Query using a different Apollo client using the app option
 * const { data } = await query(GetShopDocument, ..., { app: 'content' })
 *
 * // Query with a custom Apollo option (e.g. context to call new backend)
 * const { data } = await query(GetShopDocument, ..., { context: { hasuraDependency: true }})
 *
 * @param query document node of the query, should be generated from codegen.
 * @param variables query variables to pass into this GraphQL query.
 * @param options query options, such as selecting the apollo client type.
 **/ export var query = function() {
    var _ref = _async_to_generator(function(query, variables, options) {
        var apollo, _tmp;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        ApolloManager.waitForClient(options === null || options === void 0 ? void 0 : options.app)
                    ];
                case 1:
                    apollo = _state.sent();
                    _tmp = {};
                    return [
                        4,
                        apollo === null || apollo === void 0 ? void 0 : apollo.query(_object_spread((_tmp.query = query, _tmp.variables = variables, _tmp), options))
                    ];
                case 2:
                    return [
                        2,
                        _state.sent()
                    ];
            }
        });
    });
    return function query(query, variables, options) {
        return _ref.apply(this, arguments);
    };
}();
/**
 * Runs the GraphQL mutation with Apollo.
 *
 * @example
 * const { data } = await mutate(UpdateProductDocument, { productId })
 *
 * @param query document node of the mutation, should be generated from codegen.
 * @param variables mutation variables to pass into this GraphQL mutation.
 * @param options mutation options, such as selecting the apollo client type.
 **/ export var mutate = function() {
    var _ref = _async_to_generator(function(mutation, variables, options) {
        var apollo, _tmp;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        ApolloManager.waitForClient(options === null || options === void 0 ? void 0 : options.app)
                    ];
                case 1:
                    apollo = _state.sent();
                    _tmp = {};
                    return [
                        2,
                        apollo === null || apollo === void 0 ? void 0 : apollo.mutate(_object_spread((_tmp.mutation = mutation, _tmp.variables = variables, _tmp), options))
                    ];
            }
        });
    });
    return function mutate(mutation, variables, options) {
        return _ref.apply(this, arguments);
    };
}();
