import { createVocab } from "./createVocab";
var en_US = {
    "payment.method.credit-card": "Credit/ Debit Card",
    "payment.method.prompt-pay": "Prompt Pay",
    "payment.method.paygate": "Credit/Debit Card",
    "payment.method.ban-contact": "Bancontact",
    "payment.method.ideal": "iDEAL",
    "payment.method.sofort": "Sofort",
    "payment.method.manual": "Manual",
    "payment.method.manual.bank-transfer": "Bank Transfer",
    "payment.method.manual.purchase-order": "Purchase Order",
    "payment.method.apple-pay": "Apple Pay"
};
export default createVocab({
    en_US: en_US
}, en_US);
