import { overrideShade } from "../../functions/overrideShade";
export var grayShade = overrideShade("#98A3B3", {
    50: "#F7FAFC",
    100: "#F2F4F6",
    200: "#E7EAEF",
    400: "#B5BDC9",
    700: "#4A5463",
    800: "#1F252E",
    900: "#080C16"
});
