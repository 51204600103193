export var environment = {
    API_STAGE: "production",
    SCHEMA_PATH: "https://api.mybrikl.com/graphql",
    GATSBY_API_URL: "https://api.mybrikl.com/graphql",
    AUTH0_DOMAIN: "auth.brikl.com",
    AUTH0_CLIENT_ID: "wmiVP4HcdWq4BzqwSRD72zNOZFuFr4BY",
    PARENT_THEME_ID: "25c237fd-32f0-4396-93ac-35fc37b5bc92",
    STRIPE_CLIENT_ID: "ca_GFEnj2KF7Zg0dcJyujr7ztV2uCBuv9uY",
    STRIPE_TEST_CLIENT_ID: "ca_GFEnmRztPJ5QcOH3Op2RTESpgi9aYGt8",
    studioURL: "https://studio.brikl.com"
};
