import get from "lodash/get";
import { routes } from "../constants/routes";
export var buildBreadCrumbsRoute = function(routeKey) {
    var storefrontsRoute = "dashboard.children.saleschannels.children.storefronts";
    var teamStoresRoute = "dashboard.children.saleschannels.children.teamstores";
    switch(true){
        case routeKey.includes(storefrontsRoute):
            if (routeKey === storefrontsRoute) {
                return get(routes, storefrontsRoute, undefined);
            } else {
                var newRouteKey = routeKey.replace(storefrontsRoute, "dashboard.children.saleschannel");
                return get(routes, newRouteKey, undefined);
            }
        case routeKey.includes(teamStoresRoute):
            if (routeKey === teamStoresRoute) {
                return get(routes, teamStoresRoute, undefined);
            } else {
                var newRouteKey1 = routeKey.replace(teamStoresRoute, "dashboard.children.saleschannel");
                return get(routes, newRouteKey1, undefined);
            }
    }
    return get(routes, routeKey, undefined);
};
