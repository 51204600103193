export var catalogProductGraphQLWhitelist = [
    // Mutatoin -> Product
    "dashboardCreateProductsNB",
    "dashboardCopyProductNB",
    "dashboardUpdateProductInfoById",
    "dashboardDeleteProductByIdNB",
    // Mutation -> Product variations
    "dashboardAssignProductOptionValues",
    "dashboardCreateProductOptionNB",
    "dashboardCreateProductOptionValueNB",
    "dashboardUpdateProductOptionNB",
    "dashboardUpdateProductOptionValueNB",
    "dashboardDeleteProductOptionByIdNB",
    "dashboardDeleteProductOptionValueNB",
    // Mutation -> Media
    "dashboardCreateProductMediaNB",
    "dashboardUpdateProductMediaNB",
    "dashboardDeleteProductMediaNB",
    // Mutation -> Product Personalization
    "dashboardCreateProductPersonalizationGroupNB",
    // Query -> Product
    "dashboardGetProductsNB",
    "dashboardGetProductByIdNB",
    // Query -> Product variations
    "dashboardGetProductOptionByIdNB",
    "dashboardGetAllProductOptions",
    // Mutation => Product collections
    "addDashboardProductToCollectionNB",
    "removeDashboardProductFromCollectionNB",
    //Query -> Product attributes
    "removeDashboardAtrributeFromProductNB",
    "addDashboardAtrributeToProductNB", 
];
