import { WebSocketLink } from "@apollo/client/link/ws";
import { HASURA_API_URI_WS, HASURA_BACKEND_V2_API_URI_WS } from "../constants/endpoints";
var webSocketLinkCreator = function(uri) {
    return function(connectionParams) {
        return new WebSocketLink({
            uri: uri,
            options: {
                reconnectionAttempts: 5,
                reconnect: true,
                timeout: 30000,
                lazy: true,
                connectionParams: connectionParams
            }
        });
    };
};
export var createWSLink = webSocketLinkCreator(HASURA_BACKEND_V2_API_URI_WS);
export var createLegacyWSLink = webSocketLinkCreator(HASURA_API_URI_WS);
