import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import { ApolloError } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
export var ErrorType;
(function(ErrorType) {
    ErrorType["GRAPH_QL_ERRORS"] = "GRAPH_QL_ERRORS";
    ErrorType["GRAPH_QL_CLIENT_ERRORS"] = "GRAPH_QL_CLIENT_ERRORS";
    ErrorType["CLIENT_ERRORS"] = "CLIENT_ERRORS";
    ErrorType["NETWORK_ERROR"] = "NETWORK_ERROR";
})(ErrorType || (ErrorType = {}));
export var getErrorType = function(error) {
    var ref;
    if (!_instanceof(error, ApolloError)) return ErrorType.CLIENT_ERRORS;
    var graphqlErrors = error === null || error === void 0 ? void 0 : error.graphQLErrors;
    var graphqlClientErrors = error === null || error === void 0 ? void 0 : error.clientErrors;
    var networkError = error === null || error === void 0 ? void 0 : (ref = error.networkError) === null || ref === void 0 ? void 0 : ref.message;
    switch(true){
        case !isEmpty(graphqlErrors):
            return ErrorType.GRAPH_QL_ERRORS;
        case !isEmpty(graphqlClientErrors):
            return ErrorType.GRAPH_QL_CLIENT_ERRORS;
        case !isEmpty(networkError):
            return ErrorType.NETWORK_ERROR;
        default:
            return ErrorType.CLIENT_ERRORS;
    }
};
