import './styles.css'
// Used by color picker in @brikl/design-system
import 'libs/design-system/src/components/BriklColorPicker/styles.css'
// Used by @brikl/uploadbox
import 'cropperjs/dist/cropper.css'
// Realistic render mode css
import 'libs/studio-rendering/src/babylonjs/css/main.css'

import React, { useEffect } from 'react'

import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { AppProps } from 'next/app'

import { DashboardProvider } from '@brikl/dashboard-provider'
import { initFabricConfig, useScriptLoader } from '@brikl/studio'

import { App as AppShell } from '../../dashboard/src/app/components'

interface IProps {
  apollo: ApolloClient<NormalizedCacheObject>
}

function NextApp(props: AppProps<IProps>) {
  return (
    <DashboardProvider>
      <AppComponent {...props} />
    </DashboardProvider>
  )
}

function AppComponent({ pageProps, Component }) {
  const { isAuthenticated } = useAuth0()
  const { loadScript } = useScriptLoader(
    'https://unpkg.com/fabric@3.6.6/dist/fabric.min.js'
  )

  useEffect(() => {
    if (isAuthenticated) {
      loadScript(initFabricConfig)
    }
  }, [isAuthenticated, loadScript])

  return (
    <AppShell>
      <Component {...pageProps} />
    </AppShell>
  )
}

export default NextApp
