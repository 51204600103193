import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { useMemo } from "react";
import uniq from "lodash/uniq";
import { createFeatureFlagMap } from "../services/featureFlag";
import { useCurrentShop, useCurrentShopAdmin } from "../shop/shop.context";
export var useDashboardFeatureFlags = function() {
    var shop = useCurrentShop();
    var currentShopAdmin = useCurrentShopAdmin();
    var userEnabledFlags = currentShopAdmin === null || currentShopAdmin === void 0 ? void 0 : currentShopAdmin.featuresEnabled;
    return useMemo(function() {
        var allFlags = shop.availableFeatures || [];
        var shopEnabledFlags = shop.featuresEnabled || [];
        var enabledFlags = uniq(_to_consumable_array(shopEnabledFlags).concat(_to_consumable_array(userEnabledFlags || [])));
        return createFeatureFlagMap(allFlags, enabledFlags);
    }, [
        shop.availableFeatures,
        shop.featuresEnabled,
        userEnabledFlags
    ]);
};
