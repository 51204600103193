import has from "lodash/has";
import { PERMISSION_ROLE_ACCESS } from "@brikl/dashboard-utils";
/**
 * Get real user access level key mapped with alias key
 * @param key permissionKey permisison key of each feature
 * @returns keyof GQL_UserAccessLevelQueryOutput
 */ function getApiAccessKeyByAliasKey(key) {
    if (!has(PERMISSION_ROLE_ACCESS, key)) throw new Error("No permission ".concat(key, " exist"));
    // Map alias access key with actual role access key from API
    return PERMISSION_ROLE_ACCESS[key].key;
}
export default getApiAccessKeyByAliasKey;
