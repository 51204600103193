export var roleViewsAccess = {
    View_Catalog: {
        key: "canViewCatalog",
        value: false
    },
    View_Content: {
        key: "canViewContent",
        value: false
    },
    View_Customers: {
        key: "canViewCustomers",
        value: false
    },
    View_Deployment: {
        key: "canViewDeployment",
        value: false
    },
    View_Design: {
        key: "canViewDesign",
        value: false
    },
    View_Discounts: {
        key: "canViewDiscounts",
        value: false
    },
    View_Leads: {
        key: "canViewLeads",
        value: false
    },
    View_Orders: {
        key: "canViewOrders",
        value: false
    },
    View_Quotes: {
        key: "canViewQuotes",
        value: false
    },
    View_Reporting: {
        key: "canViewReporting",
        value: false
    },
    View_SalesChannels: {
        key: "canViewSalesChannels",
        value: false
    },
    View_Themes: {
        key: "canViewThemes",
        value: false
    }
};
/**
 * This is parhse II for update permission
 */ // const ROLE_UPDATE_ACCESS = {
//   Update_Lead: {
//     key: 'canUpdateLead',
//     value: false,
//   },
// }
export var permissionRoleAccess = roleViewsAccess;
