/**
 * Test whether a given box (in fabric.js format) contains a given point or not.
 * @param corner - The given box to test, in fabric.js format (should have bl, br, tr, tl as its properties which are the coordinates of the corners, in the [x, y] format).
 * @param {number} x - x coordinate of the test point.
 * @param {number} y - y coordinate of the test point.
 */ export var boxContainsPoint = function(corner, x, y) {
    return pointInPolygon([
        x,
        y
    ], [
        corner.bl,
        corner.br,
        corner.tr,
        corner.tl
    ].map(function(coord) {
        return [
            coord.x,
            coord.y, 
        ];
    }));
};
/**
 * Test Point In Polygon: Given a point as [x, y] and a polygon as an array of points (either clockwisely or counterclockwisely) ordered.
 * Returns true if the point is inside the polygon, and false otherwise.
 * @param {[number, number]} point - Array of size 2, stands for [x, y] coordinate of the point.
 * @param {Array<[number, number]>} vs - Array of size N, where N is the number of vertices in a polygon. Each element in the array is an array of size 2, denoting [x, y] coordinates of each vertex.
 * @see https://github.com/substack/point-in-polygon
 * @see http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
 */ export var pointInPolygon = function(point, vs) {
    var x = point[0];
    var y = point[1];
    var inside = false;
    for(var i = 0, j = vs.length - 1; i < vs.length; j = i++){
        var xi = vs[i][0];
        var yi = vs[i][1];
        var xj = vs[j][0];
        var yj = vs[j][1];
        var intersect = yi > y !== yj > y && x < (xj - xi) * (y - yi) / (yj - yi) + xi;
        if (intersect) inside = !inside;
    }
    return inside;
};
/**
 * Rotate the given point around a given fixed point by a given angle in radians (counterclockwisely in X-Y plane but clockwisely in Left-Top plane).
 * @param {{x: number, y: number}} point - The given point that is to be rotated. (Formatted as an object with x and y)
 * @param {[number, number]} fixed - The fixed point for rotation.
 * @param {number} angle - The rotation angle in radians.
 * @see https://en.wikipedia.org/wiki/Rotation_matrix
 */ export var rotateAroundPointByAngle = function(point, fixed, angle) {
    point.x -= fixed[0];
    point.y -= fixed[1];
    var nx = point.x * Math.cos(angle) - point.y * Math.sin(angle);
    var ny = point.x * Math.sin(angle) + point.y * Math.cos(angle);
    point.x = nx + fixed[0];
    point.y = ny + fixed[1];
};
