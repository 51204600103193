import * as Operations from "./new-backend";
import * as ApolloReactHooks from "@apollo/client";
export function useBulkDeleteDashboardCouponCodesNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.BulkDeleteDashboardCouponCodesNbDocument, baseOptions);
}
export function useBulkUpdateDashboardCouponCodesNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.BulkUpdateDashboardCouponCodesNbDocument, baseOptions);
}
export function useAddDashboardAtrributeToProductNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AddDashboardAtrributeToProductNbDocument, baseOptions);
}
export function useAddDashboardChildCollectionNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AddDashboardChildCollectionNbDocument, baseOptions);
}
export function useAddDashboardCollectionToSalesChannelNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AddDashboardCollectionToSalesChannelNbDocument, baseOptions);
}
export function useAddDashboardDiscountFreeProductNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AddDashboardDiscountFreeProductNbDocument, baseOptions);
}
export function useAddDashboardDiscountRuleObjectNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AddDashboardDiscountRuleObjectNbDocument, baseOptions);
}
export function useAddDashboardProductPackageToSalesChannelMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AddDashboardProductPackageToSalesChannelDocument, baseOptions);
}
export function useAddDashboardProductPackageToSalesChannelNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AddDashboardProductPackageToSalesChannelNbDocument, baseOptions);
}
export function useAddDashboardProductToCollectionNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AddDashboardProductToCollectionNbDocument, baseOptions);
}
export function useAddDashboardProductToSalesChannelNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AddDashboardProductToSalesChannelNbDocument, baseOptions);
}
export function useAddLeadMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AddLeadDocument, baseOptions);
}
export function useApplyProductImagesToPlaceholderNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.ApplyProductImagesToPlaceholderNbDocument, baseOptions);
}
export function useApplyDashboardProductLightPresetToProductNbeMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.ApplyDashboardProductLightPresetToProductNbeDocument, baseOptions);
}
export function useAssignAdminPortalUserRoleMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AssignAdminPortalUserRoleDocument, baseOptions);
}
export function useAssignDashboardAccountContactFromAccountNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AssignDashboardAccountContactFromAccountNbDocument, baseOptions);
}
export function useAssignDashboardBrandToProductNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AssignDashboardBrandToProductNbDocument, baseOptions);
}
export function useAssignDashboardContactToTeamStoresNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AssignDashboardContactToTeamStoresNbDocument, baseOptions);
}
export function useAssignDashboardManagerToAdminUserNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AssignDashboardManagerToAdminUserNbDocument, baseOptions);
}
export function useAssignDashboardMicrostoreToContactNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AssignDashboardMicrostoreToContactNbDocument, baseOptions);
}
export function useAssignPaymentConfigurationMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AssignPaymentConfigurationDocument, baseOptions);
}
export function useAssignSalesChannelProductVariantsNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.AssignSalesChannelProductVariantsNbDocument, baseOptions);
}
export function useBulkAddDashboardProductsToSalesChannelMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.BulkAddDashboardProductsToSalesChannelDocument, baseOptions);
}
export function useBulkAddProductToSalesChannelsMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.BulkAddProductToSalesChannelsDocument, baseOptions);
}
export function useBulkCreateSalesChannelSavedPlaceholderItemNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.BulkCreateSalesChannelSavedPlaceholderItemNbDocument, baseOptions);
}
export function useBulkDeleteSalesChannelSavedPlaceholderItemNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.BulkDeleteSalesChannelSavedPlaceholderItemNbDocument, baseOptions);
}
export function useBulkUpdateSalesChannelProductsPriceModifierMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.BulkUpdateSalesChannelProductsPriceModifierDocument, baseOptions);
}
export function useBulkUpdateSalesChannelSavedPlaceholderItemNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.BulkUpdateSalesChannelSavedPlaceholderItemNbDocument, baseOptions);
}
export function useConfirmAdminPortalUserInvitationNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.ConfirmAdminPortalUserInvitationNbDocument, baseOptions);
}
export function useConnectInstagramAccountMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.ConnectInstagramAccountDocument, baseOptions);
}
export function useCopyDashboardDocumentTemplateMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CopyDashboardDocumentTemplateDocument, baseOptions);
}
export function useCreateCustomPlaceholderNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateCustomPlaceholderNbDocument, baseOptions);
}
export function useCreateDashboardAccountSalesRepNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardAccountSalesRepNbDocument, baseOptions);
}
export function useCreateDashboardAdminUserNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardAdminUserNbDocument, baseOptions);
}
export function useCreateDashboardCollectionNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardCollectionNbDocument, baseOptions);
}
export function useCreateDashboardContactNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardContactNbDocument, baseOptions);
}
export function useCreateDashboardDeploymentNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardDeploymentNbDocument, baseOptions);
}
export function useCreateDashboardDiscountNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardDiscountNbDocument, baseOptions);
}
export function useCreateDashboardDiscountRuleNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardDiscountRuleNbDocument, baseOptions);
}
export function useCreateDashboardDocumentMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardDocumentDocument, baseOptions);
}
export function useCreateDashboardLeadItemNbeMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardLeadItemNbeDocument, baseOptions);
}
export function useCreateDashboardOpeningCycleNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardOpeningCycleNbDocument, baseOptions);
}
export function useCreateDashboardPersonalizationOptionNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardPersonalizationOptionNbDocument, baseOptions);
}
export function useCreateDashboardProductAttributeNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardProductAttributeNbDocument, baseOptions);
}
export function useCreateProductFromSupplierNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateProductFromSupplierNbDocument, baseOptions);
}
export function useCreateDashboardSalesChannelDiscountNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardSalesChannelDiscountNbDocument, baseOptions);
}
export function useCreateDashboardShippingMethodNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardShippingMethodNbDocument, baseOptions);
}
export function useCreateDashboardShippingSettingsNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardShippingSettingsNbDocument, baseOptions);
}
export function useCreateDashboardShippingZoneNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardShippingZoneNbDocument, baseOptions);
}
export function useCreateDashboardTeamStoreGroupNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardTeamStoreGroupNbDocument, baseOptions);
}
export function useCreateDashboardTeamStoreNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardTeamStoreNbDocument, baseOptions);
}
export function useCreateDashboardTeamStorePersonalizationGroupNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardTeamStorePersonalizationGroupNbDocument, baseOptions);
}
export function useCreateDashboardTeamStoreTargetCategoryNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardTeamStoreTargetCategoryNbDocument, baseOptions);
}
export function useCreateEmailDomainMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateEmailDomainDocument, baseOptions);
}
export function useCreateNotificationConfigMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateNotificationConfigDocument, baseOptions);
}
export function useCreateEmbellishmentTypeNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateEmbellishmentTypeNbDocument, baseOptions);
}
export function useCreatePaymentMethodInfoMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreatePaymentMethodInfoDocument, baseOptions);
}
export function useCreateProductDesignApprovalMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateProductDesignApprovalDocument, baseOptions);
}
export function useCreateProductDesignCommentMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateProductDesignCommentDocument, baseOptions);
}
export function useCreateProductImagePlaceholderPositionAndDimensionNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateProductImagePlaceholderPositionAndDimensionNbDocument, baseOptions);
}
export function useCreateDashboardProductLightPresetNbeMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateDashboardProductLightPresetNbeDocument, baseOptions);
}
export function useCreateProductNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateProductNbDocument, baseOptions);
}
export function useCreateSalesChannelMenuMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateSalesChannelMenuDocument, baseOptions);
}
export function useDashboardCreateSalesChannelPlaceholderColorwayMediaNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardCreateSalesChannelPlaceholderColorwayMediaNbDocument, baseOptions);
}
export function useCreateSalesChannelSavedPlaceholderItemNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateSalesChannelSavedPlaceholderItemNbDocument, baseOptions);
}
export function useCreateShopAddressMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreateShopAddressDocument, baseOptions);
}
export function useDashboardDisableShopDefaultFontMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardDisableShopDefaultFontDocument, baseOptions);
}
export function useDashboardEnableShopDefaultFontMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardEnableShopDefaultFontDocument, baseOptions);
}
export function useDashboardAssignLeadsToQuoteMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardAssignLeadsToQuoteDocument, baseOptions);
}
export function useDashboardAssignProductOptionValuesMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardAssignProductOptionValuesDocument, baseOptions);
}
export function useDashboardBulkCreateSalesChannelPlaceholderColorwayMediaNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardBulkCreateSalesChannelPlaceholderColorwayMediaNbDocument, baseOptions);
}
export function useDashboardBulkDeleteProductMediaNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardBulkDeleteProductMediaNbDocument, baseOptions);
}
export function useDashboardBulkDeleteSalesChannelProductMediaNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardBulkDeleteSalesChannelProductMediaNbDocument, baseOptions);
}
export function useDashboardCopyProductNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardCopyProductNbDocument, baseOptions);
}
export function useDashboardCreateBulkProductMediaNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardCreateBulkProductMediaNbDocument, baseOptions);
}
export function useDashboardCreateLeadMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardCreateLeadDocument, baseOptions);
}
export function useDashboardCreateOrderNoteMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardCreateOrderNoteDocument, baseOptions);
}
export function useDashboardCreateProductMediaNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardCreateProductMediaNbDocument, baseOptions);
}
export function useDashboardCreateProductOptionNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardCreateProductOptionNbDocument, baseOptions);
}
export function useDashboardCreateProductOptionValueNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardCreateProductOptionValueNbDocument, baseOptions);
}
export function useDashboardCreateProductPersonalizationGroupNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardCreateProductPersonalizationGroupNbDocument, baseOptions);
}
export function useDashboardCreateProductsNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardCreateProductsNbDocument, baseOptions);
}
export function useDashboardCreateQuoteMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardCreateQuoteDocument, baseOptions);
}
export function useDashboardCreateQuoteCommentMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardCreateQuoteCommentDocument, baseOptions);
}
export function useDashboardCreateQuoteNoteMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardCreateQuoteNoteDocument, baseOptions);
}
export function useDashboardCreateReportMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardCreateReportDocument, baseOptions);
}
export function useDashboardCreateSalesChannelProductMediaV2NbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardCreateSalesChannelProductMediaV2NbDocument, baseOptions);
}
export function useDashboardCreateOrderShipmentMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardCreateOrderShipmentDocument, baseOptions);
}
export function useDashboardDeleteOrderNoteMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardDeleteOrderNoteDocument, baseOptions);
}
export function useDashboardDeleteProductByIdNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardDeleteProductByIdNbDocument, baseOptions);
}
export function useDashboardDeleteProductMediaMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardDeleteProductMediaDocument, baseOptions);
}
export function useDashboardDeleteProductMediaNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardDeleteProductMediaNbDocument, baseOptions);
}
export function useDashboardDeleteProductOptionByIdNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardDeleteProductOptionByIdNbDocument, baseOptions);
}
export function useDashboardDeleteProductOptionValueNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardDeleteProductOptionValueNbDocument, baseOptions);
}
export function useDashboardDeleteQuoteMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardDeleteQuoteDocument, baseOptions);
}
export function useDashboardDeleteQuoteCommentMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardDeleteQuoteCommentDocument, baseOptions);
}
export function useDashboardGenerateOrderInvoiceMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardGenerateOrderInvoiceDocument, baseOptions);
}
export function useDashboardRemoveLeadFromQuoteMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardRemoveLeadFromQuoteDocument, baseOptions);
}
export function useDashboardTriggerEventMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardTriggerEventDocument, baseOptions);
}
export function useDashboardUpdateLeadAddressMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateLeadAddressDocument, baseOptions);
}
export function useDashboardUpdateLeadMetadataMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateLeadMetadataDocument, baseOptions);
}
export function useDashboardUpdateLeadStatusMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateLeadStatusDocument, baseOptions);
}
export function useDashboardUpdateOrderItemVariantMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateOrderItemVariantDocument, baseOptions);
}
export function useDashboardUpdateOrderMetadataMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateOrderMetadataDocument, baseOptions);
}
export function useDashboardUpdateOrderPaymentStatusMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateOrderPaymentStatusDocument, baseOptions);
}
export function useDashboardUpdateOrderProductionStatusMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateOrderProductionStatusDocument, baseOptions);
}
export function useDashboardUpdateOrderShippingAddressMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateOrderShippingAddressDocument, baseOptions);
}
export function useDashboardUpdateOrderShippingStatusMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateOrderShippingStatusDocument, baseOptions);
}
export function useDashboardUpdateProductInfoByIdMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateProductInfoByIdDocument, baseOptions);
}
export function useDashboardUpdateProductMediaNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateProductMediaNbDocument, baseOptions);
}
export function useDashboardUpdateProductOptionNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateProductOptionNbDocument, baseOptions);
}
export function useDashboardUpdateProductOptionValueNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateProductOptionValueNbDocument, baseOptions);
}
export function useDashboardUpdateQuoteCurrencyMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateQuoteCurrencyDocument, baseOptions);
}
export function useDashboardUpdateQuoteNameMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateQuoteNameDocument, baseOptions);
}
export function useDashboardUpdateQuoteSalesChannelMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateQuoteSalesChannelDocument, baseOptions);
}
export function useDashboardUpdateQuoteStatusMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateQuoteStatusDocument, baseOptions);
}
export function useDashboardUpdateReorderProductMediaNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateReorderProductMediaNbDocument, baseOptions);
}
export function useDashboardUpdateReorderSalesChannelProductMediaNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateReorderSalesChannelProductMediaNbDocument, baseOptions);
}
export function useDashboardUpdateTermOfServiceAcceptanceMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardUpdateTermOfServiceAcceptanceDocument, baseOptions);
}
export function useDeleteAdminPortalUserNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteAdminPortalUserNbDocument, baseOptions);
}
export function useDeleteDashboardAccountNbeMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardAccountNbeDocument, baseOptions);
}
export function useDeleteDashboardAdminUserNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardAdminUserNbDocument, baseOptions);
}
export function useDeleteDashboardCollectionNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardCollectionNbDocument, baseOptions);
}
export function useDeleteDashboardCollectionTaxRateNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardCollectionTaxRateNbDocument, baseOptions);
}
export function useDeleteDashboardContactNbeMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardContactNbeDocument, baseOptions);
}
export function useDeleteDashboardDiscountFreeProductNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardDiscountFreeProductNbDocument, baseOptions);
}
export function useDeleteDashboardDiscountNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardDiscountNbDocument, baseOptions);
}
export function useDeleteDashboardDiscountRuleNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardDiscountRuleNbDocument, baseOptions);
}
export function useDeleteDashboardDiscountRuleObjectNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardDiscountRuleObjectNbDocument, baseOptions);
}
export function useDeleteDashboardDocumentTemplateMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardDocumentTemplateDocument, baseOptions);
}
export function useDeleteDashboardOpeningCycleNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardOpeningCycleNbDocument, baseOptions);
}
export function useDeleteDashboardPersonalizationOptionNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardPersonalizationOptionNbDocument, baseOptions);
}
export function useDeleteDashboardProductAttributeNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardProductAttributeNbDocument, baseOptions);
}
export function useDeleteDashboardProductPersonalizationGroupNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardProductPersonalizationGroupNbDocument, baseOptions);
}
export function useDeleteDashboardProductVariantNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardProductVariantNbDocument, baseOptions);
}
export function useDeleteDashboardShippingMethodNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardShippingMethodNbDocument, baseOptions);
}
export function useDeleteDashboardShippingSettingsNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardShippingSettingsNbDocument, baseOptions);
}
export function useDeleteDashboardShippingZoneNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardShippingZoneNbDocument, baseOptions);
}
export function useDeleteDashboardStorefrontNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardStorefrontNbDocument, baseOptions);
}
export function useDeleteDashboardTaxRateNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardTaxRateNbDocument, baseOptions);
}
export function useDeleteDashboardTeamStoreGroupNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardTeamStoreGroupNbDocument, baseOptions);
}
export function useDeleteDashboardTeamStoreNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardTeamStoreNbDocument, baseOptions);
}
export function useDeleteDashboardTeamStoreTargetCategoryNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardTeamStoreTargetCategoryNbDocument, baseOptions);
}
export function useDeleteEmailDomainMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteEmailDomainDocument, baseOptions);
}
export function useDeleteEmbellishmentTypeNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteEmbellishmentTypeNbDocument, baseOptions);
}
export function useDeleteEmbellishmentTypesNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteEmbellishmentTypesNbDocument, baseOptions);
}
export function useDeletePaymentMethodInfoMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeletePaymentMethodInfoDocument, baseOptions);
}
export function useDeleteProductImagePlaceholderNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteProductImagePlaceholderNbDocument, baseOptions);
}
export function useDeleteProductImagePlaceholderPositionAndDimensionNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteProductImagePlaceholderPositionAndDimensionNbDocument, baseOptions);
}
export function useDeleteDashboardProductLightPresetNbeMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteDashboardProductLightPresetNbeDocument, baseOptions);
}
export function useDashboardDeleteSalesChannelPlaceholderColorwayMediaNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardDeleteSalesChannelPlaceholderColorwayMediaNbDocument, baseOptions);
}
export function useDeleteSalesChannelProductMediaNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteSalesChannelProductMediaNbDocument, baseOptions);
}
export function useDeleteSalesChannelSavedPlaceholderItemNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeleteSalesChannelSavedPlaceholderItemNbDocument, baseOptions);
}
export function useDisableShippingSettingsNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DisableShippingSettingsNbDocument, baseOptions);
}
export function useDuplicateDashboardTeamStoreNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DuplicateDashboardTeamStoreNbDocument, baseOptions);
}
export function useEnableShippingSettingsNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.EnableShippingSettingsNbDocument, baseOptions);
}
export function useExportCustomImagePlaceholderMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.ExportCustomImagePlaceholderDocument, baseOptions);
}
export function useExportFeenyDesignMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.ExportFeenyDesignDocument, baseOptions);
}
export function useGenerateDashboardBulkOrderInvoicesMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.GenerateDashboardBulkOrderInvoicesDocument, baseOptions);
}
export function useGenerateDashboardBulkOrderInvoicesByIdsMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.GenerateDashboardBulkOrderInvoicesByIdsDocument, baseOptions);
}
export function useGenerateDashboardCouponNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.GenerateDashboardCouponNbDocument, baseOptions);
}
export function useGenerateDashboardTeamStoreSlugUrlNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.GenerateDashboardTeamStoreSlugUrlNbDocument, baseOptions);
}
export function useGetDashboardContactNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardContactNbDocument, baseOptions);
}
export function useGetDashboardContactNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardContactNbDocument, baseOptions);
}
export function useSendAdminPortalUserInvitationNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.SendAdminPortalUserInvitationNbDocument, baseOptions);
}
export function useSendAdminPortalAdminInvitationNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.SendAdminPortalAdminInvitationNbDocument, baseOptions);
}
export function useOpenDashboardDocumentTemplateEditorMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.OpenDashboardDocumentTemplateEditorDocument, baseOptions);
}
export function useCreatePaymentProviderConfigurationMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.CreatePaymentProviderConfigurationDocument, baseOptions);
}
export function useDashboardSetupStripeConnectMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DashboardSetupStripeConnectDocument, baseOptions);
}
export function useDeletePaymentProviderConfigurationMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.DeletePaymentProviderConfigurationDocument, baseOptions);
}
export function usePreviewDashboardDocumentTemplateMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.PreviewDashboardDocumentTemplateDocument, baseOptions);
}
export function usePublishDashboardDeploymentNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.PublishDashboardDeploymentNbDocument, baseOptions);
}
export function useRefundPaymentMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.RefundPaymentDocument, baseOptions);
}
export function useRemoveAdminPortalUserRoleMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.RemoveAdminPortalUserRoleDocument, baseOptions);
}
export function useRemoveDashboardAtrributeFromProductNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.RemoveDashboardAtrributeFromProductNbDocument, baseOptions);
}
export function useRemoveDashboardChildCollectionNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.RemoveDashboardChildCollectionNbDocument, baseOptions);
}
export function useRemoveDashboardCollectionFromSalesChannelNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.RemoveDashboardCollectionFromSalesChannelNbDocument, baseOptions);
}
export function useRemoveDashboardProductFromCollectionNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.RemoveDashboardProductFromCollectionNbDocument, baseOptions);
}
export function useRemoveDashboardProductPackageFromSalesChannelMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.RemoveDashboardProductPackageFromSalesChannelDocument, baseOptions);
}
export function useRemoveDashboardProductPackageFromSalesChannelNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.RemoveDashboardProductPackageFromSalesChannelNbDocument, baseOptions);
}
export function useRemoveDashboardProductToSalesChannelNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.RemoveDashboardProductToSalesChannelNbDocument, baseOptions);
}
export function useRemoveDashboardProductLightPresetFromProductNbeMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.RemoveDashboardProductLightPresetFromProductNbeDocument, baseOptions);
}
export function useResetDashboardAdminUserPasswordMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.ResetDashboardAdminUserPasswordDocument, baseOptions);
}
export function useSalesChannelConfigurationByPkQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.SalesChannelConfigurationByPkDocument, baseOptions);
}
export function useSalesChannelConfigurationByPkLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.SalesChannelConfigurationByPkDocument, baseOptions);
}
export function useSaveDashboardDocumentTemplateMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.SaveDashboardDocumentTemplateDocument, baseOptions);
}
export function useSaveImagePlaceholderNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.SaveImagePlaceholderNbDocument, baseOptions);
}
export function useSetChildProductsMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.SetChildProductsDocument, baseOptions);
}
export function useSetDashboardCollectionDefaultTaxRateNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.SetDashboardCollectionDefaultTaxRateNbDocument, baseOptions);
}
export function useSetDashboardCollectionTaxRateNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.SetDashboardCollectionTaxRateNbDocument, baseOptions);
}
export function useSetDashboardDefaultTaxRateNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.SetDashboardDefaultTaxRateNbDocument, baseOptions);
}
export function useSetDashboardTaxRateNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.SetDashboardTaxRateNbDocument, baseOptions);
}
export function useUpdateDashboardDefaultProductLightPresetNbeMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardDefaultProductLightPresetNbeDocument, baseOptions);
}
export function useSoftDeleteAssignedPaymentConfigurationMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.SoftDeleteAssignedPaymentConfigurationDocument, baseOptions);
}
export function useSubmitContactRequestMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.SubmitContactRequestDocument, baseOptions);
}
export function useSyncDashboardProductVariantsNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.SyncDashboardProductVariantsNbDocument, baseOptions);
}
export function useToggleDashboardCollectionTaxSettingsMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.ToggleDashboardCollectionTaxSettingsDocument, baseOptions);
}
export function useToggleDashboardDiscountCouponNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.ToggleDashboardDiscountCouponNbDocument, baseOptions);
}
export function useUnassignDashboardAccountContactFromAccountNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UnassignDashboardAccountContactFromAccountNbDocument, baseOptions);
}
export function useUnassignDashboardBrandToProductNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UnassignDashboardBrandToProductNbDocument, baseOptions);
}
export function useUnassignDashboardManagerFromAdminUserNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UnassignDashboardManagerFromAdminUserNbDocument, baseOptions);
}
export function useUnassignDashboardMicrostoreFromContactNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UnassignDashboardMicrostoreFromContactNbDocument, baseOptions);
}
export function useUpdateAdminUserInfoMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateAdminUserInfoDocument, baseOptions);
}
export function useUpdateAssignedPaymentConfigurationMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateAssignedPaymentConfigurationDocument, baseOptions);
}
export function useUpdateDashboardAccountDetailNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardAccountDetailNbDocument, baseOptions);
}
export function useUpdateDashboardAdminUserNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardAdminUserNbDocument, baseOptions);
}
export function useUpdateDashboardCollectionChildSortOrderNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardCollectionChildSortOrderNbDocument, baseOptions);
}
export function useUpdateDashboardCollectionNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardCollectionNbDocument, baseOptions);
}
export function useUpdateDashboardCollectionProductSortOrderNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardCollectionProductSortOrderNbDocument, baseOptions);
}
export function useUpdateDashboardContactNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardContactNbDocument, baseOptions);
}
export function useUpdateDashboardDiscountDetailNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardDiscountDetailNbDocument, baseOptions);
}
export function useUpdateDashboardDiscountRuleNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardDiscountRuleNbDocument, baseOptions);
}
export function useUpdateDashboardOpeningCycleNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardOpeningCycleNbDocument, baseOptions);
}
export function useUpdateDashboardPersonalizationOptionNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardPersonalizationOptionNbDocument, baseOptions);
}
export function useUpdateDashboardProductAttributeNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardProductAttributeNbDocument, baseOptions);
}
export function useUpdateDashboardProductPersonalizationGroupNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardProductPersonalizationGroupNbDocument, baseOptions);
}
export function useUpdateDashboardProductPriceNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardProductPriceNbDocument, baseOptions);
}
export function useUpdateDashboardProductVariantNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardProductVariantNbDocument, baseOptions);
}
export function useUpdateDashboardSalesChannelByIdNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardSalesChannelByIdNbDocument, baseOptions);
}
export function useUpdateDashboardSalesChannelCollectionNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardSalesChannelCollectionNbDocument, baseOptions);
}
export function useUpdateDashboardSalesChannelDefaultPaymentConfigNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardSalesChannelDefaultPaymentConfigNbDocument, baseOptions);
}
export function useUpdateDashboardSalesChannelProductNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardSalesChannelProductNbDocument, baseOptions);
}
export function useUpdateDashboardSalesChannelProductPackageNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardSalesChannelProductPackageNbDocument, baseOptions);
}
export function useUpdateDashboardShippingMethodNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardShippingMethodNbDocument, baseOptions);
}
export function useUpdateDashboardShippingZoneNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardShippingZoneNbDocument, baseOptions);
}
export function useUpdateDashboardStorefrontContentNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardStorefrontContentNbDocument, baseOptions);
}
export function useUpdateDashboardStorefrontInformationNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardStorefrontInformationNbDocument, baseOptions);
}
export function useUpdateDashboardTeamStoreAvailabilityNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardTeamStoreAvailabilityNbDocument, baseOptions);
}
export function useUpdateDashboardTeamStoreContentNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardTeamStoreContentNbDocument, baseOptions);
}
export function useUpdateDashboardTeamStoreGroupNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardTeamStoreGroupNbDocument, baseOptions);
}
export function useUpdateDashboardTeamStoreInformationNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardTeamStoreInformationNbDocument, baseOptions);
}
export function useUpdateDashboardTeamStoreStrucutreNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardTeamStoreStrucutreNbDocument, baseOptions);
}
export function useUpdateDashboardTeamStoreTargetCategoryNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardTeamStoreTargetCategoryNbDocument, baseOptions);
}
export function useUpdateNotificationConfigMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateNotificationConfigDocument, baseOptions);
}
export function useUpdateEmbellishmentTypeNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateEmbellishmentTypeNbDocument, baseOptions);
}
export function useUpdateOrderTimelineVisibilityMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateOrderTimelineVisibilityDocument, baseOptions);
}
export function useUpdatePaymentMethodInfoMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdatePaymentMethodInfoDocument, baseOptions);
}
export function useUpdatePaymentProviderConfigurationMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdatePaymentProviderConfigurationDocument, baseOptions);
}
export function useUpdateProductDesignApprovalMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateProductDesignApprovalDocument, baseOptions);
}
export function useUpdateBaseProductImagePlaceholderNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateBaseProductImagePlaceholderNbDocument, baseOptions);
}
export function useUpdateCustomImagePlaceholderPositionAndDimensionNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateCustomImagePlaceholderPositionAndDimensionNbDocument, baseOptions);
}
export function useUpdateDashboardProductLightPresetNbeMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateDashboardProductLightPresetNbeDocument, baseOptions);
}
export function useUpdateSalesChannelProductMediaNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateSalesChannelProductMediaNbDocument, baseOptions);
}
export function useUpdateSalesChannelSavedPlaceholderItemNbMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateSalesChannelSavedPlaceholderItemNbDocument, baseOptions);
}
export function useUpdateShopAddressMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateShopAddressDocument, baseOptions);
}
export function useUpdateStorefrontMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.UpdateStorefrontDocument, baseOptions);
}
export function useVerifyEmailDomainNbeMutation(baseOptions) {
    return ApolloReactHooks.useMutation(Operations.VerifyEmailDomainNbeDocument, baseOptions);
}
export function useAdminPortalFundraisingQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.AdminPortalFundraisingDocument, baseOptions);
}
export function useAdminPortalFundraisingLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.AdminPortalFundraisingDocument, baseOptions);
}
export function useAdminPortalOrdersQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.AdminPortalOrdersDocument, baseOptions);
}
export function useAdminPortalOrdersLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.AdminPortalOrdersDocument, baseOptions);
}
export function useAdminPortalOrdersOverviewQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.AdminPortalOrdersOverviewDocument, baseOptions);
}
export function useAdminPortalOrdersOverviewLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.AdminPortalOrdersOverviewDocument, baseOptions);
}
export function useCheckDuplicateEmbellishmentTypeTitleNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.CheckDuplicateEmbellishmentTypeTitleNbDocument, baseOptions);
}
export function useCheckDuplicateEmbellishmentTypeTitleNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.CheckDuplicateEmbellishmentTypeTitleNbDocument, baseOptions);
}
export function useCheckDuplicatePlaceholderTitleNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.CheckDuplicatePlaceholderTitleNbDocument, baseOptions);
}
export function useCheckDuplicatePlaceholderTitleNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.CheckDuplicatePlaceholderTitleNbDocument, baseOptions);
}
export function useDashboardAddressDetailQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardAddressDetailDocument, baseOptions);
}
export function useDashboardAddressDetailLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardAddressDetailDocument, baseOptions);
}
export function useDashboardCommunicationPanelOrdersQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardCommunicationPanelOrdersDocument, baseOptions);
}
export function useDashboardCommunicationPanelOrdersLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardCommunicationPanelOrdersDocument, baseOptions);
}
export function useDashboardShopDefaultFontSettingsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardShopDefaultFontSettingsDocument, baseOptions);
}
export function useDashboardShopDefaultFontSettingsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardShopDefaultFontSettingsDocument, baseOptions);
}
export function useDashboardGetAllProductOptionsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardGetAllProductOptionsDocument, baseOptions);
}
export function useDashboardGetAllProductOptionsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardGetAllProductOptionsDocument, baseOptions);
}
export function useDashboardGetAllReportsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardGetAllReportsDocument, baseOptions);
}
export function useDashboardGetAllReportsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardGetAllReportsDocument, baseOptions);
}
export function useGetDashboardOrderItemProductByIdNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardOrderItemProductByIdNbDocument, baseOptions);
}
export function useGetDashboardOrderItemProductByIdNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardOrderItemProductByIdNbDocument, baseOptions);
}
export function useDashboardGetProductByIdNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardGetProductByIdNbDocument, baseOptions);
}
export function useDashboardGetProductByIdNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardGetProductByIdNbDocument, baseOptions);
}
export function useDashboardGetProductCollectionsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardGetProductCollectionsDocument, baseOptions);
}
export function useDashboardGetProductCollectionsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardGetProductCollectionsDocument, baseOptions);
}
export function useDashboardGetProductOptionByIdNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardGetProductOptionByIdNbDocument, baseOptions);
}
export function useDashboardGetProductOptionByIdNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardGetProductOptionByIdNbDocument, baseOptions);
}
export function useDashboardGetProductSalesChannelIdsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardGetProductSalesChannelIdsDocument, baseOptions);
}
export function useDashboardGetProductSalesChannelIdsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardGetProductSalesChannelIdsDocument, baseOptions);
}
export function useDashboardGetProductSalesChannelsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardGetProductSalesChannelsDocument, baseOptions);
}
export function useDashboardGetProductSalesChannelsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardGetProductSalesChannelsDocument, baseOptions);
}
export function useDashboardGetProductsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardGetProductsNbDocument, baseOptions);
}
export function useDashboardGetProductsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardGetProductsNbDocument, baseOptions);
}
export function useDashboardGetReportByIdQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardGetReportByIdDocument, baseOptions);
}
export function useDashboardGetReportByIdLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardGetReportByIdDocument, baseOptions);
}
export function useDashboardLeadDetailQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardLeadDetailDocument, baseOptions);
}
export function useDashboardLeadDetailLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardLeadDetailDocument, baseOptions);
}
export function useDashboardLeadsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardLeadsDocument, baseOptions);
}
export function useDashboardLeadsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardLeadsDocument, baseOptions);
}
export function useDashboardOrderByIdQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardOrderByIdDocument, baseOptions);
}
export function useDashboardOrderByIdLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardOrderByIdDocument, baseOptions);
}
export function useDashboardOrdersQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardOrdersDocument, baseOptions);
}
export function useDashboardOrdersLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardOrdersDocument, baseOptions);
}
export function useDashboardQuoteByIdQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardQuoteByIdDocument, baseOptions);
}
export function useDashboardQuoteByIdLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardQuoteByIdDocument, baseOptions);
}
export function useDashboardQuotesQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardQuotesDocument, baseOptions);
}
export function useDashboardQuotesLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardQuotesDocument, baseOptions);
}
export function useDashboardSupplierServiceAvailabilityQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardSupplierServiceAvailabilityDocument, baseOptions);
}
export function useDashboardSupplierServiceAvailabilityLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardSupplierServiceAvailabilityDocument, baseOptions);
}
export function useDashboardGetTermOfServiceAcceptanceQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardGetTermOfServiceAcceptanceDocument, baseOptions);
}
export function useDashboardGetTermOfServiceAcceptanceLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardGetTermOfServiceAcceptanceDocument, baseOptions);
}
export function useDashboardUserAccessLevelQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.DashboardUserAccessLevelDocument, baseOptions);
}
export function useDashboardUserAccessLevelLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.DashboardUserAccessLevelDocument, baseOptions);
}
export function useDemo1OrderQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.Demo1OrderDocument, baseOptions);
}
export function useDemo1OrderLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.Demo1OrderDocument, baseOptions);
}
export function useDemo2OrderQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.Demo2OrderDocument, baseOptions);
}
export function useDemo2OrderLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.Demo2OrderDocument, baseOptions);
}
export function useFeeneyDesignExportByIdQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.FeeneyDesignExportByIdDocument, baseOptions);
}
export function useFeeneyDesignExportByIdLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.FeeneyDesignExportByIdDocument, baseOptions);
}
export function useGetAddressByIdQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetAddressByIdDocument, baseOptions);
}
export function useGetAddressByIdLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetAddressByIdDocument, baseOptions);
}
export function useGetAdminPortalMicroStoreQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetAdminPortalMicroStoreDocument, baseOptions);
}
export function useGetAdminPortalMicroStoreLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetAdminPortalMicroStoreDocument, baseOptions);
}
export function useGetAdminPortalOpeningCycleNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetAdminPortalOpeningCycleNbDocument, baseOptions);
}
export function useGetAdminPortalOpeningCycleNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetAdminPortalOpeningCycleNbDocument, baseOptions);
}
export function useGetAdminPortalProductsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetAdminPortalProductsNbDocument, baseOptions);
}
export function useGetAdminPortalProductsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetAdminPortalProductsNbDocument, baseOptions);
}
export function useGetAdminPortalUserNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetAdminPortalUserNbDocument, baseOptions);
}
export function useGetAdminPortalUserNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetAdminPortalUserNbDocument, baseOptions);
}
export function useGetAdminPortalUsersNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetAdminPortalUsersNbDocument, baseOptions);
}
export function useGetAdminPortalUsersNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetAdminPortalUsersNbDocument, baseOptions);
}
export function useGetAdminPortalSalesChannelIdByOpeningCycleIdNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetAdminPortalSalesChannelIdByOpeningCycleIdNbDocument, baseOptions);
}
export function useGetAdminPortalSalesChannelIdByOpeningCycleIdNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetAdminPortalSalesChannelIdByOpeningCycleIdNbDocument, baseOptions);
}
export function useGetAllNotificationConfigByShopIdQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetAllNotificationConfigByShopIdDocument, baseOptions);
}
export function useGetAllNotificationConfigByShopIdLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetAllNotificationConfigByShopIdDocument, baseOptions);
}
export function useGetAllPaymentProviderConfigurationsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetAllPaymentProviderConfigurationsDocument, baseOptions);
}
export function useGetAllPaymentProviderConfigurationsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetAllPaymentProviderConfigurationsDocument, baseOptions);
}
export function useGetAllSupportedPaymentMethodsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetAllSupportedPaymentMethodsDocument, baseOptions);
}
export function useGetAllSupportedPaymentMethodsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetAllSupportedPaymentMethodsDocument, baseOptions);
}
export function useGetAssignedPaymentConfigurationsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetAssignedPaymentConfigurationsDocument, baseOptions);
}
export function useGetAssignedPaymentConfigurationsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetAssignedPaymentConfigurationsDocument, baseOptions);
}
export function useGetBaseProductImagesByPlaceholderIdNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetBaseProductImagesByPlaceholderIdNbDocument, baseOptions);
}
export function useGetBaseProductImagesByPlaceholderIdNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetBaseProductImagesByPlaceholderIdNbDocument, baseOptions);
}
export function useGetBaseProductImagesByPlaceholderIdsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetBaseProductImagesByPlaceholderIdsNbDocument, baseOptions);
}
export function useGetBaseProductImagesByPlaceholderIdsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetBaseProductImagesByPlaceholderIdsNbDocument, baseOptions);
}
export function useGetDashboardAccountDetailNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardAccountDetailNbDocument, baseOptions);
}
export function useGetDashboardAccountDetailNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardAccountDetailNbDocument, baseOptions);
}
export function useGetDashboardAccountsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardAccountsNbDocument, baseOptions);
}
export function useGetDashboardAccountsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardAccountsNbDocument, baseOptions);
}
export function useGetDashboardAdminUserMembersNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardAdminUserMembersNbDocument, baseOptions);
}
export function useGetDashboardAdminUserMembersNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardAdminUserMembersNbDocument, baseOptions);
}
export function useGetDashboardAdminUserNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardAdminUserNbDocument, baseOptions);
}
export function useGetDashboardAdminUserNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardAdminUserNbDocument, baseOptions);
}
export function useGetDashboardAdminUsersNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardAdminUsersNbDocument, baseOptions);
}
export function useGetDashboardAdminUsersNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardAdminUsersNbDocument, baseOptions);
}
export function useGetDashboardCollectionCommonNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardCollectionCommonNbDocument, baseOptions);
}
export function useGetDashboardCollectionCommonNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardCollectionCommonNbDocument, baseOptions);
}
export function useGetDashboardCollectionListNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardCollectionListNbDocument, baseOptions);
}
export function useGetDashboardCollectionListNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardCollectionListNbDocument, baseOptions);
}
export function useGetDashboardCollectionInformationNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardCollectionInformationNbDocument, baseOptions);
}
export function useGetDashboardCollectionInformationNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardCollectionInformationNbDocument, baseOptions);
}
export function useGetDashboardCollectionMediaNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardCollectionMediaNbDocument, baseOptions);
}
export function useGetDashboardCollectionMediaNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardCollectionMediaNbDocument, baseOptions);
}
export function useGetDashboardCollectionProductNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardCollectionProductNbDocument, baseOptions);
}
export function useGetDashboardCollectionProductNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardCollectionProductNbDocument, baseOptions);
}
export function useGetDashboardParentCollectionNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardParentCollectionNbDocument, baseOptions);
}
export function useGetDashboardParentCollectionNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardParentCollectionNbDocument, baseOptions);
}
export function useGetDashboardChildCollectionNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardChildCollectionNbDocument, baseOptions);
}
export function useGetDashboardChildCollectionNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardChildCollectionNbDocument, baseOptions);
}
export function useGetDashboardCollectionTaxListNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardCollectionTaxListNbDocument, baseOptions);
}
export function useGetDashboardCollectionTaxListNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardCollectionTaxListNbDocument, baseOptions);
}
export function useGetDashboardCollectionTaxSettingsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardCollectionTaxSettingsNbDocument, baseOptions);
}
export function useGetDashboardCollectionTaxSettingsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardCollectionTaxSettingsNbDocument, baseOptions);
}
export function useGetDashboardCollectionsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardCollectionsNbDocument, baseOptions);
}
export function useGetDashboardCollectionsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardCollectionsNbDocument, baseOptions);
}
export function useGetDashboardContactsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardContactsNbDocument, baseOptions);
}
export function useGetDashboardContactsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardContactsNbDocument, baseOptions);
}
export function useGetDashboardCustomImageEditorProductsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardCustomImageEditorProductsNbDocument, baseOptions);
}
export function useGetDashboardCustomImageEditorProductsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardCustomImageEditorProductsNbDocument, baseOptions);
}
export function useGetDashboardDiscountCouponListNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardDiscountCouponListNbDocument, baseOptions);
}
export function useGetDashboardDiscountCouponListNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardDiscountCouponListNbDocument, baseOptions);
}
export function useGetDashboardDiscountDetailNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardDiscountDetailNbDocument, baseOptions);
}
export function useGetDashboardDiscountDetailNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardDiscountDetailNbDocument, baseOptions);
}
export function useGetDashboardDiscountListNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardDiscountListNbDocument, baseOptions);
}
export function useGetDashboardDiscountListNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardDiscountListNbDocument, baseOptions);
}
export function useGetDashboardDiscountObjectProductsByIdsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardDiscountObjectProductsByIdsNbDocument, baseOptions);
}
export function useGetDashboardDiscountObjectProductsByIdsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardDiscountObjectProductsByIdsNbDocument, baseOptions);
}
export function useGetDashboardDocumentTemplatesQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardDocumentTemplatesDocument, baseOptions);
}
export function useGetDashboardDocumentTemplatesLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardDocumentTemplatesDocument, baseOptions);
}
export function useGetDashboardJobByIdNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardJobByIdNbDocument, baseOptions);
}
export function useGetDashboardJobByIdNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardJobByIdNbDocument, baseOptions);
}
export function useGetDashboardLightPresetsNbeQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardLightPresetsNbeDocument, baseOptions);
}
export function useGetDashboardLightPresetsNbeLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardLightPresetsNbeDocument, baseOptions);
}
export function useGetDashboardLightSettingNbeQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardLightSettingNbeDocument, baseOptions);
}
export function useGetDashboardLightSettingNbeLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardLightSettingNbeDocument, baseOptions);
}
export function useGetDashboardMetaFieldsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardMetaFieldsNbDocument, baseOptions);
}
export function useGetDashboardMetaFieldsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardMetaFieldsNbDocument, baseOptions);
}
export function useGetDashboardMicrostoresInContactNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardMicrostoresInContactNbDocument, baseOptions);
}
export function useGetDashboardMicrostoresInContactNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardMicrostoresInContactNbDocument, baseOptions);
}
export function useGetDashboardOpeningCyclesNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardOpeningCyclesNbDocument, baseOptions);
}
export function useGetDashboardOpeningCyclesNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardOpeningCyclesNbDocument, baseOptions);
}
export function useGetDashboardOptimizedJobByIdNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardOptimizedJobByIdNbDocument, baseOptions);
}
export function useGetDashboardOptimizedJobByIdNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardOptimizedJobByIdNbDocument, baseOptions);
}
export function useGetDashboardPricingConfigurationNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardPricingConfigurationNbDocument, baseOptions);
}
export function useGetDashboardPricingConfigurationNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardPricingConfigurationNbDocument, baseOptions);
}
export function useGetDashboardPricingModifierNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardPricingModifierNbDocument, baseOptions);
}
export function useGetDashboardPricingModifierNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardPricingModifierNbDocument, baseOptions);
}
export function useGetDashboardProductAttributeNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardProductAttributeNbDocument, baseOptions);
}
export function useGetDashboardProductAttributeNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardProductAttributeNbDocument, baseOptions);
}
export function useGetDashboardProductAttributesNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardProductAttributesNbDocument, baseOptions);
}
export function useGetDashboardProductAttributesNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardProductAttributesNbDocument, baseOptions);
}
export function useGetDashboardProductBrandByProductIdNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardProductBrandByProductIdNbDocument, baseOptions);
}
export function useGetDashboardProductBrandByProductIdNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardProductBrandByProductIdNbDocument, baseOptions);
}
export function useGetDashboardProductBrandsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardProductBrandsNbDocument, baseOptions);
}
export function useGetDashboardProductBrandsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardProductBrandsNbDocument, baseOptions);
}
export function useGetDashboardProductByIdNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardProductByIdNbDocument, baseOptions);
}
export function useGetDashboardProductByIdNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardProductByIdNbDocument, baseOptions);
}
export function useGetDashboardProductsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardProductsNbDocument, baseOptions);
}
export function useGetDashboardProductsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardProductsNbDocument, baseOptions);
}
export function useGetDashboardProductsSupplierPageNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardProductsSupplierPageNbDocument, baseOptions);
}
export function useGetDashboardProductsSupplierPageNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardProductsSupplierPageNbDocument, baseOptions);
}
export function useGetDashboardSalesChannelByIdNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardSalesChannelByIdNbDocument, baseOptions);
}
export function useGetDashboardSalesChannelByIdNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardSalesChannelByIdNbDocument, baseOptions);
}
export function useGetDashboardSalesChannelByIdsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardSalesChannelByIdsDocument, baseOptions);
}
export function useGetDashboardSalesChannelByIdsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardSalesChannelByIdsDocument, baseOptions);
}
export function useGetDashboardSalesChannelCollectionsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardSalesChannelCollectionsNbDocument, baseOptions);
}
export function useGetDashboardSalesChannelCollectionsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardSalesChannelCollectionsNbDocument, baseOptions);
}
export function useGetDashboardSalesChannelDiscountListNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardSalesChannelDiscountListNbDocument, baseOptions);
}
export function useGetDashboardSalesChannelDiscountListNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardSalesChannelDiscountListNbDocument, baseOptions);
}
export function useGetDashboardSalesChannelProductByIdNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardSalesChannelProductByIdNbDocument, baseOptions);
}
export function useGetDashboardSalesChannelProductByIdNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardSalesChannelProductByIdNbDocument, baseOptions);
}
export function useGetDashboardSalesChannelProductPackagesNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardSalesChannelProductPackagesNbDocument, baseOptions);
}
export function useGetDashboardSalesChannelProductPackagesNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardSalesChannelProductPackagesNbDocument, baseOptions);
}
export function useGetDashboardSalesChannelProductVariantsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardSalesChannelProductVariantsNbDocument, baseOptions);
}
export function useGetDashboardSalesChannelProductVariantsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardSalesChannelProductVariantsNbDocument, baseOptions);
}
export function useGetDashboardSalesChannelProductsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardSalesChannelProductsNbDocument, baseOptions);
}
export function useGetDashboardSalesChannelProductsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardSalesChannelProductsNbDocument, baseOptions);
}
export function useGetDashboardSalesChannelsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardSalesChannelsNbDocument, baseOptions);
}
export function useGetDashboardSalesChannelsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardSalesChannelsNbDocument, baseOptions);
}
export function useGetDashboardSalesChannelsProductsOnCatalogSalesChannelsPageQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardSalesChannelsProductsOnCatalogSalesChannelsPageDocument, baseOptions);
}
export function useGetDashboardSalesChannelsProductsOnCatalogSalesChannelsPageLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardSalesChannelsProductsOnCatalogSalesChannelsPageDocument, baseOptions);
}
export function useGetDashboardShippingSettingsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardShippingSettingsNbDocument, baseOptions);
}
export function useGetDashboardShippingSettingsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardShippingSettingsNbDocument, baseOptions);
}
export function useGetDashboardStorefrontContentNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardStorefrontContentNbDocument, baseOptions);
}
export function useGetDashboardStorefrontContentNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardStorefrontContentNbDocument, baseOptions);
}
export function useGetDashboardStorefrontDeploymentNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardStorefrontDeploymentNbDocument, baseOptions);
}
export function useGetDashboardStorefrontDeploymentNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardStorefrontDeploymentNbDocument, baseOptions);
}
export function useGetDashboardStorefrontInformationNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardStorefrontInformationNbDocument, baseOptions);
}
export function useGetDashboardStorefrontInformationNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardStorefrontInformationNbDocument, baseOptions);
}
export function useGetDashboardStorefrontsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardStorefrontsNbDocument, baseOptions);
}
export function useGetDashboardStorefrontsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardStorefrontsNbDocument, baseOptions);
}
export function useGetDashboardSupplierProductBrandsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardSupplierProductBrandsNbDocument, baseOptions);
}
export function useGetDashboardSupplierProductBrandsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardSupplierProductBrandsNbDocument, baseOptions);
}
export function useGetDashboardSupplierProductCategoriesNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardSupplierProductCategoriesNbDocument, baseOptions);
}
export function useGetDashboardSupplierProductCategoriesNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardSupplierProductCategoriesNbDocument, baseOptions);
}
export function useGetDashboardSupplierProductDetailPageQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardSupplierProductDetailPageDocument, baseOptions);
}
export function useGetDashboardSupplierProductDetailPageLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardSupplierProductDetailPageDocument, baseOptions);
}
export function useGetDashboardSupplierProductVariantsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardSupplierProductVariantsNbDocument, baseOptions);
}
export function useGetDashboardSupplierProductVariantsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardSupplierProductVariantsNbDocument, baseOptions);
}
export function useGetDashboardSupplierProductsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardSupplierProductsNbDocument, baseOptions);
}
export function useGetDashboardSupplierProductsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardSupplierProductsNbDocument, baseOptions);
}
export function useGetDashboardSuppliersNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardSuppliersNbDocument, baseOptions);
}
export function useGetDashboardSuppliersNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardSuppliersNbDocument, baseOptions);
}
export function useGetDashboardTaxSettingsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardTaxSettingsNbDocument, baseOptions);
}
export function useGetDashboardTaxSettingsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardTaxSettingsNbDocument, baseOptions);
}
export function useGetDashboardTeamStoreAvailabilityQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardTeamStoreAvailabilityDocument, baseOptions);
}
export function useGetDashboardTeamStoreAvailabilityLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardTeamStoreAvailabilityDocument, baseOptions);
}
export function useGetDashboardTeamStoreContentNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardTeamStoreContentNbDocument, baseOptions);
}
export function useGetDashboardTeamStoreContentNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardTeamStoreContentNbDocument, baseOptions);
}
export function useGetDashboardTeamStoreInformationNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardTeamStoreInformationNbDocument, baseOptions);
}
export function useGetDashboardTeamStoreInformationNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardTeamStoreInformationNbDocument, baseOptions);
}
export function useGetDashboardTeamStorePersonalizationNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardTeamStorePersonalizationNbDocument, baseOptions);
}
export function useGetDashboardTeamStorePersonalizationNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardTeamStorePersonalizationNbDocument, baseOptions);
}
export function useGetDashboardTeamStoreStructureNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardTeamStoreStructureNbDocument, baseOptions);
}
export function useGetDashboardTeamStoreStructureNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardTeamStoreStructureNbDocument, baseOptions);
}
export function useGetDashboardTeamStoresNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardTeamStoresNbDocument, baseOptions);
}
export function useGetDashboardTeamStoresNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardTeamStoresNbDocument, baseOptions);
}
export function useGetDashboardUserPermissionQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardUserPermissionDocument, baseOptions);
}
export function useGetDashboardUserPermissionLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardUserPermissionDocument, baseOptions);
}
export function useGetDashboardVerifiedSuppliersQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardVerifiedSuppliersDocument, baseOptions);
}
export function useGetDashboardVerifiedSuppliersLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardVerifiedSuppliersDocument, baseOptions);
}
export function useGetDashboardVerifiedSupplierProductBrandsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardVerifiedSupplierProductBrandsNbDocument, baseOptions);
}
export function useGetDashboardVerifiedSupplierProductBrandsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardVerifiedSupplierProductBrandsNbDocument, baseOptions);
}
export function useGetDashboardVerifiedSupplierProductCategoriesNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardVerifiedSupplierProductCategoriesNbDocument, baseOptions);
}
export function useGetDashboardVerifiedSupplierProductCategoriesNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardVerifiedSupplierProductCategoriesNbDocument, baseOptions);
}
export function useGetDashboardcouponUsageByDiscountIdNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetDashboardcouponUsageByDiscountIdNbDocument, baseOptions);
}
export function useGetDashboardcouponUsageByDiscountIdNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetDashboardcouponUsageByDiscountIdNbDocument, baseOptions);
}
export function useGetEmailDomainsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetEmailDomainsDocument, baseOptions);
}
export function useGetEmailDomainsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetEmailDomainsDocument, baseOptions);
}
export function useGetEmbellishmentTypesNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetEmbellishmentTypesNbDocument, baseOptions);
}
export function useGetEmbellishmentTypesNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetEmbellishmentTypesNbDocument, baseOptions);
}
export function useGetPaymentMethodInfoBySalesChannelIdQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetPaymentMethodInfoBySalesChannelIdDocument, baseOptions);
}
export function useGetPaymentMethodInfoBySalesChannelIdLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetPaymentMethodInfoBySalesChannelIdDocument, baseOptions);
}
export function useGetPaymentMethodInfoByShopQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetPaymentMethodInfoByShopDocument, baseOptions);
}
export function useGetPaymentMethodInfoByShopLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetPaymentMethodInfoByShopDocument, baseOptions);
}
export function useGetProductByIdInformationQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetProductByIdInformationDocument, baseOptions);
}
export function useGetProductByIdInformationLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetProductByIdInformationDocument, baseOptions);
}
export function useGetProductImagePlaceholderByProductIdNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetProductImagePlaceholderByProductIdNbDocument, baseOptions);
}
export function useGetProductImagePlaceholderByProductIdNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetProductImagePlaceholderByProductIdNbDocument, baseOptions);
}
export function useGetProductImagePlaceholdersBySalesChannelIdNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetProductImagePlaceholdersBySalesChannelIdNbDocument, baseOptions);
}
export function useGetProductImagePlaceholdersBySalesChannelIdNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetProductImagePlaceholdersBySalesChannelIdNbDocument, baseOptions);
}
export function useGetStudioProductLightNbeQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetStudioProductLightNbeDocument, baseOptions);
}
export function useGetStudioProductLightNbeLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetStudioProductLightNbeDocument, baseOptions);
}
export function useGetProductMediaAndTitleNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetProductMediaAndTitleNbDocument, baseOptions);
}
export function useGetProductMediaAndTitleNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetProductMediaAndTitleNbDocument, baseOptions);
}
export function useGetSalesChannelDefaultPaymentConfigNbeQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetSalesChannelDefaultPaymentConfigNbeDocument, baseOptions);
}
export function useGetSalesChannelDefaultPaymentConfigNbeLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetSalesChannelDefaultPaymentConfigNbeDocument, baseOptions);
}
export function useGetSalesChannelNbeQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetSalesChannelNbeDocument, baseOptions);
}
export function useGetSalesChannelNbeLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetSalesChannelNbeDocument, baseOptions);
}
export function useGetSalesChannelNameNbeQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetSalesChannelNameNbeDocument, baseOptions);
}
export function useGetSalesChannelNameNbeLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetSalesChannelNameNbeDocument, baseOptions);
}
export function useGetSalesChannelSavedPlaceholderItemsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetSalesChannelSavedPlaceholderItemsNbDocument, baseOptions);
}
export function useGetSalesChannelSavedPlaceholderItemsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetSalesChannelSavedPlaceholderItemsNbDocument, baseOptions);
}
export function useGetSalesChannelsNbeQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetSalesChannelsNbeDocument, baseOptions);
}
export function useGetSalesChannelsNbeLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetSalesChannelsNbeDocument, baseOptions);
}
export function useGetShopAddressesQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetShopAddressesDocument, baseOptions);
}
export function useGetShopAddressesLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetShopAddressesDocument, baseOptions);
}
export function useInstagramPostsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.InstagramPostsDocument, baseOptions);
}
export function useInstagramPostsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.InstagramPostsDocument, baseOptions);
}
export function useLeadItemByProductDesignIdQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.LeadItemByProductDesignIdDocument, baseOptions);
}
export function useLeadItemByProductDesignIdLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.LeadItemByProductDesignIdDocument, baseOptions);
}
export function useOrderDetailNewBackendQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.OrderDetailNewBackendDocument, baseOptions);
}
export function useOrderDetailNewBackendLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.OrderDetailNewBackendDocument, baseOptions);
}
export function useOrderPaymentStatusQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.OrderPaymentStatusDocument, baseOptions);
}
export function useOrderPaymentStatusLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.OrderPaymentStatusDocument, baseOptions);
}
export function useOrdersNewBackendQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.OrdersNewBackendDocument, baseOptions);
}
export function useOrdersNewBackendLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.OrdersNewBackendDocument, baseOptions);
}
export function useGetPaymentProviderConfigurationsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetPaymentProviderConfigurationsDocument, baseOptions);
}
export function useGetPaymentProviderConfigurationsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetPaymentProviderConfigurationsDocument, baseOptions);
}
export function useGetSupportedPaymentMethodsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.GetSupportedPaymentMethodsDocument, baseOptions);
}
export function useGetSupportedPaymentMethodsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.GetSupportedPaymentMethodsDocument, baseOptions);
}
export function useProductDesignApprovalQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.ProductDesignApprovalDocument, baseOptions);
}
export function useProductDesignApprovalLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.ProductDesignApprovalDocument, baseOptions);
}
export function useProductDesignCommentsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.ProductDesignCommentsDocument, baseOptions);
}
export function useProductDesignCommentsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.ProductDesignCommentsDocument, baseOptions);
}
export function useProductImagePlaceholdersNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.ProductImagePlaceholdersNbDocument, baseOptions);
}
export function useProductImagePlaceholdersNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.ProductImagePlaceholdersNbDocument, baseOptions);
}
export function useQuoteByIdQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.QuoteByIdDocument, baseOptions);
}
export function useQuoteByIdLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.QuoteByIdDocument, baseOptions);
}
export function useSalesChannelHasPlaceholderNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.SalesChannelHasPlaceholderNbDocument, baseOptions);
}
export function useSalesChannelHasPlaceholderNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.SalesChannelHasPlaceholderNbDocument, baseOptions);
}
export function useSalesChannelMenuQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.SalesChannelMenuDocument, baseOptions);
}
export function useSalesChannelMenuLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.SalesChannelMenuDocument, baseOptions);
}
export function useSearchDashboardCollectionsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.SearchDashboardCollectionsNbDocument, baseOptions);
}
export function useSearchDashboardCollectionsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.SearchDashboardCollectionsNbDocument, baseOptions);
}
export function useSearchDashboardCouponCodesNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.SearchDashboardCouponCodesNbDocument, baseOptions);
}
export function useSearchDashboardCouponCodesNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.SearchDashboardCouponCodesNbDocument, baseOptions);
}
export function useSearchDashboardCouponCodesNbv2Query(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.SearchDashboardCouponCodesNbv2Document, baseOptions);
}
export function useSearchDashboardCouponCodesNbv2LazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.SearchDashboardCouponCodesNbv2Document, baseOptions);
}
export function useSearchDashboardCustomImageEditorProductsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.SearchDashboardCustomImageEditorProductsNbDocument, baseOptions);
}
export function useSearchDashboardCustomImageEditorProductsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.SearchDashboardCustomImageEditorProductsNbDocument, baseOptions);
}
export function useSearchDashboardProductsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.SearchDashboardProductsNbDocument, baseOptions);
}
export function useSearchDashboardProductsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.SearchDashboardProductsNbDocument, baseOptions);
}
export function useSearchDashboardSalesChannelsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.SearchDashboardSalesChannelsNbDocument, baseOptions);
}
export function useSearchDashboardSalesChannelsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.SearchDashboardSalesChannelsNbDocument, baseOptions);
}
export function useSearchProductCollectionsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.SearchProductCollectionsDocument, baseOptions);
}
export function useSearchProductCollectionsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.SearchProductCollectionsDocument, baseOptions);
}
export function useSearchProductsNbeQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.SearchProductsNbeDocument, baseOptions);
}
export function useSearchProductsNbeLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.SearchProductsNbeDocument, baseOptions);
}
export function useSearchSalesChannelsQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.SearchSalesChannelsDocument, baseOptions);
}
export function useSearchSalesChannelsLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.SearchSalesChannelsDocument, baseOptions);
}
export function useStorefrontHomepageQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.StorefrontHomepageDocument, baseOptions);
}
export function useStorefrontHomepageLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.StorefrontHomepageDocument, baseOptions);
}
export function useStudioGetProductsNbQuery(baseOptions) {
    return ApolloReactHooks.useQuery(Operations.StudioGetProductsNbDocument, baseOptions);
}
export function useStudioGetProductsNbLazyQuery(baseOptions) {
    return ApolloReactHooks.useLazyQuery(Operations.StudioGetProductsNbDocument, baseOptions);
}
export function useDashboardSubscriptionJobByIdNbSubscription(baseOptions) {
    return ApolloReactHooks.useSubscription(Operations.DashboardSubscriptionJobByIdNbDocument, baseOptions);
}
export function useDashboardSubscriptionReportByIdSubscription(baseOptions) {
    return ApolloReactHooks.useSubscription(Operations.DashboardSubscriptionReportByIdDocument, baseOptions);
}
