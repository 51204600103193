/**
 * Scale the integers from [srcMin, srcMax] to [targetMin, targetMax]
 *
 * @param  {number} unscaledNum
 * @param  {number} targetMin
 * @param  {number} targetMax
 * @param  {number} srcMin
 * @param  {number} srcMax
 */ export var scaleNumberBetween = function(unscaledNum, targetMin, targetMax, srcMin, srcMax) {
    return (targetMax - targetMin) * (unscaledNum - srcMin) / (srcMax - srcMin) + targetMin;
};
/** Scales the value range from [-1000, 1000] in the slide to [0.01, 1.0]
 *
 * @param  {number} scale
 */ export var getScaleTransformValue = function(scale) {
    return scaleNumberBetween(scale, 0.01, 1.0, -1000, 1000);
};
