import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { useEffect, useRef } from "react";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import { useRouter } from "next/router";
import { useAuth } from "@brikl/dashboard-utils";
import { routes } from "../constants/routes";
import { useBreadcrumbsContext } from "../providers/Breadcrumbs/useBreadcrumbsContext";
import { buildBreadCrumbsRoute } from "../util/buildBreadCrumbsRoute";
export var useBreadcrumbs = function(keys, salesChannelId) {
    //hooks
    var ref = _sliced_to_array(useBreadcrumbsContext(), 2), setBreadcrumbs = ref[1];
    var shopId = useAuth().shopId;
    var query = useRouter().query;
    var prevItemsRef = useRef(undefined);
    useEffect(function() {
        if (isEqual(keys, prevItemsRef.current)) return;
        //utilities
        var getRouteKey = function(index) {
            return keys.slice(0, index + 1).join(".children.");
        };
        var getRouteParentNode = function(routeKey) {
            var keyArr = routeKey.split(".");
            keyArr.splice(-2);
            return get(routes, keyArr.join("."), undefined);
        };
        //data
        var querySalesChannelId = query === null || query === void 0 ? void 0 : query.salesChannelId;
        var breadcrumbsItemData = keys.map(function(_, index) {
            var routeKey = getRouteKey(index);
            var route = buildBreadCrumbsRoute(routeKey);
            var routeParentNode = getRouteParentNode(routeKey);
            var routeName = (routeParentNode === null || routeParentNode === void 0 ? void 0 : routeParentNode.hideInBreadcrumb) ? (routeParentNode === null || routeParentNode === void 0 ? void 0 : routeParentNode.navText) + "/" + (route === null || route === void 0 ? void 0 : route.navText) : route === null || route === void 0 ? void 0 : route.navText;
            var routeHref = typeof (route === null || route === void 0 ? void 0 : route.path) === "function" ? route === null || route === void 0 ? void 0 : route.path(shopId, salesChannelId !== null && salesChannelId !== void 0 ? salesChannelId : querySalesChannelId) : (route === null || route === void 0 ? void 0 : route.path) || undefined;
            return {
                name: routeName,
                href: routeHref,
                hideInBreadcrumb: route === null || route === void 0 ? void 0 : route.hideInBreadcrumb
            };
        });
        var breadcrumbsData = breadcrumbsItemData.filter(function(o) {
            return !!o.name;
        });
        setBreadcrumbs({
            active: "breadcrumbs-".concat(breadcrumbsData.length - 1),
            items: breadcrumbsData.map(function(route, index) {
                return {
                    key: "breadcrumbs-".concat(index),
                    name: route.name,
                    href: route.href,
                    hideInBreadcrumb: route.hideInBreadcrumb
                };
            })
        });
        prevItemsRef.current = keys;
    }, [
        keys,
        salesChannelId,
        query,
        shopId,
        setBreadcrumbs
    ]);
};
