/**
 *
 * @param accessLevel | access user level
 * @param title | this can be anything eg: Catalog, Leads
 * @return boolean
 */ function getAccessValueFromKeyString(accessLevel, title) {
    // remove space
    var keyString = title.replace(/\s/g, "");
    for(var key in accessLevel){
        // find matching string
        if (key.toLowerCase().includes(keyString.toLowerCase())) {
            return accessLevel[key];
        }
    }
    return true;
}
export default getAccessValueFromKeyString;
