import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
export function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
export function validateHex(hex) {
    var re = /^((0x){0,1}|#{0,1})([0-9a-fA-F]{8}|[0-9a-fA-F]{6})$/;
    return re.test(String(hex));
}
export function validateNested(errors, nestedValue) {
    try {
        return errors && nestedValue && errors[nestedValue.split(".")[0]][nestedValue.split(".")[1]] ? true : false;
    } catch (error) {
        return false;
    }
}
export function validateNumberGreatThan0(value) {
    return value > 0 ? true : false;
}
export function validateNotEmpty(value) {
    return (value === null || value === void 0 ? void 0 : value.length) > 0 ? true : false;
}
export var shopPasswordValidation = {
    max: {
        value: 100,
        message: "Password should be maximum of 100 characters"
    },
    minLength: {
        value: 6,
        message: "Your password needs at 6 characters long"
    },
    pattern: {
        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{5,100}$/,
        message: "Your password needs at least 1 uppercase letter, 1 lowercase letters, 1 number and 6 characters long"
    }
};
export function clean(obj) {
    for(var prop in obj){
        if (obj[prop] === null || obj[prop] === undefined) {
            delete obj[prop];
        } else if (isArray(obj[prop])) {
            var propArray = obj[prop];
            if (propArray.length === 0) {
                delete obj[prop];
            }
        } else if (isObject(obj[prop])) {
            clean(obj[prop]);
        }
    }
    return obj;
}
