import moment from "moment-timezone";
export var checkActiveCycleStatus = function(opening, closing, isActive) {
    if (!isActive) return "inactive";
    var openingTimeWithOffset = moment(opening).utc();
    var closingTimeWithOffset = moment(closing).utc();
    var currentTimestamp = moment();
    if (currentTimestamp.isBetween(openingTimeWithOffset, closingTimeWithOffset, null, "[]") || !closing && currentTimestamp.isSameOrAfter(openingTimeWithOffset)) return "opened";
    if (currentTimestamp.isBefore(openingTimeWithOffset)) return "scheduled";
    return "closed";
};
