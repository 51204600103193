import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { createContext, useContext } from "react";
export var ShopContext = createContext(undefined);
export var useCurrentShop = function() {
    var context = useContext(ShopContext);
    if (context === undefined) {
        throw new Error("useCurrentShop should be used inside ShopContext.Provider");
    }
    var _context = _sliced_to_array(context, 1), currentShop = _context[0].currentShop;
    return currentShop;
};
export var useCurrentShopAdmin = function() {
    var context = useContext(ShopContext);
    if (context === undefined) {
        throw new Error("useCurrentShopAdmin should be used inside ShopContext.Provider");
    }
    var _context = _sliced_to_array(context, 1), currentShopAdmin = _context[0].currentShopAdmin;
    return currentShopAdmin;
};
export var useCurrentShopActions = function() {
    var context = useContext(ShopContext);
    if (context === undefined) {
        throw new Error("useCurrentShopActions should be used inside ShopContext.Provider");
    }
    var _context = _sliced_to_array(context, 2), _ = _context[0], actions = _context[1];
    return actions;
};
