import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { v4 } from "uuid";
/**
 * conver file conect to base64
 * @param {File} file
 * @returns {Promise<string>}
 */ export function convertFileToBase64(file) {
    return new Promise(function(resolve, reject) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function() {
            return resolve(reader.result);
        };
        reader.onerror = function(error) {
            return reject(error);
        };
    });
}
/**
 * get image blob from url
 * @param {string} url
 * @returns {Promise<blob>}
 */ export function getBlobByImageUrl(url) {
    return _getBlobByImageUrl.apply(this, arguments);
}
function _getBlobByImageUrl() {
    _getBlobByImageUrl = _async_to_generator(function(url) {
        var headers, response, _tmp;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    headers = new Headers();
                    headers.append("pragma", "no-cache");
                    headers.append("cache-control", "no-cache");
                    _tmp = {};
                    response = fetch(url, (_tmp.method = "GET", _tmp.headers = headers, _tmp));
                    return [
                        4,
                        response
                    ];
                case 1:
                    return [
                        2,
                        _state.sent().blob()
                    ];
            }
        });
    });
    return _getBlobByImageUrl.apply(this, arguments);
}
var compressionOption = {
    // some browsers not support offscreenCanvas so it will fallback to main thread
    useWebWorker: true,
    maxSizeMB: 1.2
};
/**
 * compress image within size if 1.2MB
 * @param {string} src
 * @param {number} initialQuality default 0.01
 * @returns
 */ export function compressImage(src) {
    return _compressImage.apply(this, arguments);
}
function _compressImage() {
    _compressImage = _async_to_generator(function(src) {
        var initialQuality, ref, browserImageCompression, blobFile, imageFile, _tmp, compressFile, _tmp1, _tmp2;
        var _arguments = arguments;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    initialQuality = _arguments.length > 1 && _arguments[1] !== void 0 ? _arguments[1] : 0.01;
                    return [
                        4,
                        import("browser-image-compression")
                    ];
                case 1:
                    ref = _state.sent(), browserImageCompression = ref.default;
                    return [
                        4,
                        getBlobByImageUrl(src)
                    ];
                case 2:
                    blobFile = _state.sent();
                    _tmp = {};
                    imageFile = new File([
                        blobFile
                    ], "".concat(v4(), ".png"), (_tmp.type = "image/png", _tmp));
                    _tmp1 = {};
                    _tmp2 = {};
                    return [
                        4,
                        browserImageCompression(imageFile, _object_spread_props(_object_spread(_tmp1, compressionOption), (_tmp2.initialQuality = initialQuality, _tmp2)))
                    ];
                case 3:
                    compressFile = _state.sent();
                    return [
                        2,
                        convertFileToBase64(compressFile)
                    ];
            }
        });
    });
    return _compressImage.apply(this, arguments);
}
/**
 * Return image in base64 format give by url
 * @param {string} url
 * @returns {Promise<string>} base64 image
 */ export function getBase64ImageByURL(url) {
    return _getBase64ImageByURL.apply(this, arguments);
}
function _getBase64ImageByURL() {
    _getBase64ImageByURL = _async_to_generator(function(url) {
        var response, blob;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        fetch(url)
                    ];
                case 1:
                    response = _state.sent();
                    return [
                        4,
                        response.blob()
                    ];
                case 2:
                    blob = _state.sent();
                    return [
                        4,
                        new Promise(function(resolve, reject) {
                            var reader = new FileReader();
                            reader.onloadend = function() {
                                return resolve(reader.result);
                            };
                            reader.onerror = reject;
                            reader.readAsDataURL(blob);
                        })
                    ];
                case 3:
                    return [
                        2,
                        _state.sent()
                    ];
            }
        });
    });
    return _getBase64ImageByURL.apply(this, arguments);
}
/**
 * Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
 * @param {string} hex
 * @returns {object} rgb object
 */ export function hexToRgb(hex) {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
    });
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}
