import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { nbeQueries } from "./constants/nbeQueries";
import { getHttpLink, legacyHttpHasuraLink, legacyHttpLink } from "./links/https";
import { createLegacyWSLink, createWSLink } from "./links/ws";
import { createAuthorizationHeader } from "./utils/createAuthorizationHeader";
export function createApolloLinks(param) {
    var env = param.env, shopId = param.shopId, token = param.token;
    var getAuthHeaders = function() {
        return createAuthorizationHeader({
            token: token,
            shopId: shopId
        });
    };
    var authLink = setContext(function() {
        var _ref = _async_to_generator(function(_, param) {
            var previousHeaders, ref, authHeaders, _tmp;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        previousHeaders = param.headers;
                        return [
                            4,
                            getAuthHeaders()
                        ];
                    case 1:
                        ref = _state.sent(), authHeaders = ref.headers;
                        _tmp = {};
                        return [
                            2,
                            (_tmp.headers = _object_spread(_object_spread_props(_object_spread({}, authHeaders), {
                                "x-brikl-shop-id": (authHeaders === null || authHeaders === void 0 ? void 0 : authHeaders["x-brikl-shop-id"]) || (previousHeaders === null || previousHeaders === void 0 ? void 0 : previousHeaders["x-brikl-shop-id"]) || null
                            }), previousHeaders), _tmp)
                        ];
                }
            });
        });
        return function(_, _1) {
            return _ref.apply(this, arguments);
        };
    }());
    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    var httpLink = getHttpLink(env);
    var links = "object" !== "undefined" ? split(function(param) {
        var query = param.query;
        var definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    }, createWSLink(getAuthHeaders()), httpLink) : httpLink;
    // Combnation of legacy normal link and legacy harsura link
    var httpLegacyLinks = split(function(param) {
        var query = param.query;
        var definition = getMainDefinition(query);
        return definition.name.value.indexOf("reportOrders") === 0;
    }, legacyHttpHasuraLink, legacyHttpLink);
    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    var legacyLinks = "object" !== "undefined" ? split(function(param) {
        var query = param.query;
        var definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    }, createLegacyWSLink(getAuthHeaders()), httpLegacyLinks) : httpLegacyLinks;
    //Combination of legacy links and current links
    var allLinks = split(function(param) {
        var query = param.query, getContext = param.getContext;
        var definition = getMainDefinition(query);
        return nbeQueries.includes(definition.name.value) || getContext().hasuraDependency === true;
    }, links, legacyLinks);
    return {
        authLink: authLink,
        allLinks: allLinks
    };
}
