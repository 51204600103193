import moment from "moment-timezone";
import { getTimezoneOffset } from "@brikl/shared-utils";
export var checkActiveCycleStatus = function(opening, closing, isActive) {
    var now = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : moment();
    if (!isActive) return "inactive";
    var localTimezone = moment.tz.guess();
    var localTimezoneOffset = getTimezoneOffset(localTimezone);
    var openingTimeWithOffset = moment(opening).utc().utcOffset(localTimezoneOffset !== null && localTimezoneOffset !== void 0 ? localTimezoneOffset : 0);
    var closingTimeWithOffset = moment(closing).utc().utcOffset(localTimezoneOffset !== null && localTimezoneOffset !== void 0 ? localTimezoneOffset : 0);
    if (now.isBetween(openingTimeWithOffset, closingTimeWithOffset, null, "[]") || !closing && now.isSameOrAfter(openingTimeWithOffset)) return "opened";
    if (now.isBefore(openingTimeWithOffset)) return "scheduled";
    return "closed";
};
