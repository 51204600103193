import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import jwtDecode from "jwt-decode";
export var decodeJwt = function(jwtToken) {
    var decoded = // eslint-disable-next-line @typescript-eslint/no-explicit-any
    jwtDecode(jwtToken);
    return _object_spread_props(_object_spread({}, decoded), {
        imageUrl: decoded === null || decoded === void 0 ? void 0 : decoded.picture,
        userId: decoded === null || decoded === void 0 ? void 0 : decoded["https://brikl.com/user-id"],
        isBriklUser: isBriklUser(decoded === null || decoded === void 0 ? void 0 : decoded.email)
    });
};
export var isBriklUser = function(email) {
    return (email === null || email === void 0 ? void 0 : email.includes("@brikl.io")) || (email === null || email === void 0 ? void 0 : email.includes("@brikl.com"));
};
