import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import padStart from "lodash/padStart";
export var TIMEZONE_FORMAT_OPTIONS = {
    timeZoneName: "short"
};
export var DATE_FORMAT_OPTIONS = {
    day: "numeric",
    month: "short",
    year: "numeric"
};
export var DATE_WITH_TZ_FORMAT_OPTIONS = _object_spread({}, DATE_FORMAT_OPTIONS, TIMEZONE_FORMAT_OPTIONS);
export var TIME_FORMAT_OPTIONS = {
    hour: "numeric",
    minute: "numeric"
};
export var TIME_WITH_TZ_FORMAT_OPTIONS = _object_spread({}, TIME_FORMAT_OPTIONS, TIMEZONE_FORMAT_OPTIONS);
export var DATE_TIME_FORMAT_OPTIONS = _object_spread({}, DATE_FORMAT_OPTIONS, TIME_FORMAT_OPTIONS);
export var DATE_TIME_WITH_TZ_FORMAT_OPTIONS = _object_spread({}, DATE_FORMAT_OPTIONS, TIME_FORMAT_OPTIONS, TIMEZONE_FORMAT_OPTIONS);
/**
 * This is a dynamic datetime formatter depending on the location (handled by the browser)
 */ export function localFormatDateTime(date) {
    var options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    var locales = options.locales, displayTimezone = options.displayTimezone;
    var format = displayTimezone ? DATE_TIME_WITH_TZ_FORMAT_OPTIONS : DATE_TIME_FORMAT_OPTIONS;
    return date.toLocaleString(locales, format);
}
/**
 * This is a dynamic date formatter depending on the location (handled by the browser)
 */ export function localFormatDate(date) {
    var options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    var locales = options.locales, displayTimezone = options.displayTimezone;
    var format = displayTimezone ? DATE_WITH_TZ_FORMAT_OPTIONS : DATE_FORMAT_OPTIONS;
    return date.toLocaleDateString(locales, format);
}
/**
 * This is a dynamic time formatter depending on the location (handled by the browser)
 */ export function localFormatTime(date) {
    var options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    var locales = options.locales, displayTimezone = options.displayTimezone;
    var format = displayTimezone ? TIME_WITH_TZ_FORMAT_OPTIONS : TIME_FORMAT_OPTIONS;
    return date.toLocaleTimeString(locales, format);
}
/**
 * This parse the UTC time to the localetime with or without showing the UTC offset
 */ export function localeTimePeriod(fromDate, toDate, options) {
    var utcOffset = -new Date().getTimezoneOffset() / 60;
    var utcOffsetPrefix = utcOffset >= 0 ? "+" : "-";
    var utcOffsetWithPadding = padStart(Math.abs(utcOffset).toString(), 2, "0");
    var fromLocale = fromDate.toLocaleTimeString(undefined, {
        hour12: false
    });
    var toLocale = toDate.toLocaleTimeString(undefined, {
        hour12: false
    });
    return "".concat(fromLocale, "-").concat(toLocale, " ").concat((options === null || options === void 0 ? void 0 : options.isShowTimezone) ? "(".concat(utcOffsetPrefix).concat(utcOffsetWithPadding, ":00)") : "");
}
