import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import React from "react";
import noop from "lodash/noop";
import { permissionRoleAccess } from "../constants/roleViewsAccess";
import { makeDefaultUserAccessKey } from "../services/makeDefaultUserAccessKey";
export var AUTH_INITIAL_STATE = {
    user: null,
    isLoggedIn: false,
    isAuthenticating: false,
    accessLevel: makeDefaultUserAccessKey(permissionRoleAccess)
};
export var AuthContext = React.createContext(_object_spread_props(_object_spread({}, AUTH_INITIAL_STATE), {
    shopId: null,
    signIn: function() {
        return Promise.resolve(false);
    },
    signOut: noop,
    setUserAccessLevel: noop,
    setUserRole: noop
}));
