import { useState } from "react";
import { useDashboardSupplierServiceAvailabilityQuery } from "@brikl/codegen";
import { useStickyAlert } from "@brikl/dashboard-provider";
import { localeTimePeriod } from "@brikl/shared-utils";
// 30 Minutes
var INTERVAL_TIME = 30 * 60 * 1000;
// Set up the local time for 10:00 - 16:00 UTC only the time is matter not date
var START_TIME_UTC = new Date(Date.UTC(2022, 12, 1, 10, 0, 0));
var END_TIME_UTC = new Date(Date.UTC(2022, 12, 1, 16, 0, 0));
var MA_TIME = localeTimePeriod(START_TIME_UTC, END_TIME_UTC, {
    isShowTimezone: true
});
export var useETLSupplierDataLoadingAlert = function() {
    var ref = useStickyAlert(), setAlertOptions = ref.setAlertOptions, isShowAlert = ref.isShowAlert, setIsShowAlert = ref.setIsShowAlert;
    var ref1 = useState(false), isAlreadyShowOnce = ref1[0], setIsAlreadyShowOnce = ref1[1];
    var ref2 = useDashboardSupplierServiceAvailabilityQuery({
        pollInterval: INTERVAL_TIME
    }), data = ref2.data, loading = ref2.loading;
    var ref3;
    var isAllowAddProductFromSupplier = ((ref3 = data === null || data === void 0 ? void 0 : data.supplierServiceAvailability) !== null && ref3 !== void 0 ? ref3 : {}).isAllowAddProductFromSupplier;
    if (!loading && !isShowAlert && !isAllowAddProductFromSupplier && !isAlreadyShowOnce) {
        setAlertOptions({
            status: "warning",
            title: "Adding products from suppliers is currently disabled for system maintenance for approximately 3 hours, from ".concat(MA_TIME, "."),
            onClose: function() {
                return setIsShowAlert(false);
            }
        });
        setIsAlreadyShowOnce(true);
    }
    return {
        isAllowAddProductFromSupplier: isAllowAddProductFromSupplier,
        loading: loading
    };
};
