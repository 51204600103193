import { FC, ReactNode, useEffect } from 'react'

import * as Sentry from '@sentry/nextjs'
import FullStory, { identify } from 'react-fullstory'

import { useAuth } from '@brikl/dashboard-utils'
import { Flex, Spacer, Spinner, Text } from '@brikl/design-core'

import { environment } from '../../../../environments/environment'
import { TermsOfService } from '../../../modules/terms-of-service'

import { FeatureNotice } from './FeatureNotice'

const FS_ORG_ID = 'R9SGS'

type AppLayoutProps = {
  children?: ReactNode
}

export const AppLayout: FC<AppLayoutProps> = props => {
  const authContext = useAuth()

  useEffect(() => {
    if (authContext.user?.userId && authContext.shopId) {
      const userId = authContext.user.userId
      try {
        //fullstory identification
        if (identify)
          identify(userId, {
            shopId: authContext.shopId,
          })
      } catch (error) {
        console.error(error, {
          module: 'AppLayout',
          action: 'fullstorySetup',
        })
      }
      try {
        //hubspot identification
        if (environment.API_STAGE === 'production' && authContext.user?.email) {
          const _hsq = (window._hsq = window._hsq || [])
          _hsq.push([
            'identify',
            {
              email: authContext.user?.email,
              shopId: authContext.shopId,
            },
          ])
        }
      } catch (error) {
        console.error(error, {
          module: 'AppLayout',
          action: 'hubspotSetup',
        })
      }
      try {
        if (!authContext?.user?.isBriklUser) {
          window.analytics?.identify(userId, {
            email: authContext.user?.email,
          })
        }
        window.analytics?.group(authContext.shopId, {})
      } catch (error) {
        console.error(error, {
          module: 'AppLayout',
          action: 'userAuthentication',
        })
      }
      try {
        Sentry.configureScope(scope => {
          scope.setTag('shopId', authContext.shopId)
          scope.setTag('isBriklUser', authContext?.user?.isBriklUser)
          scope.setUser({ id: userId, email: authContext.user?.email })
        })
      } catch (error) {
        console.error(error, {
          module: 'AppLayout',
          action: 'sentrySetup',
        })
      }
    }
  }, [authContext.shopId, authContext.user])

  if (authContext.isAuthenticating) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        height="inherit"
        flexDirection="column"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="brand.500"
          size="xl"
        />
        <Spacer space="small" />
        <Text>Authenticating...</Text>
      </Flex>
    )
  }

  const { isLoggedIn } = authContext

  return (
    <>
      {isLoggedIn && authContext.shopId && (
        <>
          <TermsOfService />
          <FeatureNotice shopId={authContext.shopId} />
        </>
      )}
      {props.children}
      {authContext?.user?.isBriklUser === true ||
      environment.API_STAGE !== 'production' ? null : (
        <FullStory org={FS_ORG_ID} />
      )}
    </>
  )
}
