export var getCustomColors = function(colors) {
    return {
        bg: {
            primary: colors.gray[50],
            item: colors.gray[100],
            activeItem: colors.gray[200],
            container: colors.white,
            disabled: colors.gray[100],
            delete: colors.red[50],
            error: colors.red[500],
            warning: colors.yellow[500],
            success: colors.green[500],
            errorLight: colors.red[50],
            warningLight: colors.yellow[50],
            successLight: colors.green[50],
            transparent: colors.transparent,
            header: colors.gray[200]
        },
        border: {
            primary: colors.gray[500],
            divider: colors.gray[200],
            transparent: colors.transparent
        },
        brand: {
            primary: colors.brand[500],
            hoverPrimary: colors.brand[400],
            activePrimary: colors.brand[500],
            secondary: colors.brand[50],
            activeSecondary: colors.brand[200],
            50: colors.brand[50],
            100: colors.brand[100],
            200: colors.brand[200],
            300: colors.brand[300],
            400: colors.brand[400],
            500: colors.brand[500],
            600: colors.brand[600],
            700: colors.brand[700],
            800: colors.brand[800],
            900: colors.brand[900]
        },
        content: {
            primary: colors.gray[900],
            secondary: colors.gray[500],
            form: colors.gray[700],
            disabled: colors.gray[400],
            guide: colors.gray[500],
            navigation: colors.gray[700],
            error: colors.red[500],
            warning: colors.yellow[500],
            success: colors.green[500],
            delete: colors.red[500],
            deleteHover: colors.red[400],
            contrast: colors.white
        }
    };
};
