export var variantTypes = [
    "outline",
    "ghost",
    "unstyled",
    "link",
    "solid",
    "brikl-icon",
    "important",
    "primary",
    "secondary", 
];
var variants = {
    outline: {
        variant: "outline"
    },
    solid: {
        variant: "solid"
    },
    ghost: {
        variant: "ghost"
    },
    unstyled: {
        variant: "unstyled"
    },
    link: {
        variant: "link"
    },
    primary: {
        variant: undefined,
        props: {
            bg: "brand.500",
            color: "white",
            _hover: {
                backgroundColor: "none"
            },
            _focus: {
                border: "none",
                backgroundColor: "none"
            }
        }
    },
    secondary: {
        variant: undefined,
        props: {
            bg: "gray.100",
            color: "brand.500",
            _hover: {
                backgroundColor: "none"
            },
            _focus: {
                border: "none",
                backgroundColor: "none"
            }
        }
    },
    important: {
        variant: undefined,
        props: {
            bg: "red.500",
            color: "white",
            _hover: {
                backgroundColor: "none"
            },
            _focus: {
                border: "none",
                backgroundColor: "none"
            }
        }
    },
    "brikl-icon": {
        props: {
            bg: "transparent",
            color: "brand.500",
            _hover: {
                backgroundColor: "none"
            },
            _focus: {
                border: "none",
                backgroundColor: "none"
            }
        }
    }
};
export var getVariant = function(variant) {
    if (variants[variant] === undefined) {
        return {
            variant: undefined
        };
    } else {
        return variants[variant];
    }
};
