export var animationAssets = {
    briklLogo: "https://assets8.lottiefiles.com/packages/lf20_f2rnh7jn.json",
    noShippingZone: "https://assets3.lottiefiles.com/packages/lf20_63f9tkcu/NoShippingZoneImageFinal.json",
    noData: "https://assets8.lottiefiles.com/packages/lf20_zv88emch/NoDataImage.json",
    noTime: "https://assets7.lottiefiles.com/packages/lf20_zi4y86sl/NoTimeImage.json",
    variantAnimation: "https://assets8.lottiefiles.com/packages/lf20_mga94zh7.json"
};
export var animationVariants = [
    "briklLogo",
    "noShippingZone",
    "noData",
    "noTime",
    "variantAnimation", 
];
