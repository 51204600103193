//[[SALES_REP_TO_BE REMOVED]]
var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30, ref31, ref32, ref33;
import { routes } from "@brikl/dashboard-provider";
var dashboardRoutes = routes.dashboard;
/**
 * @deprecated Let's use more consistent value which is path
 */ export var SALES_REP_BLACKLIST_ROUTES = [
    (ref1 = (ref = dashboardRoutes.children) === null || ref === void 0 ? void 0 : ref.catalog.children) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.product) === null || ref2 === void 0 ? void 0 : ref2.navText,
    (ref4 = (ref3 = dashboardRoutes.children) === null || ref3 === void 0 ? void 0 : ref3.catalog.children) === null || ref4 === void 0 ? void 0 : ref4["product-options"].navText,
    (ref5 = dashboardRoutes.children) === null || ref5 === void 0 ? void 0 : ref5.customers.navText,
    (ref7 = (ref6 = dashboardRoutes.children) === null || ref6 === void 0 ? void 0 : ref6.saleschannels.children) === null || ref7 === void 0 ? void 0 : ref7.storefronts.navText,
    (ref8 = dashboardRoutes.children) === null || ref8 === void 0 ? void 0 : ref8.design.navText,
    (ref10 = (ref9 = dashboardRoutes.children) === null || ref9 === void 0 ? void 0 : ref9.catalog.children) === null || ref10 === void 0 ? void 0 : ref10.attribute.navText,
    (ref12 = (ref11 = dashboardRoutes.children) === null || ref11 === void 0 ? void 0 : ref11.catalog.children) === null || ref12 === void 0 ? void 0 : ref12["supplier-products"].navText,
    (ref14 = (ref13 = dashboardRoutes.children) === null || ref13 === void 0 ? void 0 : ref13.storeDesign.children) === null || ref14 === void 0 ? void 0 : ref14.themes.navText,
    (ref16 = (ref15 = dashboardRoutes.children) === null || ref15 === void 0 ? void 0 : ref15.storeDesign.children) === null || ref16 === void 0 ? void 0 : ref16.menus.navText, 
];
export var SALES_REP_BLACKLIST_ROUTES_PATH = [
    (ref18 = (ref17 = dashboardRoutes.children) === null || ref17 === void 0 ? void 0 : ref17.catalog.children) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.product) === null || ref19 === void 0 ? void 0 : ref19.path,
    (ref21 = (ref20 = dashboardRoutes.children) === null || ref20 === void 0 ? void 0 : ref20.catalog.children) === null || ref21 === void 0 ? void 0 : ref21["product-options"].path,
    (ref22 = dashboardRoutes.children) === null || ref22 === void 0 ? void 0 : ref22.customers.path,
    (ref24 = (ref23 = dashboardRoutes.children) === null || ref23 === void 0 ? void 0 : ref23.saleschannels.children) === null || ref24 === void 0 ? void 0 : ref24.storefronts.path,
    (ref25 = dashboardRoutes.children) === null || ref25 === void 0 ? void 0 : ref25.design.path,
    (ref27 = (ref26 = dashboardRoutes.children) === null || ref26 === void 0 ? void 0 : ref26.catalog.children) === null || ref27 === void 0 ? void 0 : ref27.attribute.path,
    (ref29 = (ref28 = dashboardRoutes.children) === null || ref28 === void 0 ? void 0 : ref28.catalog.children) === null || ref29 === void 0 ? void 0 : ref29["supplier-products"].path,
    (ref31 = (ref30 = dashboardRoutes.children) === null || ref30 === void 0 ? void 0 : ref30.storeDesign.children) === null || ref31 === void 0 ? void 0 : ref31.themes.path,
    (ref33 = (ref32 = dashboardRoutes.children) === null || ref32 === void 0 ? void 0 : ref32.storeDesign.children) === null || ref33 === void 0 ? void 0 : ref33.menus.path, 
];
export var SALES_REP_BLACKLIST_CATALOG_PAGE = [
    "productListing",
    "productOptionListing",
    "supplierProducts", 
];
