import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
var hasSquareBrackets = function(word) {
    var BRACKET_WITH_WORDS_INSIDE = new RegExp(/\[.*?\]/);
    return BRACKET_WITH_WORDS_INSIDE.test(word);
};
var addSquareBrackets = function(word) {
    return hasSquareBrackets(word) ? word : "[".concat(word, "]");
};
/**
 * @description newer localization from design team contain placeholder for dynamic localization, this function put the dynamic term in place of the placeholder
 * @param placeholderReplacement record of placeholder name without the brackets, and the value you want to replace
 * @example
 * const word = your order [orderId] has been placed, we will send email to [email]
 * const placeholder = {orderId: '1234', email: 'email@gmail.com'}
 * const result = fillPlaceholder(word, placeholder) //your order 1234 has been placed, we will send email to email@gmai.com
 */ export var fillPlaceholder = function(word) {
    var placeholderReplacement = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    var placeholderReplacementList = Object.entries(placeholderReplacement);
    return placeholderReplacementList.reduce(function(modifiedWord, param) {
        var _param = _sliced_to_array(param, 2), placeholderName = _param[0], replacement = _param[1];
        return modifiedWord.replace(addSquareBrackets(placeholderName), replacement);
    }, word);
};
