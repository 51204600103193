import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { catalogAttributeGraphQLWhitelist, catalogProductGraphQLWhitelist, collectionGraphQLWhitelist, emailConfigurationGraphQLWhitelist } from "@brikl/dashboard-utils";
export var nbeQueries = // email configuration
_to_consumable_array(emailConfigurationGraphQLWhitelist).concat(// catalog v2
_to_consumable_array(catalogProductGraphQLWhitelist), _to_consumable_array(catalogAttributeGraphQLWhitelist), _to_consumable_array(collectionGraphQLWhitelist), [
    "getSalesChannelsNBE",
    "getSalesChannelNBE",
    "getSalesChannelNameNBE",
    "refundPayment",
    "dashboardUserAccessLevel",
    "dashboardUpdateOrderShippingStatus",
    "dashboardUpdateOrderPaymentStatus",
    "updateOrderTimelineVisibility",
    "dashboardUpdateOrderMetadata",
    "dashboardTriggerEvent",
    "dashboardCreateReport",
    "dashboardGetAllReports",
    "dashboardGetReportById",
    "dashboardSubscriptionReportById",
    "dashboardQuotes",
    "dashboardQuoteById",
    "dashboardLeads",
    "dashboardCreateLead",
    "dashboardLeadDetail",
    "dashboardUpdateLeadStatus",
    "dashboardCreateQuote",
    "dashboardAssignLeadsToQuote",
    "dashboardUpdateQuoteName",
    "dashboardUpdateQuoteStatus",
    "dashboardUpdateQuoteCurrency",
    "dashboardUpdateQuoteSalesChannel",
    "dashboardCreateQuoteComment",
    "dashboardDeleteQuoteComment",
    "dashboardCreateQuoteNote",
    "dashboardCreateOrderShipment",
    "dashboardAddressDetail",
    "dashboardUpdateLeadAddress",
    "dashboardUpdateLeadMetadata",
    "dashboardOrders",
    "dashboardOrderById",
    "dashboardRemoveLeadFromQuote",
    "dashboardCommunicationPanelOrders",
    "exportFeenyDesign",
    "feeneyDesignExportById",
    "instagramPosts",
    "storefrontHomepage",
    "connectInstagramAccount",
    "dashboardUpdateOrderShippingAddress",
    "dashboardCreateOrderNote",
    "dashboardDeleteOrderNote",
    "dashboardUpdateOrderItemVariant",
    "dashboardUpdateOrderProductionStatus",
    "dashboardGenerateOrderInvoice",
    "getPaymentProviderConfigurations",
    "dashboardSetupStripeConnect",
    "createPaymentProviderConfiguration",
    "getSupportedPaymentMethods",
    "deletePaymentProviderConfiguration",
    // Payment configuration teamstore
    "getAssignedPaymentConfigurations",
    "assignPaymentConfiguration",
    "getAllPaymentProviderConfigurations",
    "softDeleteAssignedPaymentConfiguration",
    "updateAssignedPaymentConfiguration",
    "getAllPaymentMethods",
    "getEmailDomains",
    "createEmailDomain",
    "deleteEmailDomain",
    "getAllSupportedPaymentMethods",
    "getPaymentMethodInfoBySalesChannelId",
    "getPaymentMethodInfoByShop",
    "createPaymentMethodInfo",
    "updatePaymentMethodInfo",
    "deletePaymentMethodInfo",
    "createShopAddress",
    "getShopAddresses",
    "getAddressById",
    "updateShopAddress",
    "dashboardGetTermOfServiceAcceptance",
    "dashboardUpdateTermOfServiceAcceptance",
    "updatePaymentProviderConfiguration",
    // font settings
    "dashboardShopDefaultFontSettings",
    "dashboardDisableShopDefaultFont",
    "dashboardEnableShopDefaultFont",
    "verifyEmailDomainNBE",
    "orderDetailNewBackend",
    "updateAdminUserInfo",
    // light settings
    "getDashboardLightPresetsNBE",
    "getDashboardLightSettingNBE",
    "updateDashboardProductLightPresetNBE",
    "updateDashboardDefaultProductLightPresetNBE",
    "applyDashboardProductLightPresetToProductNBE",
    "deleteDashboardProductLightPresetNBE",
    "createDashboardProductLightPresetNBE",
    "applyDashboardProductLightPresetToProductNBE",
    "createDashboardLeadItemNBE",
    "leadItemByProductDesignId",
    "addLead",
    "getDashboardcouponUsageByDiscountIdNB",
    "getDashboardProductBrandsNB",
    "assignDashboardBrandToProductNB",
    "unassignDashboardBrandToProductNB",
    "getDashboardProductByIdNB",
    "getDashboardProductBrandByProductIdNB",
    "createDashboardSupplierProductNB",
    "getDashboardSupplierProductNB",
    "getDashboardTeamStoresNB",
    "getDashboardStorefrontsNB",
    "generateDashboardBulkOrderInvoices",
    "generateDashboardBulkOrderInvoicesByIds",
    "getDashboardJobByIdNB",
    "getDashboardSalesChannelByIdNB",
    "createDashboardTeamStoreNB",
    "generateDashboardTeamStoreSlugUrlNB",
    "getDashboardOpeningCyclesNB",
    "createDashboardOpeningCycleNB",
    "updateDashboardOpeningCycleNB",
    "deleteDashboardOpeningCycleNB",
    "getDashboardTeamStoreAvailability",
    "getDashboardTeamStoreContentNB",
    "getDashboardStorefrontContentNB",
    "updateDashboardTeamStoreContentNB",
    "updateDashboardStorefrontContentNB",
    "getDashboardStorefrontDeploymentNB",
    "createDashboardDeploymentNB",
    "publishDashboardDeploymentNB",
    "getDashboardCustomImageEditorProductsNB",
    "generateDashboardBulkOrderInvoices",
    "generateDashboardBulkOrderInvoicesByIds",
    "getDashboardJobByIdNB",
    "updateDashboardTeamStoreAvailabilityNB",
    "updateDashboardSalesChannelByIdNB",
    "getDashboardTeamStoreInformationNB",
    "updateDashboardTeamStoreInformationNB",
    "getDashboardStorefrontInformationNB",
    "updateDashboardStorefrontInformationNB",
    "deleteDashboardTeamStoreNB",
    "deleteDashboardStorefrontNB",
    "getDashboardTeamStoreStructureNB",
    "createDashboardTeamStoreGroupNB",
    "deleteDashboardTeamStoreGroupNB",
    "updateDashboardTeamStoreGroupNB",
    "updateDashboardTeamStoreStrucutreNB",
    "updateDashboardTeamStoreTargetCategoryNB",
    "createDashboardTeamStoreTargetCategoryNB",
    "deleteDashboardTeamStoreTargetCategoryNB",
    "getDashboardTeamStorePersonalizationNB",
    "createDashboardTeamStorePersonalizationGroupNB",
    "updateDashboardProductPersonalizationGroupNB",
    "deleteDashboardProductPersonalizationGroupNB",
    "createDashboardPersonalizationOptionNB",
    "updateDashboardPersonalizationOptionNB",
    "deleteDashboardPersonalizationOptionNB",
    "searchDashboardProductsNB",
    "getDashboardSalesChannelProductsNB",
    "addDashboardProductToSalesChannelNB",
    "removeDashboardProductToSalesChannelNB",
    "updateDashboardSalesChannelProductNB",
    "getDashboardSalesChannelCollectionsNB",
    "searchDashboardCollectionsNB",
    "addDashboardCollectionToSalesChannelNB",
    "updateDashboardSalesChannelCollectionNB",
    "removeDashboardCollectionFromSalesChannelNB",
    "searchDashboardProductPackagesNB",
    "removeDashboardProductPackageFromSalesChannelNB",
    "updateDashboardSalesChannelProductPackageNB",
    "getDashboardSalesChannelProductPackagesNB",
    "addDashboardProductPackageToSalesChannelNB",
    "duplicateDashboardTeamStoreNB",
    "searchDashboardCustomImageEditorProductsNB",
    "bulkAddDashboardProductsToSalesChannel",
    "createProductFromSupplierNB",
    "getDashboardProductsSupplierPageNB",
    // Export custom image placeholder
    "exportCustomImagePlaceholder",
    "dashboardSubscriptionJobByIdNB",
    "updateSalesChannelProductMediaNB",
    "deleteSalesChannelProductMediaNB",
    //Pricing NBE (Will be migrated to whitelist file in thier own module)
    "getDashboardDiscountListNB",
    "searchDashboardCouponCodesNB",
    "createDashboardDiscountNB",
    "getDashboardDiscountDetailNB",
    "updateDashboardDiscountDetailNB",
    "deleteDashboardDiscountNB",
    "createDashboardDiscountRuleNB",
    "updateDashboardDiscountRuleNB",
    "deleteDashboardDiscountRuleNB",
    "addDashboardDiscountRuleObjectNB",
    "deleteDashboardDiscountRuleObjectNB",
    "toggleDashboardDiscountCouponNB",
    "generateDashboardCouponNB",
    "setDashboardDefaultTaxRateNB",
    "setDashboardTaxRateNB",
    "deleteDashboardTaxRateNB",
    "getDashboardTaxSettingsNB",
    "getDashboardSalesChannelDiscountListNB",
    "getDashboardDiscountObjectProductsByIdsNB",
    "searchDashboardProductsNB",
    "searchDashboardProductPackagesNB",
    "searchDashboardDiscountObjectProductsNB",
    "addDashboardDiscountFreeProductNB",
    "deleteDashboardCollectionTaxRateNB",
    "setDashboardCollectionDefaultTaxRateNB",
    "setDashboardCollectionTaxRateNB",
    "toggleDashboardCollectionTaxSettings",
    "getDashboardCollectionTaxSettingsNB",
    "getDashboardCollectionTaxListNB",
    "updateDashboardProductPriceNB",
    "deleteDashboardDiscountFreeProductNB",
    "searchDashbordCollectionsNB",
    "getDashboardCollectionsNB",
    "createDashboardSalesChannelDiscountNB",
    "getDashboardProductsNB",
    "getDashboardSupplierProductsNB",
    "getDashboardSupplierProductVariantsNB",
    "getDashboardSupplierProductBrandsNB",
    "getDashboardSupplierProductCategoriesNB",
    "getDashboardSuppliersNB",
    "getDashboardOrderItemProductByIdNB",
    "getDashboardPricingConfigurationNB",
    "bulkUpdateSalesChannelProductsPriceModifier",
    "getDashboardPricingModifierNB",
    "getDashboardVerifiedSuppliers",
    "getDashboardVerifiedSupplierProductBrandsNB",
    "getDashboardVerifiedSupplierProductCategoriesNB",
    "getDashboardSupplierProductDetailPage",
    "dashboardSupplierServiceAvailability",
    //Shipping migration
    "getDashboardShippingSettingsNB",
    "createDashboardShippingMethodNB",
    "createDashboardShippingSettingsNB",
    "createDashboardShippingZoneNB",
    "deleteDashboardShippingSettingsNB",
    "deleteDashboardShippingZoneNB",
    "deleteDashboardShippingMethodNB",
    "updateDashboardShippingMethodNB",
    "updateDashboardShippingZoneNB",
    "disableShippingSettingsNB",
    "enableShippingSettingsNB",
    // Search migrations
    "searchProductsNBE",
    "searchProductCollections",
    "searchSalesChannels",
    "updateStorefront",
    "updateDashboardProductVariantNB",
    "syncDashboardProductVariantsNB",
    "deleteDashboardProductVariantNB",
    "searchDashboardSalesChannelsNB",
    "getDashboardSalesChannelByIds",
    // custom image editor
    "createCustomPlaceholderNB",
    "getProductImagePlaceholderByProductIdNB",
    "deleteProductImagePlaceholderNB",
    "updateCustomImagePlaceholderPositionAndDimensionNB",
    "getProductMediaAndTitleNB",
    "applyProductImagesToPlaceholderNB",
    // placeholder management
    "saveImagePlaceholderNB",
    //studio NBE
    "studioGetProductsNB",
    "dashboardGetProductSalesChannelIds",
    "studioCartprice",
    "productImagePlaceholdersNB",
    "createProductImagePlaceholderPositionAndDimensionNB",
    "updateBaseProductImagePlaceholderNB",
    "deleteProductImagePlaceholderPositionAndDimensionNB",
    "checkDuplicatePlaceholderTitleNB",
    "salesChannelHasPlaceholderNB",
    "getProductImagePlaceholdersBySalesChannelIdNB",
    "getBaseProductImagesByPlaceholderIdNB",
    "getBaseProductImagesByPlaceholderIdsNB",
    "getProductByIdInformation",
    "getSalesChannelSavedPlaceholderItemsNB",
    "createSalesChannelSavedPlaceholderItemNB",
    "updateSalesChannelSavedPlaceholderItemNB",
    "deleteSalesChannelSavedPlaceholderItemNB",
    // embellishment type NBE
    "createEmbellishmentTypeNB",
    "updateEmbellishmentTypeNB",
    "deleteEmbellishmentTypeNB",
    "deleteEmbellishmentTypesNB",
    "checkDuplicateEmbellishmentTypeTitleNB",
    "getEmbellishmentTypesNB",
    //NBE Contacts
    "getDashboardContactsNB",
    "getDashboardContactNB",
    "createDashboardContactNB",
    "updateDashboardContactNB",
    "getDashboardMicrostoresInContactNB",
    "assignDashboardMicrostoreToContactNB",
    "unassignDashboardMicrostoreFromContactNB",
    // Accounts Sales Rep
    "getDashboardAccountsNB",
    "createDashboardAccountSalesRepNB",
    "getDashboardAccountDetailNB",
    "updateDashboardAccountDetailNB",
    "assignDashboardAccountContactFromAccountNB",
    "unassignDashboardAccountContactFromAccountNB",
    "deleteDashboardAccountNBE",
    "deleteDashboardContactNBE",
    // Users
    "GetDashboardAdminUsersNB",
    //[[SALES_REP_TO_BE REMOVED]]
    "GetDashboardAdminUserNB",
    "getDashboardSalesChannelsNB",
    "assignDashboardContactToTeamStoresNB",
    "getDashboardSalesChannelsProductsOnCatalogSalesChannelsPage",
    "updateDashboardSalesChannelDefaultPaymentConfigNB",
    "getSalesChannelDefaultPaymentConfigNBE",
    "bulkDeleteSalesChannelSavedPlaceholderItemNB",
    "bulkCreateSalesChannelSavedPlaceholderItemNB",
    "bulkUpdateSalesChannelSavedPlaceholderItemNB",
    "dashboardDeleteSalesChannelPlaceholderColorwayMediaNB",
    "dashboardCreateSalesChannelPlaceholderColorwayMediaNB",
    "dashboardBulkCreateSalesChannelPlaceholderColorwayMediaNB",
    "getDashboardOptimizedJobByIdNB",
    "bulkDeleteDashboardCouponCodesNB",
    "bulkUpdateDashboardCouponCodesNB",
    //reporting app
    "getDashboardDocumentTemplates",
    "saveDashboardDocumentTemplate",
    "openDashboardDocumentTemplateEditor",
    "previewDashboardDocumentTemplate",
    "copyDashboardDocumentTemplate",
    "deleteDashboardDocumentTemplate",
    "createDashboardDocument",
    "dashboardCreateBulkProductMediaNB",
    "getDashboardUserPermission",
    //metaFields options
    "getDashboardMetaFieldsNB",
    // Menu builder
    "CreateSalesChannelMenu",
    "SalesChannelMenu", 
]);
