import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { createVocab } from "./createVocab";
var MISSING_ALL_TRANSLATIONS = {
    //todo: replace with actual locale vocab from business team
    "common.total": "Total"
};
var en_US = _object_spread_props(_object_spread({}, MISSING_ALL_TRANSLATIONS), {
    "common.items": "Items",
    "common.continue": "Continue",
    "common.Invalid.inputError": "[fieldName] is invalid",
    "common.requiredField.inputError": "[fieldName] is required",
    "common.requiredField.selectError": "[fieldName] is required",
    "common.shipping": "Shipping",
    "common.termAndConditionError": "Terms and conditions error",
    "common.accept.button": "Accept",
    "common.payNow.button": "Pay Now",
    "common.total": "Total",
    "common.termsAndConditions": "terms and conditions",
    "common.privacyPolicy": "privacy policy",
    "common.invalidFormat.inputError": "Wrong [fieldName] format",
    "common.invalidNumber.inputError": "[fieldName] must be a number"
});
var en_UK = _object_spread_props(_object_spread({}, en_US), {
    "common.items": "Items",
    "common.continue": "Continue",
    "common.Invalid.inputError": "[fieldName] is invalid",
    "common.requiredField.inputError": "[fieldName] is required",
    "common.requiredField.selectError": "[fieldName] is required",
    "common.shipping": "Shipping",
    "common.termAndConditionError": "Terms and conditions error",
    "common.accept.button": "Accept",
    "common.payNow.button": "Pay Now",
    "common.invalidFormat.inputError": "Wrong [fieldName] format"
});
var nl_NL = _object_spread_props(_object_spread({}, en_US), {
    "common.items": "Producten",
    "common.continue": "Doorgaan",
    "common.Invalid.inputError": "[fieldName] is ongeldig",
    "common.requiredField.inputError": "[fieldName] is required",
    "common.requiredField.selectError": "[fieldName] is required",
    "common.shipping": "Verzending",
    "common.termAndConditionError": "Algemene voorwaarden foutmelding",
    "common.accept.button": "Aanvaarden",
    "common.payNow.button": "Betaal nu",
    "common.invalidFormat.inputError": "Verkeerde [fieldName]-indeling",
    "common.invalidNumber.inputError": "[fieldName] moet een getal zijn"
});
var fr_CA = _object_spread_props(_object_spread({}, en_US), {
    "common.items": "Produits",
    "common.continue": "Continuer",
    "common.Invalid.inputError": "[fieldName] n'est pas valide",
    "common.requiredField.inputError": "[fieldName] est requis",
    "common.requiredField.selectError": "[fieldName] est requis",
    "common.shipping": "Livraison",
    "common.termAndConditionError": "Erreur de termes et conditions",
    "common.accept.button": "J'accepte",
    "common.payNow.button": "Je traite mon paiement",
    "common.invalidFormat.inputError": "Format de [fieldName] incorrect",
    "common.invalidNumber.inputError": "[fieldName] doit \xeatre un nombre"
});
var fr_FR = _object_spread_props(_object_spread({}, en_US), {
    "common.items": "Produits",
    "common.continue": "Continuer",
    "common.Invalid.inputError": "[fieldName] n'est pas valide",
    "common.requiredField.inputError": "[fieldName] est requis",
    "common.requiredField.selectError": "[fieldName] est requis",
    "common.shipping": "Livraison",
    "common.termAndConditionError": "Erreur de termes et conditions",
    "common.accept.button": "J'accepte",
    "common.payNow.button": "Je traite mon paiement",
    "common.invalidFormat.inputError": "Format de [fieldName] incorrect",
    "common.invalidNumber.inputError": "[fieldName] doit \xeatre un nombre"
});
var de_DE = _object_spread_props(_object_spread({}, en_US), {
    "common.items": "Produkte",
    "common.continue": "Weiter",
    "common.Invalid.inputError": "[fieldName] ist ung\xfcltig",
    "common.requiredField.inputError": "[fieldName] ist ein Pflichtfeld",
    "common.requiredField.selectError": "[fieldName] ist ein Pflichtfeld",
    "common.shipping": "Lieferung",
    "common.termAndConditionError": "Fehler! Allgemeine Nutzungsbedingungen",
    "common.accept.button": "Annehmen",
    "common.payNow.button": "Jetzt bezahlen",
    "common.invalidFormat.inputError": "Falsches Format f\xfcr [fieldName]",
    "common.invalidNumber.inputError": "[fieldName] muss eine Nummer sein"
});
var en_AU = _object_spread_props(_object_spread({}, en_US), {
    "common.items": "Items",
    "common.continue": "Continue",
    "common.Invalid.inputError": "[fieldName] is invalid",
    "common.requiredField.inputError": "[fieldName] is required",
    "common.requiredField.selectError": "[fieldName] is required",
    "common.shipping": "Shipping",
    "common.termAndConditionError": "Terms and conditions error",
    "common.accept.button": "Accept",
    "common.payNow.button": "Pay Now",
    "common.invalidFormat.inputError": "Wrong [fieldName] format",
    "common.invalidNumber.inputError": "[fieldName] must be a number"
});
var pt_BR = _object_spread_props(_object_spread({}, en_US), {
    "common.items": "Itens",
    "common.continue": "Continuar",
    "common.Invalid.inputError": "[fieldName] \xe9 inv\xe1lido",
    "common.requiredField.inputError": "[fieldName] \xe9 obrigat\xf3rio",
    "common.requiredField.selectError": "[fieldName] \xe9 obrigat\xf3rio",
    "common.shipping": "Envio",
    "common.termAndConditionError": "Erro de termos e condi\xe7\xf5es",
    "common.accept.button": "Aceitar",
    "common.payNow.button": "Pague agora",
    "common.invalidFormat.inputError": "Formato [fieldName] incorreto",
    "common.invalidNumber.inputError": "[fieldName] deve ser um n\xfamero"
});
var pt_PT = _object_spread_props(_object_spread({}, en_US), {
    "common.items": "Itens",
    "common.continue": "Continuar",
    "common.Invalid.inputError": "[fieldName] \xe9 inv\xe1lido",
    "common.requiredField.inputError": "[fieldName] \xe9 obrigat\xf3rio",
    "common.requiredField.selectError": "[fieldName] \xe9 obrigat\xf3rio",
    "common.shipping": "Envio",
    "common.termAndConditionError": "Erro de termos e condi\xe7\xf5es",
    "common.accept.button": "Aceitar",
    "common.payNow.button": "Pague agora",
    "common.invalidFormat.inputError": "Formato [fieldName] incorreto",
    "common.invalidNumber.inputError": "[fieldName] deve ser um n\xfamero"
});
var lv_LV = _object_spread_props(_object_spread({}, en_US), {
    "common.items": "Preces",
    "common.continue": "Turpināt",
    "common.Invalid.inputError": "[laukaNosaukums] ir nederīgs",
    "common.requiredField.inputError": "[laukaNosaukums] ir nepieciešams",
    "common.requiredField.selectError": "[laukaNosaukums] ir nepieciešams ",
    "common.shipping": "Piegādes",
    "common.termAndConditionError": "Notiekumu un nosacījumu kļūda",
    "common.accept.button": "Piekrītu",
    "common.payNow.button": "Maksāt tagad",
    "common.invalidFormat.inputError": "Nepareizs [laukaNosaukums] formāts",
    "common.invalidNumber.inputError": "[laukaNosaukums] jābūt ciparam "
});
var es_ES = _object_spread_props(_object_spread({}, en_US), {
    "common.items": "Productos",
    "common.continue": "Continuar",
    "common.Invalid.inputError": "[fieldName] no es v\xe1lida",
    "common.requiredField.inputError": "[Nombre del campo] es obligatorio",
    "common.requiredField.selectError": "[Nombre del campo] es obligatorio",
    "common.shipping": "Env\xedo",
    "common.termAndConditionError": "Error de t\xe9rminos y condiciones",
    "common.accept.button": "Aceptar",
    "common.payNow.button": "Pagar ahora",
    "common.invalidFormat.inputError": "Formato de [fieldName] incorrecto",
    "common.invalidNumber.inputError": "[fieldName] debe ser un n\xfamero"
});
var th_TH = _object_spread_props(_object_spread({}, en_US), {
    "common.items": "รายการสินค้า",
    "common.continue": "ดำเนินการต่อ",
    "common.Invalid.inputError": "[fieldName] ข้อมูลไม่ถูกต้อง",
    "common.requiredField.inputError": "[fieldName] กรุณากรอกข้อมูล",
    "common.requiredField.selectError": "[fieldName] กรุณากรอกข้อมูล",
    "common.shipping": "การจัดส่ง",
    "common.termAndConditionError": "ข้อกำหนดและเงื่อนไขผิดพลาด",
    "common.accept.button": "ยอมรับ",
    "common.payNow.button": "ชำระเงินตอนนี้",
    "common.invalidFormat.inputError": "รูปแบบ [fieldName] ไม่ถูกต้อง",
    "common.invalidNumber.inputError": "[fieldName] ต้องเป็นหมายเลขเท่านั้น"
});
var es_MX = _object_spread_props(_object_spread({}, en_US), {
    "common.items": "Productos",
    "common.continue": "Continuar",
    "common.Invalid.inputError": "[fieldName] no es v\xe1lida",
    "common.requiredField.inputError": "[Nombre del campo] es obligatorio",
    "common.requiredField.selectError": "[Nombre del campo] es obligatorio",
    "common.shipping": "Env\xedo",
    "common.termAndConditionError": "Error de t\xe9rminos y condiciones",
    "common.accept.button": "Aceptar",
    "common.payNow.button": "Pagar ahora",
    "common.invalidFormat.inputError": "Formato de [fieldName] incorrecto",
    "common.invalidNumber.inputError": "[fieldName] debe ser un n\xfamero"
});
export default createVocab({
    en_UK: en_UK,
    en_US: en_US,
    es_MX: es_MX,
    en_AU: en_AU,
    de_DE: de_DE,
    nl_NL: nl_NL,
    fr_FR: fr_FR,
    pt_BR: pt_BR,
    pt_PT: pt_PT,
    fr_CA: fr_CA,
    lv_LV: lv_LV,
    th_TH: th_TH,
    es_ES: es_ES
}, en_US);
