import * as Sentry from "@sentry/browser";
import isEmpty from "lodash/isEmpty";
export function defaultApolloLinkErrorHandler(errorResponse) {
    var ref, ref1, ref2;
    var graphQLErrors = errorResponse.graphQLErrors, networkError = errorResponse.networkError, operation = errorResponse.operation;
    var isDevEnv = (process === null || process === void 0 ? void 0 : (ref = process.env) === null || ref === void 0 ? void 0 : ref.NODE_ENV) === "development";
    var operationName = isDevEnv ? " ".concat(operation.operationName) : "";
    if (((ref1 = operation.operationName) === null || ref1 === void 0 ? void 0 : ref1.includes("GetShop")) || ((ref2 = operation.operationName) === null || ref2 === void 0 ? void 0 : ref2.includes("GetCommunicationPanel"))) {
        return;
    }
    try {
        if (!isEmpty(graphQLErrors)) {
            (graphQLErrors !== null && graphQLErrors !== void 0 ? graphQLErrors : []).map(function(param) {
                var message = param.message, locations = param.locations, path = param.path;
                var msgGraphQLError = "[GraphQL error".concat(operationName, "]: Message: ").concat(message, ", Location: ").concat(locations, ", Path: ").concat(path);
                defaultErrorLogger(msgGraphQLError, isDevEnv, operationName);
            });
        } else if (networkError) {
            var msgNetworkError = "[Network error ".concat(operationName, "]: ").concat(networkError);
            defaultErrorLogger(msgNetworkError, isDevEnv, operationName);
        }
    } catch (error) {
        var msgError = "[onError error ".concat(operationName, "]: ").concat(error);
        defaultErrorLogger(msgError, isDevEnv, operationName);
    }
}
function defaultErrorLogger(msgError, isDevEnv, operationName) {
    if ("object" !== "undefined") console.error(msgError);
    captureSentryError(msgError, {
        operationName: operationName
    });
}
function captureSentryError(message, tags) {
    if (Sentry) {
        if (tags) {
            Sentry.configureScope(function(scope) {
                Object.keys(tags).forEach(function(key) {
                    scope.setTag(key, tags[key]);
                });
            });
        }
        Sentry.captureException(message);
    }
}
