import { routes } from "../constants/routes";
var storeDesignRoutes = routes.dashboard.children.storeDesign.children;
export var getContentListPath = storeDesignRoutes.contents.path;
export var getContentEditorPath = storeDesignRoutes.contents.children.editor.path;
export var getContentSettingsPath = storeDesignRoutes.contents.children.settings.path;
export var getThemeListPath = storeDesignRoutes.themes.path;
export var getThemeEditorPath = storeDesignRoutes.themes.children.editor.path;
export var getStoreMenuListPath = storeDesignRoutes.menus.path;
export var getStoreMenuCreatePath = storeDesignRoutes.menus.children.create.path;
export var getStoreMenuEditPath = storeDesignRoutes.menus.children.edit.path;
