import moment from "moment";
export var convertTS = function(ts) {
    if (!ts || ts <= 0) return "";
    var dateTimeFormat = "DD MMM YYYY HH:mm:ss (Z)";
    if (typeof ts === "string") {
        // return moment(ts).local().format(dateTimeFormat)
        var date = moment(ts).format("DD MMM YYYY HH:mm:ss");
        var stillUtc = moment.utc(date).toDate();
        return moment(stillUtc).format(dateTimeFormat);
    } else {
        var unixReformatted = Number(ts.toString().slice(0, 10));
        return moment.unix(unixReformatted).format(dateTimeFormat);
    }
};
