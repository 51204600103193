var result = {
    "possibleTypes": {
        "IMetaFieldConfiguration": [
            "MetaFieldConfiguration",
            "MetaFieldOptionConfiguration"
        ],
        "LogData": [
            "BriklOnlySimpleMessageLogData",
            "EmailNotificationLogData",
            "FieldUpdatedLogData",
            "S3KeyPathLogData",
            "SimpleMessageLogData"
        ],
        "MenuItem": [
            "MenuItemModule",
            "MenuItemLink"
        ],
        "PaymentProviderConfigAdditionalInfo": [
            "OmiseInfo",
            "PayGateInfo",
            "StripeConnectInfo"
        ],
        "UserVisibleError": [
            "BulkUpdateSalesChannelProductsPriceModifierError"
        ]
    }
};
export default result;
