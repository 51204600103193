import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { createHttpLink } from "@apollo/client";
import { getApolloStudioHeaders } from "@brikl/apollo";
import { GRAPHQL_API_URI, HASURA_API_URI_HTTP, HASURA_BACKEND_V2_API_URI_HTTP } from "../constants/endpoints";
var HTTP_LINK_COMMON_COFIGS = {
    fetch: fetch,
    credentials: "same-origin"
};
export var legacyHttpLink = createHttpLink(_object_spread_props(_object_spread({}, HTTP_LINK_COMMON_COFIGS), {
    uri: GRAPHQL_API_URI
}));
export var legacyHttpHasuraLink = createHttpLink(_object_spread_props(_object_spread({}, HTTP_LINK_COMMON_COFIGS), {
    uri: HASURA_API_URI_HTTP
}));
export var getHttpLink = function(env) {
    return createHttpLink(_object_spread_props(_object_spread({}, HTTP_LINK_COMMON_COFIGS), {
        uri: HASURA_BACKEND_V2_API_URI_HTTP,
        headers: getApolloStudioHeaders({
            app: "dashboard",
            env: env
        })
    }));
};
