import React from 'react'

import {
  Box,
  Link as DesignCoreLink,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@brikl/design-core'
import { BriklButton, BriklCheckbox } from '@brikl/design-system'

import { useTermsOfService } from './hooks/useTermsOfService'

export const TermsOfService = () => {
  const {
    onClickContinue,
    onChangeAccept,
    isAccected,
    isModalOpen,
    isLoading,
  } = useTermsOfService()

  const termsOfServiceURL = 'https://www.brikl.com/terms-of-service'

  return (
    <Modal
      size="lg"
      motionPreset="slideInBottom"
      onClose={null}
      isOpen={isModalOpen}
    >
      <ModalOverlay />
      <ModalContent pb={5}>
        <ModalBody>
          {' '}
          <Heading my="5" fontSize="17px">
            Terms Of Service Acknowledgement
          </Heading>
          <Text mr="2">Welcome to BRIKL!</Text>
          <Flex alignItems="center">
            <Text mr="1">Please carefully read</Text>
            <a href={termsOfServiceURL} target="#">
              <DesignCoreLink color="brand.500" textDecor="underline">
                Brikl Terms of Service
              </DesignCoreLink>
            </a>
          </Flex>
          <Box my="4">
            <BriklCheckbox
              data-cy="accept-term-of-service-checkbox"
              isDisabled={isLoading}
              isChecked={isAccected}
              onChange={onChangeAccept}
              label="I have read and agree to the “Terms of Service“"
            />
          </Box>
        </ModalBody>
        <ModalFooter bgColor="gray.50" pb="0">
          <BriklButton
            data-cy="accept-term-of-service-button"
            isLoading={isLoading}
            isDisabled={!isAccected || isLoading}
            onClick={onClickContinue}
          >
            Accept
          </BriklButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
