export var languageNameMap = {
    en_US: "English (US)",
    en_UK: "English (UK)",
    en_CA: "English (Canada)",
    en_AU: "English (Australia)",
    de_DE: "German",
    nl_NL: "Dutch",
    fr_FR: "French",
    fr_CA: "French (Canada)",
    th_TH: "Thai",
    zh_CN: "Chinese",
    ja_JP: "Japan",
    lv_LV: "Latvian",
    es_ES: "Spanish",
    es_MX: "Spanish (MX)",
    no_NO: "Norwegian",
    pt_BR: "Portugese (BR)",
    pt_PT: "Portugese (PT)",
    fi_FI: "Finnish"
};
