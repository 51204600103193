import { GRAPHQL_URI_DEV, GRAPHQL_URI_LOCAL, GRAPHQL_URI_PRODUCTION, GRAPHQL_URI_STAGING, HASURA_BACKEND_V2_URI_DEV, HASURA_BACKEND_V2_URI_PRODUCTION, HASURA_BACKEND_V2_URI_STAGING, HASURA_URI_DEV, HASURA_URI_PRODUCTION, HASURA_URI_STAGING } from "./endpoints";
export var getGraphQLEndpointURI = function(env) {
    switch(env){
        case "production":
            return GRAPHQL_URI_PRODUCTION;
        case "staging":
            return GRAPHQL_URI_STAGING;
        case "dev":
            return GRAPHQL_URI_DEV;
        default:
            return GRAPHQL_URI_LOCAL;
    }
};
export var getHasuraBackendV2EndpointURI = function(env) {
    var protocol = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "ws";
    switch(env){
        case "production":
            return protocol + "s://" + HASURA_BACKEND_V2_URI_PRODUCTION;
        case "staging":
            return protocol + "s://" + HASURA_BACKEND_V2_URI_STAGING;
        case "dev":
            return protocol + "s://" + HASURA_BACKEND_V2_URI_DEV;
        default:
            return protocol + "s://" + HASURA_BACKEND_V2_URI_DEV;
    }
};
export var getHasuraEndpointURI = function(env) {
    var protocol = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "ws";
    switch(env){
        case "production":
            return protocol + "s://" + HASURA_URI_PRODUCTION;
        case "staging":
            return protocol + "s://" + HASURA_URI_STAGING;
        case "dev":
            return protocol + "s://" + HASURA_URI_DEV;
        default:
            return protocol + "s://" + HASURA_URI_DEV;
    }
};
