import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { extendTheme } from "@chakra-ui/react";
import { getShade } from "../functions/getShade";
import { bgColors } from "./designColors/bg";
import { borderColors } from "./designColors/border";
import { brandColors } from "./designColors/brand";
import { contentColors } from "./designColors/content";
import { brandShade } from "./shades/brandShade";
import { grayShade } from "./shades/grayShade";
import { greenShade } from "./shades/greenShade";
import { redShade } from "./shades/redShade";
import { yellowShade } from "./shades/yellowShade";
import { space } from "./space";
export var SpaceConfig;
(function(SpaceConfig) {
    SpaceConfig[SpaceConfig["xxxsmall"] = 1] = "xxxsmall";
    SpaceConfig[SpaceConfig["xxsmall"] = 2] = "xxsmall";
    SpaceConfig[SpaceConfig["xsmall"] = 4] = "xsmall";
    SpaceConfig[SpaceConfig["small"] = 8] = "small";
    SpaceConfig[SpaceConfig["medium"] = 12] = "medium";
    SpaceConfig[SpaceConfig["large"] = 16] = "large";
    SpaceConfig[SpaceConfig["xlarge"] = 24] = "xlarge";
    SpaceConfig[SpaceConfig["xxlarge"] = 32] = "xxlarge";
})(SpaceConfig || (SpaceConfig = {}));
var dash = extendTheme({
    config: {
        initialColorMode: "light",
        useSystemColorMode: false
    },
    styles: {
        global: {
            html: {
                fontSize: "16px"
            },
            body: {
                display: "block"
            },
            ".chakra-collapse": {
                overflow: "initial !important"
            }
        }
    },
    space: space,
    fonts: {
        body: "Poppins, sans-serif",
        heading: "Poppins, sans-serif",
        mono: "Poppins, monospace"
    },
    fontSizes: {
        "xx-small": "0.625rem",
        xs: "0.75rem",
        sm: "0.875rem",
        md: "1rem",
        lg: "1.125rem",
        xl: "1.5rem",
        xxl: "2rem"
    },
    fontWeights: {
        normal: 400,
        semibold: 450,
        medium: 500,
        bold: 700
    },
    lineHeights: {
        normal: "normal",
        none: "1",
        shorter: "1.25",
        short: "1.375",
        base: "1.5",
        tall: "1.625",
        taller: "2"
    },
    letterSpacings: {
        tighter: "-0.05em",
        tight: "-0.025em",
        normal: "0",
        wide: "0.025em",
        wider: "0.05em",
        widest: "0.1em"
    },
    colors: {
        brand: _object_spread({}, brandShade, brandColors),
        twitterCustom: getShade("#1ca1f2"),
        lightGray: "#fafafa",
        gray: grayShade,
        grayScale: grayShade,
        green: greenShade,
        red: redShade,
        yellow: yellowShade,
        bg: bgColors,
        background: bgColors,
        border: borderColors,
        content: contentColors
    },
    components: {
        Text: {
            baseStyle: {
                fontSize: "md",
                fontWeight: "normal"
            }
        },
        Heading: {
            baseStyle: {
                color: "gray.900"
            }
        },
        FormLabel: {
            baseStyle: {
                color: "gray.700",
                fontWeight: "normal"
            }
        },
        FormHelperText: {
            baseStyle: {
                color: "gray.700",
                fontSize: "sm",
                fontWeight: "normal"
            },
            variants: {
                warning: {
                    color: "yellow.500",
                    fontSize: "sm",
                    fontWeight: "normal"
                },
                error: {
                    color: "red.500",
                    fontSize: "sm",
                    fontWeight: "normal"
                }
            }
        }
    }
});
export default dash;
