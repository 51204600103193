var branchName = process.env.HEAD;
var prNumber = process.env.REVIEW_ID;
var commitHash = process.env.COMMIT_HASH;
export var getApolloStudioHeaders = function(param) {
    var app = param.app, env = param.env, customVersion = param.customVersion;
    var isLocal = !branchName && !prNumber && !commitHash;
    var version = customVersion !== null && customVersion !== void 0 ? customVersion : "".concat(branchName !== null && branchName !== void 0 ? branchName : "UnknownBranch", "_").concat(prNumber !== null && prNumber !== void 0 ? prNumber : "UnknownPR", "_").concat(commitHash !== null && commitHash !== void 0 ? commitHash : "UnknownCommit");
    return {
        "apollographql-client-name": app,
        "apollographql-client-version": "".concat(isLocal ? "local_" : "").concat(env, "_").concat(!isLocal ? version : "")
    };
};
