import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { reportError } from "@brikl/shared-utils";
export var DEFAULT_HEADERS = {
    headers: {}
};
export function createAuthorizationHeader(_) {
    return _createAuthorizationHeader.apply(this, arguments);
}
function _createAuthorizationHeader() {
    _createAuthorizationHeader = _async_to_generator(function(param) {
        var shopId, token, ref, accessToken, _tmp, _tmp1, error, _tmp2;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    shopId = param.shopId, token = param.token;
                    _state.label = 1;
                case 1:
                    _state.trys.push([
                        1,
                        5,
                        ,
                        6
                    ]);
                    if (!(typeof token === "string")) return [
                        3,
                        2
                    ];
                    _tmp = token;
                    return [
                        3,
                        4
                    ];
                case 2:
                    return [
                        4,
                        token === null || token === void 0 ? void 0 : token()
                    ];
                case 3:
                    _tmp = (ref = _state.sent()) === null || ref === void 0 ? void 0 : ref.__raw;
                    _state.label = 4;
                case 4:
                    accessToken = _tmp;
                    _tmp1 = {};
                    return [
                        2,
                        (_tmp1.headers = {
                            authorization: accessToken ? "Bearer ".concat(accessToken) : undefined,
                            "x-brikl-shop-id": shopId
                        }, _tmp1)
                    ];
                case 5:
                    error = _state.sent();
                    _tmp2 = {};
                    reportError(error, (_tmp2.module = "AuthLink", _tmp2.action = "createAuthorizationHeader", _tmp2));
                    return [
                        2,
                        DEFAULT_HEADERS
                    ];
                case 6:
                    return [
                        2
                    ];
            }
        });
    });
    return _createAuthorizationHeader.apply(this, arguments);
}
