import isEmpty from "lodash/isEmpty";
import SUPPORTED_LANGUAGES from "./supported-languages";
var addDefaultVocab = function(totalVocab, missingVocabLanguages, defaultVocab) {
    return missingVocabLanguages.reduce(function(accVocab, language) {
        accVocab[language] = defaultVocab;
        return accVocab;
    }, totalVocab);
};
export function createVocab(vocab, defaultVocab) {
    var inputLanguagesList = Object.keys(vocab);
    var missingVocabLanguages = SUPPORTED_LANGUAGES.filter(function(language) {
        return !inputLanguagesList.includes(language);
    });
    if (isEmpty(missingVocabLanguages)) return vocab;
    return addDefaultVocab(vocab, missingVocabLanguages, defaultVocab);
}
