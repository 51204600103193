import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { createVocab } from "./createVocab";
var MISSING_ALL_TRANSLATIONS = {
    //todo: replace with actual locale vocab from business team
    "ecom.storeSide.signinLoggedIn.emailInvalid.inputError": "Email is invalid",
    "ecom.storeSide.signinLoggedIn.emailRequired.inputError": "Email is required",
    "ecom.storeSide.signinLoggedIn.passwordRequired.inputError": "Password is required",
    "ecom.storeSide.paySum.cartItemTotalExclTax.title": "Items Total Excl. Tax ([items] items)",
    "ecom.storeSide.paySum.cartItemTotal.title": "Items Total ([items] items)",
    "ecom.storeSide.paySum.cartTotalExclTaxNoShipping.title": "Total without shipping",
    "ecom.storeSide.review.back.link": "Return to Payment",
    "ecom.storeSide.review.termAndConditionLink.checkBoxLabel": "I agree with the [terms and conditions] and the [privacy policy] of Brand"
};
var en_US = _object_spread_props(_object_spread({}, MISSING_ALL_TRANSLATIONS), {
    "ecom.storeSide.signinLoggedIn.loginSuccess.toast": "Logout successful",
    "ecom.storeSide.signinLoggedIn.loginFailed.toast": "Logout failed",
    "ecom.storeSide.signinLoggedIn.guest.pageTitle": "GUEST",
    "ecom.storeSide.orderSum.sectionTitle": "Open order summary",
    "ecom.storeSide.orderSum.outlineCloseButton": "Close order summary",
    "ecom.productCard.sectionTitle": "ITEM SUMMARY",
    "ecom.storeSide.productDetails.basePrice.title": "Base Price",
    "ecom.storeSide.productDetails.unitPrice.title": "Unit Price",
    "ecom.storeSide.duplicatedCoupon.toast": "Oops! Duplicated coupon",
    "ecom.storeSide.shipping.pageDescription": "Shipping page description",
    "ecom.storeSide.review.termAndCondition.checkBoxError": "Terms and conditions error",
    "ecom.storeSide.addressForm.shipAddress.sectionTitle": "Shipping Address",
    "ecom.storeSide.addressForm.billAddress.sectionTitle": "Billing Address",
    "ecom.storeSide.addressForm.deleteAddress.modalTitle": "You are deleting an address",
    "ecom.storeSide.addressForm.deleteAddress.modalDescription": "This will delete an address from your address book",
    "ecom.checkout.shippingMethodNotAvailable.errorAlert": "Our service is not available in your country, please change/edit the shipping address below.",
    "ecom.checkout.shippingMethodSelectedCountry.warningAlert": "Please select destination country to see available shipping methods",
    "ecom.storeSide.payment.payMethodNotAdded.warningAlert": "Payment method has not been added yet. Please configure this in payment settings.",
    "ecom.storeSide.payment.paymentFailed.toast": "Payment Failed",
    "ecom.storeSide.payment.paymentSuccess.toast": "Payment Successful",
    "ecom.storeSide.payment.pendingPayment.title": "Payment Pending",
    "ecom.storeSide.payment.IdealPaymentWarning": "Please select bank",
    "ecom.checkout.invalidAddress.toast": "The address is invalid. Please update state, city or zip code for a tax calculation.",
    "ecom.storeSide.shoppingCart.sectionTitle": "YOUR CART",
    "ecom.storeSide.productCard.detail.ghostButton": "Details",
    "ecom.storeSide.productCard.itemUnavailable.inlineAlert": "This item is unavailable; please remove it.",
    "ecom.storeSide.productCard.variantUnavailable.inlineAlert": "A selected variant is unavailable; please edit your selection.",
    "ecom.storeSide.productCard.variantNotSelected.inlineAlert": "Please select variants",
    "ecom.storeSide.paySum.sectionTitle": "PAYMENT SUMMARY",
    "ecom.storeSide.coupon.inputLabel": "Coupon",
    "ecom.storeSide.coupon.inputPlaceholder": "Enter coupon code",
    "ecom.storeSide.coupon.secondaryButton": "Apply",
    "ecom.storeSide.paySum.cartTotal.paragraph": "Cart Total",
    "ecom.storeSide.paySum.coupon.accordionTitle": "Coupon code",
    "ecom.storeSide.paySum.promotion.accordionTitle": "Promotion",
    "ecom.storeSide.paySum.cartTotalExclTax.title": "Total Excl. Tax",
    "ecom.storeSide.paySum.cartTotalExclTaxNoShipping.description": "(Tax & shipping calculated at checkout)",
    "ecom.storeSide.paySum.cartSubtotalInclTax.title": "Sub-total (Incl. Tax)",
    "ecom.storeSide.paySum.cartTotalInclTax.title": "Total Incl. Tax",
    "ecom.storeSide.paySum.cartTotalInclTaxNoShipping.description": "tax included. Shipping calculated at checkout",
    "ecom.storeSide.refundPolicy.footerLink": "Refund Policy",
    "ecom.storeSide.privatePolicy.footerLink": "Privacy Policy",
    "ecom.storeSide.termAndCondition.footerLink": "Terms & Conditions",
    "ecom.storeSide.backToStorefront.link": "Continue shopping",
    "ecom.storeSide.goToCheckout.primaryButton": "Checkout",
    "ecom.storeSide.goToCheckout.securedPay.description": "Secure payments by [Payment Gateway]",
    "ecom.storeSide.couponExpired.alert": "Please delete expired coupon code",
    "ecom.storeSide.couponExpired.inlineAlert": "Expired coupon code",
    "ecom.storeSide.couponApplied.toast": "Coupon applied. Total price updated.",
    "ecom.storeSide.couponDeleted.toast": "Coupon deleted. Total price updated. ",
    "ecom.storeSide.invalidCoupon.inputError": "Invalid coupon code.",
    "ecom.storeSide.productDetails.size.sectionTitle": "Size",
    "ecom.storeSide.productDetails.color.sectionTitle": "Color",
    "ecom.storeSide.productDetails.productPrice.sectionTitle": "Price Details",
    "ecom.storeSide.productDetails.productPrice.accordionTitle": "Product Price",
    "ecom.storeSide.productDetails.personalization.accordionTitle": "Personalization",
    "ecom.storeSide.deleteItem.confirmationModal": "Delete selected item from cart?",
    "ecom.storeSide.signinLoggedOut.pageTitle1": "CHECKOUT",
    "ecom.storeSide.signinLoggedOut.asGuest.title2": "Continue as Guest",
    "ecom.storeSide.signinLoggedOut.email.inputLabel": "Email",
    "ecom.storeSide.signinLoggedOut.email.inputPlaceholder": "Enter an email",
    "ecom.storeSide.signinLoggedOut.email.inputHelper": "We only use this to send you the receipt and order confirmation",
    "ecom.storeSide.signinLoggedOut.asGuest.primaryButton": "Continue as Guest",
    "ecom.storeSide.signinLoggedOut.asMember.title2": "Sign In for Faster Checkout",
    "ecom.storeSide.signinLoggedOut.password.inputLabel": "Password",
    "ecom.storeSide.signinLoggedOut.password.inputPlaceholder": "Enter a password",
    "ecom.storeSide.signinLoggedOut.forgetPassword.link": "Forget your password?",
    "ecom.storeSide.signinLoggedOut.asMember.primaryButton": "Sign in",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaText": "New member? ",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaLink": "Create an Account here",
    "ecom.storeSide.signinLoggedOut.back.link": "Return to cart",
    "ecom.storeSide.signinLoggedIn.member.pageTitle": "MEMBER",
    "ecom.storeSide.signinLoggedIn.continueAsMember.title2": "Continue as",
    "ecom.storeSide.signinLoggedIn.nextStep.primaryButton": "Continue ",
    "ecom.storeSide.signinLoggedIn.signOut.link": "(Not You?)",
    "ecom.storeSide.shipping.pageTitle": "SHIPPING",
    "ecom.storeSide.shipping.shipMethod.sectionTitle": "Shipping Method",
    "ecom.storeSide.shipping.shipDescription.sectionTitle": "Shipping Description",
    "ecom.storeSide.shipping.nextToPayment.primaryButton": "Next to Payment",
    "ecom.storeSide.shipping.back.link": "Return to Sign-in",
    "ecom.storeSide.payment.pageTitle": "PAYMENT",
    "ecom.storeSide.payment.payMethod.sectionTitle": "Payment Method",
    "ecom.storeSide.payment.cardDetails.sectionTitle": "Credit Card Details",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputLabel": "Card Number:",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputPlaceholder": "Enter card number",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputError": "This field is required",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputErrorValid": "Card number is invalid",
    "ecom.storeSide.payment.cardDetails.expiry.inputLabel": "Expiry:",
    "ecom.storeSide.payment.cardDetails.expiry.inputPlaceholder": "MM / YY",
    "ecom.storeSide.payment.cardDetails.expiry.inputError": "This field is required",
    "ecom.storeSide.payment.cardDetails.cvc.inputLabel": "CVC:",
    "ecom.storeSide.payment.cardDetails.cvc.inputPlaceholder": "3 digits",
    "ecom.storeSide.payment.cardDetails.cvc.inputError": "This field is required",
    "ecom.storeSide.payment.nextToReview.primaryButton": "Next to Review",
    "ecom.storeSide.payment.back.link": "Return to Shipping",
    "ecom.storeSide.review.pageTitle": "REVIEW",
    "ecom.storeSide.review.contactInfo.guideText": "Contact Information:",
    "ecom.storeSide.review.shipping.guideText": "Shipping:",
    "ecom.storeSide.review.payment.guideText": "Payment:",
    "ecom.storeSide.review.editDetails.link": "Edit",
    "ecom.storeSide.review.termAndCondition.checkBoxLabel": "I agree with the terms and conditions and the privacy policy of Brand",
    "ecom.storeSide.addressForm.firstName.inputLabel": "First Name",
    "ecom.storeSide.addressForm.firstName.inputPlaceholder": "Enter first name",
    "ecom.storeSide.addressForm.lastName.inputLabel": "Last Name",
    "ecom.storeSide.addressForm.lastName.inputPlaceholder": "Enter last name",
    "ecom.storeSide.addressForm.phone.inputLabel": "Contact Number (Optional)",
    "ecom.storeSide.addressForm.phone.inputPlaceholder": "Enter phone no.",
    "ecom.storeSide.addressForm.address1.inputLabel": "Address Line 1",
    "ecom.storeSide.addressForm.address1.inputPlaceholder": "Building number & street name",
    "ecom.storeSide.addressForm.countryCode.inputLabel": "Country",
    "ecom.storeSide.addressForm.countryCode.inputPlaceholder": "Please select",
    "ecom.storeSide.addressForm.provinceCode.inputLabel": "Province/State",
    "ecom.storeSide.addressForm.provinceCode.inputPlaceholder": "Please select",
    "ecom.storeSide.addressForm.city.inputLabel": "City",
    "ecom.storeSide.addressForm.city.inputPlaceholder": "Please select",
    "ecom.storeSide.addressForm.zip.inputLabel": "Zip Code",
    "ecom.storeSide.addressForm.zip.inputPlaceholder": "Enter zip code",
    "ecom.storeSide.addressForm.shipAddress.accordionTitle": "Select shipping address",
    "ecom.storeSide.addressForm.addNewAddress.secondaryButton": "Add new address",
    "ecom.storeSide.addressForm.editAddress.link": "Edit",
    "ecom.storeSide.addressForm.cancelAddress.ghostButton": "Cancel",
    "ecom.storeSide.addressForm.saveAddress.secondaryButton": "Save",
    "ecom.storeSide.addressForm.deleteAddress.ghostButton": "Delete ",
    "ecom.storeSide.addressForm.sameAsShipping.checkBoxLabel": "Same as shipping address",
    "ecom.storeSide.addressForm.billAddress.accordionTitle": "Select billing address",
    "ecom.storeSide.addressForm.requestInvoice.checkBoxLabel": "Request tax invoice",
    "ecom.storeSide.addressForm.company.inputLabel": "Company Name",
    "ecom.storeSide.addressForm.company.inputPlaceholder": "Enter company name",
    "ecom.storeSide.addressForm.vatNumber.inputLabel": "Tax ID",
    "ecom.storeSide.addressForm.vatNumber.inputPlaceholder": "Enter tax ID",
    "ecom.storeSide.addressForm.requiredField.inlineError": "This field is required",
    "ecom.checkout.shippingMethodUpdated.modalBody": "Your preferred shipping method is unavailable in [country]; please select another. ",
    "ecom.checkout.deleteAddress.modalTitle": "Delete default billing address?",
    "ecom.checkout.shippingMethodUpdated.message": "Shipping method has been updated as the address was changed",
    "ecom.checkout.addAddress.toast": "Address added",
    "ecom.checkout.deleteAddress.toast": "Address deleted"
});
var en_UK = _object_spread_props(_object_spread({}, en_US), {
    "ecom.storeSide.signinLoggedIn.loginSuccess.toast": "Logout successful",
    "ecom.storeSide.signinLoggedIn.loginFailed.toast": "Logout failed",
    "ecom.storeSide.signinLoggedIn.guest.pageTitle": "GUEST",
    "ecom.storeSide.orderSum.sectionTitle": "Open order summary",
    "ecom.storeSide.orderSum.outlineCloseButton": "Close order summary",
    "ecom.productCard.sectionTitle": "ITEM SUMMARY",
    "ecom.storeSide.productDetails.basePrice.title": "Base Price",
    "ecom.storeSide.productDetails.unitPrice.title": "Unit Price",
    "ecom.storeSide.duplicatedCoupon.toast": "Oops! Duplicated coupon",
    "ecom.storeSide.shipping.pageDescription": "Shipping page description",
    "ecom.storeSide.review.termAndCondition.checkBoxError": "Terms and conditions error",
    "ecom.storeSide.addressForm.shipAddress.sectionTitle": "Shipping Address",
    "ecom.storeSide.addressForm.billAddress.sectionTitle": "Billing Address",
    "ecom.storeSide.addressForm.deleteAddress.modalTitle": "You are deleting an address",
    "ecom.storeSide.addressForm.deleteAddress.modalDescription": "This will delete an address from your address book",
    "ecom.checkout.shippingMethodNotAvailable.errorAlert": "Our service is not available in your country, please change/edit the shipping address below.",
    "ecom.checkout.shippingMethodSelectedCountry.warningAlert": "Please select destination country to see available shipping methods",
    "ecom.storeSide.payment.payMethodNotAdded.warningAlert": "Payment method has not been added yet. Please configure this in payment settings.",
    "ecom.storeSide.payment.paymentFailed.toast": "Payment Failed",
    "ecom.storeSide.payment.paymentSuccess.toast": "Payment Successful",
    "ecom.storeSide.payment.pendingPayment.title": "Payment Pending",
    "ecom.storeSide.payment.IdealPaymentWarning": "Please select bank",
    "ecom.checkout.invalidAddress.toast": "The address is invalid. Please update state, city or zip code for a tax calculation.",
    "ecom.storeSide.shoppingCart.sectionTitle": "YOUR BASKET",
    "ecom.storeSide.productCard.detail.ghostButton": "Details",
    "ecom.storeSide.productCard.itemUnavailable.inlineAlert": "This item is unavailable; please remove it.",
    "ecom.storeSide.productCard.variantUnavailable.inlineAlert": "A selected variant is unavailable; please edit your selection.",
    "ecom.storeSide.productCard.variantNotSelected.inlineAlert": "Please select variants",
    "ecom.storeSide.paySum.sectionTitle": "PAYMENT SUMMARY",
    "ecom.storeSide.coupon.inputLabel": "Voucher",
    "ecom.storeSide.coupon.inputPlaceholder": "Enter voucher code",
    "ecom.storeSide.coupon.secondaryButton": "Apply",
    "ecom.storeSide.paySum.cartTotal.paragraph": "Basket Total",
    "ecom.storeSide.paySum.coupon.accordionTitle": "Voucher code",
    "ecom.storeSide.paySum.promotion.accordionTitle": "Promotion",
    "ecom.storeSide.paySum.cartTotalExclTax.title": "Total Excl. Tax",
    "ecom.storeSide.paySum.cartTotalExclTaxNoShipping.description": "(Tax & shipping calculated at checkout)",
    "ecom.storeSide.paySum.cartTotalInclTax.title": "Total Incl. Tax",
    "ecom.storeSide.paySum.cartTotalInclTaxNoShipping.description": "tax included. Shipping calculated at checkout",
    "ecom.storeSide.refundPolicy.footerLink": "Refund Policy",
    "ecom.storeSide.privatePolicy.footerLink": "Privacy Policy",
    "ecom.storeSide.termAndCondition.footerLink": "Terms & Conditions",
    "ecom.storeSide.backToStorefront.link": "Continue shopping",
    "ecom.storeSide.goToCheckout.primaryButton": "Checkout",
    "ecom.storeSide.goToCheckout.securedPay.description": "Secure payments by [Payment Gateway]",
    "ecom.storeSide.couponExpired.alert": "Please delete expired voucher code",
    "ecom.storeSide.couponExpired.inlineAlert": "Expired voucher code",
    "ecom.storeSide.couponApplied.toast": "Voucher applied. Total price updated.",
    "ecom.storeSide.couponDeleted.toast": "Voucher deleted. Total price updated. ",
    "ecom.storeSide.invalidCoupon.inputError": "Invalid voucher code.",
    "ecom.storeSide.productDetails.size.sectionTitle": "Size",
    "ecom.storeSide.productDetails.color.sectionTitle": "Colour",
    "ecom.storeSide.productDetails.productPrice.sectionTitle": "Price Details",
    "ecom.storeSide.productDetails.productPrice.accordionTitle": "Product Price",
    "ecom.storeSide.productDetails.personalization.accordionTitle": "Personalisation",
    "ecom.storeSide.deleteItem.confirmationModal": "Delete selected item from basket?",
    "ecom.storeSide.signinLoggedOut.pageTitle1": "CHECKOUT",
    "ecom.storeSide.signinLoggedOut.asGuest.title2": "Continue as Guest",
    "ecom.storeSide.signinLoggedOut.email.inputLabel": "Email",
    "ecom.storeSide.signinLoggedOut.email.inputPlaceholder": "Enter an email",
    "ecom.storeSide.signinLoggedOut.email.inputHelper": "We only use this to send you the receipt and order confirmation",
    "ecom.storeSide.signinLoggedOut.asGuest.primaryButton": "Continue as Guest",
    "ecom.storeSide.signinLoggedOut.asMember.title2": "Sign In for Faster Checkout",
    "ecom.storeSide.signinLoggedOut.password.inputLabel": "Password",
    "ecom.storeSide.signinLoggedOut.password.inputPlaceholder": "Enter a password",
    "ecom.storeSide.signinLoggedOut.forgetPassword.link": "Forget your password?",
    "ecom.storeSide.signinLoggedOut.asMember.primaryButton": "Sign in",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaText": "New member? ",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaLink": "Create an Account Here",
    "ecom.storeSide.signinLoggedOut.back.link": "Return to cart",
    "ecom.storeSide.signinLoggedIn.member.pageTitle": "MEMBER",
    "ecom.storeSide.signinLoggedIn.continueAsMember.title2": "Continue as",
    "ecom.storeSide.signinLoggedIn.nextStep.primaryButton": "Continue ",
    "ecom.storeSide.signinLoggedIn.signOut.link": "(Not You?)",
    "ecom.storeSide.shipping.pageTitle": "SHIPPING",
    "ecom.storeSide.shipping.shipMethod.sectionTitle": "Shipping Method",
    "ecom.storeSide.shipping.shipDescription.sectionTitle": "Shipping Description",
    "ecom.storeSide.shipping.nextToPayment.primaryButton": "Next to Payment",
    "ecom.storeSide.shipping.back.link": "Return to Sign In",
    "ecom.storeSide.payment.pageTitle": "PAYMENT",
    "ecom.storeSide.payment.payMethod.sectionTitle": "Payment Method",
    "ecom.storeSide.payment.cardDetails.sectionTitle": "Credit Card Details",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputLabel": "Card Number:",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputPlaceholder": "Enter card number",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputError": "This field is required",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputErrorValid": "Card number is invalid",
    "ecom.storeSide.payment.cardDetails.expiry.inputLabel": "Expiry:",
    "ecom.storeSide.payment.cardDetails.expiry.inputPlaceholder": "MM / YY",
    "ecom.storeSide.payment.cardDetails.expiry.inputError": "This field is required",
    "ecom.storeSide.payment.cardDetails.cvc.inputLabel": "CVC:",
    "ecom.storeSide.payment.cardDetails.cvc.inputPlaceholder": "3 digits",
    "ecom.storeSide.payment.cardDetails.cvc.inputError": "This field is required",
    "ecom.storeSide.payment.nextToReview.primaryButton": "Next to Review",
    "ecom.storeSide.payment.back.link": "Return to Shipping",
    "ecom.storeSide.review.pageTitle": "REVIEW",
    "ecom.storeSide.review.contactInfo.guideText": "Contact Information:",
    "ecom.storeSide.review.shipping.guideText": "Shipping:",
    "ecom.storeSide.review.payment.guideText": "Payment:",
    "ecom.storeSide.review.editDetails.link": "Edit",
    "ecom.storeSide.review.termAndCondition.checkBoxLabel": "I agree with the terms and conditions and the privacy policy of the brand",
    "ecom.storeSide.addressForm.firstName.inputLabel": "First Name",
    "ecom.storeSide.addressForm.firstName.inputPlaceholder": "Enter first name",
    "ecom.storeSide.addressForm.lastName.inputLabel": "Last Name",
    "ecom.storeSide.addressForm.lastName.inputPlaceholder": "Enter last name",
    "ecom.storeSide.addressForm.phone.inputLabel": "Contact Number (Optional)",
    "ecom.storeSide.addressForm.phone.inputPlaceholder": "Enter phone no.",
    "ecom.storeSide.addressForm.address1.inputLabel": "Address Line 1",
    "ecom.storeSide.addressForm.address1.inputPlaceholder": "Building number & street name",
    "ecom.storeSide.addressForm.countryCode.inputLabel": "Country",
    "ecom.storeSide.addressForm.countryCode.inputPlaceholder": "Please select",
    "ecom.storeSide.addressForm.provinceCode.inputLabel": "County",
    "ecom.storeSide.addressForm.provinceCode.inputPlaceholder": "Please select",
    "ecom.storeSide.addressForm.city.inputLabel": "City",
    "ecom.storeSide.addressForm.city.inputPlaceholder": "Please select",
    "ecom.storeSide.addressForm.zip.inputLabel": "Postcode",
    "ecom.storeSide.addressForm.zip.inputPlaceholder": "Enter postcode",
    "ecom.storeSide.addressForm.shipAddress.accordionTitle": "Select delivery address",
    "ecom.storeSide.addressForm.addNewAddress.secondaryButton": "Add new address",
    "ecom.storeSide.addressForm.editAddress.link": "Edit",
    "ecom.storeSide.addressForm.cancelAddress.ghostButton": "Cancel",
    "ecom.storeSide.addressForm.saveAddress.secondaryButton": "Save",
    "ecom.storeSide.addressForm.deleteAddress.ghostButton": "Delete ",
    "ecom.storeSide.addressForm.sameAsShipping.checkBoxLabel": "Same as delivery address",
    "ecom.storeSide.addressForm.requestInvoice.checkBoxLabel": "Request tax invoice",
    "ecom.storeSide.addressForm.company.inputLabel": "Company Name",
    "ecom.storeSide.addressForm.company.inputPlaceholder": "Enter company name",
    "ecom.storeSide.addressForm.vatNumber.inputLabel": "Tax ID",
    "ecom.storeSide.addressForm.vatNumber.inputPlaceholder": "Enter tax ID",
    "ecom.storeSide.addressForm.requiredField.inlineError": "This field is required",
    "ecom.checkout.shippingMethodUpdated.modalBody": "Your preferred delivery method is unavailable in [country]; please select another. ",
    "ecom.checkout.deleteAddress.modalTitle": "Delete default billing address?",
    "ecom.checkout.addAddress.toast": "Address added",
    "ecom.checkout.deleteAddress.toast": "Address deleted"
});
var nl_NL = _object_spread_props(_object_spread({}, en_US), {
    "ecom.storeSide.signinLoggedIn.loginSuccess.toast": "Uitloggen succesvol",
    "ecom.storeSide.signinLoggedIn.loginFailed.toast": "Uitloggen mislukt",
    "ecom.storeSide.signinLoggedIn.guest.pageTitle": "Gast",
    "ecom.storeSide.orderSum.sectionTitle": "Overzicht van bestellingen openen",
    "ecom.storeSide.orderSum.outlineCloseButton": "Overzicht van bestellingen sluiten",
    "ecom.productCard.sectionTitle": "Productoverzicht",
    "ecom.storeSide.productDetails.basePrice.title": "Basisprijs",
    "ecom.storeSide.productDetails.unitPrice.title": "Eenheidsprijs",
    "ecom.storeSide.duplicatedCoupon.toast": "Oeps! Dubbele coupon",
    "ecom.storeSide.shipping.pageDescription": "Beschrijving van de verzendpagina",
    "ecom.storeSide.review.termAndCondition.checkBoxError": "Algemene voorwaarden foutmelding",
    "ecom.storeSide.addressForm.shipAddress.sectionTitle": "Verzendingsadres",
    "ecom.storeSide.addressForm.billAddress.sectionTitle": "Facturatieadres",
    "ecom.storeSide.addressForm.deleteAddress.modalTitle": "U staat op het punt een adres te verwijderen",
    "ecom.storeSide.addressForm.deleteAddress.modalDescription": "Hiermee wordt een adres uit uw adresboek verwijderd",
    "ecom.checkout.shippingMethodNotAvailable.errorAlert": "Onze service is niet beschikbaar in uw land. Wijzig/bewerk het verzendadres hieronder.",
    "ecom.checkout.shippingMethodSelectedCountry.warningAlert": "Selecteer het land van bestemming om de beschikbare verzendmethoden te zien",
    "ecom.storeSide.payment.payMethodNotAdded.warningAlert": "Betaalmethode is nog niet toegevoegd. Configureer dit in de betalingsinstellingen.",
    "ecom.storeSide.payment.paymentFailed.toast": "Betaling mislukt",
    "ecom.storeSide.payment.paymentSuccess.toast": "Betaling gelukt",
    "ecom.storeSide.payment.pendingPayment.title": "Betaling in behandeling",
    "ecom.storeSide.payment.IdealPaymentWarning": "Selecteer bank a.u.b",
    "ecom.checkout.invalidAddress.toast": "Het adres is ongeldig. Vul de provincie, stad of postcode in voor de juiste BTW.",
    "ecom.storeSide.shoppingCart.sectionTitle": "Jouw winkelmandje",
    "ecom.storeSide.productCard.detail.ghostButton": "Details",
    "ecom.storeSide.productCard.itemUnavailable.inlineAlert": "Dit artikel is niet beschikbaar. Verwijder het alstublieft.",
    "ecom.storeSide.productCard.variantUnavailable.inlineAlert": "De variantkeuze is niet beschikbaar. Pas je keuze aan.",
    "ecom.storeSide.productCard.variantNotSelected.inlineAlert": "Maak je keuze",
    "ecom.storeSide.paySum.sectionTitle": "Betalingsoverzicht",
    "ecom.storeSide.coupon.inputLabel": "Coupon",
    "ecom.storeSide.coupon.inputPlaceholder": "Vul je couponcode in",
    "ecom.storeSide.coupon.secondaryButton": "Toepassen",
    "ecom.storeSide.paySum.cartTotal.paragraph": "Totaal winkelmandje",
    "ecom.storeSide.paySum.coupon.accordionTitle": "Coupon code",
    "ecom.storeSide.paySum.promotion.accordionTitle": "Promotie",
    "ecom.storeSide.paySum.cartTotalExclTax.title": "Totaal excl BTW",
    "ecom.storeSide.paySum.cartTotalExclTaxNoShipping.description": "BTW & verzendkosten worden berekend bij het uitchecken.",
    "ecom.storeSide.paySum.cartTotalInclTax.title": "Totaal incl BTW",
    "ecom.storeSide.paySum.cartTotalInclTaxNoShipping.description": "btw inbegrepen. Verzending berekend bij het uitchecen.)",
    "ecom.storeSide.refundPolicy.footerLink": "Terugbetalingsbeleid",
    "ecom.storeSide.privatePolicy.footerLink": "Privacybeleid",
    "ecom.storeSide.termAndCondition.footerLink": "Algemene voorwaarden",
    "ecom.storeSide.backToStorefront.link": "Verder winkelen",
    "ecom.storeSide.goToCheckout.primaryButton": "Uitchecken",
    "ecom.storeSide.goToCheckout.securedPay.description": "Veilige betalingen met  [ ]",
    "ecom.storeSide.couponExpired.alert": "Verwijder a.u.b. verlopen couponcode",
    "ecom.storeSide.couponExpired.inlineAlert": "Verlopen couponcode",
    "ecom.storeSide.couponApplied.toast": "Coupon toegepast. Totale prijs bijgewerkt.",
    "ecom.storeSide.couponDeleted.toast": "Coupon verwijderd. Totale prijs bijgewerkt.",
    "ecom.storeSide.invalidCoupon.inputError": "Ongeldige couponcode.",
    "ecom.storeSide.productDetails.size.sectionTitle": "Maat",
    "ecom.storeSide.productDetails.color.sectionTitle": "Kleur",
    "ecom.storeSide.productDetails.productPrice.sectionTitle": "Prijsdetails",
    "ecom.storeSide.productDetails.productPrice.accordionTitle": "Productprijs",
    "ecom.storeSide.productDetails.personalization.accordionTitle": "Personalisatie",
    "ecom.storeSide.deleteItem.confirmationModal": "Geselecteerd artikel uit winkelmandje verwijderen?",
    "ecom.storeSide.signinLoggedOut.pageTitle1": "Checkout",
    "ecom.storeSide.signinLoggedOut.asGuest.title2": "Ga door als gast",
    "ecom.storeSide.signinLoggedOut.email.inputLabel": "Email",
    "ecom.storeSide.signinLoggedOut.email.inputPlaceholder": "Vul een email in",
    "ecom.storeSide.signinLoggedOut.email.inputHelper": "We gebruiken dit alleen om je de orderbevestiging te sturen",
    "ecom.storeSide.signinLoggedOut.asGuest.primaryButton": "Ga door als gast",
    "ecom.storeSide.signinLoggedOut.asMember.title2": "Aanmelden voor sneller uitchecken",
    "ecom.storeSide.signinLoggedOut.password.inputLabel": "Wachtwoord",
    "ecom.storeSide.signinLoggedOut.password.inputPlaceholder": "Wachtwoord invullen",
    "ecom.storeSide.signinLoggedOut.forgetPassword.link": "Wachtwoord vergeten?",
    "ecom.storeSide.signinLoggedOut.asMember.primaryButton": "Inloggen",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaText": "?",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaLink": "Maak hier een account aan",
    "ecom.storeSide.signinLoggedOut.back.link": "Terug naar winkelmandje",
    "ecom.storeSide.signinLoggedIn.member.pageTitle": "?",
    "ecom.storeSide.signinLoggedIn.continueAsMember.title2": "Ga verder als",
    "ecom.storeSide.signinLoggedIn.nextStep.primaryButton": "Ga verder",
    "ecom.storeSide.signinLoggedIn.signOut.link": "(Dit ben jij niet?)",
    "ecom.storeSide.shipping.pageTitle": "Verzending",
    "ecom.storeSide.shipping.shipMethod.sectionTitle": "Verzendmethode",
    "ecom.storeSide.shipping.shipDescription.sectionTitle": "Verzending beschrijving:",
    "ecom.storeSide.shipping.nextToPayment.primaryButton": "Ga naar Betaling",
    "ecom.storeSide.shipping.back.link": "Keer terug naar login",
    "ecom.storeSide.payment.pageTitle": "Betaling",
    "ecom.storeSide.payment.payMethod.sectionTitle": "Betaalmethode",
    "ecom.storeSide.payment.cardDetails.sectionTitle": "Kredietkaart details",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputLabel": "Kaartnummer:",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputPlaceholder": "Vul kaartnummer in",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputError": "Dit veld is verplicht",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputErrorValid": "Kaartnummer is ongeldig",
    "ecom.storeSide.payment.cardDetails.expiry.inputLabel": "Vervaldatum:",
    "ecom.storeSide.payment.cardDetails.expiry.inputPlaceholder": "MM / YY",
    "ecom.storeSide.payment.cardDetails.expiry.inputError": "Dit veld is verplicht",
    "ecom.storeSide.payment.cardDetails.cvc.inputLabel": "CVC:",
    "ecom.storeSide.payment.cardDetails.cvc.inputPlaceholder": "3 cijfers",
    "ecom.storeSide.payment.cardDetails.cvc.inputError": "Dit veld is verplicht",
    "ecom.storeSide.payment.nextToReview.primaryButton": "Ga naar Nakijken",
    "ecom.storeSide.payment.back.link": "Keer terug naar Verzenden",
    "ecom.storeSide.review.pageTitle": "Nakijken",
    "ecom.storeSide.review.contactInfo.guideText": "Contactgegevens:",
    "ecom.storeSide.review.shipping.guideText": "Verzenden:",
    "ecom.storeSide.review.payment.guideText": "Betaling:",
    "ecom.storeSide.review.editDetails.link": "Aanpassen",
    "ecom.storeSide.review.termAndCondition.checkBoxLabel": "Ik ga akkoord met de algemene voorwaarden en het privacybeleid van",
    "ecom.storeSide.addressForm.firstName.inputLabel": "Voornaam",
    "ecom.storeSide.addressForm.firstName.inputPlaceholder": "Vull je voornaam in",
    "ecom.storeSide.addressForm.lastName.inputLabel": "Achternaam",
    "ecom.storeSide.addressForm.lastName.inputPlaceholder": "Vul je achternaam in:",
    "ecom.storeSide.addressForm.phone.inputLabel": "Contactnummer (optioneel)",
    "ecom.storeSide.addressForm.phone.inputPlaceholder": "Vul tel. nr. in",
    "ecom.storeSide.addressForm.address1.inputLabel": "Adres regel 1",
    "ecom.storeSide.addressForm.address1.inputPlaceholder": "Gebouwnummer & straatnaam",
    "ecom.storeSide.addressForm.countryCode.inputLabel": "Land",
    "ecom.storeSide.addressForm.countryCode.inputPlaceholder": "Maak een keuze",
    "ecom.storeSide.addressForm.provinceCode.inputLabel": "Provincie/Staat",
    "ecom.storeSide.addressForm.provinceCode.inputPlaceholder": "Maak een keuze",
    "ecom.storeSide.addressForm.city.inputLabel": "Stad",
    "ecom.storeSide.addressForm.city.inputPlaceholder": "Maak een keuze",
    "ecom.storeSide.addressForm.zip.inputLabel": "Postcode",
    "ecom.storeSide.addressForm.zip.inputPlaceholder": "Vul postcode in",
    "ecom.storeSide.addressForm.shipAddress.accordionTitle": "Selecteer verzendadres",
    "ecom.storeSide.addressForm.addNewAddress.secondaryButton": "Nieuw adres toevoegen",
    "ecom.storeSide.addressForm.editAddress.link": "Aanpassen",
    "ecom.storeSide.addressForm.cancelAddress.ghostButton": "Annuleer",
    "ecom.storeSide.addressForm.saveAddress.secondaryButton": "Bewaar",
    "ecom.storeSide.addressForm.deleteAddress.ghostButton": "Verwijder",
    "ecom.storeSide.addressForm.sameAsShipping.checkBoxLabel": "Zelfde als verzendadres",
    "ecom.storeSide.addressForm.billAddress.accordionTitle": "Selecteer factuuradres",
    "ecom.storeSide.addressForm.requestInvoice.checkBoxLabel": "BTW factuur aanvragen",
    "ecom.storeSide.addressForm.company.inputLabel": "Bedrijfsnaam",
    "ecom.storeSide.addressForm.company.inputPlaceholder": "Vul bedrijfsnaam in",
    "ecom.storeSide.addressForm.vatNumber.inputLabel": "BTW nummer",
    "ecom.storeSide.addressForm.vatNumber.inputPlaceholder": "Vul BTW nummer in",
    "ecom.storeSide.addressForm.requiredField.inlineError": "Dit veld is verplicht",
    "ecom.checkout.deleteAddress.modalTitle": "Standaard factuuradres verwijderen?",
    "ecom.checkout.addAddress.toast": "Adres toegevoegd",
    "ecom.checkout.deleteAddress.toast": "Adres verwijderd"
});
var fr_CA = _object_spread_props(_object_spread({}, en_US), {
    "ecom.storeSide.signinLoggedIn.loginSuccess.toast": "D\xe9connexion r\xe9ussie",
    "ecom.storeSide.signinLoggedIn.loginFailed.toast": "\xc9chec de la d\xe9connexion",
    "ecom.storeSide.signinLoggedIn.guest.pageTitle": "INVIT\xc9",
    "ecom.storeSide.orderSum.sectionTitle": "Ouvrir le r\xe9capitulatif de la commande",
    "ecom.storeSide.orderSum.outlineCloseButton": "Fermer le r\xe9capitulatif de la commande",
    "ecom.productCard.sectionTitle": "R\xc9SUM\xc9 DES PRODUITS",
    "ecom.storeSide.productDetails.basePrice.title": "Prix ​​de base",
    "ecom.storeSide.productDetails.unitPrice.title": "Prix ​​unitaire",
    "ecom.storeSide.duplicatedCoupon.toast": "Oups! Coupon dupliqu\xe9",
    "ecom.storeSide.shipping.pageDescription": "Description de la page de livraison",
    "ecom.storeSide.review.termAndCondition.checkBoxError": "Erreur de termes et conditions",
    "ecom.storeSide.addressForm.shipAddress.sectionTitle": "Adresse de livraison",
    "ecom.storeSide.addressForm.billAddress.sectionTitle": "Adresse de facturation",
    "ecom.storeSide.addressForm.deleteAddress.modalTitle": "Vous \xeates sur le point de supprimer une adresse",
    "ecom.storeSide.addressForm.deleteAddress.modalDescription": "Cela supprimera une adresse de votre carnet d'adresses",
    "ecom.checkout.shippingMethodNotAvailable.errorAlert": "Notre service n'est pas disponible dans votre pays, veuillez changer/modifier l'adresse de livraison ci-dessous.",
    "ecom.checkout.shippingMethodSelectedCountry.warningAlert": "Veuillez s\xe9lectionner le pays de destination pour voir les m\xe9thodes d'exp\xe9dition disponibles",
    "ecom.storeSide.payment.payMethodNotAdded.warningAlert": "Le mode de paiement n'a pas encore \xe9t\xe9 ajout\xe9. Veuillez le configurer dans les param\xe8tres de paiement.",
    "ecom.storeSide.payment.paymentFailed.toast": "Paiement \xe9chou\xe9",
    "ecom.storeSide.payment.paymentSuccess.toast": "Paiement r\xe9ussi",
    "ecom.storeSide.payment.pendingPayment.title": "Paiement en attente",
    "ecom.storeSide.payment.IdealPaymentWarning": "Veuillez s\xe9lectionner la banque",
    "ecom.checkout.invalidAddress.toast": "L'adresse est invalide. Veuillez mettre \xe0 jour l'\xe9tat, la ville ou le code postal pour un calcul de la taxe.",
    "ecom.storeSide.shoppingCart.sectionTitle": "Mon panier",
    "ecom.storeSide.productCard.detail.ghostButton": "D\xe9tails",
    "ecom.storeSide.productCard.itemUnavailable.inlineAlert": "Cet article n'est pas disponible ; veuillez le retirer.",
    "ecom.storeSide.productCard.variantUnavailable.inlineAlert": "Le choix n'est pas disponible; veuillez modifier votre s\xe9lection.",
    "ecom.storeSide.productCard.variantNotSelected.inlineAlert": "Faites votre choix",
    "ecom.storeSide.paySum.sectionTitle": "Aper\xe7u du paiement",
    "ecom.storeSide.coupon.inputLabel": "Code promo",
    "ecom.storeSide.coupon.inputPlaceholder": "Entrez votre code promo",
    "ecom.storeSide.coupon.secondaryButton": "Appliquer",
    "ecom.storeSide.paySum.cartTotal.paragraph": "Panier total",
    "ecom.storeSide.paySum.coupon.accordionTitle": "Code promo",
    "ecom.storeSide.paySum.promotion.accordionTitle": "Promo",
    "ecom.storeSide.paySum.cartTotalExclTax.title": "Sous-total",
    "ecom.storeSide.paySum.cartTotalExclTaxNoShipping.description": "Les imp\xf4ts et les frais d'exp\xe9dition sont calcul\xe9 au moment du d\xe9part.",
    "ecom.storeSide.paySum.cartTotalInclTax.title": "Total TTC",
    "ecom.storeSide.paySum.cartTotalInclTaxNoShipping.description": "(Taxe de 7\xa0% incluse. Frais de livraison calcul\xe9 au moment du paiement.)",
    "ecom.storeSide.refundPolicy.footerLink": "Politique de remboursement",
    "ecom.storeSide.privatePolicy.footerLink": "Politique de Confidentialit\xe9",
    "ecom.storeSide.termAndCondition.footerLink": "Termes et conditions",
    "ecom.storeSide.backToStorefront.link": "Continuer mes achats",
    "ecom.storeSide.goToCheckout.primaryButton": "R\xe9capitulatif",
    "ecom.storeSide.goToCheckout.securedPay.description": "Paiements s\xe9curis\xe9s par [Payment Gateway]",
    "ecom.storeSide.couponExpired.alert": "Veuillez supprimer le code promo expir\xe9",
    "ecom.storeSide.couponExpired.inlineAlert": "Code promo expir\xe9",
    "ecom.storeSide.couponApplied.toast": "Code promo appliqu\xe9. Prix ​​total mis \xe0 jour.",
    "ecom.storeSide.couponDeleted.toast": "Code promo supprim\xe9. Prix ​​total mis \xe0 jour.",
    "ecom.storeSide.invalidCoupon.inputError": "Code promo invalide.",
    "ecom.storeSide.productDetails.size.sectionTitle": "Taille",
    "ecom.storeSide.productDetails.color.sectionTitle": "Couleur",
    "ecom.storeSide.productDetails.productPrice.sectionTitle": "D\xe9tails du prix",
    "ecom.storeSide.productDetails.productPrice.accordionTitle": "Prix ​​du produit",
    "ecom.storeSide.productDetails.personalization.accordionTitle": "Personnalisation",
    "ecom.storeSide.deleteItem.confirmationModal": "Supprimer l'article s\xe9lectionn\xe9 du panier\xa0?",
    "ecom.storeSide.signinLoggedOut.pageTitle1": "R\xe9capitulatif",
    "ecom.storeSide.signinLoggedOut.asGuest.title2": "Je continue sans cr\xe9er de compte",
    "ecom.storeSide.signinLoggedOut.email.inputLabel": "Courriel",
    "ecom.storeSide.signinLoggedOut.email.inputPlaceholder": "J'entre mon e-mail",
    "ecom.storeSide.signinLoggedOut.email.inputHelper": "Nous l'utilisons uniquement pour vous envoyer le bon de r\xe9ception et la confirmation de commande",
    "ecom.storeSide.signinLoggedOut.asGuest.primaryButton": "Je continue sans cr\xe9er un compte",
    "ecom.storeSide.signinLoggedOut.asMember.title2": "Je me connecte pour un paiement plus rapide",
    "ecom.storeSide.signinLoggedOut.password.inputLabel": "Mot de passe",
    "ecom.storeSide.signinLoggedOut.password.inputPlaceholder": "J'entre mon mot de passe",
    "ecom.storeSide.signinLoggedOut.forgetPassword.link": "Mot de passe oubli\xe9 ?",
    "ecom.storeSide.signinLoggedOut.asMember.primaryButton": "S'identifier",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaText": "S'inscrire",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaLink": "Je cr\xe9e mon compte ici",
    "ecom.storeSide.signinLoggedOut.back.link": "Retour au panier",
    "ecom.storeSide.signinLoggedIn.member.pageTitle": "Membre",
    "ecom.storeSide.signinLoggedIn.continueAsMember.title2": "Je continue en tant que",
    "ecom.storeSide.signinLoggedIn.nextStep.primaryButton": "Je continue",
    "ecom.storeSide.signinLoggedIn.signOut.link": "(Pas vous?)",
    "ecom.storeSide.shipping.pageTitle": "Livraison",
    "ecom.storeSide.shipping.shipMethod.sectionTitle": "Mode de livraison",
    "ecom.storeSide.shipping.shipDescription.sectionTitle": "Description de mode de livraison",
    "ecom.storeSide.shipping.nextToPayment.primaryButton": "Aller au paiement",
    "ecom.storeSide.shipping.back.link": "Revenir \xe0 la connexion",
    "ecom.storeSide.payment.pageTitle": "Paiement",
    "ecom.storeSide.payment.payMethod.sectionTitle": "Mode de paiement",
    "ecom.storeSide.payment.cardDetails.sectionTitle": "D\xe9tails de la carte de cr\xe9dit",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputLabel": "Num\xe9ro de carte:",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputPlaceholder": "Saisissez les 16 chiffres de la carte \xe0 utiliser",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputError": "Ce champ est requis",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputErrorValid": "Le num\xe9ro de carte est invalide",
    "ecom.storeSide.payment.cardDetails.expiry.inputLabel": "Expiration:",
    "ecom.storeSide.payment.cardDetails.expiry.inputPlaceholder": "MM/AA",
    "ecom.storeSide.payment.cardDetails.expiry.inputError": "Ce champ est requis",
    "ecom.storeSide.payment.cardDetails.cvc.inputLabel": "CVC:",
    "ecom.storeSide.payment.cardDetails.cvc.inputPlaceholder": "3 chiffres",
    "ecom.storeSide.payment.cardDetails.cvc.inputError": "Ce champ est requis",
    "ecom.storeSide.payment.nextToReview.primaryButton": "Passer en revue",
    "ecom.storeSide.payment.back.link": "Retour \xe0 la livraison",
    "ecom.storeSide.review.pageTitle": "Passer en revue",
    "ecom.storeSide.review.contactInfo.guideText": "Coordonn\xe9es:",
    "ecom.storeSide.review.shipping.guideText": "Livraison:",
    "ecom.storeSide.review.payment.guideText": "Paiement:",
    "ecom.storeSide.review.editDetails.link": "\xc9diter",
    "ecom.storeSide.review.termAndCondition.checkBoxLabel": "J'accepte les termes et conditions et la politique de confidentialit\xe9 de la marque",
    "ecom.storeSide.addressForm.firstName.inputPlaceholder": "Entrez votre pr\xe9nom",
    "ecom.storeSide.addressForm.lastName.inputLabel": "Nom",
    "ecom.storeSide.addressForm.lastName.inputPlaceholder": "Entrez votre nom",
    "ecom.storeSide.addressForm.phone.inputLabel": "Num\xe9ro de t\xe9l\xe9phone (optionnel)",
    "ecom.storeSide.addressForm.phone.inputPlaceholder": "Entrez num. Tel.",
    "ecom.storeSide.addressForm.address1.inputLabel": "Adresse Ligne 1:",
    "ecom.storeSide.addressForm.address1.inputPlaceholder": "Num\xe9ro du b\xe2timent et nom de la rue",
    "ecom.storeSide.addressForm.countryCode.inputLabel": "Pays",
    "ecom.storeSide.addressForm.countryCode.inputPlaceholder": "Veuillez s\xe9lectionner",
    "ecom.storeSide.addressForm.provinceCode.inputLabel": "R\xe9gion",
    "ecom.storeSide.addressForm.provinceCode.inputPlaceholder": "Veuillez s\xe9lectionner",
    "ecom.storeSide.addressForm.city.inputLabel": "Ville",
    "ecom.storeSide.addressForm.city.inputPlaceholder": "Veuillez s\xe9lectionner",
    "ecom.storeSide.addressForm.zip.inputLabel": "Code postal",
    "ecom.storeSide.addressForm.zip.inputPlaceholder": "Entrez code postal",
    "ecom.storeSide.addressForm.shipAddress.accordionTitle": "S\xe9lectionnez l'adresse de livraison",
    "ecom.storeSide.addressForm.addNewAddress.secondaryButton": "Ajouter une nouvelle adresse",
    "ecom.storeSide.addressForm.editAddress.link": "\xc9diter",
    "ecom.storeSide.addressForm.cancelAddress.ghostButton": "Annuler",
    "ecom.storeSide.addressForm.saveAddress.secondaryButton": "Sauvegarder",
    "ecom.storeSide.addressForm.deleteAddress.ghostButton": "Effacer",
    "ecom.storeSide.addressForm.sameAsShipping.checkBoxLabel": "Same as delivery address",
    "ecom.storeSide.addressForm.billAddress.accordionTitle": "Selectionner l'adresse de facturation",
    "ecom.storeSide.addressForm.requestInvoice.checkBoxLabel": "Demander une facture fiscale",
    "ecom.storeSide.addressForm.company.inputLabel": "Nom de l'entreprise:",
    "ecom.storeSide.addressForm.company.inputPlaceholder": "Entrez le nom de l'entreprise",
    "ecom.storeSide.addressForm.vatNumber.inputLabel": "Num\xe9ro d'identification fiscale\xa0:",
    "ecom.storeSide.addressForm.vatNumber.inputPlaceholder": "Saisir le num\xe9ro d'identification fiscale",
    "ecom.storeSide.addressForm.requiredField.inlineError": "Ce champ est requis",
    "ecom.checkout.deleteAddress.modalTitle": "Supprimer l'adresse de facturation par d\xe9faut\xa0?",
    "ecom.checkout.addAddress.toast": "Adresse ajout\xe9e",
    "ecom.checkout.deleteAddress.toast": "Adresse supprim\xe9e"
});
var fr_FR = _object_spread_props(_object_spread({}, en_US), {
    "ecom.storeSide.signinLoggedIn.loginSuccess.toast": "D\xe9connexion r\xe9ussie",
    "ecom.storeSide.signinLoggedIn.loginFailed.toast": "\xc9chec de la d\xe9connexion",
    "ecom.storeSide.signinLoggedIn.guest.pageTitle": "INVIT\xc9",
    "ecom.storeSide.orderSum.sectionTitle": "Ouvrir le r\xe9capitulatif de la commande",
    "ecom.storeSide.orderSum.outlineCloseButton": "Fermer le r\xe9capitulatif de la commande",
    "ecom.productCard.sectionTitle": "R\xc9SUM\xc9 DES PRODUITS",
    "ecom.storeSide.productDetails.basePrice.title": "Prix ​​de base",
    "ecom.storeSide.productDetails.unitPrice.title": "Prix ​​unitaire",
    "ecom.storeSide.duplicatedCoupon.toast": "Oups! Coupon dupliqu\xe9",
    "ecom.storeSide.shipping.pageDescription": "Description de la page de livraison",
    "ecom.storeSide.review.termAndCondition.checkBoxError": "Erreur de termes et conditions",
    "ecom.storeSide.addressForm.shipAddress.sectionTitle": "Adresse de livraison",
    "ecom.storeSide.addressForm.billAddress.sectionTitle": "Adresse de facturation",
    "ecom.storeSide.addressForm.deleteAddress.modalTitle": "Vous \xeates sur le point de supprimer une adresse",
    "ecom.storeSide.addressForm.deleteAddress.modalDescription": "Cela supprimera une adresse de votre carnet d'adresses",
    "ecom.checkout.shippingMethodNotAvailable.errorAlert": "Notre service n'est pas disponible dans votre pays, veuillez changer/modifier l'adresse de livraison ci-dessous.",
    "ecom.checkout.shippingMethodSelectedCountry.warningAlert": "Veuillez s\xe9lectionner le pays de destination pour voir les m\xe9thodes d'exp\xe9dition disponibles",
    "ecom.storeSide.payment.payMethodNotAdded.warningAlert": "Le mode de paiement n'a pas encore \xe9t\xe9 ajout\xe9. Veuillez le configurer dans les param\xe8tres de paiement.",
    "ecom.storeSide.payment.paymentFailed.toast": "Paiement \xe9chou\xe9",
    "ecom.storeSide.payment.paymentSuccess.toast": "Paiement r\xe9ussi",
    "ecom.storeSide.payment.pendingPayment.title": "Paiement en attente",
    "ecom.storeSide.payment.IdealPaymentWarning": "Veuillez s\xe9lectionner la banque",
    "ecom.checkout.invalidAddress.toast": "L'adresse est invalide. Veuillez mettre \xe0 jour l'\xe9tat, la ville ou le code postal pour un calcul de la taxe.",
    "ecom.storeSide.shoppingCart.sectionTitle": "Mon panier",
    "ecom.storeSide.productCard.detail.ghostButton": "D\xe9tails",
    "ecom.storeSide.productCard.itemUnavailable.inlineAlert": "Cet article n'est pas disponible ; veuillez le retirer.",
    "ecom.storeSide.productCard.variantUnavailable.inlineAlert": "Le choix n'est pas disponible; veuillez modifier votre s\xe9lection.",
    "ecom.storeSide.productCard.variantNotSelected.inlineAlert": "Faites votre choix",
    "ecom.storeSide.paySum.sectionTitle": "Aper\xe7u du paiement",
    "ecom.storeSide.coupon.inputLabel": "Code promo",
    "ecom.storeSide.coupon.inputPlaceholder": "Entrez votre code promo",
    "ecom.storeSide.coupon.secondaryButton": "Appliquer",
    "ecom.storeSide.paySum.cartTotal.paragraph": "Panier total",
    "ecom.storeSide.paySum.coupon.accordionTitle": "Code promo",
    "ecom.storeSide.paySum.promotion.accordionTitle": "Promo",
    "ecom.storeSide.paySum.cartTotalExclTax.title": "Sous-total",
    "ecom.storeSide.paySum.cartTotalExclTaxNoShipping.description": "La TVA et les frais d'exp\xe9dition sont calcul\xe9 au moment du d\xe9part.",
    "ecom.storeSide.paySum.cartTotalInclTax.title": "Total TTC",
    "ecom.storeSide.paySum.cartTotalInclTaxNoShipping.description": "(Taxe de 7\xa0% incluse. Frais de livraison calcul\xe9 au moment du paiement.)",
    "ecom.storeSide.refundPolicy.footerLink": "Politique de remboursement",
    "ecom.storeSide.privatePolicy.footerLink": "Politique de Confidentialit\xe9",
    "ecom.storeSide.termAndCondition.footerLink": "Termes et conditions",
    "ecom.storeSide.backToStorefront.link": "Continuer mes achats",
    "ecom.storeSide.goToCheckout.primaryButton": "R\xe9capitulatif",
    "ecom.storeSide.goToCheckout.securedPay.description": "Paiements s\xe9curis\xe9s par [Payment Gateway]",
    "ecom.storeSide.couponExpired.alert": "Veuillez supprimer le code promo expir\xe9",
    "ecom.storeSide.couponExpired.inlineAlert": "Code promo expir\xe9",
    "ecom.storeSide.couponApplied.toast": "Code promo appliqu\xe9. Prix ​​total mis \xe0 jour.",
    "ecom.storeSide.couponDeleted.toast": "Code promo supprim\xe9. Prix ​​total mis \xe0 jour.",
    "ecom.storeSide.invalidCoupon.inputError": "Code promo invalide.",
    "ecom.storeSide.productDetails.size.sectionTitle": "Taille",
    "ecom.storeSide.productDetails.color.sectionTitle": "Couleur",
    "ecom.storeSide.productDetails.productPrice.sectionTitle": "D\xe9tails du prix",
    "ecom.storeSide.productDetails.productPrice.accordionTitle": "Prix ​​du produit",
    "ecom.storeSide.productDetails.personalization.accordionTitle": "Personnalisation",
    "ecom.storeSide.deleteItem.confirmationModal": "Supprimer l'article s\xe9lectionn\xe9 du panier\xa0?",
    "ecom.storeSide.signinLoggedOut.pageTitle1": "R\xe9capitulatif",
    "ecom.storeSide.signinLoggedOut.asGuest.title2": "Je continue sans cr\xe9er de compte",
    "ecom.storeSide.signinLoggedOut.email.inputLabel": "E-mail",
    "ecom.storeSide.signinLoggedOut.email.inputPlaceholder": "J'entre mon e-mail",
    "ecom.storeSide.signinLoggedOut.email.inputHelper": "Nous l'utilisons uniquement pour vous envoyer le bon de r\xe9ception et la confirmation de commande",
    "ecom.storeSide.signinLoggedOut.asGuest.primaryButton": "Je continue sans cr\xe9er un compte",
    "ecom.storeSide.signinLoggedOut.asMember.title2": "Je me connecte pour un paiement plus rapide",
    "ecom.storeSide.signinLoggedOut.password.inputLabel": "Mot de passe",
    "ecom.storeSide.signinLoggedOut.password.inputPlaceholder": "J'entre mon mot de passe",
    "ecom.storeSide.signinLoggedOut.forgetPassword.link": "Mot de passe oubli\xe9 ?",
    "ecom.storeSide.signinLoggedOut.asMember.primaryButton": "S'identifier",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaText": "S'inscrire",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaLink": "Je cr\xe9e mon compte ici",
    "ecom.storeSide.signinLoggedOut.back.link": "Retour au panier",
    "ecom.storeSide.signinLoggedIn.member.pageTitle": "Membre",
    "ecom.storeSide.signinLoggedIn.continueAsMember.title2": "Je continue en tant que",
    "ecom.storeSide.signinLoggedIn.nextStep.primaryButton": "Je continue",
    "ecom.storeSide.signinLoggedIn.signOut.link": "(Pas vous?)",
    "ecom.storeSide.shipping.pageTitle": "Livraison",
    "ecom.storeSide.shipping.shipMethod.sectionTitle": "Mode de livraison",
    "ecom.storeSide.shipping.shipDescription.sectionTitle": "Description de mode de livraison",
    "ecom.storeSide.shipping.nextToPayment.primaryButton": "Aller au paiement",
    "ecom.storeSide.shipping.back.link": "Revenir \xe0 la connexion",
    "ecom.storeSide.payment.pageTitle": "Paiement",
    "ecom.storeSide.payment.payMethod.sectionTitle": "Mode de paiement",
    "ecom.storeSide.payment.cardDetails.sectionTitle": "D\xe9tails de la carte de cr\xe9dit",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputLabel": "Num\xe9ro de carte:",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputPlaceholder": "Saisissez les 16 chiffres de la carte \xe0 utiliser",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputError": "Ce champ est requis",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputErrorValid": "Le num\xe9ro de carte est invalide",
    "ecom.storeSide.payment.cardDetails.expiry.inputLabel": "Expiration:",
    "ecom.storeSide.payment.cardDetails.expiry.inputPlaceholder": "MM/AA",
    "ecom.storeSide.payment.cardDetails.expiry.inputError": "Ce champ est requis",
    "ecom.storeSide.payment.cardDetails.cvc.inputLabel": "CVC:",
    "ecom.storeSide.payment.cardDetails.cvc.inputPlaceholder": "3 chiffres",
    "ecom.storeSide.payment.cardDetails.cvc.inputError": "Ce champ est requis",
    "ecom.storeSide.payment.nextToReview.primaryButton": "Passer en revue",
    "ecom.storeSide.payment.back.link": "Retour \xe0 la livraison",
    "ecom.storeSide.review.pageTitle": "Passer en revue",
    "ecom.storeSide.review.contactInfo.guideText": "Coordonn\xe9es:",
    "ecom.storeSide.review.shipping.guideText": "Livraison:",
    "ecom.storeSide.review.payment.guideText": "Paiement:",
    "ecom.storeSide.review.editDetails.link": "\xc9diter",
    "ecom.storeSide.review.termAndCondition.checkBoxLabel": "J'accepte les termes et conditions et la politique de confidentialit\xe9 de la marque",
    "ecom.storeSide.addressForm.firstName.inputLabel": "Pr\xe9nom",
    "ecom.storeSide.addressForm.firstName.inputPlaceholder": "Entrez votre pr\xe9nom",
    "ecom.storeSide.addressForm.lastName.inputLabel": "Nom",
    "ecom.storeSide.addressForm.lastName.inputPlaceholder": "Entrez votre nom",
    "ecom.storeSide.addressForm.phone.inputLabel": "Num\xe9ro de t\xe9l\xe9phone (optionnel)",
    "ecom.storeSide.addressForm.phone.inputPlaceholder": "Entrez num. Tel.",
    "ecom.storeSide.addressForm.address1.inputLabel": "Adresse Ligne 1",
    "ecom.storeSide.addressForm.address1.inputPlaceholder": "Num\xe9ro du b\xe2timent et nom de la rue",
    "ecom.storeSide.addressForm.countryCode.inputLabel": "Pays",
    "ecom.storeSide.addressForm.countryCode.inputPlaceholder": "Veuillez s\xe9lectionner",
    "ecom.storeSide.addressForm.provinceCode.inputLabel": "R\xe9gion",
    "ecom.storeSide.addressForm.provinceCode.inputPlaceholder": "Veuillez s\xe9lectionner",
    "ecom.storeSide.addressForm.city.inputLabel": "Ville",
    "ecom.storeSide.addressForm.city.inputPlaceholder": "Veuillez s\xe9lectionner",
    "ecom.storeSide.addressForm.zip.inputLabel": "Code postal",
    "ecom.storeSide.addressForm.zip.inputPlaceholder": "Entrez code postal",
    "ecom.storeSide.addressForm.shipAddress.accordionTitle": "S\xe9lectionnez l'adresse de livraison",
    "ecom.storeSide.addressForm.addNewAddress.secondaryButton": "Ajouter une nouvelle adresse",
    "ecom.storeSide.addressForm.editAddress.link": "\xc9diter",
    "ecom.storeSide.addressForm.cancelAddress.ghostButton": "Annuler",
    "ecom.storeSide.addressForm.saveAddress.secondaryButton": "Sauvegarder",
    "ecom.storeSide.addressForm.deleteAddress.ghostButton": "Effacer",
    "ecom.storeSide.addressForm.sameAsShipping.checkBoxLabel": "Same as delivery address",
    "ecom.storeSide.addressForm.billAddress.accordionTitle": "Selectionner l'adresse de facturation",
    "ecom.storeSide.addressForm.requestInvoice.checkBoxLabel": "Demander une facture fiscale",
    "ecom.storeSide.addressForm.company.inputLabel": "Nom de l'entreprise:",
    "ecom.storeSide.addressForm.company.inputPlaceholder": "Entrez le nom de l'entreprise",
    "ecom.storeSide.addressForm.vatNumber.inputLabel": "Num\xe9ro d'identification fiscale\xa0:",
    "ecom.storeSide.addressForm.vatNumber.inputPlaceholder": "Saisir le num\xe9ro d'identification fiscale",
    "ecom.storeSide.addressForm.requiredField.inlineError": "Ce champ est requis",
    "ecom.checkout.deleteAddress.modalTitle": "Supprimer l'adresse de facturation par d\xe9faut\xa0?",
    "ecom.checkout.addAddress.toast": "Adresse ajout\xe9e",
    "ecom.checkout.deleteAddress.toast": "Adresse supprim\xe9e"
});
var de_DE = _object_spread_props(_object_spread({}, en_US), {
    "ecom.storeSide.signinLoggedIn.loginSuccess.toast": "Logout erfolgreich",
    "ecom.storeSide.signinLoggedIn.loginFailed.toast": "Logout fehlgeschlagen",
    "ecom.storeSide.signinLoggedIn.guest.pageTitle": "Gast",
    "ecom.storeSide.orderSum.sectionTitle": "Bestellung ansehen",
    "ecom.storeSide.orderSum.outlineCloseButton": "Ansicht der Bestellung schliessen",
    "ecom.productCard.sectionTitle": "Produkt\xfcbersicht",
    "ecom.storeSide.productDetails.basePrice.title": "Basispreis",
    "ecom.storeSide.productDetails.unitPrice.title": "Einzelpreis",
    "ecom.storeSide.duplicatedCoupon.toast": "Ups! Doppelter Coupon",
    "ecom.storeSide.shipping.pageDescription": "Lieferungsbeschreibung",
    "ecom.storeSide.review.termAndCondition.checkBoxError": "Allgemeine Nutzungsbedingungen",
    "ecom.storeSide.addressForm.shipAddress.sectionTitle": "Lieferadresse",
    "ecom.storeSide.addressForm.billAddress.sectionTitle": "Rechnungsadresse",
    "ecom.storeSide.addressForm.deleteAddress.modalTitle": "Sie sind dabei eine Adresse zu l\xf6schen",
    "ecom.storeSide.addressForm.deleteAddress.modalDescription": "Sie l\xf6schen hiermit eine Adresse aus Ihrem Adressbuch",
    "ecom.checkout.shippingMethodNotAvailable.errorAlert": "Unser Service ist in Ihrem Land nicht verf\xfcgbar.",
    "ecom.checkout.shippingMethodSelectedCountry.warningAlert": "Bitte w\xe4hlen Sie das Lieferland aus, um die verf\xfcgbaren Liefermethoden anzuzeigen.",
    "ecom.storeSide.payment.payMethodNotAdded.warningAlert": "Zahlungsmethode wurde noch nicht hinzugef\xfcgt. Bitte konfigurieren Sie dies in den Zahlungseinstellungen.",
    "ecom.storeSide.payment.paymentFailed.toast": "Bezahlung fehlgeschlagen",
    "ecom.storeSide.payment.paymentSuccess.toast": "Bezahlung erfolgreich",
    "ecom.storeSide.payment.pendingPayment.title": "Bezahlung ausstehend",
    "ecom.storeSide.payment.IdealPaymentWarning": "Bitte w\xe4hlen Sie eine Bank",
    "ecom.checkout.invalidAddress.toast": "Die Adresse ist ung\xfcltig. Bitte aktualisieren Sie das Bundesland, die Stadt oder die Postleitzahl f\xfcr eine Steuerberechnung.",
    "ecom.storeSide.shoppingCart.sectionTitle": "Mein Warenkorb",
    "ecom.storeSide.productCard.detail.ghostButton": "Details",
    "ecom.storeSide.productCard.itemUnavailable.inlineAlert": "Dieser Artikel ist nicht verf\xfcgbar; Bitte entfernen.",
    "ecom.storeSide.productCard.variantUnavailable.inlineAlert": "Eine ausgew\xe4hlte Variante ist nicht verf\xfcgbar; Bitte bearbeiten Sie Ihre Auswahl.",
    "ecom.storeSide.productCard.variantNotSelected.inlineAlert": "Bitte Varianten ausw\xe4hlen",
    "ecom.storeSide.paySum.sectionTitle": "Zahlungs\xfcbersicht",
    "ecom.storeSide.coupon.inputLabel": "Gutschein",
    "ecom.storeSide.coupon.inputPlaceholder": "Gutschein-Code eingeben",
    "ecom.storeSide.coupon.secondaryButton": "Best\xe4tigen",
    "ecom.storeSide.paySum.cartTotal.paragraph": "Warenkorb Summe",
    "ecom.storeSide.paySum.coupon.accordionTitle": "Gutschein-Code",
    "ecom.storeSide.paySum.promotion.accordionTitle": "Promotion",
    "ecom.storeSide.paySum.cartTotalExclTax.title": "Summe exkl. MwSt.",
    "ecom.storeSide.paySum.cartTotalExclTaxNoShipping.description": "MwSt. und Versandkosten berechnet an der Kasse",
    "ecom.storeSide.paySum.cartTotalInclTax.title": "Summe inkl. MwSt.",
    "ecom.storeSide.paySum.cartTotalInclTaxNoShipping.description": "inkl. 7% MwSt. Versand wird an der Kasse berechnet",
    "ecom.storeSide.refundPolicy.footerLink": "R\xfcckgaberecht",
    "ecom.storeSide.privatePolicy.footerLink": "Datenschutz-Bestimmungen",
    "ecom.storeSide.termAndCondition.footerLink": "Gesch\xe4ftsbedingungen",
    "ecom.storeSide.backToStorefront.link": "Mit dem Einkauf fortfahren",
    "ecom.storeSide.goToCheckout.primaryButton": "Jetzt bestellen",
    "ecom.storeSide.goToCheckout.securedPay.description": "Sichere Zahlungen durch [Payment Gateway]",
    "ecom.storeSide.couponExpired.alert": "Bitte entfernen Sie den abgelaufenen Gutscheincode",
    "ecom.storeSide.couponExpired.inlineAlert": "Gutscheincode abgelaufen",
    "ecom.storeSide.couponApplied.toast": "Gutschein best\xe4tigt. Gesamtpreis aktualisiert.",
    "ecom.storeSide.couponDeleted.toast": "Gutschein entfernt. Gesamtpreis aktualisiert.",
    "ecom.storeSide.invalidCoupon.inputError": "Ung\xfcltiger Gutscheincode.",
    "ecom.storeSide.productDetails.size.sectionTitle": "Gr\xf6\xdfe",
    "ecom.storeSide.productDetails.color.sectionTitle": "Farbe",
    "ecom.storeSide.productDetails.productPrice.sectionTitle": "Preisdetails",
    "ecom.storeSide.productDetails.productPrice.accordionTitle": "Produktpreis",
    "ecom.storeSide.productDetails.personalization.accordionTitle": "Personalisierung",
    "ecom.storeSide.deleteItem.confirmationModal": "Ausgew\xe4hlten Artikel vom Warenkorb entfernen?",
    "ecom.storeSide.signinLoggedOut.pageTitle1": "Jetzt bestellen",
    "ecom.storeSide.signinLoggedOut.asGuest.title2": "Als Gast fortfahren",
    "ecom.storeSide.signinLoggedOut.email.inputLabel": "Email",
    "ecom.storeSide.signinLoggedOut.email.inputPlaceholder": "Email eingeben",
    "ecom.storeSide.signinLoggedOut.email.inputHelper": "Wir verwenden diese nur, um Ihnen die Rechnung und Bestellbest\xe4tigung zuzusenden",
    "ecom.storeSide.signinLoggedOut.asGuest.primaryButton": "Als Gast fortfahren",
    "ecom.storeSide.signinLoggedOut.asMember.title2": "Melden Sie sich f\xfcr einen schnelleren Checkout an",
    "ecom.storeSide.signinLoggedOut.password.inputLabel": "Passwort",
    "ecom.storeSide.signinLoggedOut.password.inputPlaceholder": "Passwort eingeben",
    "ecom.storeSide.signinLoggedOut.forgetPassword.link": "Passwort vergessen?",
    "ecom.storeSide.signinLoggedOut.asMember.primaryButton": "Login",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaText": "Neuer Account?",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaLink": "Account hier erstellen",
    "ecom.storeSide.signinLoggedOut.back.link": "Zur\xfcck zum Warenkorb",
    "ecom.storeSide.signinLoggedIn.member.pageTitle": "Account",
    "ecom.storeSide.signinLoggedIn.continueAsMember.title2": "Weiter als",
    "ecom.storeSide.signinLoggedIn.nextStep.primaryButton": "Weiter",
    "ecom.storeSide.signinLoggedIn.signOut.link": "Nicht Ihr Account?",
    "ecom.storeSide.shipping.pageTitle": "Versand",
    "ecom.storeSide.shipping.shipMethod.sectionTitle": "Liefermethode",
    "ecom.storeSide.shipping.shipDescription.sectionTitle": "Lieferbeschreibung",
    "ecom.storeSide.shipping.nextToPayment.primaryButton": "Zur Bezahlung",
    "ecom.storeSide.shipping.back.link": "Zum Login",
    "ecom.storeSide.payment.pageTitle": "Bezahlung",
    "ecom.storeSide.payment.payMethod.sectionTitle": "Zahlungsmethode",
    "ecom.storeSide.payment.cardDetails.sectionTitle": "Kartendetails",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputLabel": "Kartennummer",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputPlaceholder": "Kartennummer eingeben",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputError": "Pflichtfeld",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputErrorValid": "Kartennummer ist ung\xfcltig",
    "ecom.storeSide.payment.cardDetails.expiry.inputLabel": "Verfallsdatum:",
    "ecom.storeSide.payment.cardDetails.expiry.inputPlaceholder": "MM / YY",
    "ecom.storeSide.payment.cardDetails.expiry.inputError": "Pflichtfeld",
    "ecom.storeSide.payment.cardDetails.cvc.inputLabel": "CVC:",
    "ecom.storeSide.payment.cardDetails.cvc.inputPlaceholder": "3 Ziffern",
    "ecom.storeSide.payment.cardDetails.cvc.inputError": "Pflichtfeld",
    "ecom.storeSide.payment.nextToReview.primaryButton": "Zur Zusammenfassung",
    "ecom.storeSide.payment.back.link": "Zur\xfcck zu Lieferung",
    "ecom.storeSide.review.pageTitle": "Review",
    "ecom.storeSide.review.contactInfo.guideText": "Kontaktinfo:",
    "ecom.storeSide.review.shipping.guideText": "Lieferung:",
    "ecom.storeSide.review.payment.guideText": "Bezahlung:",
    "ecom.storeSide.review.editDetails.link": "Bearbeiten",
    "ecom.storeSide.review.termAndCondition.checkBoxLabel": "Ich stimme den Allgemeinen Gesch\xe4ftsbedingungen und der Datenschutzerkl\xe4rung von [] zu.",
    "ecom.storeSide.addressForm.firstName.inputLabel": "Vorname",
    "ecom.storeSide.addressForm.firstName.inputPlaceholder": "Vorname eingeben",
    "ecom.storeSide.addressForm.lastName.inputLabel": "Nachname",
    "ecom.storeSide.addressForm.lastName.inputPlaceholder": "Nachname eingeben",
    "ecom.storeSide.addressForm.phone.inputLabel": "Kontaktnummer (optional)",
    "ecom.storeSide.addressForm.phone.inputPlaceholder": "Telefonnummer eingeben:",
    "ecom.storeSide.addressForm.address1.inputLabel": "Adresse",
    "ecom.storeSide.addressForm.address1.inputPlaceholder": "Stra\xdfe",
    "ecom.storeSide.addressForm.countryCode.inputLabel": "Land",
    "ecom.storeSide.addressForm.countryCode.inputPlaceholder": "Bitte ausw\xe4hlen",
    "ecom.storeSide.addressForm.provinceCode.inputLabel": "Bundesland",
    "ecom.storeSide.addressForm.provinceCode.inputPlaceholder": "Bitte ausw\xe4hlen",
    "ecom.storeSide.addressForm.city.inputLabel": "Stadt",
    "ecom.storeSide.addressForm.city.inputPlaceholder": "Bitte ausw\xe4hlen",
    "ecom.storeSide.addressForm.zip.inputLabel": "Postleitzahl",
    "ecom.storeSide.addressForm.zip.inputPlaceholder": "Postleitzahl eingeben",
    "ecom.storeSide.addressForm.shipAddress.accordionTitle": "Lieferadresse ausw\xe4hlen",
    "ecom.storeSide.addressForm.addNewAddress.secondaryButton": "Neue Adresse",
    "ecom.storeSide.addressForm.editAddress.link": "Bearbeiten",
    "ecom.storeSide.addressForm.cancelAddress.ghostButton": "Stornieren",
    "ecom.storeSide.addressForm.saveAddress.secondaryButton": "Speichern",
    "ecom.storeSide.addressForm.deleteAddress.ghostButton": "L\xf6schen",
    "ecom.storeSide.addressForm.sameAsShipping.checkBoxLabel": "Gleiche wie Lieferadresse",
    "ecom.storeSide.addressForm.billAddress.accordionTitle": "Rechnungsadresse ausw\xe4hlen",
    "ecom.storeSide.addressForm.requestInvoice.checkBoxLabel": "Steuerbeleg anfragen",
    "ecom.storeSide.addressForm.company.inputLabel": "Name der Firma",
    "ecom.storeSide.addressForm.company.inputPlaceholder": "Firmennamen eingeben",
    "ecom.storeSide.addressForm.vatNumber.inputLabel": "Steuernummer",
    "ecom.storeSide.addressForm.vatNumber.inputPlaceholder": "Name der Firma",
    "ecom.storeSide.addressForm.requiredField.inlineError": "Firmennamen eingeben",
    "ecom.checkout.deleteAddress.modalTitle": "Standardrechnungsaddresse l\xf6schen",
    "ecom.checkout.addAddress.toast": "Jetzt bestellen",
    "ecom.checkout.deleteAddress.toast": "Adresse gel\xf6scht"
});
var en_AU = _object_spread_props(_object_spread({}, en_US), {
    "ecom.storeSide.signinLoggedIn.loginSuccess.toast": "Logout successful",
    "ecom.storeSide.signinLoggedIn.loginFailed.toast": "Logout failed",
    "ecom.storeSide.signinLoggedIn.guest.pageTitle": "GUEST",
    "ecom.storeSide.orderSum.sectionTitle": "Open order summary",
    "ecom.storeSide.orderSum.outlineCloseButton": "Close order summary",
    "ecom.productCard.sectionTitle": "ITEM SUMMARY",
    "ecom.storeSide.productDetails.basePrice.title": "Base Price",
    "ecom.storeSide.productDetails.unitPrice.title": "Unit Price",
    "ecom.storeSide.duplicatedCoupon.toast": "Oops! Duplicated coupon",
    "ecom.storeSide.shipping.pageDescription": "Shipping page description",
    "ecom.storeSide.review.termAndCondition.checkBoxError": "Terms and conditions error",
    "ecom.storeSide.addressForm.shipAddress.sectionTitle": "Shipping Address",
    "ecom.storeSide.addressForm.billAddress.sectionTitle": "Billing Address",
    "ecom.storeSide.addressForm.deleteAddress.modalTitle": "You are deleting an address",
    "ecom.storeSide.addressForm.deleteAddress.modalDescription": "This will delete an address from your address book",
    "ecom.checkout.shippingMethodNotAvailable.errorAlert": "Our service is not available in your country, please change/edit the shipping address below.",
    "ecom.checkout.shippingMethodSelectedCountry.warningAlert": "Please select destination country to see available shipping methods",
    "ecom.storeSide.payment.payMethodNotAdded.warningAlert": "Payment method has not been added yet. Please configure this in payment settings.",
    "ecom.storeSide.payment.paymentFailed.toast": "Payment Failed",
    "ecom.storeSide.payment.paymentSuccess.toast": "Payment Successful",
    "ecom.storeSide.payment.pendingPayment.title": "Payment Pending",
    "ecom.storeSide.payment.IdealPaymentWarning": "Please select bank",
    "ecom.checkout.invalidAddress.toast": "The address is invalid. Please update state, city or zip code for a tax calculation.",
    "ecom.storeSide.shoppingCart.sectionTitle": "YOUR CART",
    "ecom.storeSide.productCard.detail.ghostButton": "Details",
    "ecom.storeSide.productCard.itemUnavailable.inlineAlert": "This item is unavailable; please remove it.",
    "ecom.storeSide.productCard.variantUnavailable.inlineAlert": "A selected variant is unavailable; please edit your selection.",
    "ecom.storeSide.productCard.variantNotSelected.inlineAlert": "Please select variants",
    "ecom.storeSide.paySum.sectionTitle": "PAYMENT SUMMARY",
    "ecom.storeSide.coupon.inputLabel": "Coupon",
    "ecom.storeSide.coupon.inputPlaceholder": "Enter coupon code",
    "ecom.storeSide.coupon.secondaryButton": "Apply",
    "ecom.storeSide.paySum.cartTotal.paragraph": "Cart Total",
    "ecom.storeSide.paySum.coupon.accordionTitle": "Coupon code",
    "ecom.storeSide.paySum.promotion.accordionTitle": "Promotion",
    "ecom.storeSide.paySum.cartTotalExclTax.title": "Total Excl. GST",
    "ecom.storeSide.paySum.cartTotalExclTaxNoShipping.description": "(GST & shipping calculated at checkout)",
    "ecom.storeSide.paySum.cartTotalInclTax.title": "Total Incl. GST",
    "ecom.storeSide.paySum.cartTotalInclTaxNoShipping.description": "GST included. Shipping calculated at checkout",
    "ecom.storeSide.refundPolicy.footerLink": "Refund Policy",
    "ecom.storeSide.privatePolicy.footerLink": "Privacy Policy",
    "ecom.storeSide.termAndCondition.footerLink": "Terms & Conditions",
    "ecom.storeSide.backToStorefront.link": "Continue shopping",
    "ecom.storeSide.goToCheckout.primaryButton": "Checkout",
    "ecom.storeSide.goToCheckout.securedPay.description": "Secure payments by [Payment Gateway]",
    "ecom.storeSide.couponExpired.alert": "Please delete expired coupon code",
    "ecom.storeSide.couponExpired.inlineAlert": "Expired coupon code",
    "ecom.storeSide.couponApplied.toast": "Coupon applied. Total price updated.",
    "ecom.storeSide.couponDeleted.toast": "Coupon deleted. Total price updated. ",
    "ecom.storeSide.invalidCoupon.inputError": "Invalid coupon code.",
    "ecom.storeSide.productDetails.size.sectionTitle": "Size",
    "ecom.storeSide.productDetails.color.sectionTitle": "Color",
    "ecom.storeSide.productDetails.productPrice.sectionTitle": "Price Details",
    "ecom.storeSide.productDetails.productPrice.accordionTitle": "Product Price",
    "ecom.storeSide.productDetails.personalization.accordionTitle": "Personalisation",
    "ecom.storeSide.deleteItem.confirmationModal": "Delete selected item from cart?",
    "ecom.storeSide.signinLoggedOut.pageTitle1": "CHECKOUT",
    "ecom.storeSide.signinLoggedOut.asGuest.title2": "Continue as Guest",
    "ecom.storeSide.signinLoggedOut.email.inputLabel": "Email",
    "ecom.storeSide.signinLoggedOut.email.inputPlaceholder": "Enter an email",
    "ecom.storeSide.signinLoggedOut.email.inputHelper": "We only use this to send you the receipt and order confirmation",
    "ecom.storeSide.signinLoggedOut.asGuest.primaryButton": "Continue as Guest",
    "ecom.storeSide.signinLoggedOut.asMember.title2": "Sign In for Faster Checkout",
    "ecom.storeSide.signinLoggedOut.password.inputLabel": "Password",
    "ecom.storeSide.signinLoggedOut.password.inputPlaceholder": "Enter a password",
    "ecom.storeSide.signinLoggedOut.forgetPassword.link": "Forget your password?",
    "ecom.storeSide.signinLoggedOut.asMember.primaryButton": "Sign in",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaText": "New member? ",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaLink": "Create an Account here",
    "ecom.storeSide.signinLoggedOut.back.link": "Return to cart",
    "ecom.storeSide.signinLoggedIn.member.pageTitle": "MEMBER",
    "ecom.storeSide.signinLoggedIn.continueAsMember.title2": "Continue as",
    "ecom.storeSide.signinLoggedIn.nextStep.primaryButton": "Continue ",
    "ecom.storeSide.signinLoggedIn.signOut.link": "(Not You?)",
    "ecom.storeSide.shipping.pageTitle": "SHIPPING",
    "ecom.storeSide.shipping.shipMethod.sectionTitle": "Shipping Method",
    "ecom.storeSide.shipping.shipDescription.sectionTitle": "Shipping Description",
    "ecom.storeSide.shipping.nextToPayment.primaryButton": "Next to Payment",
    "ecom.storeSide.shipping.back.link": "Return to Sign-in",
    "ecom.storeSide.payment.pageTitle": "PAYMENT",
    "ecom.storeSide.payment.payMethod.sectionTitle": "Payment Method",
    "ecom.storeSide.payment.cardDetails.sectionTitle": "Credit Card Details",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputLabel": "Card Number:",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputPlaceholder": "Enter card number",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputError": "This field is required",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputErrorValid": "Card number is invalid",
    "ecom.storeSide.payment.cardDetails.expiry.inputLabel": "Expiry:",
    "ecom.storeSide.payment.cardDetails.expiry.inputPlaceholder": "MM / YY",
    "ecom.storeSide.payment.cardDetails.expiry.inputError": "This field is required",
    "ecom.storeSide.payment.cardDetails.cvc.inputLabel": "CVC:",
    "ecom.storeSide.payment.cardDetails.cvc.inputPlaceholder": "3 digits",
    "ecom.storeSide.payment.cardDetails.cvc.inputError": "This field is required",
    "ecom.storeSide.payment.nextToReview.primaryButton": "Next to Review",
    "ecom.storeSide.payment.back.link": "Return to Shipping",
    "ecom.storeSide.review.pageTitle": "REVIEW",
    "ecom.storeSide.review.contactInfo.guideText": "Contact Information:",
    "ecom.storeSide.review.shipping.guideText": "Shipping:",
    "ecom.storeSide.review.payment.guideText": "Payment:",
    "ecom.storeSide.review.editDetails.link": "Edit",
    "ecom.storeSide.review.termAndCondition.checkBoxLabel": "I agree with the terms and conditions and the privacy policy of Brand",
    "ecom.storeSide.addressForm.firstName.inputLabel": "First Name",
    "ecom.storeSide.addressForm.firstName.inputPlaceholder": "Enter first name",
    "ecom.storeSide.addressForm.lastName.inputLabel": "Last Name",
    "ecom.storeSide.addressForm.lastName.inputPlaceholder": "Enter last name",
    "ecom.storeSide.addressForm.phone.inputLabel": "Contact Number (Optional)",
    "ecom.storeSide.addressForm.phone.inputPlaceholder": "Enter phone no.",
    "ecom.storeSide.addressForm.address1.inputLabel": "Address Line 1",
    "ecom.storeSide.addressForm.address1.inputPlaceholder": "Building number & street name",
    "ecom.storeSide.addressForm.countryCode.inputLabel": "Country",
    "ecom.storeSide.addressForm.countryCode.inputPlaceholder": "Please select",
    "ecom.storeSide.addressForm.provinceCode.inputLabel": "Province/State",
    "ecom.storeSide.addressForm.provinceCode.inputPlaceholder": "Please select",
    "ecom.storeSide.addressForm.city.inputLabel": "City",
    "ecom.storeSide.addressForm.city.inputPlaceholder": "Please select",
    "ecom.storeSide.addressForm.zip.inputLabel": "Postcode",
    "ecom.storeSide.addressForm.zip.inputPlaceholder": "Enter postcode",
    "ecom.storeSide.addressForm.shipAddress.accordionTitle": "Select shipping address",
    "ecom.storeSide.addressForm.addNewAddress.secondaryButton": "Add new address",
    "ecom.storeSide.addressForm.editAddress.link": "Edit",
    "ecom.storeSide.addressForm.cancelAddress.ghostButton": "Cancel",
    "ecom.storeSide.addressForm.saveAddress.secondaryButton": "Save",
    "ecom.storeSide.addressForm.deleteAddress.ghostButton": "Delete ",
    "ecom.storeSide.addressForm.sameAsShipping.checkBoxLabel": "Same as shipping address",
    "ecom.storeSide.addressForm.billAddress.accordionTitle": "Select billing address",
    "ecom.storeSide.addressForm.requestInvoice.checkBoxLabel": "Request tax invoice",
    "ecom.storeSide.addressForm.company.inputLabel": "Company Name",
    "ecom.storeSide.addressForm.company.inputPlaceholder": "Enter company name",
    "ecom.storeSide.addressForm.vatNumber.inputLabel": "TIN",
    "ecom.storeSide.addressForm.vatNumber.inputPlaceholder": "Enter TIN",
    "ecom.storeSide.addressForm.requiredField.inlineError": "This field is required",
    "ecom.checkout.shippingMethodUpdated.modalBody": "Your preferred shipping method is unavailable in [country]; please select another. ",
    "ecom.checkout.deleteAddress.modalTitle": "Delete default billing address?",
    "ecom.checkout.addAddress.toast": "Address added",
    "ecom.checkout.deleteAddress.toast": "Address deleted"
});
var pt_BR = _object_spread_props(_object_spread({}, en_US), {
    "ecom.storeSide.signinLoggedIn.loginSuccess.toast": "Logout bem-sucedido",
    "ecom.storeSide.signinLoggedIn.loginFailed.toast": "Falha ao sair",
    "ecom.storeSide.signinLoggedIn.guest.pageTitle": "CONVIDADO",
    "ecom.storeSide.orderSum.sectionTitle": "Resumo do pedido aberto",
    "ecom.storeSide.orderSum.outlineCloseButton": "Fechar resumo do pedido",
    "ecom.productCard.sectionTitle": "RESUMO DO ITENS",
    "ecom.storeSide.productDetails.basePrice.title": "Pre\xe7o base",
    "ecom.storeSide.productDetails.unitPrice.title": "Pre\xe7o unit\xe1rio",
    "ecom.storeSide.duplicatedCoupon.toast": "Ops! Cupom duplicado",
    "ecom.storeSide.shipping.pageDescription": "Descri\xe7\xe3o da p\xe1gina de envio",
    "ecom.storeSide.review.termAndCondition.checkBoxError": "Erro de termos e condi\xe7\xf5es",
    "ecom.storeSide.addressForm.shipAddress.sectionTitle": "Endere\xe7o de entrega",
    "ecom.storeSide.addressForm.billAddress.sectionTitle": "Endere\xe7o de Cobran\xe7a",
    "ecom.storeSide.addressForm.deleteAddress.modalTitle": "Voc\xea est\xe1 prestes a excluir um endere\xe7o",
    "ecom.storeSide.addressForm.deleteAddress.modalDescription": "Isso excluir\xe1 um endere\xe7o do seu cat\xe1logo de endere\xe7os",
    "ecom.checkout.shippingMethodNotAvailable.errorAlert": "Nosso servi\xe7o n\xe3o est\xe1 dispon\xedvel em seu pa\xeds, altere/edite o endere\xe7o de entrega abaixo.",
    "ecom.checkout.shippingMethodSelectedCountry.warningAlert": "Selecione o pa\xeds de destino para ver os m\xe9todos de envio dispon\xedveis",
    "ecom.storeSide.payment.payMethodNotAdded.warningAlert": "A forma de pagamento ainda n\xe3o foi adicionada. Por favor, configure isso nas configura\xe7\xf5es de pagamento.",
    "ecom.storeSide.payment.paymentFailed.toast": "Pagamento falhou",
    "ecom.storeSide.payment.paymentSuccess.toast": "Pagamento bem-sucedido",
    "ecom.storeSide.payment.pendingPayment.title": "Pagamento pendente",
    "ecom.storeSide.payment.IdealPaymentWarning": "Por favor selecione o banco",
    "ecom.checkout.invalidAddress.toast": "O endere\xe7o \xe9 inv\xe1lido. Atualize o estado, cidade ou CEP para um c\xe1lculo de imposto.",
    "ecom.storeSide.shoppingCart.sectionTitle": "Sacola",
    "ecom.storeSide.productCard.detail.ghostButton": "Detalhes",
    "ecom.storeSide.productCard.itemUnavailable.inlineAlert": "Este item n\xe3o est\xe1 dispon\xedvel; por favor, remova-o.",
    "ecom.storeSide.productCard.variantUnavailable.inlineAlert": "A escolha n\xe3o est\xe1 dispon\xedvel; por favor, altere sua sele\xe7\xe3o.",
    "ecom.storeSide.productCard.variantNotSelected.inlineAlert": "Fa\xe7a a sua escolha",
    "ecom.storeSide.paySum.sectionTitle": "Vis\xe3o geral do pagamento",
    "ecom.storeSide.coupon.inputLabel": "Cupom",
    "ecom.storeSide.coupon.inputPlaceholder": "Digite o c\xf3digo do cupom",
    "ecom.storeSide.coupon.secondaryButton": "Aplique",
    "ecom.storeSide.paySum.cartTotal.paragraph": "Total do carrinho",
    "ecom.storeSide.paySum.coupon.accordionTitle": "Cupom",
    "ecom.storeSide.paySum.promotion.accordionTitle": "Promo\xe7\xe3o",
    "ecom.storeSide.paySum.cartTotalExclTax.title": "Subtotal",
    "ecom.storeSide.paySum.cartTotalExclTaxNoShipping.description": "(Imposto e frete calculados na pr\xf3xima etapa)",
    "ecom.storeSide.paySum.cartTotalInclTax.title": "Total  ",
    "ecom.storeSide.paySum.cartTotalInclTaxNoShipping.description": "de imposto inclu\xeddo. Frete calculado na pr\xf3xma etapa)",
    "ecom.storeSide.refundPolicy.footerLink": "Politica de reembolso",
    "ecom.storeSide.privatePolicy.footerLink": "Pol\xedtica de Privacidade",
    "ecom.storeSide.termAndCondition.footerLink": "Termos e Condi\xe7\xf5es",
    "ecom.storeSide.backToStorefront.link": "Continue comprando",
    "ecom.storeSide.goToCheckout.primaryButton": "Final. Compra",
    "ecom.storeSide.couponExpired.alert": "Remova o c\xf3digo promocional expirado",
    "ecom.storeSide.couponExpired.inlineAlert": "C\xf3digo de cupom expirado",
    "ecom.storeSide.couponApplied.toast": "Cupom aplicado. Pre\xe7o total atualizado.",
    "ecom.storeSide.couponDeleted.toast": "Cupom Exclu\xeddo. Pre\xe7o total atualizado.",
    "ecom.storeSide.invalidCoupon.inputError": "C\xf3digo de cupom inv\xe1lido.",
    "ecom.storeSide.productDetails.size.sectionTitle": "Tamanho",
    "ecom.storeSide.productDetails.color.sectionTitle": "Cor",
    "ecom.storeSide.productDetails.productPrice.sectionTitle": "Detalhes do pre\xe7o",
    "ecom.storeSide.productDetails.productPrice.accordionTitle": "Pre\xe7o do produto",
    "ecom.storeSide.productDetails.personalization.accordionTitle": "Personaliza\xe7\xe3o",
    "ecom.storeSide.deleteItem.confirmationModal": "Excluir item selecionado do carrinho?",
    "ecom.storeSide.signinLoggedOut.pageTitle1": "Final. Compra",
    "ecom.storeSide.signinLoggedOut.asGuest.title2": "Continuar como convidado",
    "ecom.storeSide.signinLoggedOut.email.inputLabel": "E-mail",
    "ecom.storeSide.signinLoggedOut.email.inputPlaceholder": "Digite seu e-mail",
    "ecom.storeSide.signinLoggedOut.email.inputHelper": "Usamos apenas para enviar o recibo e a confirma\xe7\xe3o do pedido",
    "ecom.storeSide.signinLoggedOut.asGuest.primaryButton": "Continuar como convidado",
    "ecom.storeSide.signinLoggedOut.asMember.title2": "J\xe1 possui uma conta? Fazer login",
    "ecom.storeSide.signinLoggedOut.password.inputLabel": "Senha",
    "ecom.storeSide.signinLoggedOut.password.inputPlaceholder": "Insira uma senha",
    "ecom.storeSide.signinLoggedOut.forgetPassword.link": "Esqueceu sua senha?",
    "ecom.storeSide.signinLoggedOut.asMember.primaryButton": "Fazer login",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaText": "Novo membro?",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaLink": "Criar minha conta aqui",
    "ecom.storeSide.signinLoggedOut.back.link": "Retornar ao carrinho",
    "ecom.storeSide.signinLoggedIn.member.pageTitle": "Membro",
    "ecom.storeSide.signinLoggedIn.continueAsMember.title2": "Continuar como",
    "ecom.storeSide.signinLoggedIn.nextStep.primaryButton": "Continuar",
    "ecom.storeSide.signinLoggedIn.signOut.link": "N\xe3o \xe9 sua conta?",
    "ecom.storeSide.shipping.pageTitle": "Frete",
    "ecom.storeSide.shipping.shipMethod.sectionTitle": "M\xe9todo de envio",
    "ecom.storeSide.shipping.shipDescription.sectionTitle": "Descri\xe7\xe3o de envio",
    "ecom.storeSide.shipping.nextToPayment.primaryButton": "Siga para pagamento",
    "ecom.storeSide.shipping.back.link": "Retornar ao Login",
    "ecom.storeSide.payment.pageTitle": "Pagamento",
    "ecom.storeSide.payment.payMethod.sectionTitle": "Forma de pagamento",
    "ecom.storeSide.payment.cardDetails.sectionTitle": "Detalhes do cart\xe3o de cr\xe9dito",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputLabel": "N\xfamero do cart\xe3o:",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputPlaceholder": "Digite o n\xfamero do cart\xe3o",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputError": "Este campo \xe9 obrigat\xf3rio",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputErrorValid": "O n\xfamero do cart\xe3o \xe9 inv\xe1lido",
    "ecom.storeSide.payment.cardDetails.expiry.inputLabel": "Expira\xe7\xe3o:",
    "ecom.storeSide.payment.cardDetails.expiry.inputPlaceholder": "MM/AA",
    "ecom.storeSide.payment.cardDetails.expiry.inputError": "Este campo \xe9 obrigat\xf3rio",
    "ecom.storeSide.payment.cardDetails.cvc.inputLabel": "CVC:",
    "ecom.storeSide.payment.cardDetails.cvc.inputPlaceholder": "3 d\xedgitos",
    "ecom.storeSide.payment.cardDetails.cvc.inputError": "Este campo \xe9 obrigat\xf3rio",
    "ecom.storeSide.payment.nextToReview.primaryButton": "Revis\xe3o do pedido",
    "ecom.storeSide.payment.back.link": "Retornar a frete",
    "ecom.storeSide.review.pageTitle": "Revis\xe3o final",
    "ecom.storeSide.review.contactInfo.guideText": "Informa\xe7\xf5es de Contato:",
    "ecom.storeSide.review.shipping.guideText": "Frete:",
    "ecom.storeSide.review.payment.guideText": "Pagamento:",
    "ecom.storeSide.review.editDetails.link": "Editar",
    "ecom.storeSide.review.termAndCondition.checkBoxLabel": "Concordo com os termos e condi\xe7\xf5es e a pol\xedtica de privacidade da marca",
    "ecom.storeSide.addressForm.firstName.inputLabel": "Nome",
    "ecom.storeSide.addressForm.firstName.inputPlaceholder": "Insira o nome",
    "ecom.storeSide.addressForm.lastName.inputLabel": "Sobrenome",
    "ecom.storeSide.addressForm.lastName.inputPlaceholder": "Insira o sobrenome",
    "ecom.storeSide.addressForm.phone.inputLabel": "N\xfamero de contato (opcional):",
    "ecom.storeSide.addressForm.phone.inputPlaceholder": "Digite o n\xfamero do telefone",
    "ecom.storeSide.addressForm.address1.inputLabel": "Endere\xe7o Linha 1",
    "ecom.storeSide.addressForm.address1.inputPlaceholder": "N\xfamero do pr\xe9dio e nome da rua",
    "ecom.storeSide.addressForm.countryCode.inputLabel": "Pa\xeds",
    "ecom.storeSide.addressForm.countryCode.inputPlaceholder": "Por favor selecione",
    "ecom.storeSide.addressForm.provinceCode.inputLabel": "Prov\xedncia/Estado",
    "ecom.storeSide.addressForm.provinceCode.inputPlaceholder": "Por favor selecione",
    "ecom.storeSide.addressForm.city.inputLabel": "Cidade",
    "ecom.storeSide.addressForm.city.inputPlaceholder": "Por favor selecione",
    "ecom.storeSide.addressForm.zip.inputLabel": "C\xf3digo postal",
    "ecom.storeSide.addressForm.zip.inputPlaceholder": "Digite o c\xf3digo postal",
    "ecom.storeSide.addressForm.shipAddress.accordionTitle": "Selecione o endere\xe7o de entrega",
    "ecom.storeSide.addressForm.addNewAddress.secondaryButton": "Adicionar novo endere\xe7o",
    "ecom.storeSide.addressForm.editAddress.link": "Editar",
    "ecom.storeSide.addressForm.cancelAddress.ghostButton": "Cancelar",
    "ecom.storeSide.addressForm.saveAddress.secondaryButton": "Salve",
    "ecom.storeSide.addressForm.deleteAddress.ghostButton": "Excluir",
    "ecom.storeSide.addressForm.sameAsShipping.checkBoxLabel": "Igual ao endere\xe7o de entrega",
    "ecom.storeSide.addressForm.billAddress.accordionTitle": "Selecione o endere\xe7o de faturamento",
    "ecom.storeSide.addressForm.requestInvoice.checkBoxLabel": "Solicitar nota fiscal",
    "ecom.storeSide.addressForm.company.inputLabel": "Nome da empresa",
    "ecom.storeSide.addressForm.company.inputPlaceholder": "Digite o nome da empresa",
    "ecom.storeSide.addressForm.vatNumber.inputLabel": "CPF",
    "ecom.storeSide.addressForm.vatNumber.inputPlaceholder": "Insira o CPF",
    "ecom.storeSide.addressForm.requiredField.inlineError": "Este campo \xe9 obrigat\xf3rio",
    "ecom.checkout.deleteAddress.modalTitle": "Excluir endere\xe7o de cobran\xe7a padr\xe3o?",
    "ecom.checkout.addAddress.toast": "Endere\xe7o adicionado",
    "ecom.checkout.deleteAddress.toast": "Endere\xe7o exclu\xeddo"
});
var pt_PT = _object_spread_props(_object_spread({}, en_US), {
    "ecom.storeSide.signinLoggedIn.loginSuccess.toast": "Logout bem-sucedido",
    "ecom.storeSide.signinLoggedIn.loginFailed.toast": "Falha ao sair",
    "ecom.storeSide.signinLoggedIn.guest.pageTitle": "CONVIDADO",
    "ecom.storeSide.orderSum.sectionTitle": "Resumo do pedido aberto",
    "ecom.storeSide.orderSum.outlineCloseButton": "Fechar resumo do pedido",
    "ecom.productCard.sectionTitle": "RESUMO DO ITENS",
    "ecom.storeSide.productDetails.basePrice.title": "Pre\xe7o base",
    "ecom.storeSide.productDetails.unitPrice.title": "Pre\xe7o unit\xe1rio",
    "ecom.storeSide.duplicatedCoupon.toast": "Ops! Cupom duplicado",
    "ecom.storeSide.shipping.pageDescription": "Descri\xe7\xe3o da p\xe1gina de envio",
    "ecom.storeSide.review.termAndCondition.checkBoxError": "Erro de termos e condi\xe7\xf5es",
    "ecom.storeSide.addressForm.shipAddress.sectionTitle": "Endere\xe7o de entrega",
    "ecom.storeSide.addressForm.billAddress.sectionTitle": "Endere\xe7o de Cobran\xe7a",
    "ecom.storeSide.addressForm.deleteAddress.modalTitle": "Voc\xea est\xe1 prestes a excluir um endere\xe7o",
    "ecom.storeSide.addressForm.deleteAddress.modalDescription": "Isso excluir\xe1 um endere\xe7o do seu cat\xe1logo de endere\xe7os",
    "ecom.checkout.shippingMethodNotAvailable.errorAlert": "Nosso servi\xe7o n\xe3o est\xe1 dispon\xedvel em seu pa\xeds, altere/edite o endere\xe7o de entrega abaixo.",
    "ecom.checkout.shippingMethodSelectedCountry.warningAlert": "Selecione o pa\xeds de destino para ver os m\xe9todos de envio dispon\xedveis",
    "ecom.storeSide.payment.payMethodNotAdded.warningAlert": "A forma de pagamento ainda n\xe3o foi adicionada. Por favor, configure isso nas configura\xe7\xf5es de pagamento.",
    "ecom.storeSide.payment.paymentFailed.toast": "Pagamento falhou",
    "ecom.storeSide.payment.paymentSuccess.toast": "Pagamento bem-sucedido",
    "ecom.storeSide.payment.pendingPayment.title": "Pagamento pendente",
    "ecom.storeSide.payment.IdealPaymentWarning": "Por favor selecione o banco",
    "ecom.checkout.invalidAddress.toast": "O endere\xe7o \xe9 inv\xe1lido. Atualize o estado, cidade ou CEP para um c\xe1lculo de imposto.",
    "ecom.storeSide.shoppingCart.sectionTitle": "Sacola",
    "ecom.storeSide.productCard.detail.ghostButton": "Detalhes",
    "ecom.storeSide.productCard.itemUnavailable.inlineAlert": "Este item n\xe3o est\xe1 dispon\xedvel; por favor, remova-o.",
    "ecom.storeSide.productCard.variantUnavailable.inlineAlert": "A escolha n\xe3o est\xe1 dispon\xedvel; por favor, altere sua sele\xe7\xe3o.",
    "ecom.storeSide.productCard.variantNotSelected.inlineAlert": "Fa\xe7a a sua escolha",
    "ecom.storeSide.paySum.sectionTitle": "Vis\xe3o geral do pagamento",
    "ecom.storeSide.coupon.inputLabel": "Cupom",
    "ecom.storeSide.coupon.inputPlaceholder": "Digite o c\xf3digo do cupom",
    "ecom.storeSide.coupon.secondaryButton": "Aplique",
    "ecom.storeSide.paySum.cartTotal.paragraph": "Total do carrinho",
    "ecom.storeSide.paySum.coupon.accordionTitle": "Cupom",
    "ecom.storeSide.paySum.promotion.accordionTitle": "Promo\xe7\xe3o",
    "ecom.storeSide.paySum.cartTotalExclTax.title": "Subtotal",
    "ecom.storeSide.paySum.cartTotalExclTaxNoShipping.description": "(Imposto e frete calculados na pr\xf3xima etapa)",
    "ecom.storeSide.paySum.cartTotalInclTax.title": "Total  ",
    "ecom.storeSide.paySum.cartTotalInclTaxNoShipping.description": "de imposto inclu\xeddo. Frete calculado na pr\xf3xma etapa)",
    "ecom.storeSide.refundPolicy.footerLink": "Politica de reembolso",
    "ecom.storeSide.privatePolicy.footerLink": "Pol\xedtica de Privacidade",
    "ecom.storeSide.termAndCondition.footerLink": "Termos e Condi\xe7\xf5es",
    "ecom.storeSide.backToStorefront.link": "Continue comprando",
    "ecom.storeSide.goToCheckout.primaryButton": "Final. Compra",
    "ecom.storeSide.goToCheckout.securedPay.description": "Pagamentos garantidos por [Gateway de pagamento]",
    "ecom.storeSide.couponExpired.alert": "Remova o c\xf3digo promocional expirado",
    "ecom.storeSide.couponExpired.inlineAlert": "C\xf3digo de cupom expirado",
    "ecom.storeSide.couponApplied.toast": "Cupom aplicado. Pre\xe7o total atualizado.",
    "ecom.storeSide.couponDeleted.toast": "Cupom Exclu\xeddo. Pre\xe7o total atualizado.",
    "ecom.storeSide.invalidCoupon.inputError": "C\xf3digo de cupom inv\xe1lido.",
    "ecom.storeSide.productDetails.size.sectionTitle": "Tamanho",
    "ecom.storeSide.productDetails.color.sectionTitle": "Cor",
    "ecom.storeSide.productDetails.productPrice.sectionTitle": "Detalhes do pre\xe7o",
    "ecom.storeSide.productDetails.productPrice.accordionTitle": "Pre\xe7o do produto",
    "ecom.storeSide.productDetails.personalization.accordionTitle": "Personaliza\xe7\xe3o",
    "ecom.storeSide.deleteItem.confirmationModal": "Excluir item selecionado do carrinho?",
    "ecom.storeSide.signinLoggedOut.pageTitle1": "Final. Compra",
    "ecom.storeSide.signinLoggedOut.asGuest.title2": "Continuar como convidado",
    "ecom.storeSide.signinLoggedOut.email.inputLabel": "E-mail",
    "ecom.storeSide.signinLoggedOut.email.inputPlaceholder": "Digite seu e-mail",
    "ecom.storeSide.signinLoggedOut.email.inputHelper": "Usamos apenas para enviar o recibo e a confirma\xe7\xe3o do pedido",
    "ecom.storeSide.signinLoggedOut.asGuest.primaryButton": "Continuar como convidado",
    "ecom.storeSide.signinLoggedOut.asMember.title2": "J\xe1 possui uma conta? Fazer login",
    "ecom.storeSide.signinLoggedOut.password.inputLabel": "Senha",
    "ecom.storeSide.signinLoggedOut.password.inputPlaceholder": "Insira uma senha",
    "ecom.storeSide.signinLoggedOut.forgetPassword.link": "Esqueceu sua senha?",
    "ecom.storeSide.signinLoggedOut.asMember.primaryButton": "Fazer login",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaText": "Novo membro?",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaLink": "Criar minha conta aqui",
    "ecom.storeSide.signinLoggedOut.back.link": "Retornar ao carrinho",
    "ecom.storeSide.signinLoggedIn.member.pageTitle": "Membro",
    "ecom.storeSide.signinLoggedIn.continueAsMember.title2": "Continuar como",
    "ecom.storeSide.signinLoggedIn.nextStep.primaryButton": "Continuar",
    "ecom.storeSide.signinLoggedIn.signOut.link": "N\xe3o \xe9 sua conta?",
    "ecom.storeSide.shipping.pageTitle": "Frete",
    "ecom.storeSide.shipping.shipMethod.sectionTitle": "M\xe9todo de envio",
    "ecom.storeSide.shipping.shipDescription.sectionTitle": "Descri\xe7\xe3o de envio",
    "ecom.storeSide.shipping.nextToPayment.primaryButton": "Siga para pagamento",
    "ecom.storeSide.shipping.back.link": "Retornar ao Login",
    "ecom.storeSide.payment.pageTitle": "Pagamento",
    "ecom.storeSide.payment.payMethod.sectionTitle": "Forma de pagamento",
    "ecom.storeSide.payment.cardDetails.sectionTitle": "Detalhes do cart\xe3o de cr\xe9dito",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputLabel": "N\xfamero do cart\xe3o:",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputPlaceholder": "Digite o n\xfamero do cart\xe3o",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputError": "Este campo \xe9 obrigat\xf3rio",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputErrorValid": "O n\xfamero do cart\xe3o \xe9 inv\xe1lido",
    "ecom.storeSide.payment.cardDetails.expiry.inputLabel": "Expira\xe7\xe3o:",
    "ecom.storeSide.payment.cardDetails.expiry.inputPlaceholder": "MM/AA",
    "ecom.storeSide.payment.cardDetails.expiry.inputError": "Este campo \xe9 obrigat\xf3rio",
    "ecom.storeSide.payment.cardDetails.cvc.inputLabel": "CVC:",
    "ecom.storeSide.payment.cardDetails.cvc.inputPlaceholder": "3 d\xedgitos",
    "ecom.storeSide.payment.cardDetails.cvc.inputError": "Este campo \xe9 obrigat\xf3rio",
    "ecom.storeSide.payment.nextToReview.primaryButton": "Revis\xe3o do pedido",
    "ecom.storeSide.payment.back.link": "Retornar a frete",
    "ecom.storeSide.review.pageTitle": "Revis\xe3o final",
    "ecom.storeSide.review.contactInfo.guideText": "Informa\xe7\xf5es de Contato:",
    "ecom.storeSide.review.shipping.guideText": "Frete:",
    "ecom.storeSide.review.payment.guideText": "Pagamento:",
    "ecom.storeSide.review.editDetails.link": "Editar",
    "ecom.storeSide.review.termAndCondition.checkBoxLabel": "Concordo com os termos e condi\xe7\xf5es e a pol\xedtica de privacidade da marca",
    "ecom.storeSide.addressForm.firstName.inputLabel": "Nome",
    "ecom.storeSide.addressForm.firstName.inputPlaceholder": "Insira o nome",
    "ecom.storeSide.addressForm.lastName.inputLabel": "Sobrenome",
    "ecom.storeSide.addressForm.lastName.inputPlaceholder": "Insira o sobrenome",
    "ecom.storeSide.addressForm.phone.inputLabel": "N\xfamero de contato (opcional):",
    "ecom.storeSide.addressForm.phone.inputPlaceholder": "Digite o n\xfamero do telefone",
    "ecom.storeSide.addressForm.address1.inputLabel": "Endere\xe7o Linha 1",
    "ecom.storeSide.addressForm.address1.inputPlaceholder": "N\xfamero do pr\xe9dio e nome da rua",
    "ecom.storeSide.addressForm.countryCode.inputLabel": "Pa\xeds",
    "ecom.storeSide.addressForm.countryCode.inputPlaceholder": "Por favor selecione",
    "ecom.storeSide.addressForm.provinceCode.inputLabel": "Prov\xedncia/Estado",
    "ecom.storeSide.addressForm.provinceCode.inputPlaceholder": "Por favor selecione",
    "ecom.storeSide.addressForm.city.inputLabel": "Cidade",
    "ecom.storeSide.addressForm.city.inputPlaceholder": "Por favor selecione",
    "ecom.storeSide.addressForm.zip.inputLabel": "C\xf3digo postal",
    "ecom.storeSide.addressForm.zip.inputPlaceholder": "Digite o c\xf3digo postal",
    "ecom.storeSide.addressForm.shipAddress.accordionTitle": "Selecione o endere\xe7o de entrega",
    "ecom.storeSide.addressForm.addNewAddress.secondaryButton": "Adicionar novo endere\xe7o",
    "ecom.storeSide.addressForm.editAddress.link": "Editar",
    "ecom.storeSide.addressForm.cancelAddress.ghostButton": "Cancelar",
    "ecom.storeSide.addressForm.saveAddress.secondaryButton": "Save",
    "ecom.storeSide.addressForm.deleteAddress.ghostButton": "Excluir",
    "ecom.storeSide.addressForm.sameAsShipping.checkBoxLabel": "Igual ao endere\xe7o de entrega",
    "ecom.storeSide.addressForm.billAddress.accordionTitle": "Selecione o endere\xe7o de faturamento",
    "ecom.storeSide.addressForm.requestInvoice.checkBoxLabel": "Solicitar nota fiscal",
    "ecom.storeSide.addressForm.company.inputLabel": "Nome da empresa",
    "ecom.storeSide.addressForm.company.inputPlaceholder": "Digite o nome da empresa",
    "ecom.storeSide.addressForm.vatNumber.inputLabel": "CPF",
    "ecom.storeSide.addressForm.vatNumber.inputPlaceholder": "Insira o CPF",
    "ecom.storeSide.addressForm.requiredField.inlineError": "Este campo \xe9 obrigat\xf3rio",
    "ecom.checkout.deleteAddress.modalTitle": "Excluir endere\xe7o de cobran\xe7a padr\xe3o?",
    "ecom.checkout.addAddress.toast": "Endere\xe7o adicionado",
    "ecom.checkout.deleteAddress.toast": "Endere\xe7o exclu\xeddo"
});
var lv_LV = _object_spread_props(_object_spread({}, en_US), {
    "ecom.storeSide.signinLoggedIn.loginSuccess.toast": "Veiksmīga izrakstīšanās",
    "ecom.storeSide.signinLoggedIn.loginFailed.toast": "Neveiksmīga izrakstīšanās",
    "ecom.storeSide.signinLoggedIn.guest.pageTitle": "VIESIS",
    "ecom.storeSide.orderSum.sectionTitle": "Atvērt pasūtījuma kopsavilkumu",
    "ecom.storeSide.orderSum.outlineCloseButton": "Aizvērt pasūtījuma kopsavilkumu",
    "ecom.productCard.sectionTitle": "PREČU KOPSAVILKUMS",
    "ecom.storeSide.productDetails.basePrice.title": "Cena bez nodokļiem",
    "ecom.storeSide.productDetails.unitPrice.title": "Vienības cena",
    "ecom.storeSide.duplicatedCoupon.toast": "Ups! Dublēts kupons",
    "ecom.storeSide.shipping.pageDescription": "Piegādes lapas apraksts",
    "ecom.storeSide.review.termAndCondition.checkBoxError": "Notiekumu un nosacījumu kļūda",
    "ecom.storeSide.addressForm.shipAddress.sectionTitle": "Piegādes adrese",
    "ecom.storeSide.addressForm.billAddress.sectionTitle": "Norēķinu adrese",
    "ecom.storeSide.addressForm.deleteAddress.modalTitle": "Jūs gatavojaties dzēst adresi",
    "ecom.storeSide.addressForm.deleteAddress.modalDescription": "Adrese tiks izdzēsta no jūsu adrešu grāmatiņas",
    "ecom.checkout.shippingMethodNotAvailable.errorAlert": "Mūsu pakalpojumi nav pieejami jūsu valstī, lūdzu, mainiet/labojiet piegādes adresi.",
    "ecom.checkout.shippingMethodSelectedCountry.warningAlert": "Lūdzu, izvēlieties galamērķa valsti, lai redzētu pieejamos piegādes veidus",
    "ecom.storeSide.payment.payMethodNotAdded.warningAlert": "Nav pievienota maksājuma metode. Lūdzu konfigurējiet to maksājuma uzstādījumos.",
    "ecom.storeSide.payment.paymentFailed.toast": "Maksājums neizdevās",
    "ecom.storeSide.payment.paymentSuccess.toast": "Maksājums veiksmīgs",
    "ecom.storeSide.payment.pendingPayment.title": "Gaida maksājumu",
    "ecom.storeSide.payment.IdealPaymentWarning": "Lūdzu, izvēlieties banku",
    "ecom.checkout.invalidAddress.toast": "Adrese ir nederīga. Lūdzu, atjaunojiet valsti, pilsētu vai pasta indeksu, lai veiktu nodokļu aprēķinu.",
    "ecom.storeSide.shoppingCart.sectionTitle": "JŪSU GROZS",
    "ecom.storeSide.productCard.detail.ghostButton": "Detaļas",
    "ecom.storeSide.productCard.itemUnavailable.inlineAlert": "Šī prece nav pieejama; lūdzu, izdzēsiet to.",
    "ecom.storeSide.productCard.variantUnavailable.inlineAlert": "Atlasītais variants nav pieejams; lūdzu, rediģējiet savu izvēli.",
    "ecom.storeSide.productCard.variantNotSelected.inlineAlert": "Lūdzu, atlasiet variantus",
    "ecom.storeSide.paySum.sectionTitle": "MAKSĀJUMA KOPSAVILKUMS",
    "ecom.storeSide.coupon.inputLabel": "Kupons ",
    "ecom.storeSide.coupon.inputPlaceholder": "Ievadiet kupona kodu",
    "ecom.storeSide.coupon.secondaryButton": "Pielietot",
    "ecom.storeSide.paySum.cartTotal.paragraph": "Grozs kopā",
    "ecom.storeSide.paySum.coupon.accordionTitle": "Kupona kods",
    "ecom.storeSide.paySum.promotion.accordionTitle": "Akcija",
    "ecom.storeSide.paySum.cartTotalExclTax.title": "Kopā bez PVN",
    "ecom.storeSide.paySum.cartTotalExclTaxNoShipping.description": "(Nodokļi un piegādes izmaksas tiek aprēķinātas pie pasūtījuma apmaksas)",
    "ecom.storeSide.paySum.cartTotalInclTax.title": "Kopā ar PVN",
    "ecom.storeSide.paySum.cartTotalInclTaxNoShipping.description": "(Ieskaitot 7% nodokli. Piegādes izmaksas tiek aprēķinātas pie pasūtījuma apmaksas)",
    "ecom.storeSide.refundPolicy.footerLink": "Atgriešanas politika",
    "ecom.storeSide.privatePolicy.footerLink": "Privātuma politika",
    "ecom.storeSide.termAndCondition.footerLink": "VISPĀRĒJIE NOTEIKUMI",
    "ecom.storeSide.backToStorefront.link": "Turpināt iepirkties",
    "ecom.storeSide.goToCheckout.primaryButton": "Noformēt pasūtījumu",
    "ecom.storeSide.goToCheckout.securedPay.description": "Droši maksājumi, izmantojot",
    "ecom.storeSide.couponExpired.alert": "Lūdzu, izdzēsiet kupona kodu, kuram beidzies derīguma termiņš",
    "ecom.storeSide.couponExpired.inlineAlert": "Kupona kodam ir beidzies derīguma termiņš",
    "ecom.storeSide.couponApplied.toast": "Kupons pievienots. Kopējā cena atjaunināta.",
    "ecom.storeSide.couponDeleted.toast": "Kupons izdzēsts. Kopējā cena atjaunināta.",
    "ecom.storeSide.invalidCoupon.inputError": "Nederīgs kupona kods.",
    "ecom.storeSide.productDetails.size.sectionTitle": "Izmērs",
    "ecom.storeSide.productDetails.color.sectionTitle": "Krāsa",
    "ecom.storeSide.productDetails.productPrice.sectionTitle": "Cenas detaļas",
    "ecom.storeSide.productDetails.productPrice.accordionTitle": "Produkta cena",
    "ecom.storeSide.productDetails.personalization.accordionTitle": "Personifikācija",
    "ecom.storeSide.deleteItem.confirmationModal": "Dzēst izvēlēto preci no groza?",
    "ecom.storeSide.signinLoggedOut.pageTitle1": "Pasūtījuma noformēšana",
    "ecom.storeSide.signinLoggedOut.asGuest.title2": "Turpināt kā viesis",
    "ecom.storeSide.signinLoggedOut.email.inputLabel": "E-pasts",
    "ecom.storeSide.signinLoggedOut.email.inputPlaceholder": "Ievadiet e-pastu",
    "ecom.storeSide.signinLoggedOut.email.inputHelper": "Mēs to izmantosim tikai, lai nosūtītu jums rēķinu un pasūtījuma apstiprinājumu",
    "ecom.storeSide.signinLoggedOut.asGuest.primaryButton": "Turpināt kā viesis",
    "ecom.storeSide.signinLoggedOut.asMember.title2": "Pierakstieties, lai ātrāk norēķinātos",
    "ecom.storeSide.signinLoggedOut.password.inputLabel": "Parole",
    "ecom.storeSide.signinLoggedOut.password.inputPlaceholder": "Ievadiet paroli",
    "ecom.storeSide.signinLoggedOut.forgetPassword.link": "Aizmirsāt paroli?",
    "ecom.storeSide.signinLoggedOut.asMember.primaryButton": "Ielogoties",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaText": "Jauns lietotājs?",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaLink": "Izveidojiet jaunu kontu šeit",
    "ecom.storeSide.signinLoggedOut.back.link": "Atgriezties uz grozu",
    "ecom.storeSide.signinLoggedIn.member.pageTitle": "Lietotājs",
    "ecom.storeSide.signinLoggedIn.continueAsMember.title2": "Turpināt kā",
    "ecom.storeSide.signinLoggedIn.nextStep.primaryButton": "Turpināt",
    "ecom.storeSide.signinLoggedIn.signOut.link": "(Neesi tu?)",
    "ecom.storeSide.shipping.pageTitle": "PIEGĀDE",
    "ecom.storeSide.shipping.shipMethod.sectionTitle": "Piegādes metode",
    "ecom.storeSide.shipping.shipDescription.sectionTitle": "Piegādes apraksts",
    "ecom.storeSide.shipping.nextToPayment.primaryButton": "Blakus Maksājumam",
    "ecom.storeSide.shipping.back.link": "Atgriezieties pie pierakstīšanās",
    "ecom.storeSide.payment.pageTitle": "MAKSĀJUMS",
    "ecom.storeSide.payment.payMethod.sectionTitle": "Apmaksas veids",
    "ecom.storeSide.payment.cardDetails.sectionTitle": "Maksājumu kartes informācija",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputLabel": "Kartes numurs:",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputPlaceholder": "Ievadiet kartes numuru",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputError": "Šis lauks ir obligāts",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputErrorValid": "Kartes numurs nav derīgs",
    "ecom.storeSide.payment.cardDetails.expiry.inputLabel": "Derīguma termiņš:",
    "ecom.storeSide.payment.cardDetails.expiry.inputPlaceholder": "MM/GG",
    "ecom.storeSide.payment.cardDetails.expiry.inputError": "Šis lauks ir obligāts",
    "ecom.storeSide.payment.cardDetails.cvc.inputLabel": "CVC:",
    "ecom.storeSide.payment.cardDetails.cvc.inputPlaceholder": "3 cipari",
    "ecom.storeSide.payment.cardDetails.cvc.inputError": "Šis lauks ir obligāts",
    "ecom.storeSide.payment.nextToReview.primaryButton": "Blakus vienumam Pārskatīt",
    "ecom.storeSide.payment.back.link": "Atgriezties pie Piegādes",
    "ecom.storeSide.review.pageTitle": "Pārskatīt pasūtījumu",
    "ecom.storeSide.review.contactInfo.guideText": "Kontaktinformācija ",
    "ecom.storeSide.review.shipping.guideText": "Piegāde:",
    "ecom.storeSide.review.payment.guideText": "Maksājums:",
    "ecom.storeSide.review.editDetails.link": "Rediģēt",
    "ecom.storeSide.review.termAndCondition.checkBoxLabel": "Es piekrītu zīmola noteikumiem un privātuma politikai",
    "ecom.storeSide.addressForm.firstName.inputLabel": "Vārds",
    "ecom.storeSide.addressForm.firstName.inputPlaceholder": "Ievadiet vārdu",
    "ecom.storeSide.addressForm.lastName.inputLabel": "Uzvārds",
    "ecom.storeSide.addressForm.lastName.inputPlaceholder": "Ievadiet uzvārdu",
    "ecom.storeSide.addressForm.phone.inputLabel": "Kontakttālrunis (pēc izvēles):",
    "ecom.storeSide.addressForm.phone.inputPlaceholder": "Ievadiet tālruņa nr.",
    "ecom.storeSide.addressForm.address1.inputLabel": "Adreses 1.\xa0rinda",
    "ecom.storeSide.addressForm.address1.inputPlaceholder": "Ēkas numurs un ielas nosaukums",
    "ecom.storeSide.addressForm.countryCode.inputLabel": "Valsts",
    "ecom.storeSide.addressForm.countryCode.inputPlaceholder": "Lūdzu izvēlieties",
    "ecom.storeSide.addressForm.provinceCode.inputLabel": "Reģions",
    "ecom.storeSide.addressForm.provinceCode.inputPlaceholder": "Lūdzu izvēlieties",
    "ecom.storeSide.addressForm.city.inputLabel": "Pilsēta",
    "ecom.storeSide.addressForm.city.inputPlaceholder": "Lūdzu izvēlieties",
    "ecom.storeSide.addressForm.zip.inputLabel": "Pasta indekss",
    "ecom.storeSide.addressForm.zip.inputPlaceholder": "Ievadiet pasta indeksu",
    "ecom.storeSide.addressForm.shipAddress.accordionTitle": "Izvēlieties piegādes adresi",
    "ecom.storeSide.addressForm.addNewAddress.secondaryButton": "Pievienojiet jaunu adresi",
    "ecom.storeSide.addressForm.editAddress.link": "Rediģēt ",
    "ecom.storeSide.addressForm.cancelAddress.ghostButton": "Atcelt ",
    "ecom.storeSide.addressForm.saveAddress.secondaryButton": "Saglabāt ",
    "ecom.storeSide.addressForm.deleteAddress.ghostButton": "Dzēst",
    "ecom.storeSide.addressForm.sameAsShipping.checkBoxLabel": "Tāda pati kā piegādes adrese",
    "ecom.storeSide.addressForm.billAddress.accordionTitle": "Izvēlēties norēķinu adresi",
    "ecom.storeSide.addressForm.requestInvoice.checkBoxLabel": "Pieprasīt nodokļu rēķinu",
    "ecom.storeSide.addressForm.company.inputLabel": "Kompānijas nosaukums",
    "ecom.storeSide.addressForm.company.inputPlaceholder": "Ievadiet kompānijas nosaukumu",
    "ecom.storeSide.addressForm.vatNumber.inputLabel": "Nodokļu ID",
    "ecom.storeSide.addressForm.vatNumber.inputPlaceholder": "Ievadiet nodokļu ID",
    "ecom.storeSide.addressForm.requiredField.inlineError": "Šis lauks ir obligāts",
    "ecom.checkout.deleteAddress.modalTitle": "Dzēst noklusējuma norēķinu adresi?",
    "ecom.checkout.addAddress.toast": "Adrese pievienota",
    "ecom.checkout.deleteAddress.toast": "Adrese dzēsta"
});
var es_ES = _object_spread_props(_object_spread({}, en_US), {
    "ecom.storeSide.signinLoggedIn.loginSuccess.toast": "Cierre de sesi\xf3n exitoso",
    "ecom.storeSide.signinLoggedIn.loginFailed.toast": "Cierre de sesi\xf3n fallido",
    "ecom.storeSide.signinLoggedIn.guest.pageTitle": "INVITADO",
    "ecom.storeSide.orderSum.sectionTitle": "Resumen del Pedido",
    "ecom.storeSide.orderSum.outlineCloseButton": "Cerrar Pedido",
    "ecom.productCard.sectionTitle": "Resumen de Productos",
    "ecom.storeSide.productDetails.basePrice.title": "Precio base",
    "ecom.storeSide.productDetails.unitPrice.title": "Precio unitario",
    "ecom.storeSide.duplicatedCoupon.toast": "\xa1Ups! Cup\xf3n duplicado",
    "ecom.storeSide.shipping.pageDescription": "Descripci\xf3n de la p\xe1gina de env\xedo",
    "ecom.storeSide.review.termAndCondition.checkBoxError": "Error de t\xe9rminos y condiciones",
    "ecom.storeSide.addressForm.shipAddress.sectionTitle": "Direcci\xf3n de env\xedo",
    "ecom.storeSide.addressForm.billAddress.sectionTitle": "Direcci\xf3n de Env\xedo",
    "ecom.storeSide.addressForm.deleteAddress.modalTitle": "Est\xe1s a punto de eliminar una direcci\xf3n",
    "ecom.storeSide.addressForm.deleteAddress.modalDescription": "Esto eliminar\xe1 una direcci\xf3n de tu libreta de direcciones",
    "ecom.checkout.shippingMethodNotAvailable.errorAlert": "Nuestro servicio no est\xe1 disponible en tu pa\xeds, cambia/edita la direcci\xf3n de env\xedo a continuaci\xf3n.",
    "ecom.checkout.shippingMethodSelectedCountry.warningAlert": "Selecciona el pa\xeds de destino para ver los m\xe9todos de env\xedo disponibles",
    "ecom.storeSide.payment.payMethodNotAdded.warningAlert": "A\xfan no se ha agregado el m\xe9todo de pago. Agr\xe9galo en la configuraci\xf3n de pago.",
    "ecom.storeSide.payment.paymentFailed.toast": "Pago fallido",
    "ecom.storeSide.payment.paymentSuccess.toast": "Pago exitoso",
    "ecom.storeSide.payment.pendingPayment.title": "Pago pendiente",
    "ecom.storeSide.payment.IdealPaymentWarning": "Por favor selecciona un banco",
    "ecom.checkout.invalidAddress.toast": "La direcci\xf3n no es v\xe1lida. Actualice el estado, la ciudad o el c\xf3digo postal para calcular los impuestos.",
    "ecom.storeSide.shoppingCart.sectionTitle": "Mi cesta",
    "ecom.storeSide.productCard.detail.ghostButton": "Detalles",
    "ecom.storeSide.productCard.itemUnavailable.inlineAlert": "Este art\xedculo no est\xe1 disponible; favor de retirarlo.",
    "ecom.storeSide.productCard.variantUnavailable.inlineAlert": "La opci\xf3n seleccionada no est\xe1 disponible. Ajusta tu elecci\xf3n.",
    "ecom.storeSide.productCard.variantNotSelected.inlineAlert": "Selecciona una opci\xf3n",
    "ecom.storeSide.paySum.sectionTitle": "Resumen de pago",
    "ecom.storeSide.coupon.inputLabel": "C\xf3digo de descuento",
    "ecom.storeSide.coupon.inputPlaceholder": "Ingresa tu c\xf3digo de descuento",
    "ecom.storeSide.coupon.secondaryButton": "Usar",
    "ecom.storeSide.paySum.cartTotal.paragraph": "Cesta de la compra total",
    "ecom.storeSide.paySum.coupon.accordionTitle": "C\xf3digo de descuento",
    "ecom.storeSide.paySum.promotion.accordionTitle": "Promoci\xf3n",
    "ecom.storeSide.paySum.cartTotalExclTax.title": "Subtotal",
    "ecom.storeSide.paySum.cartTotalExclTaxNoShipping.description": "Los impuestos y gastos de env\xedo se calcula al momento de pagar.",
    "ecom.storeSide.paySum.cartTotalInclTax.title": "Total",
    "ecom.storeSide.paySum.cartTotalInclTaxNoShipping.description": "(Incluye 7% de impuestos. Costo de env\xedo calculado al finalizar la compra).",
    "ecom.storeSide.refundPolicy.footerLink": "Politica de reembolso",
    "ecom.storeSide.privatePolicy.footerLink": "Pol\xedtica de Privacidad",
    "ecom.storeSide.termAndCondition.footerLink": "T\xe9rminos y condiciones",
    "ecom.storeSide.backToStorefront.link": "Continuar comprando",
    "ecom.storeSide.goToCheckout.primaryButton": "Finalizar compra",
    "ecom.storeSide.goToCheckout.securedPay.description": "Pagos seguros con [Pasarela de pago]",
    "ecom.storeSide.couponExpired.alert": "Elimina el c\xf3digo de descuento caducado",
    "ecom.storeSide.couponExpired.inlineAlert": "C\xf3digo de descuento expirado",
    "ecom.storeSide.couponApplied.toast": "C\xf3digo de descuento aplicado. Precio actualizado.",
    "ecom.storeSide.couponDeleted.toast": "C\xf3digo de descuento eliminado. Precio actualizado.",
    "ecom.storeSide.invalidCoupon.inputError": " C\xf3digo de descuento inv\xe1lido.",
    "ecom.storeSide.productDetails.size.sectionTitle": "Talla",
    "ecom.storeSide.productDetails.color.sectionTitle": "Color",
    "ecom.storeSide.productDetails.productPrice.sectionTitle": "Detalle del precio",
    "ecom.storeSide.productDetails.productPrice.accordionTitle": "Precio del producto",
    "ecom.storeSide.productDetails.personalization.accordionTitle": "Personalizaci\xf3n",
    "ecom.storeSide.deleteItem.confirmationModal": "\xbfEliminar el art\xedculo seleccionado de la cesta?",
    "ecom.storeSide.signinLoggedOut.pageTitle1": "Finalizar compra",
    "ecom.storeSide.signinLoggedOut.asGuest.title2": "Continuar como invitado",
    "ecom.storeSide.signinLoggedOut.email.inputLabel": "Correo electr\xf3nico",
    "ecom.storeSide.signinLoggedOut.email.inputPlaceholder": "Ingresa tu correo electr\xf3nico",
    "ecom.storeSide.signinLoggedOut.email.inputHelper": "Solo lo usamos para enviarle su recibo y  confirmaci\xf3n del pedido.",
    "ecom.storeSide.signinLoggedOut.asGuest.primaryButton": "Continuar como invitado",
    "ecom.storeSide.signinLoggedOut.asMember.title2": "Conectarse",
    "ecom.storeSide.signinLoggedOut.password.inputLabel": "Contrase\xf1a",
    "ecom.storeSide.signinLoggedOut.password.inputPlaceholder": "Ingresa tu contrase\xf1a",
    "ecom.storeSide.signinLoggedOut.forgetPassword.link": "\xbfOlvid\xf3 su contrase\xf1a?",
    "ecom.storeSide.signinLoggedOut.asMember.primaryButton": "Conectarse",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaText": "Registrarme",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaLink": "Crear una cuenta aqu\xed",
    "ecom.storeSide.signinLoggedOut.back.link": "Regresar a la cesta",
    "ecom.storeSide.signinLoggedIn.member.pageTitle": "Miembro",
    "ecom.storeSide.signinLoggedIn.continueAsMember.title2": "Continuar como",
    "ecom.storeSide.signinLoggedIn.nextStep.primaryButton": "Continuar",
    "ecom.storeSide.signinLoggedIn.signOut.link": "(\xbfNo eres t\xfa?)",
    "ecom.storeSide.shipping.pageTitle": "Env\xedo",
    "ecom.storeSide.shipping.shipMethod.sectionTitle": "M\xe9todo de env\xedo",
    "ecom.storeSide.shipping.shipDescription.sectionTitle": "Descripci\xf3n del m\xe9todo de env\xedo",
    "ecom.storeSide.shipping.nextToPayment.primaryButton": "Seguir a Pago",
    "ecom.storeSide.shipping.back.link": "Regresar a Conexi\xf3n",
    "ecom.storeSide.payment.pageTitle": "Pago",
    "ecom.storeSide.payment.payMethod.sectionTitle": "Forma de pago",
    "ecom.storeSide.payment.cardDetails.sectionTitle": "Detalles de la Tarjeta de Cr\xe9dito",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputLabel": "N\xfamero de la Tarjeta de Cr\xe9dito",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputPlaceholder": "Ingresa el n\xfamero de la tarjeta",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputError": "Este campo es obligatorio",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputErrorValid": "N\xfamero de tarjeta inv\xe1lido",
    "ecom.storeSide.payment.cardDetails.expiry.inputLabel": "Fecha de expiraci\xf3n",
    "ecom.storeSide.payment.cardDetails.expiry.inputPlaceholder": "MM/AA",
    "ecom.storeSide.payment.cardDetails.expiry.inputError": "Este campo es obligatorio",
    "ecom.storeSide.payment.cardDetails.cvc.inputLabel": "CVC:",
    "ecom.storeSide.payment.cardDetails.cvc.inputPlaceholder": "3 d\xedgitos",
    "ecom.storeSide.payment.cardDetails.cvc.inputError": "Este campo es obligatorio",
    "ecom.storeSide.payment.nextToReview.primaryButton": "Seguir a Revisi\xf3n de la compra",
    "ecom.storeSide.payment.back.link": "Regresar a Env\xedo",
    "ecom.storeSide.review.pageTitle": "Revisar",
    "ecom.storeSide.review.contactInfo.guideText": "Informaci\xf3n de Contacto:",
    "ecom.storeSide.review.shipping.guideText": "Env\xedo:",
    "ecom.storeSide.review.payment.guideText": "Pago:",
    "ecom.storeSide.review.editDetails.link": "Editar",
    "ecom.storeSide.review.termAndCondition.checkBoxLabel": "Acepto los t\xe9rminos y condiciones y la pol\xedtica de privacidad de la marca",
    "ecom.storeSide.addressForm.firstName.inputLabel": "Nombre",
    "ecom.storeSide.addressForm.firstName.inputPlaceholder": "Ingresa tu nombre",
    "ecom.storeSide.addressForm.lastName.inputLabel": "Apellido(s)",
    "ecom.storeSide.addressForm.lastName.inputPlaceholder": "Ingresa tus apellidos",
    "ecom.storeSide.addressForm.phone.inputLabel": "N\xfamero de contacto (Opcional)",
    "ecom.storeSide.addressForm.phone.inputPlaceholder": "Ingresa n\xfamero telef\xf3nico",
    "ecom.storeSide.addressForm.address1.inputLabel": "Direcci\xf3n L\xednea 1",
    "ecom.storeSide.addressForm.address1.inputPlaceholder": "Calle y N\xfamero",
    "ecom.storeSide.addressForm.countryCode.inputLabel": "Pa\xeds",
    "ecom.storeSide.addressForm.countryCode.inputPlaceholder": "Selecciona",
    "ecom.storeSide.addressForm.provinceCode.inputLabel": "Estado",
    "ecom.storeSide.addressForm.provinceCode.inputPlaceholder": "Selecciona",
    "ecom.storeSide.addressForm.city.inputLabel": "Ciudad",
    "ecom.storeSide.addressForm.city.inputPlaceholder": "Selecciona",
    "ecom.storeSide.addressForm.zip.inputLabel": "C\xf3digo Postal",
    "ecom.storeSide.addressForm.zip.inputPlaceholder": "Ingresa c\xf3digo postal",
    "ecom.storeSide.addressForm.shipAddress.accordionTitle": "Selecciona direcci\xf3n de env\xedo",
    "ecom.storeSide.addressForm.addNewAddress.secondaryButton": "Agregar nueva direcci\xf3n",
    "ecom.storeSide.addressForm.editAddress.link": "Editar",
    "ecom.storeSide.addressForm.cancelAddress.ghostButton": "Cancelar",
    "ecom.storeSide.addressForm.saveAddress.secondaryButton": "Guardar",
    "ecom.storeSide.addressForm.deleteAddress.ghostButton": "Eliminar",
    "ecom.storeSide.addressForm.sameAsShipping.checkBoxLabel": "Igual que la direccion de env\xedo",
    "ecom.storeSide.addressForm.billAddress.accordionTitle": "Selecciona direcci\xf3n de facturaci\xf3n",
    "ecom.storeSide.addressForm.requestInvoice.checkBoxLabel": "Solicitar factura fiscal",
    "ecom.storeSide.addressForm.company.inputLabel": "Nombre de la empresa",
    "ecom.storeSide.addressForm.company.inputPlaceholder": "Ingresa nombre de la empresa",
    "ecom.storeSide.addressForm.vatNumber.inputLabel": "RFC",
    "ecom.storeSide.addressForm.vatNumber.inputPlaceholder": "Ingresa RFC",
    "ecom.storeSide.addressForm.requiredField.inlineError": "Este campo es obligatorio",
    "ecom.checkout.deleteAddress.modalTitle": "\xbfEliminar la direcci\xf3n de facturaci\xf3n predeterminada?",
    "ecom.checkout.addAddress.toast": "Direcci\xf3n agregada",
    "ecom.checkout.deleteAddress.toast": "Direcci\xf3n eliminada"
});
var th_TH = _object_spread_props(_object_spread({}, en_US), {
    "ecom.storeSide.signinLoggedIn.loginSuccess.toast": "ออกจากระบบเรียบร้อย",
    "ecom.storeSide.signinLoggedIn.loginFailed.toast": "ออกจากระบบผิดพลาด",
    "ecom.storeSide.signinLoggedIn.guest.pageTitle": "ผู้เยี่ยมชม",
    "ecom.storeSide.orderSum.sectionTitle": "สรุปข้อมูลก่อนยืนยันการซื้อ",
    "ecom.storeSide.orderSum.outlineCloseButton": "สรุปข้อมูลการซื้อ",
    "ecom.productCard.sectionTitle": "สรุปข้อมูลสินค้า",
    "ecom.storeSide.productDetails.basePrice.title": "ราคาสินค้า",
    "ecom.storeSide.productDetails.unitPrice.title": "ราคาต่อหน่วย",
    "ecom.storeSide.duplicatedCoupon.toast": "อุ๊ปส์ พบคูปองซ้ำ",
    "ecom.storeSide.shipping.pageDescription": "รายอะเอียดการจัดส่ง",
    "ecom.storeSide.review.termAndCondition.checkBoxError": "ข้อกำหนดและเงื่อนไขผิดพลาด",
    "ecom.storeSide.addressForm.shipAddress.sectionTitle": "ที่อยู่ในการจัดส่ง",
    "ecom.storeSide.addressForm.billAddress.sectionTitle": "ที่อยู่สำหรับแจ้งหนี้",
    "ecom.storeSide.addressForm.deleteAddress.modalTitle": "ข้อมูลที่อยู่กำลังถูกลบ",
    "ecom.storeSide.addressForm.deleteAddress.modalDescription": "ที่อยู่นี้จะถูกลบออกจากบัญชีที่อยู่ของท่าน",
    "ecom.checkout.shippingMethodNotAvailable.errorAlert": "ไม่สามารถให้บริการในประเทศที่เลือก กรุณาแก้ไขที่อยู่ในการจัดส่ง",
    "ecom.checkout.shippingMethodSelectedCountry.warningAlert": "กรุณาเลือกประเทศปลายทางเพื่อเรียกดูประเภทของการจัดส่ง",
    "ecom.storeSide.payment.payMethodNotAdded.warningAlert": "ช่องทางการชำระเงินยังไม่ได้ถูกตั้งค่า กรุณาตั้งค่าเพื่อดำเนินการ",
    "ecom.storeSide.payment.paymentFailed.toast": "การชำระเงินเกิดข้อผิดพลาด",
    "ecom.storeSide.payment.paymentSuccess.toast": "การชำระเงินสำเร็จเรียบร้อย",
    "ecom.storeSide.payment.pendingPayment.title": "รอชำระเงิน",
    "ecom.storeSide.payment.IdealPaymentWarning": "กรุณาเลือกธนาคาร",
    "ecom.checkout.invalidAddress.toast": "ข้อมูลที่อยู่ไม่ถูกต้อง กรุณากรอกข้อมูลจังหวัดหรือรหัสไปรษณีย์เพื่อคำนวณภาษี",
    "ecom.storeSide.shoppingCart.sectionTitle": "ตะกร้าของคุณ",
    "ecom.storeSide.productCard.detail.ghostButton": "รายละเอียด",
    "ecom.storeSide.productCard.itemUnavailable.inlineAlert": "ไม่พบข้อมูล กรุณาเลือกใหม่",
    "ecom.storeSide.productCard.variantUnavailable.inlineAlert": "ไม่พบข้อมูลของตัวเลือก กรุณาเลือกตัวเลือกอื่นๆ ",
    "ecom.storeSide.productCard.variantNotSelected.inlineAlert": "กรุณาเลือกตัวเลือก",
    "ecom.storeSide.paySum.sectionTitle": "สรุปยอดชำระ",
    "ecom.storeSide.coupon.inputLabel": "บัตรส่วนลด",
    "ecom.storeSide.coupon.inputPlaceholder": "ใส่รหัสบัตรส่วนลด",
    "ecom.storeSide.coupon.secondaryButton": "ใช้",
    "ecom.storeSide.paySum.cartTotal.paragraph": "จำนวนรวมทั้งสิ้น",
    "ecom.storeSide.paySum.coupon.accordionTitle": "รหัสบัตรส่วนลด",
    "ecom.storeSide.paySum.promotion.accordionTitle": "โปรโมชั่น",
    "ecom.storeSide.paySum.cartTotalExclTax.title": "ยอดรวม ไม่รวมภาษี",
    "ecom.storeSide.paySum.cartTotalExclTaxNoShipping.description": "ค่าจัดส่งและภาษีคำนวณในหน้าสิ้นสุดการทำรายการ",
    "ecom.storeSide.paySum.cartTotalInclTax.title": "ยอดรวม รวมภาษี",
    "ecom.storeSide.paySum.cartTotalInclTaxNoShipping.description": "ค่าจัดส่งและภาษี 7% คำนวณในหน้าสิ้นสุดการทำรายการ",
    "ecom.storeSide.refundPolicy.footerLink": "นโยบายการคืนเงิน",
    "ecom.storeSide.privatePolicy.footerLink": "นโยบายความเป็นส่วนตัว",
    "ecom.storeSide.termAndCondition.footerLink": "ข้อกำหนดและเงื่อนไข",
    "ecom.storeSide.backToStorefront.link": "ช้อปปิ้งต่อไป",
    "ecom.storeSide.goToCheckout.primaryButton": "สิ้นสุดการทำรายการ",
    "ecom.storeSide.goToCheckout.securedPay.description": "ชำระเงินอย่างปลอดภัยโดย ",
    "ecom.storeSide.couponExpired.alert": "กรุณาลบรหัสบัตรส่วนลดที่หมดอายุ",
    "ecom.storeSide.couponExpired.inlineAlert": "รหัสบัตรส่วนลดหมดอายุ",
    "ecom.storeSide.couponApplied.toast": "บัตรส่วนลดได้ถูกนำมาใช้ ยอดรวมล่าสุด",
    "ecom.storeSide.couponDeleted.toast": "บัตรส่วนลดได้ถูกลบออก ยอดรวมล่าสุด",
    "ecom.storeSide.invalidCoupon.inputError": "รหัสบัตรส่วนลดไม่ถูกต้อง",
    "ecom.storeSide.productDetails.size.sectionTitle": "ขนาด",
    "ecom.storeSide.productDetails.color.sectionTitle": "สี",
    "ecom.storeSide.productDetails.productPrice.sectionTitle": "รายละเอียดราคา",
    "ecom.storeSide.productDetails.productPrice.accordionTitle": "ราคาผลิตภัณฑ์",
    "ecom.storeSide.productDetails.personalization.accordionTitle": "การทำให้เป็นส่วนตัว",
    "ecom.storeSide.deleteItem.confirmationModal": "ลบตัวเลือกนี้ออกจากตะกร้า?",
    "ecom.storeSide.signinLoggedOut.pageTitle1": "สิ้นสุดการทำรายการ",
    "ecom.storeSide.signinLoggedOut.asGuest.title2": "เข้าชมในฐานะผู้เข้าชม",
    "ecom.storeSide.signinLoggedOut.email.inputLabel": "อีเมล",
    "ecom.storeSide.signinLoggedOut.email.inputPlaceholder": "ใส่อีเมล",
    "ecom.storeSide.signinLoggedOut.email.inputHelper": "นำมาใช้เพื่อส่งยืนยันการทำรายการและใบเสร็จเพียงเท่านั้น",
    "ecom.storeSide.signinLoggedOut.asGuest.primaryButton": "เข้าชมในฐานะผู้เข้าชม",
    "ecom.storeSide.signinLoggedOut.asMember.title2": "เข้าสู่ระบบเพื่อเพิ่มความรวดเร็วในการทำรายการ",
    "ecom.storeSide.signinLoggedOut.password.inputLabel": "รหัสผ่าน",
    "ecom.storeSide.signinLoggedOut.password.inputPlaceholder": "ใส่รหัสผ่าน",
    "ecom.storeSide.signinLoggedOut.forgetPassword.link": "ลืมรหัสผ่าน?",
    "ecom.storeSide.signinLoggedOut.asMember.primaryButton": "เข้าสู่ระบบ",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaText": "สมาชิกใหม่?",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaLink": "ลงทะเบียนที่นี่",
    "ecom.storeSide.signinLoggedOut.back.link": "กลับสู่หน้าตะกร้าสินค้า",
    "ecom.storeSide.signinLoggedIn.member.pageTitle": "สมาชิก",
    "ecom.storeSide.signinLoggedIn.continueAsMember.title2": "เข้าชมในฐานะสมาชิก คุณ",
    "ecom.storeSide.signinLoggedIn.nextStep.primaryButton": "ดำเนินการต่อ",
    "ecom.storeSide.signinLoggedIn.signOut.link": "(ไม่ใช่คุณ?)",
    "ecom.storeSide.shipping.pageTitle": "การส่งสินค้า",
    "ecom.storeSide.shipping.shipMethod.sectionTitle": "วิธีการจัดส่งสินค้า",
    "ecom.storeSide.shipping.shipDescription.sectionTitle": "รายละเอียดการส่งสินค้า",
    "ecom.storeSide.shipping.nextToPayment.primaryButton": "ไปยังหน้าการชำระเงิน",
    "ecom.storeSide.shipping.back.link": "กลับสู่หน้าเข้าสู่ระบบ",
    "ecom.storeSide.payment.pageTitle": "การชำระเงิน",
    "ecom.storeSide.payment.payMethod.sectionTitle": "วิธีการชำระเงิน",
    "ecom.storeSide.payment.cardDetails.sectionTitle": "รายละเอียดบัตรเครดิต",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputLabel": "หมายเลขบัตร",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputPlaceholder": "ป้อนหมายเลขบัตร",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputError": "ช่องบังคับกรอก",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputErrorValid": "หมายเลขบัตรไม่ถูกต้อง",
    "ecom.storeSide.payment.cardDetails.expiry.inputLabel": "วันหมดอายุ",
    "ecom.storeSide.payment.cardDetails.expiry.inputPlaceholder": "ดด/ปป",
    "ecom.storeSide.payment.cardDetails.expiry.inputError": "ช่องบังคับกรอก",
    "ecom.storeSide.payment.cardDetails.cvc.inputLabel": "CVC:",
    "ecom.storeSide.payment.cardDetails.cvc.inputPlaceholder": "เลขสามหลัก",
    "ecom.storeSide.payment.cardDetails.cvc.inputError": "ช่องบังคับกรอก",
    "ecom.storeSide.payment.nextToReview.primaryButton": "ไปยังหน้าตรวจสอบ",
    "ecom.storeSide.payment.back.link": "กลับสู่หน้าการจัดส่ง",
    "ecom.storeSide.review.pageTitle": "ตรวจสอบ",
    "ecom.storeSide.review.contactInfo.guideText": "ข้อมูลการติดต่อ",
    "ecom.storeSide.review.shipping.guideText": "ที่อยู่สำหรับจัดส่ง",
    "ecom.storeSide.review.payment.guideText": "การชำระเงิน",
    "ecom.storeSide.review.editDetails.link": "แก้ไข",
    "ecom.storeSide.review.termAndCondition.checkBoxLabel": "ข้าพเจ้าตกลงในข้อกำหนดและเงื่อนไขและนโยบายความเป็นส่วนตัวของเจ้าของผลิตภัณฑ์",
    "ecom.storeSide.addressForm.firstName.inputLabel": "ชื่อจริง",
    "ecom.storeSide.addressForm.firstName.inputPlaceholder": "ใส่ชื่อจริง",
    "ecom.storeSide.addressForm.lastName.inputLabel": "นามสกุล",
    "ecom.storeSide.addressForm.lastName.inputPlaceholder": "ใส่นามสกุล",
    "ecom.storeSide.addressForm.phone.inputLabel": "เบอร์ติดต่อ (ไม่บังคับ)",
    "ecom.storeSide.addressForm.phone.inputPlaceholder": "ใส่หมายเลขติดต่อ",
    "ecom.storeSide.addressForm.address1.inputLabel": "ที่อยู่ 1",
    "ecom.storeSide.addressForm.address1.inputPlaceholder": "เลขอาคารและถนน",
    "ecom.storeSide.addressForm.countryCode.inputLabel": "ประเทศ",
    "ecom.storeSide.addressForm.countryCode.inputPlaceholder": "กรุณาเลือก",
    "ecom.storeSide.addressForm.provinceCode.inputLabel": "จังหวัด",
    "ecom.storeSide.addressForm.provinceCode.inputPlaceholder": "กรุณาเลือก",
    "ecom.storeSide.addressForm.city.inputLabel": "เมือง",
    "ecom.storeSide.addressForm.city.inputPlaceholder": "กรุณาเลือก",
    "ecom.storeSide.addressForm.zip.inputLabel": "รหัสไปรษณีย์",
    "ecom.storeSide.addressForm.zip.inputPlaceholder": "ใส่รหัสไปรษณีย์",
    "ecom.storeSide.addressForm.shipAddress.accordionTitle": "เลือกที่อยู่ในการจัดส่ง",
    "ecom.storeSide.addressForm.addNewAddress.secondaryButton": "เพิ่มที่อยู่ใหม่",
    "ecom.storeSide.addressForm.editAddress.link": "แก้ไข",
    "ecom.storeSide.addressForm.cancelAddress.ghostButton": "ยกเลิก",
    "ecom.storeSide.addressForm.saveAddress.secondaryButton": "บันทึก",
    "ecom.storeSide.addressForm.deleteAddress.ghostButton": "ลบ",
    "ecom.storeSide.addressForm.sameAsShipping.checkBoxLabel": "เช่นเดียวกับที่อยู่จัดส่ง",
    "ecom.storeSide.addressForm.billAddress.accordionTitle": "เลือกที่อยู่ในการเรียกเก็บเงิน",
    "ecom.storeSide.addressForm.requestInvoice.checkBoxLabel": "ขอใบกำกับภาษี",
    "ecom.storeSide.addressForm.company.inputLabel": "ชื่อบริษัท",
    "ecom.storeSide.addressForm.company.inputPlaceholder": "ใส่ชื่อบริษัท",
    "ecom.storeSide.addressForm.vatNumber.inputLabel": "หมายเลขผู้เสียภาษี",
    "ecom.storeSide.addressForm.vatNumber.inputPlaceholder": "ใส่หมายเลขผู้เสียภาษี",
    "ecom.storeSide.addressForm.requiredField.inlineError": "ช่องบังคับกรอก",
    "ecom.checkout.deleteAddress.modalTitle": "ลบที่อยู่หลัก",
    "ecom.checkout.addAddress.toast": "ที่อยู่ได้ถูกเพิ่มเรียบร้อยแล้ว",
    "ecom.checkout.deleteAddress.toast": "ที่อยู่ได้ถูกลบเรียบร้อยแล้ว"
});
var ja_JP = _object_spread_props(_object_spread({}, en_US), {
    "ecom.storeSide.coupon.secondaryButton": "適用",
    "ecom.storeSide.privatePolicy.footerLink": "個人情報の取り扱い",
    "ecom.storeSide.backToStorefront.link": "買い物を続ける",
    "ecom.storeSide.productDetails.size.sectionTitle": "サイズ",
    "ecom.storeSide.productDetails.color.sectionTitle": "カラー",
    "ecom.storeSide.productDetails.personalization.accordionTitle": "パーソナライズ",
    "ecom.storeSide.signinLoggedOut.email.inputLabel": "Eメール",
    "ecom.storeSide.signinLoggedOut.password.inputLabel": "パスワード",
    "ecom.storeSide.shipping.pageTitle": "配送",
    "ecom.storeSide.shipping.shipMethod.sectionTitle": "配送方法",
    "ecom.storeSide.payment.payMethod.sectionTitle": "お支払方法",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputPlaceholder": "カード番号入力",
    "ecom.storeSide.review.pageTitle": "レビュー",
    "ecom.storeSide.review.editDetails.link": "修正",
    "ecom.storeSide.addressForm.firstName.inputLabel": "名",
    "ecom.storeSide.addressForm.lastName.inputLabel": "姓",
    "ecom.storeSide.addressForm.countryCode.inputLabel": "国",
    "ecom.storeSide.addressForm.city.inputLabel": "市",
    "ecom.storeSide.addressForm.shipAddress.sectionTitle": "修正",
    "ecom.storeSide.addressForm.cancelAddress.ghostButton": "キャンセル",
    "ecom.storeSide.addressForm.saveAddress.secondaryButton": "保存",
    "ecom.storeSide.addressForm.deleteAddress.ghostButton": "削除",
    "ecom.storeSide.addressForm.billAddress.sectionTitle": "配送先住所と同じ",
    "ecom.storeSide.addressForm.vatNumber.inputPlaceholder": "法人名",
    "ecom.storeSide.addressForm.requiredField.inlineError": "法人名を入力"
});
var es_MX = _object_spread_props(_object_spread({}, en_US), {
    "ecom.storeSide.signinLoggedIn.loginSuccess.toast": "Cierre de sesi\xf3n exitoso",
    "ecom.storeSide.signinLoggedIn.loginFailed.toast": "Cierre de sesi\xf3n fallido",
    "ecom.storeSide.signinLoggedIn.guest.pageTitle": "INVITADO",
    "ecom.storeSide.orderSum.sectionTitle": "Resumen del Pedido",
    "ecom.storeSide.orderSum.outlineCloseButton": "Cerrar Pedido",
    "ecom.productCard.sectionTitle": "Resumen de Productos",
    "ecom.storeSide.productDetails.basePrice.title": "Precio base",
    "ecom.storeSide.productDetails.unitPrice.title": "Precio unitario",
    "ecom.storeSide.duplicatedCoupon.toast": "\xa1Ups! Cup\xf3n duplicado",
    "ecom.storeSide.shipping.pageDescription": "Descripci\xf3n de la p\xe1gina de env\xedo",
    "ecom.storeSide.review.termAndCondition.checkBoxError": "Error de t\xe9rminos y condiciones",
    "ecom.storeSide.addressForm.shipAddress.sectionTitle": "Direcci\xf3n de env\xedo",
    "ecom.storeSide.addressForm.billAddress.sectionTitle": "Direcci\xf3n de Env\xedo",
    "ecom.storeSide.addressForm.deleteAddress.modalTitle": "Est\xe1s a punto de eliminar una direcci\xf3n",
    "ecom.storeSide.addressForm.deleteAddress.modalDescription": "Esto eliminar\xe1 una direcci\xf3n de tu libreta de direcciones",
    "ecom.checkout.shippingMethodNotAvailable.errorAlert": "Nuestro servicio no est\xe1 disponible en tu pa\xeds, cambia/edita la direcci\xf3n de env\xedo a continuaci\xf3n.",
    "ecom.checkout.shippingMethodSelectedCountry.warningAlert": "Selecciona el pa\xeds de destino para ver los m\xe9todos de env\xedo disponibles",
    "ecom.storeSide.payment.payMethodNotAdded.warningAlert": "A\xfan no se ha agregado el m\xe9todo de pago. Agr\xe9galo en la configuraci\xf3n de pago.",
    "ecom.storeSide.payment.paymentFailed.toast": "Pago fallido",
    "ecom.storeSide.payment.paymentSuccess.toast": "Pago exitoso",
    "ecom.storeSide.payment.pendingPayment.title": "Pago pendiente",
    "ecom.storeSide.payment.IdealPaymentWarning": "Por favor selecciona un banco",
    "ecom.checkout.invalidAddress.toast": "La direcci\xf3n no es v\xe1lida. Actualice el estado, la ciudad o el c\xf3digo postal para calcular los impuestos.",
    "ecom.storeSide.shoppingCart.sectionTitle": "Mi carrito",
    "ecom.storeSide.productCard.detail.ghostButton": "Detalles",
    "ecom.storeSide.productCard.itemUnavailable.inlineAlert": "Este art\xedculo no est\xe1 disponible; favor de retirarlo.",
    "ecom.storeSide.productCard.variantUnavailable.inlineAlert": "La opci\xf3n seleccionada no est\xe1 disponible. Ajusta tu elecci\xf3n.",
    "ecom.storeSide.productCard.variantNotSelected.inlineAlert": "Selecciona una opci\xf3n",
    "ecom.storeSide.paySum.sectionTitle": "Resumen de pago",
    "ecom.storeSide.coupon.inputLabel": "C\xf3digo de descuento",
    "ecom.storeSide.coupon.inputPlaceholder": "Ingresa tu c\xf3digo de descuento",
    "ecom.storeSide.coupon.secondaryButton": "Usar",
    "ecom.storeSide.paySum.cartTotal.paragraph": "Carrito de compra",
    "ecom.storeSide.paySum.coupon.accordionTitle": "C\xf3digo de descuento",
    "ecom.storeSide.paySum.promotion.accordionTitle": "Promoci\xf3n",
    "ecom.storeSide.paySum.cartTotalExclTax.title": "Subtotal",
    "ecom.storeSide.paySum.cartTotalExclTaxNoShipping.description": "El IVA y gastos de env\xedo se calculan al momento de pagar.",
    "ecom.storeSide.paySum.cartTotalInclTax.title": "Total",
    "ecom.storeSide.paySum.cartTotalInclTaxNoShipping.description": "(Incluye 7% de IVA. Costo de env\xedo calculado al finalizar la compra).",
    "ecom.storeSide.refundPolicy.footerLink": "Politica de reembolso",
    "ecom.storeSide.privatePolicy.footerLink": "Pol\xedtica de Privacidad",
    "ecom.storeSide.termAndCondition.footerLink": "T\xe9rminos y condiciones",
    "ecom.storeSide.backToStorefront.link": "Continuar comprando",
    "ecom.storeSide.goToCheckout.primaryButton": "Finalizar compra",
    "ecom.storeSide.goToCheckout.securedPay.description": "Pagos seguros con [Pasarela de pago]",
    "ecom.storeSide.couponExpired.alert": "Elimina el c\xf3digo de descuento caducado",
    "ecom.storeSide.couponExpired.inlineAlert": "C\xf3digo de descuento expirado",
    "ecom.storeSide.couponApplied.toast": "C\xf3digo de descuento aplicado. Precio actualizado.",
    "ecom.storeSide.couponDeleted.toast": "C\xf3digo de descuento eliminado. Precio actualizado.",
    "ecom.storeSide.invalidCoupon.inputError": " C\xf3digo de descuento inv\xe1lido.",
    "ecom.storeSide.productDetails.size.sectionTitle": "Talla",
    "ecom.storeSide.productDetails.color.sectionTitle": "Color",
    "ecom.storeSide.productDetails.productPrice.sectionTitle": "Detalle del precio",
    "ecom.storeSide.productDetails.productPrice.accordionTitle": "Precio del producto",
    "ecom.storeSide.productDetails.personalization.accordionTitle": "Personalizaci\xf3n",
    "ecom.storeSide.deleteItem.confirmationModal": "\xbfEliminar el art\xedculo seleccionado del carrito?",
    "ecom.storeSide.signinLoggedOut.pageTitle1": "Finalizar compra",
    "ecom.storeSide.signinLoggedOut.asGuest.title2": "Continuar como invitado",
    "ecom.storeSide.signinLoggedOut.email.inputLabel": "Correo electr\xf3nico",
    "ecom.storeSide.signinLoggedOut.email.inputPlaceholder": "Ingresa tu correo electr\xf3nico",
    "ecom.storeSide.signinLoggedOut.email.inputHelper": "Solo lo usamos para enviarle su recibo y  confirmaci\xf3n del pedido.",
    "ecom.storeSide.signinLoggedOut.asGuest.primaryButton": "Continuar como invitado",
    "ecom.storeSide.signinLoggedOut.asMember.title2": "Conectarse",
    "ecom.storeSide.signinLoggedOut.password.inputLabel": "Contrase\xf1a",
    "ecom.storeSide.signinLoggedOut.password.inputPlaceholder": "Ingresa tu contrase\xf1a",
    "ecom.storeSide.signinLoggedOut.forgetPassword.link": "\xbfOlvid\xf3 su contrase\xf1a?",
    "ecom.storeSide.signinLoggedOut.asMember.primaryButton": "Conectarse",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaText": "Registrarme",
    "ecom.storeSide.signinLoggedOut.createAccount.ctaLink": "Crear una cuenta aqu\xed",
    "ecom.storeSide.signinLoggedOut.back.link": "Regresar a la cesta",
    "ecom.storeSide.signinLoggedIn.member.pageTitle": "Miembro",
    "ecom.storeSide.signinLoggedIn.continueAsMember.title2": "Continuar como",
    "ecom.storeSide.signinLoggedIn.nextStep.primaryButton": "Continuar",
    "ecom.storeSide.signinLoggedIn.signOut.link": "(\xbfNo eres t\xfa?)",
    "ecom.storeSide.shipping.pageTitle": "Env\xedo",
    "ecom.storeSide.shipping.shipMethod.sectionTitle": "M\xe9todo de env\xedo",
    "ecom.storeSide.shipping.shipDescription.sectionTitle": "Descripci\xf3n del m\xe9todo de env\xedo",
    "ecom.storeSide.shipping.nextToPayment.primaryButton": "Seguir a Pago",
    "ecom.storeSide.shipping.back.link": "Regresar a Conexi\xf3n",
    "ecom.storeSide.payment.pageTitle": "Pago",
    "ecom.storeSide.payment.payMethod.sectionTitle": "Forma de pago",
    "ecom.storeSide.payment.cardDetails.sectionTitle": "Detalles de la Tarjeta de Cr\xe9dito",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputLabel": "N\xfamero de la Tarjeta de Cr\xe9dito",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputPlaceholder": "Ingresa el n\xfamero de la tarjeta",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputError": "Este campo es obligatorio",
    "ecom.storeSide.payment.cardDetails.cardNumber.inputErrorValid": "N\xfamero de tarjeta inv\xe1lido",
    "ecom.storeSide.payment.cardDetails.expiry.inputLabel": "Fecha de expiraci\xf3n",
    "ecom.storeSide.payment.cardDetails.expiry.inputPlaceholder": "MM/AA",
    "ecom.storeSide.payment.cardDetails.expiry.inputError": "Este campo es obligatorio",
    "ecom.storeSide.payment.cardDetails.cvc.inputLabel": "CVC:",
    "ecom.storeSide.payment.cardDetails.cvc.inputPlaceholder": "3 d\xedgitos",
    "ecom.storeSide.payment.cardDetails.cvc.inputError": "Este campo es obligatorio",
    "ecom.storeSide.payment.nextToReview.primaryButton": "Seguir a Revisi\xf3n de la compra",
    "ecom.storeSide.payment.back.link": "Regresar a Env\xedo",
    "ecom.storeSide.review.pageTitle": "Revisar",
    "ecom.storeSide.review.contactInfo.guideText": "Informaci\xf3n de Contacto:",
    "ecom.storeSide.review.shipping.guideText": "Env\xedo:",
    "ecom.storeSide.review.payment.guideText": "Pago:",
    "ecom.storeSide.review.editDetails.link": "Editar",
    "ecom.storeSide.review.termAndCondition.checkBoxLabel": "Acepto los t\xe9rminos y condiciones y la pol\xedtica de privacidad de la marca",
    "ecom.storeSide.addressForm.firstName.inputLabel": "Nombre",
    "ecom.storeSide.addressForm.firstName.inputPlaceholder": "Ingresa tu nombre",
    "ecom.storeSide.addressForm.lastName.inputLabel": "Apellido(s)",
    "ecom.storeSide.addressForm.lastName.inputPlaceholder": "Ingresa tus apellidos",
    "ecom.storeSide.addressForm.phone.inputLabel": "N\xfamero de contacto (Opcional)",
    "ecom.storeSide.addressForm.phone.inputPlaceholder": "Ingresa n\xfamero telef\xf3nico",
    "ecom.storeSide.addressForm.address1.inputLabel": "Direcci\xf3n L\xednea 1",
    "ecom.storeSide.addressForm.address1.inputPlaceholder": "Calle y N\xfamero",
    "ecom.storeSide.addressForm.countryCode.inputLabel": "Pa\xeds",
    "ecom.storeSide.addressForm.countryCode.inputPlaceholder": "Selecciona",
    "ecom.storeSide.addressForm.provinceCode.inputLabel": "Estado",
    "ecom.storeSide.addressForm.provinceCode.inputPlaceholder": "Selecciona",
    "ecom.storeSide.addressForm.city.inputLabel": "Ciudad",
    "ecom.storeSide.addressForm.city.inputPlaceholder": "Selecciona",
    "ecom.storeSide.addressForm.zip.inputLabel": "C\xf3digo Postal",
    "ecom.storeSide.addressForm.zip.inputPlaceholder": "Ingresa c\xf3digo postal",
    "ecom.storeSide.addressForm.shipAddress.accordionTitle": "Selecciona direcci\xf3n de env\xedo",
    "ecom.storeSide.addressForm.addNewAddress.secondaryButton": "Agregar nueva direcci\xf3n",
    "ecom.storeSide.addressForm.editAddress.link": "Editar",
    "ecom.storeSide.addressForm.cancelAddress.ghostButton": "Cancelar",
    "ecom.storeSide.addressForm.saveAddress.secondaryButton": "Guardar",
    "ecom.storeSide.addressForm.deleteAddress.ghostButton": "Eliminar",
    "ecom.storeSide.addressForm.sameAsShipping.checkBoxLabel": "Igual que la direccion de env\xedo",
    "ecom.storeSide.addressForm.billAddress.accordionTitle": "Selecciona direcci\xf3n de facturaci\xf3n",
    "ecom.storeSide.addressForm.requestInvoice.checkBoxLabel": "Solicitar factura fiscal",
    "ecom.storeSide.addressForm.company.inputLabel": "Nombre de la empresa",
    "ecom.storeSide.addressForm.company.inputPlaceholder": "Ingresa nombre de la empresa",
    "ecom.storeSide.addressForm.vatNumber.inputLabel": "RFC",
    "ecom.storeSide.addressForm.vatNumber.inputPlaceholder": "Ingresa RFC",
    "ecom.storeSide.addressForm.requiredField.inlineError": "Este campo es obligatorio",
    "ecom.checkout.deleteAddress.modalTitle": "\xbfEliminar la direcci\xf3n de facturaci\xf3n predeterminada?",
    "ecom.checkout.addAddress.toast": "Direcci\xf3n agregada",
    "ecom.checkout.deleteAddress.toast": "Direcci\xf3n eliminada"
});
export default createVocab({
    en_UK: en_UK,
    en_US: en_US,
    en_AU: en_AU,
    de_DE: de_DE,
    nl_NL: nl_NL,
    fr_FR: fr_FR,
    pt_BR: pt_BR,
    pt_PT: pt_PT,
    fr_CA: fr_CA,
    lv_LV: lv_LV,
    th_TH: th_TH,
    es_ES: es_ES,
    ja_JP: ja_JP,
    es_MX: es_MX
}, en_US);
