import { grayShade } from "../shades/grayShade";
import { greenShade } from "../shades/greenShade";
import { redShade } from "../shades/redShade";
import { yellowShade } from "../shades/yellowShade";
export var contentColors = {
    primary: grayShade[900],
    secondary: grayShade[500],
    form: grayShade[700],
    disabled: grayShade[400],
    guide: grayShade[500],
    navigation: grayShade[700],
    error: redShade[500],
    warning: yellowShade[500],
    success: greenShade[500],
    delete: redShade[500],
    deleteHover: redShade[400],
    contrast: "#FFFFFF"
};
