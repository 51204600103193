var SUPPORT_LANGUAGES = [
    "en_UK",
    "en_US",
    "en_CA",
    "en_AU",
    "de_DE",
    "nl_NL",
    "fr_FR",
    "pt_BR",
    "pt_PT",
    "fr_CA",
    "lv_LV",
    "es_ES",
    "th_TH",
    "ja_JP",
    "zh_CN",
    "no_NO",
    "es_MX", 
];
export default SUPPORT_LANGUAGES;
