import mapValues from "lodash/mapValues";
export var useColorMode = function() {
    return "light";
};
export function useColorWithVariant(variant, colorScheme) {
    var colorMode = useColorMode();
    var colorMap = colorScheme[variant];
    var _colorMode;
    return mapValues(colorMap, function(value) {
        return (_colorMode = value[colorMode]) !== null && _colorMode !== void 0 ? _colorMode : value;
    });
}
export function useColor(colorScheme) {
    var colorMode = useColorMode();
    var _colorMode;
    return mapValues(colorScheme, function(value) {
        return (_colorMode = value[colorMode]) !== null && _colorMode !== void 0 ? _colorMode : value;
    });
}
