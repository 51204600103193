import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
var STUDIO_APP_PATTERN = /\/studio\/(.*)\//;
var STUDIO_URL_PATTERN = // eslint-disable-next-line no-useless-escape
/^(https?|http):\/\/[^?\/]+\/studio\/[^?\/]+\/design\//;
// eslint-disable-next-line no-useless-escape
var EMBED_URL_PATTERN = /^(https?|http):\/\/[^?\/]+\/studio/;
export function isStudioApp(path) {
    return path ? STUDIO_APP_PATTERN.test(path) : STUDIO_URL_PATTERN.test(window.location.href);
}
export function isStorefront() {
    var path = window.location.href;
    return !STUDIO_URL_PATTERN.test(path) && EMBED_URL_PATTERN.test(path) && !path.includes("embed") || path.includes("/app/") || path.includes("/store/");
}
export function isImageCustomizationMode() {
    return getViewFromQueryString() === "ic";
}
export function getViewFromQueryString() {
    var urlParams = getURLParams();
    return urlParams === null || urlParams === void 0 ? void 0 : urlParams.get("v");
}
export function getTemplateIdFromQueryString() {
    var urlParams = getURLParams();
    return urlParams === null || urlParams === void 0 ? void 0 : urlParams.get("st");
}
export function getURLParams() {
    return "object" !== "undefined" ? new URLSearchParams(window.location.search) : null;
}
/**
 * convert object to url string
 * @param  {BuildUrlParamsOptions} params
 */ export var buildUrlParams = function(params) {
    return Object.entries(params).filter(function(param) {
        var _param = _sliced_to_array(param, 2), _ = _param[0], value = _param[1];
        return !isNil(value);
    }).map(function(param) {
        var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
        return "".concat(key, "=").concat(value);
    }).join("&");
};
export function getStudioV2URL(param) {
    var environment = param.environment, shopId = param.shopId, urlParamsOptions = param.urlParamsOptions;
    var briklUrl = "https://".concat(environment === "dev" ? "dev." : "", "dashboard.mybrikl.com/admin/").concat(shopId, "/designroom/room");
    var urlParams = buildUrlParams(_object_spread_props(_object_spread({}, urlParamsOptions), {
        s: shopId
    }));
    return "".concat(briklUrl).concat(!isEmpty(urlParams) ? "?".concat(urlParams) : "");
}
export function getStudioV3URL(param) {
    var urlParamsOptions = param.urlParamsOptions, shopId = param.shopId;
    var urlPrams = buildUrlParams(urlParamsOptions);
    return "/studio/".concat(shopId, "/design/room").concat(!isEmpty(urlPrams) ? "?".concat(urlPrams) : "");
}
// TODO: This is missing size param. It is better you can use above function
export function generateV3StudioURL(param) {
    var shopId = param.shopId, productId = param.productId, designId = param.designId, ownedByOrganisationId = param.ownedByOrganisationId, orderId = param.orderId;
    return "/dashboard/".concat(shopId, "/design/room?p=").concat(productId, "&pd=").concat(designId, "&o=").concat(ownedByOrganisationId, "&exportOrderId=").concat(orderId);
}
export function isGifUrl(url) {
    return url === null || url === void 0 ? void 0 : url.includes(".gif");
}
export function isGltfUrl(url) {
    return url === null || url === void 0 ? void 0 : url.includes(".gltf");
}
