// TODO: Tar, Use this in Font library TextEngine, and Export in Next PR
import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { GQL_SpotLightPosition } from "@brikl/codegen";
import { getFontFamilyFromUrl } from "./fontsUtils";
export var GOOGLE_FONTS = "https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Catamaran:wght@400;700&family=Caveat+Brush&family=Comfortaa:wght@400;500&family=Henny+Penny&family=Montserrat:wght@400;500;700&family=Mulish:wght@400;500&family=Oswald:wght@400;500&family=PT+Sans&family=Poppins:wght@300;500&family=Prompt&family=Roboto+Slab:wght@400;500&family=Roboto:wght@300;400;700&family=Rubik:wght@400;500&family=Varela&family=Viga&family=Work+Sans:wght@300;400&family=Yesteryear&display";
export var DEFAULT_FONTS = _to_consumable_array(getFontFamilyFromUrl(GOOGLE_FONTS)).concat([
    "Arial"
]);
var DEFAULT_AMBIENT_LIGHT = {
    intensity: 0.6,
    color: "0xffffff",
    visible: true
};
var DEFAULT_DOME_LIGHT = {
    visible: true,
    intensity: 0.1,
    skyColor: "0xffffff",
    groundColor: "0xbbbbbb"
};
var DEFAULT_SPOT_LIGHT = {
    visible: true,
    color: "0xffffff",
    castShadow: true,
    intensity: 0.6
};
var _obj;
export var SpotLightPositions = (_obj = {}, _define_property(_obj, GQL_SpotLightPosition.FrontRight, {
    x: 600,
    y: 200,
    z: 200
}), _define_property(_obj, GQL_SpotLightPosition.FrontLeft, {
    x: -500,
    y: 200,
    z: 200
}), _define_property(_obj, GQL_SpotLightPosition.BackRight, {
    x: 300,
    y: 350,
    z: -750
}), _define_property(_obj, GQL_SpotLightPosition.BackLeft, {
    x: -300,
    y: 350,
    z: -750
}), _define_property(_obj, GQL_SpotLightPosition.Front, {
    x: 0,
    y: 0,
    z: 10
}), _obj);
export var DEFAULT_LIGHTS_PRESET = {
    ambientLights: [
        DEFAULT_AMBIENT_LIGHT
    ],
    domeLights: [
        DEFAULT_DOME_LIGHT
    ],
    spotLights: [
        _object_spread_props(_object_spread({}, DEFAULT_SPOT_LIGHT), {
            position: GQL_SpotLightPosition.FrontRight,
            distance: 2000
        }),
        _object_spread_props(_object_spread({}, DEFAULT_SPOT_LIGHT), {
            position: GQL_SpotLightPosition.FrontLeft,
            distance: 1500
        }),
        _object_spread_props(_object_spread({}, DEFAULT_SPOT_LIGHT), {
            position: GQL_SpotLightPosition.BackRight,
            distance: 1150
        }),
        _object_spread_props(_object_spread({}, DEFAULT_SPOT_LIGHT), {
            position: GQL_SpotLightPosition.BackLeft,
            distance: 1150
        }),
        _object_spread_props(_object_spread({}, DEFAULT_SPOT_LIGHT), {
            position: GQL_SpotLightPosition.Front,
            distance: 1200,
            intensity: 0.025
        }), 
    ]
};
