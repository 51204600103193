import { FC, ReactNode } from 'react'

/**
 * Starts the amplify client
 */
import '../../auth/auth.config'

import { AppLayout } from './layout/AppLayout'

type AppProps = {
  children?: ReactNode
}

export const App: FC<AppProps> = props => {
  const { children } = props

  return <AppLayout>{children}</AppLayout>
}
