import { ApolloClient } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { defaultApolloLinkErrorHandler } from "@brikl/codegen";
import { cache } from "./constants/cache";
import { createApolloLinks } from "./createDashboardApolloLink";
var errorLink = onError(defaultApolloLinkErrorHandler);
export function createDashboardApolloClient(param) {
    var env = param.env, shopId = param.shopId, token = param.token;
    var ref = createApolloLinks({
        env: env,
        shopId: shopId,
        token: token
    }), authLink = ref.authLink, allLinks = ref.allLinks;
    return new ApolloClient({
        ssrMode: "object" === "undefined",
        link: authLink.concat(errorLink.concat(allLinks)),
        cache: cache.restore({}),
        connectToDevTools: env === "dev"
    });
}
export function createDashboardApolloClientWithCache(param) {
    var env = param.env, shopId = param.shopId, token = param.token;
    var apolloDashboardCache = new Map();
    if (apolloDashboardCache.has(env)) return apolloDashboardCache.get(env);
    var client = createDashboardApolloClient({
        env: env,
        shopId: shopId,
        token: token
    });
    apolloDashboardCache.set(env, client);
    return client;
}
