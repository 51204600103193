import { FeatureFlag } from "@brikl/codegen";
var BASE_PRODUCT_PATHNAME = "/dashboard/[shopId]/catalog/product/[productId]";
var BASE_PRODUCT_PACKAGE_PATHNAME = "/dashboard/[shopId]/catalog/product-package/[productPackageId]";
var BASE_COLLECTION_PATHNAME = "/dashboard/[shopId]/catalog/collection/[collectionId]";
var BASE_SETTINGS_PATHNAME = "/dashboard/[shopId]/settings";
var getProductPathBuilder = function(childPath) {
    return function(shopId, productId) {
        return "/dashboard/".concat(shopId, "/catalog/product/").concat(productId).concat(childPath);
    };
};
var getProductPackagePathBuilder = function(childPath) {
    return function(shopId, productPackageId) {
        return "/dashboard/".concat(shopId, "/catalog/product-package/").concat(productPackageId).concat(childPath);
    };
};
var getCollectionPathBuilder = function(childPath) {
    return function(shopId, collectionId) {
        return "/dashboard/".concat(shopId, "/catalog/collection/").concat(collectionId).concat(childPath);
    };
};
var getAttributePathBuilder = function(childPath) {
    return function(shopId, attributeId) {
        return "/dashboard/".concat(shopId, "/catalog/attribute/").concat(attributeId).concat(childPath);
    };
};
var getProductOptionPathBuilder = function(childPath) {
    return function(shopId, productOptionId) {
        return "/dashboard/".concat(shopId, "/catalog/product-options/").concat(productOptionId).concat(childPath);
    };
};
export var routes = {
    signup: {
        navText: "Sign Up",
        path: function() {
            return "/signup";
        }
    },
    dashboard: {
        navText: "Dashboard",
        path: function(shopId) {
            return "/dashboard/".concat(shopId, "/home");
        },
        children: {
            leads: {
                navText: "Leads",
                hideInBreadcrumb: false,
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/leads");
                },
                pathname: "/dashboard/[shopId]/leads",
                children: {
                    detail: {
                        navText: "Lead Details",
                        pathname: "/dashboard/[shopId]/leads/[leadId]",
                        path: function(shopId, leadId) {
                            return "/dashboard/".concat(shopId, "/leads/").concat(leadId);
                        }
                    }
                }
            },
            quotes: {
                navText: "Quotes",
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/quotes");
                },
                pathname: "/dashboard/[shopId]/quotes",
                children: {
                    quote: {
                        navText: "Quote",
                        path: function(shopId, quoteId) {
                            return "/dashboard/".concat(shopId, "/quotes/").concat(quoteId);
                        },
                        pathname: "/dashboard/[shopId]/quotes/[quoteId]"
                    }
                }
            },
            features: {
                navText: "Feature Flag",
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/features");
                },
                pathname: "/dashboard/[shopId]/features",
                showOnlyToBriklUser: true
            },
            account: {
                navText: "Account",
                path: function() {
                    return "/account";
                },
                children: {
                    settings: {
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/account/settings");
                        },
                        pathname: "/dashboard/[shopId]/account/settings",
                        navText: "Settings"
                    }
                }
            },
            onboarding: {
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/onboarding");
                },
                pathname: "/dashboard/[shopId]/onboarding",
                navText: "Onboarding"
            },
            home: {
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/home");
                },
                pathname: "/dashboard/[shopId]/home",
                navText: "Home"
            },
            catalog: {
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/catalog");
                },
                pathname: "/dashboard/[shopId]/catalog",
                navText: "Catalog",
                hideInBreadcrumb: true,
                children: {
                    product: {
                        navText: "Products",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/catalog/product");
                        },
                        pathname: "/dashboard/[shopId]/catalog/product",
                        children: {
                            // review: {
                            //   navText: 'Review',
                            //   pathname: `${BASE_PRODUCT_PATHNAME}/review`,
                            //   path: getProductPathBuilder('/review'),
                            // },
                            information: {
                                navText: "Information",
                                pathname: "".concat(BASE_PRODUCT_PATHNAME, "/information"),
                                path: getProductPathBuilder("/information")
                            },
                            "product-option": {
                                navText: "Product Option",
                                pathname: "".concat(BASE_PRODUCT_PATHNAME, "/product-option"),
                                path: getProductPathBuilder("/product-option")
                            },
                            inventory: {
                                navText: "Inventory / Price",
                                pathname: "".concat(BASE_PRODUCT_PATHNAME, "/inventory"),
                                path: getProductPathBuilder("/inventory")
                            },
                            media: {
                                navText: "Images",
                                pathname: "".concat(BASE_PRODUCT_PATHNAME, "/media"),
                                path: getProductPathBuilder("/media")
                            },
                            collections: {
                                navText: "Collections",
                                pathname: "".concat(BASE_PRODUCT_PATHNAME, "/collections"),
                                path: getProductPathBuilder("/collections")
                            },
                            "sales-channels": {
                                navText: "Sales Channels",
                                pathname: "".concat(BASE_PRODUCT_PATHNAME, "/sales-channels"),
                                path: getProductPathBuilder("/sales-channels")
                            },
                            attributes: {
                                navText: "Attributes",
                                pathname: "".concat(BASE_PRODUCT_PATHNAME, "/attributes"),
                                path: getProductPathBuilder("/attributes")
                            },
                            personalization: {
                                navText: "Personalization",
                                pathname: "".concat(BASE_PRODUCT_PATHNAME, "/personalization"),
                                path: getProductPathBuilder("/personalization")
                            },
                            discounts: {
                                navText: "Discounts",
                                pathname: "".concat(BASE_PRODUCT_PATHNAME, "/discounts"),
                                path: getProductPathBuilder("/discounts")
                            },
                            studioeditor: {
                                navText: "Design Studio",
                                path: function(shopId, productId) {
                                    return "/studio/".concat(shopId, "/design/room?&p=").concat(productId);
                                },
                                pathname: "/studio/[shopId]/design/room"
                            }
                        }
                    },
                    "supplier-products": {
                        navText: "Supplier Products",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/catalog/supplier-products");
                        },
                        pathname: "/dashboard/[shopId]/catalog/supplier-products",
                        requiredFeatureFlag: "ADD_PRODUCT_SUPPLIER",
                        children: {
                            "supplier-product-detail": {
                                navText: "Supplier Product",
                                path: function(shopId, productId) {
                                    return "/dashboard/".concat(shopId, "/catalog/supplier-products/").concat(productId);
                                },
                                pathname: "/dashboard/[shopId]/catalog/supplier-products/[productId]",
                                requiredFeatureFlag: "ADD_PRODUCT_SUPPLIER"
                            }
                        }
                    },
                    "product-package": {
                        navText: "Product Packages",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/catalog/product-package");
                        },
                        pathname: "/dashboard/[shopId]/catalog/product-package",
                        children: {
                            information: {
                                navText: "Information",
                                pathname: "".concat(BASE_PRODUCT_PACKAGE_PATHNAME, "/information"),
                                path: getProductPackagePathBuilder("/information")
                            },
                            media: {
                                navText: "Images",
                                pathname: "".concat(BASE_PRODUCT_PACKAGE_PATHNAME, "/media"),
                                path: getProductPackagePathBuilder("/media")
                            },
                            price: {
                                navText: "Price",
                                pathname: "".concat(BASE_PRODUCT_PACKAGE_PATHNAME, "/price"),
                                path: getProductPackagePathBuilder("/price")
                            },
                            products: {
                                navText: "Products",
                                pathname: "".concat(BASE_PRODUCT_PACKAGE_PATHNAME, "/products"),
                                path: getProductPackagePathBuilder("/products")
                            },
                            collections: {
                                navText: "Collections",
                                pathname: "".concat(BASE_PRODUCT_PACKAGE_PATHNAME, "/collections"),
                                path: getProductPackagePathBuilder("/collections")
                            },
                            saleschannels: {
                                navText: "Sales Channels",
                                pathname: "".concat(BASE_PRODUCT_PACKAGE_PATHNAME, "/saleschannels"),
                                path: getProductPackagePathBuilder("/saleschannels")
                            },
                            personalization: {
                                navText: "Personalization",
                                pathname: "".concat(BASE_PRODUCT_PACKAGE_PATHNAME, "/personalization"),
                                path: getProductPackagePathBuilder("/personalization"),
                                requiredFeatureFlag: "PRODUCT_PACKAGE_PERSONALIZATION"
                            }
                        }
                    },
                    collection: {
                        navText: "Collections",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/catalog/collection");
                        },
                        pathname: "/dashboard/[shopId]/catalog/collection",
                        children: {
                            information: {
                                navText: "Information",
                                pathname: "".concat(BASE_COLLECTION_PATHNAME, "/information"),
                                path: getCollectionPathBuilder("/information")
                            },
                            media: {
                                navText: "Images",
                                pathname: "".concat(BASE_COLLECTION_PATHNAME, "/media"),
                                path: getCollectionPathBuilder("/media")
                            },
                            products: {
                                navText: "Products",
                                pathname: "".concat(BASE_COLLECTION_PATHNAME, "/products"),
                                path: getCollectionPathBuilder("/products")
                            },
                            "product-packages": {
                                navText: "Product Packages",
                                pathname: "".concat(BASE_COLLECTION_PATHNAME, "/product-packages"),
                                path: getCollectionPathBuilder("/product-packages")
                            },
                            parents: {
                                navText: "Parent Collections",
                                pathname: "".concat(BASE_COLLECTION_PATHNAME, "/parents"),
                                path: getCollectionPathBuilder("/parents")
                            },
                            childs: {
                                navText: "Child Collections",
                                pathname: "".concat(BASE_COLLECTION_PATHNAME, "/childs"),
                                path: getCollectionPathBuilder("/childs")
                            },
                            "tax-setting": {
                                navText: "Tax",
                                pathname: "".concat(BASE_COLLECTION_PATHNAME, "/tax-setting"),
                                path: getCollectionPathBuilder("/tax-setting")
                            }
                        }
                    },
                    attribute: {
                        navText: "Attributes",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/catalog/attribute");
                        },
                        pathname: "/dashboard/[shopId]/catalog/attribute",
                        children: {
                            information: {
                                navText: "Information",
                                pathname: "/dashboard/[shopId]/catalog/attribute/[attributeId]",
                                path: getAttributePathBuilder("/information")
                            }
                        }
                    },
                    "product-options": {
                        navText: "Product Options",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/catalog/product-options");
                        },
                        pathname: "/dashboard/[shopId]/catalog/product-options",
                        children: {
                            information: {
                                navText: "Information",
                                pathname: "/dashboard/[shopId]/catalog/product-options/[productOptionId]",
                                path: getProductOptionPathBuilder("/information")
                            }
                        }
                    }
                }
            },
            embellish: {
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/embellish");
                },
                pathname: "/dashboard/[shopId]/embellish",
                hideInBreadcrumb: true,
                navText: "Embellish",
                children: {
                    "custom-image-editor": {
                        navText: "Image Placeholder",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/embellish/custom-image-editor");
                        },
                        pathname: "/dashboard/[shopId]/embellish/custom-image-editor",
                        children: {
                            "setup-placeholder": {
                                navText: "Setup Placeholder",
                                path: function(shopId, productId) {
                                    return "/dashboard/".concat(shopId, "/embellish/custom-image-editor/").concat(productId);
                                },
                                pathname: "/dashboard/[shopId]/embellish/custom-image-editor/[productId]"
                            }
                        }
                    },
                    "embellishment-type": {
                        navText: "Embellishment Type",
                        pathname: "/dashboard/[shopId]/embellish/embellishment-type",
                        requiredFeatureFlag: "EMBELLISHMENT_TYPE",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/embellish/embellishment-type");
                        }
                    },
                    "placeholder-management": {
                        navText: "Placeholder Management",
                        pathname: "/dashboard/[shopId]/embellish/placeholder-management/[type]",
                        requiredFeatureFlag: "STU_EMB_PLACEHOLDER_MANAGEMENT",
                        path: function(shopId) {
                            var type = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "multiple";
                            return "/dashboard/".concat(shopId, "/embellish/placeholder-management/").concat(type);
                        },
                        children: {
                            multiple: {
                                navText: "Multiple",
                                pathname: "/dashboard/[shopId]/embellish/placeholder-management/multiple",
                                path: function(shopId) {
                                    return "/dashboard/".concat(shopId, "/embellish/placeholder-management/multiple");
                                }
                            },
                            individual: {
                                navText: "Individual",
                                pathname: "/dashboard/[shopId]/embellish/placeholder-management/individual",
                                path: function(shopId) {
                                    return "/dashboard/".concat(shopId, "/embellish/placeholder-management/individual");
                                },
                                children: {
                                    "setup-placeholder": {
                                        navText: "Setup Placeholder",
                                        pathname: "/dashboard/[shopId]/embellish/placeholder-management/individual/[productId]",
                                        path: function(shopId, productId) {
                                            return "/dashboard/".concat(shopId, "/embellish/placeholder-management/individual/").concat(productId);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            design: {
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/design");
                },
                pathname: "/dashboard/[shopId]/design",
                navText: "Design",
                hideInBreadcrumb: true,
                children: {
                    // hide for time being as customers are confused or mis-use this feature
                    // product: {
                    //   navText: 'Custom Products',
                    //   path: (shopId: string) => `/dashboard/${shopId}/design/products`,
                    //   pathname: '/dashboard/[shopId]/design/products',
                    // },
                    designroom: {
                        navText: "Design Studio",
                        path: function(shopId) {
                            return "/studio/".concat(shopId, "/design/room");
                        },
                        pathname: "/studio/[shopId]/design/room"
                    },
                    // hide for time being as customers are confused or mis-use this feature
                    // designs: {
                    //   navText: 'Saved Designs',
                    //   path: (shopId: string) => `/dashboard/${shopId}/design/designs`,
                    //   pathname: '/dashboard/[shopId]/design/designs',
                    // },
                    // TODO: remove it once studio app works fine
                    // studioeditor: {
                    //   navText: 'Studio Editor',
                    //   path: (shopId: string) => `/studio/${shopId}/design/editor`,
                    //   pathname: '/studio/[shopId]/design/editor',
                    // },
                    prints: {
                        navText: "Print Library",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/design/prints");
                        },
                        pathname: "/dashboard/[shopId]/design/prints"
                    },
                    images: {
                        navText: "Image Library",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/design/images");
                        },
                        pathname: "/dashboard/[shopId]/design/images"
                    },
                    fonts: {
                        navText: "Font Library",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/design/fonts");
                        },
                        pathname: "/dashboard/[shopId]/design/fonts",
                        children: {
                            font: {
                                navText: "Font",
                                path: function(shopId, fontId) {
                                    return "/dashboard/".concat(shopId, "/design/fonts/").concat(fontId);
                                },
                                pathname: "/dashboard/[shopId]/design/fonts/[fontId]"
                            }
                        }
                    },
                    "material-assets": {
                        navText: "Material Assets",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/design/material-assets");
                        },
                        pathname: "/dashboard/[shopId]/design/material-assets"
                    },
                    materials: {
                        navText: "Color Palette",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/design/materials");
                        },
                        pathname: "/dashboard/[shopId]/design/materials",
                        children: {
                            material: {
                                navText: "Material",
                                path: function(shopId, materialId) {
                                    return "/dashboard/".concat(shopId, "/design/materials/").concat(materialId);
                                },
                                pathname: "/dashboard/[shopId]/design/materials/[materialId]"
                            }
                        }
                    },
                    colors: {
                        navText: "Color Library",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/design/colors");
                        },
                        pathname: "/dashboard/[shopId]/design/colors",
                        children: {
                            color: {
                                navText: "Color",
                                path: function(shopId, colorId) {
                                    return "/dashboard/".concat(shopId, "/design/colors/").concat(colorId);
                                },
                                pathname: "/dashboard/[shopId]/design/colors/[colorId]"
                            }
                        }
                    },
                    printingtechniques: {
                        navText: "Printing Techniques",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/design/printingtechniques");
                        },
                        pathname: "/dashboard/[shopId]/design/printingtechniques",
                        children: {
                            printingtechnique: {
                                navText: "Printing Technique",
                                path: function(shopId, printingTechniqueId) {
                                    return "/dashboard/".concat(shopId, "/design/printingtechniques/").concat(printingTechniqueId);
                                },
                                pathname: "/dashboard/[shopId]/design/printingtechniques/[printingTechniqueId]"
                            }
                        }
                    }
                }
            },
            storeDesign: {
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/store-design");
                },
                pathname: "/dashboard/[shopId]/store-design",
                navText: "Store Design",
                hideInBreadcrumb: true,
                children: {
                    contents: {
                        navText: "Contents",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/store-design/contents");
                        },
                        pathname: "/dashboard/[shopId]/store-design/contents",
                        children: {
                            editor: {
                                navText: "Editor",
                                path: function(shopId, contentId) {
                                    return "/dashboard/".concat(shopId, "/store-design/content/").concat(contentId, "/editor");
                                },
                                pathname: "/dashboard/[shopId]/store-design/content/[contentId]/editor"
                            },
                            settings: {
                                navText: "Content Settings",
                                path: function(shopId, contentId) {
                                    return "/dashboard/".concat(shopId, "/store-design/content/").concat(contentId, "/settings");
                                },
                                pathname: "/dashboard/[shopId]/store-design/content/[contentId]/settings"
                            }
                        }
                    },
                    menus: {
                        navText: "Navigation",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/store-design/menus");
                        },
                        pathname: "/dashboard/[shopId]/store-design/menus",
                        requiredFeatureFlag: "MENU_BUILDER_V2",
                        children: {
                            create: {
                                navText: "Create",
                                path: function(shopId) {
                                    return "/dashboard/".concat(shopId, "/store-design/menus/create");
                                },
                                pathname: "/dashboard/[shopId]/store-design/menus/create"
                            },
                            edit: {
                                navText: "Edit menu",
                                path: function(shopId, menuId) {
                                    return "/dashboard/".concat(shopId, "/store-design/menus/").concat(menuId);
                                },
                                pathname: "/dashboard/[shopId]/store-design/menus/[menuId]"
                            }
                        }
                    },
                    themes: {
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/store-design/themes");
                        },
                        pathname: "/dashboard/[shopId]/store-design/themes",
                        navText: "Themes",
                        children: {
                            editor: {
                                navText: "Theme Editor",
                                pathname: "/dashboard/[shopId]/store-design/themes/[themeId]/editor",
                                path: function(shopId, themeId) {
                                    return "/dashboard/".concat(shopId, "/store-design/themes/").concat(themeId, "/editor");
                                }
                            }
                        }
                    }
                }
            },
            saleschannel: {
                path: function(shopId, salesChannelId) {
                    return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId);
                },
                pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]",
                navText: "Sales Channel",
                children: {
                    // review: {
                    //   navText: 'Review',
                    //   pathname: '/dashboard/[shopId]/saleschannel/[salesChannelId]/review',
                    //   path: (shopId: string, salesChannelId: string) =>
                    //     `/dashboard/${shopId}/saleschannel/${salesChannelId}/review`,
                    // },
                    information: {
                        navText: "Information",
                        pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/information",
                        path: function(shopId, salesChannelId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/information");
                        }
                    },
                    catalog: {
                        navText: "Catalog",
                        pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/catalog",
                        path: function(shopId, salesChannelId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/catalog");
                        }
                    },
                    availability: {
                        navText: "Availability",
                        pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/availability",
                        path: function(shopId, salesChannelId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/availability");
                        }
                    },
                    productMedia: {
                        navText: "Product Media",
                        pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/[productId]/media",
                        path: function(shopId, salesChannelId, productId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/").concat(productId, "/media");
                        }
                    },
                    content: {
                        navText: "Content",
                        pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/content",
                        path: function(shopId, salesChannelId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/content");
                        }
                    },
                    personalization: {
                        navText: "Personalization",
                        pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/personalization",
                        path: function(shopId, salesChannelId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/personalization");
                        }
                    },
                    fundraising: {
                        navText: "Fundraising",
                        pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/fundraising",
                        path: function(shopId, salesChannelId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/fundraising");
                        },
                        children: {
                            information: {
                                navText: "Information",
                                pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/fundraising/[fundraisingId]/information",
                                path: function(shopId, salesChannelId, fundraisingId) {
                                    return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/fundraising/").concat(fundraisingId, "/information");
                                }
                            }
                        }
                    },
                    fee: {
                        navText: "Fees",
                        pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/fee",
                        path: function(shopId, salesChannelId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/fee");
                        },
                        children: {
                            information: {
                                navText: "Information",
                                pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/fee/[feeId]/information",
                                path: function(shopId, salesChannelId, feeId) {
                                    return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/fee/").concat(feeId, "/information");
                                }
                            }
                        }
                    },
                    payment: {
                        navText: "Payment",
                        pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/payment",
                        path: function(shopId, salesChannelId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/payment");
                        }
                    },
                    shipping: {
                        navText: "Shipping",
                        pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/shipping",
                        path: function(shopId, salesChannelId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/shipping");
                        }
                    },
                    "admin-portal": {
                        navText: "Admin Portal",
                        pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/admin-portal",
                        path: function(shopId, salesChannelId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/admin-portal");
                        },
                        children: {
                            users: {
                                navText: "Admin Portal User",
                                pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/admin-portal/users/[userId]",
                                path: function(shopId, salesChannelId, userId) {
                                    return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/admin-portal/users/").concat(userId);
                                }
                            }
                        }
                    },
                    setup: {
                        navText: "Setup",
                        pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/setup",
                        path: function(shopId, salesChannelId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/setup");
                        }
                    },
                    email: {
                        navText: "Contact Email",
                        pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/email",
                        path: function(shopId, salesChannelId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/email");
                        }
                    },
                    structure: {
                        navText: "Structure",
                        pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/structure",
                        path: function(shopId, salesChannelId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/structure");
                        }
                    },
                    deployment: {
                        navText: "Deployment",
                        pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/deployment",
                        path: function(shopId, salesChannelId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/deployment");
                        }
                    },
                    cartItems: {
                        navText: "Cart Items",
                        pathname: "/dashboard/[shopId]/saleschannel/[salesChannelId]/cart-items",
                        path: function(shopId, salesChannelId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/").concat(salesChannelId, "/cart-items");
                        }
                    }
                }
            },
            saleschannels: {
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/saleschannel");
                },
                pathname: "/dashboard/[shopId]/saleschannel",
                navText: "Sales Channels",
                hideInBreadcrumb: true,
                children: {
                    storefronts: {
                        navText: "Storefronts",
                        pathname: "/dashboard/[shopId]/saleschannel/storefronts",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/storefronts");
                        }
                    },
                    teamstores: {
                        navText: "Microstores",
                        pathname: "/dashboard/[shopId]/saleschannel/teamstores",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/saleschannel/teamstores");
                        }
                    }
                }
            },
            "saleschannel-product": {
                pathname: "/dashboard/[shopId]/saleschannel-product/[salesChannelProductId]/",
                navText: "Edit Product Details in Sales Channel",
                children: {
                    information: {
                        navText: "Product Information",
                        pathname: "/dashboard/[shopId]/saleschannel-product/[salesChannelProductId]/product-information",
                        path: function(shopId, salesChannelProductId) {
                            return "/dashboard/".concat(shopId, "/saleschannel-product/").concat(salesChannelProductId, "/product-information");
                        }
                    },
                    variants: {
                        navText: "Product Variants",
                        pathname: "/dashboard/[shopId]/saleschannel-product/[salesChannelProductId]/product-variants",
                        path: function(shopId, salesChannelProductId) {
                            return "/dashboard/".concat(shopId, "/saleschannel-product/").concat(salesChannelProductId, "/product-variants");
                        }
                    },
                    media: {
                        navText: "Product Media",
                        pathname: "/dashboard/[shopId]/saleschannel-product/[salesChannelProductId]/product-media",
                        path: function(shopId, salesChannelProductId) {
                            return "/dashboard/".concat(shopId, "/saleschannel-product/").concat(salesChannelProductId, "/product-media");
                        }
                    }
                }
            },
            "cart-items": {
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/cart-items");
                },
                pathname: "/dashboard/[shopId]/cart-items",
                navText: "Cart Items",
                requireFeature: FeatureFlag.CartItemList
            },
            orders: {
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/orders");
                },
                pathname: "/dashboard/[shopId]/orders",
                navText: "Orders",
                children: {
                    detail: {
                        navText: "Order Details",
                        pathname: "/dashboard/[shopId]/orders/[orderId]",
                        path: function(shopId, orderId) {
                            return "/dashboard/".concat(shopId, "/orders/").concat(orderId);
                        }
                    },
                    information: {
                        navText: "Information",
                        pathname: "/dashboard/[shopId]/orders/[orderId]/information",
                        path: function(shopId, orderId) {
                            return "/dashboard/".concat(shopId, "/orders/").concat(orderId, "/information");
                        }
                    },
                    customer: {
                        navText: "Customer",
                        pathname: "/dashboard/[shopId]/orders/[orderId]/customer",
                        path: function(shopId, orderId) {
                            return "/dashboard/".concat(shopId, "/orders/").concat(orderId, "/customer");
                        }
                    }
                }
            },
            customers: {
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/customers");
                },
                pathname: "/dashboard/[shopId]/customers",
                navText: "Customers",
                children: {
                    detail: {
                        navText: "Customer Details",
                        pathname: "/dashboard/[shopId]/customers/[orderId]",
                        path: function(shopId, customerId) {
                            return "/dashboard/".concat(shopId, "/customers/").concat(customerId);
                        }
                    }
                }
            },
            discounts: {
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/discounts");
                },
                pathname: "/dashboard/[shopId]/discounts",
                navText: "Discounts",
                hideInBreadcrumb: true,
                children: {
                    promotion: {
                        navText: "Promotions",
                        pathname: "/dashboard/[shopId]/discounts/promotion",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/discounts/promotion");
                        },
                        children: {
                            information: {
                                navText: "Information",
                                pathname: "/dashboard/[shopId]/discounts/promotion/[discountId]/information",
                                path: function(shopId, discountId) {
                                    return "/dashboard/".concat(shopId, "/discounts/promotion/").concat(discountId, "/information");
                                }
                            },
                            behaviour: {
                                navText: "Behaviour",
                                pathname: "/dashboard/[shopId]/discounts/promotion/[discountId]/behaviour",
                                path: function(shopId, discountId) {
                                    return "/dashboard/".concat(shopId, "/discounts/promotion/").concat(discountId, "/behaviour");
                                }
                            },
                            rules: {
                                navText: "Rules",
                                pathname: "/dashboard/[shopId]/discounts/promotion/[discountId]/rules",
                                path: function(shopId, discountId) {
                                    return "/dashboard/".concat(shopId, "/discounts/promotion/").concat(discountId, "/rules");
                                }
                            }
                        }
                    },
                    coupon: {
                        navText: "Coupons",
                        pathname: "/dashboard/[shopId]/discounts/coupon",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/discounts/coupon");
                        },
                        children: {
                            information: {
                                navText: "Information",
                                pathname: "/dashboard/[shopId]/discounts/coupon/[discountId]/information",
                                path: function(shopId, discountId) {
                                    return "/dashboard/".concat(shopId, "/discounts/coupon/").concat(discountId, "/information");
                                }
                            },
                            behaviour: {
                                navText: "Behaviour",
                                pathname: "/dashboard/[shopId]/discounts/coupon/[discountId]/behaviour",
                                path: function(shopId, discountId) {
                                    return "/dashboard/".concat(shopId, "/discounts/coupon/").concat(discountId, "/behaviour");
                                }
                            },
                            rules: {
                                navText: "Rules",
                                pathname: "/dashboard/[shopId]/discounts/coupon/[discountId]/rules",
                                path: function(shopId, discountId) {
                                    return "/dashboard/".concat(shopId, "/discounts/coupon/").concat(discountId, "/rules");
                                }
                            },
                            coupons: {
                                navText: "Coupons",
                                pathname: "/dashboard/[shopId]/discounts/coupon/[discountId]/coupons",
                                path: function(shopId, discountId) {
                                    return "/dashboard/".concat(shopId, "/discounts/coupon/").concat(discountId, "/coupons");
                                }
                            }
                        }
                    }
                }
            },
            upcharges: {
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/upcharges");
                },
                pathname: "/dashboard/[shopId]/upcharges",
                navText: "Upcharges",
                hideInBreadcrumb: true,
                children: {
                    fundraising: {
                        navText: "Fundraising",
                        pathname: "/dashboard/[shopId]/upcharges/fundraising",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/upcharges/fundraising");
                        },
                        children: {
                            information: {
                                navText: "Information",
                                pathname: "/dashboard/[shopId]/upcharges/fundraising/[discountId]/information",
                                path: function(shopId, discountId) {
                                    return "/dashboard/".concat(shopId, "/upcharges/fundraising/").concat(discountId, "/information");
                                }
                            },
                            behaviour: {
                                navText: "Behaviour",
                                pathname: "/dashboard/[shopId]/upcharges/fundraising/[discountId]/behaviour",
                                path: function(shopId, discountId) {
                                    return "/dashboard/".concat(shopId, "/upcharges/fundraising/").concat(discountId, "/behaviour");
                                }
                            },
                            rules: {
                                navText: "Rules",
                                pathname: "/dashboard/[shopId]/upcharges/fundraising/[discountId]/rules",
                                path: function(shopId, discountId) {
                                    return "/dashboard/".concat(shopId, "/upcharges/fundraising/").concat(discountId, "/rules");
                                }
                            }
                        }
                    },
                    fee: {
                        navText: "Fees",
                        pathname: "/dashboard/[shopId]/upcharges/fee",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/upcharges/fee");
                        },
                        children: {
                            information: {
                                navText: "Information",
                                pathname: "/dashboard/[shopId]/upcharges/fee/[discountId]/information",
                                path: function(shopId, discountId) {
                                    return "/dashboard/".concat(shopId, "/upcharges/fee/").concat(discountId, "/information");
                                }
                            },
                            behaviour: {
                                navText: "Behaviour",
                                pathname: "/dashboard/[shopId]/upcharges/fee/[discountId]/behaviour",
                                path: function(shopId, discountId) {
                                    return "/dashboard/".concat(shopId, "/upcharges/fee/").concat(discountId, "/behaviour");
                                }
                            },
                            rules: {
                                navText: "Rules",
                                pathname: "/dashboard/[shopId]/upcharges/fee/[discountId]/rules",
                                path: function(shopId, discountId) {
                                    return "/dashboard/".concat(shopId, "/upcharges/fee/").concat(discountId, "/rules");
                                }
                            }
                        }
                    }
                }
            },
            settings: {
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/settings/information");
                },
                pathname: "/dashboard/[shopId]/settings/information",
                navText: "Settings",
                children: {
                    information: {
                        navText: "Information",
                        pathname: "".concat(BASE_SETTINGS_PATHNAME, "/information"),
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/settings/information");
                        }
                    },
                    currency: {
                        navText: "Currency",
                        pathname: "".concat(BASE_SETTINGS_PATHNAME, "/currency"),
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/settings/currency");
                        }
                    },
                    language: {
                        navText: "Language",
                        pathname: "".concat(BASE_SETTINGS_PATHNAME, "/language"),
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/settings/language");
                        }
                    },
                    // ...(isDev && {
                    //   email: {
                    //     navText: 'Contact Email',
                    //     pathname: `${BASE_SETTINGS_PATHNAME}/email`,
                    //     path: (shopId: string) => `/dashboard/${shopId}/settings/email`,
                    //   },
                    // }),
                    email: {
                        navText: "Contact Email",
                        pathname: "".concat(BASE_SETTINGS_PATHNAME, "/email"),
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/settings/email");
                        }
                    },
                    social: {
                        navText: "Social",
                        pathname: "".concat(BASE_SETTINGS_PATHNAME, "/social"),
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/settings/social");
                        }
                    },
                    payment: {
                        navText: "Payment",
                        pathname: "".concat(BASE_SETTINGS_PATHNAME, "/payment"),
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/settings/payment");
                        }
                    },
                    tax: {
                        navText: "Tax",
                        pathname: "".concat(BASE_SETTINGS_PATHNAME, "/tax"),
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/settings/tax");
                        }
                    },
                    shipping: {
                        navText: "Shipping",
                        pathname: "".concat(BASE_SETTINGS_PATHNAME, "/shipping"),
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/settings/shipping");
                        }
                    },
                    users: {
                        navText: "User Management",
                        pathname: "".concat(BASE_SETTINGS_PATHNAME, "/users"),
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/settings/users");
                        }
                    },
                    checkout: {
                        navText: "Checkout (BRIKL ONLY)",
                        pathname: "".concat(BASE_SETTINGS_PATHNAME, "/checkout"),
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/settings/checkout");
                        },
                        showOnlyToBriklUser: true
                    },
                    // Hide for the time being caz it's not applicable
                    // billing: {
                    //   navText: 'Billing',
                    //   pathname: `${BASE_SETTINGS_PATHNAME}/billing`,
                    //   path: (shopId: string) => `/dashboard/${shopId}/settings/billing`,
                    // },
                    webhooks: {
                        navText: "Webhooks (BRIKL ONLY)",
                        pathname: "".concat(BASE_SETTINGS_PATHNAME, "/webhooks"),
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/settings/webhooks");
                        },
                        showOnlyToBriklUser: true
                    },
                    template: {
                        navText: "Document Template",
                        pathname: "".concat(BASE_SETTINGS_PATHNAME, "/template"),
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/settings/template");
                        },
                        requiredFeatureFlag: "DOCUMENT_APP"
                    }
                }
            },
            deployment: {
                navText: "Deployment",
                pathname: "/dashboard/[shopId]/deployment",
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/deployment");
                }
            },
            reporting: {
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/reporting");
                },
                pathname: "/dashboard/[shopId]/reporting",
                navText: "Reporting",
                hideInBreadcrumb: true,
                children: {
                    general: {
                        navText: "General",
                        pathname: "/dashboard/[shopId]/reporting/general",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/reporting/general");
                        }
                    },
                    customers: {
                        navText: "Customers Report",
                        pathname: "/dashboard/[shopId]/reporting/customers",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/reporting/customers");
                        }
                    },
                    coupons: {
                        navText: "Coupons Report",
                        pathname: "/dashboard/[shopId]/reporting/coupons",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/reporting/coupons");
                        }
                    },
                    orders: {
                        navText: "Orders Report",
                        pathname: "/dashboard/[shopId]/reporting/orders",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/reporting/orders");
                        }
                    },
                    "order-items": {
                        navText: "Order Items Report",
                        pathname: "/dashboard/[shopId]/reporting/orderItems",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/reporting/orderItems");
                        }
                    },
                    sku: {
                        navText: "SKU Report",
                        pathname: "/dashboard/[shopId]/reporting/sku",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/reporting/sku");
                        }
                    },
                    teamstores: {
                        navText: "Microstores Report",
                        pathname: "/dashboard/[shopId]/reporting/teamstores",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/reporting/teamstores");
                        }
                    },
                    accounting: {
                        navText: "Accounting Report (BRIKL ONLY)",
                        pathname: "/dashboard/[shopId]/reporting/accounting",
                        path: function(shopId) {
                            return "/dashboard/".concat(shopId, "/reporting/accounting");
                        },
                        showOnlyToBriklUser: true
                    }
                }
            },
            accounts: {
                navText: "Accounts",
                pathname: "/dashboard/[shopId]/accounts",
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/accounts");
                },
                children: {
                    detail: {
                        navText: "Account Details",
                        pathname: "/dashboard/[shopId]/accounts/[accountId]",
                        path: function(shopId, accountId) {
                            return "/dashboard/".concat(shopId, "/accounts/").concat(accountId);
                        }
                    }
                }
            },
            contacts: {
                navText: "Contacts",
                pathname: "/dashboard/[shopId]/contacts",
                path: function(shopId) {
                    return "/dashboard/".concat(shopId, "/contacts");
                },
                children: {
                    detail: {
                        navText: "Contact Details",
                        pathname: "/dashboard/[shopId]/contacts/[contactId]/details",
                        path: function(shopId, contactId) {
                            return "/dashboard/".concat(shopId, "/contacts/").concat(contactId, "/details");
                        }
                    }
                }
            }
        }
    },
    verify: {
        children: {
            index: {
                navText: "Verify",
                pathname: "/verify",
                path: function() {
                    return "/verify";
                }
            }
        }
    },
    forgot: {
        children: {
            index: {
                navText: "Forgot Password",
                pathname: "/forgot",
                path: function() {
                    return "/forgot";
                }
            }
        }
    },
    login: {
        children: {
            index: {
                navText: "Login",
                pathname: "/login",
                path: function() {
                    return "/login";
                }
            },
            shops: {
                navText: "Shops",
                pathname: "/login/shops",
                path: function() {
                    return "/login/shops";
                }
            }
        }
    },
    public: {
        children: {
            communicationPannel: {
                navText: "Communication Pannel",
                pathname: "/public/[shopId]/[salesChannelId]/[communicationPanelId]",
                path: function(shopId, salesChannelId, communicationPanelId) {
                    return "/public/".concat(shopId, "/").concat(salesChannelId, "/").concat(communicationPanelId);
                }
            },
            stripeconnect: {
                navText: "Stripe Connect",
                pathname: "/public/stripeconnect",
                path: function() {
                    return "/public/stripeconnect";
                }
            },
            instagramAuth: {
                navText: "Instagram Authorization",
                pathname: "/public/instagram-auth",
                path: function() {
                    return "/public/instagram-auth";
                }
            }
        }
    },
    internal: {
        children: {
            shops: {
                navText: "Shops",
                pathname: "/internal/shops",
                path: function() {
                    return "/internal/shops";
                },
                showOnlyToBriklUser: true
            },
            themes: {
                navText: "Themes",
                pathname: "/internal/themes",
                path: function() {
                    return "/internal/themes";
                },
                showOnlyToBriklUser: true
            }
        }
    }
};
