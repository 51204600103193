import { SpaceConfig } from "../theme/theme";
export var getSingleSpacing = function(space) {
    if (typeof space === "number") return space;
    return space ? SpaceConfig[space] : 0;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getSpacing(space) {
    if (Array.isArray(space)) return space.map(getSingleSpacing);
    return getSingleSpacing(space);
}
