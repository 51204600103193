import { useDashboardFeatureFlags as useDashboardFeatureFlagsOriginal } from '@brikl/dashboard-utils'

// Note: using a normal import instead of named re-export (export {} from '')
// to mark deprecate for every file that is importing the hook from this path.
/**
 * @deprecated Please import from '@brikl/dashboard-utils' directly instead.
 */
export const useDashboardFeatureFlags = () => {
  return useDashboardFeatureFlagsOriginal()
}
