import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { InMemoryCache } from "@apollo/client";
import { newBackendApolloFragmentMatcher } from "@brikl/codegen";
import { typePolicies } from "../typePolicies";
export var cache = new InMemoryCache({
    possibleTypes: _object_spread_props(_object_spread({}, newBackendApolloFragmentMatcher.possibleTypes), {
        // Manually written OBE types.
        ShopDesignStep: [
            "LabelStep",
            "FabricStep",
            "PlacementprintStep",
            "CommentStep",
            "LogoStep",
            "ColorStep",
            "TextStep",
            "AppliqueStep",
            "AlloverprintStep",
            "AddToCartStep",
            "OrderDetailStep",
            "PersonalizationStep",
            "PatternStep", 
        ],
        BoardItem: [
            "BoardItemLabel",
            "BoardItemText",
            "BoardItemLogo"
        ]
    }),
    typePolicies: typePolicies
});
