import { useCallback, useEffect, useMemo } from "react";
import has from "lodash/has";
import { FeatureFlag, useGetDashboardAdminUserNbQuery } from "@brikl/codegen";
import { useDashboardUserAccessLevelQuery } from "@brikl/codegen";
import { useAuth } from "@brikl/dashboard-utils";
import { useDashboardFeatureFlags } from "~/apps/dashboard/src/hooks/useDashboardFeatureFlags";
import { useCurrentShop } from "../../shop/shop.context";
import getAccessValueFromKeyString from "./getAccessValueFromKeyString";
import getApiAccessKeyByAliasKey from "./getApiAccessKeyByAliasKey";
/**
 * @param aliasAccessKey (Optional) permisison key of each feature, if there is no specific key, access value will bound back to always FALSE
 * @see https://www.notion.so/brikl/Q2-2021-BRIKLENG-2236-Role-base-access-control-91bd282f71f647a095c15d962536070a
 */ export var useRoleAccess = function(aliasAccessKey, userRoles) {
    var ref, ref1, ref2;
    var shop = useCurrentShop();
    var auth = useAuth();
    var userId = (ref = auth.user) === null || ref === void 0 ? void 0 : ref.userId;
    // The rule apply only for shop integrated with new backend
    var isFeatureSupported = (ref1 = shop.capabilities) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.featureFlags) === null || ref2 === void 0 ? void 0 : ref2.includes(FeatureFlag.BackendV2);
    var SALES_REP = useDashboardFeatureFlags().SALES_REP;
    // API
    var ref3 = useDashboardUserAccessLevelQuery({
        skip: !isFeatureSupported
    }), data = ref3.data, loading = ref3.loading, error = ref3.error;
    var ref4 = useGetDashboardAdminUserNbQuery({
        skip: !userId,
        variables: {
            userId: userId
        }
    }), adminUserData = ref4.data, isLoadingAdminUserData = ref4.loading;
    useEffect(function() {
        if (!isFeatureSupported) return;
        // Update user acess level in context provider
        if (!loading && !isLoadingAdminUserData && (data === null || data === void 0 ? void 0 : data.userAccessLevel)) {
            auth.setUserAccessLevel(data === null || data === void 0 ? void 0 : data.userAccessLevel);
            if (adminUserData === null || adminUserData === void 0 ? void 0 : adminUserData.adminUser) {
                var ref;
                auth.setUserRole(adminUserData === null || adminUserData === void 0 ? void 0 : (ref = adminUserData.adminUser) === null || ref === void 0 ? void 0 : ref.assignedPosition);
            }
        }
    }, [
        loading,
        isLoadingAdminUserData,
        data,
        adminUserData
    ]);
    var checkAccessByAliasKey = function(aliasKey) {
        // Get real access level key
        var apiAccessKey = getApiAccessKeyByAliasKey(aliasKey);
        if (!has(auth.accessLevel, apiAccessKey)) return false;
        return auth.accessLevel[apiAccessKey];
    };
    var checkUserRole = function(userRoles) {
        if (!userRoles) return true;
        return !!auth.userRole && (userRoles === null || userRoles === void 0 ? void 0 : userRoles.includes(auth === null || auth === void 0 ? void 0 : auth.userRole));
    };
    /**
   * To check if user has authority to perform a certain feature
   *
   * @return boolean | true // always allow access if a certain feature does not integrated with API.
   * @return boolean | true // always true if rule does not allowed with in the whistlist
   * @return boolean | false // always false if access key is undefined
   */ var isAuthorized = useMemo(function() {
        if (!isFeatureSupported) return true;
        if (!aliasAccessKey) return false;
        var userAccessKeyPermission = checkAccessByAliasKey(aliasAccessKey);
        var userRolePermission = SALES_REP ? checkUserRole(userRoles) : true;
        return userAccessKeyPermission && userRolePermission;
    }, [
        auth.accessLevel,
        auth.userRole,
        aliasAccessKey,
        userRoles
    ]);
    /**
   * Simply find the permission value based on string
   * Title will find the simply matching key in the system
   *
   * @param title | this can be anything eg: Catalog, Leads
   * @return boolean
   */ var checkAccessByKeyString = useCallback(function(title) {
        if (!isFeatureSupported) return true;
        return getAccessValueFromKeyString(auth.accessLevel, title);
    }, [
        auth.accessLevel
    ]);
    return {
        loading: loading || isLoadingAdminUserData,
        data: {
            isAuthorized: isAuthorized
        },
        checkAccessByKeyString: checkAccessByKeyString,
        error: error
    };
};
