export var isFeatureEnabled = function(featuresEnabled, flag) {
    return featuresEnabled === null || featuresEnabled === void 0 ? void 0 : featuresEnabled.includes(flag);
};
export var createFeatureFlagMap = function(allFlags, enabledFlags) {
    var mappedFlags = {};
    // Map all available flags and mark it as false
    allFlags.forEach(function(flag) {
        mappedFlags[flag] = false;
    });
    // Change flags in enabledFlags to true
    enabledFlags.forEach(function(flag) {
        mappedFlags[flag] = true;
    });
    return mappedFlags;
};
