import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import merge from "lodash/merge";
import BaseLocaleVocab from "./base-vocab";
import CommonLocaleVocab from "./common-vocab";
import EcommerceLocaleVocab from "./ecommerce-vocab";
import PaymentLocaleVocab from "./payment-vocab";
/**
 * @note to add more vocab, do createLocaleVocab(vocab1, vocab2, ...)
 */ var createLocaleVocab = function() {
    for(var _len = arguments.length, domainLocaleVocab = new Array(_len), _key = 0; _key < _len; _key++){
        domainLocaleVocab[_key] = arguments[_key];
    }
    return merge.apply(void 0, [
        {}
    ].concat(_to_consumable_array(domainLocaleVocab)));
};
export default createLocaleVocab(BaseLocaleVocab, EcommerceLocaleVocab, CommonLocaleVocab, PaymentLocaleVocab);
