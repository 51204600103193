import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "fragment BulkOperationResultNB on BulkOperationOutput{total success{id}failed{id}}"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "fragment DashboardSearchCouponCodesFieldsNB on Coupon{id discountId couponCode currentUsed shopId enabled maxUsagePerCustomer maxUsageOverall createdAt updatedAt}"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "fragment adminPortalOpeningCycleFieldsNB on TeamStoreOpeningCycle{id ordersFrom ordersUntil name status teamStore{id title logo currentStoreOpeningCycleId slugUrlLocalizations{langCode content}}}"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "fragment adminPortalUser on AdminPortalUser{id userId salesChannelId shopId email status roles createdAt}"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "fragment BaseProductImagePlaceholder on ProductImagePlaceholder{id title description itemValue itemType top left angle width height actualWidth actualHeight actualUnit}"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "fragment dashboardAddressDetailFields on Address{address1 longitude address2 city company countryCode email externalId externalVendor firstName id isValidAddress lastName latitude meta organisationId phone provinceCode shopId type vatNumber zip}"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "fragment DashboardAdminUserCommonFieldsNB on AdminUser{id firstName lastName email updatedAt lastLogin status assignedPosition manager{id firstName lastName email}}"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "fragment dashboardCollectionCommonFieldsNB on ProductCollection{id shopId defaultTitle thumbnailUrl internalId}"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "fragment dashboardCollectionInformationFieldsNB on ProductCollection{...dashboardCollectionCommonFieldsNB titleLocalizations{langCode content}slugLocalizations{langCode content}descriptionLocalizations{langCode content}}",
        ""
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "fragment dashboardCollectionMediaFieldsNB on ProductCollection{...dashboardCollectionCommonFieldsNB headingBannerUrl}",
        ""
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "fragment dashboardCollectionProductFieldsNB on ProductCollectionProductsEdge{sortOrder node{id defaultTitle thumbnailUrl type}}"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "fragment dashboardParentCollectionFieldsNB on ProductCollection{...dashboardCollectionCommonFieldsNB parentCollections{...dashboardCollectionCommonFieldsNB}}",
        ""
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "fragment dashboardChildCollectionFieldsNB on ProductCollection{...dashboardCollectionCommonFieldsNB childCollections(first:500){edges{sortOrder node{...dashboardCollectionCommonFieldsNB}}}}",
        ""
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "fragment dashboardCollectionAttributeFieldsNB on ProductCollection{...dashboardCollectionCommonFieldsNB}",
        ""
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        "fragment dashboardContactFieldsNB on AccountContact{id name email phone description updatedAt account{id name accountOwner{id firstName lastName email}}}"
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
function _templateObject15() {
    var data = _tagged_template_literal([
        "fragment DashboardContactMicrostoreFieldsNB on AccountContact{id teamStores{id logo title currentStoreOpeningCycle{id status}updatedAt}}"
    ]);
    _templateObject15 = function _templateObject15() {
        return data;
    };
    return data;
}
function _templateObject16() {
    var data = _tagged_template_literal([
        "fragment dashboardDiscountCouponFields on Coupon{id couponCode maxUsageOverall maxUsagePerCustomer currentUsed enabled}"
    ]);
    _templateObject16 = function _templateObject16() {
        return data;
    };
    return data;
}
function _templateObject17() {
    var data = _tagged_template_literal([
        "fragment dashboardDiscountDetailRuleObjectFields on DiscountRuleObject{id discountRuleId value type}"
    ]);
    _templateObject17 = function _templateObject17() {
        return data;
    };
    return data;
}
function _templateObject18() {
    var data = _tagged_template_literal([
        "fragment dashboardDiscountDetailRuleFields on DiscountRule{id currency value objects{...dashboardDiscountDetailRuleObjectFields}objectType condition}",
        ""
    ]);
    _templateObject18 = function _templateObject18() {
        return data;
    };
    return data;
}
function _templateObject19() {
    var data = _tagged_template_literal([
        "fragment dashboardDiscountDetailFields on Discount{id accountingId shopId internalNote enabled category isVisibleToCustomer isVisibleToCartOnly isUpcharge salesChannelId availableFrom availableUntil title titleLocalizations{langCode content}priority canBeCombinedWithOtherDiscounts applyOnce applyBeforeTax applyTo discountType discountValue discountFreeProducts{id productId}discountRules{...dashboardDiscountDetailRuleFields}}",
        ""
    ]);
    _templateObject19 = function _templateObject19() {
        return data;
    };
    return data;
}
function _templateObject20() {
    var data = _tagged_template_literal([
        "fragment dashboardDiscountListItemFields on Discount{id discountType enabled internalNote title availableFrom availableUntil category discountRules{id objectType objects{id value type}}}"
    ]);
    _templateObject20 = function _templateObject20() {
        return data;
    };
    return data;
}
function _templateObject21() {
    var data = _tagged_template_literal([
        "fragment dashboardLeadDetailFields on Lead{id items{id quantity productId productDesignId meta leadId}createdAt langCode meta addressId address{firstName lastName address1 address2 city company countryCode email externalId externalVendor id isValidAddress latitude longitude meta organisationId phone provinceCode shopId type vatNumber zip}organisationId salesChannelId shopId langCode quotes{cartId createdAt deletedAt id comments{comment meta id quoteId}leads{... on _LeadToQuote_Lead{id}}meta name ownedByOrganisationId shopId status updatedAt}items{productId productDesignId quantity meta leadId}status updatedAt}"
    ]);
    _templateObject21 = function _templateObject21() {
        return data;
    };
    return data;
}
function _templateObject22() {
    var data = _tagged_template_literal([
        "fragment billingAddress on Order{billingAddress{id firstName countryCode provinceCode lastName email address1 address2 city company phone vatNumber zip type}}"
    ]);
    _templateObject22 = function _templateObject22() {
        return data;
    };
    return data;
}
function _templateObject23() {
    var data = _tagged_template_literal([
        "fragment dashboardOrderListFields on Order{id no paymentStatus total shippingStatus createdAt updatedAt currencyCode ...billingAddress}",
        ""
    ]);
    _templateObject23 = function _templateObject23() {
        return data;
    };
    return data;
}
function _templateObject24() {
    var data = _tagged_template_literal([
        "fragment dashboardOrderDetailFields on Order{...dashboardOrderListFields meta shopId itemSubTotal subTotal taxTotal taxPercentage organisationId billingAddressId cartId comment shippingAddressId updatedAt salesChannelId taxes shippingTotal shippingTaxTotal shippingSubTotal salesChannelTitle productionStatus appliedDiscounts{amount currencyCode couponCode title orderItemId applyBeforeTax amountBeforeTax}items{total imageUrl subTotal taxTotal unitPrice salesChannelTitle productionStatus customizationOptions{customizationOptionId customizationGroupId title value amount}variantTitle optionValueId variantOptions{optionValueId orderItemId organisationId productId productTitle shopId title value variantId variantOptionId}title customizationTotal id meta productId productDesignId quantity productDesignRevisionNo sku}notes{createdAt createdBy deletedAt id note orderId updatedAt}payments{id providerPaymentId provider refund{amount remain}}cartShippingMethodName shipments{id providerTrackingNumber}history(filter:{payloadTypes:[BRIKL_SIMPLE_MESSAGE,FIELD_UPDATED]}){id createdBy createdAt updatedAt eventType visibility{isHiddenFromUsers}data{type ... on BriklOnlySimpleMessageLogData{BriklOnlySimpleMessageLogData:data}... on FieldUpdatedLogData{FieldUpdatedPayload:data{fields}}}}}",
        ""
    ]);
    _templateObject24 = function _templateObject24() {
        return data;
    };
    return data;
}
function _templateObject25() {
    var data = _tagged_template_literal([
        "fragment dashboardProductAttributeCommonFieldsNB on ProductAttribute{id defaultTitle internalId iconUrl sortOrder}"
    ]);
    _templateObject25 = function _templateObject25() {
        return data;
    };
    return data;
}
function _templateObject26() {
    var data = _tagged_template_literal([
        "fragment dashboardProductAttributeDetailFieldsNB on ProductAttribute{...dashboardProductAttributeCommonFieldsNB titleLocalizations{langCode content}descriptionLocalizations{langCode content}iconSpotlight titleSpotlight}",
        ""
    ]);
    _templateObject26 = function _templateObject26() {
        return data;
    };
    return data;
}
function _templateObject27() {
    var data = _tagged_template_literal([
        "fragment dashboardLeadDetailForQuoteFields on _LeadToQuote_Lead{id createdAt langCode status updatedAt salesChannelId shopId meta address{firstName lastName address1 address2 city company countryCode email externalId externalVendor id isValidAddress latitude longitude meta organisationId phone provinceCode shopId type vatNumber zip}items{productId quantity productDesignId leadId id meta}}"
    ]);
    _templateObject27 = function _templateObject27() {
        return data;
    };
    return data;
}
function _templateObject28() {
    var data = _tagged_template_literal([
        "fragment quoteCommentDetailFields on QuoteComment{id createdAt comment}"
    ]);
    _templateObject28 = function _templateObject28() {
        return data;
    };
    return data;
}
function _templateObject29() {
    var data = _tagged_template_literal([
        "fragment quoteNoteDetailFields on QuoteNote{id createdAt note}"
    ]);
    _templateObject29 = function _templateObject29() {
        return data;
    };
    return data;
}
function _templateObject30() {
    var data = _tagged_template_literal([
        "fragment dashboardQuoteDetailFields on Quote{id name cartId createdAt updatedAt ownedByOrganisationId status salesChannelId currencyCode leads{...dashboardLeadDetailForQuoteFields}comments{...quoteCommentDetailFields}notes{...quoteNoteDetailFields}}",
        "",
        "",
        ""
    ]);
    _templateObject30 = function _templateObject30() {
        return data;
    };
    return data;
}
function _templateObject31() {
    var data = _tagged_template_literal([
        "fragment dashboardQuoteListFields on Quote{id cartId ownedByOrganisationId name createdAt updatedAt status}"
    ]);
    _templateObject31 = function _templateObject31() {
        return data;
    };
    return data;
}
function _templateObject32() {
    var data = _tagged_template_literal([
        "fragment dashboardShopDefaultFontSettingFields on DefaultFontSettings{shopId disabledInDesignStudio createdAt updatedAt}"
    ]);
    _templateObject32 = function _templateObject32() {
        return data;
    };
    return data;
}
function _templateObject33() {
    var data = _tagged_template_literal([
        "fragment dashboardTaxSettingsFields on TaxSettings{shopId defaultTaxRate taxRates{countryCode provinceCode percent}}"
    ]);
    _templateObject33 = function _templateObject33() {
        return data;
    };
    return data;
}
function _templateObject34() {
    var data = _tagged_template_literal([
        "fragment feenyExportDesign on FeeneyDesignExport{createdAt createdBy expiredAt id productDesignId shopId status updatedAt url}"
    ]);
    _templateObject34 = function _templateObject34() {
        return data;
    };
    return data;
}
function _templateObject35() {
    var data = _tagged_template_literal([
        "fragment order on Order{id comment organisationId total payments{id method provider status paymentProviderConfigurationId}salesChannelId paymentStatus createdAt cartId shippingAddressId no productionStatus shippingStatus currencyCode subTotal taxes taxTotal taxProvider cartShippingMethodName shippingTotal shippingSubTotal items{id salesChannelId productId productionStatus productDesignId quantity orderId total subTotal title imageUrl customizationOptions{title value amount}variantOptions{title value}variantTitle sku}appliedDiscounts{id amount couponCode title currencyCode orderItemId discountType isUpcharge isVisibleToCustomer applyBeforeTax amountBeforeTax}billingAddress{type address1 organisationId shopId city countryCode firstName lastName provinceCode phone email zip}shippingTaxTotal shippingTotal shippingSubTotal shippingAddress{type address1 organisationId shopId city countryCode firstName lastName provinceCode phone email zip}notifyToEmail history(filter:{payloadTypes:[BRIKL_SIMPLE_MESSAGE,FIELD_UPDATED]}){id createdAt createdBy updatedAt eventType visibility{isHiddenFromUsers}data{type ... on BriklOnlySimpleMessageLogData{BriklOnlySimpleMessageLogData:data}... on FieldUpdatedLogData{FieldUpdatedPayload:data{fields}}}}}"
    ]);
    _templateObject35 = function _templateObject35() {
        return data;
    };
    return data;
}
function _templateObject36() {
    var data = _tagged_template_literal([
        "fragment ImagePlaceholder on ProductImagePlaceholder{id title description itemValue itemType width height top left angle}"
    ]);
    _templateObject36 = function _templateObject36() {
        return data;
    };
    return data;
}
function _templateObject37() {
    var data = _tagged_template_literal([
        "fragment PlaceholderPositionAndDimension on ProductImagePlaceholderPositionAndDimension{id scaleX scaleY placeholderId productImageIds productId top left angle width height}"
    ]);
    _templateObject37 = function _templateObject37() {
        return data;
    };
    return data;
}
function _templateObject38() {
    var data = _tagged_template_literal([
        "fragment AmbientLightSetting on AmbientLight{id intensity color productLightPresetId visible}"
    ]);
    _templateObject38 = function _templateObject38() {
        return data;
    };
    return data;
}
function _templateObject39() {
    var data = _tagged_template_literal([
        "fragment DomeLightSetting on DomeLight{id intensity skyColor groundColor productLightPresetId visible}"
    ]);
    _templateObject39 = function _templateObject39() {
        return data;
    };
    return data;
}
function _templateObject40() {
    var data = _tagged_template_literal([
        "fragment SpotLightSetting on SpotLight{id intensity color distance castShadow productLightPresetId visible position}"
    ]);
    _templateObject40 = function _templateObject40() {
        return data;
    };
    return data;
}
function _templateObject41() {
    var data = _tagged_template_literal([
        "fragment ProductLightSettingPreset on ProductLightPreset{id shopId title default createdAt updatedAt ambientLights{...AmbientLightSetting}domeLights{...DomeLightSetting}spotLights{...SpotLightSetting}}",
        "",
        "",
        ""
    ]);
    _templateObject41 = function _templateObject41() {
        return data;
    };
    return data;
}
function _templateObject42() {
    var data = _tagged_template_literal([
        "fragment ProductLightPreset on ProductLightPreset{title ambientLights{...AmbientLightSetting}domeLights{...DomeLightSetting}spotLights{...SpotLightSetting}}",
        "",
        "",
        ""
    ]);
    _templateObject42 = function _templateObject42() {
        return data;
    };
    return data;
}
function _templateObject43() {
    var data = _tagged_template_literal([
        "fragment productPriceFieldsNB on PricingConfiguration{price{currencyCode amount}roundingConfiguration{roundAfterTax mode digits}}"
    ]);
    _templateObject43 = function _templateObject43() {
        return data;
    };
    return data;
}
function _templateObject44() {
    var data = _tagged_template_literal([
        "fragment productWeightFieldsNB on ProductWeight{id unit value}"
    ]);
    _templateObject44 = function _templateObject44() {
        return data;
    };
    return data;
}
function _templateObject45() {
    var data = _tagged_template_literal([
        "fragment productVariantsFieldsNB on ProductVariant{id pricingConfiguration{price{currencyCode amount}}sku title accountingId weight{...productWeightFieldsNB}optionValues{id productOption{type}}active inventory{quantity}}",
        ""
    ]);
    _templateObject45 = function _templateObject45() {
        return data;
    };
    return data;
}
function _templateObject46() {
    var data = _tagged_template_literal([
        "fragment productVariantsWithOptionValueFieldsNB on ProductVariant{id sku accountingId title active optionValues{value defaultTitle productOptionId}}"
    ]);
    _templateObject46 = function _templateObject46() {
        return data;
    };
    return data;
}
function _templateObject47() {
    var data = _tagged_template_literal([
        "fragment quoteDetailFields on Quote{id name cartId createdAt updatedAt ownedByOrganisationId status salesChannelId currencyCode comments{...quoteCommentDetailFields}notes{...quoteNoteDetailFields}}",
        "",
        ""
    ]);
    _templateObject47 = function _templateObject47() {
        return data;
    };
    return data;
}
function _templateObject48() {
    var data = _tagged_template_literal([
        "fragment reportFields on Report{id url status type shopId updatedAt expireAt createdBy createdAt}"
    ]);
    _templateObject48 = function _templateObject48() {
        return data;
    };
    return data;
}
function _templateObject49() {
    var data = _tagged_template_literal([
        "fragment MenuInfoFields on Menu{id shopId title createdAt updatedAt}"
    ]);
    _templateObject49 = function _templateObject49() {
        return data;
    };
    return data;
}
function _templateObject50() {
    var data = _tagged_template_literal([
        "fragment MenuItemModuleFields on MenuItemModule{id type data{moduleType}}"
    ]);
    _templateObject50 = function _templateObject50() {
        return data;
    };
    return data;
}
function _templateObject51() {
    var data = _tagged_template_literal([
        "fragment MenuItemLinkFields on MenuItemLink{id type data{title titleLocalizations{content langCode}href hrefLocalizations{content langCode}isOpenInNewTab}}"
    ]);
    _templateObject51 = function _templateObject51() {
        return data;
    };
    return data;
}
function _templateObject52() {
    var data = _tagged_template_literal([
        "fragment MenuItemLinkFieldsWithChildren on MenuItemLink{...MenuItemLinkFields children{... on MenuItemLink{...MenuItemLinkFields children{... on MenuItemLink{...MenuItemLinkFields children{... on MenuItemLink{...MenuItemLinkFields children{... on MenuItemLink{...MenuItemLinkFields}}}}}}}}}",
        ""
    ]);
    _templateObject52 = function _templateObject52() {
        return data;
    };
    return data;
}
function _templateObject53() {
    var data = _tagged_template_literal([
        "fragment MenuItemFields on MenuItem{... on MenuItemModule{...MenuItemModuleFields}... on MenuItemLink{...MenuItemLinkFieldsWithChildren}}",
        "",
        ""
    ]);
    _templateObject53 = function _templateObject53() {
        return data;
    };
    return data;
}
function _templateObject54() {
    var data = _tagged_template_literal([
        "fragment textLocalizationFieldsNB on LocalizedText{langCode content}"
    ]);
    _templateObject54 = function _templateObject54() {
        return data;
    };
    return data;
}
function _templateObject55() {
    var data = _tagged_template_literal([
        "fragment shippingPriceFieldsNB on ShippingPrice{currency value}"
    ]);
    _templateObject55 = function _templateObject55() {
        return data;
    };
    return data;
}
function _templateObject56() {
    var data = _tagged_template_literal([
        "fragment shippingRateFieldsNB on ShippingRate{id shippingMethodId minimumTotal{...shippingPriceFieldsNB}rate{...shippingPriceFieldsNB}}",
        ""
    ]);
    _templateObject56 = function _templateObject56() {
        return data;
    };
    return data;
}
function _templateObject57() {
    var data = _tagged_template_literal([
        "fragment shippingMethodFieldsNB on ShippingMethodSettings{id shippingZoneId name defaultTitle titleId defaultDescription active titleLocalizations{...textLocalizationFieldsNB}descriptionLocalizations{...textLocalizationFieldsNB}shippingRates{...shippingRateFieldsNB}}",
        "",
        ""
    ]);
    _templateObject57 = function _templateObject57() {
        return data;
    };
    return data;
}
function _templateObject58() {
    var data = _tagged_template_literal([
        "fragment shippingZoneFieldsNB on ShippingZone{id shippingSettingsId name applyToAllCountries countryCodes provinceCodes defaultShippingMethodId active shippingMethods{...shippingMethodFieldsNB}}",
        ""
    ]);
    _templateObject58 = function _templateObject58() {
        return data;
    };
    return data;
}
function _templateObject59() {
    var data = _tagged_template_literal([
        "fragment shippingSettingFieldsNB on ShippingSettings{id shopId salesChannelId enabled shippingZones{...shippingZoneFieldsNB}}",
        ""
    ]);
    _templateObject59 = function _templateObject59() {
        return data;
    };
    return data;
}
function _templateObject60() {
    var data = _tagged_template_literal([
        "fragment shopAddress on Address{address1 address2 city company countryCode email externalId externalVendor firstName id isValidAddress lastName latitude longitude meta organisationId phone provinceCode shopId type vatNumber zip}"
    ]);
    _templateObject60 = function _templateObject60() {
        return data;
    };
    return data;
}
function _templateObject61() {
    var data = _tagged_template_literal([
        "mutation bulkDeleteDashboardCouponCodesNB($couponCodeIds:[ID!]!){bulkDeleteCoupons(ids:$couponCodeIds){...BulkOperationResultNB}}",
        ""
    ]);
    _templateObject61 = function _templateObject61() {
        return data;
    };
    return data;
}
function _templateObject62() {
    var data = _tagged_template_literal([
        "mutation bulkUpdateDashboardCouponCodesNB($inputs:[BulkUpdateCouponInput!]!){bulkUpdateCoupons(inputs:$inputs){...BulkOperationResultNB}}",
        ""
    ]);
    _templateObject62 = function _templateObject62() {
        return data;
    };
    return data;
}
function _templateObject63() {
    var data = _tagged_template_literal([
        "mutation addDashboardAtrributeToProductNB($productId:ID!,$productAttributeId:ID!){addAttributeToProduct(productId:$productId productAttributeId:$productAttributeId){id}}"
    ]);
    _templateObject63 = function _templateObject63() {
        return data;
    };
    return data;
}
function _templateObject64() {
    var data = _tagged_template_literal([
        "mutation addDashboardChildCollectionNB($collectionId:ID!,$input:AddChildProductCollectionInput!){addChildProductCollection(productCollectionId:$collectionId,input:$input){...dashboardChildCollectionFieldsNB}}",
        ""
    ]);
    _templateObject64 = function _templateObject64() {
        return data;
    };
    return data;
}
function _templateObject65() {
    var data = _tagged_template_literal([
        "mutation addDashboardCollectionToSalesChannelNB($salesChannelId:ID!,$collectionId:ID!){addCollectionToSalesChannel(salesChannelId:$salesChannelId collectionId:$collectionId){id}}"
    ]);
    _templateObject65 = function _templateObject65() {
        return data;
    };
    return data;
}
function _templateObject66() {
    var data = _tagged_template_literal([
        "mutation addDashboardDiscountFreeProductNB($discountId:ID!,$productId:ID!){addDiscountFreeProduct(discountId:$discountId,productId:$productId){id discountId productId}}"
    ]);
    _templateObject66 = function _templateObject66() {
        return data;
    };
    return data;
}
function _templateObject67() {
    var data = _tagged_template_literal([
        "mutation addDashboardDiscountRuleObjectNB($discountRuleId:ID!,$input:CreateDiscountRuleObjectInput!){addDiscountRuleObject(discountRuleId:$discountRuleId,input:$input){...dashboardDiscountDetailRuleObjectFields}}",
        ""
    ]);
    _templateObject67 = function _templateObject67() {
        return data;
    };
    return data;
}
function _templateObject68() {
    var data = _tagged_template_literal([
        "mutation addDashboardProductPackageToSalesChannel($salesChannelId:ID!,$productPackageId:ID!){addProductPackageToSalesChannel(salesChannelId:$salesChannelId productPackageId:$productPackageId){id}}"
    ]);
    _templateObject68 = function _templateObject68() {
        return data;
    };
    return data;
}
function _templateObject69() {
    var data = _tagged_template_literal([
        "mutation addDashboardProductPackageToSalesChannelNB($salesChannelId:ID!,$productPackageId:ID!){addProductPackageToSalesChannel(salesChannelId:$salesChannelId productPackageId:$productPackageId){id}}"
    ]);
    _templateObject69 = function _templateObject69() {
        return data;
    };
    return data;
}
function _templateObject70() {
    var data = _tagged_template_literal([
        "mutation addDashboardProductToCollectionNB($input:AddProductToCollectionInput!){addProductToCollection(input:$input){...dashboardCollectionCommonFieldsNB}}",
        ""
    ]);
    _templateObject70 = function _templateObject70() {
        return data;
    };
    return data;
}
function _templateObject71() {
    var data = _tagged_template_literal([
        "mutation addDashboardProductToSalesChannelNB($salesChannelId:ID!,$productId:ID!){addProductToSalesChannel(salesChannelId:$salesChannelId,productId:$productId){id}}"
    ]);
    _templateObject71 = function _templateObject71() {
        return data;
    };
    return data;
}
function _templateObject72() {
    var data = _tagged_template_literal([
        "mutation addLead($input:CreateLeadInput!){createLead(input:$input){leadId}}"
    ]);
    _templateObject72 = function _templateObject72() {
        return data;
    };
    return data;
}
function _templateObject73() {
    var data = _tagged_template_literal([
        "mutation applyProductImagesToPlaceholderNB($id:ID!,$input:UpdateProductImagePlaceholderPositionAndDimensionInput!){updateProductImagePlaceholderPositionAndDimension(id:$id,input:$input){id productImageIds}}"
    ]);
    _templateObject73 = function _templateObject73() {
        return data;
    };
    return data;
}
function _templateObject74() {
    var data = _tagged_template_literal([
        "mutation applyDashboardProductLightPresetToProductNBE($presetId:ID!,$productId:ID!){applyProductLightPresetToProduct(presetId:$presetId,productId:$productId){id}}"
    ]);
    _templateObject74 = function _templateObject74() {
        return data;
    };
    return data;
}
function _templateObject75() {
    var data = _tagged_template_literal([
        "mutation assignAdminPortalUserRole($input:AssignRoleToShopUserInput!){assignRoleToShopUser(input:$input){success}}"
    ]);
    _templateObject75 = function _templateObject75() {
        return data;
    };
    return data;
}
function _templateObject76() {
    var data = _tagged_template_literal([
        "mutation assignDashboardAccountContactFromAccountNB($contactId:ID!,$accountId:ID!){assignAccountContactToAccount(contactId:$contactId,accountId:$accountId){success}}"
    ]);
    _templateObject76 = function _templateObject76() {
        return data;
    };
    return data;
}
function _templateObject77() {
    var data = _tagged_template_literal([
        "mutation assignDashboardBrandToProductNB($id:ID!,$brandId:ID!){assignBrandToProduct(id:$id,brandId:$brandId){success}}"
    ]);
    _templateObject77 = function _templateObject77() {
        return data;
    };
    return data;
}
function _templateObject78() {
    var data = _tagged_template_literal([
        "mutation assignDashboardContactToTeamStoresNB($contactId:ID!,$teamstoreIds:[ID!]!){assignContactToTeamStores(contactId:$contactId,teamStoreIds:$teamstoreIds){success}}"
    ]);
    _templateObject78 = function _templateObject78() {
        return data;
    };
    return data;
}
function _templateObject79() {
    var data = _tagged_template_literal([
        "mutation assignDashboardManagerToAdminUserNB($adminUserId:ID!,$managerId:ID!){assignManagerToAdminUser(adminUserId:$adminUserId,managerId:$managerId){success}}"
    ]);
    _templateObject79 = function _templateObject79() {
        return data;
    };
    return data;
}
function _templateObject80() {
    var data = _tagged_template_literal([
        "mutation assignDashboardMicrostoreToContactNB($contactId:ID!,$teamstoreIds:[ID!]!){assignContactToTeamStores(contactId:$contactId,teamStoreIds:$teamstoreIds){success}}"
    ]);
    _templateObject80 = function _templateObject80() {
        return data;
    };
    return data;
}
function _templateObject81() {
    var data = _tagged_template_literal([
        "mutation assignPaymentConfiguration($input:CreateSalesChannelPaymentConfigurationInput!){createSalesChannelPaymentConfiguration(input:$input){salesChannelPaymentConfigurationId}}"
    ]);
    _templateObject81 = function _templateObject81() {
        return data;
    };
    return data;
}
function _templateObject82() {
    var data = _tagged_template_literal([
        "mutation assignSalesChannelProductVariantsNB($salesChannelId:ID!,$productId:ID!,$variantIds:[ID!]!){assignSalesChannelProductVariants(salesChannelId:$salesChannelId productId:$productId variantIds:$variantIds){variantId}}"
    ]);
    _templateObject82 = function _templateObject82() {
        return data;
    };
    return data;
}
function _templateObject83() {
    var data = _tagged_template_literal([
        "mutation bulkAddDashboardProductsToSalesChannel($salesChannelId:ID!,$productIds:[ID!]!){bulkAddProductsToSalesChannel(salesChannelId:$salesChannelId productIds:$productIds){id}}"
    ]);
    _templateObject83 = function _templateObject83() {
        return data;
    };
    return data;
}
function _templateObject84() {
    var data = _tagged_template_literal([
        "mutation bulkAddProductToSalesChannels($salesChannelIds:[ID!]!,$productId:ID!){bulkAddProductToSalesChannels(salesChannelIds:$salesChannelIds productId:$productId){id}}"
    ]);
    _templateObject84 = function _templateObject84() {
        return data;
    };
    return data;
}
function _templateObject85() {
    var data = _tagged_template_literal([
        "mutation bulkCreateSalesChannelSavedPlaceholderItemNB($input:BulkCreateSalesChannelSavedPlaceholdersInput!){bulkCreateSalesChannelSavedPlaceholderItem(input:$input){id productImagePlaceholderId itemValue itemName mediaId scaleX scaleY top left angle width height lock}}"
    ]);
    _templateObject85 = function _templateObject85() {
        return data;
    };
    return data;
}
function _templateObject86() {
    var data = _tagged_template_literal([
        "mutation bulkDeleteSalesChannelSavedPlaceholderItemNB($ids:[ID!]!){bulkDeleteSalesChannelSavedPlaceholderItem(ids:$ids){success}}"
    ]);
    _templateObject86 = function _templateObject86() {
        return data;
    };
    return data;
}
function _templateObject87() {
    var data = _tagged_template_literal([
        "mutation bulkUpdateSalesChannelProductsPriceModifier($input:BulkUpdateSalesChannelProductsPriceModifierInput!){bulkUpdateSalesChannelProductsPriceModifier(input:$input){userErrors{code message fields}}}"
    ]);
    _templateObject87 = function _templateObject87() {
        return data;
    };
    return data;
}
function _templateObject88() {
    var data = _tagged_template_literal([
        "mutation bulkUpdateSalesChannelSavedPlaceholderItemNB($ids:[ID!]!,$input:BulkUpdateSalesChannelSavedPlaceholderItemInput!){bulkUpdateSalesChannelSavedPlaceholderItem(ids:$ids,input:$input){success}}"
    ]);
    _templateObject88 = function _templateObject88() {
        return data;
    };
    return data;
}
function _templateObject89() {
    var data = _tagged_template_literal([
        "mutation confirmAdminPortalUserInvitationNB($input:ConfirmAdminPortalInvitationInput!){confirmAdminPortalUserInvitation(input:$input){userId}}"
    ]);
    _templateObject89 = function _templateObject89() {
        return data;
    };
    return data;
}
function _templateObject90() {
    var data = _tagged_template_literal([
        "mutation connectInstagramAccount($code:String!,$redirectUri:String!){connectInstagramAccount(input:{code:$code,redirectUri:$redirectUri}){id expiresAt}}"
    ]);
    _templateObject90 = function _templateObject90() {
        return data;
    };
    return data;
}
function _templateObject91() {
    var data = _tagged_template_literal([
        "mutation copyDashboardDocumentTemplate($id:String!){copyDocumentTemplate(id:$id){id shopId name isPublished createdAt updatedAt tag layout pages dataSetting editor}}"
    ]);
    _templateObject91 = function _templateObject91() {
        return data;
    };
    return data;
}
function _templateObject92() {
    var data = _tagged_template_literal([
        "mutation createCustomPlaceholderNB($input:CreateCustomProductImagePlaceholderWithPositionAndDimensionInput!){createCustomProductImagePlaceholder(input:$input){placeholder{...ImagePlaceholder}productImagePlaceholderPositionAndDimension{...PlaceholderPositionAndDimension}}}",
        "",
        ""
    ]);
    _templateObject92 = function _templateObject92() {
        return data;
    };
    return data;
}
function _templateObject93() {
    var data = _tagged_template_literal([
        "mutation createDashboardAccountSalesRepNB($input:CreateAccountInput!){createAccount(input:$input){id}}"
    ]);
    _templateObject93 = function _templateObject93() {
        return data;
    };
    return data;
}
function _templateObject94() {
    var data = _tagged_template_literal([
        "mutation CreateDashboardAdminUserNB($input:CreateAdminUserInput!,$debugEnabled:Boolean!){createAdminUser(input:$input){id ...DashboardAdminUserCommonFieldsNB @include(if:$debugEnabled)}}",
        ""
    ]);
    _templateObject94 = function _templateObject94() {
        return data;
    };
    return data;
}
function _templateObject95() {
    var data = _tagged_template_literal([
        "mutation createDashboardCollectionNB($input:CreateProductCollectionInput!){createProductCollection(input:$input){id}}"
    ]);
    _templateObject95 = function _templateObject95() {
        return data;
    };
    return data;
}
function _templateObject96() {
    var data = _tagged_template_literal([
        "mutation createDashboardContactNB($input:CreateAccountContactInput!){createAccountContact(input:$input){...dashboardContactFieldsNB}}",
        ""
    ]);
    _templateObject96 = function _templateObject96() {
        return data;
    };
    return data;
}
function _templateObject97() {
    var data = _tagged_template_literal([
        "mutation createDashboardDeploymentNB($input:CreateDeploymentInput!){createDeployment(input:$input){netlifyDeployId}}"
    ]);
    _templateObject97 = function _templateObject97() {
        return data;
    };
    return data;
}
function _templateObject98() {
    var data = _tagged_template_literal([
        "mutation createDashboardDiscountNB($input:CreateDiscountInput!){createDiscount(input:$input){id}}"
    ]);
    _templateObject98 = function _templateObject98() {
        return data;
    };
    return data;
}
function _templateObject99() {
    var data = _tagged_template_literal([
        "mutation createDashboardDiscountRuleNB($discountId:ID!,$input:CreateDiscountRuleInput!){createDiscountRule(discountId:$discountId,input:$input){...dashboardDiscountDetailRuleFields}}",
        ""
    ]);
    _templateObject99 = function _templateObject99() {
        return data;
    };
    return data;
}
function _templateObject100() {
    var data = _tagged_template_literal([
        "mutation createDashboardDocument($input:CreateDocumentInput!){createDocument(input:$input)}"
    ]);
    _templateObject100 = function _templateObject100() {
        return data;
    };
    return data;
}
function _templateObject101() {
    var data = _tagged_template_literal([
        "mutation createDashboardLeadItemNBE($input:CreateNewLeadItemInput!){createLeadItem(input:$input){leadId}}"
    ]);
    _templateObject101 = function _templateObject101() {
        return data;
    };
    return data;
}
function _templateObject102() {
    var data = _tagged_template_literal([
        "mutation createDashboardOpeningCycleNB($teamStoreId:ID!,$input:CreateTeamStoreOpeningCycleInput!){createTeamStoreOpeningCycle(teamStoreId:$teamStoreId,input:$input){id name ordersFrom ordersUntil status}}"
    ]);
    _templateObject102 = function _templateObject102() {
        return data;
    };
    return data;
}
function _templateObject103() {
    var data = _tagged_template_literal([
        "mutation createDashboardPersonalizationOptionNB($personalizationGroupId:ID!,$input:CreateProductPersonalizationOptionInput!){createProductPersonalizationOption(personalizationGroupId:$personalizationGroupId input:$input){id}}"
    ]);
    _templateObject103 = function _templateObject103() {
        return data;
    };
    return data;
}
function _templateObject104() {
    var data = _tagged_template_literal([
        "mutation createDashboardProductAttributeNB($input:CreateProductAttributeInput!){createProductAttribute(input:$input){...dashboardProductAttributeDetailFieldsNB}}",
        ""
    ]);
    _templateObject104 = function _templateObject104() {
        return data;
    };
    return data;
}
function _templateObject105() {
    var data = _tagged_template_literal([
        "mutation createProductFromSupplierNB($input:[CreateProductFromSupplierInput!]!){createProductFromSupplier(input:$input){total success{id}failed{id error}}}"
    ]);
    _templateObject105 = function _templateObject105() {
        return data;
    };
    return data;
}
function _templateObject106() {
    var data = _tagged_template_literal([
        "mutation createDashboardSalesChannelDiscountNB($salesChannelId:ID!,$input:CreateDiscountInput!){createSalesChannelDiscount(salesChannelId:$salesChannelId,input:$input){id}}"
    ]);
    _templateObject106 = function _templateObject106() {
        return data;
    };
    return data;
}
function _templateObject107() {
    var data = _tagged_template_literal([
        "mutation createDashboardShippingMethodNB($shippingZoneId:ID!,$input:CreateShippingMethodInput!){createShippingMethod(zoneId:$shippingZoneId,input:$input){...shippingMethodFieldsNB}}",
        ""
    ]);
    _templateObject107 = function _templateObject107() {
        return data;
    };
    return data;
}
function _templateObject108() {
    var data = _tagged_template_literal([
        "mutation createDashboardShippingSettingsNB($salesChannelId:ID){createShippingSettings(salesChannelId:$salesChannelId){...shippingSettingFieldsNB}}",
        ""
    ]);
    _templateObject108 = function _templateObject108() {
        return data;
    };
    return data;
}
function _templateObject109() {
    var data = _tagged_template_literal([
        "mutation createDashboardShippingZoneNB($shippingSettingId:ID!,$input:CreateShippingZoneInput!){createShippingZone(settingsId:$shippingSettingId,input:$input){...shippingZoneFieldsNB}}",
        ""
    ]);
    _templateObject109 = function _templateObject109() {
        return data;
    };
    return data;
}
function _templateObject110() {
    var data = _tagged_template_literal([
        "mutation createDashboardTeamStoreGroupNB($input:CreateSalesChannelGroupInput!){createSalesChannelGroup(input:$input){id}}"
    ]);
    _templateObject110 = function _templateObject110() {
        return data;
    };
    return data;
}
function _templateObject111() {
    var data = _tagged_template_literal([
        "mutation createDashboardTeamStoreNB($input:CreateTeamStoreInput){createTeamStore(input:$input){id}}"
    ]);
    _templateObject111 = function _templateObject111() {
        return data;
    };
    return data;
}
function _templateObject112() {
    var data = _tagged_template_literal([
        "mutation createDashboardTeamStorePersonalizationGroupNB($salesChannelId:ID!,$input:CreateProductPersonalizationGroupInput!){createSalesChannelPersonalizationGroup(salesChannelId:$salesChannelId input:$input){id}}"
    ]);
    _templateObject112 = function _templateObject112() {
        return data;
    };
    return data;
}
function _templateObject113() {
    var data = _tagged_template_literal([
        "mutation createDashboardTeamStoreTargetCategoryNB($input:CreateSalesChannelTargetCategoryInput!){createSalesChannelTargetCategory(input:$input){id}}"
    ]);
    _templateObject113 = function _templateObject113() {
        return data;
    };
    return data;
}
function _templateObject114() {
    var data = _tagged_template_literal([
        "mutation createEmailDomain($input:CreateEmailDomainInput!){createEmailDomain(input:$input){id shopId domain status dnsRecords{name type value}}}"
    ]);
    _templateObject114 = function _templateObject114() {
        return data;
    };
    return data;
}
function _templateObject115() {
    var data = _tagged_template_literal([
        "mutation createNotificationConfig($input:CreateNotificationConfigurationInput!){createNotificationConfiguration(input:$input){id configuration}}"
    ]);
    _templateObject115 = function _templateObject115() {
        return data;
    };
    return data;
}
function _templateObject116() {
    var data = _tagged_template_literal([
        "mutation createEmbellishmentTypeNB($input:CreateEmbellishmentTypeInput!){createEmbellishmentType(input:$input){id}}"
    ]);
    _templateObject116 = function _templateObject116() {
        return data;
    };
    return data;
}
function _templateObject117() {
    var data = _tagged_template_literal([
        "mutation createPaymentMethodInfo($input:CreatePaymentMethodInfoInput!){createPaymentMethodInfo(input:$input){id salesChannelId provider method instructionsId}}"
    ]);
    _templateObject117 = function _templateObject117() {
        return data;
    };
    return data;
}
function _templateObject118() {
    var data = _tagged_template_literal([
        "mutation createProductDesignApproval($input:CreateProductDesignApprovalInput!){createProductDesignApproval(input:$input){id productDesignId status updatedAt updatedBy}}"
    ]);
    _templateObject118 = function _templateObject118() {
        return data;
    };
    return data;
}
function _templateObject119() {
    var data = _tagged_template_literal([
        "mutation createProductDesignComment($input:CreateProductDesignCommentInput!){createProductDesignComment(input:$input){id comment role createdAt userId firstName lastName email}}"
    ]);
    _templateObject119 = function _templateObject119() {
        return data;
    };
    return data;
}
function _templateObject120() {
    var data = _tagged_template_literal([
        "mutation createProductImagePlaceholderPositionAndDimensionNB($input:CreateProductImagePlaceholderPositionAndDimensionInput!){createProductImagePlaceholderPositionAndDimension(input:$input){...PlaceholderPositionAndDimension placeholder{...ImagePlaceholder}}}",
        "",
        ""
    ]);
    _templateObject120 = function _templateObject120() {
        return data;
    };
    return data;
}
function _templateObject121() {
    var data = _tagged_template_literal([
        "mutation createDashboardProductLightPresetNBE($input:CreateProductLightPresetInput!){createProductLightPreset(input:$input){id}}"
    ]);
    _templateObject121 = function _templateObject121() {
        return data;
    };
    return data;
}
function _templateObject122() {
    var data = _tagged_template_literal([
        "mutation createProductNB($input:CreateProductInput!){createProduct(input:$input){id}}"
    ]);
    _templateObject122 = function _templateObject122() {
        return data;
    };
    return data;
}
function _templateObject123() {
    var data = _tagged_template_literal([
        "mutation CreateSalesChannelMenu($input:CreateMenuInput!){createMenu(input:$input){id ...MenuInfoFields items{...MenuItemFields}}}",
        "",
        ""
    ]);
    _templateObject123 = function _templateObject123() {
        return data;
    };
    return data;
}
function _templateObject124() {
    var data = _tagged_template_literal([
        "mutation dashboardCreateSalesChannelPlaceholderColorwayMediaNB($input:CreateSalesChannelPlaceholderColorwayMediaInput!){createSalesChannelPlaceholderColorwayMedia(input:$input){id productImagePlaceholderId itemValue itemName}}"
    ]);
    _templateObject124 = function _templateObject124() {
        return data;
    };
    return data;
}
function _templateObject125() {
    var data = _tagged_template_literal([
        "mutation createSalesChannelSavedPlaceholderItemNB($input:CreateSalesChannelSavedPlaceholderItemInput!){createSalesChannelSavedPlaceholderItem(input:$input){id productImagePlaceholderId itemValue itemName mediaId scaleX scaleY top left angle width height lock}}"
    ]);
    _templateObject125 = function _templateObject125() {
        return data;
    };
    return data;
}
function _templateObject126() {
    var data = _tagged_template_literal([
        "mutation createShopAddress($input:CreateShopAddressInput!){createShopAddress(input:$input){...shopAddress}}",
        ""
    ]);
    _templateObject126 = function _templateObject126() {
        return data;
    };
    return data;
}
function _templateObject127() {
    var data = _tagged_template_literal([
        "mutation dashboardDisableShopDefaultFont($fontName:String!){disableDefaultFont(fontName:$fontName){...dashboardShopDefaultFontSettingFields}}",
        ""
    ]);
    _templateObject127 = function _templateObject127() {
        return data;
    };
    return data;
}
function _templateObject128() {
    var data = _tagged_template_literal([
        "mutation dashboardEnableShopDefaultFont($fontName:String!){enableDefaultFont(fontName:$fontName){...dashboardShopDefaultFontSettingFields}}",
        ""
    ]);
    _templateObject128 = function _templateObject128() {
        return data;
    };
    return data;
}
function _templateObject129() {
    var data = _tagged_template_literal([
        "mutation dashboardAssignLeadsToQuote($input:AssignLeadsToQuoteInput!){assignLeadsToQuote(input:$input){quoteId}}"
    ]);
    _templateObject129 = function _templateObject129() {
        return data;
    };
    return data;
}
function _templateObject130() {
    var data = _tagged_template_literal([
        "mutation dashboardAssignProductOptionValues($id:ID!,$options:[AssignProductOptionsOptionInput!]!){assignProductOptions(productId:$id,input:{productOptions:$options}){id internalId defaultTitle type}}"
    ]);
    _templateObject130 = function _templateObject130() {
        return data;
    };
    return data;
}
function _templateObject131() {
    var data = _tagged_template_literal([
        "mutation dashboardBulkCreateSalesChannelPlaceholderColorwayMediaNB($salesChannelId:ID!,$productImagePlaceholderId:ID!,$inputs:[BulkCreateSalesChannelPlaceholderColorwayMediaInput!]!){bulkCreateSalesChannelPlaceholderColorwayMedia(salesChannelId:$salesChannelId productImagePlaceholderId:$productImagePlaceholderId inputs:$inputs){id itemValue itemName}}"
    ]);
    _templateObject131 = function _templateObject131() {
        return data;
    };
    return data;
}
function _templateObject132() {
    var data = _tagged_template_literal([
        "mutation dashboardBulkDeleteProductMediaNB($ids:[ID!]!){bulkDeleteProductMedia(ids:$ids){success}}"
    ]);
    _templateObject132 = function _templateObject132() {
        return data;
    };
    return data;
}
function _templateObject133() {
    var data = _tagged_template_literal([
        "mutation dashboardBulkDeleteSalesChannelProductMediaNB($ids:[ID!]!){bulkDeleteSalesChannelProductMedia(ids:$ids){success}}"
    ]);
    _templateObject133 = function _templateObject133() {
        return data;
    };
    return data;
}
function _templateObject134() {
    var data = _tagged_template_literal([
        "mutation dashboardCopyProductNB($id:ID!,$input:CopyProductInput!){copyProduct(productId:$id,input:$input){id}}"
    ]);
    _templateObject134 = function _templateObject134() {
        return data;
    };
    return data;
}
function _templateObject135() {
    var data = _tagged_template_literal([
        "mutation dashboardCreateBulkProductMediaNB($productId:ID!,$input:[CreateProductMediaInput!]!){createProductMediaV2(productId:$productId,input:$input){id sortOrder default isCustomProductMedia isDefaultMediaForOptionValue source visibleInCollection productOptionValueId}}"
    ]);
    _templateObject135 = function _templateObject135() {
        return data;
    };
    return data;
}
function _templateObject136() {
    var data = _tagged_template_literal([
        "mutation dashboardCreateLead($input:CreateLeadInput!){createLead(input:$input){leadId}}"
    ]);
    _templateObject136 = function _templateObject136() {
        return data;
    };
    return data;
}
function _templateObject137() {
    var data = _tagged_template_literal([
        "mutation dashboardCreateOrderNote($orderId:ID!,$note:String!){createOrderNote(input:{orderId:$orderId,note:$note}){orderId order{notes{id note}}}}"
    ]);
    _templateObject137 = function _templateObject137() {
        return data;
    };
    return data;
}
function _templateObject138() {
    var data = _tagged_template_literal([
        "mutation dashboardCreateProductMediaNB($id:ID!,$input:CreateProductMediaInput!){createProductMedia(productId:$id,input:$input){type source sortOrder default visibleInCollection productOptionValueId}}"
    ]);
    _templateObject138 = function _templateObject138() {
        return data;
    };
    return data;
}
function _templateObject139() {
    var data = _tagged_template_literal([
        "mutation dashboardCreateProductOptionNB($input:CreateProductOptionInput!){createProductOption(input:$input){id internalId type}}"
    ]);
    _templateObject139 = function _templateObject139() {
        return data;
    };
    return data;
}
function _templateObject140() {
    var data = _tagged_template_literal([
        "mutation dashboardCreateProductOptionValueNB($input:CreateProductOptionValueInput!){createProductOptionValue(input:$input){id pricingConfiguration{roundingConfiguration{digits mode roundAfterTax}price{currencyCode amount}}productOptionId defaultTitle value sortOrder updatedAt createdAt titleLocalizations{langCode content}}}"
    ]);
    _templateObject140 = function _templateObject140() {
        return data;
    };
    return data;
}
function _templateObject141() {
    var data = _tagged_template_literal([
        "mutation dashboardCreateProductPersonalizationGroupNB($productId:ID!,$input:CreateProductPersonalizationGroupInput!){createProductPersonalizationGroup(productId:$productId,input:$input){id}}"
    ]);
    _templateObject141 = function _templateObject141() {
        return data;
    };
    return data;
}
function _templateObject142() {
    var data = _tagged_template_literal([
        "mutation dashboardCreateProductsNB{createProduct(input:{status:INACTIVE,type:STOCK}){id}}"
    ]);
    _templateObject142 = function _templateObject142() {
        return data;
    };
    return data;
}
function _templateObject143() {
    var data = _tagged_template_literal([
        "mutation dashboardCreateQuote($input:CreateQuoteInput!){createQuote(input:$input){quoteId}}"
    ]);
    _templateObject143 = function _templateObject143() {
        return data;
    };
    return data;
}
function _templateObject144() {
    var data = _tagged_template_literal([
        "mutation dashboardCreateQuoteComment($quoteId:ID!,$comment:String!){createQuoteComment(input:{quoteId:$quoteId,comment:$comment}){quoteId}}"
    ]);
    _templateObject144 = function _templateObject144() {
        return data;
    };
    return data;
}
function _templateObject145() {
    var data = _tagged_template_literal([
        "mutation dashboardCreateQuoteNote($quoteId:ID!,$note:String!){createQuoteNote(input:{quoteId:$quoteId,note:$note}){quoteId}}"
    ]);
    _templateObject145 = function _templateObject145() {
        return data;
    };
    return data;
}
function _templateObject146() {
    var data = _tagged_template_literal([
        "mutation dashboardCreateReport($input:CreateReportInput!){createReport(input:$input){reportId}}"
    ]);
    _templateObject146 = function _templateObject146() {
        return data;
    };
    return data;
}
function _templateObject147() {
    var data = _tagged_template_literal([
        "mutation dashboardCreateSalesChannelProductMediaV2NB($id:ID!,$input:[CreateSalesChannelProductMediaInputV2!]!){createSalesChannelProductMediaV2(salesChannelProductId:$id,input:$input){id salesChannelId baseProductMediaId productMediaId default sortOrder}}"
    ]);
    _templateObject147 = function _templateObject147() {
        return data;
    };
    return data;
}
function _templateObject148() {
    var data = _tagged_template_literal([
        "mutation dashboardCreateOrderShipment($input:CreateShipmentInput!){createShipment(input:$input){shipmentId shipment{providerTrackingNumber}}}"
    ]);
    _templateObject148 = function _templateObject148() {
        return data;
    };
    return data;
}
function _templateObject149() {
    var data = _tagged_template_literal([
        "mutation dashboardDeleteOrderNote($orderId:ID!,$noteId:ID!){deleteOrderNote(input:{orderId:$orderId,noteId:$noteId}){orderId order{notes{id note}}}}"
    ]);
    _templateObject149 = function _templateObject149() {
        return data;
    };
    return data;
}
function _templateObject150() {
    var data = _tagged_template_literal([
        "mutation dashboardDeleteProductByIdNB($id:ID!){deleteProduct(id:$id){success}}"
    ]);
    _templateObject150 = function _templateObject150() {
        return data;
    };
    return data;
}
function _templateObject151() {
    var data = _tagged_template_literal([
        "mutation dashboardDeleteProductMedia($id:ID!){deleteProductMedia(id:$id){success}}"
    ]);
    _templateObject151 = function _templateObject151() {
        return data;
    };
    return data;
}
function _templateObject152() {
    var data = _tagged_template_literal([
        "mutation dashboardDeleteProductMediaNB($id:ID!){deleteProductMedia(id:$id){success}}"
    ]);
    _templateObject152 = function _templateObject152() {
        return data;
    };
    return data;
}
function _templateObject153() {
    var data = _tagged_template_literal([
        "mutation dashboardDeleteProductOptionByIdNB($id:ID!){deleteProductOption(id:$id){success}}"
    ]);
    _templateObject153 = function _templateObject153() {
        return data;
    };
    return data;
}
function _templateObject154() {
    var data = _tagged_template_literal([
        "mutation dashboardDeleteProductOptionValueNB($id:ID!){deleteProductOptionValue(id:$id){success}}"
    ]);
    _templateObject154 = function _templateObject154() {
        return data;
    };
    return data;
}
function _templateObject155() {
    var data = _tagged_template_literal([
        "mutation dashboardDeleteQuote($input:DeleteQuoteInput!){deleteQuote(input:$input){quoteId}}"
    ]);
    _templateObject155 = function _templateObject155() {
        return data;
    };
    return data;
}
function _templateObject156() {
    var data = _tagged_template_literal([
        "mutation dashboardDeleteQuoteComment($quoteId:ID!,$commentId:ID!){deleteQuoteComment(input:{quoteId:$quoteId,commentId:$commentId}){quoteId}}"
    ]);
    _templateObject156 = function _templateObject156() {
        return data;
    };
    return data;
}
function _templateObject157() {
    var data = _tagged_template_literal([
        "mutation dashboardGenerateOrderInvoice($input:GenerateOrderInvoiceInput!){generateOrderInvoice(input:$input){url}}"
    ]);
    _templateObject157 = function _templateObject157() {
        return data;
    };
    return data;
}
function _templateObject158() {
    var data = _tagged_template_literal([
        "mutation dashboardRemoveLeadFromQuote($input:RemoveLeadFromQuoteInput!){removeLeadFromQuote(input:$input){quoteId}}"
    ]);
    _templateObject158 = function _templateObject158() {
        return data;
    };
    return data;
}
function _templateObject159() {
    var data = _tagged_template_literal([
        "mutation dashboardTriggerEvent($input:TriggerEventInput!){triggerEvent(input:$input){messageId}}"
    ]);
    _templateObject159 = function _templateObject159() {
        return data;
    };
    return data;
}
function _templateObject160() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateLeadAddress($input:UpdateLeadAddressInput!){updateLeadAddress(input:$input){leadId}}"
    ]);
    _templateObject160 = function _templateObject160() {
        return data;
    };
    return data;
}
function _templateObject161() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateLeadMetadata($input:updateLeadMetaInput!){updateLeadMetadata(input:$input){leadId}}"
    ]);
    _templateObject161 = function _templateObject161() {
        return data;
    };
    return data;
}
function _templateObject162() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateLeadStatus($input:UpdateLeadStatusInput!){updateLeadStatus(input:$input){lead{status}leadId}}"
    ]);
    _templateObject162 = function _templateObject162() {
        return data;
    };
    return data;
}
function _templateObject163() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateOrderItemVariant($input:UpdateOrderItemVariant!){UpdateOrderItemVariant(input:$input){order{items{id variantOptions{title value optionValueId variantId variantOptionId}}}}}"
    ]);
    _templateObject163 = function _templateObject163() {
        return data;
    };
    return data;
}
function _templateObject164() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateOrderMetadata($input:UpdateOrderMetadataInput!){UpdateOrderMetadata(input:$input){orderId}}"
    ]);
    _templateObject164 = function _templateObject164() {
        return data;
    };
    return data;
}
function _templateObject165() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateOrderPaymentStatus($orderId:ID!,$status:OrderPaymentStatusEnum!,$isHiddenFromUsers:Boolean){UpdateOrder(input:{orderId:$orderId,paymentStatus:$status,isHiddenFromUsers:$isHiddenFromUsers}){orderId}}"
    ]);
    _templateObject165 = function _templateObject165() {
        return data;
    };
    return data;
}
function _templateObject166() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateOrderProductionStatus($orderId:ID!,$productionStatus:ProductionStatus!,$isHiddenFromUsers:Boolean){updateOrderProductionStatus(input:{orderId:$orderId,productionStatus:$productionStatus,isHiddenFromUsers:$isHiddenFromUsers}){order{id productionStatus items{productionStatus}}}}"
    ]);
    _templateObject166 = function _templateObject166() {
        return data;
    };
    return data;
}
function _templateObject167() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateOrderShippingAddress($orderId:ID!,$address:UpdateShippingAddressInput!){UpdateOrderShippingAddress(input:{orderId:$orderId,address:$address}){orderId}}"
    ]);
    _templateObject167 = function _templateObject167() {
        return data;
    };
    return data;
}
function _templateObject168() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateOrderShippingStatus($orderId:ID!,$status:ShippingStatusEnum!,$isHiddenFromUsers:Boolean){UpdateOrder(input:{orderId:$orderId,shippingStatus:$status,isHiddenFromUsers:$isHiddenFromUsers}){orderId}}"
    ]);
    _templateObject168 = function _templateObject168() {
        return data;
    };
    return data;
}
function _templateObject169() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateProductInfoById($id:ID!,$input:UpdateProductInput!){updateProduct(id:$id,input:$input){id}}"
    ]);
    _templateObject169 = function _templateObject169() {
        return data;
    };
    return data;
}
function _templateObject170() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateProductMediaNB($id:ID!,$input:UpdateProductMediaInput!){updateProductMedia(id:$id,input:$input){id type source sortOrder default visibleInCollection productOptionValueId}}"
    ]);
    _templateObject170 = function _templateObject170() {
        return data;
    };
    return data;
}
function _templateObject171() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateProductOptionNB($id:ID!,$input:UpdateProductOptionInput!){updateProductOption(id:$id,input:$input){id}}"
    ]);
    _templateObject171 = function _templateObject171() {
        return data;
    };
    return data;
}
function _templateObject172() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateProductOptionValueNB($id:ID!,$input:UpdateProductOptionValueInput!){updateProductOptionValue(id:$id,input:$input){id}}"
    ]);
    _templateObject172 = function _templateObject172() {
        return data;
    };
    return data;
}
function _templateObject173() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateQuoteCurrency($currencyCode:String!,$quoteId:ID!){updateQuoteCurrency(input:{currencyCode:$currencyCode,quoteId:$quoteId}){quoteId}}"
    ]);
    _templateObject173 = function _templateObject173() {
        return data;
    };
    return data;
}
function _templateObject174() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateQuoteName($quoteId:ID!,$name:String!){updateQuoteName(input:{quoteId:$quoteId,name:$name}){quoteId}}"
    ]);
    _templateObject174 = function _templateObject174() {
        return data;
    };
    return data;
}
function _templateObject175() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateQuoteSalesChannel($salesChannelId:String!,$quoteId:ID!){updateQuoteSalesChannel(input:{salesChannelId:$salesChannelId,quoteId:$quoteId}){quoteId}}"
    ]);
    _templateObject175 = function _templateObject175() {
        return data;
    };
    return data;
}
function _templateObject176() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateQuoteStatus($quoteId:ID!,$status:UpdatableQuoteStatus!){updateQuoteStatus(input:{quoteId:$quoteId,status:$status}){quoteId}}"
    ]);
    _templateObject176 = function _templateObject176() {
        return data;
    };
    return data;
}
function _templateObject177() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateReorderProductMediaNB($input:[ReorderProductMediaInput!]!){reorderProductMedia(input:$input){id sortOrder}}"
    ]);
    _templateObject177 = function _templateObject177() {
        return data;
    };
    return data;
}
function _templateObject178() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateReorderSalesChannelProductMediaNB($input:[ReorderSalesChannelProductMediaInput!]!){reorderSalesChannelProductMedia(input:$input){id sortOrder}}"
    ]);
    _templateObject178 = function _templateObject178() {
        return data;
    };
    return data;
}
function _templateObject179() {
    var data = _tagged_template_literal([
        "mutation dashboardUpdateTermOfServiceAcceptance{acceptTermOfService{tosAcceptedAt}}"
    ]);
    _templateObject179 = function _templateObject179() {
        return data;
    };
    return data;
}
function _templateObject180() {
    var data = _tagged_template_literal([
        "mutation deleteAdminPortalUserNB($id:ID!,$salesChannelId:ID!){removeAdminPortalUser(id:$id,salesChannelId:$salesChannelId){success}}"
    ]);
    _templateObject180 = function _templateObject180() {
        return data;
    };
    return data;
}
function _templateObject181() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardAccountNBE($id:ID!){deleteAccount(id:$id){success}}"
    ]);
    _templateObject181 = function _templateObject181() {
        return data;
    };
    return data;
}
function _templateObject182() {
    var data = _tagged_template_literal([
        "mutation DeleteDashboardAdminUserNB($userId:ID!){deleteAdminUser(id:$userId){success}}"
    ]);
    _templateObject182 = function _templateObject182() {
        return data;
    };
    return data;
}
function _templateObject183() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardCollectionNB($collectionId:ID!){deleteProductCollection(id:$collectionId){success}}"
    ]);
    _templateObject183 = function _templateObject183() {
        return data;
    };
    return data;
}
function _templateObject184() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardCollectionTaxRateNB($collectionId:ID!,$input:DeleteTaxRateInput!){deleteCollectionTaxRate(collectionId:$collectionId,input:$input){success}}"
    ]);
    _templateObject184 = function _templateObject184() {
        return data;
    };
    return data;
}
function _templateObject185() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardContactNBE($id:ID!){deleteAccountContact(id:$id){success}}"
    ]);
    _templateObject185 = function _templateObject185() {
        return data;
    };
    return data;
}
function _templateObject186() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardDiscountFreeProductNB($discountFreeProductId:ID!){deleteDiscountFreeProduct(id:$discountFreeProductId){success}}"
    ]);
    _templateObject186 = function _templateObject186() {
        return data;
    };
    return data;
}
function _templateObject187() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardDiscountNB($id:ID!){deleteDiscount(id:$id){success}}"
    ]);
    _templateObject187 = function _templateObject187() {
        return data;
    };
    return data;
}
function _templateObject188() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardDiscountRuleNB($id:ID!){deleteDiscountRule(id:$id){success}}"
    ]);
    _templateObject188 = function _templateObject188() {
        return data;
    };
    return data;
}
function _templateObject189() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardDiscountRuleObjectNB($id:ID!){deleteDiscountRuleObject(id:$id){success}}"
    ]);
    _templateObject189 = function _templateObject189() {
        return data;
    };
    return data;
}
function _templateObject190() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardDocumentTemplate($id:String!){deleteDocumentTemplate(id:$id){success}}"
    ]);
    _templateObject190 = function _templateObject190() {
        return data;
    };
    return data;
}
function _templateObject191() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardOpeningCycleNB($id:ID!){deleteTeamStoreOpeningCycle(id:$id){success}}"
    ]);
    _templateObject191 = function _templateObject191() {
        return data;
    };
    return data;
}
function _templateObject192() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardPersonalizationOptionNB($id:ID!){deleteProductPersonalizationOption(id:$id){success}}"
    ]);
    _templateObject192 = function _templateObject192() {
        return data;
    };
    return data;
}
function _templateObject193() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardProductAttributeNB($productAttributeId:ID!){deleteProductAttribute(id:$productAttributeId){success}}"
    ]);
    _templateObject193 = function _templateObject193() {
        return data;
    };
    return data;
}
function _templateObject194() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardProductPersonalizationGroupNB($id:ID!){deleteProductPersonalizationGroup(id:$id){success}}"
    ]);
    _templateObject194 = function _templateObject194() {
        return data;
    };
    return data;
}
function _templateObject195() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardProductVariantNB($productVariantId:ID!){deleteProductVariant(id:$productVariantId){success}}"
    ]);
    _templateObject195 = function _templateObject195() {
        return data;
    };
    return data;
}
function _templateObject196() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardShippingMethodNB($shippingMethodId:ID!){deleteShippingMethod(id:$shippingMethodId){success}}"
    ]);
    _templateObject196 = function _templateObject196() {
        return data;
    };
    return data;
}
function _templateObject197() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardShippingSettingsNB($id:ID!){deleteShippingSettings(id:$id){success}}"
    ]);
    _templateObject197 = function _templateObject197() {
        return data;
    };
    return data;
}
function _templateObject198() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardShippingZoneNB($shippingZoneId:ID!){deleteShippingZone(id:$shippingZoneId){success}}"
    ]);
    _templateObject198 = function _templateObject198() {
        return data;
    };
    return data;
}
function _templateObject199() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardStorefrontNB($id:ID!){deleteStoreFront(id:$id){success}}"
    ]);
    _templateObject199 = function _templateObject199() {
        return data;
    };
    return data;
}
function _templateObject200() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardTaxRateNB($input:DeleteTaxRateInput!){deleteTaxRate(input:$input){success}}"
    ]);
    _templateObject200 = function _templateObject200() {
        return data;
    };
    return data;
}
function _templateObject201() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardTeamStoreGroupNB($id:ID!){deleteSalesChannelGroup(id:$id){success}}"
    ]);
    _templateObject201 = function _templateObject201() {
        return data;
    };
    return data;
}
function _templateObject202() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardTeamStoreNB($id:ID!){deleteTeamStore(id:$id){success}}"
    ]);
    _templateObject202 = function _templateObject202() {
        return data;
    };
    return data;
}
function _templateObject203() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardTeamStoreTargetCategoryNB($id:ID!){deleteSalesChannelTargetCategory(id:$id){success}}"
    ]);
    _templateObject203 = function _templateObject203() {
        return data;
    };
    return data;
}
function _templateObject204() {
    var data = _tagged_template_literal([
        "mutation deleteEmailDomain($id:ID!){deleteEmailDomain(id:$id){success}}"
    ]);
    _templateObject204 = function _templateObject204() {
        return data;
    };
    return data;
}
function _templateObject205() {
    var data = _tagged_template_literal([
        "mutation deleteEmbellishmentTypeNB($id:ID!){deleteEmbellishmentType(id:$id){success}}"
    ]);
    _templateObject205 = function _templateObject205() {
        return data;
    };
    return data;
}
function _templateObject206() {
    var data = _tagged_template_literal([
        "mutation deleteEmbellishmentTypesNB($ids:[ID!]!){deleteEmbellishmentTypes(ids:$ids){success}}"
    ]);
    _templateObject206 = function _templateObject206() {
        return data;
    };
    return data;
}
function _templateObject207() {
    var data = _tagged_template_literal([
        "mutation deletePaymentMethodInfo($input:DeletePaymentMethodInfoInput!){deletePaymentMethodInfo(input:$input){success}}"
    ]);
    _templateObject207 = function _templateObject207() {
        return data;
    };
    return data;
}
function _templateObject208() {
    var data = _tagged_template_literal([
        "mutation deleteProductImagePlaceholderNB($id:ID!){deleteProductImagePlaceholder(id:$id){success}}"
    ]);
    _templateObject208 = function _templateObject208() {
        return data;
    };
    return data;
}
function _templateObject209() {
    var data = _tagged_template_literal([
        "mutation deleteProductImagePlaceholderPositionAndDimensionNB($id:ID!){deleteProductImagePlaceholderPositionAndDimension(id:$id){success}}"
    ]);
    _templateObject209 = function _templateObject209() {
        return data;
    };
    return data;
}
function _templateObject210() {
    var data = _tagged_template_literal([
        "mutation deleteDashboardProductLightPresetNBE($id:ID!){deleteProductLightPreset(id:$id){success}}"
    ]);
    _templateObject210 = function _templateObject210() {
        return data;
    };
    return data;
}
function _templateObject211() {
    var data = _tagged_template_literal([
        "mutation dashboardDeleteSalesChannelPlaceholderColorwayMediaNB($id:ID!){deleteSalesChannelPlaceholderColorwayMedia(id:$id){success}}"
    ]);
    _templateObject211 = function _templateObject211() {
        return data;
    };
    return data;
}
function _templateObject212() {
    var data = _tagged_template_literal([
        "mutation deleteSalesChannelProductMediaNB($id:ID!){deleteSalesChannelProductMedia(id:$id){success}}"
    ]);
    _templateObject212 = function _templateObject212() {
        return data;
    };
    return data;
}
function _templateObject213() {
    var data = _tagged_template_literal([
        "mutation deleteSalesChannelSavedPlaceholderItemNB($id:ID!){deleteSalesChannelSavedPlaceholderItem(id:$id){success}}"
    ]);
    _templateObject213 = function _templateObject213() {
        return data;
    };
    return data;
}
function _templateObject214() {
    var data = _tagged_template_literal([
        "mutation disableShippingSettingsNB($id:ID!){disableShippingSettings(id:$id){id}}"
    ]);
    _templateObject214 = function _templateObject214() {
        return data;
    };
    return data;
}
function _templateObject215() {
    var data = _tagged_template_literal([
        "mutation duplicateDashboardTeamStoreNB($id:ID!,$input:DuplicateTeamStoreInput!){duplicateTeamStore(id:$id,input:$input){id}}"
    ]);
    _templateObject215 = function _templateObject215() {
        return data;
    };
    return data;
}
function _templateObject216() {
    var data = _tagged_template_literal([
        "mutation enableShippingSettingsNB($id:ID!){enableShippingSettings(id:$id){...shippingSettingFieldsNB}}",
        ""
    ]);
    _templateObject216 = function _templateObject216() {
        return data;
    };
    return data;
}
function _templateObject217() {
    var data = _tagged_template_literal([
        "mutation exportCustomImagePlaceholder($input:ExportCustomImagePlaceholderInput!){exportCustomImagePlaceholder(input:$input){jobId}}"
    ]);
    _templateObject217 = function _templateObject217() {
        return data;
    };
    return data;
}
function _templateObject218() {
    var data = _tagged_template_literal([
        "mutation exportFeenyDesign($input:exportFeeneyDesignInput!){exportFeeneyDesign(input:$input){exportId}}"
    ]);
    _templateObject218 = function _templateObject218() {
        return data;
    };
    return data;
}
function _templateObject219() {
    var data = _tagged_template_literal([
        "mutation generateDashboardBulkOrderInvoices($input:GenerateBulkOrderInvoicesInput!){generateBulkOrderInvoices(input:$input){jobId}}"
    ]);
    _templateObject219 = function _templateObject219() {
        return data;
    };
    return data;
}
function _templateObject220() {
    var data = _tagged_template_literal([
        "mutation generateDashboardBulkOrderInvoicesByIds($orderIds:[ID!]!){generateOrderInvoiceByIds(orderIds:$orderIds){jobId}}"
    ]);
    _templateObject220 = function _templateObject220() {
        return data;
    };
    return data;
}
function _templateObject221() {
    var data = _tagged_template_literal([
        "mutation generateDashboardCouponNB($discountId:ID!,$input:GenerateCouponsInput!){generateCoupons(discountId:$discountId,input:$input){...dashboardDiscountCouponFields}}",
        ""
    ]);
    _templateObject221 = function _templateObject221() {
        return data;
    };
    return data;
}
function _templateObject222() {
    var data = _tagged_template_literal([
        "mutation generateDashboardTeamStoreSlugUrlNB($storeFrontId:ID!,$input:LocalizedTextInput!){autoGenerateSlugUrl(storeFrontId:$storeFrontId,input:$input){langCode content}}"
    ]);
    _templateObject222 = function _templateObject222() {
        return data;
    };
    return data;
}
function _templateObject223() {
    var data = _tagged_template_literal([
        "query getDashboardContactNB($id:ID!){accountContact(id:$id){...dashboardContactFieldsNB}}",
        ""
    ]);
    _templateObject223 = function _templateObject223() {
        return data;
    };
    return data;
}
function _templateObject224() {
    var data = _tagged_template_literal([
        "mutation sendAdminPortalUserInvitationNB($input:SendAdminPortalInvitationsInput!){sendAdminPortalUserInvitations(input:$input){...adminPortalUser}}",
        ""
    ]);
    _templateObject224 = function _templateObject224() {
        return data;
    };
    return data;
}
function _templateObject225() {
    var data = _tagged_template_literal([
        "mutation sendAdminPortalAdminInvitationNB($input:SendAdminPortalInvitationsInput!){sendAdminPortalAdminInvitations(input:$input){...adminPortalUser}}",
        ""
    ]);
    _templateObject225 = function _templateObject225() {
        return data;
    };
    return data;
}
function _templateObject226() {
    var data = _tagged_template_literal([
        "mutation openDashboardDocumentTemplateEditor($id:String!){openEditorDocumentTemplate(id:$id)}"
    ]);
    _templateObject226 = function _templateObject226() {
        return data;
    };
    return data;
}
function _templateObject227() {
    var data = _tagged_template_literal([
        "mutation createPaymentProviderConfiguration($input:CreatePaymentProviderConfigurationInput!){createPaymentProviderConfiguration(input:$input){paymentProviderConfiguration{enabledMethods id name provider providerPublicKey shopId}paymentProviderConfigurationId}}"
    ]);
    _templateObject227 = function _templateObject227() {
        return data;
    };
    return data;
}
function _templateObject228() {
    var data = _tagged_template_literal([
        "mutation dashboardSetupStripeConnect($input:SetupStripeConnectInput!){setupStripeConnect(input:$input){paymentProviderConfigurationId paymentProviderConfiguration{enabledMethods id name provider providerPublicKey shopId}}}"
    ]);
    _templateObject228 = function _templateObject228() {
        return data;
    };
    return data;
}
function _templateObject229() {
    var data = _tagged_template_literal([
        "mutation deletePaymentProviderConfiguration($input:DeletePaymentProviderConfigurationInput!){deletePaymentProviderConfiguration(input:$input){success}}"
    ]);
    _templateObject229 = function _templateObject229() {
        return data;
    };
    return data;
}
function _templateObject230() {
    var data = _tagged_template_literal([
        "mutation previewDashboardDocumentTemplate($id:String!){previewDocumentTemplate(id:$id)}"
    ]);
    _templateObject230 = function _templateObject230() {
        return data;
    };
    return data;
}
function _templateObject231() {
    var data = _tagged_template_literal([
        "mutation publishDashboardDeploymentNB($input:PublishDeploymentInput!){publishDeployment(input:$input){success}}"
    ]);
    _templateObject231 = function _templateObject231() {
        return data;
    };
    return data;
}
function _templateObject232() {
    var data = _tagged_template_literal([
        "mutation refundPayment($input:RefundPaymentInput!){refundPayment(input:$input){payment{id status}}}"
    ]);
    _templateObject232 = function _templateObject232() {
        return data;
    };
    return data;
}
function _templateObject233() {
    var data = _tagged_template_literal([
        "mutation removeAdminPortalUserRole($input:RemoveRoleFromShopUserInput!){removeRoleFromShopUser(input:$input){success}}"
    ]);
    _templateObject233 = function _templateObject233() {
        return data;
    };
    return data;
}
function _templateObject234() {
    var data = _tagged_template_literal([
        "mutation removeDashboardAtrributeFromProductNB($productId:ID!,$productAttributeId:ID!){removeAttributeFromProduct(productId:$productId productAttributeId:$productAttributeId){id}}"
    ]);
    _templateObject234 = function _templateObject234() {
        return data;
    };
    return data;
}
function _templateObject235() {
    var data = _tagged_template_literal([
        "mutation removeDashboardChildCollectionNB($collectionId:ID!,$childCollectionId:ID!){removeChildProductCollection(productCollectionId:$collectionId childProductCollectionId:$childCollectionId){...dashboardChildCollectionFieldsNB}}",
        ""
    ]);
    _templateObject235 = function _templateObject235() {
        return data;
    };
    return data;
}
function _templateObject236() {
    var data = _tagged_template_literal([
        "mutation removeDashboardCollectionFromSalesChannelNB($id:ID!){removeCollectionFromSalesChannel(id:$id){success}}"
    ]);
    _templateObject236 = function _templateObject236() {
        return data;
    };
    return data;
}
function _templateObject237() {
    var data = _tagged_template_literal([
        "mutation removeDashboardProductFromCollectionNB($productId:ID!,$collectionId:ID!){removeProductFromCollection(productId:$productId productCollectionId:$collectionId){...dashboardCollectionCommonFieldsNB products{edges{...dashboardCollectionProductFieldsNB}}}}",
        "",
        ""
    ]);
    _templateObject237 = function _templateObject237() {
        return data;
    };
    return data;
}
function _templateObject238() {
    var data = _tagged_template_literal([
        "mutation removeDashboardProductPackageFromSalesChannel($salesChannelId:ID!,$productPackageId:ID!){addProductPackageToSalesChannel(salesChannelId:$salesChannelId productPackageId:$productPackageId){id}}"
    ]);
    _templateObject238 = function _templateObject238() {
        return data;
    };
    return data;
}
function _templateObject239() {
    var data = _tagged_template_literal([
        "mutation removeDashboardProductPackageFromSalesChannelNB($id:ID!){removeProductPackageFromSalesChannel(id:$id){success}}"
    ]);
    _templateObject239 = function _templateObject239() {
        return data;
    };
    return data;
}
function _templateObject240() {
    var data = _tagged_template_literal([
        "mutation removeDashboardProductToSalesChannelNB($id:ID!){removeProductFromSalesChannel(id:$id){success}}"
    ]);
    _templateObject240 = function _templateObject240() {
        return data;
    };
    return data;
}
function _templateObject241() {
    var data = _tagged_template_literal([
        "mutation removeDashboardProductLightPresetFromProductNBE($productId:ID!){removeProductLightPresetFromProduct(productId:$productId){success}}"
    ]);
    _templateObject241 = function _templateObject241() {
        return data;
    };
    return data;
}
function _templateObject242() {
    var data = _tagged_template_literal([
        "mutation resetDashboardAdminUserPassword($id:ID!){regenerateAdminUserPassword(id:$id){success}}"
    ]);
    _templateObject242 = function _templateObject242() {
        return data;
    };
    return data;
}
function _templateObject243() {
    var data = _tagged_template_literal([
        "query salesChannelConfigurationByPK($shopId:String!,$salesChannelId:String!,$currencyCode:String!){salesChannelPaymentConfigurationByPk(shopId:$shopId currencyCode:$currencyCode salesChannelId:$salesChannelId){shopId currencyCode providerConfiguration{id enabledMethods provider providerPublicKey shopId}}}"
    ]);
    _templateObject243 = function _templateObject243() {
        return data;
    };
    return data;
}
function _templateObject244() {
    var data = _tagged_template_literal([
        "mutation saveDashboardDocumentTemplate($id:ID,$name:String,$type:DocumentTypeInput,$isPublished:Boolean){saveDocumentTemplate(id:$id input:{name:$name,type:$type,isPublished:$isPublished}){id shopId name isPublished createdAt updatedAt tag layout pages dataSetting editor dataType}}"
    ]);
    _templateObject244 = function _templateObject244() {
        return data;
    };
    return data;
}
function _templateObject245() {
    var data = _tagged_template_literal([
        "mutation saveImagePlaceholderNB($input:SaveProductImagePlaceholderInput!){saveImagePlaceholder(input:$input){...BaseProductImagePlaceholder}}",
        ""
    ]);
    _templateObject245 = function _templateObject245() {
        return data;
    };
    return data;
}
function _templateObject246() {
    var data = _tagged_template_literal([
        "mutation setChildProducts($productId:ID!,$input:SetChildProductsInput!){setChildProducts(productId:$productId,input:$input){id}}"
    ]);
    _templateObject246 = function _templateObject246() {
        return data;
    };
    return data;
}
function _templateObject247() {
    var data = _tagged_template_literal([
        "mutation setDashboardCollectionDefaultTaxRateNB($collectionId:ID!,$percent:Float!){setCollectionDefaultTaxRate(collectionId:$collectionId,percent:$percent)}"
    ]);
    _templateObject247 = function _templateObject247() {
        return data;
    };
    return data;
}
function _templateObject248() {
    var data = _tagged_template_literal([
        "mutation setDashboardCollectionTaxRateNB($collectionId:ID!,$input:TaxRateInput!){setCollectionTaxRate(collectionId:$collectionId,input:$input){countryCode provinceCode percent}}"
    ]);
    _templateObject248 = function _templateObject248() {
        return data;
    };
    return data;
}
function _templateObject249() {
    var data = _tagged_template_literal([
        "mutation setDashboardDefaultTaxRateNB($percent:Float!){setDefaultTaxRate(percent:$percent)}"
    ]);
    _templateObject249 = function _templateObject249() {
        return data;
    };
    return data;
}
function _templateObject250() {
    var data = _tagged_template_literal([
        "mutation setDashboardTaxRateNB($input:TaxRateInput!){setTaxRate(input:$input){countryCode provinceCode percent}}"
    ]);
    _templateObject250 = function _templateObject250() {
        return data;
    };
    return data;
}
function _templateObject251() {
    var data = _tagged_template_literal([
        "mutation updateDashboardDefaultProductLightPresetNBE($id:ID!,$isDefault:Boolean!){setDefaultProductLightPreset(id:$id,isDefault:$isDefault){id}}"
    ]);
    _templateObject251 = function _templateObject251() {
        return data;
    };
    return data;
}
function _templateObject252() {
    var data = _tagged_template_literal([
        "mutation softDeleteAssignedPaymentConfiguration($input:DeleteSalesChannelPaymentConfigurationInput!){deleteSalesChannelPaymentConfiguration(input:$input){success}}"
    ]);
    _templateObject252 = function _templateObject252() {
        return data;
    };
    return data;
}
function _templateObject253() {
    var data = _tagged_template_literal([
        "mutation submitContactRequest($input:SubmitContactRequestInput!){submitContactRequest(input:$input){success}}"
    ]);
    _templateObject253 = function _templateObject253() {
        return data;
    };
    return data;
}
function _templateObject254() {
    var data = _tagged_template_literal([
        "mutation syncDashboardProductVariantsNB($productId:ID!){syncProductVariants(productId:$productId){id}}"
    ]);
    _templateObject254 = function _templateObject254() {
        return data;
    };
    return data;
}
function _templateObject255() {
    var data = _tagged_template_literal([
        "mutation toggleDashboardCollectionTaxSettings($collectionId:ID!,$enabled:Boolean!){setCollectionTaxSettingsEnabledFlag(collectionId:$collectionId enabled:$enabled)}"
    ]);
    _templateObject255 = function _templateObject255() {
        return data;
    };
    return data;
}
function _templateObject256() {
    var data = _tagged_template_literal([
        "mutation toggleDashboardDiscountCouponNB($id:ID!,$enabled:Boolean){updateCoupon(id:$id,input:{enabled:$enabled}){...dashboardDiscountCouponFields}}",
        ""
    ]);
    _templateObject256 = function _templateObject256() {
        return data;
    };
    return data;
}
function _templateObject257() {
    var data = _tagged_template_literal([
        "mutation unassignDashboardAccountContactFromAccountNB($contactId:ID!){unassignAccountContactFromAccount(contactId:$contactId){success}}"
    ]);
    _templateObject257 = function _templateObject257() {
        return data;
    };
    return data;
}
function _templateObject258() {
    var data = _tagged_template_literal([
        "mutation unassignDashboardBrandToProductNB($id:ID!){unassignBrandToProduct(id:$id){success}}"
    ]);
    _templateObject258 = function _templateObject258() {
        return data;
    };
    return data;
}
function _templateObject259() {
    var data = _tagged_template_literal([
        "mutation unassignDashboardManagerFromAdminUserNB($adminUserId:ID!){unassignManagerFromAdminUser(adminUserId:$adminUserId){success}}"
    ]);
    _templateObject259 = function _templateObject259() {
        return data;
    };
    return data;
}
function _templateObject260() {
    var data = _tagged_template_literal([
        "mutation unassignDashboardMicrostoreFromContactNB($teamStoreId:ID!){unassignContactFromTeamStores(teamStoreId:$teamStoreId){success}}"
    ]);
    _templateObject260 = function _templateObject260() {
        return data;
    };
    return data;
}
function _templateObject261() {
    var data = _tagged_template_literal([
        "mutation updateAdminUserInfo($input:UpdateAdminUserInfoInput!){updateAdminUserInfo(input:$input){id firstName lastName email}}"
    ]);
    _templateObject261 = function _templateObject261() {
        return data;
    };
    return data;
}
function _templateObject262() {
    var data = _tagged_template_literal([
        "mutation updateAssignedPaymentConfiguration($input:UpdateSalesChannelPaymentConfigurationInput!){updateSalesChannelPaymentConfiguration(input:$input){salesChannelPaymentConfigurationId salesChannelPaymentConfiguration{id currencyCode}}}"
    ]);
    _templateObject262 = function _templateObject262() {
        return data;
    };
    return data;
}
function _templateObject263() {
    var data = _tagged_template_literal([
        "mutation updateDashboardAccountDetailNB($id:ID!,$input:UpdateAccountInput!){updateAccount(id:$id,input:$input){id name type referenceId description updatedAt enabled accountOwner{...DashboardAdminUserCommonFieldsNB}}}",
        ""
    ]);
    _templateObject263 = function _templateObject263() {
        return data;
    };
    return data;
}
function _templateObject264() {
    var data = _tagged_template_literal([
        "mutation UpdateDashboardAdminUserNB($id:ID!,$input:UpdateAdminUserInput!,$debugEnabled:Boolean!){updateAdminUser(id:$id,input:$input){id ...DashboardAdminUserCommonFieldsNB @include(if:$debugEnabled)}}",
        ""
    ]);
    _templateObject264 = function _templateObject264() {
        return data;
    };
    return data;
}
function _templateObject265() {
    var data = _tagged_template_literal([
        "mutation updateDashboardCollectionChildSortOrderNB($collectionId:ID!,$childCollectionId:ID!,$sortOrder:Int){updateProductCollectionChildProductCollection(productCollectionId:$collectionId childProductCollectionId:$childCollectionId input:{sortOrder:$sortOrder}){...dashboardParentCollectionFieldsNB ...dashboardChildCollectionFieldsNB}}",
        "",
        ""
    ]);
    _templateObject265 = function _templateObject265() {
        return data;
    };
    return data;
}
function _templateObject266() {
    var data = _tagged_template_literal([
        "mutation updateDashboardCollectionNB($collectionId:ID!,$input:UpdateProductCollectionInput!,$debugEnabled:Boolean!){updateProductCollection(id:$collectionId,input:$input){id ...dashboardCollectionCommonFieldsNB @include(if:$debugEnabled)...dashboardCollectionInformationFieldsNB @include(if:$debugEnabled)...dashboardCollectionMediaFieldsNB @include(if:$debugEnabled)...dashboardCollectionCommonFieldsNB products @include(if:$debugEnabled){edges{...dashboardCollectionProductFieldsNB}}...dashboardParentCollectionFieldsNB @include(if:$debugEnabled)...dashboardChildCollectionFieldsNB @include(if:$debugEnabled)...dashboardCollectionAttributeFieldsNB @include(if:$debugEnabled)}}",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
    ]);
    _templateObject266 = function _templateObject266() {
        return data;
    };
    return data;
}
function _templateObject267() {
    var data = _tagged_template_literal([
        "mutation updateDashboardCollectionProductSortOrderNB($productId:ID!,$collectionId:ID!,$sortOrder:Int){updateProductCollectionProduct(productCollectionId:$collectionId productId:$productId input:{sortOrder:$sortOrder}){id products{edges{...dashboardCollectionProductFieldsNB}}}}",
        ""
    ]);
    _templateObject267 = function _templateObject267() {
        return data;
    };
    return data;
}
function _templateObject268() {
    var data = _tagged_template_literal([
        "mutation updateDashboardContactNB($id:ID!,$input:UpdateAccountContactInput!){updateAccountContact(id:$id,input:$input){...dashboardContactFieldsNB}}",
        ""
    ]);
    _templateObject268 = function _templateObject268() {
        return data;
    };
    return data;
}
function _templateObject269() {
    var data = _tagged_template_literal([
        "mutation updateDashboardDiscountDetailNB($id:ID!,$input:UpdateDiscountInput!){updateDiscount(id:$id,input:$input){...dashboardDiscountDetailFields}}",
        ""
    ]);
    _templateObject269 = function _templateObject269() {
        return data;
    };
    return data;
}
function _templateObject270() {
    var data = _tagged_template_literal([
        "mutation updateDashboardDiscountRuleNB($id:ID!,$input:UpdateDiscountRuleInput!){updateDiscountRule(id:$id,input:$input){...dashboardDiscountDetailRuleFields}}",
        ""
    ]);
    _templateObject270 = function _templateObject270() {
        return data;
    };
    return data;
}
function _templateObject271() {
    var data = _tagged_template_literal([
        "mutation updateDashboardOpeningCycleNB($id:ID!,$input:UpdateTeamStoreOpeningCycleInput!){updateTeamStoreOpeningCycle(id:$id,input:$input){id name ordersFrom ordersUntil status}}"
    ]);
    _templateObject271 = function _templateObject271() {
        return data;
    };
    return data;
}
function _templateObject272() {
    var data = _tagged_template_literal([
        "mutation updateDashboardPersonalizationOptionNB($id:ID!,$input:UpdateProductPersonalizationOptionInput!){updateProductPersonalizationOption(id:$id,input:$input){id}}"
    ]);
    _templateObject272 = function _templateObject272() {
        return data;
    };
    return data;
}
function _templateObject273() {
    var data = _tagged_template_literal([
        "mutation updateDashboardProductAttributeNB($id:ID!,$input:UpdateProductAttributeInput!){updateProductAttribute(id:$id,input:$input){...dashboardProductAttributeDetailFieldsNB}}",
        ""
    ]);
    _templateObject273 = function _templateObject273() {
        return data;
    };
    return data;
}
function _templateObject274() {
    var data = _tagged_template_literal([
        "mutation updateDashboardProductPersonalizationGroupNB($id:ID!,$input:UpdateProductPersonalizationGroupInput!){updateProductPersonalizationGroup(id:$id,input:$input){inputType titleLocalizations{langCode content}}}"
    ]);
    _templateObject274 = function _templateObject274() {
        return data;
    };
    return data;
}
function _templateObject275() {
    var data = _tagged_template_literal([
        "mutation updateDashboardProductPriceNB($productId:ID!,$price:[PriceInput!],$roundAfterTax:Boolean){updateProduct(id:$productId input:{pricingConfiguration:{price:$price,roundingConfiguration:{mode:HALF_UP,roundAfterTax:$roundAfterTax}}}){id pricingConfiguration{...productPriceFieldsNB}}}",
        ""
    ]);
    _templateObject275 = function _templateObject275() {
        return data;
    };
    return data;
}
function _templateObject276() {
    var data = _tagged_template_literal([
        "mutation updateDashboardProductVariantNB($productVariantId:ID!,$productVariantInput:UpdateProductVariantInput!){updateProductVariant(id:$productVariantId,input:$productVariantInput){id}}"
    ]);
    _templateObject276 = function _templateObject276() {
        return data;
    };
    return data;
}
function _templateObject277() {
    var data = _tagged_template_literal([
        "mutation updateDashboardSalesChannelByIdNB($id:ID!,$input:UpdateSalesChannelInput!){updateSalesChannel(id:$id,input:$input){name}}"
    ]);
    _templateObject277 = function _templateObject277() {
        return data;
    };
    return data;
}
function _templateObject278() {
    var data = _tagged_template_literal([
        "mutation updateDashboardSalesChannelCollectionNB($id:ID!,$input:UpdateSalesChannelCollectionInput!){updateSalesChannelCollection(id:$id,input:$input){id}}"
    ]);
    _templateObject278 = function _templateObject278() {
        return data;
    };
    return data;
}
function _templateObject279() {
    var data = _tagged_template_literal([
        "mutation updateDashboardSalesChannelDefaultPaymentConfigNB($id:ID!,$input:UpdateSalesChannelInput!){updateSalesChannel(id:$id,input:$input){id useSalesChannelPaymentConfigsOnly}}"
    ]);
    _templateObject279 = function _templateObject279() {
        return data;
    };
    return data;
}
function _templateObject280() {
    var data = _tagged_template_literal([
        "mutation updateDashboardSalesChannelProductNB($id:ID!,$input:UpdateSalesChannelProductInput!){updateSalesChannelProduct(id:$id,input:$input){id}}"
    ]);
    _templateObject280 = function _templateObject280() {
        return data;
    };
    return data;
}
function _templateObject281() {
    var data = _tagged_template_literal([
        "mutation updateDashboardSalesChannelProductPackageNB($id:ID!,$input:UpdateSalesChannelProductPackageInput!){updateSalesChannelProductPackage(id:$id,input:$input){id}}"
    ]);
    _templateObject281 = function _templateObject281() {
        return data;
    };
    return data;
}
function _templateObject282() {
    var data = _tagged_template_literal([
        "mutation updateDashboardShippingMethodNB($shippingMethodId:ID!,$input:UpdateShippingMethodInput!){updateShippingMethod(id:$shippingMethodId,input:$input){...shippingMethodFieldsNB}}",
        ""
    ]);
    _templateObject282 = function _templateObject282() {
        return data;
    };
    return data;
}
function _templateObject283() {
    var data = _tagged_template_literal([
        "mutation updateDashboardShippingZoneNB($shippingZoneId:ID!,$input:UpdateShippingZoneInput!){updateShippingZone(id:$shippingZoneId,input:$input){...shippingZoneFieldsNB}}",
        ""
    ]);
    _templateObject283 = function _templateObject283() {
        return data;
    };
    return data;
}
function _templateObject284() {
    var data = _tagged_template_literal([
        "mutation updateDashboardStorefrontContentNB($id:ID!,$input:UpdateStoreFrontInput!){updateStoreFront(id:$id,input:$input){logo favicon}}"
    ]);
    _templateObject284 = function _templateObject284() {
        return data;
    };
    return data;
}
function _templateObject285() {
    var data = _tagged_template_literal([
        "mutation updateDashboardStorefrontInformationNB($id:ID!,$input:UpdateStoreFrontInput!){updateStoreFront(id:$id,input:$input){titleLocalizations{langCode content}domainUrl}}"
    ]);
    _templateObject285 = function _templateObject285() {
        return data;
    };
    return data;
}
function _templateObject286() {
    var data = _tagged_template_literal([
        "mutation updateDashboardTeamStoreAvailabilityNB($id:ID!,$input:UpdateTeamStoreInput!){updateTeamStore(id:$id,input:$input){isPublic hasPassword password timezone showAvailability}}"
    ]);
    _templateObject286 = function _templateObject286() {
        return data;
    };
    return data;
}
function _templateObject287() {
    var data = _tagged_template_literal([
        "mutation updateDashboardTeamStoreContentNB($id:ID!,$input:UpdateTeamStoreInput!){updateTeamStore(id:$id,input:$input){brandColor logo mainBanner mainBannerHeight}}"
    ]);
    _templateObject287 = function _templateObject287() {
        return data;
    };
    return data;
}
function _templateObject288() {
    var data = _tagged_template_literal([
        "mutation updateDashboardTeamStoreGroupNB($id:ID!,$input:UpdateSalesChannelGroupInput!){updateSalesChannelGroup(id:$id,input:$input){name title titleLocalizations{langCode content}}}"
    ]);
    _templateObject288 = function _templateObject288() {
        return data;
    };
    return data;
}
function _templateObject289() {
    var data = _tagged_template_literal([
        "mutation updateDashboardTeamStoreInformationNB($id:ID!,$input:UpdateTeamStoreInput!){updateTeamStore(id:$id,input:$input){titleLocalizations{langCode content}introductionTextLocalizations{langCode content}headlineTitleLocalizations{langCode content}slugUrlLocalizations{langCode content}homepageUrlLocalizations{langCode content}}}"
    ]);
    _templateObject289 = function _templateObject289() {
        return data;
    };
    return data;
}
function _templateObject290() {
    var data = _tagged_template_literal([
        "mutation updateDashboardTeamStoreStrucutreNB($id:ID!,$input:UpdateTeamStoreInput!){updateTeamStore(id:$id,input:$input){isRequiredGroup isRequiredTargetCategory}}"
    ]);
    _templateObject290 = function _templateObject290() {
        return data;
    };
    return data;
}
function _templateObject291() {
    var data = _tagged_template_literal([
        "mutation updateDashboardTeamStoreTargetCategoryNB($id:ID!,$input:UpdateSalesChannelTargetCategoryInput!){updateSalesChannelTargetCategory(id:$id,input:$input){name title titleLocalizations{langCode content}}}"
    ]);
    _templateObject291 = function _templateObject291() {
        return data;
    };
    return data;
}
function _templateObject292() {
    var data = _tagged_template_literal([
        "mutation updateNotificationConfig($input:UpdateNotificationConfigurationInput!){updateNotificationConfiguration(input:$input){id configuration}}"
    ]);
    _templateObject292 = function _templateObject292() {
        return data;
    };
    return data;
}
function _templateObject293() {
    var data = _tagged_template_literal([
        "mutation updateEmbellishmentTypeNB($id:ID!,$input:UpdateEmbellishmentTypeInput!){updateEmbellishmentType(id:$id,input:$input){id}}"
    ]);
    _templateObject293 = function _templateObject293() {
        return data;
    };
    return data;
}
function _templateObject294() {
    var data = _tagged_template_literal([
        "mutation updateOrderTimelineVisibility($input:SetAuditLogVisibilityInput!){setAuditLogVisibility(input:$input){id}}"
    ]);
    _templateObject294 = function _templateObject294() {
        return data;
    };
    return data;
}
function _templateObject295() {
    var data = _tagged_template_literal([
        "mutation updatePaymentMethodInfo($input:UpdatePaymentMethodInfoInput!){updatePaymentMethodInfo(input:$input){id provider method instructions{languages{content}}}}"
    ]);
    _templateObject295 = function _templateObject295() {
        return data;
    };
    return data;
}
function _templateObject296() {
    var data = _tagged_template_literal([
        "mutation updatePaymentProviderConfiguration($input:UpdatePaymentProviderConfigurationInput!){updatePaymentProviderConfiguration(input:$input){paymentProviderConfiguration{enabledMethods id name provider providerPublicKey shopId}paymentProviderConfigurationId}}"
    ]);
    _templateObject296 = function _templateObject296() {
        return data;
    };
    return data;
}
function _templateObject297() {
    var data = _tagged_template_literal([
        "mutation updateProductDesignApproval($input:UpdateProductDesignApprovalInput!){updateProductDesignApproval(input:$input){id productDesignId status updatedAt updatedBy}}"
    ]);
    _templateObject297 = function _templateObject297() {
        return data;
    };
    return data;
}
function _templateObject298() {
    var data = _tagged_template_literal([
        "mutation updateBaseProductImagePlaceholderNB($id:ID!,$input:UpdateProductImagePlaceholderInput!){updateProductImagePlaceholder(id:$id,input:$input){id}}"
    ]);
    _templateObject298 = function _templateObject298() {
        return data;
    };
    return data;
}
function _templateObject299() {
    var data = _tagged_template_literal([
        "mutation updateCustomImagePlaceholderPositionAndDimensionNB($id:ID!,$input:UpdateProductImagePlaceholderPositionAndDimensionInput!){updateProductImagePlaceholderPositionAndDimension(id:$id,input:$input){id}}"
    ]);
    _templateObject299 = function _templateObject299() {
        return data;
    };
    return data;
}
function _templateObject300() {
    var data = _tagged_template_literal([
        "mutation updateDashboardProductLightPresetNBE($id:ID!,$input:UpdateProductLightPresetInput!){updateProductLightPreset(id:$id,input:$input){id}}"
    ]);
    _templateObject300 = function _templateObject300() {
        return data;
    };
    return data;
}
function _templateObject301() {
    var data = _tagged_template_literal([
        "mutation updateSalesChannelProductMediaNB($id:ID!,$input:UpdateSalesChannelProductMediaInput!){updateSalesChannelProductMedia(id:$id,input:$input){id active visibleInMultipleCollection}}"
    ]);
    _templateObject301 = function _templateObject301() {
        return data;
    };
    return data;
}
function _templateObject302() {
    var data = _tagged_template_literal([
        "mutation updateSalesChannelSavedPlaceholderItemNB($input:UpdateSalesChannelSavedPlaceholderItemInput!){updateSalesChannelSavedPlaceholderItem(input:$input){id}}"
    ]);
    _templateObject302 = function _templateObject302() {
        return data;
    };
    return data;
}
function _templateObject303() {
    var data = _tagged_template_literal([
        "mutation updateShopAddress($input:UpdateShopAddressInput!){updateShopAddress(input:$input){...shopAddress}}",
        ""
    ]);
    _templateObject303 = function _templateObject303() {
        return data;
    };
    return data;
}
function _templateObject304() {
    var data = _tagged_template_literal([
        "mutation updateStorefront($id:ID!,$input:UpdateStoreFrontInput!){updateStoreFront(id:$id,input:$input){id}}"
    ]);
    _templateObject304 = function _templateObject304() {
        return data;
    };
    return data;
}
function _templateObject305() {
    var data = _tagged_template_literal([
        "mutation verifyEmailDomainNBE($id:ID!){verifyEmailDomain(id:$id){verified}}"
    ]);
    _templateObject305 = function _templateObject305() {
        return data;
    };
    return data;
}
function _templateObject306() {
    var data = _tagged_template_literal([
        "query adminPortalFundraising($input:OrderDiscountBySalesChannelIdFilterInput!){orderDiscountBySalesChannelId(filter:$input){total discounts{id title value total type}}}"
    ]);
    _templateObject306 = function _templateObject306() {
        return data;
    };
    return data;
}
function _templateObject307() {
    var data = _tagged_template_literal([
        "query adminPortalOrders($input:GetObfuscatedOrdersInput!,$first:Int,$after:String,$last:Int,$before:String){obfuscatedOrders(input:$input){totalOrders newOrders(first:$first,after:$after,last:$last,before:$before){edges{node{createdAt currencyCode obscuredEmail billingAddress{obscuredName}shippingAddress{countryCode}no shippingStatus paymentStatus payments{method}total}}pageInfo{startCursor endCursor hasNextPage hasPreviousPage}}}}"
    ]);
    _templateObject307 = function _templateObject307() {
        return data;
    };
    return data;
}
function _templateObject308() {
    var data = _tagged_template_literal([
        "query adminPortalOrdersOverview($input:GetObfuscatedOrdersInput!,$first:Int){obfuscatedOrders(input:$input){totalSales totalOrders newOrders(first:$first){edges{node{createdAt currencyCode billingAddress{obscuredName}no shippingStatus total}}}}}"
    ]);
    _templateObject308 = function _templateObject308() {
        return data;
    };
    return data;
}
function _templateObject309() {
    var data = _tagged_template_literal([
        "query checkDuplicateEmbellishmentTypeTitleNB($title:String!){checkDuplicateEmbellishmentTypeTitle(title:$title)}"
    ]);
    _templateObject309 = function _templateObject309() {
        return data;
    };
    return data;
}
function _templateObject310() {
    var data = _tagged_template_literal([
        "query checkDuplicatePlaceholderTitleNB($title:String!){checkDuplicatePlaceholderTitle(title:$title)}"
    ]);
    _templateObject310 = function _templateObject310() {
        return data;
    };
    return data;
}
function _templateObject311() {
    var data = _tagged_template_literal([
        "query dashboardAddressDetail($id:String!){addressById(id:$id){...dashboardAddressDetailFields}}",
        ""
    ]);
    _templateObject311 = function _templateObject311() {
        return data;
    };
    return data;
}
function _templateObject312() {
    var data = _tagged_template_literal([
        "query dashboardCommunicationPanelOrders($input:GetObfuscatedOrdersInput!){obfuscatedOrders(input:$input){orders}}"
    ]);
    _templateObject312 = function _templateObject312() {
        return data;
    };
    return data;
}
function _templateObject313() {
    var data = _tagged_template_literal([
        "query dashboardShopDefaultFontSettings{defaultFontSettings{...dashboardShopDefaultFontSettingFields}}",
        ""
    ]);
    _templateObject313 = function _templateObject313() {
        return data;
    };
    return data;
}
function _templateObject314() {
    var data = _tagged_template_literal([
        "query dashboardGetAllProductOptions($first:Int,$before:String,$last:Int,$after:String,$orderBy:[ProductOptionsOrderBy!]){productOptions(first:$first before:$before last:$last after:$after orderBy:$orderBy){pageInfo{hasNextPage hasPreviousPage startCursor endCursor}edges{node{id internalId defaultTitle type sortOrder values{id productOptionId value defaultTitle}}}}}"
    ]);
    _templateObject314 = function _templateObject314() {
        return data;
    };
    return data;
}
function _templateObject315() {
    var data = _tagged_template_literal([
        "query dashboardGetAllReports{reports{...reportFields}}",
        ""
    ]);
    _templateObject315 = function _templateObject315() {
        return data;
    };
    return data;
}
function _templateObject316() {
    var data = _tagged_template_literal([
        "query getDashboardOrderItemProductByIdNB($id:ID!){product(id:$id){id internalId status availableFrom accountingId defaultTitle requestQuotationEnabled type meta defaultTitle childProducts{defaultTitle thumbnailUrl status id internalId accountingId}options{id internalId defaultTitle type values{id value productOptionId defaultTitle titleLocalizations{langCode content}}}personalizations{id inputType title titleLocalizations{langCode content}options{id title titleLocalizations{langCode content}duplicationRule pricingConfiguration{roundingConfiguration{roundAfterTax digits mode}price{amount currencyCode}}}}media{id type source sortOrder default isDefaultMediaForOptionValue visibleInCollection productOptionValueId productOptionValue{id sortOrder value}isCustomProductMedia createdAt}titleLocalizations{langCode content}descriptionLocalizations{langCode content}slugLocalizations{langCode content}pricingConfiguration{...productPriceFieldsNB}collections{...dashboardCollectionCommonFieldsNB}attributes{...dashboardProductAttributeCommonFieldsNB}variants{...productVariantsWithOptionValueFieldsNB}discounts{...dashboardDiscountListItemFields}}}",
        "",
        "",
        "",
        "",
        ""
    ]);
    _templateObject316 = function _templateObject316() {
        return data;
    };
    return data;
}
function _templateObject317() {
    var data = _tagged_template_literal([
        "query dashboardGetProductByIdNB($id:ID!){product(id:$id){id internalId status availableFrom accountingId requestQuotationEnabled type meta weight{...productWeightFieldsNB}defaultTitle childProducts{defaultTitle thumbnailUrl status id internalId accountingId}options{id internalId defaultTitle type values{id value productOptionId defaultTitle titleLocalizations{langCode content}}}personalizations{id inputType title titleLocalizations{langCode content}options{id title titleLocalizations{langCode content}duplicationRule pricingConfiguration{roundingConfiguration{roundAfterTax digits mode}price{amount currencyCode}}}}media{id type source sortOrder default isDefaultMediaForOptionValue visibleInCollection productOptionValueId productOptionValue{id sortOrder value defaultTitle}isCustomProductMedia createdAt}titleLocalizations{langCode content}descriptionLocalizations{langCode content}slugLocalizations{langCode content}pricingConfiguration{...productPriceFieldsNB}collections{...dashboardCollectionCommonFieldsNB}attributes{...dashboardProductAttributeCommonFieldsNB}variants{...productVariantsFieldsNB}discounts{...dashboardDiscountListItemFields}}}",
        "",
        "",
        "",
        "",
        "",
        ""
    ]);
    _templateObject317 = function _templateObject317() {
        return data;
    };
    return data;
}
function _templateObject318() {
    var data = _tagged_template_literal([
        "query dashboardGetProductCollections($id:ID!){product(id:$id){id collections{id internalId defaultTitle thumbnailUrl}}}"
    ]);
    _templateObject318 = function _templateObject318() {
        return data;
    };
    return data;
}
function _templateObject319() {
    var data = _tagged_template_literal([
        "query dashboardGetProductOptionByIdNB($id:ID!){productOption(id:$id){id internalId defaultTitle type sortOrder updatedAt createdAt values{id pricingConfiguration{roundingConfiguration{digits mode roundAfterTax}price{currencyCode amount}}productOptionId defaultTitle value sortOrder updatedAt createdAt titleLocalizations{langCode content}}titleLocalizations{langCode content}}}"
    ]);
    _templateObject319 = function _templateObject319() {
        return data;
    };
    return data;
}
function _templateObject320() {
    var data = _tagged_template_literal([
        "query dashboardGetProductSalesChannelIds($id:ID!){product(id:$id){id salesChannels{id}}}"
    ]);
    _templateObject320 = function _templateObject320() {
        return data;
    };
    return data;
}
function _templateObject321() {
    var data = _tagged_template_literal([
        "query dashboardGetProductSalesChannels($id:ID!){product(id:$id){id salesChannels{id name products{id productId}productPackages{id productPackageId}storeFront{id title logo}teamStore{id title logo}updatedAt}}}"
    ]);
    _templateObject321 = function _templateObject321() {
        return data;
    };
    return data;
}
function _templateObject322() {
    var data = _tagged_template_literal([
        "query dashboardGetProductsNB($filter:ProductsFilterInput!,$first:Int,$before:String,$last:Int,$after:String){products(filter:$filter first:$first before:$before last:$last after:$after){pageInfo{hasNextPage hasPreviousPage startCursor endCursor}edges{node{id defaultTitle internalId thumbnailUrl type status salesChannels{id name teamStore{title}storeFront{title}}}}}}"
    ]);
    _templateObject322 = function _templateObject322() {
        return data;
    };
    return data;
}
function _templateObject323() {
    var data = _tagged_template_literal([
        "query dashboardGetReportById($id:String!){reportById(id:$id){...reportFields}}",
        ""
    ]);
    _templateObject323 = function _templateObject323() {
        return data;
    };
    return data;
}
function _templateObject324() {
    var data = _tagged_template_literal([
        "query dashboardLeadDetail($id:String!){leadById(id:$id){...dashboardLeadDetailFields}}",
        ""
    ]);
    _templateObject324 = function _templateObject324() {
        return data;
    };
    return data;
}
function _templateObject325() {
    var data = _tagged_template_literal([
        "query dashboardLeads($search:Lead_bool_exp,$limit:Int!,$offset:Int!){leads(where:$search limit:$limit offset:$offset order_by:{createdAt:desc}){...dashboardLeadDetailFields}}",
        ""
    ]);
    _templateObject325 = function _templateObject325() {
        return data;
    };
    return data;
}
function _templateObject326() {
    var data = _tagged_template_literal([
        "query dashboardOrderById($id:String!){orderById(id:$id){...dashboardOrderDetailFields}}",
        ""
    ]);
    _templateObject326 = function _templateObject326() {
        return data;
    };
    return data;
}
function _templateObject327() {
    var data = _tagged_template_literal([
        "query dashboardOrders($where:Order_bool_exp,$limit:Int!,$offset:Int!){orders(where:$where limit:$limit offset:$offset order_by:{createdAt:desc}){...dashboardOrderListFields}}",
        ""
    ]);
    _templateObject327 = function _templateObject327() {
        return data;
    };
    return data;
}
function _templateObject328() {
    var data = _tagged_template_literal([
        "query dashboardQuoteById($id:String!){quoteById(id:$id){...dashboardQuoteDetailFields}}",
        ""
    ]);
    _templateObject328 = function _templateObject328() {
        return data;
    };
    return data;
}
function _templateObject329() {
    var data = _tagged_template_literal([
        "query dashboardQuotes($limit:Int!,$offset:Int!,$search:Quote_bool_exp){quotes(where:$search limit:$limit offset:$offset order_by:{createdAt:desc}){...dashboardQuoteListFields}}",
        ""
    ]);
    _templateObject329 = function _templateObject329() {
        return data;
    };
    return data;
}
function _templateObject330() {
    var data = _tagged_template_literal([
        "query dashboardSupplierServiceAvailability{supplierServiceAvailability{isAllowAddProductFromSupplier}}"
    ]);
    _templateObject330 = function _templateObject330() {
        return data;
    };
    return data;
}
function _templateObject331() {
    var data = _tagged_template_literal([
        "query dashboardGetTermOfServiceAcceptance{adminUser{tosAcceptedAt}}"
    ]);
    _templateObject331 = function _templateObject331() {
        return data;
    };
    return data;
}
function _templateObject332() {
    var data = _tagged_template_literal([
        "query dashboardUserAccessLevel{userAccessLevel{canViewCatalog canViewContent canViewCustomers canViewDeployment canViewLeads canViewDiscounts canViewDesign canViewThemes canViewReporting canViewSalesChannels canViewQuotes canViewOrders}}"
    ]);
    _templateObject332 = function _templateObject332() {
        return data;
    };
    return data;
}
function _templateObject333() {
    var data = _tagged_template_literal([
        "query demo1Order{orders{id}}"
    ]);
    _templateObject333 = function _templateObject333() {
        return data;
    };
    return data;
}
function _templateObject334() {
    var data = _tagged_template_literal([
        "query demo2Order{orders{cartId}}"
    ]);
    _templateObject334 = function _templateObject334() {
        return data;
    };
    return data;
}
function _templateObject335() {
    var data = _tagged_template_literal([
        "query feeneyDesignExportById($id:String!){feeneyDesignExportById(id:$id){...feenyExportDesign}}",
        ""
    ]);
    _templateObject335 = function _templateObject335() {
        return data;
    };
    return data;
}
function _templateObject336() {
    var data = _tagged_template_literal([
        "query getAddressById($id:String!){addressById(id:$id){...shopAddress}}",
        ""
    ]);
    _templateObject336 = function _templateObject336() {
        return data;
    };
    return data;
}
function _templateObject337() {
    var data = _tagged_template_literal([
        "query getAdminPortalMicroStore($id:ID!){teamStore(id:$id){id timezone title logo enabled storeOpeningCycles{id name ordersFrom ordersUntil status}slugUrlLocalizations{content langCode}currentStoreOpeningCycle{id name ordersFrom ordersUntil status}}}"
    ]);
    _templateObject337 = function _templateObject337() {
        return data;
    };
    return data;
}
function _templateObject338() {
    var data = _tagged_template_literal([
        "query getAdminPortalOpeningCycleNB($openingCycleId:ID!){teamStoreOpeningCycle(id:$openingCycleId){...adminPortalOpeningCycleFieldsNB}}",
        ""
    ]);
    _templateObject338 = function _templateObject338() {
        return data;
    };
    return data;
}
function _templateObject339() {
    var data = _tagged_template_literal([
        "query getAdminPortalProductsNB($filter:AggregateOrderProductFilterInput!){aggregateOrderProducts(filter:$filter){productId imageUrl title quantity product{id thumbnailUrl}variants{optionValueId title quantity imageUrl}}}"
    ]);
    _templateObject339 = function _templateObject339() {
        return data;
    };
    return data;
}
function _templateObject340() {
    var data = _tagged_template_literal([
        "query getAdminPortalUserNB($salesChannelId:ID!){adminPortalUser(salesChannelId:$salesChannelId){userId roles id email shopId status salesChannelId email createdAt}}"
    ]);
    _templateObject340 = function _templateObject340() {
        return data;
    };
    return data;
}
function _templateObject341() {
    var data = _tagged_template_literal([
        "query getAdminPortalUsersNB($salesChannelId:ID!){adminPortalUsers(salesChannelId:$salesChannelId){id userId salesChannelId shopId email status roles createdAt}}"
    ]);
    _templateObject341 = function _templateObject341() {
        return data;
    };
    return data;
}
function _templateObject342() {
    var data = _tagged_template_literal([
        "query getAdminPortalSalesChannelIdByOpeningCycleIdNB($openingCycleId:ID!){teamStoreOpeningCycleSalesChannelId(id:$openingCycleId)}"
    ]);
    _templateObject342 = function _templateObject342() {
        return data;
    };
    return data;
}
function _templateObject343() {
    var data = _tagged_template_literal([
        "query getAllNotificationConfigByShopID($id:String!){notificationConfigurations(where:{shopId:{_eq:$id}}){channel configuration id notificationType salesChannelId shopId}}"
    ]);
    _templateObject343 = function _templateObject343() {
        return data;
    };
    return data;
}
function _templateObject344() {
    var data = _tagged_template_literal([
        "query getAllPaymentProviderConfigurations{paymentProviderConfigurations{id name}}"
    ]);
    _templateObject344 = function _templateObject344() {
        return data;
    };
    return data;
}
function _templateObject345() {
    var data = _tagged_template_literal([
        "query getAllSupportedPaymentMethods{supportedPaymentMethods{paymentMethods{provider paymentMethods}}}"
    ]);
    _templateObject345 = function _templateObject345() {
        return data;
    };
    return data;
}
function _templateObject346() {
    var data = _tagged_template_literal([
        "query getAssignedPaymentConfigurations($salesChannelId:String){salesChannelPaymentConfiguration(where:{salesChannelId:{_eq:$salesChannelId}}){id currencyCode providerConfigurationId salesChannelId shopId providerConfiguration{id enabledMethods name provider}}}"
    ]);
    _templateObject346 = function _templateObject346() {
        return data;
    };
    return data;
}
function _templateObject347() {
    var data = _tagged_template_literal([
        "query getBaseProductImagesByPlaceholderIdNB($id:ID!){getBaseProductImagesByPlaceholderId(id:$id){placeholderId productImageIds product{id defaultTitle titleLocalizations{langCode content}}selectedMedia{id source default sortOrder}}}"
    ]);
    _templateObject347 = function _templateObject347() {
        return data;
    };
    return data;
}
function _templateObject348() {
    var data = _tagged_template_literal([
        "query getBaseProductImagesByPlaceholderIdsNB($ids:[ID!]!){getBaseProductImagesByPlaceholderIds(ids:$ids){placeholderId productImageIds product{id defaultTitle titleLocalizations{langCode content}}selectedMedia{id source default sortOrder}}}"
    ]);
    _templateObject348 = function _templateObject348() {
        return data;
    };
    return data;
}
function _templateObject349() {
    var data = _tagged_template_literal([
        "query getDashboardAccountDetailNB($id:ID!){account(id:$id){id name type referenceId description updatedAt enabled accountOwner{id firstName lastName email}contacts{...dashboardContactFieldsNB}}}",
        ""
    ]);
    _templateObject349 = function _templateObject349() {
        return data;
    };
    return data;
}
function _templateObject350() {
    var data = _tagged_template_literal([
        "query getDashboardAccountsNB($first:Int,$after:String,$keyword:String){accounts(first:$first,after:$after,query:$keyword){edges{node{id name type referenceId description updatedAt enabled accountOwner{...DashboardAdminUserCommonFieldsNB}contacts{...dashboardContactFieldsNB}}cursor}pageInfo{hasNextPage endCursor}}}",
        "",
        ""
    ]);
    _templateObject350 = function _templateObject350() {
        return data;
    };
    return data;
}
function _templateObject351() {
    var data = _tagged_template_literal([
        "query GetDashboardAdminUserMembersNB($userId:ID!,$membersFilter:AdminUsersFilterInput){adminUser(id:$userId){id members(filter:$membersFilter){edges{node{...DashboardAdminUserCommonFieldsNB}}}}}",
        ""
    ]);
    _templateObject351 = function _templateObject351() {
        return data;
    };
    return data;
}
function _templateObject352() {
    var data = _tagged_template_literal([
        "query GetDashboardAdminUserNB($userId:ID!){adminUser(id:$userId){...DashboardAdminUserCommonFieldsNB}}",
        ""
    ]);
    _templateObject352 = function _templateObject352() {
        return data;
    };
    return data;
}
function _templateObject353() {
    var data = _tagged_template_literal([
        "query GetDashboardAdminUsersNB($filter:AdminUsersFilterInput,$first:Int,$after:String,$query:String){adminUsers(filter:$filter,first:$first,after:$after,query:$query){pageInfo{hasNextPage endCursor}edges{cursor node{...DashboardAdminUserCommonFieldsNB}}}}",
        ""
    ]);
    _templateObject353 = function _templateObject353() {
        return data;
    };
    return data;
}
function _templateObject354() {
    var data = _tagged_template_literal([
        "query getDashboardCollectionCommonNB($collectionId:ID!){productCollection(id:$collectionId){...dashboardCollectionCommonFieldsNB}}",
        ""
    ]);
    _templateObject354 = function _templateObject354() {
        return data;
    };
    return data;
}
function _templateObject355() {
    var data = _tagged_template_literal([
        "query getDashboardCollectionListNB($first:Int,$before:String,$last:Int,$after:String){productCollections(first:$first,before:$before,last:$last,after:$after){pageInfo{hasNextPage hasPreviousPage startCursor endCursor}edges{cursor node{...dashboardCollectionCommonFieldsNB}}}}",
        ""
    ]);
    _templateObject355 = function _templateObject355() {
        return data;
    };
    return data;
}
function _templateObject356() {
    var data = _tagged_template_literal([
        "query getDashboardCollectionInformationNB($collectionId:ID!){productCollection(id:$collectionId){...dashboardCollectionInformationFieldsNB}}",
        ""
    ]);
    _templateObject356 = function _templateObject356() {
        return data;
    };
    return data;
}
function _templateObject357() {
    var data = _tagged_template_literal([
        "query getDashboardCollectionMediaNB($collectionId:ID!){productCollection(id:$collectionId){...dashboardCollectionMediaFieldsNB}}",
        ""
    ]);
    _templateObject357 = function _templateObject357() {
        return data;
    };
    return data;
}
function _templateObject358() {
    var data = _tagged_template_literal([
        "query getDashboardCollectionProductNB($collectionId:ID!,$filter:ProductsFilterInput){productCollection(id:$collectionId){...dashboardCollectionCommonFieldsNB products(filter:$filter,first:500){edges{sortOrder node{id defaultTitle thumbnailUrl type media{id source isCustomProductMedia}}}}}}",
        ""
    ]);
    _templateObject358 = function _templateObject358() {
        return data;
    };
    return data;
}
function _templateObject359() {
    var data = _tagged_template_literal([
        "query getDashboardParentCollectionNB($collectionId:ID!){productCollection(id:$collectionId){...dashboardParentCollectionFieldsNB}}",
        ""
    ]);
    _templateObject359 = function _templateObject359() {
        return data;
    };
    return data;
}
function _templateObject360() {
    var data = _tagged_template_literal([
        "query getDashboardChildCollectionNB($collectionId:ID!){productCollection(id:$collectionId){...dashboardChildCollectionFieldsNB}}",
        ""
    ]);
    _templateObject360 = function _templateObject360() {
        return data;
    };
    return data;
}
function _templateObject361() {
    var data = _tagged_template_literal([
        "query getDashboardCollectionTaxListNB($filter:taxCollectionsFilterInput,$first:Int,$after:String){taxCollections(filter:$filter,first:$first,after:$after){pageInfo{hasNextPage endCursor}edges{node{id collection{id defaultTitle internalId}}}}}"
    ]);
    _templateObject361 = function _templateObject361() {
        return data;
    };
    return data;
}
function _templateObject362() {
    var data = _tagged_template_literal([
        "query getDashboardCollectionTaxSettingsNB($collectionId:ID!){productCollection(id:$collectionId){taxSettings{enabled ...dashboardTaxSettingsFields}}}",
        ""
    ]);
    _templateObject362 = function _templateObject362() {
        return data;
    };
    return data;
}
function _templateObject363() {
    var data = _tagged_template_literal([
        "query getDashboardCollectionsNB($collectionIds:[ID!]!){productCollectionByIds(ids:$collectionIds){id defaultTitle}}"
    ]);
    _templateObject363 = function _templateObject363() {
        return data;
    };
    return data;
}
function _templateObject364() {
    var data = _tagged_template_literal([
        "query getDashboardContactsNB($first:Int,$after:String,$keyword:String){accountContacts(first:$first,after:$after,query:$keyword){edges{node{...dashboardContactFieldsNB}cursor}pageInfo{endCursor hasNextPage}}}",
        ""
    ]);
    _templateObject364 = function _templateObject364() {
        return data;
    };
    return data;
}
function _templateObject365() {
    var data = _tagged_template_literal([
        "query getDashboardCustomImageEditorProductsNB($first:Int,$before:String,$last:Int,$after:String){products(first:$first,before:$before,last:$last,after:$after){edges{node{id defaultTitle internalId thumbnailUrl lastExportedCustomImage}cursor}pageInfo{hasNextPage hasPreviousPage startCursor endCursor}}}"
    ]);
    _templateObject365 = function _templateObject365() {
        return data;
    };
    return data;
}
function _templateObject366() {
    var data = _tagged_template_literal([
        "query getDashboardDiscountCouponListNB($discountId:ID!,$first:Int,$after:String){discount(id:$discountId){id discountCoupons(first:$first,after:$after){edges{node{...dashboardDiscountCouponFields}cursor}pageInfo{hasNextPage endCursor}}}}",
        ""
    ]);
    _templateObject366 = function _templateObject366() {
        return data;
    };
    return data;
}
function _templateObject367() {
    var data = _tagged_template_literal([
        "query getDashboardDiscountDetailNB($id:ID!){discount(id:$id){...dashboardDiscountDetailFields}}",
        ""
    ]);
    _templateObject367 = function _templateObject367() {
        return data;
    };
    return data;
}
function _templateObject368() {
    var data = _tagged_template_literal([
        "query getDashboardDiscountListNB($query:String,$filter:DiscountFilterInput,$orderBy:[DiscountOrderByInput!],$before:String,$after:String,$first:Int,$last:Int){discounts(query:$query filter:$filter orderBy:$orderBy before:$before after:$after first:$first last:$last){pageInfo{hasNextPage endCursor}edges{cursor node{...dashboardDiscountListItemFields}}}}",
        ""
    ]);
    _templateObject368 = function _templateObject368() {
        return data;
    };
    return data;
}
function _templateObject369() {
    var data = _tagged_template_literal([
        "query getDashboardDiscountObjectProductsByIdsNB($productIds:[ID!]!){products(filter:{id:$productIds}){edges{node{id defaultTitle thumbnailUrl}}}}"
    ]);
    _templateObject369 = function _templateObject369() {
        return data;
    };
    return data;
}
function _templateObject370() {
    var data = _tagged_template_literal([
        "query getDashboardDocumentTemplates($first:Int,$before:String,$last:Int,$after:String,$filters:TemplatesFilter){documentTemplates(first:$first before:$before last:$last after:$after filters:$filters){edges{node{id shopId name isPublished createdAt updatedAt tag dataType}}pageInfo{hasNextPage hasPreviousPage startCursor endCursor}}}"
    ]);
    _templateObject370 = function _templateObject370() {
        return data;
    };
    return data;
}
function _templateObject371() {
    var data = _tagged_template_literal([
        "query getDashboardJobByIdNB($id:ID!){jobById(id:$id){id meta status output}}"
    ]);
    _templateObject371 = function _templateObject371() {
        return data;
    };
    return data;
}
function _templateObject372() {
    var data = _tagged_template_literal([
        "query getDashboardLightPresetsNBE{productLightPresets{...ProductLightSettingPreset}}",
        ""
    ]);
    _templateObject372 = function _templateObject372() {
        return data;
    };
    return data;
}
function _templateObject373() {
    var data = _tagged_template_literal([
        "query getDashboardLightSettingNBE($productId:String!){productLightSetting(productId:$productId){id shopId productId productLightPresetId preset{...ProductLightSettingPreset}}}",
        ""
    ]);
    _templateObject373 = function _templateObject373() {
        return data;
    };
    return data;
}
function _templateObject374() {
    var data = _tagged_template_literal([
        "query getDashboardMetaFieldsNB($filter:MetaFieldsFilterInput){metaFields(filter:$filter){id shopId resourceType type name title createdAt updatedAt createdBy updatedBy configuration{type ... on MetaFieldOptionConfiguration{MetaFieldType:type MetaFieldOption:option{title value}}}}}"
    ]);
    _templateObject374 = function _templateObject374() {
        return data;
    };
    return data;
}
function _templateObject375() {
    var data = _tagged_template_literal([
        "query getDashboardMicrostoresInContactNB($id:ID!){accountContact(id:$id){...DashboardContactMicrostoreFieldsNB}}",
        ""
    ]);
    _templateObject375 = function _templateObject375() {
        return data;
    };
    return data;
}
function _templateObject376() {
    var data = _tagged_template_literal([
        "query getDashboardOpeningCyclesNB($teamStoreId:ID!){teamStoreOpeningCycles(teamStoreId:$teamStoreId){edges{node{id name ordersFrom ordersUntil status}cursor}}}"
    ]);
    _templateObject376 = function _templateObject376() {
        return data;
    };
    return data;
}
function _templateObject377() {
    var data = _tagged_template_literal([
        "query getDashboardOptimizedJobByIdNB($id:ID!,$before:String,$filters:JobFilterInput,$first:Int,$after:String){jobById(id:$id){id meta status output childJobs(first:$first,before:$before,filters:$filters,after:$after){progress{total completed failed}pageInfo{hasNextPage hasPreviousPage startCursor endCursor}edges{node{id output status meta}}}}}"
    ]);
    _templateObject377 = function _templateObject377() {
        return data;
    };
    return data;
}
function _templateObject378() {
    var data = _tagged_template_literal([
        "query getDashboardPricingConfigurationNB($salesChannelId:ID!,$first:Int,$last:Int,$before:String,$after:String){salesChannel(id:$salesChannelId){productsV2(first:$first,last:$last,before:$before,after:$after){edges{node{pricingConfiguration{roundingConfiguration{digits mode roundAfterTax}modifiers{id priceModifierConfiguration{id shopId discountId salesChannelId category accountingId type title titleLocalizations{langCode content}}title value unit titleLocalizations{langCode content}category type priority enabled}price{currencyCode amount}}id product{defaultTitle internalId id thumbnailUrl pricingConfiguration{price{currencyCode amount}}variants{id title pricingConfiguration{price{currencyCode amount}}}}}cursor}pageInfo{hasNextPage hasPreviousPage startCursor endCursor}}}}"
    ]);
    _templateObject378 = function _templateObject378() {
        return data;
    };
    return data;
}
function _templateObject379() {
    var data = _tagged_template_literal([
        "query getDashboardPricingModifierNB{priceModifierConfigurations{edges{node{title category titleLocalizations{langCode content}id type}}}}"
    ]);
    _templateObject379 = function _templateObject379() {
        return data;
    };
    return data;
}
function _templateObject380() {
    var data = _tagged_template_literal([
        "query getDashboardProductAttributeNB($id:ID!){productAttribute(id:$id){...dashboardProductAttributeDetailFieldsNB}}",
        ""
    ]);
    _templateObject380 = function _templateObject380() {
        return data;
    };
    return data;
}
function _templateObject381() {
    var data = _tagged_template_literal([
        "query getDashboardProductAttributesNB($first:Int,$after:String){productAttributes(first:$first,after:$after){edges{node{...dashboardProductAttributeCommonFieldsNB}}pageInfo{hasNextPage endCursor}}}",
        ""
    ]);
    _templateObject381 = function _templateObject381() {
        return data;
    };
    return data;
}
function _templateObject382() {
    var data = _tagged_template_literal([
        "query getDashboardProductBrandByProductIdNB($productId:ID!){productBrandByProductId(productId:$productId){id title}}"
    ]);
    _templateObject382 = function _templateObject382() {
        return data;
    };
    return data;
}
function _templateObject383() {
    var data = _tagged_template_literal([
        "query getDashboardProductBrandsNB{productBrands{id title}}"
    ]);
    _templateObject383 = function _templateObject383() {
        return data;
    };
    return data;
}
function _templateObject384() {
    var data = _tagged_template_literal([
        "query getDashboardProductByIdNB($id:ID!){product(id:$id){id accountingId internalId shopId brandId brand{id title}titleLocalizations{langCode content}slugLocalizations{langCode content}descriptionLocalizations{langCode content}variants{...productVariantsWithOptionValueFieldsNB}options{id internalId defaultTitle type values{id value productOptionId defaultTitle}}}}",
        ""
    ]);
    _templateObject384 = function _templateObject384() {
        return data;
    };
    return data;
}
function _templateObject385() {
    var data = _tagged_template_literal([
        "query getDashboardProductsNB($query:String,$filter:ProductsFilterInput,$orderBy:[ProductsOrderBy!],$first:Int,$last:Int,$after:String){products(query:$query filter:$filter orderBy:$orderBy first:$first last:$last after:$after){pageInfo{hasNextPage hasPreviousPage startCursor endCursor}edges{node{id defaultTitle internalId thumbnailUrl type brand{id title}collections{id defaultTitle}options{id defaultTitle values{id defaultTitle}}}cursor}}}"
    ]);
    _templateObject385 = function _templateObject385() {
        return data;
    };
    return data;
}
function _templateObject386() {
    var data = _tagged_template_literal([
        "query getDashboardProductsSupplierPageNB($query:String,$filter:ProductsFilterInput,$orderBy:[ProductsOrderBy!],$first:Int,$last:Int,$after:String){products(query:$query filter:$filter orderBy:$orderBy first:$first last:$last after:$after){pageInfo{hasNextPage hasPreviousPage startCursor endCursor}edges{node{id defaultTitle internalId thumbnailUrl status updatedAt sources{source styleId}defaultSource salesChannels{id name teamStore{title}storeFront{title}}}cursor}}}"
    ]);
    _templateObject386 = function _templateObject386() {
        return data;
    };
    return data;
}
function _templateObject387() {
    var data = _tagged_template_literal([
        "query getDashboardSalesChannelByIdNB($id:ID!){salesChannel(id:$id){id type name languages meta products{id productId product{id defaultTitle thumbnailUrl}}collections{id collectionId collection{id defaultTitle thumbnailUrl products{edges{node{id defaultTitle thumbnailUrl}}}}}discounts{edges{node{id accountingId title category createdAt updatedAt isUpcharge availableFrom availableUntil}}}storeFront{title logo deployments{publishedAt}shareLinkUrlLocalizations{langCode content}}teamStore{title logo currentStoreOpeningCycleId timezone shareLinkUrlLocalizations{langCode content}personalizationGroups{id title options{id title}}}}}"
    ]);
    _templateObject387 = function _templateObject387() {
        return data;
    };
    return data;
}
function _templateObject388() {
    var data = _tagged_template_literal([
        "query getDashboardSalesChannelByIds($salesChannelIds:[ID!]!){salesChannelsByIds(ids:$salesChannelIds){id name}}"
    ]);
    _templateObject388 = function _templateObject388() {
        return data;
    };
    return data;
}
function _templateObject389() {
    var data = _tagged_template_literal([
        "query getDashboardSalesChannelCollectionsNB($id:ID!){salesChannel(id:$id){collections{id collectionId sortOrder collection{id defaultTitle thumbnailUrl}}}}"
    ]);
    _templateObject389 = function _templateObject389() {
        return data;
    };
    return data;
}
function _templateObject390() {
    var data = _tagged_template_literal([
        "query getDashboardSalesChannelDiscountListNB($salesChannelId:ID!,$filter:SalesChannelDiscountFilterInput,$before:String,$after:String,$first:Int,$last:Int){salesChannel(id:$salesChannelId){id discounts(filter:$filter before:$before after:$after first:$first last:$last){pageInfo{hasNextPage endCursor}edges{cursor node{...dashboardDiscountListItemFields}}}}}",
        ""
    ]);
    _templateObject390 = function _templateObject390() {
        return data;
    };
    return data;
}
function _templateObject391() {
    var data = _tagged_template_literal([
        "query getDashboardSalesChannelProductByIdNB($id:ID!){salesChannelProduct(id:$id){id accountingId internalId salesChannelId productId product{options{id values{id value defaultTitle}}}media{id salesChannelId productMediaId baseProductMediaId visibleInMultipleCollection isDefaultMediaForOptionValue productOptionValueId productOptionValue{id value defaultTitle}default sortOrder active source}titleLocalizations{langCode content}slugLocalizations{langCode content}descriptionLocalizations{langCode content}}}"
    ]);
    _templateObject391 = function _templateObject391() {
        return data;
    };
    return data;
}
function _templateObject392() {
    var data = _tagged_template_literal([
        "query getDashboardSalesChannelProductPackagesNB($id:ID!){salesChannel(id:$id){productPackages{id productPackageId sortOrder productPackage{id defaultTitle thumbnailUrl}}}}"
    ]);
    _templateObject392 = function _templateObject392() {
        return data;
    };
    return data;
}
function _templateObject393() {
    var data = _tagged_template_literal([
        "query getDashboardSalesChannelProductVariantsNB($salesChannelId:ID!,$productId:ID!){salesChannelProductVariants(salesChannelId:$salesChannelId productId:$productId){productId salesChannelId variantId}}"
    ]);
    _templateObject393 = function _templateObject393() {
        return data;
    };
    return data;
}
function _templateObject394() {
    var data = _tagged_template_literal([
        "query getDashboardSalesChannelProductsNB($id:ID!){salesChannel(id:$id){id type useBasePrice useSalesPrice customProductsMedia{id productId mediaId baseProductMediaId visibleInMultipleCollections isCustomMedia default sortOrder source active createdAt updatedAt}productMedia{id productId mediaId visibleInMultipleCollections baseProductMediaId isCustomMedia default sortOrder source active createdAt updatedAt}products{id productId internalId sortOrder media{source default}titleLocalizations{content langCode}pricingConfiguration{price{currencyCode amount}}product{id defaultTitle internalId thumbnailUrl media{source sortOrder id visibleInCollection isCustomProductMedia default}pricingConfiguration{price{currencyCode amount}}}}}}"
    ]);
    _templateObject394 = function _templateObject394() {
        return data;
    };
    return data;
}
function _templateObject395() {
    var data = _tagged_template_literal([
        "query getDashboardSalesChannelsNB($query:String,$filter:SalesChannelsFilter,$orderBy:[SalesChannelsOrderBy!],$after:String,$first:Int,$last:Int){salesChannels(query:$query filter:$filter orderBy:$orderBy after:$after first:$first last:$last){pageInfo{endCursor hasNextPage}edges{node{id name type storeFront{id title logo domainUrl salesChannel{name}}teamStore{id logo title isPublic timezone updatedAt currentStoreOpeningCycle{id name ordersFrom ordersUntil status}salesChannel{name}contact{account{name accountOwner{id firstName lastName email}}}}products{id productId}}}}}"
    ]);
    _templateObject395 = function _templateObject395() {
        return data;
    };
    return data;
}
function _templateObject396() {
    var data = _tagged_template_literal([
        "query getDashboardSalesChannelsProductsOnCatalogSalesChannelsPage($filter:SalesChannelProductsFilterInput!,$before:String,$after:String,$first:Int,$last:Int){salesChannelProducts(filter:$filter before:$before after:$after first:$first last:$last){pageInfo{hasNextPage hasPreviousPage endCursor startCursor}edges{node{id accountingId internalId salesChannelId salesChannel{name storeFront{title logo}teamStore{title logo}}updatedAt productId updatedAt titleLocalizations{content langCode}product{updatedAt defaultTitle internalId}}}}}"
    ]);
    _templateObject396 = function _templateObject396() {
        return data;
    };
    return data;
}
function _templateObject397() {
    var data = _tagged_template_literal([
        "query getDashboardShippingSettingsNB($salesChannelId:ID){shippingSettings(salesChannelId:$salesChannelId){...shippingSettingFieldsNB}}",
        ""
    ]);
    _templateObject397 = function _templateObject397() {
        return data;
    };
    return data;
}
function _templateObject398() {
    var data = _tagged_template_literal([
        "query getDashboardStorefrontContentNB($id:ID!){storeFront(id:$id){selectedShopThemeId logo favicon}}"
    ]);
    _templateObject398 = function _templateObject398() {
        return data;
    };
    return data;
}
function _templateObject399() {
    var data = _tagged_template_literal([
        "query getDashboardStorefrontDeploymentNB($id:ID!){storeFront(id:$id){deployments{createdAt netlifyDeployId previewUrl publishedAt salesChannelId shopId status triggeredBy updatedAt}netlifyConfiguration{id shopId siteId}}}"
    ]);
    _templateObject399 = function _templateObject399() {
        return data;
    };
    return data;
}
function _templateObject400() {
    var data = _tagged_template_literal([
        "query getDashboardStorefrontInformationNB($id:ID!){storeFront(id:$id){title titleLocalizations{langCode content}domainUrl}}"
    ]);
    _templateObject400 = function _templateObject400() {
        return data;
    };
    return data;
}
function _templateObject401() {
    var data = _tagged_template_literal([
        "query getDashboardStorefrontsNB($before:String,$after:String,$first:Int,$last:Int){storeFronts(before:$before,after:$after,first:$first,last:$last){edges{node{id logo title domainUrl salesChannel{name}}cursor}pageInfo{hasNextPage endCursor}}}"
    ]);
    _templateObject401 = function _templateObject401() {
        return data;
    };
    return data;
}
function _templateObject402() {
    var data = _tagged_template_literal([
        "query getDashboardSupplierProductBrandsNB{supplierProductBrands{value}}"
    ]);
    _templateObject402 = function _templateObject402() {
        return data;
    };
    return data;
}
function _templateObject403() {
    var data = _tagged_template_literal([
        "query getDashboardSupplierProductCategoriesNB{supplierProductCategories{category}}"
    ]);
    _templateObject403 = function _templateObject403() {
        return data;
    };
    return data;
}
function _templateObject404() {
    var data = _tagged_template_literal([
        "query getDashboardSupplierProductDetailPage($id:ID!){product(id:$id){id internalId defaultSource defaultTitle options{id internalId defaultTitle type values{id value productOptionId defaultTitle titleLocalizations{langCode content}}}media{id type source default productOptionValueId productOptionValue{id sortOrder value defaultTitle}}descriptionLocalizations{langCode content}variants{id gtin optionValues{id defaultTitle productOption{type id}}pricingConfiguration{price{currencyCode amount}}sku title accountingId inventory{quantity}}}}"
    ]);
    _templateObject404 = function _templateObject404() {
        return data;
    };
    return data;
}
function _templateObject405() {
    var data = _tagged_template_literal([
        "query getDashboardSupplierProductVariantsNB($filter:SupplierProductVariantsFilterInput,$orderBy:[SupplierProductVariantsOrderByInput!],$first:Int,$before:String,$last:Int,$after:String){supplierProductVariants(filter:$filter orderBy:$orderBy first:$first before:$before last:$last after:$after){pageInfo{hasNextPage hasPreviousPage startCursor endCursor}edges{node{id supplier productId variantId styleId gtin color size quantity price thumbnailFullUrl}}}}"
    ]);
    _templateObject405 = function _templateObject405() {
        return data;
    };
    return data;
}
function _templateObject406() {
    var data = _tagged_template_literal([
        "query getDashboardSupplierProductsNB($query:String,$filter:SupplierProductsFilterInput,$first:Int,$before:String,$last:Int,$after:String,$orderBy:[SupplierProductsOrderByInput!]){supplierProducts(query:$query filter:$filter first:$first before:$before last:$last after:$after orderBy:$orderBy){pageInfo{hasNextPage hasPreviousPage startCursor endCursor}edges{node{id supplier productId styleId title description brand categories updatedAt thumbnailFullUrl}}}}"
    ]);
    _templateObject406 = function _templateObject406() {
        return data;
    };
    return data;
}
function _templateObject407() {
    var data = _tagged_template_literal([
        "query getDashboardSuppliersNB{suppliers{value}}"
    ]);
    _templateObject407 = function _templateObject407() {
        return data;
    };
    return data;
}
function _templateObject408() {
    var data = _tagged_template_literal([
        "query getDashboardTaxSettingsNB{taxSettings{shopId defaultTaxRate taxRates{countryCode provinceCode percent}}}"
    ]);
    _templateObject408 = function _templateObject408() {
        return data;
    };
    return data;
}
function _templateObject409() {
    var data = _tagged_template_literal([
        "query getDashboardTeamStoreAvailability($id:ID!){teamStore(id:$id){isPublic hasPassword password timezone currentStoreOpeningCycleId showAvailability currentStoreOpeningCycle{id name ordersFrom ordersUntil status}}}"
    ]);
    _templateObject409 = function _templateObject409() {
        return data;
    };
    return data;
}
function _templateObject410() {
    var data = _tagged_template_literal([
        "query getDashboardTeamStoreContentNB($id:ID!){teamStore(id:$id){title brandColor logo mainBanner mainBannerHeight continueShoppingPageId}}"
    ]);
    _templateObject410 = function _templateObject410() {
        return data;
    };
    return data;
}
function _templateObject411() {
    var data = _tagged_template_literal([
        "query getDashboardTeamStoreInformationNB($id:ID!){teamStore(id:$id){storeFront{id}title titleLocalizations{langCode content}headlineTitle headlineTitleLocalizations{langCode content}introductionText introductionTextLocalizations{langCode content}slugUrlLocalizations{langCode content}homepageUrlLocalizations{langCode content}contactId contact{...dashboardContactFieldsNB}contactPerson contactEmail contactPhone}}",
        ""
    ]);
    _templateObject411 = function _templateObject411() {
        return data;
    };
    return data;
}
function _templateObject412() {
    var data = _tagged_template_literal([
        "query getDashboardTeamStorePersonalizationNB($id:ID!){teamStore(id:$id){salesChannel{languages}personalizationGroups{id inputType title titleLocalizations{langCode content}options{id duplicationRule pricingConfiguration{roundingConfiguration{digits mode roundAfterTax}price{currencyCode amount}createdAt updatedAt createdBy updatedBy}title titleLocalizations{langCode content}}}}}"
    ]);
    _templateObject412 = function _templateObject412() {
        return data;
    };
    return data;
}
function _templateObject413() {
    var data = _tagged_template_literal([
        "query getDashboardTeamStoreStructureNB($id:ID!){teamStore(id:$id){isRequiredGroup salesChannel{languages}groups{id name title titleLocalizations{langCode content}}isRequiredTargetCategory targetCategories{id name title titleLocalizations{langCode content}}}}"
    ]);
    _templateObject413 = function _templateObject413() {
        return data;
    };
    return data;
}
function _templateObject414() {
    var data = _tagged_template_literal([
        "query getDashboardTeamStoresNB($before:String,$after:String,$first:Int,$last:Int){teamStores(before:$before,after:$after,first:$first,last:$last){edges{node{id logo title isPublic timezone updatedAt currentStoreOpeningCycle{id name ordersFrom ordersUntil status}salesChannel{name}}cursor}pageInfo{hasNextPage endCursor}}}"
    ]);
    _templateObject414 = function _templateObject414() {
        return data;
    };
    return data;
}
function _templateObject415() {
    var data = _tagged_template_literal([
        "query getDashboardUserPermission($userId:ID!){adminUser(id:$userId){...DashboardAdminUserCommonFieldsNB tosAcceptedAt}userAccessLevel{canViewCatalog canViewContent canViewCustomers canViewDeployment canViewLeads canViewDiscounts canViewDesign canViewThemes canViewReporting canViewSalesChannels canViewQuotes canViewOrders}}",
        ""
    ]);
    _templateObject415 = function _templateObject415() {
        return data;
    };
    return data;
}
function _templateObject416() {
    var data = _tagged_template_literal([
        "query getDashboardVerifiedSuppliers{verifiedSuppliers{edges{node{value}}}}"
    ]);
    _templateObject416 = function _templateObject416() {
        return data;
    };
    return data;
}
function _templateObject417() {
    var data = _tagged_template_literal([
        "query getDashboardVerifiedSupplierProductBrandsNB{verifiedSupplierProductBrands{value}}"
    ]);
    _templateObject417 = function _templateObject417() {
        return data;
    };
    return data;
}
function _templateObject418() {
    var data = _tagged_template_literal([
        "query getDashboardVerifiedSupplierProductCategoriesNB{verifiedSupplierProductCategories{category}}"
    ]);
    _templateObject418 = function _templateObject418() {
        return data;
    };
    return data;
}
function _templateObject419() {
    var data = _tagged_template_literal([
        "query getDashboardcouponUsageByDiscountIdNB($discountId:ID!){couponUsageByDiscountId(discountId:$discountId){couponCode totalUsed}}"
    ]);
    _templateObject419 = function _templateObject419() {
        return data;
    };
    return data;
}
function _templateObject420() {
    var data = _tagged_template_literal([
        "query getEmailDomains{emailDomains{id shopId domain status dnsRecords{name type value}}}"
    ]);
    _templateObject420 = function _templateObject420() {
        return data;
    };
    return data;
}
function _templateObject421() {
    var data = _tagged_template_literal([
        "query getEmbellishmentTypesNB($first:Int,$filter:EmbellishmentTypeFilterInput,$before:String,$last:Int,$after:String){embellishmentTypes(first:$first filter:$filter before:$before last:$last after:$after){edges{node{basePriceConfigurationId sellingPriceConfigurationId basePriceConfiguration{price{currencyCode amount}createdAt updatedAt createdBy updatedBy}sellingPriceConfiguration{price{currencyCode amount}}id title active updatedAt createdAt}cursor}pageInfo{hasNextPage hasPreviousPage startCursor endCursor}}}"
    ]);
    _templateObject421 = function _templateObject421() {
        return data;
    };
    return data;
}
function _templateObject422() {
    var data = _tagged_template_literal([
        "query getPaymentMethodInfoBySalesChannelId($salesChannelId:String!){paymentMethodInfos(salesChannelId:$salesChannelId){id provider method instructionsV2{langCode content}}}"
    ]);
    _templateObject422 = function _templateObject422() {
        return data;
    };
    return data;
}
function _templateObject423() {
    var data = _tagged_template_literal([
        "query getPaymentMethodInfoByShop{paymentMethodInfos{id provider method instructionsV2{langCode content}}}"
    ]);
    _templateObject423 = function _templateObject423() {
        return data;
    };
    return data;
}
function _templateObject424() {
    var data = _tagged_template_literal([
        "query getProductByIdInformation($id:ID!){product(id:$id){id internalId status accountingId defaultTitle type options{id internalId defaultTitle type values{id value productOptionId defaultTitle titleLocalizations{langCode content}}}media{id type source sortOrder default visibleInCollection productOptionValueId id}}}"
    ]);
    _templateObject424 = function _templateObject424() {
        return data;
    };
    return data;
}
function _templateObject425() {
    var data = _tagged_template_literal([
        "query getProductImagePlaceholderByProductIdNB($id:ID!){getProductImagePlaceholderByProductId(id:$id){placeholder{...ImagePlaceholder}...PlaceholderPositionAndDimension}}",
        "",
        ""
    ]);
    _templateObject425 = function _templateObject425() {
        return data;
    };
    return data;
}
function _templateObject426() {
    var data = _tagged_template_literal([
        "query getProductImagePlaceholdersBySalesChannelIdNB($id:ID!){getProductImagePlaceholdersBySalesChannelId(id:$id){placeholder{...ImagePlaceholder}...PlaceholderPositionAndDimension}}",
        "",
        ""
    ]);
    _templateObject426 = function _templateObject426() {
        return data;
    };
    return data;
}
function _templateObject427() {
    var data = _tagged_template_literal([
        "query getStudioProductLightNBE($productId:String!){productLightSetting(productId:$productId){id productId preset{...ProductLightPreset}}}",
        ""
    ]);
    _templateObject427 = function _templateObject427() {
        return data;
    };
    return data;
}
function _templateObject428() {
    var data = _tagged_template_literal([
        "query getProductMediaAndTitleNB($id:ID!){product(id:$id){media{id source type sortOrder default visibleInCollection productOptionValueId isCustomProductMedia}defaultTitle titleLocalizations{langCode content}}}"
    ]);
    _templateObject428 = function _templateObject428() {
        return data;
    };
    return data;
}
function _templateObject429() {
    var data = _tagged_template_literal([
        "query getSalesChannelDefaultPaymentConfigNBE($id:ID!){salesChannel(id:$id){id useSalesChannelPaymentConfigsOnly}}"
    ]);
    _templateObject429 = function _templateObject429() {
        return data;
    };
    return data;
}
function _templateObject430() {
    var data = _tagged_template_literal([
        "query getSalesChannelNBE($id:ID!){salesChannel(id:$id){shopId name type storeFront{id title domainUrl logo selectedShopThemeId}teamStore{id title logo slugUrlLocalizations{langCode content}storeFront{id title domainUrl logo selectedShopThemeId}}}}"
    ]);
    _templateObject430 = function _templateObject430() {
        return data;
    };
    return data;
}
function _templateObject431() {
    var data = _tagged_template_literal([
        "query getSalesChannelNameNBE($id:ID!){salesChannel(id:$id){id name}}"
    ]);
    _templateObject431 = function _templateObject431() {
        return data;
    };
    return data;
}
function _templateObject432() {
    var data = _tagged_template_literal([
        "query getSalesChannelSavedPlaceholderItemsNB($salesChannelId:ID!){getSalesChannelSavedPlaceholderItems(salesChannelId:$salesChannelId){id productImagePlaceholderId itemValue mediaId scaleX scaleY top left angle width height placeholderColorwayMedia{edges{node{id salesChannelId productImagePlaceholderId itemValue itemName}}}}}"
    ]);
    _templateObject432 = function _templateObject432() {
        return data;
    };
    return data;
}
function _templateObject433() {
    var data = _tagged_template_literal([
        "query getSalesChannelsNBE{salesChannels(first:100){edges{node{id name type teamStore{logo}}}}}"
    ]);
    _templateObject433 = function _templateObject433() {
        return data;
    };
    return data;
}
function _templateObject434() {
    var data = _tagged_template_literal([
        "query getShopAddresses{shopAddresses{...shopAddress}}",
        ""
    ]);
    _templateObject434 = function _templateObject434() {
        return data;
    };
    return data;
}
function _templateObject435() {
    var data = _tagged_template_literal([
        "query instagramPosts($userId:String!){instagramPosts(input:{userId:$userId}){id posts{id caption mediaUrl permalink timestamp}username}}"
    ]);
    _templateObject435 = function _templateObject435() {
        return data;
    };
    return data;
}
function _templateObject436() {
    var data = _tagged_template_literal([
        "query leadItemByProductDesignId($productDesignId:ID!){leadItemByProductDesignId(productDesignId:$productDesignId){id leadId productDesignId}}"
    ]);
    _templateObject436 = function _templateObject436() {
        return data;
    };
    return data;
}
function _templateObject437() {
    var data = _tagged_template_literal([
        "query orderDetailNewBackend($id:String!,$accessCode:String){orderById(id:$id,accessCode:$accessCode){...order}}",
        ""
    ]);
    _templateObject437 = function _templateObject437() {
        return data;
    };
    return data;
}
function _templateObject438() {
    var data = _tagged_template_literal([
        "query orderPaymentStatus($id:String!,$accessCode:String){orderById(id:$id,accessCode:$accessCode){id payments{method provider status}paymentStatus}}"
    ]);
    _templateObject438 = function _templateObject438() {
        return data;
    };
    return data;
}
function _templateObject439() {
    var data = _tagged_template_literal([
        "query ordersNewBackend{orders(order_by:{createdAt:desc}){...order}}",
        ""
    ]);
    _templateObject439 = function _templateObject439() {
        return data;
    };
    return data;
}
function _templateObject440() {
    var data = _tagged_template_literal([
        "query getPaymentProviderConfigurations{paymentProviderConfigurations{enabledMethods id name provider providerPublicKey shopId}}"
    ]);
    _templateObject440 = function _templateObject440() {
        return data;
    };
    return data;
}
function _templateObject441() {
    var data = _tagged_template_literal([
        "query getSupportedPaymentMethods{supportedPaymentMethods{paymentMethods{provider paymentMethods}}}"
    ]);
    _templateObject441 = function _templateObject441() {
        return data;
    };
    return data;
}
function _templateObject442() {
    var data = _tagged_template_literal([
        "query productDesignApproval($productDesignId:ID!){productDesignApproval(productDesignId:$productDesignId){id productDesignId status updatedAt updatedBy}}"
    ]);
    _templateObject442 = function _templateObject442() {
        return data;
    };
    return data;
}
function _templateObject443() {
    var data = _tagged_template_literal([
        "query productDesignComments($id:ID!,$pageSize:Int!,$after:String!){productDesignComments(productDesignId:$id){totalCount connections(first:$pageSize,after:$after){edges{node{id comment userId role createdAt firstName lastName email}}pageInfo{endCursor hasNextPage}}}}"
    ]);
    _templateObject443 = function _templateObject443() {
        return data;
    };
    return data;
}
function _templateObject444() {
    var data = _tagged_template_literal([
        "query productImagePlaceholdersNB{productImagePlaceholders{...BaseProductImagePlaceholder}}",
        ""
    ]);
    _templateObject444 = function _templateObject444() {
        return data;
    };
    return data;
}
function _templateObject445() {
    var data = _tagged_template_literal([
        "query quoteById($id:String!){quoteById(id:$id){...quoteDetailFields}}",
        ""
    ]);
    _templateObject445 = function _templateObject445() {
        return data;
    };
    return data;
}
function _templateObject446() {
    var data = _tagged_template_literal([
        "query salesChannelHasPlaceholderNB($id:ID!){salesChannelHasPlaceholder(id:$id)}"
    ]);
    _templateObject446 = function _templateObject446() {
        return data;
    };
    return data;
}
function _templateObject447() {
    var data = _tagged_template_literal([
        "query SalesChannelMenu($id:ID!){menu(id:$id){...MenuInfoFields items{...MenuItemFields}}}",
        "",
        ""
    ]);
    _templateObject447 = function _templateObject447() {
        return data;
    };
    return data;
}
function _templateObject448() {
    var data = _tagged_template_literal([
        "query searchDashboardCollectionsNB($orderBy:[ProductCollectionsOrderBy!],$query:String!){productCollections(orderBy:$orderBy,query:$query){edges{node{id internalId defaultTitle thumbnailUrl}}}}"
    ]);
    _templateObject448 = function _templateObject448() {
        return data;
    };
    return data;
}
function _templateObject449() {
    var data = _tagged_template_literal([
        "query searchDashboardCouponCodesNB($discountId:ID!,$couponCodeKeyword:String!){searchCouponCode(discountId:$discountId,couponCode:$couponCodeKeyword){...dashboardDiscountCouponFields}}",
        ""
    ]);
    _templateObject449 = function _templateObject449() {
        return data;
    };
    return data;
}
function _templateObject450() {
    var data = _tagged_template_literal([
        "query searchDashboardCouponCodesNBV2($filters:CouponCodeFilterInput!,$orderBy:[CouponCodeOrderByInput!],$query:String,$first:Int,$after:String){couponCodes(query:$query filter:$filters orderBy:$orderBy first:$first after:$after){edges{node{...DashboardSearchCouponCodesFieldsNB}cursor}pageInfo{hasNextPage endCursor}}}",
        ""
    ]);
    _templateObject450 = function _templateObject450() {
        return data;
    };
    return data;
}
function _templateObject451() {
    var data = _tagged_template_literal([
        "query searchDashboardCustomImageEditorProductsNB($keyword:String!,$filter:ProductsFilterInput,$first:Int,$before:String,$last:Int,$after:String){products(query:$keyword filter:$filter first:$first before:$before last:$last after:$after){edges{node{id internalId accountingId defaultTitle thumbnailUrl lastExportedCustomImage assignedPlaceholders{placeholder{title}}}cursor}pageInfo{hasNextPage hasPreviousPage startCursor endCursor}}}"
    ]);
    _templateObject451 = function _templateObject451() {
        return data;
    };
    return data;
}
function _templateObject452() {
    var data = _tagged_template_literal([
        "query searchDashboardProductsNB($keyword:String!,$filter:ProductsFilterInput,$first:Int){products(query:$keyword,filter:$filter,first:$first){pageInfo{hasNextPage hasPreviousPage startCursor endCursor}edges{node{id internalId defaultTitle thumbnailUrl}}}}"
    ]);
    _templateObject452 = function _templateObject452() {
        return data;
    };
    return data;
}
function _templateObject453() {
    var data = _tagged_template_literal([
        "query searchDashboardSalesChannelsNB($query:String!,$after:String,$first:Int = 100){salesChannels(query:$query,first:$first,after:$after){pageInfo{endCursor hasNextPage}edges{node{id name type storeFront{id title logo}teamStore{id title logo currentStoreOpeningCycle{status}}}}}}"
    ]);
    _templateObject453 = function _templateObject453() {
        return data;
    };
    return data;
}
function _templateObject454() {
    var data = _tagged_template_literal([
        "query searchProductCollections($keyword:String!,$filter:SearchProductCollectionsFilterInput){searchProductCollections(keyword:$keyword,filter:$filter,first:100){edges{node{id defaultTitle thumbnailUrl}}}}"
    ]);
    _templateObject454 = function _templateObject454() {
        return data;
    };
    return data;
}
function _templateObject455() {
    var data = _tagged_template_literal([
        "query searchProductsNBE($keyword:String!,$filter:SearchProductsFilterInput){searchProducts(keyword:$keyword,filter:$filter,first:100){edges{node{id defaultTitle thumbnailUrl}}}}"
    ]);
    _templateObject455 = function _templateObject455() {
        return data;
    };
    return data;
}
function _templateObject456() {
    var data = _tagged_template_literal([
        "query searchSalesChannels($keyword:String!){searchSalesChannels(keyword:$keyword){edges{node{id name}}}}"
    ]);
    _templateObject456 = function _templateObject456() {
        return data;
    };
    return data;
}
function _templateObject457() {
    var data = _tagged_template_literal([
        "query storefrontHomepage($salesChannelId:ID!){salesChannel(id:$salesChannelId){id type storeFront{id homeContentModelId}}}"
    ]);
    _templateObject457 = function _templateObject457() {
        return data;
    };
    return data;
}
function _templateObject458() {
    var data = _tagged_template_literal([
        "query studioGetProductsNB($first:Int,$before:String,$last:Int,$after:String){products(first:$first before:$before last:$last after:$after filter:{types:[CUSTOM]}){pageInfo{hasNextPage hasPreviousPage startCursor endCursor}edges{node{id defaultTitle internalId type status thumbnail}}}}"
    ]);
    _templateObject458 = function _templateObject458() {
        return data;
    };
    return data;
}
function _templateObject459() {
    var data = _tagged_template_literal([
        "subscription dashboardSubscriptionJobByIdNB($id:ID!){jobById(id:$id){id status output}}"
    ]);
    _templateObject459 = function _templateObject459() {
        return data;
    };
    return data;
}
function _templateObject460() {
    var data = _tagged_template_literal([
        "subscription dashboardSubscriptionReportById($id:String!){reportById(id:$id){...reportFields}}",
        ""
    ]);
    _templateObject460 = function _templateObject460() {
        return data;
    };
    return data;
}
import gql from "graphql-tag";
export var GQL_AdminPortalUserStatus;
(function(GQL_AdminPortalUserStatus) {
    GQL_AdminPortalUserStatus["Pending"] = "PENDING";
    GQL_AdminPortalUserStatus["Active"] = "ACTIVE";
})(GQL_AdminPortalUserStatus || (GQL_AdminPortalUserStatus = {}));
export var GQL_AuditLogEventType;
(function(GQL_AuditLogEventType) {
    GQL_AuditLogEventType["OrderCreated"] = "ORDER_CREATED";
    GQL_AuditLogEventType["OrderUpdated"] = "ORDER_UPDATED";
    GQL_AuditLogEventType["PaymentPaid"] = "PAYMENT_PAID";
    GQL_AuditLogEventType["ShipmentCreated"] = "SHIPMENT_CREATED";
})(GQL_AuditLogEventType || (GQL_AuditLogEventType = {}));
export var GQL_AuditLogPayloadType;
(function(GQL_AuditLogPayloadType) {
    GQL_AuditLogPayloadType["S3Keypath"] = "S3_KEYPATH";
    GQL_AuditLogPayloadType["EmailNotification"] = "EMAIL_NOTIFICATION";
    GQL_AuditLogPayloadType["SimpleMessage"] = "SIMPLE_MESSAGE";
    GQL_AuditLogPayloadType["FieldUpdated"] = "FIELD_UPDATED";
    GQL_AuditLogPayloadType["BriklSimpleMessage"] = "BRIKL_SIMPLE_MESSAGE";
})(GQL_AuditLogPayloadType || (GQL_AuditLogPayloadType = {}));
export var GQL_AuditLogStatus;
(function(GQL_AuditLogStatus) {
    GQL_AuditLogStatus["Error"] = "ERROR";
    GQL_AuditLogStatus["Warn"] = "WARN";
    GQL_AuditLogStatus["Success"] = "SUCCESS";
    GQL_AuditLogStatus["Info"] = "INFO";
})(GQL_AuditLogStatus || (GQL_AuditLogStatus = {}));
export var GQL_BulkUpdateSalesChannelProductsPriceModifierErrorCode;
(function(GQL_BulkUpdateSalesChannelProductsPriceModifierErrorCode) {
    GQL_BulkUpdateSalesChannelProductsPriceModifierErrorCode["InvalidValue"] = "INVALID_VALUE";
    GQL_BulkUpdateSalesChannelProductsPriceModifierErrorCode["SaleschannelNotFound"] = "SALESCHANNEL_NOT_FOUND";
    GQL_BulkUpdateSalesChannelProductsPriceModifierErrorCode["SaleschannelProductNotFound"] = "SALESCHANNEL_PRODUCT_NOT_FOUND";
    GQL_BulkUpdateSalesChannelProductsPriceModifierErrorCode["SaleschannelProductVariantNotFound"] = "SALESCHANNEL_PRODUCT_VARIANT_NOT_FOUND";
    GQL_BulkUpdateSalesChannelProductsPriceModifierErrorCode["PriceModifierConfigurationNotFound"] = "PRICE_MODIFIER_CONFIGURATION_NOT_FOUND";
})(GQL_BulkUpdateSalesChannelProductsPriceModifierErrorCode || (GQL_BulkUpdateSalesChannelProductsPriceModifierErrorCode = {}));
export var GQL_CountryCode;
(function(GQL_CountryCode) {
    GQL_CountryCode["EuropeanUnion"] = "EUROPEAN_UNION";
    GQL_CountryCode["Ad"] = "AD";
    GQL_CountryCode["Ae"] = "AE";
    GQL_CountryCode["Af"] = "AF";
    GQL_CountryCode["Ag"] = "AG";
    GQL_CountryCode["Ai"] = "AI";
    GQL_CountryCode["Al"] = "AL";
    GQL_CountryCode["Am"] = "AM";
    GQL_CountryCode["An"] = "AN";
    GQL_CountryCode["Ao"] = "AO";
    GQL_CountryCode["Ar"] = "AR";
    GQL_CountryCode["At"] = "AT";
    GQL_CountryCode["Au"] = "AU";
    GQL_CountryCode["Aw"] = "AW";
    GQL_CountryCode["Ax"] = "AX";
    GQL_CountryCode["Az"] = "AZ";
    GQL_CountryCode["Ba"] = "BA";
    GQL_CountryCode["Bb"] = "BB";
    GQL_CountryCode["Bd"] = "BD";
    GQL_CountryCode["Be"] = "BE";
    GQL_CountryCode["Bf"] = "BF";
    GQL_CountryCode["Bg"] = "BG";
    GQL_CountryCode["Bh"] = "BH";
    GQL_CountryCode["Bi"] = "BI";
    GQL_CountryCode["Bj"] = "BJ";
    GQL_CountryCode["Bl"] = "BL";
    GQL_CountryCode["Bm"] = "BM";
    GQL_CountryCode["Bn"] = "BN";
    GQL_CountryCode["Bo"] = "BO";
    GQL_CountryCode["Bq"] = "BQ";
    GQL_CountryCode["Br"] = "BR";
    GQL_CountryCode["Bs"] = "BS";
    GQL_CountryCode["Bt"] = "BT";
    GQL_CountryCode["Bv"] = "BV";
    GQL_CountryCode["Bw"] = "BW";
    GQL_CountryCode["By"] = "BY";
    GQL_CountryCode["Bz"] = "BZ";
    GQL_CountryCode["Ca"] = "CA";
    GQL_CountryCode["Cc"] = "CC";
    GQL_CountryCode["Cd"] = "CD";
    GQL_CountryCode["Cf"] = "CF";
    GQL_CountryCode["Cg"] = "CG";
    GQL_CountryCode["Ch"] = "CH";
    GQL_CountryCode["Ci"] = "CI";
    GQL_CountryCode["Ck"] = "CK";
    GQL_CountryCode["Cl"] = "CL";
    GQL_CountryCode["Cm"] = "CM";
    GQL_CountryCode["Cn"] = "CN";
    GQL_CountryCode["Co"] = "CO";
    GQL_CountryCode["Cr"] = "CR";
    GQL_CountryCode["Cu"] = "CU";
    GQL_CountryCode["Cv"] = "CV";
    GQL_CountryCode["Cw"] = "CW";
    GQL_CountryCode["Cx"] = "CX";
    GQL_CountryCode["Cy"] = "CY";
    GQL_CountryCode["Cz"] = "CZ";
    GQL_CountryCode["De"] = "DE";
    GQL_CountryCode["Dj"] = "DJ";
    GQL_CountryCode["Dk"] = "DK";
    GQL_CountryCode["Dm"] = "DM";
    GQL_CountryCode["Do"] = "DO";
    GQL_CountryCode["Dz"] = "DZ";
    GQL_CountryCode["Ec"] = "EC";
    GQL_CountryCode["Ee"] = "EE";
    GQL_CountryCode["Eg"] = "EG";
    GQL_CountryCode["Eh"] = "EH";
    GQL_CountryCode["Er"] = "ER";
    GQL_CountryCode["Es"] = "ES";
    GQL_CountryCode["Et"] = "ET";
    GQL_CountryCode["Fi"] = "FI";
    GQL_CountryCode["Fj"] = "FJ";
    GQL_CountryCode["Fk"] = "FK";
    GQL_CountryCode["Fo"] = "FO";
    GQL_CountryCode["Fr"] = "FR";
    GQL_CountryCode["Ga"] = "GA";
    GQL_CountryCode["Gb"] = "GB";
    GQL_CountryCode["Gd"] = "GD";
    GQL_CountryCode["Ge"] = "GE";
    GQL_CountryCode["Gf"] = "GF";
    GQL_CountryCode["Gg"] = "GG";
    GQL_CountryCode["Gh"] = "GH";
    GQL_CountryCode["Gi"] = "GI";
    GQL_CountryCode["Gl"] = "GL";
    GQL_CountryCode["Gm"] = "GM";
    GQL_CountryCode["Gn"] = "GN";
    GQL_CountryCode["Gp"] = "GP";
    GQL_CountryCode["Gq"] = "GQ";
    GQL_CountryCode["Gr"] = "GR";
    GQL_CountryCode["Gs"] = "GS";
    GQL_CountryCode["Gt"] = "GT";
    GQL_CountryCode["Gw"] = "GW";
    GQL_CountryCode["Gy"] = "GY";
    GQL_CountryCode["Hk"] = "HK";
    GQL_CountryCode["Hm"] = "HM";
    GQL_CountryCode["Hn"] = "HN";
    GQL_CountryCode["Hr"] = "HR";
    GQL_CountryCode["Ht"] = "HT";
    GQL_CountryCode["Hu"] = "HU";
    GQL_CountryCode["Id"] = "ID";
    GQL_CountryCode["Ie"] = "IE";
    GQL_CountryCode["Il"] = "IL";
    GQL_CountryCode["Im"] = "IM";
    GQL_CountryCode["In"] = "IN";
    GQL_CountryCode["Io"] = "IO";
    GQL_CountryCode["Iq"] = "IQ";
    GQL_CountryCode["Ir"] = "IR";
    GQL_CountryCode["Is"] = "IS";
    GQL_CountryCode["It"] = "IT";
    GQL_CountryCode["Je"] = "JE";
    GQL_CountryCode["Jm"] = "JM";
    GQL_CountryCode["Jo"] = "JO";
    GQL_CountryCode["Jp"] = "JP";
    GQL_CountryCode["Ke"] = "KE";
    GQL_CountryCode["Kg"] = "KG";
    GQL_CountryCode["Kh"] = "KH";
    GQL_CountryCode["Ki"] = "KI";
    GQL_CountryCode["Km"] = "KM";
    GQL_CountryCode["Kn"] = "KN";
    GQL_CountryCode["Kp"] = "KP";
    GQL_CountryCode["Kr"] = "KR";
    GQL_CountryCode["Kw"] = "KW";
    GQL_CountryCode["Ky"] = "KY";
    GQL_CountryCode["Kz"] = "KZ";
    GQL_CountryCode["La"] = "LA";
    GQL_CountryCode["Lb"] = "LB";
    GQL_CountryCode["Lc"] = "LC";
    GQL_CountryCode["Li"] = "LI";
    GQL_CountryCode["Lk"] = "LK";
    GQL_CountryCode["Lr"] = "LR";
    GQL_CountryCode["Ls"] = "LS";
    GQL_CountryCode["Lt"] = "LT";
    GQL_CountryCode["Lu"] = "LU";
    GQL_CountryCode["Lv"] = "LV";
    GQL_CountryCode["Ly"] = "LY";
    GQL_CountryCode["Ma"] = "MA";
    GQL_CountryCode["Mc"] = "MC";
    GQL_CountryCode["Md"] = "MD";
    GQL_CountryCode["Me"] = "ME";
    GQL_CountryCode["Mf"] = "MF";
    GQL_CountryCode["Mg"] = "MG";
    GQL_CountryCode["Mk"] = "MK";
    GQL_CountryCode["Ml"] = "ML";
    GQL_CountryCode["Mm"] = "MM";
    GQL_CountryCode["Mn"] = "MN";
    GQL_CountryCode["Mo"] = "MO";
    GQL_CountryCode["Mq"] = "MQ";
    GQL_CountryCode["Mr"] = "MR";
    GQL_CountryCode["Ms"] = "MS";
    GQL_CountryCode["Mt"] = "MT";
    GQL_CountryCode["Mu"] = "MU";
    GQL_CountryCode["Mv"] = "MV";
    GQL_CountryCode["Mw"] = "MW";
    GQL_CountryCode["Mx"] = "MX";
    GQL_CountryCode["My"] = "MY";
    GQL_CountryCode["Mz"] = "MZ";
    GQL_CountryCode["Na"] = "NA";
    GQL_CountryCode["Nc"] = "NC";
    GQL_CountryCode["Ne"] = "NE";
    GQL_CountryCode["Nf"] = "NF";
    GQL_CountryCode["Ng"] = "NG";
    GQL_CountryCode["Ni"] = "NI";
    GQL_CountryCode["Nl"] = "NL";
    GQL_CountryCode["No"] = "NO";
    GQL_CountryCode["Np"] = "NP";
    GQL_CountryCode["Nr"] = "NR";
    GQL_CountryCode["Nu"] = "NU";
    GQL_CountryCode["Nz"] = "NZ";
    GQL_CountryCode["Om"] = "OM";
    GQL_CountryCode["Pa"] = "PA";
    GQL_CountryCode["Pe"] = "PE";
    GQL_CountryCode["Pf"] = "PF";
    GQL_CountryCode["Pg"] = "PG";
    GQL_CountryCode["Ph"] = "PH";
    GQL_CountryCode["Pk"] = "PK";
    GQL_CountryCode["Pl"] = "PL";
    GQL_CountryCode["Pm"] = "PM";
    GQL_CountryCode["Pn"] = "PN";
    GQL_CountryCode["Ps"] = "PS";
    GQL_CountryCode["Pt"] = "PT";
    GQL_CountryCode["Py"] = "PY";
    GQL_CountryCode["Qa"] = "QA";
    GQL_CountryCode["Re"] = "RE";
    GQL_CountryCode["Ro"] = "RO";
    GQL_CountryCode["Rs"] = "RS";
    GQL_CountryCode["Ru"] = "RU";
    GQL_CountryCode["Rw"] = "RW";
    GQL_CountryCode["Sa"] = "SA";
    GQL_CountryCode["Sb"] = "SB";
    GQL_CountryCode["Sc"] = "SC";
    GQL_CountryCode["Sd"] = "SD";
    GQL_CountryCode["Se"] = "SE";
    GQL_CountryCode["Sg"] = "SG";
    GQL_CountryCode["Sh"] = "SH";
    GQL_CountryCode["Si"] = "SI";
    GQL_CountryCode["Sj"] = "SJ";
    GQL_CountryCode["Sk"] = "SK";
    GQL_CountryCode["Sl"] = "SL";
    GQL_CountryCode["Sm"] = "SM";
    GQL_CountryCode["Sn"] = "SN";
    GQL_CountryCode["So"] = "SO";
    GQL_CountryCode["Sr"] = "SR";
    GQL_CountryCode["Ss"] = "SS";
    GQL_CountryCode["St"] = "ST";
    GQL_CountryCode["Sv"] = "SV";
    GQL_CountryCode["Sx"] = "SX";
    GQL_CountryCode["Sy"] = "SY";
    GQL_CountryCode["Sz"] = "SZ";
    GQL_CountryCode["Tc"] = "TC";
    GQL_CountryCode["Td"] = "TD";
    GQL_CountryCode["Tf"] = "TF";
    GQL_CountryCode["Tg"] = "TG";
    GQL_CountryCode["Th"] = "TH";
    GQL_CountryCode["Tj"] = "TJ";
    GQL_CountryCode["Tk"] = "TK";
    GQL_CountryCode["Tl"] = "TL";
    GQL_CountryCode["Tm"] = "TM";
    GQL_CountryCode["Tn"] = "TN";
    GQL_CountryCode["To"] = "TO";
    GQL_CountryCode["Tr"] = "TR";
    GQL_CountryCode["Tt"] = "TT";
    GQL_CountryCode["Tv"] = "TV";
    GQL_CountryCode["Tw"] = "TW";
    GQL_CountryCode["Tz"] = "TZ";
    GQL_CountryCode["Ua"] = "UA";
    GQL_CountryCode["Ug"] = "UG";
    GQL_CountryCode["Um"] = "UM";
    GQL_CountryCode["Us"] = "US";
    GQL_CountryCode["Uy"] = "UY";
    GQL_CountryCode["Uz"] = "UZ";
    GQL_CountryCode["Va"] = "VA";
    GQL_CountryCode["Vc"] = "VC";
    GQL_CountryCode["Ve"] = "VE";
    GQL_CountryCode["Vg"] = "VG";
    GQL_CountryCode["Vn"] = "VN";
    GQL_CountryCode["Vu"] = "VU";
    GQL_CountryCode["Wf"] = "WF";
    GQL_CountryCode["Ws"] = "WS";
    GQL_CountryCode["Xk"] = "XK";
    GQL_CountryCode["Ye"] = "YE";
    GQL_CountryCode["Yt"] = "YT";
    GQL_CountryCode["Za"] = "ZA";
    GQL_CountryCode["Zm"] = "ZM";
    GQL_CountryCode["Zw"] = "ZW";
})(GQL_CountryCode || (GQL_CountryCode = {}));
export var GQL_CouponCodeOrderByField;
(function(GQL_CouponCodeOrderByField) {
    GQL_CouponCodeOrderByField["CouponCode"] = "couponCode";
    GQL_CouponCodeOrderByField["MaxUsageOverall"] = "maxUsageOverall";
    GQL_CouponCodeOrderByField["MaxUsagePerCustomer"] = "maxUsagePerCustomer";
    GQL_CouponCodeOrderByField["CreatedAt"] = "createdAt";
})(GQL_CouponCodeOrderByField || (GQL_CouponCodeOrderByField = {}));
export var GQL_CouponCodeStatus;
(function(GQL_CouponCodeStatus) {
    GQL_CouponCodeStatus["Enabled"] = "ENABLED";
    GQL_CouponCodeStatus["Disabled"] = "DISABLED";
})(GQL_CouponCodeStatus || (GQL_CouponCodeStatus = {}));
export var GQL_CreateTeamStoreErrorCode;
(function(GQL_CreateTeamStoreErrorCode) {
    GQL_CreateTeamStoreErrorCode["SlugurlLocalizationAlreadyExists"] = "SLUGURL_LOCALIZATION_ALREADY_EXISTS";
})(GQL_CreateTeamStoreErrorCode || (GQL_CreateTeamStoreErrorCode = {}));
export var GQL_CurrencyCode;
(function(GQL_CurrencyCode) {
    GQL_CurrencyCode["Aud"] = "AUD";
    GQL_CurrencyCode["Chf"] = "CHF";
    GQL_CurrencyCode["Dkk"] = "DKK";
    GQL_CurrencyCode["Sek"] = "SEK";
    GQL_CurrencyCode["Zar"] = "ZAR";
    GQL_CurrencyCode["Eur"] = "EUR";
    GQL_CurrencyCode["Thb"] = "THB";
    GQL_CurrencyCode["Usd"] = "USD";
    GQL_CurrencyCode["Cad"] = "CAD";
    GQL_CurrencyCode["Gbp"] = "GBP";
    GQL_CurrencyCode["Mxn"] = "MXN";
    GQL_CurrencyCode["Cny"] = "CNY";
    GQL_CurrencyCode["Sgd"] = "SGD";
    GQL_CurrencyCode["Nok"] = "NOK";
    GQL_CurrencyCode["Brl"] = "BRL";
    GQL_CurrencyCode["Jpy"] = "JPY";
})(GQL_CurrencyCode || (GQL_CurrencyCode = {}));
export var GQL_DiscountAppliableType;
(function(GQL_DiscountAppliableType) {
    GQL_DiscountAppliableType["Cart"] = "CART";
    GQL_DiscountAppliableType["CartItem"] = "CART_ITEM";
})(GQL_DiscountAppliableType || (GQL_DiscountAppliableType = {}));
export var GQL_DiscountCategory;
(function(GQL_DiscountCategory) {
    GQL_DiscountCategory["Fee"] = "FEE";
    GQL_DiscountCategory["Fundraising"] = "FUNDRAISING";
    GQL_DiscountCategory["Promotion"] = "PROMOTION";
    GQL_DiscountCategory["Coupon"] = "COUPON";
    GQL_DiscountCategory["Other"] = "OTHER";
})(GQL_DiscountCategory || (GQL_DiscountCategory = {}));
export var GQL_DiscountOrderByField;
(function(GQL_DiscountOrderByField) {
    GQL_DiscountOrderByField["DefaultTitle"] = "defaultTitle";
    GQL_DiscountOrderByField["UpdatedAt"] = "updatedAt";
    GQL_DiscountOrderByField["AvailableFrom"] = "availableFrom";
    GQL_DiscountOrderByField["AvailableUntil"] = "availableUntil";
})(GQL_DiscountOrderByField || (GQL_DiscountOrderByField = {}));
export var GQL_DiscountRuleCondition;
(function(GQL_DiscountRuleCondition) {
    GQL_DiscountRuleCondition["Eq"] = "EQ";
    GQL_DiscountRuleCondition["In"] = "IN";
    GQL_DiscountRuleCondition["Gt"] = "GT";
    GQL_DiscountRuleCondition["Lt"] = "LT";
    GQL_DiscountRuleCondition["NotEq"] = "NOT_EQ";
    GQL_DiscountRuleCondition["NotIn"] = "NOT_IN";
})(GQL_DiscountRuleCondition || (GQL_DiscountRuleCondition = {}));
export var GQL_DiscountRuleObjectType;
(function(GQL_DiscountRuleObjectType) {
    GQL_DiscountRuleObjectType["CartValue"] = "CART_VALUE";
    GQL_DiscountRuleObjectType["CartItemValue"] = "CART_ITEM_VALUE";
    GQL_DiscountRuleObjectType["CartItemQuantity"] = "CART_ITEM_QUANTITY";
    GQL_DiscountRuleObjectType["CartTotalQuantity"] = "CART_TOTAL_QUANTITY";
    GQL_DiscountRuleObjectType["Customer"] = "CUSTOMER";
    GQL_DiscountRuleObjectType["Collection"] = "COLLECTION";
    GQL_DiscountRuleObjectType["ProductType"] = "PRODUCT_TYPE";
    GQL_DiscountRuleObjectType["Product"] = "PRODUCT";
    GQL_DiscountRuleObjectType["Productpackage"] = "PRODUCTPACKAGE";
    GQL_DiscountRuleObjectType["Saleschannel"] = "SALESCHANNEL";
    GQL_DiscountRuleObjectType["Currency"] = "CURRENCY";
})(GQL_DiscountRuleObjectType || (GQL_DiscountRuleObjectType = {}));
export var GQL_DiscountStatus;
(function(GQL_DiscountStatus) {
    GQL_DiscountStatus["Enabled"] = "ENABLED";
    GQL_DiscountStatus["Disabled"] = "DISABLED";
})(GQL_DiscountStatus || (GQL_DiscountStatus = {}));
export var GQL_DiscountType;
(function(GQL_DiscountType) {
    GQL_DiscountType["FixedTotalDiscount"] = "FIXED_TOTAL_DISCOUNT";
    GQL_DiscountType["PercentTotalDiscount"] = "PERCENT_TOTAL_DISCOUNT";
    GQL_DiscountType["FreeShipping"] = "FREE_SHIPPING";
    GQL_DiscountType["BuyXGetY"] = "BUY_X_GET_Y";
})(GQL_DiscountType || (GQL_DiscountType = {}));
export var GQL_DocumentFileTypeInput;
(function(GQL_DocumentFileTypeInput) {
    GQL_DocumentFileTypeInput["Pdf"] = "PDF";
    GQL_DocumentFileTypeInput["Xlsx"] = "XLSX";
})(GQL_DocumentFileTypeInput || (GQL_DocumentFileTypeInput = {}));
export var GQL_DocumentTypeInput;
(function(GQL_DocumentTypeInput) {
    GQL_DocumentTypeInput["Orders"] = "ORDERS";
    GQL_DocumentTypeInput["Order"] = "ORDER";
})(GQL_DocumentTypeInput || (GQL_DocumentTypeInput = {}));
export var GQL_ExternalVendor;
(function(GQL_ExternalVendor) {
    GQL_ExternalVendor["Shopify"] = "SHOPIFY";
    GQL_ExternalVendor["Magento"] = "MAGENTO";
})(GQL_ExternalVendor || (GQL_ExternalVendor = {}));
export var GQL_FeeneyDesignExportStatus;
(function(GQL_FeeneyDesignExportStatus) {
    GQL_FeeneyDesignExportStatus["Processing"] = "PROCESSING";
    GQL_FeeneyDesignExportStatus["Failed"] = "FAILED";
    GQL_FeeneyDesignExportStatus["Completed"] = "COMPLETED";
})(GQL_FeeneyDesignExportStatus || (GQL_FeeneyDesignExportStatus = {}));
export var GQL_JobStatus;
(function(GQL_JobStatus) {
    GQL_JobStatus["Queued"] = "QUEUED";
    GQL_JobStatus["Processing"] = "PROCESSING";
    GQL_JobStatus["Failed"] = "FAILED";
    GQL_JobStatus["Completed"] = "COMPLETED";
    GQL_JobStatus["Canceled"] = "CANCELED";
    GQL_JobStatus["RollingBack"] = "ROLLING_BACK";
    GQL_JobStatus["RollbackFailed"] = "ROLLBACK_FAILED";
    GQL_JobStatus["RollbackCompleted"] = "ROLLBACK_COMPLETED";
})(GQL_JobStatus || (GQL_JobStatus = {}));
export var GQL_JobType;
(function(GQL_JobType) {
    GQL_JobType["File"] = "FILE";
    GQL_JobType["Report"] = "REPORT";
    GQL_JobType["MultiviewPlaceholders"] = "MULTIVIEW_PLACEHOLDERS";
    GQL_JobType["Invoice"] = "INVOICE";
})(GQL_JobType || (GQL_JobType = {}));
export var GQL_LanguageCode;
(function(GQL_LanguageCode) {
    GQL_LanguageCode["EnUk"] = "en_UK";
    GQL_LanguageCode["EnUs"] = "en_US";
    GQL_LanguageCode["EnCa"] = "en_CA";
    GQL_LanguageCode["EnAu"] = "en_AU";
    GQL_LanguageCode["NlNl"] = "nl_NL";
    GQL_LanguageCode["FrFr"] = "fr_FR";
    GQL_LanguageCode["FrCa"] = "fr_CA";
    GQL_LanguageCode["ThTh"] = "th_TH";
    GQL_LanguageCode["DeDe"] = "de_DE";
    GQL_LanguageCode["ZhCn"] = "zh_CN";
    GQL_LanguageCode["JaJp"] = "ja_JP";
    GQL_LanguageCode["EsMx"] = "es_MX";
    GQL_LanguageCode["EsEs"] = "es_ES";
    GQL_LanguageCode["LvLv"] = "lv_LV";
    GQL_LanguageCode["NoNo"] = "no_NO";
    GQL_LanguageCode["PtBr"] = "pt_BR";
    GQL_LanguageCode["PtPt"] = "pt_PT";
    GQL_LanguageCode["FiFi"] = "fi_FI";
})(GQL_LanguageCode || (GQL_LanguageCode = {}));
export var GQL_LeadOrderByEnum;
(function(GQL_LeadOrderByEnum) {
    GQL_LeadOrderByEnum["Asc"] = "asc";
    GQL_LeadOrderByEnum["Desc"] = "desc";
})(GQL_LeadOrderByEnum || (GQL_LeadOrderByEnum = {}));
export var GQL_MeasurementUnit;
(function(GQL_MeasurementUnit) {
    GQL_MeasurementUnit["Cm"] = "CM";
    GQL_MeasurementUnit["In"] = "IN";
})(GQL_MeasurementUnit || (GQL_MeasurementUnit = {}));
export var GQL_MenuContainerType;
(function(GQL_MenuContainerType) {
    GQL_MenuContainerType["Header"] = "HEADER";
    GQL_MenuContainerType["Footer"] = "FOOTER";
})(GQL_MenuContainerType || (GQL_MenuContainerType = {}));
export var GQL_MenuItemModuleType;
(function(GQL_MenuItemModuleType) {
    GQL_MenuItemModuleType["SearchBar"] = "SEARCH_BAR";
    GQL_MenuItemModuleType["Logo"] = "LOGO";
    GQL_MenuItemModuleType["CurrencySelector"] = "CURRENCY_SELECTOR";
    GQL_MenuItemModuleType["LanguageSelector"] = "LANGUAGE_SELECTOR";
    GQL_MenuItemModuleType["SocialMedia"] = "SOCIAL_MEDIA";
    GQL_MenuItemModuleType["TermsAndConditions"] = "TERMS_AND_CONDITIONS";
    GQL_MenuItemModuleType["Cart"] = "CART";
    GQL_MenuItemModuleType["PaymentOptions"] = "PAYMENT_OPTIONS";
    GQL_MenuItemModuleType["ContactAddress"] = "CONTACT_ADDRESS";
    GQL_MenuItemModuleType["Collection"] = "COLLECTION";
    GQL_MenuItemModuleType["Product"] = "PRODUCT";
    GQL_MenuItemModuleType["ContentPage"] = "CONTENT_PAGE";
})(GQL_MenuItemModuleType || (GQL_MenuItemModuleType = {}));
export var GQL_MenuItemType;
(function(GQL_MenuItemType) {
    GQL_MenuItemType["Module"] = "MODULE";
    GQL_MenuItemType["Link"] = "LINK";
})(GQL_MenuItemType || (GQL_MenuItemType = {}));
export var GQL_MetaFieldResourceType;
(function(GQL_MetaFieldResourceType) {
    GQL_MetaFieldResourceType["Saleschannel"] = "SALESCHANNEL";
    GQL_MetaFieldResourceType["Product"] = "PRODUCT";
})(GQL_MetaFieldResourceType || (GQL_MetaFieldResourceType = {}));
export var GQL_MetaFieldType;
(function(GQL_MetaFieldType) {
    GQL_MetaFieldType["SingleLineTextField"] = "SINGLE_LINE_TEXT_FIELD";
    GQL_MetaFieldType["MultipleLineTextField"] = "MULTIPLE_LINE_TEXT_FIELD";
    GQL_MetaFieldType["Boolean"] = "BOOLEAN";
    GQL_MetaFieldType["Int"] = "INT";
    GQL_MetaFieldType["Number"] = "NUMBER";
    GQL_MetaFieldType["Option"] = "OPTION";
})(GQL_MetaFieldType || (GQL_MetaFieldType = {}));
export var GQL_NotificationChannel;
(function(GQL_NotificationChannel) {
    GQL_NotificationChannel["Email"] = "EMAIL";
    GQL_NotificationChannel["Webhook"] = "WEBHOOK";
})(GQL_NotificationChannel || (GQL_NotificationChannel = {}));
export var GQL_NotificationType;
(function(GQL_NotificationType) {
    GQL_NotificationType["ContactRequestSubmitted"] = "CONTACT_REQUEST_SUBMITTED";
    GQL_NotificationType["OrderConfirmation"] = "ORDER_CONFIRMATION";
    GQL_NotificationType["NewLeadCreated"] = "NEW_LEAD_CREATED";
    GQL_NotificationType["QuotationRequestConfirmation"] = "QUOTATION_REQUEST_CONFIRMATION";
    GQL_NotificationType["AdminPortalInvitation"] = "ADMIN_PORTAL_INVITATION";
})(GQL_NotificationType || (GQL_NotificationType = {}));
export var GQL_OrderBySortOrder;
(function(GQL_OrderBySortOrder) {
    GQL_OrderBySortOrder["Asc"] = "asc";
    GQL_OrderBySortOrder["Desc"] = "desc";
})(GQL_OrderBySortOrder || (GQL_OrderBySortOrder = {}));
export var GQL_OrderDiscountCategory;
(function(GQL_OrderDiscountCategory) {
    GQL_OrderDiscountCategory["Fundraising"] = "FUNDRAISING";
    GQL_OrderDiscountCategory["Other"] = "OTHER";
})(GQL_OrderDiscountCategory || (GQL_OrderDiscountCategory = {}));
export var GQL_OrderOrderByEnum;
(function(GQL_OrderOrderByEnum) {
    GQL_OrderOrderByEnum["Asc"] = "asc";
    GQL_OrderOrderByEnum["Desc"] = "desc";
})(GQL_OrderOrderByEnum || (GQL_OrderOrderByEnum = {}));
export var GQL_OrderPaymentStatusEnum;
(function(GQL_OrderPaymentStatusEnum) {
    GQL_OrderPaymentStatusEnum["Canceled"] = "CANCELED";
    GQL_OrderPaymentStatusEnum["Cancelled"] = "CANCELLED";
    GQL_OrderPaymentStatusEnum["Failed"] = "FAILED";
    GQL_OrderPaymentStatusEnum["Paid"] = "PAID";
    GQL_OrderPaymentStatusEnum["Pending"] = "PENDING";
})(GQL_OrderPaymentStatusEnum || (GQL_OrderPaymentStatusEnum = {}));
export var GQL_OrganisationRole;
(function(GQL_OrganisationRole) {
    GQL_OrganisationRole["Owner"] = "OWNER";
})(GQL_OrganisationRole || (GQL_OrganisationRole = {}));
export var GQL_PaymentMethod;
(function(GQL_PaymentMethod) {
    GQL_PaymentMethod["Applepay"] = "APPLEPAY";
    GQL_PaymentMethod["BankTransfer"] = "BANK_TRANSFER";
    GQL_PaymentMethod["Bancontact"] = "BANCONTACT";
    GQL_PaymentMethod["Ideal"] = "IDEAL";
    GQL_PaymentMethod["Sofort"] = "SOFORT";
    GQL_PaymentMethod["CreditCard"] = "CREDIT_CARD";
    GQL_PaymentMethod["Cash"] = "CASH";
    GQL_PaymentMethod["CashOnDelivery"] = "CASH_ON_DELIVERY";
    GQL_PaymentMethod["Promptpay"] = "PROMPTPAY";
    GQL_PaymentMethod["PurchaseOrder"] = "PURCHASE_ORDER";
    GQL_PaymentMethod["Manual"] = "MANUAL";
})(GQL_PaymentMethod || (GQL_PaymentMethod = {}));
export var GQL_PaymentMethodInput;
(function(GQL_PaymentMethodInput) {
    GQL_PaymentMethodInput["Applepay"] = "APPLEPAY";
    GQL_PaymentMethodInput["Bancontact"] = "BANCONTACT";
    GQL_PaymentMethodInput["BankTransfer"] = "BANK_TRANSFER";
    GQL_PaymentMethodInput["Cash"] = "CASH";
    GQL_PaymentMethodInput["CashOnDelivery"] = "CASH_ON_DELIVERY";
    GQL_PaymentMethodInput["CreditCard"] = "CREDIT_CARD";
    GQL_PaymentMethodInput["Ideal"] = "IDEAL";
    GQL_PaymentMethodInput["Manual"] = "MANUAL";
    GQL_PaymentMethodInput["Promptpay"] = "PROMPTPAY";
    GQL_PaymentMethodInput["PurchaseOrder"] = "PURCHASE_ORDER";
    GQL_PaymentMethodInput["Sofort"] = "SOFORT";
})(GQL_PaymentMethodInput || (GQL_PaymentMethodInput = {}));
export var GQL_PaymentProvider;
(function(GQL_PaymentProvider) {
    GQL_PaymentProvider["StripeConnect"] = "STRIPE_CONNECT";
    GQL_PaymentProvider["Stripe"] = "STRIPE";
    GQL_PaymentProvider["Omise"] = "OMISE";
    GQL_PaymentProvider["Paygate"] = "PAYGATE";
    GQL_PaymentProvider["Custom"] = "CUSTOM";
    GQL_PaymentProvider["Manual"] = "MANUAL";
})(GQL_PaymentProvider || (GQL_PaymentProvider = {}));
export var GQL_PaymentProviderInput;
(function(GQL_PaymentProviderInput) {
    GQL_PaymentProviderInput["StripeConnect"] = "STRIPE_CONNECT";
    GQL_PaymentProviderInput["Omise"] = "OMISE";
    GQL_PaymentProviderInput["Manual"] = "MANUAL";
})(GQL_PaymentProviderInput || (GQL_PaymentProviderInput = {}));
export var GQL_PaymentStatus;
(function(GQL_PaymentStatus) {
    GQL_PaymentStatus["Pending"] = "PENDING";
    GQL_PaymentStatus["Processing"] = "PROCESSING";
    GQL_PaymentStatus["Refunded"] = "REFUNDED";
    GQL_PaymentStatus["Paid"] = "PAID";
    GQL_PaymentStatus["Failed"] = "FAILED";
    GQL_PaymentStatus["Voided"] = "VOIDED";
})(GQL_PaymentStatus || (GQL_PaymentStatus = {}));
export var GQL_PlaceholderActualDimensionUnit;
(function(GQL_PlaceholderActualDimensionUnit) {
    GQL_PlaceholderActualDimensionUnit["Cm"] = "CM";
    GQL_PlaceholderActualDimensionUnit["In"] = "IN";
})(GQL_PlaceholderActualDimensionUnit || (GQL_PlaceholderActualDimensionUnit = {}));
export var GQL_PlaceholderImageItemType;
(function(GQL_PlaceholderImageItemType) {
    GQL_PlaceholderImageItemType["Text"] = "TEXT";
    GQL_PlaceholderImageItemType["Image"] = "IMAGE";
})(GQL_PlaceholderImageItemType || (GQL_PlaceholderImageItemType = {}));
export var GQL_PlaceholderOriginType;
(function(GQL_PlaceholderOriginType) {
    GQL_PlaceholderOriginType["Center"] = "CENTER";
    GQL_PlaceholderOriginType["Right"] = "RIGHT";
    GQL_PlaceholderOriginType["Left"] = "LEFT";
    GQL_PlaceholderOriginType["Top"] = "TOP";
    GQL_PlaceholderOriginType["Bottom"] = "BOTTOM";
})(GQL_PlaceholderOriginType || (GQL_PlaceholderOriginType = {}));
export var GQL_PriceModifierConfiguratioOrderByField;
(function(GQL_PriceModifierConfiguratioOrderByField) {
    GQL_PriceModifierConfiguratioOrderByField["DiscountId"] = "discountId";
    GQL_PriceModifierConfiguratioOrderByField["SalesChannelId"] = "salesChannelId";
})(GQL_PriceModifierConfiguratioOrderByField || (GQL_PriceModifierConfiguratioOrderByField = {}));
export var GQL_PriceModifierDiscountType;
(function(GQL_PriceModifierDiscountType) {
    GQL_PriceModifierDiscountType["FixedTotalDiscount"] = "FIXED_TOTAL_DISCOUNT";
    GQL_PriceModifierDiscountType["PercentTotalDiscount"] = "PERCENT_TOTAL_DISCOUNT";
})(GQL_PriceModifierDiscountType || (GQL_PriceModifierDiscountType = {}));
export var GQL_ProductCollectionsOrderByField;
(function(GQL_ProductCollectionsOrderByField) {
    GQL_ProductCollectionsOrderByField["InternalId"] = "internalId";
    GQL_ProductCollectionsOrderByField["UpdatedAt"] = "updatedAt";
})(GQL_ProductCollectionsOrderByField || (GQL_ProductCollectionsOrderByField = {}));
export var GQL_ProductDesignApprovalStatus;
(function(GQL_ProductDesignApprovalStatus) {
    GQL_ProductDesignApprovalStatus["WaitingForApproval"] = "WAITING_FOR_APPROVAL";
    GQL_ProductDesignApprovalStatus["Rejected"] = "REJECTED";
    GQL_ProductDesignApprovalStatus["Approved"] = "APPROVED";
})(GQL_ProductDesignApprovalStatus || (GQL_ProductDesignApprovalStatus = {}));
export var GQL_ProductMediaType;
(function(GQL_ProductMediaType) {
    GQL_ProductMediaType["Image"] = "IMAGE";
    GQL_ProductMediaType["Video"] = "VIDEO";
    GQL_ProductMediaType["Interactive"] = "INTERACTIVE";
    GQL_ProductMediaType["Original"] = "ORIGINAL";
    GQL_ProductMediaType["Custom"] = "CUSTOM";
    GQL_ProductMediaType["DuplicatedBaseProduct"] = "DUPLICATED_BASE_PRODUCT";
})(GQL_ProductMediaType || (GQL_ProductMediaType = {}));
export var GQL_ProductOptionType;
(function(GQL_ProductOptionType) {
    GQL_ProductOptionType["Color"] = "COLOR";
    GQL_ProductOptionType["Size"] = "SIZE";
    GQL_ProductOptionType["Material"] = "MATERIAL";
    GQL_ProductOptionType["Gender"] = "GENDER";
    GQL_ProductOptionType["Other"] = "OTHER";
})(GQL_ProductOptionType || (GQL_ProductOptionType = {}));
export var GQL_ProductOptionsOrderByField;
(function(GQL_ProductOptionsOrderByField) {
    GQL_ProductOptionsOrderByField["SortOrder"] = "sortOrder";
})(GQL_ProductOptionsOrderByField || (GQL_ProductOptionsOrderByField = {}));
export var GQL_ProductOrderByField;
(function(GQL_ProductOrderByField) {
    GQL_ProductOrderByField["ProductId"] = "productId";
    GQL_ProductOrderByField["SortOrder"] = "sortOrder";
})(GQL_ProductOrderByField || (GQL_ProductOrderByField = {}));
export var GQL_ProductPersonalizationDuplicationRule;
(function(GQL_ProductPersonalizationDuplicationRule) {
    GQL_ProductPersonalizationDuplicationRule["Allow"] = "ALLOW";
    GQL_ProductPersonalizationDuplicationRule["Warn"] = "WARN";
    GQL_ProductPersonalizationDuplicationRule["Block"] = "BLOCK";
})(GQL_ProductPersonalizationDuplicationRule || (GQL_ProductPersonalizationDuplicationRule = {}));
export var GQL_ProductPersonalizationGroupInputType;
(function(GQL_ProductPersonalizationGroupInputType) {
    GQL_ProductPersonalizationGroupInputType["Text"] = "TEXT";
    GQL_ProductPersonalizationGroupInputType["Select"] = "SELECT";
    GQL_ProductPersonalizationGroupInputType["MultiSelect"] = "MULTI_SELECT";
})(GQL_ProductPersonalizationGroupInputType || (GQL_ProductPersonalizationGroupInputType = {}));
export var GQL_ProductStatus;
(function(GQL_ProductStatus) {
    GQL_ProductStatus["Active"] = "ACTIVE";
    GQL_ProductStatus["Inactive"] = "INACTIVE";
})(GQL_ProductStatus || (GQL_ProductStatus = {}));
export var GQL_ProductType;
(function(GQL_ProductType) {
    GQL_ProductType["Stock"] = "STOCK";
    GQL_ProductType["Custom"] = "CUSTOM";
    GQL_ProductType["Digital"] = "DIGITAL";
    GQL_ProductType["Package"] = "PACKAGE";
})(GQL_ProductType || (GQL_ProductType = {}));
export var GQL_ProductionStatus;
(function(GQL_ProductionStatus) {
    GQL_ProductionStatus["NotApplicable"] = "NOT_APPLICABLE";
    GQL_ProductionStatus["Pending"] = "PENDING";
    GQL_ProductionStatus["InProduction"] = "IN_PRODUCTION";
    GQL_ProductionStatus["Done"] = "DONE";
})(GQL_ProductionStatus || (GQL_ProductionStatus = {}));
export var GQL_ProductsOrderByField;
(function(GQL_ProductsOrderByField) {
    GQL_ProductsOrderByField["DefaultTitle"] = "defaultTitle";
    GQL_ProductsOrderByField["InternalId"] = "internalId";
    GQL_ProductsOrderByField["BrandTitle"] = "brandTitle";
    GQL_ProductsOrderByField["UpdatedAt"] = "updatedAt";
    GQL_ProductsOrderByField["DefaultSource"] = "defaultSource";
})(GQL_ProductsOrderByField || (GQL_ProductsOrderByField = {}));
export var GQL_QuoteStatus;
(function(GQL_QuoteStatus) {
    GQL_QuoteStatus["Created"] = "CREATED";
    GQL_QuoteStatus["InProgress"] = "IN_PROGRESS";
    GQL_QuoteStatus["Sent"] = "SENT";
    GQL_QuoteStatus["CustomerApproved"] = "CUSTOMER_APPROVED";
    GQL_QuoteStatus["ToPay"] = "TO_PAY";
    GQL_QuoteStatus["Paid"] = "PAID";
})(GQL_QuoteStatus || (GQL_QuoteStatus = {}));
export var GQL_ReportFileTypeInput;
(function(GQL_ReportFileTypeInput) {
    GQL_ReportFileTypeInput["Csv"] = "CSV";
    GQL_ReportFileTypeInput["Xls"] = "XLS";
    GQL_ReportFileTypeInput["Xlsx"] = "XLSX";
})(GQL_ReportFileTypeInput || (GQL_ReportFileTypeInput = {}));
export var GQL_ReportStatus;
(function(GQL_ReportStatus) {
    GQL_ReportStatus["Processing"] = "PROCESSING";
    GQL_ReportStatus["Failed"] = "FAILED";
    GQL_ReportStatus["Completed"] = "COMPLETED";
})(GQL_ReportStatus || (GQL_ReportStatus = {}));
export var GQL_ReportTypeInput;
(function(GQL_ReportTypeInput) {
    GQL_ReportTypeInput["Orders"] = "ORDERS";
    GQL_ReportTypeInput["OrdersItems"] = "ORDERS_ITEMS";
    GQL_ReportTypeInput["TeamStore"] = "TEAM_STORE";
    GQL_ReportTypeInput["OrdersDiscount"] = "ORDERS_DISCOUNT";
    GQL_ReportTypeInput["CouponUsage"] = "COUPON_USAGE";
})(GQL_ReportTypeInput || (GQL_ReportTypeInput = {}));
export var GQL_ResourceType;
(function(GQL_ResourceType) {
    GQL_ResourceType["Order"] = "ORDER";
    GQL_ResourceType["Payment"] = "PAYMENT";
})(GQL_ResourceType || (GQL_ResourceType = {}));
export var GQL_RoundingMode;
(function(GQL_RoundingMode) {
    GQL_RoundingMode["HalfOdd"] = "HALF_ODD";
    GQL_RoundingMode["HalfEven"] = "HALF_EVEN";
    GQL_RoundingMode["HalfUp"] = "HALF_UP";
    GQL_RoundingMode["HalfDown"] = "HALF_DOWN";
    GQL_RoundingMode["HalfTowardsZero"] = "HALF_TOWARDS_ZERO";
    GQL_RoundingMode["HalfAwayFromZero"] = "HALF_AWAY_FROM_ZERO";
    GQL_RoundingMode["Down"] = "DOWN";
})(GQL_RoundingMode || (GQL_RoundingMode = {}));
export var GQL_SalesChannelType;
(function(GQL_SalesChannelType) {
    GQL_SalesChannelType["Storefront"] = "STOREFRONT";
    GQL_SalesChannelType["Teamstore"] = "TEAMSTORE";
})(GQL_SalesChannelType || (GQL_SalesChannelType = {}));
export var GQL_SalesChannelsOrderByField;
(function(GQL_SalesChannelsOrderByField) {
    GQL_SalesChannelsOrderByField["Id"] = "id";
    GQL_SalesChannelsOrderByField["Name"] = "name";
    GQL_SalesChannelsOrderByField["UpdatedAt"] = "updatedAt";
    GQL_SalesChannelsOrderByField["DefaultTitle"] = "defaultTitle";
})(GQL_SalesChannelsOrderByField || (GQL_SalesChannelsOrderByField = {}));
export var GQL_ShipmentDimensionsUnitEnum;
(function(GQL_ShipmentDimensionsUnitEnum) {
    GQL_ShipmentDimensionsUnitEnum["Cm"] = "CM";
    GQL_ShipmentDimensionsUnitEnum["In"] = "IN";
})(GQL_ShipmentDimensionsUnitEnum || (GQL_ShipmentDimensionsUnitEnum = {}));
export var GQL_ShipmentProviderEnum;
(function(GQL_ShipmentProviderEnum) {
    GQL_ShipmentProviderEnum["Sendcloud"] = "SENDCLOUD";
    GQL_ShipmentProviderEnum["Shipengine"] = "SHIPENGINE";
    GQL_ShipmentProviderEnum["Custom"] = "CUSTOM";
    GQL_ShipmentProviderEnum["Bpost"] = "BPOST";
})(GQL_ShipmentProviderEnum || (GQL_ShipmentProviderEnum = {}));
export var GQL_ShipmentWeightUnitEnum;
(function(GQL_ShipmentWeightUnitEnum) {
    GQL_ShipmentWeightUnitEnum["G"] = "G";
    GQL_ShipmentWeightUnitEnum["Kg"] = "KG";
    GQL_ShipmentWeightUnitEnum["Lb"] = "LB";
    GQL_ShipmentWeightUnitEnum["Oz"] = "OZ";
})(GQL_ShipmentWeightUnitEnum || (GQL_ShipmentWeightUnitEnum = {}));
export var GQL_ShippingMethodType;
(function(GQL_ShippingMethodType) {
    GQL_ShippingMethodType["WeightRate"] = "WEIGHT_RATE";
    GQL_ShippingMethodType["FlatRate"] = "FLAT_RATE";
})(GQL_ShippingMethodType || (GQL_ShippingMethodType = {}));
export var GQL_ShippingShippingStatusEnum;
(function(GQL_ShippingShippingStatusEnum) {
    GQL_ShippingShippingStatusEnum["Pending"] = "PENDING";
    GQL_ShippingShippingStatusEnum["Canceled"] = "CANCELED";
    GQL_ShippingShippingStatusEnum["InTransit"] = "IN_TRANSIT";
    GQL_ShippingShippingStatusEnum["Error"] = "ERROR";
    GQL_ShippingShippingStatusEnum["Failed"] = "FAILED";
    GQL_ShippingShippingStatusEnum["Unknow"] = "UNKNOW";
    GQL_ShippingShippingStatusEnum["Delivered"] = "DELIVERED";
})(GQL_ShippingShippingStatusEnum || (GQL_ShippingShippingStatusEnum = {}));
export var GQL_ShippingStatusEnum;
(function(GQL_ShippingStatusEnum) {
    GQL_ShippingStatusEnum["Canceled"] = "CANCELED";
    GQL_ShippingStatusEnum["Delivered"] = "DELIVERED";
    GQL_ShippingStatusEnum["Failed"] = "FAILED";
    GQL_ShippingStatusEnum["Pending"] = "PENDING";
    GQL_ShippingStatusEnum["Shipped"] = "SHIPPED";
})(GQL_ShippingStatusEnum || (GQL_ShippingStatusEnum = {}));
export var GQL_ShopUserRole;
(function(GQL_ShopUserRole) {
    GQL_ShopUserRole["AdminPortalAdmin"] = "ADMIN_PORTAL_ADMIN";
    GQL_ShopUserRole["AdminPortalUser"] = "ADMIN_PORTAL_USER";
    GQL_ShopUserRole["ShopOwner"] = "SHOP_OWNER";
    GQL_ShopUserRole["SalesRep"] = "SALES_REP";
})(GQL_ShopUserRole || (GQL_ShopUserRole = {}));
export var GQL_SpotLightPosition;
(function(GQL_SpotLightPosition) {
    GQL_SpotLightPosition["FrontRight"] = "FRONT_RIGHT";
    GQL_SpotLightPosition["FrontLeft"] = "FRONT_LEFT";
    GQL_SpotLightPosition["BackRight"] = "BACK_RIGHT";
    GQL_SpotLightPosition["BackLeft"] = "BACK_LEFT";
    GQL_SpotLightPosition["Front"] = "FRONT";
})(GQL_SpotLightPosition || (GQL_SpotLightPosition = {}));
export var GQL_SupplierConnectionStatus;
(function(GQL_SupplierConnectionStatus) {
    GQL_SupplierConnectionStatus["Verifying"] = "VERIFYING";
    GQL_SupplierConnectionStatus["Verified"] = "VERIFIED";
    GQL_SupplierConnectionStatus["Failed"] = "FAILED";
    GQL_SupplierConnectionStatus["Deactivated"] = "DEACTIVATED";
})(GQL_SupplierConnectionStatus || (GQL_SupplierConnectionStatus = {}));
export var GQL_SupplierProductOrderByField;
(function(GQL_SupplierProductOrderByField) {
    GQL_SupplierProductOrderByField["Supplier"] = "supplier";
    GQL_SupplierProductOrderByField["ProductId"] = "productId";
    GQL_SupplierProductOrderByField["Title"] = "title";
    GQL_SupplierProductOrderByField["Brand"] = "brand";
    GQL_SupplierProductOrderByField["StyleId"] = "styleId";
    GQL_SupplierProductOrderByField["UpdatedAt"] = "updatedAt";
})(GQL_SupplierProductOrderByField || (GQL_SupplierProductOrderByField = {}));
export var GQL_SupplierProductVariantsOrderByField;
(function(GQL_SupplierProductVariantsOrderByField) {
    GQL_SupplierProductVariantsOrderByField["Color"] = "color";
    GQL_SupplierProductVariantsOrderByField["Size"] = "size";
})(GQL_SupplierProductVariantsOrderByField || (GQL_SupplierProductVariantsOrderByField = {}));
export var GQL_TaxProvider;
(function(GQL_TaxProvider) {
    GQL_TaxProvider["BriklTaxSettings"] = "BRIKL_TAX_SETTINGS";
    GQL_TaxProvider["Taxjar"] = "TAXJAR";
    GQL_TaxProvider["Avalara"] = "AVALARA";
})(GQL_TaxProvider || (GQL_TaxProvider = {}));
export var GQL_TeamStoreOpeningCycleStatus;
(function(GQL_TeamStoreOpeningCycleStatus) {
    GQL_TeamStoreOpeningCycleStatus["Inactive"] = "INACTIVE";
    GQL_TeamStoreOpeningCycleStatus["Scheduled"] = "SCHEDULED";
    GQL_TeamStoreOpeningCycleStatus["Active"] = "ACTIVE";
    GQL_TeamStoreOpeningCycleStatus["Closed"] = "CLOSED";
})(GQL_TeamStoreOpeningCycleStatus || (GQL_TeamStoreOpeningCycleStatus = {}));
export var GQL_TeamStoreProductStatus;
(function(GQL_TeamStoreProductStatus) {
    GQL_TeamStoreProductStatus["Active"] = "ACTIVE";
    GQL_TeamStoreProductStatus["Inactive"] = "INACTIVE";
})(GQL_TeamStoreProductStatus || (GQL_TeamStoreProductStatus = {}));
export var GQL_UpdatableLeadStatus;
(function(GQL_UpdatableLeadStatus) {
    GQL_UpdatableLeadStatus["Canceled"] = "CANCELED";
    GQL_UpdatableLeadStatus["Done"] = "DONE";
    GQL_UpdatableLeadStatus["InProgress"] = "IN_PROGRESS";
})(GQL_UpdatableLeadStatus || (GQL_UpdatableLeadStatus = {}));
export var GQL_UpdatableQuoteStatus;
(function(GQL_UpdatableQuoteStatus) {
    GQL_UpdatableQuoteStatus["InProgress"] = "IN_PROGRESS";
    GQL_UpdatableQuoteStatus["Sent"] = "SENT";
    GQL_UpdatableQuoteStatus["CustomerApproved"] = "CUSTOMER_APPROVED";
    GQL_UpdatableQuoteStatus["ToPay"] = "TO_PAY";
    GQL_UpdatableQuoteStatus["Paid"] = "PAID";
})(GQL_UpdatableQuoteStatus || (GQL_UpdatableQuoteStatus = {}));
export var GQL_UpdateTeamStoreErrorCode;
(function(GQL_UpdateTeamStoreErrorCode) {
    GQL_UpdateTeamStoreErrorCode["SlugurlLocalizationAlreadyExists"] = "SLUGURL_LOCALIZATION_ALREADY_EXISTS";
})(GQL_UpdateTeamStoreErrorCode || (GQL_UpdateTeamStoreErrorCode = {}));
export var GQL_UploadType;
(function(GQL_UploadType) {
    GQL_UploadType["ProductDesignTemplateColorzone"] = "PRODUCT_DESIGN_TEMPLATE_COLORZONE";
    GQL_UploadType["StudioMaterial"] = "STUDIO_MATERIAL";
    GQL_UploadType["Temp"] = "TEMP";
})(GQL_UploadType || (GQL_UploadType = {}));
export var GQL_UserRole;
(function(GQL_UserRole) {
    GQL_UserRole["Admin"] = "ADMIN";
    GQL_UserRole["SaleReps"] = "SALE_REPS";
    GQL_UserRole["SalesRep"] = "SALES_REP";
})(GQL_UserRole || (GQL_UserRole = {}));
export var GQL_UserStatus;
(function(GQL_UserStatus) {
    GQL_UserStatus["Active"] = "ACTIVE";
    GQL_UserStatus["Pending"] = "PENDING";
})(GQL_UserStatus || (GQL_UserStatus = {}));
export var GQL_ValidateCouponCodeFailedReasonCode;
(function(GQL_ValidateCouponCodeFailedReasonCode) {
    GQL_ValidateCouponCodeFailedReasonCode["CouponUsageExceeded"] = "COUPON_USAGE_EXCEEDED";
    GQL_ValidateCouponCodeFailedReasonCode["InvalidCouponCode"] = "INVALID_COUPON_CODE";
})(GQL_ValidateCouponCodeFailedReasonCode || (GQL_ValidateCouponCodeFailedReasonCode = {}));
export var GQL_VerificationStatus;
(function(GQL_VerificationStatus) {
    GQL_VerificationStatus["NotStarted"] = "NOT_STARTED";
    GQL_VerificationStatus["Pending"] = "PENDING";
    GQL_VerificationStatus["Success"] = "SUCCESS";
    GQL_VerificationStatus["Failed"] = "FAILED";
    GQL_VerificationStatus["TemporaryFailure"] = "TEMPORARY_FAILURE";
})(GQL_VerificationStatus || (GQL_VerificationStatus = {}));
export var GQL_WeightUnit;
(function(GQL_WeightUnit) {
    GQL_WeightUnit["Kg"] = "KG";
    GQL_WeightUnit["Lb"] = "LB";
    GQL_WeightUnit["Oz"] = "OZ";
    GQL_WeightUnit["G"] = "G";
})(GQL_WeightUnit || (GQL_WeightUnit = {}));
export var BulkOperationResultNbFragmentDoc = gql(_templateObject());
export var DashboardSearchCouponCodesFieldsNbFragmentDoc = gql(_templateObject1());
export var AdminPortalOpeningCycleFieldsNbFragmentDoc = gql(_templateObject2());
export var AdminPortalUserFragmentDoc = gql(_templateObject3());
export var BaseProductImagePlaceholderFragmentDoc = gql(_templateObject4());
export var DashboardAddressDetailFieldsFragmentDoc = gql(_templateObject5());
export var DashboardAdminUserCommonFieldsNbFragmentDoc = gql(_templateObject6());
export var DashboardCollectionCommonFieldsNbFragmentDoc = gql(_templateObject7());
export var DashboardCollectionInformationFieldsNbFragmentDoc = gql(_templateObject8(), DashboardCollectionCommonFieldsNbFragmentDoc);
export var DashboardCollectionMediaFieldsNbFragmentDoc = gql(_templateObject9(), DashboardCollectionCommonFieldsNbFragmentDoc);
export var DashboardCollectionProductFieldsNbFragmentDoc = gql(_templateObject10());
export var DashboardParentCollectionFieldsNbFragmentDoc = gql(_templateObject11(), DashboardCollectionCommonFieldsNbFragmentDoc);
export var DashboardChildCollectionFieldsNbFragmentDoc = gql(_templateObject12(), DashboardCollectionCommonFieldsNbFragmentDoc);
export var DashboardCollectionAttributeFieldsNbFragmentDoc = gql(_templateObject13(), DashboardCollectionCommonFieldsNbFragmentDoc);
export var DashboardContactFieldsNbFragmentDoc = gql(_templateObject14());
export var DashboardContactMicrostoreFieldsNbFragmentDoc = gql(_templateObject15());
export var DashboardDiscountCouponFieldsFragmentDoc = gql(_templateObject16());
export var DashboardDiscountDetailRuleObjectFieldsFragmentDoc = gql(_templateObject17());
export var DashboardDiscountDetailRuleFieldsFragmentDoc = gql(_templateObject18(), DashboardDiscountDetailRuleObjectFieldsFragmentDoc);
export var DashboardDiscountDetailFieldsFragmentDoc = gql(_templateObject19(), DashboardDiscountDetailRuleFieldsFragmentDoc);
export var DashboardDiscountListItemFieldsFragmentDoc = gql(_templateObject20());
export var DashboardLeadDetailFieldsFragmentDoc = gql(_templateObject21());
export var BillingAddressFragmentDoc = gql(_templateObject22());
export var DashboardOrderListFieldsFragmentDoc = gql(_templateObject23(), BillingAddressFragmentDoc);
export var DashboardOrderDetailFieldsFragmentDoc = gql(_templateObject24(), DashboardOrderListFieldsFragmentDoc);
export var DashboardProductAttributeCommonFieldsNbFragmentDoc = gql(_templateObject25());
export var DashboardProductAttributeDetailFieldsNbFragmentDoc = gql(_templateObject26(), DashboardProductAttributeCommonFieldsNbFragmentDoc);
export var DashboardLeadDetailForQuoteFieldsFragmentDoc = gql(_templateObject27());
export var QuoteCommentDetailFieldsFragmentDoc = gql(_templateObject28());
export var QuoteNoteDetailFieldsFragmentDoc = gql(_templateObject29());
export var DashboardQuoteDetailFieldsFragmentDoc = gql(_templateObject30(), DashboardLeadDetailForQuoteFieldsFragmentDoc, QuoteCommentDetailFieldsFragmentDoc, QuoteNoteDetailFieldsFragmentDoc);
export var DashboardQuoteListFieldsFragmentDoc = gql(_templateObject31());
export var DashboardShopDefaultFontSettingFieldsFragmentDoc = gql(_templateObject32());
export var DashboardTaxSettingsFieldsFragmentDoc = gql(_templateObject33());
export var FeenyExportDesignFragmentDoc = gql(_templateObject34());
export var OrderFragmentDoc = gql(_templateObject35());
export var ImagePlaceholderFragmentDoc = gql(_templateObject36());
export var PlaceholderPositionAndDimensionFragmentDoc = gql(_templateObject37());
export var AmbientLightSettingFragmentDoc = gql(_templateObject38());
export var DomeLightSettingFragmentDoc = gql(_templateObject39());
export var SpotLightSettingFragmentDoc = gql(_templateObject40());
export var ProductLightSettingPresetFragmentDoc = gql(_templateObject41(), AmbientLightSettingFragmentDoc, DomeLightSettingFragmentDoc, SpotLightSettingFragmentDoc);
export var ProductLightPresetFragmentDoc = gql(_templateObject42(), AmbientLightSettingFragmentDoc, DomeLightSettingFragmentDoc, SpotLightSettingFragmentDoc);
export var ProductPriceFieldsNbFragmentDoc = gql(_templateObject43());
export var ProductWeightFieldsNbFragmentDoc = gql(_templateObject44());
export var ProductVariantsFieldsNbFragmentDoc = gql(_templateObject45(), ProductWeightFieldsNbFragmentDoc);
export var ProductVariantsWithOptionValueFieldsNbFragmentDoc = gql(_templateObject46());
export var QuoteDetailFieldsFragmentDoc = gql(_templateObject47(), QuoteCommentDetailFieldsFragmentDoc, QuoteNoteDetailFieldsFragmentDoc);
export var ReportFieldsFragmentDoc = gql(_templateObject48());
export var MenuInfoFieldsFragmentDoc = gql(_templateObject49());
export var MenuItemModuleFieldsFragmentDoc = gql(_templateObject50());
export var MenuItemLinkFieldsFragmentDoc = gql(_templateObject51());
export var MenuItemLinkFieldsWithChildrenFragmentDoc = gql(_templateObject52(), MenuItemLinkFieldsFragmentDoc);
export var MenuItemFieldsFragmentDoc = gql(_templateObject53(), MenuItemModuleFieldsFragmentDoc, MenuItemLinkFieldsWithChildrenFragmentDoc);
export var TextLocalizationFieldsNbFragmentDoc = gql(_templateObject54());
export var ShippingPriceFieldsNbFragmentDoc = gql(_templateObject55());
export var ShippingRateFieldsNbFragmentDoc = gql(_templateObject56(), ShippingPriceFieldsNbFragmentDoc);
export var ShippingMethodFieldsNbFragmentDoc = gql(_templateObject57(), TextLocalizationFieldsNbFragmentDoc, ShippingRateFieldsNbFragmentDoc);
export var ShippingZoneFieldsNbFragmentDoc = gql(_templateObject58(), ShippingMethodFieldsNbFragmentDoc);
export var ShippingSettingFieldsNbFragmentDoc = gql(_templateObject59(), ShippingZoneFieldsNbFragmentDoc);
export var ShopAddressFragmentDoc = gql(_templateObject60());
export var BulkDeleteDashboardCouponCodesNbDocument = gql(_templateObject61(), BulkOperationResultNbFragmentDoc);
export var BulkUpdateDashboardCouponCodesNbDocument = gql(_templateObject62(), BulkOperationResultNbFragmentDoc);
export var AddDashboardAtrributeToProductNbDocument = gql(_templateObject63());
export var AddDashboardChildCollectionNbDocument = gql(_templateObject64(), DashboardChildCollectionFieldsNbFragmentDoc);
export var AddDashboardCollectionToSalesChannelNbDocument = gql(_templateObject65());
export var AddDashboardDiscountFreeProductNbDocument = gql(_templateObject66());
export var AddDashboardDiscountRuleObjectNbDocument = gql(_templateObject67(), DashboardDiscountDetailRuleObjectFieldsFragmentDoc);
export var AddDashboardProductPackageToSalesChannelDocument = gql(_templateObject68());
export var AddDashboardProductPackageToSalesChannelNbDocument = gql(_templateObject69());
export var AddDashboardProductToCollectionNbDocument = gql(_templateObject70(), DashboardCollectionCommonFieldsNbFragmentDoc);
export var AddDashboardProductToSalesChannelNbDocument = gql(_templateObject71());
export var AddLeadDocument = gql(_templateObject72());
export var ApplyProductImagesToPlaceholderNbDocument = gql(_templateObject73());
export var ApplyDashboardProductLightPresetToProductNbeDocument = gql(_templateObject74());
export var AssignAdminPortalUserRoleDocument = gql(_templateObject75());
export var AssignDashboardAccountContactFromAccountNbDocument = gql(_templateObject76());
export var AssignDashboardBrandToProductNbDocument = gql(_templateObject77());
export var AssignDashboardContactToTeamStoresNbDocument = gql(_templateObject78());
export var AssignDashboardManagerToAdminUserNbDocument = gql(_templateObject79());
export var AssignDashboardMicrostoreToContactNbDocument = gql(_templateObject80());
export var AssignPaymentConfigurationDocument = gql(_templateObject81());
export var AssignSalesChannelProductVariantsNbDocument = gql(_templateObject82());
export var BulkAddDashboardProductsToSalesChannelDocument = gql(_templateObject83());
export var BulkAddProductToSalesChannelsDocument = gql(_templateObject84());
export var BulkCreateSalesChannelSavedPlaceholderItemNbDocument = gql(_templateObject85());
export var BulkDeleteSalesChannelSavedPlaceholderItemNbDocument = gql(_templateObject86());
export var BulkUpdateSalesChannelProductsPriceModifierDocument = gql(_templateObject87());
export var BulkUpdateSalesChannelSavedPlaceholderItemNbDocument = gql(_templateObject88());
export var ConfirmAdminPortalUserInvitationNbDocument = gql(_templateObject89());
export var ConnectInstagramAccountDocument = gql(_templateObject90());
export var CopyDashboardDocumentTemplateDocument = gql(_templateObject91());
export var CreateCustomPlaceholderNbDocument = gql(_templateObject92(), ImagePlaceholderFragmentDoc, PlaceholderPositionAndDimensionFragmentDoc);
export var CreateDashboardAccountSalesRepNbDocument = gql(_templateObject93());
export var CreateDashboardAdminUserNbDocument = gql(_templateObject94(), DashboardAdminUserCommonFieldsNbFragmentDoc);
export var CreateDashboardCollectionNbDocument = gql(_templateObject95());
export var CreateDashboardContactNbDocument = gql(_templateObject96(), DashboardContactFieldsNbFragmentDoc);
export var CreateDashboardDeploymentNbDocument = gql(_templateObject97());
export var CreateDashboardDiscountNbDocument = gql(_templateObject98());
export var CreateDashboardDiscountRuleNbDocument = gql(_templateObject99(), DashboardDiscountDetailRuleFieldsFragmentDoc);
export var CreateDashboardDocumentDocument = gql(_templateObject100());
export var CreateDashboardLeadItemNbeDocument = gql(_templateObject101());
export var CreateDashboardOpeningCycleNbDocument = gql(_templateObject102());
export var CreateDashboardPersonalizationOptionNbDocument = gql(_templateObject103());
export var CreateDashboardProductAttributeNbDocument = gql(_templateObject104(), DashboardProductAttributeDetailFieldsNbFragmentDoc);
export var CreateProductFromSupplierNbDocument = gql(_templateObject105());
export var CreateDashboardSalesChannelDiscountNbDocument = gql(_templateObject106());
export var CreateDashboardShippingMethodNbDocument = gql(_templateObject107(), ShippingMethodFieldsNbFragmentDoc);
export var CreateDashboardShippingSettingsNbDocument = gql(_templateObject108(), ShippingSettingFieldsNbFragmentDoc);
export var CreateDashboardShippingZoneNbDocument = gql(_templateObject109(), ShippingZoneFieldsNbFragmentDoc);
export var CreateDashboardTeamStoreGroupNbDocument = gql(_templateObject110());
export var CreateDashboardTeamStoreNbDocument = gql(_templateObject111());
export var CreateDashboardTeamStorePersonalizationGroupNbDocument = gql(_templateObject112());
export var CreateDashboardTeamStoreTargetCategoryNbDocument = gql(_templateObject113());
export var CreateEmailDomainDocument = gql(_templateObject114());
export var CreateNotificationConfigDocument = gql(_templateObject115());
export var CreateEmbellishmentTypeNbDocument = gql(_templateObject116());
export var CreatePaymentMethodInfoDocument = gql(_templateObject117());
export var CreateProductDesignApprovalDocument = gql(_templateObject118());
export var CreateProductDesignCommentDocument = gql(_templateObject119());
export var CreateProductImagePlaceholderPositionAndDimensionNbDocument = gql(_templateObject120(), PlaceholderPositionAndDimensionFragmentDoc, ImagePlaceholderFragmentDoc);
export var CreateDashboardProductLightPresetNbeDocument = gql(_templateObject121());
export var CreateProductNbDocument = gql(_templateObject122());
export var CreateSalesChannelMenuDocument = gql(_templateObject123(), MenuInfoFieldsFragmentDoc, MenuItemFieldsFragmentDoc);
export var DashboardCreateSalesChannelPlaceholderColorwayMediaNbDocument = gql(_templateObject124());
export var CreateSalesChannelSavedPlaceholderItemNbDocument = gql(_templateObject125());
export var CreateShopAddressDocument = gql(_templateObject126(), ShopAddressFragmentDoc);
export var DashboardDisableShopDefaultFontDocument = gql(_templateObject127(), DashboardShopDefaultFontSettingFieldsFragmentDoc);
export var DashboardEnableShopDefaultFontDocument = gql(_templateObject128(), DashboardShopDefaultFontSettingFieldsFragmentDoc);
export var DashboardAssignLeadsToQuoteDocument = gql(_templateObject129());
export var DashboardAssignProductOptionValuesDocument = gql(_templateObject130());
export var DashboardBulkCreateSalesChannelPlaceholderColorwayMediaNbDocument = gql(_templateObject131());
export var DashboardBulkDeleteProductMediaNbDocument = gql(_templateObject132());
export var DashboardBulkDeleteSalesChannelProductMediaNbDocument = gql(_templateObject133());
export var DashboardCopyProductNbDocument = gql(_templateObject134());
export var DashboardCreateBulkProductMediaNbDocument = gql(_templateObject135());
export var DashboardCreateLeadDocument = gql(_templateObject136());
export var DashboardCreateOrderNoteDocument = gql(_templateObject137());
export var DashboardCreateProductMediaNbDocument = gql(_templateObject138());
export var DashboardCreateProductOptionNbDocument = gql(_templateObject139());
export var DashboardCreateProductOptionValueNbDocument = gql(_templateObject140());
export var DashboardCreateProductPersonalizationGroupNbDocument = gql(_templateObject141());
export var DashboardCreateProductsNbDocument = gql(_templateObject142());
export var DashboardCreateQuoteDocument = gql(_templateObject143());
export var DashboardCreateQuoteCommentDocument = gql(_templateObject144());
export var DashboardCreateQuoteNoteDocument = gql(_templateObject145());
export var DashboardCreateReportDocument = gql(_templateObject146());
export var DashboardCreateSalesChannelProductMediaV2NbDocument = gql(_templateObject147());
export var DashboardCreateOrderShipmentDocument = gql(_templateObject148());
export var DashboardDeleteOrderNoteDocument = gql(_templateObject149());
export var DashboardDeleteProductByIdNbDocument = gql(_templateObject150());
export var DashboardDeleteProductMediaDocument = gql(_templateObject151());
export var DashboardDeleteProductMediaNbDocument = gql(_templateObject152());
export var DashboardDeleteProductOptionByIdNbDocument = gql(_templateObject153());
export var DashboardDeleteProductOptionValueNbDocument = gql(_templateObject154());
export var DashboardDeleteQuoteDocument = gql(_templateObject155());
export var DashboardDeleteQuoteCommentDocument = gql(_templateObject156());
export var DashboardGenerateOrderInvoiceDocument = gql(_templateObject157());
export var DashboardRemoveLeadFromQuoteDocument = gql(_templateObject158());
export var DashboardTriggerEventDocument = gql(_templateObject159());
export var DashboardUpdateLeadAddressDocument = gql(_templateObject160());
export var DashboardUpdateLeadMetadataDocument = gql(_templateObject161());
export var DashboardUpdateLeadStatusDocument = gql(_templateObject162());
export var DashboardUpdateOrderItemVariantDocument = gql(_templateObject163());
export var DashboardUpdateOrderMetadataDocument = gql(_templateObject164());
export var DashboardUpdateOrderPaymentStatusDocument = gql(_templateObject165());
export var DashboardUpdateOrderProductionStatusDocument = gql(_templateObject166());
export var DashboardUpdateOrderShippingAddressDocument = gql(_templateObject167());
export var DashboardUpdateOrderShippingStatusDocument = gql(_templateObject168());
export var DashboardUpdateProductInfoByIdDocument = gql(_templateObject169());
export var DashboardUpdateProductMediaNbDocument = gql(_templateObject170());
export var DashboardUpdateProductOptionNbDocument = gql(_templateObject171());
export var DashboardUpdateProductOptionValueNbDocument = gql(_templateObject172());
export var DashboardUpdateQuoteCurrencyDocument = gql(_templateObject173());
export var DashboardUpdateQuoteNameDocument = gql(_templateObject174());
export var DashboardUpdateQuoteSalesChannelDocument = gql(_templateObject175());
export var DashboardUpdateQuoteStatusDocument = gql(_templateObject176());
export var DashboardUpdateReorderProductMediaNbDocument = gql(_templateObject177());
export var DashboardUpdateReorderSalesChannelProductMediaNbDocument = gql(_templateObject178());
export var DashboardUpdateTermOfServiceAcceptanceDocument = gql(_templateObject179());
export var DeleteAdminPortalUserNbDocument = gql(_templateObject180());
export var DeleteDashboardAccountNbeDocument = gql(_templateObject181());
export var DeleteDashboardAdminUserNbDocument = gql(_templateObject182());
export var DeleteDashboardCollectionNbDocument = gql(_templateObject183());
export var DeleteDashboardCollectionTaxRateNbDocument = gql(_templateObject184());
export var DeleteDashboardContactNbeDocument = gql(_templateObject185());
export var DeleteDashboardDiscountFreeProductNbDocument = gql(_templateObject186());
export var DeleteDashboardDiscountNbDocument = gql(_templateObject187());
export var DeleteDashboardDiscountRuleNbDocument = gql(_templateObject188());
export var DeleteDashboardDiscountRuleObjectNbDocument = gql(_templateObject189());
export var DeleteDashboardDocumentTemplateDocument = gql(_templateObject190());
export var DeleteDashboardOpeningCycleNbDocument = gql(_templateObject191());
export var DeleteDashboardPersonalizationOptionNbDocument = gql(_templateObject192());
export var DeleteDashboardProductAttributeNbDocument = gql(_templateObject193());
export var DeleteDashboardProductPersonalizationGroupNbDocument = gql(_templateObject194());
export var DeleteDashboardProductVariantNbDocument = gql(_templateObject195());
export var DeleteDashboardShippingMethodNbDocument = gql(_templateObject196());
export var DeleteDashboardShippingSettingsNbDocument = gql(_templateObject197());
export var DeleteDashboardShippingZoneNbDocument = gql(_templateObject198());
export var DeleteDashboardStorefrontNbDocument = gql(_templateObject199());
export var DeleteDashboardTaxRateNbDocument = gql(_templateObject200());
export var DeleteDashboardTeamStoreGroupNbDocument = gql(_templateObject201());
export var DeleteDashboardTeamStoreNbDocument = gql(_templateObject202());
export var DeleteDashboardTeamStoreTargetCategoryNbDocument = gql(_templateObject203());
export var DeleteEmailDomainDocument = gql(_templateObject204());
export var DeleteEmbellishmentTypeNbDocument = gql(_templateObject205());
export var DeleteEmbellishmentTypesNbDocument = gql(_templateObject206());
export var DeletePaymentMethodInfoDocument = gql(_templateObject207());
export var DeleteProductImagePlaceholderNbDocument = gql(_templateObject208());
export var DeleteProductImagePlaceholderPositionAndDimensionNbDocument = gql(_templateObject209());
export var DeleteDashboardProductLightPresetNbeDocument = gql(_templateObject210());
export var DashboardDeleteSalesChannelPlaceholderColorwayMediaNbDocument = gql(_templateObject211());
export var DeleteSalesChannelProductMediaNbDocument = gql(_templateObject212());
export var DeleteSalesChannelSavedPlaceholderItemNbDocument = gql(_templateObject213());
export var DisableShippingSettingsNbDocument = gql(_templateObject214());
export var DuplicateDashboardTeamStoreNbDocument = gql(_templateObject215());
export var EnableShippingSettingsNbDocument = gql(_templateObject216(), ShippingSettingFieldsNbFragmentDoc);
export var ExportCustomImagePlaceholderDocument = gql(_templateObject217());
export var ExportFeenyDesignDocument = gql(_templateObject218());
export var GenerateDashboardBulkOrderInvoicesDocument = gql(_templateObject219());
export var GenerateDashboardBulkOrderInvoicesByIdsDocument = gql(_templateObject220());
export var GenerateDashboardCouponNbDocument = gql(_templateObject221(), DashboardDiscountCouponFieldsFragmentDoc);
export var GenerateDashboardTeamStoreSlugUrlNbDocument = gql(_templateObject222());
export var GetDashboardContactNbDocument = gql(_templateObject223(), DashboardContactFieldsNbFragmentDoc);
export var SendAdminPortalUserInvitationNbDocument = gql(_templateObject224(), AdminPortalUserFragmentDoc);
export var SendAdminPortalAdminInvitationNbDocument = gql(_templateObject225(), AdminPortalUserFragmentDoc);
export var OpenDashboardDocumentTemplateEditorDocument = gql(_templateObject226());
export var CreatePaymentProviderConfigurationDocument = gql(_templateObject227());
export var DashboardSetupStripeConnectDocument = gql(_templateObject228());
export var DeletePaymentProviderConfigurationDocument = gql(_templateObject229());
export var PreviewDashboardDocumentTemplateDocument = gql(_templateObject230());
export var PublishDashboardDeploymentNbDocument = gql(_templateObject231());
export var RefundPaymentDocument = gql(_templateObject232());
export var RemoveAdminPortalUserRoleDocument = gql(_templateObject233());
export var RemoveDashboardAtrributeFromProductNbDocument = gql(_templateObject234());
export var RemoveDashboardChildCollectionNbDocument = gql(_templateObject235(), DashboardChildCollectionFieldsNbFragmentDoc);
export var RemoveDashboardCollectionFromSalesChannelNbDocument = gql(_templateObject236());
export var RemoveDashboardProductFromCollectionNbDocument = gql(_templateObject237(), DashboardCollectionCommonFieldsNbFragmentDoc, DashboardCollectionProductFieldsNbFragmentDoc);
export var RemoveDashboardProductPackageFromSalesChannelDocument = gql(_templateObject238());
export var RemoveDashboardProductPackageFromSalesChannelNbDocument = gql(_templateObject239());
export var RemoveDashboardProductToSalesChannelNbDocument = gql(_templateObject240());
export var RemoveDashboardProductLightPresetFromProductNbeDocument = gql(_templateObject241());
export var ResetDashboardAdminUserPasswordDocument = gql(_templateObject242());
export var SalesChannelConfigurationByPkDocument = gql(_templateObject243());
export var SaveDashboardDocumentTemplateDocument = gql(_templateObject244());
export var SaveImagePlaceholderNbDocument = gql(_templateObject245(), BaseProductImagePlaceholderFragmentDoc);
export var SetChildProductsDocument = gql(_templateObject246());
export var SetDashboardCollectionDefaultTaxRateNbDocument = gql(_templateObject247());
export var SetDashboardCollectionTaxRateNbDocument = gql(_templateObject248());
export var SetDashboardDefaultTaxRateNbDocument = gql(_templateObject249());
export var SetDashboardTaxRateNbDocument = gql(_templateObject250());
export var UpdateDashboardDefaultProductLightPresetNbeDocument = gql(_templateObject251());
export var SoftDeleteAssignedPaymentConfigurationDocument = gql(_templateObject252());
export var SubmitContactRequestDocument = gql(_templateObject253());
export var SyncDashboardProductVariantsNbDocument = gql(_templateObject254());
export var ToggleDashboardCollectionTaxSettingsDocument = gql(_templateObject255());
export var ToggleDashboardDiscountCouponNbDocument = gql(_templateObject256(), DashboardDiscountCouponFieldsFragmentDoc);
export var UnassignDashboardAccountContactFromAccountNbDocument = gql(_templateObject257());
export var UnassignDashboardBrandToProductNbDocument = gql(_templateObject258());
export var UnassignDashboardManagerFromAdminUserNbDocument = gql(_templateObject259());
export var UnassignDashboardMicrostoreFromContactNbDocument = gql(_templateObject260());
export var UpdateAdminUserInfoDocument = gql(_templateObject261());
export var UpdateAssignedPaymentConfigurationDocument = gql(_templateObject262());
export var UpdateDashboardAccountDetailNbDocument = gql(_templateObject263(), DashboardAdminUserCommonFieldsNbFragmentDoc);
export var UpdateDashboardAdminUserNbDocument = gql(_templateObject264(), DashboardAdminUserCommonFieldsNbFragmentDoc);
export var UpdateDashboardCollectionChildSortOrderNbDocument = gql(_templateObject265(), DashboardParentCollectionFieldsNbFragmentDoc, DashboardChildCollectionFieldsNbFragmentDoc);
export var UpdateDashboardCollectionNbDocument = gql(_templateObject266(), DashboardCollectionCommonFieldsNbFragmentDoc, DashboardCollectionInformationFieldsNbFragmentDoc, DashboardCollectionMediaFieldsNbFragmentDoc, DashboardCollectionProductFieldsNbFragmentDoc, DashboardParentCollectionFieldsNbFragmentDoc, DashboardChildCollectionFieldsNbFragmentDoc, DashboardCollectionAttributeFieldsNbFragmentDoc);
export var UpdateDashboardCollectionProductSortOrderNbDocument = gql(_templateObject267(), DashboardCollectionProductFieldsNbFragmentDoc);
export var UpdateDashboardContactNbDocument = gql(_templateObject268(), DashboardContactFieldsNbFragmentDoc);
export var UpdateDashboardDiscountDetailNbDocument = gql(_templateObject269(), DashboardDiscountDetailFieldsFragmentDoc);
export var UpdateDashboardDiscountRuleNbDocument = gql(_templateObject270(), DashboardDiscountDetailRuleFieldsFragmentDoc);
export var UpdateDashboardOpeningCycleNbDocument = gql(_templateObject271());
export var UpdateDashboardPersonalizationOptionNbDocument = gql(_templateObject272());
export var UpdateDashboardProductAttributeNbDocument = gql(_templateObject273(), DashboardProductAttributeDetailFieldsNbFragmentDoc);
export var UpdateDashboardProductPersonalizationGroupNbDocument = gql(_templateObject274());
export var UpdateDashboardProductPriceNbDocument = gql(_templateObject275(), ProductPriceFieldsNbFragmentDoc);
export var UpdateDashboardProductVariantNbDocument = gql(_templateObject276());
export var UpdateDashboardSalesChannelByIdNbDocument = gql(_templateObject277());
export var UpdateDashboardSalesChannelCollectionNbDocument = gql(_templateObject278());
export var UpdateDashboardSalesChannelDefaultPaymentConfigNbDocument = gql(_templateObject279());
export var UpdateDashboardSalesChannelProductNbDocument = gql(_templateObject280());
export var UpdateDashboardSalesChannelProductPackageNbDocument = gql(_templateObject281());
export var UpdateDashboardShippingMethodNbDocument = gql(_templateObject282(), ShippingMethodFieldsNbFragmentDoc);
export var UpdateDashboardShippingZoneNbDocument = gql(_templateObject283(), ShippingZoneFieldsNbFragmentDoc);
export var UpdateDashboardStorefrontContentNbDocument = gql(_templateObject284());
export var UpdateDashboardStorefrontInformationNbDocument = gql(_templateObject285());
export var UpdateDashboardTeamStoreAvailabilityNbDocument = gql(_templateObject286());
export var UpdateDashboardTeamStoreContentNbDocument = gql(_templateObject287());
export var UpdateDashboardTeamStoreGroupNbDocument = gql(_templateObject288());
export var UpdateDashboardTeamStoreInformationNbDocument = gql(_templateObject289());
export var UpdateDashboardTeamStoreStrucutreNbDocument = gql(_templateObject290());
export var UpdateDashboardTeamStoreTargetCategoryNbDocument = gql(_templateObject291());
export var UpdateNotificationConfigDocument = gql(_templateObject292());
export var UpdateEmbellishmentTypeNbDocument = gql(_templateObject293());
export var UpdateOrderTimelineVisibilityDocument = gql(_templateObject294());
export var UpdatePaymentMethodInfoDocument = gql(_templateObject295());
export var UpdatePaymentProviderConfigurationDocument = gql(_templateObject296());
export var UpdateProductDesignApprovalDocument = gql(_templateObject297());
export var UpdateBaseProductImagePlaceholderNbDocument = gql(_templateObject298());
export var UpdateCustomImagePlaceholderPositionAndDimensionNbDocument = gql(_templateObject299());
export var UpdateDashboardProductLightPresetNbeDocument = gql(_templateObject300());
export var UpdateSalesChannelProductMediaNbDocument = gql(_templateObject301());
export var UpdateSalesChannelSavedPlaceholderItemNbDocument = gql(_templateObject302());
export var UpdateShopAddressDocument = gql(_templateObject303(), ShopAddressFragmentDoc);
export var UpdateStorefrontDocument = gql(_templateObject304());
export var VerifyEmailDomainNbeDocument = gql(_templateObject305());
export var AdminPortalFundraisingDocument = gql(_templateObject306());
export var AdminPortalOrdersDocument = gql(_templateObject307());
export var AdminPortalOrdersOverviewDocument = gql(_templateObject308());
export var CheckDuplicateEmbellishmentTypeTitleNbDocument = gql(_templateObject309());
export var CheckDuplicatePlaceholderTitleNbDocument = gql(_templateObject310());
export var DashboardAddressDetailDocument = gql(_templateObject311(), DashboardAddressDetailFieldsFragmentDoc);
export var DashboardCommunicationPanelOrdersDocument = gql(_templateObject312());
export var DashboardShopDefaultFontSettingsDocument = gql(_templateObject313(), DashboardShopDefaultFontSettingFieldsFragmentDoc);
export var DashboardGetAllProductOptionsDocument = gql(_templateObject314());
export var DashboardGetAllReportsDocument = gql(_templateObject315(), ReportFieldsFragmentDoc);
export var GetDashboardOrderItemProductByIdNbDocument = gql(_templateObject316(), ProductPriceFieldsNbFragmentDoc, DashboardCollectionCommonFieldsNbFragmentDoc, DashboardProductAttributeCommonFieldsNbFragmentDoc, ProductVariantsWithOptionValueFieldsNbFragmentDoc, DashboardDiscountListItemFieldsFragmentDoc);
export var DashboardGetProductByIdNbDocument = gql(_templateObject317(), ProductWeightFieldsNbFragmentDoc, ProductPriceFieldsNbFragmentDoc, DashboardCollectionCommonFieldsNbFragmentDoc, DashboardProductAttributeCommonFieldsNbFragmentDoc, ProductVariantsFieldsNbFragmentDoc, DashboardDiscountListItemFieldsFragmentDoc);
export var DashboardGetProductCollectionsDocument = gql(_templateObject318());
export var DashboardGetProductOptionByIdNbDocument = gql(_templateObject319());
export var DashboardGetProductSalesChannelIdsDocument = gql(_templateObject320());
export var DashboardGetProductSalesChannelsDocument = gql(_templateObject321());
export var DashboardGetProductsNbDocument = gql(_templateObject322());
export var DashboardGetReportByIdDocument = gql(_templateObject323(), ReportFieldsFragmentDoc);
export var DashboardLeadDetailDocument = gql(_templateObject324(), DashboardLeadDetailFieldsFragmentDoc);
export var DashboardLeadsDocument = gql(_templateObject325(), DashboardLeadDetailFieldsFragmentDoc);
export var DashboardOrderByIdDocument = gql(_templateObject326(), DashboardOrderDetailFieldsFragmentDoc);
export var DashboardOrdersDocument = gql(_templateObject327(), DashboardOrderListFieldsFragmentDoc);
export var DashboardQuoteByIdDocument = gql(_templateObject328(), DashboardQuoteDetailFieldsFragmentDoc);
export var DashboardQuotesDocument = gql(_templateObject329(), DashboardQuoteListFieldsFragmentDoc);
export var DashboardSupplierServiceAvailabilityDocument = gql(_templateObject330());
export var DashboardGetTermOfServiceAcceptanceDocument = gql(_templateObject331());
export var DashboardUserAccessLevelDocument = gql(_templateObject332());
export var Demo1OrderDocument = gql(_templateObject333());
export var Demo2OrderDocument = gql(_templateObject334());
export var FeeneyDesignExportByIdDocument = gql(_templateObject335(), FeenyExportDesignFragmentDoc);
export var GetAddressByIdDocument = gql(_templateObject336(), ShopAddressFragmentDoc);
export var GetAdminPortalMicroStoreDocument = gql(_templateObject337());
export var GetAdminPortalOpeningCycleNbDocument = gql(_templateObject338(), AdminPortalOpeningCycleFieldsNbFragmentDoc);
export var GetAdminPortalProductsNbDocument = gql(_templateObject339());
export var GetAdminPortalUserNbDocument = gql(_templateObject340());
export var GetAdminPortalUsersNbDocument = gql(_templateObject341());
export var GetAdminPortalSalesChannelIdByOpeningCycleIdNbDocument = gql(_templateObject342());
export var GetAllNotificationConfigByShopIdDocument = gql(_templateObject343());
export var GetAllPaymentProviderConfigurationsDocument = gql(_templateObject344());
export var GetAllSupportedPaymentMethodsDocument = gql(_templateObject345());
export var GetAssignedPaymentConfigurationsDocument = gql(_templateObject346());
export var GetBaseProductImagesByPlaceholderIdNbDocument = gql(_templateObject347());
export var GetBaseProductImagesByPlaceholderIdsNbDocument = gql(_templateObject348());
export var GetDashboardAccountDetailNbDocument = gql(_templateObject349(), DashboardContactFieldsNbFragmentDoc);
export var GetDashboardAccountsNbDocument = gql(_templateObject350(), DashboardAdminUserCommonFieldsNbFragmentDoc, DashboardContactFieldsNbFragmentDoc);
export var GetDashboardAdminUserMembersNbDocument = gql(_templateObject351(), DashboardAdminUserCommonFieldsNbFragmentDoc);
export var GetDashboardAdminUserNbDocument = gql(_templateObject352(), DashboardAdminUserCommonFieldsNbFragmentDoc);
export var GetDashboardAdminUsersNbDocument = gql(_templateObject353(), DashboardAdminUserCommonFieldsNbFragmentDoc);
export var GetDashboardCollectionCommonNbDocument = gql(_templateObject354(), DashboardCollectionCommonFieldsNbFragmentDoc);
export var GetDashboardCollectionListNbDocument = gql(_templateObject355(), DashboardCollectionCommonFieldsNbFragmentDoc);
export var GetDashboardCollectionInformationNbDocument = gql(_templateObject356(), DashboardCollectionInformationFieldsNbFragmentDoc);
export var GetDashboardCollectionMediaNbDocument = gql(_templateObject357(), DashboardCollectionMediaFieldsNbFragmentDoc);
export var GetDashboardCollectionProductNbDocument = gql(_templateObject358(), DashboardCollectionCommonFieldsNbFragmentDoc);
export var GetDashboardParentCollectionNbDocument = gql(_templateObject359(), DashboardParentCollectionFieldsNbFragmentDoc);
export var GetDashboardChildCollectionNbDocument = gql(_templateObject360(), DashboardChildCollectionFieldsNbFragmentDoc);
export var GetDashboardCollectionTaxListNbDocument = gql(_templateObject361());
export var GetDashboardCollectionTaxSettingsNbDocument = gql(_templateObject362(), DashboardTaxSettingsFieldsFragmentDoc);
export var GetDashboardCollectionsNbDocument = gql(_templateObject363());
export var GetDashboardContactsNbDocument = gql(_templateObject364(), DashboardContactFieldsNbFragmentDoc);
export var GetDashboardCustomImageEditorProductsNbDocument = gql(_templateObject365());
export var GetDashboardDiscountCouponListNbDocument = gql(_templateObject366(), DashboardDiscountCouponFieldsFragmentDoc);
export var GetDashboardDiscountDetailNbDocument = gql(_templateObject367(), DashboardDiscountDetailFieldsFragmentDoc);
export var GetDashboardDiscountListNbDocument = gql(_templateObject368(), DashboardDiscountListItemFieldsFragmentDoc);
export var GetDashboardDiscountObjectProductsByIdsNbDocument = gql(_templateObject369());
export var GetDashboardDocumentTemplatesDocument = gql(_templateObject370());
export var GetDashboardJobByIdNbDocument = gql(_templateObject371());
export var GetDashboardLightPresetsNbeDocument = gql(_templateObject372(), ProductLightSettingPresetFragmentDoc);
export var GetDashboardLightSettingNbeDocument = gql(_templateObject373(), ProductLightSettingPresetFragmentDoc);
export var GetDashboardMetaFieldsNbDocument = gql(_templateObject374());
export var GetDashboardMicrostoresInContactNbDocument = gql(_templateObject375(), DashboardContactMicrostoreFieldsNbFragmentDoc);
export var GetDashboardOpeningCyclesNbDocument = gql(_templateObject376());
export var GetDashboardOptimizedJobByIdNbDocument = gql(_templateObject377());
export var GetDashboardPricingConfigurationNbDocument = gql(_templateObject378());
export var GetDashboardPricingModifierNbDocument = gql(_templateObject379());
export var GetDashboardProductAttributeNbDocument = gql(_templateObject380(), DashboardProductAttributeDetailFieldsNbFragmentDoc);
export var GetDashboardProductAttributesNbDocument = gql(_templateObject381(), DashboardProductAttributeCommonFieldsNbFragmentDoc);
export var GetDashboardProductBrandByProductIdNbDocument = gql(_templateObject382());
export var GetDashboardProductBrandsNbDocument = gql(_templateObject383());
export var GetDashboardProductByIdNbDocument = gql(_templateObject384(), ProductVariantsWithOptionValueFieldsNbFragmentDoc);
export var GetDashboardProductsNbDocument = gql(_templateObject385());
export var GetDashboardProductsSupplierPageNbDocument = gql(_templateObject386());
export var GetDashboardSalesChannelByIdNbDocument = gql(_templateObject387());
export var GetDashboardSalesChannelByIdsDocument = gql(_templateObject388());
export var GetDashboardSalesChannelCollectionsNbDocument = gql(_templateObject389());
export var GetDashboardSalesChannelDiscountListNbDocument = gql(_templateObject390(), DashboardDiscountListItemFieldsFragmentDoc);
export var GetDashboardSalesChannelProductByIdNbDocument = gql(_templateObject391());
export var GetDashboardSalesChannelProductPackagesNbDocument = gql(_templateObject392());
export var GetDashboardSalesChannelProductVariantsNbDocument = gql(_templateObject393());
export var GetDashboardSalesChannelProductsNbDocument = gql(_templateObject394());
export var GetDashboardSalesChannelsNbDocument = gql(_templateObject395());
export var GetDashboardSalesChannelsProductsOnCatalogSalesChannelsPageDocument = gql(_templateObject396());
export var GetDashboardShippingSettingsNbDocument = gql(_templateObject397(), ShippingSettingFieldsNbFragmentDoc);
export var GetDashboardStorefrontContentNbDocument = gql(_templateObject398());
export var GetDashboardStorefrontDeploymentNbDocument = gql(_templateObject399());
export var GetDashboardStorefrontInformationNbDocument = gql(_templateObject400());
export var GetDashboardStorefrontsNbDocument = gql(_templateObject401());
export var GetDashboardSupplierProductBrandsNbDocument = gql(_templateObject402());
export var GetDashboardSupplierProductCategoriesNbDocument = gql(_templateObject403());
export var GetDashboardSupplierProductDetailPageDocument = gql(_templateObject404());
export var GetDashboardSupplierProductVariantsNbDocument = gql(_templateObject405());
export var GetDashboardSupplierProductsNbDocument = gql(_templateObject406());
export var GetDashboardSuppliersNbDocument = gql(_templateObject407());
export var GetDashboardTaxSettingsNbDocument = gql(_templateObject408());
export var GetDashboardTeamStoreAvailabilityDocument = gql(_templateObject409());
export var GetDashboardTeamStoreContentNbDocument = gql(_templateObject410());
export var GetDashboardTeamStoreInformationNbDocument = gql(_templateObject411(), DashboardContactFieldsNbFragmentDoc);
export var GetDashboardTeamStorePersonalizationNbDocument = gql(_templateObject412());
export var GetDashboardTeamStoreStructureNbDocument = gql(_templateObject413());
export var GetDashboardTeamStoresNbDocument = gql(_templateObject414());
export var GetDashboardUserPermissionDocument = gql(_templateObject415(), DashboardAdminUserCommonFieldsNbFragmentDoc);
export var GetDashboardVerifiedSuppliersDocument = gql(_templateObject416());
export var GetDashboardVerifiedSupplierProductBrandsNbDocument = gql(_templateObject417());
export var GetDashboardVerifiedSupplierProductCategoriesNbDocument = gql(_templateObject418());
export var GetDashboardcouponUsageByDiscountIdNbDocument = gql(_templateObject419());
export var GetEmailDomainsDocument = gql(_templateObject420());
export var GetEmbellishmentTypesNbDocument = gql(_templateObject421());
export var GetPaymentMethodInfoBySalesChannelIdDocument = gql(_templateObject422());
export var GetPaymentMethodInfoByShopDocument = gql(_templateObject423());
export var GetProductByIdInformationDocument = gql(_templateObject424());
export var GetProductImagePlaceholderByProductIdNbDocument = gql(_templateObject425(), ImagePlaceholderFragmentDoc, PlaceholderPositionAndDimensionFragmentDoc);
export var GetProductImagePlaceholdersBySalesChannelIdNbDocument = gql(_templateObject426(), ImagePlaceholderFragmentDoc, PlaceholderPositionAndDimensionFragmentDoc);
export var GetStudioProductLightNbeDocument = gql(_templateObject427(), ProductLightPresetFragmentDoc);
export var GetProductMediaAndTitleNbDocument = gql(_templateObject428());
export var GetSalesChannelDefaultPaymentConfigNbeDocument = gql(_templateObject429());
export var GetSalesChannelNbeDocument = gql(_templateObject430());
export var GetSalesChannelNameNbeDocument = gql(_templateObject431());
export var GetSalesChannelSavedPlaceholderItemsNbDocument = gql(_templateObject432());
export var GetSalesChannelsNbeDocument = gql(_templateObject433());
export var GetShopAddressesDocument = gql(_templateObject434(), ShopAddressFragmentDoc);
export var InstagramPostsDocument = gql(_templateObject435());
export var LeadItemByProductDesignIdDocument = gql(_templateObject436());
export var OrderDetailNewBackendDocument = gql(_templateObject437(), OrderFragmentDoc);
export var OrderPaymentStatusDocument = gql(_templateObject438());
export var OrdersNewBackendDocument = gql(_templateObject439(), OrderFragmentDoc);
export var GetPaymentProviderConfigurationsDocument = gql(_templateObject440());
export var GetSupportedPaymentMethodsDocument = gql(_templateObject441());
export var ProductDesignApprovalDocument = gql(_templateObject442());
export var ProductDesignCommentsDocument = gql(_templateObject443());
export var ProductImagePlaceholdersNbDocument = gql(_templateObject444(), BaseProductImagePlaceholderFragmentDoc);
export var QuoteByIdDocument = gql(_templateObject445(), QuoteDetailFieldsFragmentDoc);
export var SalesChannelHasPlaceholderNbDocument = gql(_templateObject446());
export var SalesChannelMenuDocument = gql(_templateObject447(), MenuInfoFieldsFragmentDoc, MenuItemFieldsFragmentDoc);
export var SearchDashboardCollectionsNbDocument = gql(_templateObject448());
export var SearchDashboardCouponCodesNbDocument = gql(_templateObject449(), DashboardDiscountCouponFieldsFragmentDoc);
export var SearchDashboardCouponCodesNbv2Document = gql(_templateObject450(), DashboardSearchCouponCodesFieldsNbFragmentDoc);
export var SearchDashboardCustomImageEditorProductsNbDocument = gql(_templateObject451());
export var SearchDashboardProductsNbDocument = gql(_templateObject452());
export var SearchDashboardSalesChannelsNbDocument = gql(_templateObject453());
export var SearchProductCollectionsDocument = gql(_templateObject454());
export var SearchProductsNbeDocument = gql(_templateObject455());
export var SearchSalesChannelsDocument = gql(_templateObject456());
export var StorefrontHomepageDocument = gql(_templateObject457());
export var StudioGetProductsNbDocument = gql(_templateObject458());
export var DashboardSubscriptionJobByIdNbDocument = gql(_templateObject459());
export var DashboardSubscriptionReportByIdDocument = gql(_templateObject460(), ReportFieldsFragmentDoc);
