import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
var getMediaCDNHost = function(environment) {
    switch(environment){
        case "local":
            return "https://dev.cdn.mybrikl.com";
        case "production":
            return "https://cdn.mybrikl.com";
        case "staging":
            return "https://staging.cdn.mybrikl.com";
        default:
            return "https://".concat(environment, ".cdn.mybrikl.com");
    }
};
var getIMGIXHost = function(environment) {
    switch(environment){
        case "local":
            return "https://dev-cdn-media-brikl.imgix.net";
        case "production":
            return "https://cdn-media-brikl.imgix.net";
        case "staging":
            return "https://staging-cdn-media-brikl.imgix.net";
        default:
            return "https://".concat(environment, "-cdn-media-brikl.imgix.net");
    }
};
export var makeImgIxURL = function(param) {
    var environment = param.environment, url = param.url, queryOptions = param.queryOptions;
    if (!url || url === "undefined") return undefined;
    return "".concat(url === null || url === void 0 ? void 0 : url.replace(getMediaCDNHost(environment), getIMGIXHost(environment))).concat(buildQuery(queryOptions));
};
var s3AndCdnMap = new Map([
    [
        "dev-brikl-ecommerce.s3-accelerate.amazonaws.com",
        "dev.cdn.mybrikl.com"
    ],
    [
        "staging-brikl-ecommerce.s3-accelerate.amazonaws.com",
        "staging.cdn.mybrikl.com", 
    ],
    [
        "production-brikl-ecommerce.s3-accelerate.amazonaws.com",
        "cdn.mybrikl.com"
    ], 
]);
var s3AndImgixMap = new Map([
    [
        "dev-brikl-ecommerce.s3-accelerate.amazonaws.com",
        "dev-cdn-media-brikl.imgix.net", 
    ],
    [
        "staging-brikl-ecommerce.s3-accelerate.amazonaws.com",
        "staging-cdn-media-brikl.imgix.net", 
    ],
    [
        "production-brikl-ecommerce.s3-accelerate.amazonaws.com",
        "cdn-media-brikl.imgix.net", 
    ], 
]);
export var convertS3ToCdn = function(param) {
    var environment = param.environment, url = param.url;
    return "".concat(url === null || url === void 0 ? void 0 : url.replace("https://".concat(environment, "-brikl-ecommerce.s3-accelerate.amazonaws.com"), getMediaCDNHost(environment)));
};
export var fixS3ToCdnUrl = function(url) {
    var type = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "IMGIX";
    var domain = new URL(url);
    if (type === "IMGIX") {
        var ref;
        return url.replace(domain.hostname, (ref = s3AndImgixMap.get(domain.hostname)) !== null && ref !== void 0 ? ref : "dev-cdn-media-brikl.imgix.net");
    }
    var ref1;
    return url.replace(domain.hostname, (ref1 = s3AndCdnMap.get(domain.hostname)) !== null && ref1 !== void 0 ? ref1 : "dev.cdn.mybrikl.com");
};
export function fixCDNFromS3(url) {
    if (!url || url.includes("cdn-media-brikl") || url.includes("cdn.mybrikl")) return url;
    return fixS3ToCdnUrl(url);
}
function addTimestamp(src) {
    if (src.indexOf("?") !== -1) {
        return src + "&datetime=" + new Date().getTime();
    } else {
        return src + "?datetime=" + new Date().getTime();
    }
}
function buildQuery(queryOptions) {
    if (!queryOptions || isEmpty(queryOptions)) return "";
    return "?".concat(map(queryOptions, function(value, key) {
        return "".concat(key, "=").concat(value);
    }).join("&"));
}
export function isImgixCDNUrl(imageUrl) {
    return imageUrl === null || imageUrl === void 0 ? void 0 : imageUrl.includes("imgix.net");
}
export function previewImageUrl(param) {
    var url = param.url, environment = param.environment;
    return makeImgIxURL({
        environment: environment,
        url: url,
        queryOptions: {
            q: "15",
            "max-w": "150",
            "max-h": "150"
        }
    });
}
/**
 * check if https and NOT a S3 signed url - wrap with a timestamp to fix CORS
 * @param {string} src
 * @returns {string} src
 */ export function fixCDN(src) {
    var isDev = "production" === "development";
    // Skip no cdn url. and skip signed URLs (s.)
    if (!(src === null || src === void 0 ? void 0 : src.includes("cdn.")) || !(src === null || src === void 0 ? void 0 : src.includes("https")) || (src === null || src === void 0 ? void 0 : src.includes("https://s."))) {
        return src;
    }
    // Add timestamp for dev
    if (isDev) {
        return addTimestamp(src);
    }
    // Use CDN proxy
    var parsedUrl = new URL(src);
    var currentUrl = new URL(window.location.href);
    return src.replace(parsedUrl.origin, "".concat(currentUrl.origin, "/cdn/").concat(parsedUrl.hostname));
}
export function removeDateTime(url) {
    return url === null || url === void 0 ? void 0 : url.replace(/\?datetime=[0-9]+/g, "");
}
